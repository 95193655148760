import PropTypes from 'prop-types';
import NavItem from '../../NavItem';
import { Dropdown, Menu } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { Divider } from '@material-ui/core';
import React, { useCallback, useRef, useState } from 'react';
import { Users as UsersIcon } from 'react-feather';
import SafeAreaMenu from './SafeAreaMenu';
import './subMenu.css';
import { EyeInvisibleOutlined } from '@ant-design/icons';

const SubMenu = (props) => {
  const parent = useRef(null);
  const child = useRef(null);
  const [open, setOpen] = useState(false);
  const [visibleBtnShow, setVisibleBtnShow] = useState(false);
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  let timerId = null;

  const getAdditionalFilter = useCallback(() => {
    if (props.itemMenu.href === 'all_customers') {
      return [
        {
          href: '/customers',
          icon: UsersIcon,
          title: 'Клиенты',
        },
        {
          href: '/purveyors',
          icon: UsersIcon,
          title: 'Поставщики',
        },
      ];
    }

    if (props.itemMenu.href === 'contracts') {
      return [
        {
          href: '/contracts',
          title: 'Вагонное экспедирование',
          filters: [{ name: 'type', value: ['vex'] }],
        },
        {
          href: '/contracts',
          title: 'ТЭО с клиентом',
          filters: [{ name: 'type', value: ['tec'] }],
        },
        {
          href: '/contracts',
          title: 'Автоперевозка',
          filters: [{ name: 'type', value: ['aut'] }],
        },
        {
          href: '/contracts',
          title: 'Купля-продажа контейнера',
          filters: [{ name: 'type', value: ['kpc'] }],
        },
        {
          href: '/contracts',
          title: 'Ремонт контейнера',
          filters: [{ name: 'type', value: ['rec'] }],
        },
        {
          href: '/contracts',
          title: 'Предоставление вагона',
          filters: [{ name: 'type', value: ['vag'] }],
        },
        {
          href: '/contracts',
          title: 'Франшиза',
          filters: [{ name: 'type', value: ['fra'] }],
        },
        {
          href: '/contracts',
          title: 'ТЭО с поставщиком',
          filters: [{ name: 'type', value: ['tep'] }],
        },
      ];
    }
  }, [props.itemMenu.href]);

  const handleMouseEnter = () => {
    timerId = setTimeout(() => {
      setOpen(true);
      setVisibleBtnShow(false);
    }, 0);
  };

  const handleMouseLeave = () => {
    timerId = null;
    setOpen(false);
  };

  const handleChangeVisibleContextMenu = useCallback(
    (value) => {
      if (value) {
        setOpen(false);
      }
      setVisibleBtnShow(value);
    },
    [setVisibleBtnShow],
  );

  return (
    <>
      <Dropdown
        visible={visibleBtnShow}
        onVisibleChange={(value) => handleChangeVisibleContextMenu(value)}
        overlay={
          <Menu style={{ width: 200 }}>
            <div onMouseLeave={() => setVisibleBtnShow(false)}>
              <Menu.Item
                icon={<EyeInvisibleOutlined />}
                onClick={() => {
                  props.handleChangeVisibleSection(
                    props.itemMenu?.section_id,
                    false,
                  );
                }}
              >
                Скрыть
              </Menu.Item>
            </div>
          </Menu>
        }
        trigger={['contextMenu']}
      >
        <div
          style={{ cursor: 'pointer' }}
          ref={parent}
          onClick={() => {
            navigate(props.itemMenu.href);
            props.onClick && props.onClick();
          }}
          onMouseEnter={() => handleMouseEnter()}
          onMouseLeave={() => handleMouseLeave()}
        >
          <NavItem
            href={`/${props.itemMenu.href}`}
            hover={open}
            key={props.itemMenu?.id}
            title={props.itemMenu?.section}
            iconName={props.itemMenu?.icon}
            onlyIcon={props.closeFullMenu}
            //linkState={{filters: item?.filters }}
          />

          {open && parent.current && child.current && (
            <SafeAreaMenu anchor={parent.current} submenu={child.current} />
          )}

          <div
            className={'sub-menu-wrap'}
            style={{ visibility: open ? 'visible' : 'hidden' }}
          >
            <div ref={child} className={'sub-menu-block'}>
              {getAdditionalFilter() &&
                getAdditionalFilter().map((subItem) => {
                  return (
                    <>
                      <Menu.Item>
                        <span>{subItem.title}</span>
                        <Link
                          onClick={(e) => e.stopPropagation()}
                          to={subItem.href}
                          state={{ filters: subItem?.filters }}
                        />
                      </Menu.Item>
                      <Divider />
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      </Dropdown>
    </>
  );
};

SubMenu.propTypes = {
  itemMenu: PropTypes.object,
  closeFullMenu: PropTypes.bool,
  href: PropTypes.string,
  onClick: PropTypes.func,
  handleChangeVisibleSection: PropTypes.func,
};

export default SubMenu;
