export function sortColumnsByIndex(sourceArray, arrayOfOrders) {
  if (!sourceArray || !arrayOfOrders) {
    return [];
  }
  const sortedArrOfOrders = arrayOfOrders.sort((a, b) =>
    a.index > b.index ? 1 : -1,
  );
  const resultArr = [];
  let resChildren = [];
  for (const column of sortedArrOfOrders) {
    for (const sourceColumn of sourceArray) {
      if (column?.data_index === sourceColumn.uniqueIndex) {
        const children = sourceColumn?.children;
        if (children && children[0]?.width) {
          children[0].width = column?.width
            ? column.width
            : sourceColumn?.width;
        }
        resultArr.push({
          ...sourceColumn,
          idInDatabase: column.id,
          width: column?.width ? column.width : sourceColumn?.width,
          //children: sourceColumn?.children ? sourceColumn.children : null,
          columnId: column.visible ? 'active' : 'additional',
          children: children,
        });
      }
      /** Ниже идет формирование исключетельно для колонок групп когда сами колонки оборачиваются в группы с title
       * они помечаны параметров isGroup: true
       * Выглядит они вот так:
       * |        Название группы      |
       * |-----------------------------|
       * | name1      |     testName2  |  <------- вот эти 2 колонки name1 и testName2 являются элементами группы
       * |-----------------------------|
       * Надеюсь тебе стало понятнее )
       * */
      if (
        sourceColumn.isGroup &&
        sourceColumn.uniqueIndex === column?.group_index &&
        !resultArr.some((obj) => obj.uniqueIndex === sourceColumn.uniqueIndex)
      ) {
        // eslint-disable-next-line no-unsafe-optional-chaining
        const childrenArr = [...sourceColumn?.children];
        for (const children of childrenArr) {
          if (column?.data_index === children.uniqueIndex) {
            const childrenChildren = children?.children;
            if (childrenChildren && childrenChildren[0]?.width) {
              childrenChildren[0].width = column?.width
                ? column.width
                : childrenChildren?.width;
              childrenChildren[0]['uniqueIndex'] = children.uniqueIndex;
            }
            resChildren.push({
              ...children,
              idInDatabase: column.id,
              width: column?.width ? column.width : children?.width,
              //children: sourceColumn?.children ? sourceColumn.children : null,
              columnId: column.visible ? 'active' : 'additional',
              children: childrenChildren,
            });
          }
        }
        if (resChildren.length === childrenArr.length) {
          resultArr.push({
            ...sourceColumn,
            children: [...resChildren],
          });
          resChildren = [];
        }
      }
    }
  }
  return resultArr.sort(
    (a, b) => arrayOfOrders[a.index] - arrayOfOrders[b.index],
  );
}
