import React, { useContext, useEffect, useState } from 'react';
import { Dropdown, Menu, message, Space, Table } from 'antd';
import EllipsisOutlined from '@ant-design/icons/lib/icons/EllipsisOutlined';
import { restApiHost } from '../../utils/appVariables';
import ChainWithUserModal from './ChainWithUserModal';
import UserSwitchOutlined from '@ant-design/icons/lib/icons/UserSwitchOutlined';
import { userInformations } from '../../contex';
import { getBranchId } from '../../localStorageUtils/getBranchId';
import { call } from '../../apiUtils/call';

const AbonentsTable = ({ abonents, loading }) => {
  const [openChainWithUser, setOpenChainWithUser] = useState(false);
  const [loadingStaffList, setLoadingStaffList] = useState(false);
  const [currAbonent, setCurrAbonent] = useState(null);
  const [staff, setStaff] = useState([]);
  const { userInfo } = useContext(userInformations);

  useEffect(() => {
    getStaff();
  }, []);

  const groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, []);
  };

  const getStaff = async () => {
    try {
      const url = `${restApiHost}/accounts/staff_users/${
        userInfo.current.haveViewBranch ? '?' + 'branch=' + getBranchId() : ''
      }`;
      const res = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      });
      const json = await res.json();
      const groupedUsers = groupBy(json?.data, 'staff_position');
      setStaff(Object.entries(groupedUsers));
    } catch (e) {
      console.log('fetch profile info error', e);
    }
  };

  const menu = (abonent) => {
    return (
      <Menu style={{ width: 200 }}>
        <Menu.Item
          icon={<UserSwitchOutlined />}
          onClick={() => {
            setOpenChainWithUser(true);
            setCurrAbonent(abonent);
          }}
        >
          Связать с сотрудником
        </Menu.Item>
      </Menu>
    );
  };

  const columns = [
    {
      title: '',
      key: 'action',
      width: 50,
      align: 'center',
      render: (abonent) => (
        <Space size="middle">
          <Dropdown overlay={menu(abonent)} trigger={['click']}>
            <EllipsisOutlined style={{ cursor: 'pointer', fontSize: '24px' }} />
          </Dropdown>
        </Space>
      ),
    },
    {
      title: 'Имя абонента',
      key: 'firstName',
      dataIndex: 'firstName',
    },
    {
      title: 'Фамилия абонента',
      key: 'lastname',
      dataIndex: 'lastname',
    },
    {
      title: 'Номер телефона',
      key: 'phoneNumber',
      dataIndex: 'phoneNumber',
    },
    {
      title: 'Короткий номер',
      key: 'extension',
      dataIndex: 'extension',
    },
    {
      title: 'АОН',
      key: 'callingLineIdPhoneNumber',
      dataIndex: 'callingLineIdPhoneNumber',
    },
    {
      title: 'Наименование подразделения',
      key: 'department',
      dataIndex: 'department',
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
    },
    {
      title: 'Идентификаторы',
      children: [
        {
          title: 'ID предприятия VPBX',
          key: 'serviceProviderId',
          dataIndex: 'serviceProviderId',
        },
        {
          title: 'ID группы VPBX',
          key: 'groupId',
          dataIndex: 'groupId',
        },
        {
          title: 'ID абонента VPBX',
          key: 'userId',
          dataIndex: 'userId',
        },
      ],
    },
  ];

  const handleChangeVisibleChainUserModal = (visible) => {
    setOpenChainWithUser(visible);
  };

  const searchStaff = async (keyword) => {
    setLoadingStaffList(true);
    try {
      const url = `${restApiHost}/accounts/staff_users/?search=${keyword}`;
      const json = await call(url, {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      });
      if (!json) return;
      const groupedUsers = groupBy(json?.data, 'staff_position');
      setStaff(Object.entries(groupedUsers));
      setLoadingStaffList(false);
    } catch (e) {
      console.log(e);
      message.error('Ошибка запроса! Не удалось найти лида');
      setLoadingStaffList(false);
    }
  };

  return (
    <>
      <div style={{ marginBottom: '20px', marginTop: '20px' }}>
        <Table
          scroll={{ x: 1600, y: 500 }}
          columns={columns}
          loading={loading}
          rowClassName={(record, index) =>
            index % 2 === 0
              ? 'black-text table-row-color'
              : 'black-text table-row-white'
          }
          size="small"
          bordered={true}
          pagination={{ size: 'middle', pageSize: 15, showSizeChanger: false }}
          dataSource={abonents}
        />

        <ChainWithUserModal
          visible={openChainWithUser}
          staff={staff}
          loadingStaffList={loadingStaffList}
          searchStaff={searchStaff}
          getStaffList={getStaff}
          user={currAbonent}
          handleChangeVisible={handleChangeVisibleChainUserModal}
        />
      </div>
    </>
  );
};

export default AbonentsTable;
