import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';

import { useTypedSelector } from '../store/hooks/useTypedSelector';
import formationDocumentTitle from '../utils/formationDocumentTitle';

import TaskPageTabs from '../components/tasks/taskpagetabs/TaskPageTabs';

const Task = () => {
  const notificationCount = useTypedSelector(
    (state) => state.notificationCount.count,
  );

  return (
    <>
      <Helmet>
        <title>
          {formationDocumentTitle('Заявки | Квик Транс', notificationCount)}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <TaskPageTabs />
        </Container>
      </Box>
    </>
  );
};

export default Task;
