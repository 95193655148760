import { Button } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const TodayButton = (props) => {
  return (
    <>
      <Button
        onClick={() => {
          props.switchToToday();
        }}
        type="default"
        size={'middle'}
        icon={<CalendarOutlined />}
      >
        Сегодня
      </Button>
    </>
  );
};

TodayButton.propTypes = {
  switchToToday: PropTypes.func.isRequired,
};

export default TodayButton;
