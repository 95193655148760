import React, { useEffect, useState } from 'react';
import BillTemplate from './BillTemplate';
import {
  Button,
  Dropdown,
  Menu,
  message,
  Modal,
  Popconfirm,
  Space,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import { restApiHost } from '../../utils/appVariables';
import { DeleteOutlined, EllipsisOutlined } from '@ant-design/icons';
import DownloadOutlined from '@ant-design/icons/lib/icons/DownloadOutlined';

const BillTable = ({ data, loading, refresh, task }) => {
  const [visibleInvoiceModal, setVisibleInvoiceModal] = useState(false);
  const [currentInvoice, setCurrentInvoice] = useState(null);
  const [fullInvoiceInfo, setFullInvoiceInfo] = useState(null);
  const [loadingInfo, setLoadingInfo] = useState(false);

  useEffect(() => {
    if (visibleInvoiceModal && currentInvoice) {
      getFullInvoiceInfo(currentInvoice.id);
    }
  }, [visibleInvoiceModal, currentInvoice]);

  const getFullInvoiceInfo = async (id) => {
    setLoadingInfo(true);
    try {
      const url = `${restApiHost}/shipping/customer_invoice/${id}/`;
      const res = await fetch(url, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });

      if (res.status == 403) {
        message.warning(
          'У вас недостаточно прав для взаимодействия со счетами',
        );
        setLoadingInfo(false);
        return;
      }

      const json = await res.json();
      setFullInvoiceInfo(json);
      setLoadingInfo(false);
    } catch (e) {
      console.log('get invoice info err', e);
      message.error('Ошибка запроса на получение информации по счету');
    }
  };

  const deleteInvoice = async (id) => {
    try {
      const url = `${restApiHost}/shipping/customer_invoice/${id}/`;
      const res = await fetch(url, {
        method: 'DELETE',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      });

      if (res.status == 403) {
        message.warning(
          'У вас недостаточно прав для взаимодействия со счетами',
        );
        setLoadingInfo(false);
        return;
      }

      refresh();
      message.success('Запись успешно удалена');
    } catch (e) {
      console.log('delete invoice err', e);
      message.error('Ошибка запроса на удаление счета');
    }
  };

  const menu = (invoice) => {
    return (
      <Menu style={{ width: 200 }}>
        {/*<Menu.Item icon={<EditOutlined/>} onClick={() => {*/}

        {/*}}>*/}
        {/*    Редактировать*/}
        {/*</Menu.Item>*/}
        <Menu.Item
          icon={<DownloadOutlined />}
          onClick={() => {
            setVisibleInvoiceModal(true);
            setCurrentInvoice(invoice);
          }}
        >
          PDF
        </Menu.Item>
        <Popconfirm
          title="Вы действительно хотите удалить запись?"
          onConfirm={() => deleteInvoice(invoice.id)}
          onCancel={() => console.log('Delete item')}
          okText="Да"
          cancelText="Нет"
          placement="rightBottom"
        >
          <Menu.Item icon={<DeleteOutlined />}>Удалить</Menu.Item>
        </Popconfirm>
      </Menu>
    );
  };

  const columns = [
    {
      title: '',
      key: 'action',
      width: 50,
      fixed: 'left',
      render: (invoice) => (
        <Space size="middle">
          <Dropdown overlay={menu(invoice)} trigger={['hover']}>
            <EllipsisOutlined style={{ cursor: 'pointer', fontSize: '24px' }} />
          </Dropdown>
        </Space>
      ),
    },
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      width: 50,
    },
    {
      title: 'Услуга',
      children: [
        {
          title: 'Содержание услуги',
          key: 'service',
          dataIndex: 'cost',
          width: 250,
          ellipsis: {
            showTitle: false,
          },
          render: (cost) =>
            cost.length > 0 ? (
              <Tooltip
                placement="topLeft"
                title={cost.map((item) => item.service?.name).join(', ')}
              >
                {cost.map((item, index) => (
                  <Tag key={index}>{item.service?.name}</Tag>
                ))}
              </Tooltip>
            ) : null,
        },
        {
          title: 'Наименование услуги',
          key: 'name',
          dataIndex: 'cost',
          width: 280,
          ellipsis: {
            showTitle: false,
          },
          render: (cost) =>
            cost.length > 0 ? (
              <Tooltip
                placement="topLeft"
                title={cost.map((item) => item.operation?.name).join(', ')}
              >
                {cost.map((item, index) => (
                  <Tag key={index}>{item.operation?.name}</Tag>
                ))}
              </Tooltip>
            ) : null,
        },
      ],
    },
    {
      title: 'Валюта',
      key: 'currency_str',
      dataIndex: 'currency_str',
      width: 100,
    },
    {
      title: 'Кол-во',
      key: 'count',
      dataIndex: 'cost',
      width: 300,
      ellipsis: {
        showTitle: false,
      },
      render: (cost) =>
        cost.length > 0 ? (
          <Tooltip
            placement="topLeft"
            title={cost
              .map((item) => `${item.service.name} - ${item.count}`)
              .join(', ')}
          >
            {cost
              .map((item) => `${item.service.name} - ${item.count}`)
              .join(', ')}
          </Tooltip>
        ) : null,
    },
    {
      title: 'Счет',
      children: [
        {
          title: 'Номер счета',
          key: 'number_invoice',
          dataIndex: 'number_invoice',
          width: 150,
        },
        {
          title: 'Дата счета',
          key: 'date_create',
          dataIndex: 'date_create',
          width: 150,
          render: (date) => date && date.split('T')[0],
        },
      ],
    },
    {
      title: 'Состояние счета',
      key: 'status_str',
      dataIndex: 'status_str',
      width: 150,
    },
  ];

  return (
    <div>
      {/*<BillTemplate/>*/}

      <Table
        locale={{
          triggerDesc: 'По убыванию',
          triggerAsc: 'По возрастанию',
          cancelSort: 'Сбросить сортировку',
        }}
        // onRow={(record, rowIndex) => {
        //     return {
        //         onDoubleClick: (event) => {
        //             setCurrEditApplication(record);
        //             setVisibleEditApplicationModal(true);
        //         },
        //     };
        // }}
        showHeader
        scroll={{ x: 1300 }}
        loading={loading}
        dataSource={data}
        bordered={true}
        columns={columns}
        rowClassName={(record, index) =>
          index % 2 === 0
            ? 'black-text table-row-color'
            : 'black-text table-row-white'
        }
        size="small"
        pagination={{
          position: ['none'],
          size: 'middle',
          showSizeChanger: false,
        }}
      />
      <Modal
        width={1000}
        visible={visibleInvoiceModal}
        title="Скачать счет на оплату"
        onOk={() => setVisibleInvoiceModal(false)}
        onCancel={() => setVisibleInvoiceModal(false)}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setVisibleInvoiceModal(false);
            }}
          >
            Закрыть
          </Button>,
        ]}
      >
        <BillTemplate
          loading={loadingInfo}
          data={fullInvoiceInfo}
          task={task}
        />
      </Modal>
    </div>
  );
};

export default BillTable;
