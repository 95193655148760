import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Form, message, Modal, Steps } from 'antd';
import { showConfirm } from '../../../UiComponents/showConfirn/showConfirn';
import PropTypes from 'prop-types';
import LeedsManagersTable from '../../LeedsManagersTable';
import { ErrorBoundary } from '../../../ErrorBoundary';
import CustomerChangeHistory from '../../../UpdateCustomer/customerStepsComponents/customerChangeHistory/CustomerChangeHistory';
import HistoryCallLeadTable from '../../../HistoryCalls/Leads/HistoryCallLeadTable';
import ContactsLeed from '../../ContactsLeed';
import EmailsForClients from '../../../email/EmailsForClients';
import BasicInformationLeadForm from './steps/BasicInformationLeadForm';
import { getDisplayedSteps } from '../../../customer/cutomerModalUtils/customerModalUtilsFunctions';
import CustomDot from '../../../UiComponents/steps/CustomDot';
import moment from 'moment/moment';
import { userInformations } from '../../../../contex';
import {
  createLead,
  createLeadContact,
  editLead,
} from '../../../../logic/lead/LeadApiFunctions';
import { useTypedSelector } from '../../../../store/hooks/useTypedSelector';

const { Step } = Steps;

const LeadModal = (props) => {
  const [mainForm] = Form.useForm();
  const { userInfo } = useContext(userInformations);
  const [currentStep, setCurrentStep] = useState(0);
  const [messengerOtherSelected, setMessengerOtherSelected] = useState(false);
  const [sourceOtherSelected, setSourceOtherSelected] = useState(false);
  const [isArchive, setIsArchive] = useState(false);
  const demoAccessIsUsed = useTypedSelector(
    (state) => state.demoAccess.demoAccessIsUsed,
  );

  useEffect(() => {
    if (props.editingLead) {
      setFormValue(props.editingLead);
    }
  }, [props.editingLead]);

  const setFormValue = useCallback(
    (leadData) => {
      mainForm.setFieldsValue({
        ...leadData,
        city: leadData?.city?.id || null,
        strat_arch_reason: leadData?.strat_arch_reason,
        call_result: leadData?.call_result?.id ? leadData.call_result.id : null,
        date_recent_request: leadData?.date_recent_request
          ? moment(leadData.date_recent_request).format('YYYY-MM-DD')
          : null,

        date_next_request: leadData?.date_next_request
          ? moment(leadData.date_next_request).format('YYYY-MM-DD HH:mm')
          : null,

        manager: leadData?.manager?.full_name
          ? leadData?.manager.full_name
          : '',
      });

      if (leadData?.call_result?.id === 1 || leadData?.call_result?.id === 2) {
        setIsArchive(true);
      }
      setSourceOtherSelected(!!leadData?.source_other);
      setMessengerOtherSelected(!!leadData?.messenger_other);
    },
    [mainForm, setIsArchive, setSourceOtherSelected, setMessengerOtherSelected],
  );

  const stepsModal = [
    {
      title: 'Основная информация',
      visible: true,
      content: () => {
        return (
          <>
            <ErrorBoundary>
              <BasicInformationLeadForm
                form={mainForm}
                messengerOtherSelected={messengerOtherSelected}
                editingLead={props.editingLead}
                setMessengerOtherSelected={(val) => {
                  setMessengerOtherSelected(val);
                }}
                sourceOtherSelected={sourceOtherSelected}
                isArchive={isArchive}
                setIsArchive={(val) => setIsArchive(val)}
              />
            </ErrorBoundary>
          </>
        );
      },
    },
    {
      title: 'Контактные лица',
      visible: true,
      disabled: !props.editingLead,
      content: () => {
        return (
          <ErrorBoundary>
            <ContactsLeed lead={props.editingLead} />
          </ErrorBoundary>
        );
      },
    },
    {
      title: 'Менеджеры',
      visible: true,
      disabled: !props.editingLead,
      content: () => {
        return (
          <ErrorBoundary>
            <LeedsManagersTable leadId={props.editingLead?.id} />
          </ErrorBoundary>
        );
      },
    },
    {
      title: 'История изменений',
      visible: props.editingLead !== null,
      disabled: !props.editingLead,
      content: () => {
        return (
          <ErrorBoundary>
            <CustomerChangeHistory
              customerId={props.editingLead?.id}
              isLeadHistory={true}
            />
          </ErrorBoundary>
        );
      },
    },
    {
      title: 'История вызовов',
      visible: false,
      disabled: !props.editingLead || demoAccessIsUsed,
      content: () => {
        return (
          <ErrorBoundary>
            <HistoryCallLeadTable leadId={props.editingLead?.id} />
          </ErrorBoundary>
        );
      },
    },
    {
      title: 'Переписка',
      visible: props.editingLead !== null,
      disabled: !props.editingLead || demoAccessIsUsed,
      content: () => {
        return (
          <ErrorBoundary>
            <EmailsForClients lead={props.editingLead?.id} />
          </ErrorBoundary>
        );
      },
    },
  ];

  const handleCloseModal = () => {
    clearingForm();
    props.onCloseModal();
  };

  const clearingForm = () => {
    mainForm.resetFields();
    setCurrentStep(0);
    setMessengerOtherSelected(false);
    setSourceOtherSelected(false);
  };

  const prev = useCallback(() => {
    setCurrentStep((prevState) => prevState - 1);
  }, []);

  const next = useCallback(async () => {
    if (currentStep === 0 && !props.editingLead) {
      await submitForm();
      return;
    }
    setCurrentStep((prevState) => prevState + 1);
  }, [props.editingLead]);

  const handleCreateContact = useCallback(
    async (body) => {
      const res = await createLeadContact(body, userInfo);
      if (res?.data) {
        message.success('Контактное лицо успешно добавлено!');
      }
    },
    [createLeadContact],
  );

  const submitForm = useCallback(() => {
    mainForm
      .validateFields()
      .then(async (values) => {
        const hide = message.loading(
          props.editingLead ? 'Редактирование лида' : 'Создание лида',
          1000,
        );
        const dateNextRequest = values?.date_next_request
          ? new Date(`${values?.date_next_request}`)
          : null;
        const body = {
          ...values,
          color: values?.color ? values.color : null,
          messenger_other: values?.messenger_other
            ? values.messenger_other
            : null,
          source_other: values?.source_other ? values.source_other : null,
          date_recent_request: values.date_recent_request || null,
          date_next_request: dateNextRequest
            ? moment(dateNextRequest).format()
            : null,
        };
        console.log(body);
        delete body.manager;

        !body.date_next_request && delete body.date_next_request;
        !body.date_recent_request && delete body.date_recent_request;
        const res = props.editingLead
          ? await editLead(props.editingLead?.id, body, userInfo)
          : await createLead(body, userInfo);

        if (res?.data) {
          if (props.editingLead) {
            handleCloseModal();
          } else {
            const bodyContactPersons = {
              full_name: values?.contact_person,
              lead: res?.data.id,
              phone_number: values?.phone_number,
              email: values?.email,
            };
            await handleCreateContact(bodyContactPersons);
            setCurrentStep((prevState) => prevState + 1);
            props.setCurrentLeadData(res?.data);
          }
        }
        hide();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    mainForm,
    props.editingLead,
    handleCloseModal,
    handleCreateContact,
    setCurrentStep,
    props.setCurrentLeadData,
  ]);

  return (
    <>
      <Modal
        visible={props.visibleModal}
        title={props.editingLead ? 'Редактирование лида' : 'Добавление лида'}
        width={1000}
        onCancel={async () => {
          if (props.editingLead && !demoAccessIsUsed) {
            setCurrentStep(0);
            showConfirm(submitForm, handleCloseModal);
          } else {
            handleCloseModal();
          }
        }}
        footer={[]}
      >
        <Steps
          className={'customer-modal-steps'}
          size="small"
          progressDot={CustomDot}
          current={currentStep}
          onChange={(current) => setCurrentStep(current)}
        >
          {getDisplayedSteps(stepsModal).map((item) => (
            <Step
              style={{ marginTop: '7px' }}
              key={item.title}
              title={item.title}
              disabled={item.disabled}
            />
          ))}
        </Steps>
        <div className="steps-content" style={{ marginTop: '10px' }}>
          {getDisplayedSteps(stepsModal)[currentStep]?.content()}
        </div>
        <div
          className="steps-action"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          {currentStep > 0 && (
            <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
              Назад
            </Button>
          )}
          {(props.editingLead && currentStep === 0) ||
          currentStep === getDisplayedSteps(stepsModal).length - 1 ? (
            <Button
              style={{ marginRight: '10px' }}
              disabled={demoAccessIsUsed}
              onClick={async () => {
                await submitForm();
              }}
            >
              Сохранить
            </Button>
          ) : (
            <></>
          )}
          {currentStep <
            (demoAccessIsUsed
              ? getDisplayedSteps(stepsModal).length - 2
              : getDisplayedSteps(stepsModal).length - 1) && (
            <Button type="primary" onClick={() => next()}>
              Далее
            </Button>
          )}
          {currentStep ===
            (demoAccessIsUsed
              ? stepsModal.length - 3
              : stepsModal.length - 1) && (
            <Button
              type="primary"
              onClick={() => {
                handleCloseModal();
              }}
            >
              Закрыть
            </Button>
          )}
        </div>
      </Modal>
    </>
  );
};

LeadModal.propTypes = {
  visibleModal: PropTypes.bool,
  editingLead: PropTypes.object,
  onCloseModal: PropTypes.func,
  setCurrentLeadData: PropTypes.func,
};

export default LeadModal;
