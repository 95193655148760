import PropTypes from 'prop-types';
import { Checkbox, ConfigProvider, Drawer, Table } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import getSectionsForCurrentGroup from '../../getSectionsForCurrentGroup';
import locale from 'antd/lib/locale/ru_RU';
import { Box } from '@material-ui/core';
import { multipleEditFunction } from '../../../../../apiUtils/multipleUpdate/multipleEditFunction';
import { userInformations } from '../../../../../contex';
import useCheckMobileScreen from '../../../../../hooks/useCheckMobileScreen';
import { ErrorBoundary } from '../../../../ErrorBoundary';
import ScrollingLoadedList from '../../../../mobile/scrollingLoadedList/ScrollingLoadedList';
import SectionsListItem from '../../../../mobile/branches/list/SectionsListItem';

const MultipleSettingSectionsDrawer = (props) => {
  const { userInfo } = useContext(userInformations);
  const [loading, setLoading] = useState(false);
  const [columnsTable, setColumnsTable] = useState([]);
  const [tableData, setTableData] = useState([]);
  const isMobile = useCheckMobileScreen();

  useEffect(async () => {
    if (props.groups && props.visible) {
      await synchronizationSectionDisplayValuesByGroups();
    }
  }, [props.visible]);

  async function synchronizationSectionDisplayValuesByGroups() {
    setLoading(true);
    const { columns, data } = await prepareSectionsForOutputToTable(
      props.groups,
    );
    setColumnsTable(columns);
    setTableData(data);
    setLoading(false);
  }

  const prepareSectionsForOutputToTable = async (groups) => {
    if (!groups) return;
    let allSectionsAllGroups = [];
    let columnsTable = [
      {
        title: 'Название секции',
        width: 100,
        key: 'nameSections',
        dataIndex: 'sectionsName',
        align: 'center',
      },
    ];
    for (let group of groups) {
      columnsTable.push({
        title: group?.name,
        width: 100,
        key: group?.name,
        align: 'center',
        render: (data) => {
          const groupId = group?.id;
          return typeof data[groupId]?.visible !== 'undefined' &&
            JSON.parse(data[groupId]?.visible) ? (
            <Checkbox
              checked={true}
              onChange={(e) => {
                multipleChangeVisible(data, e.target.checked);
              }}
            />
          ) : (
            <Checkbox
              checked={false}
              onChange={(e) => {
                multipleChangeVisible(data, e.target.checked);
              }}
            />
          );
        },
      });
      allSectionsAllGroups.push({
        groupId: group?.id,
        sections: await getSectionsForCurrentGroup(group?.id, props.branchId),
      });
    }
    const dataForTable = formationTableData(allSectionsAllGroups);

    return {
      columns: columnsTable,
      data: dataForTable,
    };
  };

  const formationTableData = (allSectionsAllGroups) => {
    let tableDataMap = new Map();
    /* первой итерацией заполняем мапу секциями*/
    for (const section of allSectionsAllGroups[0].sections) {
      tableDataMap.set(section.href, {
        sectionsName: section.section,
        sectionIndex: section.index,
      });
    }
    /*теперь заполняем visible секции по каждой группе*/
    for (const group of allSectionsAllGroups) {
      if (!group?.sections) continue;
      for (const groupSection of group.sections) {
        if (tableDataMap.has(groupSection.href)) {
          const objForSet = {
            ...tableDataMap.get(groupSection.href),
          };
          objForSet[group.groupId] = {
            visible: groupSection?.value.toString(),
            sectionDataBaseId: groupSection?.id,
          };
          //objForSet['sectionDataBaseId'] = groupSection?.id
          tableDataMap.set(groupSection.href, objForSet);
        }
      }
    }

    const result = [];
    tableDataMap.forEach((value) => {
      result.push({
        ...value,
      });
    });

    return result;
  };

  const multipleChangeVisible = async (dataRow, visible) => {
    const appName = '/accounts/group_section_visibility/';
    const data = {
      value: visible,
    };
    const itemsIds = [];
    for (const item in dataRow) {
      // eslint-disable-next-line no-prototype-builtins
      if (dataRow[item].hasOwnProperty('sectionDataBaseId')) {
        itemsIds.push(dataRow[item]?.sectionDataBaseId);
      }
    }
    const status = await multipleEditFunction(
      appName,
      data,
      itemsIds,
      userInfo,
    );
    if (status === 'success') {
      await synchronizationSectionDisplayValuesByGroups();
    }
  };

  const closeDrawer = () => {
    clearUpVariable();
    props.closeDrawer();
  };

  const clearUpVariable = () => {
    setColumnsTable([]);
    setTableData([]);
  };

  return (
    <>
      <Drawer
        className={'global-style-drawer'}
        visible={props.visible}
        title="Разделы"
        placement="right"
        //width={'Calc(100% - 255px'}
        height={'100vh'}
        bodyStyle={{ overflow: 'initial' }}
        onClose={() => closeDrawer()}
      >
        <Box sx={{ pt: 3 }} style={{ height: '100%' }}>
          {!isMobile ? (
            <ConfigProvider locale={locale}>
              <Table
                rowClassName={(record, index) =>
                  index % 2 === 0
                    ? 'black-text table-row-color'
                    : 'black-text table-row-white'
                }
                sticky={true}
                dataSource={tableData}
                columns={columnsTable}
                size="small"
                bordered={true}
                loading={loading}
                rowKey={(record) => record.id}
                pagination={false}
              />
            </ConfigProvider>
          ) : (
            <>
              <ErrorBoundary>
                <ScrollingLoadedList
                  dataList={tableData}
                  totalData={tableData?.length}
                  loadMoreItems={async () => {
                    //await handleUpdate(page + 1, params, false,true)
                  }}
                  renderListItem={(item) => (
                    <SectionsListItem
                      section={item}
                      fields={columnsTable.filter(
                        (item) => item.key !== 'nameSections',
                      )}
                    />
                  )}
                />
              </ErrorBoundary>
            </>
          )}
        </Box>
      </Drawer>
    </>
  );
};

MultipleSettingSectionsDrawer.PropTypes = {
  visible: PropTypes.bool.isRequired,
  groups: PropTypes.any.isRequired,
  branchId: PropTypes.number.isRequired,
  closeDrawer: PropTypes.func.isRequired,
};

export default MultipleSettingSectionsDrawer;
