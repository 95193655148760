import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Card, Col, Divider, Dropdown, Row, Space } from 'antd';
import EllipsisOutlined from '@ant-design/icons/lib/icons/EllipsisOutlined';
import { priceFormatter } from '../../../../utils/priceFormatter';

const CostListItem = (props) => {
  const cardTitle = useCallback(() => {
    return (
      <>
        <Row justify={'space-between'} wrap={false}>
          <Col>
            <Col className={'mainText'}>
              <Col xs={24}>
                <div className={'secondText'}>{'Операция'}</div>
                <div className={'valueText'}>
                  {props.cost?.operation?.name || '-'}
                </div>
              </Col>
            </Col>
          </Col>
          <Row justify={'end'}>
            <Space size="middle" align={'start'}>
              <Dropdown
                overlay={props.menuActions(props.cost)}
                trigger={['click']}
              >
                <EllipsisOutlined
                  style={{ cursor: 'pointer', fontSize: '24px' }}
                />
              </Dropdown>
            </Space>
          </Row>
        </Row>
      </>
    );
  }, [props.cost, props.menuActions]);

  return (
    <>
      <Card className={'mainCard'} bodyStyle={{ padding: '12px' }}>
        {cardTitle()}
        <Divider style={{ margin: '10px 0' }} />

        <Row gutter={[0, 10]}>
          <Col xs={12}>
            <div className={'secondText'}>{'Вал.'}</div>
            <div className={'valueText'}>
              {props.handleRenderCurrency(props.cost?.currency) || '-'}
            </div>
          </Col>

          <Col xs={12}>
            <div className={'secondText'}>{'Курс'}</div>
            <div className={'valueText'}>
              {props.cost?.currency_rates || '-'}
            </div>
          </Col>

          <Col xs={12}>
            <div className={'secondText'}>{'Цена Вал.'}</div>
            <div className={'valueText'}>
              {props.priceFormatter(props.cost?.price_currency) || '-'}
            </div>
          </Col>

          <Col xs={12}>
            <div className={'secondText'}>{'Цена Вал.(У)'}</div>
            <div className={'valueText'}>
              {props.priceFormatter(props.cost?.price_currency) || '-'}
            </div>
          </Col>

          <Col xs={12}>
            <div className={'secondText'}>{'Ед. Кол-во'}</div>
            <div className={'valueText'}>
              {props.cost?.count_services || '-'}
            </div>
          </Col>

          <Col xs={12}>
            <div className={'secondText'}>{'Ед. изм'}</div>
            <div className={'valueText'}>
              {props.cost?.service?.units || '-'}
            </div>
          </Col>

          <Col xs={12}>
            <div className={'secondText'}>{'НДС Ставка'}</div>
            <div className={'valueText'}>{props.cost?.var_rate_str || '-'}</div>
          </Col>

          <Col xs={12}>
            <div className={'secondText'}>{'НДС Валюта(У)'}</div>
            <div className={'valueText'}>
              {props.priceFormatter(props.cost?.sum_vat) || '-'}
            </div>
          </Col>

          <Col xs={12}>
            <div className={'secondText'}>{'Всего'}</div>
            <div className={'valueText'}>
              {props.cost?.in_currency
                ? priceFormatter(props.cost?.sum_currency?.sum_with_vat || 0)
                : priceFormatter(props.cost?.sum_with_vat || 0) || '-'}
            </div>
          </Col>

          <Col xs={12}>
            <div className={'secondText'}>{'Всего(У)'}</div>
            <div className={'valueText'}>
              {props.cost?.sum_with_vat
                ? priceFormatter(props.cost?.sum_with_vat)
                : 0}
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default CostListItem;

CostListItem.propTypes = {
  cost: PropTypes.object,
  menuActions: PropTypes.func,
  handleRenderCurrency: PropTypes.func.isRequired,
  priceFormatter: PropTypes.func.isRequired,
};
