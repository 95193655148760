import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CheckOutlined } from '@ant-design/icons';
import React from 'react';

const MessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 10px 0 10px;
  align-items: ${(props) =>
    props.isMine ? 'flex-end !important' : 'flex-start !important'};
  max-width: 80%;
  min-width: 15%;
  width: fit-content;
  align-self: ${(props) =>
    props.isMine ? 'flex-end !important' : 'flex-start !important'};
  background-color: ${(props) => (props.isMine ? '#007fff' : '#f4f4f4')};
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: visible;
  position: relative;
  padding: 10px;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    right: ${(props) => (props.isMine ? '-5px' : 'auto')};
    left: ${(props) => (props.isMine ? 'auto' : '-10px')};
    width: 30px;
    height: 0;
    border-style: solid;
    border-width: ${(props) =>
      props.isMine ? '0 15px 15px 0' : '0 15px 15px 15px'};
    border-color: ${(props) =>
      props.isMine
        ? '#f4f4f4 transparent #007fff transparent'
        : '#f4f4f4 transparent #f4f4f4 transparent'};
    box-shadow: 1px 6px 9px -6px rgba(0, 0, 0, 0.1);
  }
`;
const MessageContent = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.isMine ? 'flex-end' : 'flex-start')};
  color: ${(props) => (props.isMine ? 'white' : 'black')};
`;

const UserName = styled.div`
  font-size: 14px;
  color: ${(props) => (props.isMine ? 'white' : 'black')};
  font-weight: bold;
  margin-bottom: 5px;
`;

const Text = styled.p`
  font-size: 15px;
  line-height: 105%;
  font-weight: 400;
  word-wrap: break-word;
  width: 100%;
  text-align: left;
  margin: 0;
`;

const Timestamp = styled.div`
  font-size: 12px;
  align-self: flex-end;
  color: ${(props) => (props.isMine ? 'white' : '#888')};
  display: flex;
  align-items: center;
  gap: 5px;
  float: right;
  margin-left: 3px;
  margin-top: 5px;
`;

const CheckedArrows = styled.div`
  display: ${(props) => (props.isMine && props.isViewed ? 'flex' : 'none')};
  font-size: 14px;
  height: 18px;
  z-index: 1;
  margin-bottom: -3px;
`;
const TimeCaption = styled.p`
  margin-bottom: 0;
  z-index: 1;
  opacity: 0.7;
  max-width: 35px;
  width: 35px;
  text-align: right;
  line-height: 100%;
  font-weight: 400;
  height: 100%;
  font-size: 10px;
`;

const MessageBox = (props) => {
  return (
    <>
      <MessageContainer className={'MessageBox-item'} isMine={props.isMine}>
        <MessageContent isMine={props.isMine}>
          {!props.isMine ? (
            <UserName isMine={props.isMine}>{props.userName}</UserName>
          ) : (
            <></>
          )}
          <Text>
            {props.text}
            <Timestamp isMine={props.isMine}>
              <TimeCaption>
                {props.timestamp ? props.timestamp : ''}
              </TimeCaption>
              <CheckedArrows isMine={props.isMine} isViewed={props.isViewed}>
                <CheckOutlined />
                <CheckOutlined style={{ marginLeft: '-8px' }} />
              </CheckedArrows>
            </Timestamp>
          </Text>
        </MessageContent>
      </MessageContainer>
    </>
  );
};

MessageBox.propTypes = {
  userName: PropTypes.string,
  isMine: PropTypes.bool,
  isViewed: PropTypes.bool,
  text: PropTypes.string,
  title: PropTypes.string,
  timestamp: PropTypes.string,
  backgroundColor: PropTypes.string,
};
export default React.memo(MessageBox);
