import { priceFormatter } from '../../../utils/priceFormatter';
import { currencyFormatter } from '../../../utils/currencyFormatter';

export const KpTableSum = (data, colorTableSum) => {
  if (data !== undefined) {
    const html = `${data
      .map(
        (
          preTask,
          index,
        ) => `<table cellspacing="0" cellpadding="0" style="width: 97%;margin: 0 0 1em">
${
  index === 0
    ? `<thead style="color: ${colorTableSum?.colorTextHead} !important;"><tr style="height: 34px;background-color: ${colorTableSum?.colorHead};">
<th style="height: 34px;padding: 5px; font-size: 13px;text-align: center;border-right-width: 1pt;border-right-color: #FFFFFF;border-right-style: solid;">Маршрут (услуга)</th>
<th style="height: 34px;padding: 5px; font-size: 13px;text-align: center; white-space: nowrap;">Стоимость перевозки</th>
</tr></thead>`
    : ''
}
<tbody style="color: ${colorTableSum.colorTextBody} !important;"> ${
          preTask.shoulderPars?.length > 0
            ? preTask.shoulderPars
                .map(
                  (item) => `<tr style="height: 34px;background-color: ${
                    colorTableSum?.colorBody
                  };text-align: center; white-space: nowrap;
    border-left-width: 1pt;
    border-left-color: #FFFFFF;
    border-right-style: solid;
    border-right-width: 1pt;
    border-right-color: #FFFFFF;">
    <td style="color: ${
      colorTableSum.colorTextBody
    } !important; width: 436px;max-width: 436px; padding: 5px; font-size: 13px; border-right-style: solid;border-right-width: 1pt;border-right-color: #FFFFFF;vertical-align: middle">${
                    item?.shoulderName
                  } ${
                    item?.transportTask
                      ? item.transportTask.map(
                          (transport) => transport?.typeShort,
                        )
                      : ''
                  }</td><td style="color: ${
                    colorTableSum.colorTextBody
                  } !important;width: 220px;max-width: 220px;font-size: 13px;vertical-align: middle;"> ${
                    item.transport_unit?.length > 0
                      ? item.transport_unit.map(
                          (transport) => transport.type_transport,
                        ) +
                        ' ' +
                        '-'
                      : ''
                  } ${priceFormatter(item.price)} ${
                    item.currency ? currencyFormatter(item.currency) : ''
                  } ${
                    item.vat_rate !== null && item.vat_rate !== undefined
                      ? 'НДС' + ' ' + item.vat_rate + '%'
                      : 'Без НДС'
                  }</td></tr>`,
                )
                .join('')
            : ''
        }</tbody> </table>`,
      )
      .join('')}`;
    return html;
  } else {
    return ` <h2>Таблица не сгенерирована</h2>`;
  }
};
