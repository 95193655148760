import { Card, Descriptions, Skeleton } from 'antd';

const AccountProfileDetails = (props) => {
  return (
    <Card
      style={{ borderRadius: '10px', overflow: 'hidden' }}
      bodyStyle={{ padding: '0' }}
    >
      <Skeleton loading={props.loading} shape={'round'}>
        <Descriptions
          title="Основная информация"
          layout={'vertical'}
          style={{
            backgroundColor: '#fff',
            padding: '15px',
          }}
        >
          <Descriptions.Item label="ФИО">
            {!props.loading && props.profileInfo && props.profileInfo.full_name
              ? props.profileInfo.full_name
              : 'Не указано'}
          </Descriptions.Item>
          <Descriptions.Item label="Контактный номер">
            {!props.loading &&
            props.profileInfo &&
            props.profileInfo.phone_number
              ? props.profileInfo.phone_number
              : 'Не указано'}
          </Descriptions.Item>
          <Descriptions.Item label="Email">
            {!props.loading && props.profileInfo && props.profileInfo.email
              ? props.profileInfo.email
              : 'Не указано'}
          </Descriptions.Item>
          <Descriptions.Item label="Телефония">
            {!props.loading && props.profileInfo && props.profileInfo.abonent
              ? props.profileInfo.abonent
              : 'Не указано'}
          </Descriptions.Item>
          <Descriptions.Item label="Дата создания">
            {!props.loading &&
            props.profileInfo &&
            props.profileInfo.date_joined
              ? props.profileInfo.date_joined.substr(0, 10)
              : 'Не указано'}
          </Descriptions.Item>
          <Descriptions.Item label="Права доступа">
            {!props.loading &&
            props.profileInfo &&
            props.profileInfo.groups &&
            props.profileInfo.groups.length > 0
              ? props.profileInfo.groups.map((group) => group.name).join(', ')
              : 'Не указано'}
          </Descriptions.Item>
          {props?.profileInfo?.signature && (
            <Descriptions.Item label="Подпись(email)">
              {
                <div
                  dangerouslySetInnerHTML={{
                    __html: props.profileInfo.signature,
                  }}
                />
              }
            </Descriptions.Item>
          )}
        </Descriptions>
      </Skeleton>
    </Card>
  );
};

export default AccountProfileDetails;
