import React, {
  useEffect,
  useState,
  useContext,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import {
  Avatar,
  Button,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Modal,
  Select,
  Space,
  Table,
  Tag,
  Tabs,
  Tooltip,
  Row,
  Col,
  Popconfirm,
  ConfigProvider,
} from 'antd';
import locale from 'antd/lib/locale/ru_RU';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import getInitials from '../../utils/getInitials';
import moment from 'moment';
import { restApiHost } from '../../utils/appVariables';
import EllipsisOutlined from '@ant-design/icons/lib/icons/EllipsisOutlined';
import { MaskedInput } from 'antd-mask-input';
import { userInformations } from '../../contex';
import { call } from '../../apiUtils/call';
import { getFiltersTable } from '../../utils/getFilters';
import SearchDataFilter from '../leeds/filters/SearchData';
import FilterDatePicker from '../leeds/filters/FilterDatePicker';
import { setPaginationSettings } from '../../localStorageUtils/paginationSettings';
import convertToSelectOptions from '../leeds/filters/filtersUtils/convertToSelectOptions';
import FilterSelect from '../leeds/filters/FilterSelect';
import MultipleSelectFromForm from '../UiComponents/selects/MultipleSelectFromForm';
import {
  prepareColumnsForResizing,
  sortingColumnsTable,
} from '../settingTable/sortingColumnsTable';
import SettingTableColumnsDrawer from '../settingTable/SettingTableColumnsDrawer';
import { ErrorBoundary } from '../ErrorBoundary';
import { callGetProfileInfo } from '../account/callGetProfileInfo';
import PermissionsTable from '../permissions/PermissionsTable';
import useAsyncEffect from 'use-async-effect';
import ResizableTableColumnHeader from '../table/ResizableTableColumnHeader';
import filterActiveColumns from '../table/utils';
import changeWidthStyleMainTable from '../table/changeWidthStyleMainTable';
import { editTableColumn } from '../../logic/profileInfo/mainTable/MainTableApiFunctions';
import useStateRef from 'react-usestateref';
import { getProfileInfo } from '../../logic/account/AccountsApiFunctions';
import ScrollingLoadedList from '../mobile/scrollingLoadedList/ScrollingLoadedList';
import StaffListItem from '../mobile/staff/list/StaffListItem';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import { userInfoHaveViewAllBranch } from '../../logic/account/utils';
import UniversalFiltersModal from '../mobile/filtersModal/UniversalFiltersModal';

const { Option } = Select;
const { TabPane } = Tabs;

const StaffTable = ({
  data,
  handleUpdate,
  totalStaff,
  loading,
  tableFilters,
  pageProps,
  setLoading,
  visibleFilters,
  visibleTableSetting,
  onCloseDrawerTableSetting,
  activeTab,
  setActiveTab,
  branchList,
  handleGetBranchList,
  setVisibleFilters,
  scrollingLoadedListRef,
  handleScrollToTopMobileList,
}) => {
  const tableName = 'staff';
  const [staffEditForm] = Form.useForm();
  const [disableEditBtn, setDisableEditBtn] = useState(true);
  const [showEditStaff, setShowEditStaff] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState([]);
  const [currEditStaff, setCurrEditStaff] = useState(null);
  const [groupList, setGroupList] = useState([]);
  const [totalGroup, setTotalGroup] = useState([]);
  const [page, setPage] = useState(1);
  const { userInfo } = useContext(userInformations);
  const [params, setParams] = useState(null);
  const [columnFiltersFromTableComponent, setColumnFiltersFromTableComponent] =
    useState([]);
  const [baseColumns, setBaseColumns, baseColumnsRef] = useStateRef([]);
  const mainTableRef = useRef(null);
  const isMobile = useCheckMobileScreen();

  const authPassEmailOptions = [
    {
      title: 'Выдан',
      id: true,
    },
    {
      title: 'Не выдан',
      id: null,
    },
  ];
  useEffect(() => {
    const col = sortingColumnsTable(
      userInfo,
      tableName,
      columns,
      visibleFilters,
    );
    const preparedColumnsForResizing = prepareColumnsForResizing(
      col,
      handleResize,
    );
    setBaseColumns(preparedColumnsForResizing);
  }, [visibleFilters]);

  useAsyncEffect(async () => {
    await getGroupList();
  }, []);

  useEffect(() => {
    if (tableFilters) {
      const newFilters = {
        ...tableFilters?.filter,
        filter: {
          ...tableFilters?.filter,
          search: tableFilters?.filter?.search,
        },
      };
      setParams(newFilters);
    } else {
      setParams(null);
      setColumnFiltersFromTableComponent([]);
    }
  }, [tableFilters]);

  useEffect(() => {
    if (pageProps) {
      setPage(pageProps);
    }
  }, [pageProps]);

  useEffect(() => {
    if (currEditStaff) {
      staffEditForm.setFieldsValue({
        full_name: currEditStaff.full_name,
        email: currEditStaff.email,
        phone_number: currEditStaff.phone_number,
        branch: currEditStaff.branch?.id,
        staff_position: currEditStaff.groups.map((group) => group.id),
      });
      setSelectedStaff(currEditStaff.groups.map((group) => group.id));
    }
  }, [currEditStaff]);

  const getGroupList = async () => {
    try {
      const url = `${restApiHost}/accounts/groups/`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      setGroupList(json.data);
      setTotalGroup(json?.count ? json.count : 0);
    } catch (e) {
      console.log('group list fetch err', e);
      message.error('Ошибка запрос на получение групп');
    }
  };

  const menu = (staff) => {
    return (
      <Menu style={{ width: 200 }}>
        <Menu.Item
          icon={<EditOutlined />}
          onClick={() => {
            setCurrEditStaff(staff);
            setShowEditStaff(true);
            handleGetBranchList();
          }}
        >
          Редактировать
        </Menu.Item>
        {userInfo.current.id !== staff.id ? (
          <Popconfirm
            title="Вы действительно хотите удалить сотрудника?"
            onConfirm={async () => {
              await deleteStaff(staff.id);
            }}
            okText="Да"
            cancelText="Нет"
            placement="rightBottom"
          >
            <Menu.Item icon={<DeleteOutlined />}>Удалить</Menu.Item>
          </Popconfirm>
        ) : (
          <></>
        )}
      </Menu>
    );
  };

  const updateUserInfo = async (userInfoForCallApi) => {
    setLoading(true);
    const profileInfo = await callGetProfileInfo(userInfoForCallApi);
    const haveViewBranch =
      profileInfo?.data?.permissions.some(
        (o) => o.codename === 'switch_branch',
      ) &&
      profileInfo?.data?.permissions.some((o) => o.codename === 'view_branch');
    if (haveViewBranch) {
      profileInfo['haveViewBranch'] = true;
    }
    userInfo.current = profileInfo;
    const col = sortingColumnsTable(
      userInfo,
      tableName,
      columns,
      visibleFilters,
    );
    const preparedColumnsForResizing = prepareColumnsForResizing(
      col,
      handleResize,
    );
    setBaseColumns(preparedColumnsForResizing);
    setLoading(false);
  };

  const filteringColumnsChildrenPillarByVisible = useMemo(() => {
    const copyColumns = [...baseColumns];
    return filterActiveColumns(copyColumns);
  }, [baseColumns]);

  const mainTableWidth = useMemo(() => {
    const copyColumns = [...baseColumns];
    let filteredColumns = filterActiveColumns(copyColumns);
    return filteredColumns.reduce((n, { width }) => n + width, 0);
  }, [baseColumns]);

  const getMainTableWidth = useCallback(() => {
    return mainTableWidth;
  }, [mainTableWidth]);

  useEffect(() => {
    if (mainTableRef.current.children[0]) {
      changeWidthStyleMainTable(mainTableRef, mainTableWidth);
    }
  }, [mainTableWidth]);

  const handleResize = useCallback(
    (index) =>
      async (e, { size }) => {
        const copyColumns = [...baseColumnsRef.current];

        setBaseColumns((columns) => {
          const nextColumns = [...columns];
          const children = nextColumns[index]?.children
            ? // eslint-disable-next-line no-unsafe-optional-chaining
              [...nextColumns[index]?.children]
            : null;
          if (children && children[0]?.width) {
            children[0].width = size;
          }
          nextColumns[index] = {
            ...nextColumns[index],
            width: size,
            children: children ? [...children] : [],
          };
          return nextColumns;
        });

        const databaseColumnsId = copyColumns[index]?.idInDatabase;
        if (!databaseColumnsId) return;

        await editTableColumn(databaseColumnsId, { width: size }, userInfo);
        const res = await getProfileInfo(userInfo);
        if (res) {
          userInfo.current = res;
          const haveViewBranch = userInfoHaveViewAllBranch(res);
          if (haveViewBranch) {
            userInfo.current['haveViewBranch'] = true;
          }
        }
      },
    [baseColumnsRef.current],
  );

  const columns = [
    {
      title: '',
      name: 'Действия',
      key: 'action',
      width: 50,
      uniqueIndex: 'action',
      index: 1,
      align: 'center',
      ellipsis: {
        showTitle: false,
      },
      render: (staff) => (
        <Space size="middle">
          <Dropdown overlay={menu(staff)} trigger={['hover']}>
            <EllipsisOutlined style={{ cursor: 'pointer', fontSize: '24px' }} />
          </Dropdown>
        </Space>
      ),
    },
    {
      title: 'ФИО',
      key: 'full_name__search',
      width: 350,
      ellipsis: {
        showTitle: false,
      },
      uniqueIndex: 'full_name',
      index: 2,
      render: (data) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {data.full_name ? (
            <>
              <Avatar
                style={{ width: '32px', minWidth: 32, marginRight: '10px' }}
              >
                {getInitials(data.full_name)}
              </Avatar>
              <Tooltip placement="leftBottom" title={data.full_name}>
                <div className={'leed-comment'}>{data.full_name}</div>
              </Tooltip>
            </>
          ) : (
            <>
              <Avatar style={{ marginRight: '10px' }}>!</Avatar>
              <p style={{ margin: 0 }}>-</p>
            </>
          )}
        </div>
      ),
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'ФИО'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={columnFiltersFromTableComponent?.full_name__search}
              columnKey={'full_name__search'}
            />
          ),
          visible: visibleFilters,
          key: 'full_name__search',
          width: 350,
          ellipsis: {
            showTitle: false,
          },
          render: (data) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {data.full_name ? (
                <>
                  <Avatar style={{ width: '32px', marginRight: '10px' }}>
                    {getInitials(data.full_name)}
                  </Avatar>
                  <Tooltip placement="leftBottom" title={data.full_name}>
                    <div className={'leed-comment'}>{data.full_name}</div>
                  </Tooltip>
                </>
              ) : (
                <>
                  <Avatar style={{ marginRight: '10px' }}>!</Avatar>
                  <p style={{ margin: 0 }}>-</p>
                </>
              )}
            </div>
          ),
        },
      ],
    },
    {
      title: 'Почта',
      key: 'email__search',
      dataIndex: 'email',
      width: 250,
      uniqueIndex: 'email',
      index: 3,
      ellipsis: {
        showTitle: false,
      },
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Комментарий'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={columnFiltersFromTableComponent?.email__search}
              columnKey={'email__search'}
            />
          ),
          visible: visibleFilters,
          key: 'email__search',
          dataIndex: 'email',
          width: 250,
          ellipsis: {
            showTitle: false,
          },
        },
      ],
    },
    {
      title: 'Пароль почты',
      key: 'auth_pass_mail',
      dataIndex: 'auth_pass_mail',
      width: 150,
      uniqueIndex: 'auth_pass_mail',
      index: 4,
      ellipsis: {
        showTitle: false,
      },
      render: (auth_pass_mail) => (auth_pass_mail ? 'Выдан' : 'Не выдан'),
      children: [
        {
          title: (
            <FilterSelect
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={columnFiltersFromTableComponent?.auth_pass_mail}
              columnKey={'auth_pass_mail'}
              useSingleSelect={true}
              dataList={convertToSelectOptions(
                authPassEmailOptions,
                'item?.title',
              )}
            />
          ),
          visible: visibleFilters,
          key: 'auth_pass_mail',
          dataIndex: 'auth_pass_mail',
          width: 150,
          ellipsis: {
            showTitle: false,
          },
          render: (auth_pass_mail) => (auth_pass_mail ? 'Выдан' : 'Не выдан'),
        },
      ],
    },
    {
      title: 'Контактный номер',
      key: 'phone_number__search',
      dataIndex: 'phone_number',
      width: 200,
      uniqueIndex: 'phone_number',
      index: 5,
      ellipsis: {
        showTitle: false,
      },
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Контактный номер'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={
                columnFiltersFromTableComponent?.phone_number__search
              }
              columnKey={'phone_number__search'}
            />
          ),
          visible: visibleFilters,
          key: 'phone_number__search',
          dataIndex: 'phone_number',
          width: 200,
          ellipsis: {
            showTitle: false,
          },
        },
      ],
    },
    {
      title: 'Телефония',
      key: 'abonent__search',
      dataIndex: 'abonent',
      width: 200,
      uniqueIndex: 'abonent',
      index: 6,
      ellipsis: {
        showTitle: false,
      },
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Контактный номер'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={columnFiltersFromTableComponent?.abonent__search}
              columnKey={'abonent__search'}
            />
          ),
          visible: visibleFilters,
          key: 'abonent__search',
          dataIndex: 'abonent',
          width: 200,
          ellipsis: {
            showTitle: false,
          },
        },
      ],
    },
    {
      title: 'Права доступа',
      key: 'groups',
      dataIndex: 'groups',
      width: 300,
      ellipsis: {
        showTitle: false,
      },
      uniqueIndex: 'groups',
      index: 7,
      render: (groups) => (
        <>
          {groups.length > 0 ? (
            <Tooltip
              title={groups.map((item) => item.name).join(', ')}
              placement={'topLeft'}
            >
              {groups.map((group) => {
                return <Tag key={group.id}>{group.name}</Tag>;
              })}
            </Tooltip>
          ) : (
            'Не указано'
          )}
        </>
      ),
      children: [
        {
          title: (
            <FilterSelect
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={columnFiltersFromTableComponent?.groups}
              columnKey={'groups'}
              dataList={convertToSelectOptions(groupList, 'item?.name')}
            />
          ),
          visible: visibleFilters,
          key: 'groups',
          dataIndex: 'groups',
          width: 300,
          ellipsis: {
            showTitle: false,
          },
          render: (groups) => (
            <>
              {groups.length > 0 ? (
                <Tooltip
                  title={groups.map((item) => item.name).join(', ')}
                  placement={'topLeft'}
                >
                  {groups.map((group) => {
                    return <Tag key={group.id}>{group.name}</Tag>;
                  })}
                </Tooltip>
              ) : (
                'Не указано'
              )}
            </>
          ),
        },
      ],
    },
    {
      title: 'Дата создания',
      key: 'date_create__periodDate',
      dataIndex: 'date_joined',
      width: 229,
      uniqueIndex: 'date_joined',
      index: 8,
      ellipsis: {
        showTitle: false,
      },
      render: (date_joined) => (
        <>{date_joined ? moment(date_joined).format('DD/MM/YYYY') : '-'}</>
      ),
      children: [
        {
          title: (
            <FilterDatePicker
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={
                columnFiltersFromTableComponent?.date_create__periodDate
              }
              columnKey={'date_create__periodDate'}
            />
          ),
          visible: visibleFilters,
          key: 'date_create__periodDate',
          dataIndex: 'date_joined',
          width: 229,
          ellipsis: {
            showTitle: false,
          },
          render: (date_joined) => (
            <>{date_joined ? moment(date_joined).format('DD/MM/YYYY') : '-'}</>
          ),
        },
      ],
    },
  ];

  const staffEdit = async (values, id) => {
    try {
      const url = `${restApiHost}/accounts/staff_users/${id}/`;
      const json = await call(
        url,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(values),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      message.success('Успешно отредактировано!');
      staffEditForm.resetFields();
      setShowEditStaff(false);
      isMobile && handleScrollToTopMobileList();
      await handleUpdate(page, params, false, false);
    } catch (e) {
      console.log('edit staff err', e);
      message.error('Ошибка редактирования данных сотрудника!');
      setShowEditStaff(false);
    }
  };
  const deleteStaff = async (staffId) => {
    try {
      const url = `${restApiHost}/accounts/staff_users/${staffId}/`;
      const response = await call(
        url,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!response) return;

      message.success('Запись успешно удалена');
      isMobile && handleScrollToTopMobileList();
      await handleUpdate(page, params, false, false);
    } catch (e) {
      console.log('delete err', e);
      message.error(
        e.message ? e.message : 'Ошибка запроса на удаление сотрудника',
      );
    }
  };

  const handleChangeGroup = async (body) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async function (resolve, reject) {
      try {
        const url = `${restApiHost}/accounts/change_group/`;
        const json = await call(
          url,
          {
            method: 'POST',
            headers: {
              Authorization: `Token ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
          },
          userInfo.current.haveViewBranch,
        );

        if (!json) return;

        message.success(
          body?.action === 'add' ? 'Успешно добавлено' : 'Успешно удалено',
        );
      } catch (e) {
        console.log('change group err', e);
        message.error('Ошибка запроса на смену прав пользователя');
        reject();
      }
    });
  };

  const handleTableChange = (pagination, filtersTable, sorter) => {
    const { page, filters } = getFiltersTable(pagination, filtersTable, sorter);
    setPage(page);
    setColumnFiltersFromTableComponent(filtersTable);
    let newFilters = { ...filters };
    if (tableFilters?.filter?.search) {
      newFilters = {
        ...filters,
        filter: { ...filters.filter, search: tableFilters.filter.search },
      };
    }
    setParams(newFilters);
    handleUpdate(page, newFilters);
  };

  const tabChange = (tab) => {
    if (!tab) return;
    setActiveTab(tab);
  };

  return (
    <>
      <Tabs
        type="card"
        activeKey={activeTab}
        defaultActiveKey="1"
        onChange={tabChange}
      >
        <TabPane
          tabBarStyle={{ margin: '0px', position: 'relative' }}
          tab="Сотрудники"
          key="1"
        >
          <ErrorBoundary>
            <UniversalFiltersModal
              visibleModal={visibleFilters && isMobile}
              handleClose={() => {
                setVisibleFilters(false);
              }}
              resetFilters={() => {
                setVisibleFilters(false);
                handleUpdate(1, null, false, false);
              }}
              columnsList={columns}
            />
          </ErrorBoundary>

          <ConfigProvider locale={locale}>
            <div ref={mainTableRef}>
              {!isMobile ? (
                <Table
                  rowClassName={(record, index) =>
                    index % 2 === 0
                      ? 'black-text table-row-color'
                      : 'black-text table-row-white'
                  }
                  onRow={(record) => {
                    return {
                      onDoubleClick: () => {
                        setCurrEditStaff(record);
                        setShowEditStaff(true);
                        handleGetBranchList();
                      },
                    };
                  }}
                  components={{
                    header: {
                      cell: ResizableTableColumnHeader,
                    },
                  }}
                  onChange={(val) => {
                    handleTableChange(
                      { current: val?.current },
                      columnFiltersFromTableComponent,
                    );
                  }}
                  scroll={{ x: getMainTableWidth() }}
                  sticky={true}
                  bordered={true}
                  columns={filteringColumnsChildrenPillarByVisible}
                  dataSource={data}
                  loading={loading}
                  pagination={setPaginationSettings(totalStaff, page)}
                  size="small"
                />
              ) : (
                <ErrorBoundary>
                  <ScrollingLoadedList
                    dataList={data}
                    totalData={totalStaff}
                    loadMoreItems={async () => {
                      await handleUpdate(page + 1, params, false, true);
                    }}
                    scrollingLoadedListRef={scrollingLoadedListRef}
                    renderListItem={(item) => (
                      <StaffListItem
                        staff={item}
                        menuActions={(staff) => menu(staff)}
                      />
                    )}
                  />
                </ErrorBoundary>
              )}
            </div>
          </ConfigProvider>

          <Modal
            visible={showEditStaff}
            title="Редактирование сотрудника"
            onCancel={() => {
              setShowEditStaff(false);
              isMobile && handleScrollToTopMobileList();
              handleUpdate(page, params, false, false);
              staffEditForm.resetFields();
            }}
            footer={[
              <Button
                key="back"
                onClick={() => {
                  setShowEditStaff(false);
                  isMobile && handleScrollToTopMobileList();
                  handleUpdate(page, params, false, false);
                  staffEditForm.resetFields();
                }}
              >
                Закрыть
              </Button>,
              <Button
                key="submit"
                type="primary"
                disabled={disableEditBtn}
                onClick={() => {
                  // await closeLead(currLead.id)
                  staffEditForm.validateFields().then((values) => {
                    // staffEdit(values)
                    if (currEditStaff && currEditStaff.id) {
                      const body = values;
                      if (values?.auth_pass_mail?.length === 0) {
                        delete body.auth_pass_mail;
                      }
                      staffEdit(body, currEditStaff.id);
                    } else {
                      message.error('Непредвиденная ошибка!');
                    }
                  });
                }}
              >
                Сохранить
              </Button>,
            ]}
          >
            <Form
              form={staffEditForm}
              onValuesChange={() =>
                setTimeout(() => {
                  staffEditForm
                    .validateFields()
                    .then(() => setDisableEditBtn(false))
                    .catch(() => setDisableEditBtn(true));
                }, 0)
              }
              layout="vertical"
            >
              <Row gutter={15}>
                <Col lg={12} xs={24}>
                  <Form.Item
                    label="ФИО"
                    name={'full_name'}
                    rules={[{ required: true, message: 'Обязательное поле!' }]}
                  >
                    <Input
                      autoComplete={'off'}
                      placeholder="Введите ФИО контактного человека"
                    />
                  </Form.Item>
                </Col>
                <Col lg={12} xs={24}>
                  <Form.Item
                    label="Телефон (контактный номер)"
                    name={'phone_number'}
                  >
                    <MaskedInput
                      autoComplete={'off'}
                      mask="+1 (111) 111 11 11"
                      name="phone_number"
                      placeholder="+_ (___) ___ __ __"
                    />
                  </Form.Item>
                </Col>
                <Col lg={12} xs={24}>
                  <Form.Item
                    label="Email"
                    name={'email'}
                    rules={[{ required: true, message: 'Обязательное поле!' }]}
                  >
                    <Input
                      autoComplete={'off'}
                      placeholder="Введите почту сотрудника"
                    />
                  </Form.Item>
                </Col>
                <Col lg={12} xs={24}>
                  <Form.Item label="Пароль от почты сотрудника">
                    <Form.Item
                      noStyle
                      name={'auth_pass_mail'}
                      className={`${
                        currEditStaff?.auth_pass_mail ? 'visual-disabled' : ''
                      }`}
                      rules={[
                        {
                          required: !currEditStaff?.auth_pass_mail
                            ? true
                            : false,
                          message: !currEditStaff?.auth_pass_mail
                            ? 'Обязательное поле!'
                            : '',
                        },
                      ]}
                    >
                      <Input.Password
                        autoComplete={'off'}
                        placeholder="Введите пароль от почты сотрудника"
                      />
                    </Form.Item>
                    <span
                      style={{ color: '#f5222d', fontStyle: 'italic' }}
                    >{`*${
                      currEditStaff?.auth_pass_mail
                        ? 'Пароль выдан'
                        : 'Пароль не выдан'
                    }`}</span>
                  </Form.Item>
                </Col>
                {userInfo.current.haveViewBranch ? (
                  <Col lg={12} xs={24}>
                    <Form.Item
                      label="Филиал"
                      name={'branch'}
                      rules={[
                        { required: true, message: 'Обязательное поле!' },
                      ]}
                    >
                      <Select
                        getPopupContainer={(trigger) =>
                          isMobile ? trigger.parentNode : document.body
                        }
                        showSearch
                        placeholder="Филиал"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {branchList && branchList.length > 0 ? (
                          <>
                            {branchList.map((item, index) => {
                              return (
                                <Option
                                  key={index}
                                  data-value={JSON.stringify(item)}
                                  value={item.id}
                                >
                                  {item.name}
                                </Option>
                              );
                            })}
                          </>
                        ) : (
                          <Option disabled>Нет доступных вариантов</Option>
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                ) : (
                  ''
                )}

                <Col lg={24} xs={24}>
                  <Form.Item
                    label="Должность"
                    rules={[{ required: true, message: 'Обязательное поле!' }]}
                  >
                    <MultipleSelectFromForm
                      value={selectedStaff}
                      onChange={(value) => {
                        setSelectedStaff(value);

                        if (value.length < selectedStaff.length) {
                          let difference = [...selectedStaff].filter(
                            (x) => !value.includes(x),
                          );
                          handleChangeGroup({
                            group: difference[0],
                            user: currEditStaff.id,
                            action: 'delete',
                          }).catch(() => {
                            value = selectedStaff;
                            setSelectedStaff(value);
                          });
                        } else {
                          let difference = value.filter(
                            (x) => !selectedStaff.includes(x),
                          );
                          handleChangeGroup({
                            group: difference[0],
                            user: currEditStaff.id,
                            action: 'add',
                          }).catch(() => {
                            value = value.filter((x) => x !== difference[0]);
                            setSelectedStaff(value);
                          });
                        }
                      }}
                      options={
                        groupList?.length > 0 ? (
                          <>
                            {groupList.map((group) => {
                              return (
                                <Option key={group.id} value={group.id}>
                                  {group.name}
                                </Option>
                              );
                            })}
                          </>
                        ) : (
                          <Option value="disabled" disabled={true}>
                            Нет доступных вариантов
                          </Option>
                        )
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
          <ErrorBoundary>
            <SettingTableColumnsDrawer
              visible={visibleTableSetting}
              loading={false}
              columns={baseColumns}
              onCloseDrawer={async () => {
                onCloseDrawerTableSetting();
                await updateUserInfo(userInfo);
              }}
            />
          </ErrorBoundary>
        </TabPane>
        <TabPane tab="Права доступа" key="2">
          <ErrorBoundary>
            <PermissionsTable
              groupList={groupList}
              totalGroup={totalGroup}
              callGetGroup={async () => await getGroupList()}
            />
          </ErrorBoundary>
        </TabPane>
      </Tabs>
    </>
  );
};

export default StaffTable;
