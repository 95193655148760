import PropTypes from 'prop-types';
import './HeaderCalendarTaskDrawer.css';
import HeaderForm from './form/HeaderForm';
import { Tabs } from 'antd';
const { TabPane } = Tabs;

const HeaderCalendarTaskDrawer = (props) => {
  return (
    <>
      <div className={'headerCalendarTaskDrawer'}>
        <div className={'main-info'}>
          <HeaderForm
            form={props.form}
            editingItem={props.editingItem}
            editFields={props.editFields}
            callCreateEvent={() => props.callCreateEvent()}
            onCreateEventTag={async (tag) => props.onCreateEventTag(tag)}
            onDeleteEventTag={async (tag) => props.onDeleteEventTag(tag)}
            setEditFields={(val) => props.setEditFields(val)}
            setEditingTask={props.setEditingTask}
          />
        </div>
        <div className={'tabs'}>
          <Tabs type="card" defaultActiveKey="1">
            <TabPane tabBarStyle={{ margin: '0px' }} tab="Основная" key="1" />
          </Tabs>
        </div>
      </div>
    </>
  );
};

HeaderCalendarTaskDrawer.propTypes = {
  form: PropTypes.any,
  editingItem: PropTypes.object,
  editFields: PropTypes.bool,
  setEditFields: PropTypes.func,
  onCreateEventTag: PropTypes.func,
  onDeleteEventTag: PropTypes.func,
  callCreateEvent: PropTypes.func,
  setEditingTask: PropTypes.func,
};

export default HeaderCalendarTaskDrawer;
