import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Worker } from '@react-pdf-viewer/core';
import ru_RU from '@react-pdf-viewer/locales/lib/ru_RU.json';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { ErrorBoundary } from '../../ErrorBoundary';

const PdfViewerModal = ({ visible, setVisible, pdfFileUrl, width }) => {
  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;
  const [copyPdfFileUrl, setCopyPdfFileUrl] = useState(null);

  {
    /* useEffect в данном случаи нужны
        для перерисовки компонента,
        т.к ссылки на pdf
        могутбыть одинаковые*/
  }

  useEffect(() => {
    if (!visible) {
      setCopyPdfFileUrl(null);
    }
  }, [visible]);

  useEffect(() => {
    if (visible && pdfFileUrl) {
      console.log(pdfFileUrl);
      setCopyPdfFileUrl(pdfFileUrl);
    }
  }, [visible, pdfFileUrl]);

  const renderToolbar = () => (
    <Toolbar>
      {(slots) => {
        const {
          CurrentPageInput,
          Download,
          EnterFullScreen,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          Print,
          ShowSearchPopover,
          Zoom,
          ZoomIn,
          ZoomOut,
        } = slots;
        return (
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              width: '100%',
            }}
          >
            <div style={{ padding: '0px 2px' }}>
              <ShowSearchPopover />
            </div>
            <div style={{ padding: '0px 2px' }}>
              <ZoomOut />
            </div>
            <div style={{ padding: '0px 2px' }}>
              <Zoom />
            </div>
            <div style={{ padding: '0px 2px' }}>
              <ZoomIn />
            </div>
            <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
              <GoToPreviousPage />
            </div>
            <div style={{ padding: '0px 2px' }}>
              <CurrentPageInput /> / <NumberOfPages />
            </div>
            <div style={{ padding: '0px 2px' }}>
              <GoToNextPage />
            </div>
            <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
              <EnterFullScreen />
            </div>
            <div style={{ padding: '0px 2px' }}>
              <Download />
            </div>
            <div style={{ padding: '0px 2px' }}>
              <Print />
            </div>
          </div>
        );
      }}
    </Toolbar>
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
  });

  const renderError = (e) => {
    console.log(e);
    return (
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          justifyItems: 'center',
          alignItems: 'center',
        }}
      >
        <div>
          <h2 style={{ color: 'red' }}>PDF файл не найден</h2>
        </div>
      </div>
    );
  };
  return (
    <Modal
      width={width ? width : 900}
      bodyStyle={{ height: '100vh' }}
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      footer={[]}
    >
      <div style={{ marginTop: '15px', height: '100%' }}>
        {pdfFileUrl ? (
          <ErrorBoundary>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
              <Viewer
                fileUrl={`${copyPdfFileUrl}`}
                localization={ru_RU}
                plugins={[defaultLayoutPluginInstance, toolbarPluginInstance]}
                renderError={renderError}
              />
            </Worker>
          </ErrorBoundary>
        ) : (
          <></>
        )}
      </div>
    </Modal>
  );
};

export default PdfViewerModal;
