import { Box } from '@material-ui/core';

const BillsTab = () => {
  return (
    <Box sx={{ mt: 3 }}>
      <div>Счета</div>
    </Box>
  );
};
export default BillsTab;
