import React, { useContext, useEffect, useState } from 'react';
import { Header } from 'antd/es/layout/layout';
import PropTypes from 'prop-types';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { userInformations } from '../../../contex';
import { Col, Row, Badge, Button, Tooltip } from 'antd';
import {
  BellOutlined,
  MessageOutlined,
  CalendarOutlined,
} from '@ant-design/icons';
import { ErrorBoundary } from '../../ErrorBoundary';
import getNotificationList from '../../../logic/notifications/NotificationsApiFunctions';
import Logo from '../../Logo';
import SoundButtons from './SoundButtons';
import { editProfileInfo } from '../../../logic/profileInfo/ProfileInfoApiFunctions';
import getChatNotificationList from '../../../logic/notifications/ChatNotificationApiFunction';
import GlobalNotification from '../../notification/GlobalNotification';
import { useTypedSelector } from '../../../store/hooks/useTypedSelector';
import { globalStore } from '../../../store';
import { TodoActionTypes } from '../../../store/todo/chatNotification/chatNotificationTodo';
import { TodoNotificationCountActionTypes } from '../../../store/todo/notificationCount/notificationCountTodo';
import { ActionActiveChatIdTypes } from '../../../store/todo/activeChatId/activeChatId';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';
import MobileMenuButton from './MobileMenuButton';

const LayoutHeader = (props) => {
  const navigate = useNavigate();
  const [visibleDrawerNotifications, setVisibleDrawerNotifications] =
    useState(false);
  const [visibleDrawerChatNotifications, setVisibleDrawerChatNotifications] =
    useState(false);
  const { userInfo } = useContext(userInformations);
  const [notificationList, setNotificationList] = useState([]);
  const [chatNotificationList, setChatNotificationList] = useState([]);
  const chatNotificationCount = useTypedSelector(
    (state) => state.chatNotification.count,
  );
  const activeChatId = useTypedSelector((state) => state.activeChatId.chatId);
  const thereWasTaskCardOpening = useTypedSelector(
    (state) => state.activeChatId.thereWasTaskCardOpening,
  );
  const [notificationCount, setNotificationCount] = useState(0);
  const [, setPage] = useState(1);
  const [toggleSound, setToggleSound] = useState(userInfo?.current?.all_sound);
  const [toggleChatNotifications, setToggleChatNotifications] = useState(
    userInfo?.current?.chat_notifications,
  );
  const isMobile = useCheckMobileScreen();

  useEffect(async () => {
    await callGetNotificationsList(0, false, true);
    await callGetChatNotificationsList(0, true);
  }, []);

  useEffect(async () => {
    if (activeChatId === null && thereWasTaskCardOpening) {
      await callGetChatNotificationsList(0, true);
      console.log('callGetChatNotificationsList', thereWasTaskCardOpening);
      globalStore.dispatch({
        type: ActionActiveChatIdTypes.SET_WAS_TASK_CARD_OPENING,
        payload: false,
      });
    }
  }, [activeChatId, thereWasTaskCardOpening]);

  useEffect(async () => {
    if (visibleDrawerNotifications) {
      await callGetNotificationsList(0, false, true);
    }
  }, [visibleDrawerNotifications]);

  useEffect(async () => {
    if (visibleDrawerChatNotifications) {
      await callGetChatNotificationsList(0, true);
    }
  }, [visibleDrawerChatNotifications]);

  useEffect(() => {
    if (chatNotificationCount + notificationCount > 0) {
      globalStore.dispatch({
        type: TodoNotificationCountActionTypes.SET_COUNT_NOTIFICATION_COUNT,
        payload: chatNotificationCount + notificationCount,
      });
    } else {
      globalStore.dispatch({
        type: TodoNotificationCountActionTypes.SET_COUNT_NOTIFICATION_COUNT,
        payload: 0,
      });
    }
  }, [chatNotificationCount, notificationCount]);

  const handleOpenDrawerNotifications = () => {
    setVisibleDrawerNotifications(true);
  };

  const callGetNotificationsList = async (offset, onlyCount, noMergeArr) => {
    const res = await getNotificationList(offset, userInfo);
    if (res?.data?.length > 0 && !onlyCount) {
      setNotificationList((prevState) =>
        noMergeArr ? res.data : prevState.concat(res.data),
      );
    }

    if (res?.count !== null && res?.count !== undefined) {
      setNotificationCount(res.count);
    }
  };

  const callGetChatNotificationsList = async (offset, noMergeArr) => {
    const res = await getChatNotificationList(offset, userInfo);
    if (res?.data?.length > 0) {
      setChatNotificationList((prevState) =>
        noMergeArr ? res?.data : prevState.concat(res.data),
      );
    } else if (noMergeArr) {
      setChatNotificationList([]);
    }

    if (res?.count !== null && res?.count !== undefined) {
      globalStore.dispatch({
        type: TodoActionTypes.SET_COUNT,
        payload: res.count,
      });
    }
  };

  const handleCloseNotificationDrawer = async () => {
    setVisibleDrawerNotifications(false);
    await callGetNotificationsList(0, true, true);
    setPage(1);
    setNotificationList([]);
  };

  const handleCloseChatNotificationDrawer = async () => {
    console.log('handleCloseChatNotificationDrawer');
    setVisibleDrawerChatNotifications(false);
    await callGetChatNotificationsList(0, true);
  };

  const loadMoreNotifications = async () => {
    const offset = notificationList?.length;
    if (offset !== null && offset !== undefined) {
      await callGetNotificationsList(offset, false, false);
    }
  };

  const loadMoreChatNotification = async () => {
    const offset = chatNotificationList?.length;
    if (offset !== null && offset !== undefined) {
      await callGetChatNotificationsList(offset, false);
    }
  };

  const pushNewNotifications = (notificationData) => {
    if (!notificationData) return;
    setNotificationList((prevState) =>
      [{ ...notificationData, viewed: null }].concat(prevState),
    );
    setNotificationCount((prevState) => prevState + 1);
  };

  const removeNotificationFormList = (notificationId) => {
    if (!notificationId) return;
    setNotificationList((prevState) =>
      prevState.filter((item) => item.id !== notificationId),
    );
  };

  const removeAllNotificationsFromList = (useForChatNotification) => {
    if (useForChatNotification) {
      setChatNotificationList([]);
      globalStore.dispatch({
        type: TodoNotificationCountActionTypes.SET_COUNT_NOTIFICATION_COUNT,
        payload: 0,
      });
    }
    setNotificationList([]);
    setNotificationCount(0);
  };

  const handleToggleSound = async (use) => {
    setToggleSound(use);
    await editProfileInfo(userInfo.current?.id, { all_sound: use }, userInfo);
    userInfo.current['all_sound'] = use;
  };

  const handleToggleUseChatNotification = async (use) => {
    setToggleChatNotifications(use);
    await editProfileInfo(
      userInfo.current?.id,
      { chat_notifications: use },
      userInfo,
    );
    userInfo.current['chat_notifications'] = use;
  };

  const redirectToCalendar = async () => {
    navigate('calendar');
  };

  return (
    <>
      <Header className={'Header'}>
        <section className="Header-body">
          <Row justify={'space-between'}>
            <Col style={{ maxWidth: '100%' }}>
              <RouterLink to="/">
                <Logo
                  isMobile={isMobile}
                  visibleIconCompany={!props.closeFullMenu}
                />
              </RouterLink>
            </Col>
            <Col>
              <Row className={'Header-buttons-toolbar'}>
                <Col className={'Header-button-col'}>
                  <Badge
                    className={'Header-button Header-button-badge'}
                    count={notificationCount}
                    showZero={false}
                  >
                    <Button
                      shape="default"
                      size={isMobile ? 'small' : 'middle'}
                      onClick={handleOpenDrawerNotifications}
                      icon={<BellOutlined />}
                    />
                  </Badge>
                </Col>
                <Col className={'Header-button-col'}>
                  <Badge
                    className={'Header-button Header-button-badge'}
                    count={chatNotificationCount}
                    showZero={false}
                  >
                    <Button
                      shape="default"
                      size={isMobile ? 'small' : 'middle'}
                      onClick={() => {
                        setVisibleDrawerChatNotifications(true);
                      }}
                      icon={<MessageOutlined />}
                    />
                  </Badge>
                </Col>
                <Col className={'Header-button-col'}>
                  <SoundButtons
                    useSound={toggleSound}
                    isMobile={isMobile}
                    useChatNotifications={toggleChatNotifications}
                    handleSound={async (use) => {
                      await handleToggleSound(use);
                    }}
                    handleChangeUseChatNotifications={async (use) => {
                      await handleToggleUseChatNotification(use);
                    }}
                  />
                </Col>
                <Col className={'Header-button-col'}>
                  <Tooltip title={'Перейти в календарь'}>
                    <Button
                      shape="default"
                      size={isMobile ? 'small' : 'middle'}
                      onClick={redirectToCalendar}
                      icon={<CalendarOutlined />}
                    />
                  </Tooltip>
                </Col>
              </Row>
            </Col>
          </Row>
        </section>
        <MobileMenuButton
          handleMenu={() => {
            props.handleToggleMobileMenu();
          }}
        />
      </Header>

      <ErrorBoundary>
        <GlobalNotification
          toggleSound={toggleSound}
          useChatNotifications={toggleChatNotifications}
          visibleDrawerNotifications={visibleDrawerNotifications}
          visibleDrawerChatNotifications={visibleDrawerChatNotifications}
          notificationList={notificationList}
          chatNotificationList={chatNotificationList}
          loadMoreNotifications={async () => {
            await loadMoreNotifications();
          }}
          loadMoreChatNotification={async () => {
            await loadMoreChatNotification();
          }}
          setChatNotification={(notificationList) => {
            setChatNotificationList(notificationList);
          }}
          callGetNotificationsList={async (offset, onlyCount, noMerge) => {
            await callGetNotificationsList(offset, onlyCount, noMerge);
          }}
          callGetChatNotificationsList={async () => {
            await callGetChatNotificationsList(0, true);
          }}
          pushNewNotifications={(notificationData) => {
            pushNewNotifications(notificationData);
          }}
          removeNotificationFormList={(notificationId) => {
            removeNotificationFormList(notificationId);
          }}
          removeAllNotificationsFromList={(useForChatNotification) => {
            removeAllNotificationsFromList(useForChatNotification);
          }}
          handleCloseNotificationDrawer={async () => {
            await handleCloseNotificationDrawer();
          }}
          handleCloseChatNotificationDrawer={async () => {
            await handleCloseChatNotificationDrawer();
          }}
        />
      </ErrorBoundary>
    </>
  );
};

LayoutHeader.propTypes = {
  closeFullMenu: PropTypes.bool,
  handleToggleMobileMenu: PropTypes.func,
};

export default LayoutHeader;
