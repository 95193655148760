import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { ErrorBoundary } from '../../ErrorBoundary';
import DayOfWeek from './dayOfWeek/DayOfWeek';
import filtersTasksByDay from '../month/tasks/tasksUtils/filtersTasksByDay';

const Week = (props) => {
  const getWeekMonthByIndex = (indexWeek) => {
    if (props.month.length < indexWeek) return [];
    return props.month[indexWeek];
  };

  return (
    <>
      <Spin spinning={props.loading} style={{ top: '26%' }}>
        <div className={'calendar-wrapper'}>
          {getWeekMonthByIndex(props.indexCurrentWeekMonth)?.map(
            (dayWeek, index) => (
              <ErrorBoundary key={index}>
                <DayOfWeek
                  day={dayWeek}
                  tasks={filtersTasksByDay(dayWeek, props.tasks)}
                  index={index}
                  selectedStaff={props.selectedStaff}
                  goToCompetitionDate={(day) => {
                    props.goToCompetitionDate(day);
                  }}
                  handleEditTask={(task) => props.handleEditTask(task)}
                  handleCreateTask={(day, time) =>
                    props.handleCreateTask(day, time)
                  }
                />
              </ErrorBoundary>
            ),
          )}
        </div>
      </Spin>
    </>
  );
};

Week.propTypes = {
  month: PropTypes.any,
  indexCurrentWeekMonth: PropTypes.number,
  tasks: PropTypes.any,
  loading: PropTypes.bool,
  selectedStaff: PropTypes.number,
  goToCompetitionDate: PropTypes.func,
  handleEditTask: PropTypes.func,
  handleCreateTask: PropTypes.func,
};

export default Week;
