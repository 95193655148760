import PropTypes from 'prop-types';
import { Col, Form, Input, message, Row, Select } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import getLocations from '../../../../utils/getLocations';
import searchCities from '../../../../logic/refbook/cities/CitiesApiFunction';
import { userInformations } from '../../../../contex';
import useCheckMobileScreen from '../../../../hooks/useCheckMobileScreen';

const { Option } = Select;

const BasicInformationBranchStep = (props) => {
  const { userInfo } = useContext(userInformations);
  const [addressValue, setAddressValue] = useState('');
  const [waypoints, setWaypoints] = useState([]);
  const [cities, setCities] = useState([]);
  const isMobile = useCheckMobileScreen();

  useEffect(async () => {
    await searchLocation();
  }, []);

  useEffect(async () => {
    if (props.editingBranchItem?.city?.name) {
      await callSearchCites(props.editingBranchItem?.city?.name);
    } else {
      await callSearchCites();
    }
  }, [props.editingBranchItem]);

  const searchLocation = async (keyword) => {
    try {
      setAddressValue(keyword);
      const location = await getLocations(keyword ? keyword : null);
      if (location) {
        setWaypoints(location);
      }
    } catch (e) {
      console.log('search location err', e);
      message.error('Ошибка поиска адреса');
      setWaypoints([]);
    }
  };

  const callSearchCites = async (keyword) => {
    try {
      const cites = await searchCities(keyword ? keyword : null, userInfo);
      if (cites) {
        setCities(cites);
      }
    } catch (e) {
      console.log('search cites err', e);
      message.error('Ошибка поиска города');
    }
  };

  return (
    <>
      <Form
        form={props.form}
        className={'reset-margin-form'}
        layout={'vertical'}
      >
        <Row gutter={16}>
          <Col xs={24} lg={24}>
            <Row gutter={16}>
              <Col lg={6} xs={24}>
                <Form.Item
                  label={'Название'}
                  name={'name'}
                  rules={[{ required: true, message: 'Обязательное поле!' }]}
                >
                  <Input placeholder={'Название'} />
                </Form.Item>
              </Col>
              <Col lg={6} xs={24}>
                <Form.Item
                  label={'Город'}
                  name={'city'}
                  rules={[{ required: true, message: 'Обязательное поле!' }]}
                >
                  <Select
                    getPopupContainer={(trigger) =>
                      isMobile ? trigger.parentNode : document.body
                    }
                    placeholder={'Город'}
                    filterOption={false}
                    showSearch
                    allowClear={true}
                    onSearch={async (keyword) => {
                      await callSearchCites(keyword);
                    }}
                    onClick={async () => {
                      await callSearchCites();
                    }}
                  >
                    {cities?.length > 0 ? (
                      <>
                        {cities.map((city, index) => (
                          <Option key={index} value={city?.id}>
                            {city?.name}
                          </Option>
                        ))}
                      </>
                    ) : (
                      <>
                        <Option disabled>Нет доступных вариантов</Option>
                      </>
                    )}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={6} xs={24}>
                <Form.Item
                  label={'Адрес'}
                  name={'address'}
                  rules={[{ required: true, message: 'Обязательное поле!' }]}
                >
                  <Select
                    getPopupContainer={(trigger) =>
                      isMobile ? trigger.parentNode : document.body
                    }
                    placeholder={'Адрес'}
                    filterOption={false}
                    showSearch
                    allowClear={true}
                    onSearch={async (keyword) => {
                      await searchLocation(keyword);
                    }}
                    onChange={async () => {
                      await searchLocation();
                    }}
                  >
                    {waypoints.length > 0 ? (
                      <>
                        {waypoints.map((waypoint, index) => (
                          <Option key={index} value={waypoint?.value}>
                            {waypoint?.value}
                          </Option>
                        ))}
                      </>
                    ) : (
                      <>
                        {addressValue ? (
                          <Option value={addressValue}>{addressValue}</Option>
                        ) : (
                          <Option disabled>Нет доступных вариантов</Option>
                        )}
                      </>
                    )}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={6} xs={24}>
                <Form.Item
                  label={'Email'}
                  name={'email'}
                  rules={[{ required: true, message: 'Обязательное поле!' }]}
                >
                  <Input placeholder={'Email'} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default BasicInformationBranchStep;

BasicInformationBranchStep.propTypes = {
  editingBranchItem: PropTypes.any,
  form: PropTypes.any,
};
