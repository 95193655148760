import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Button,
  Table,
  message,
  Dropdown,
  Space,
  Menu,
  Popconfirm,
  Tooltip,
} from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  EllipsisOutlined,
} from '@ant-design/icons';
import { restApiHost } from '../../../utils/appVariables';
import { userInformations } from '../../../contex';
import { call } from '../../../apiUtils/call';
import { Box } from '@material-ui/core';
import {
  getCustomerCustomerWaypoints,
  searchCustomerInList,
} from '../../../logic/customer/CustomerApiFunctions';
import AddTransportationModal from './AddTransportationModal';
import { ErrorBoundary } from '../../ErrorBoundary';
import { getCustomerById } from '../../customer/customerApiFunctions/getCustomerById';
import CreateCustomerModal from '../../CreateCustomer/CreateCustomerModal';
import UpdateCustomerModal from '../UpdateCustomerModal';
import useAsyncEffect from 'use-async-effect';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';
import ScrollingLoadedList from '../../mobile/scrollingLoadedList/ScrollingLoadedList';
import CustomerWaypointListItem from '../../mobile/customers/modal/CustomerWaypointListItem';

const AddTransportation = ({
  customerId,
  mainCustomerData,
  customerContactPerson,
  clientHasCategorySkl,
  handleUpdate,
  zIndexModal,
}) => {
  const [customerWaypoints, setCustomerWaypoints] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [customersInStockList, setCustomersInStockList] = useState([]);
  const [editingItem, setEditingItem] = useState(null);
  const [visibleModalCreateStockCustomer, setVisibleModalCreateStockCustomer] =
    useState(false);
  const [visibleModalEditContactCustomer, setVisibleModalEditContactCustomer] =
    useState(false);
  const [currentStockCustomer, setCurrentStockCustomer] = useState(null);
  const [loading, setLoading] = useState(false);
  const { userInfo } = useContext(userInformations);
  const isMobile = useCheckMobileScreen();

  useEffect(async () => {
    await getCustomerList();
  }, []);

  useAsyncEffect(async () => {
    customerId && (await handleGetCustomerWaypoints(customerId));
  }, [customerId]);

  const handleGetCustomerWaypoints = useCallback(
    async (customerId) => {
      const res = await getCustomerCustomerWaypoints(customerId, userInfo);
      if (res?.data && typeof res?.data !== 'string') {
        setCustomerWaypoints(res.data);
      }
    },
    [setCustomerWaypoints, getCustomerCustomerWaypoints],
  );

  const menu = (waypoint) => {
    return (
      <Menu style={{ width: 190 }}>
        <Menu.Item
          icon={<EditOutlined />}
          onClick={() => {
            setEditingItem(waypoint);
            setVisibleModal(true);
          }}
        >
          Редактировать
        </Menu.Item>
        <Popconfirm
          title="Вы действительно хотите удалить запись?"
          onConfirm={() => deleteWayPoint(waypoint.id)}
          okText="Да"
          cancelText="Нет"
          placement="rightBottom"
        >
          <Menu.Item icon={<DeleteOutlined />}>Удалить</Menu.Item>
        </Popconfirm>
      </Menu>
    );
  };

  const columns = [
    {
      title: '',
      key: 'action',
      width: 50,
      align: 'center',
      visible: true,
      render: (waypoint) => (
        <Space size="middle">
          <Dropdown overlay={menu(waypoint)} trigger={['hover']}>
            <EllipsisOutlined style={{ cursor: 'pointer', fontSize: '24px' }} />
          </Dropdown>
        </Space>
      ),
    },
    {
      title: 'Контрагент на складе',
      visible: !clientHasCategorySkl,
      key: 'customer',
      dataIndex: 'storage_customer',
      ellipsis: {
        showTitle: false,
      },
      render: (storage_customer) =>
        storage_customer?.legal_name ? (
          <Tooltip placement={'top'} title={storage_customer.legal_name}>
            {storage_customer.legal_name}
          </Tooltip>
        ) : (
          <></>
        ),
    },
    {
      title: 'Контактное лицо',
      key: 'contactPerson',
      visible: !clientHasCategorySkl,
      children: [
        {
          title: 'Контакт',
          key: 'contact',
          width: 150,
          dataIndex: 'contact_person',
          render: (contact_person) =>
            contact_person?.full_name ? (
              <Tooltip title={contact_person.full_name}>
                {contact_person.full_name}
              </Tooltip>
            ) : (
              <></>
            ),
        },
        {
          title: 'Телефон',
          key: 'tel',
          width: 150,
          dataIndex: 'contact_person',
          render: (contact_person) =>
            contact_person?.phone_number ? (
              <Tooltip title={contact_person.phone_number}>
                {contact_person.phone_number}
              </Tooltip>
            ) : (
              <></>
            ),
        },
      ],
    },
    {
      title: 'Адрес',
      key: 'address',
      visible: true,
      dataIndex: 'address',
      ellipsis: {
        showTitle: false,
      },
      render: (address) =>
        address ? (
          <Tooltip placement={'top'} title={address}>
            {address}
          </Tooltip>
        ) : (
          <></>
        ),
    },
  ];

  const addTransportation = async (body) => {
    try {
      const url = `${restApiHost}/customers/waypoint_and_customer/`;
      const json = await call(
        url,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      message.success('Пункт успешно добавлен!');
      customerId && (await handleGetCustomerWaypoints(customerId));
      setVisibleModal(false);
      setCurrentStockCustomer(null);
    } catch (e) {
      console.log('add waypoint err', e);
      message.error('Ошибка запроса на добавление пункта');
    }
  };

  const editTransportation = async (id, body) => {
    if (!id) return;
    try {
      const url = `${restApiHost}/customers/waypoint_and_customer/${id}/`;
      const json = await call(
        url,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      message.success('Пункт успешно обновлен!');
      customerId && (await handleGetCustomerWaypoints(customerId));
      setEditingItem(null);
      setVisibleModal(false);
      setCurrentStockCustomer(null);
    } catch (e) {
      console.log('edit waypoint err', e);
      message.error('Ошибка запроса на редактирование пункта');
    }
  };

  const deleteWayPoint = async (id) => {
    try {
      const url = `${restApiHost}/customers/waypoint_and_customer/${id}/`;
      const json = await call(
        url,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      customerId && (await handleGetCustomerWaypoints(customerId));

      message.success('Пункт успешно удален!');
    } catch (e) {
      console.log('delete waypoint err', e);
      message.error('Ошибка запроса на удаление пункта');
    }
  };

  const getCustomerList = async () => {
    const responseCustomers = await searchCustomerInList(userInfo, null);
    if (responseCustomers?.data) {
      setCustomersInStockList(responseCustomers.data);
    }
  };

  const callSearchCustomerList = async (keyword) => {
    if (!keyword) return;
    const responseCustomers = await searchCustomerInList(
      userInfo,
      null,
      keyword,
    );
    if (responseCustomers?.data) {
      setCustomersInStockList(responseCustomers.data);
    }
  };

  const addCustomerToListOptionsSelect = async (
    storageCustomerId,
    customersListInStock,
  ) => {
    const includeCustomer = customersListInStock.find(
      (item) => item?.id === storageCustomerId,
    );
    if (!includeCustomer) {
      const customerInfo = await getCustomerById(storageCustomerId, userInfo);
      setCustomersInStockList([...customersListInStock, customerInfo]);
    }
  };

  const openModalCreateStockCustomer = () => {
    setVisibleModalCreateStockCustomer(true);
  };

  const openModalEditContactPersonCustomer = () => {
    setVisibleModalEditContactCustomer(true);
  };

  const handleCancelModalCreateStockCustomer = async () => {
    setVisibleModalCreateStockCustomer(false);
    await getCustomerList();
  };

  const getColumnsTable = (columns) => {
    return columns.filter((item) => item.visible === true);
  };

  const getCustomer = async (customerId) => {
    setLoading(true);
    const customerData = await getCustomerById(customerId, userInfo);
    if (customerData) {
      setCurrentStockCustomer(customerData);
    }
    setLoading(false);
  };

  return (
    <>
      <Button onClick={() => setVisibleModal(true)}>Добавить</Button>
      <Box pt={2}>
        {!isMobile ? (
          <Table
            rowClassName={(record, index) =>
              index % 2 === 0
                ? 'black-text table-row-color'
                : 'black-text table-row-white'
            }
            size="small"
            pagination={{ size: 'middle', showSizeChanger: false }}
            columns={getColumnsTable(columns)}
            dataSource={customerWaypoints}
            bordered={true}
            onRow={(record) => {
              return {
                onDoubleClick: () => {
                  setEditingItem(record);
                  setVisibleModal(true);
                },
              };
            }}
          />
        ) : (
          <>
            <div style={{ paddingTop: '5px' }}>
              <ErrorBoundary>
                <ScrollingLoadedList
                  dataList={customerWaypoints}
                  totalData={customerWaypoints?.length}
                  loadMoreItems={async () => {
                    //handleUpdate(page + 1, filters, false,true)
                  }}
                  renderListItem={(item) => (
                    <CustomerWaypointListItem
                      customerWaypoint={item}
                      clientHasCategorySkl={clientHasCategorySkl}
                      menuActions={(manager) => menu(manager)}
                    />
                  )}
                />
              </ErrorBoundary>
            </div>
          </>
        )}
      </Box>
      <ErrorBoundary>
        <AddTransportationModal
          visible={visibleModal}
          editingItem={editingItem}
          customerId={customerId}
          customerInStock={customersInStockList}
          customerContactPerson={customerContactPerson}
          onCloseModal={async () => {
            setEditingItem(null);
            setVisibleModal(false);
            setCurrentStockCustomer(null);
            await handleGetCustomerWaypoints(customerId);
          }}
          clientHasCategorySkl={clientHasCategorySkl}
          currentStockCustomer={currentStockCustomer}
          clearCurrentStockCustomer={() => {
            setCurrentStockCustomer(null);
          }}
          loading={loading}
          zIndexModal={zIndexModal}
          addTransportation={async (body) => {
            await addTransportation(body);
          }}
          editTransportation={async (editingItemId, body) => {
            await editTransportation(editingItemId, body);
          }}
          callSearchCustomerInStockList={async (keyword) =>
            await callSearchCustomerList(keyword)
          }
          getCustomerInStockList={async () => await getCustomerList()}
          addCustomerToListOptionsSelect={(storageCustomerId) =>
            addCustomerToListOptionsSelect(
              storageCustomerId,
              customersInStockList,
            )
          }
          getCustomerStockDataById={async (customerId) =>
            await getCustomer(customerId)
          }
          openModalCreateStockCustomer={() => {
            openModalCreateStockCustomer();
          }}
          openModalEditContactPersonCustomer={(customerId) => {
            openModalEditContactPersonCustomer(customerId);
          }}
        />
      </ErrorBoundary>

      <ErrorBoundary>
        <CreateCustomerModal
          visible={visibleModalCreateStockCustomer}
          defaultCategoriesClientArr={['skl']}
          handleCancel={async () => {
            await handleCancelModalCreateStockCustomer();
          }}
          handleOk={async () => {
            await handleCancelModalCreateStockCustomer();
          }}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <UpdateCustomerModal
          onlyUsedToAddSigner={true}
          visible={visibleModalEditContactCustomer}
          getDataCustomer={async (customerId) => await getCustomer(customerId)}
          handleOk={async () => {
            setVisibleModalEditContactCustomer(false);
            currentStockCustomer?.id
              ? await getCustomer(currentStockCustomer?.id)
              : handleUpdate(customerId);
          }}
          handleCancel={async () => {
            setVisibleModalEditContactCustomer(false);
            currentStockCustomer?.id
              ? await getCustomer(currentStockCustomer?.id)
              : handleUpdate(customerId);
          }}
          currentStep={() => 1}
          loading={false}
          data={currentStockCustomer ? currentStockCustomer : mainCustomerData}
        />
      </ErrorBoundary>
    </>
  );
};

export default AddTransportation;
