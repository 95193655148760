export function getShortTemplate(template) {
  switch (template) {
    case 'Авто':
      return 'АТ';
    case 'Импорт':
      return 'ИП';
    case 'Экспорт':
      return 'ОП-Э';
    case 'Отправление':
      return 'ОП';
    case 'Отпр.-Порожний':
      return 'ОП-П';
    case 'Предоставление вагона':
      return 'ВГ';
    case 'Предоставление платформы':
      return 'ПП';
    case 'Прибытие':
      return 'ПР';
    case 'Продажа контейнера':
      return 'CS';
    case 'Ремонт':
      return 'RP';
    case 'Склад - Склад':
      return 'Ск-Ск';
    case 'Франшиза':
      return 'ФР';
    default:
      return 'Ошибка';
  }
}
