import PropTypes from 'prop-types';
import { getTodayClass } from '../../month/dayOfMonth/getTodayClass';
import { getMonthName } from '../../calendarUtils/getMonthName';

const DayOfWeekHeader = (props) => {
  return (
    <>
      <div className={'week-day-header-wrapper'}>
        <div>{props.day.format('dd').toUpperCase()}</div>
        {props.day.date() === 1 ? (
          <div style={{ fontWeight: 'bold', textAlign: 'center' }}>
            <div
              onClick={() => {
                if (!props.useForDayViewCalendar) {
                  props.goToCompetitionDate(props.day);
                }
              }}
              style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
              className={getTodayClass(
                props.day.format('DD-MM-YY'),
                props.useForDayViewCalendar,
              )}
            >
              <div>{props.day.date()}</div>
              <div style={{ marginLeft: '5px' }}>
                {getMonthName(props.day.month())}
              </div>
            </div>
            <div>
              {props.timePeriodTitle ? `(${props.timePeriodTitle})` : ''}
            </div>
          </div>
        ) : (
          <div
            onClick={() => {
              if (!props.useForDayViewCalendar) {
                props.goToCompetitionDate(props.day);
              }
            }}
            className={getTodayClass(
              props.day.format('DD-MM-YY'),
              props.useForDayViewCalendar,
            )}
          >
            {props.day.date()}
          </div>
        )}
      </div>
    </>
  );
};
DayOfWeekHeader.propTypes = {
  day: PropTypes.any,
  useForDayViewCalendar: PropTypes.bool,
  goToCompetitionDate: PropTypes.func,
};

export default DayOfWeekHeader;
