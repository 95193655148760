import PropTypes from 'prop-types';

const Logo = (props) => (
  <>
    {props.visibleIconCompany ? (
      <img
        className={'Logo-company-preview if-size-pc'}
        width="35"
        alt="Logo"
        src={'../static/svg/logo/qt_small_second_green.svg'}
        {...props}
      />
    ) : (
      <></>
    )}
    <img
      className={'Logo-company-name if-size-pc'}
      style={{ marginBottom: '-3px' }}
      width="90"
      alt="Logo"
      src={'../static/svg/logo/qt_title_green.svg'}
      {...props}
    />
    <img
      className={'Logo-company-name-double if-size-pc'}
      style={{ marginLeft: '8px' }}
      width="110"
      alt="Logo"
      src={'../static/svg/logo/qt_full_white.svg'}
      {...props}
    />
    <img
      className={'Logo-company-name-double-mobile if-size-not-pc'}
      width="110"
      alt="Logo"
      src={'../static/svg/logo/qt_full_green.svg'}
      {...props}
    />
  </>
);

Logo.propTypes = {
  visibleIconCompany: PropTypes.bool.isRequired,
};
export default Logo;
