import React, { useContext, useEffect, useState } from 'react';
import { restApiHost } from '../../utils/appVariables';
import { message, Spin } from 'antd';
import { Card } from '@material-ui/core';
import { userInformations } from '../../contex';
import { call } from '../../apiUtils/call';

const LeedsStatTable = (props) => {
  const [loading, setLoading] = useState(false);
  const [leads, setLeads] = useState([]);
  const [totalLeads, setTotalLeads] = useState(0);
  const { userInfo } = useContext(userInformations);

  useEffect(async () => {
    await getLeedsList();
  }, []);

  const getLeedsList = async (page = 1) => {
    setLoading(true);
    try {
      const url = `${restApiHost}/leads/list/?page=${page}`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );
      if (!json) return;
      setLeads(json?.data ? json.data : []);
      setTotalLeads(json?.count);
      setLoading(false);
    } catch (e) {
      console.log('fetch leed error', e);
      message.error('Ошибка загрузки списка лидов');
      setLoading(false);
    }
  };

  const getStatusFromServer = (status) => {
    switch (status) {
      case 'all':
        return <div className={'lead-status orange'}>Отправлены КП</div>;
      case 'arc':
        return <div className={'lead-status'}>Стратегический архив</div>;
      case 'clo':
        return <div className={'lead-status red'}>Отказ</div>;
      case 'cus':
        return <div className={'lead-status green'}>Клиент</div>;
      case 'new':
        return <div className={'lead-status blue'}>Новый лид</div>;
      case 'par':
        return <div className={'lead-status'}>Отправлена часть КП</div>;
      case 'sen':
        return <div className={'lead-status'}>Отправлено КП</div>;
      case 'wor':
        return <div className={'lead-status yellow'}>В работе</div>;
      default:
        return <div className={'lead-status'}>Неизвестный статус</div>;
    }
  };

  const renderStatLeads = (data) => {
    return data.map((lead) => {
      const date = lead.date_create ? new Date(lead.date_create) : null;
      return (
        <div key={lead.id} className={'leads-list__item'}>
          <div className="leads-list-block">
            <div className="leads-list__date">
              {date
                ? new Intl.DateTimeFormat('ru-RU').format(date)
                : 'Не указано'}
            </div>
            <div className="leads-list__name">
              {lead.company_name || lead.email || lead.contact_person}
            </div>
          </div>

          <div className="leads-list__status">
            {getStatusFromServer(lead.status)}
          </div>
        </div>
      );
    });
  };

  return (
    <Card {...props} style={{ maxHeight: '500px', overflowY: 'hidden' }}>
      <Spin
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
        spinning={loading}
      >
        <div className="leads-header">
          <span>Последние лиды</span>
          <span>{totalLeads} всего</span>
        </div>
        <div className="leads-list">
          {leads && leads.length > 0 && renderStatLeads(leads)}
        </div>
      </Spin>
    </Card>
  );
};

export default LeedsStatTable;
