import PropTypes from 'prop-types';
import Calendar from 'react-calendar';
import './miniCalendar.css';
import { useEffect, useState } from 'react';
import { getDate } from '../../email/new/EmailListItem';

const MiniCalendar = (props) => {
  const [currentDate, setCurrentDate] = useState(props.currentDate);
  const [activeStartDate, setActiveStartDate] = useState(props.currentDate);

  useEffect(() => {
    setCurrentDate(props.currentDate);
    setActiveStartDate(props.currentDate);
  }, [props.currentDate]);

  const onActiveStartDateChangeHandler = ({ activeStartDate }) => {
    setActiveStartDate(activeStartDate);
  };

  const checkIfTaskExist = ({ date }) => {
    const flag = props.tasks.find(
      (tDate) => getDate(tDate.end) === getDate(date),
    );
    return flag ? <span className={'MiniCalendar-tile-event-exists'} /> : null;
  };

  return (
    <>
      <Calendar
        tileClassName={'MiniCalendar-tile'}
        tileContent={checkIfTaskExist}
        locale={'ru-RU'}
        value={currentDate}
        activeStartDate={activeStartDate}
        onActiveStartDateChange={onActiveStartDateChangeHandler}
        onChange={(value) => props.switchCalendarCurrentDate(value)}
      />
    </>
  );
};

MiniCalendar.propTypes = {
  currentDate: PropTypes.any,
  switchCalendarCurrentDate: PropTypes.func,
  tasks: PropTypes.any,
};

export default MiniCalendar;
