import React from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Button as ButtonAntd } from 'antd';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const ExcelExport = ({ dataSet, fileName }) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (dataSet, fileName) => {
    const ws = XLSX.utils.json_to_sheet(dataSet);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  return (
    <ButtonAntd
      onClick={() => exportToCSV(dataSet, fileName)}
      disabled={!(dataSet && dataSet.length > 0)}
      style={{ margin: '0 5px' }}
      type="text"
      icon={<DownloadOutlined />}
    />
  );
};

export default ExcelExport;
