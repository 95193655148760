import { TodoActionNotificationCount } from '../todo/notificationCount/notificationCountTodo';

const initialState = {
  count: 0,
};

export const notificationCountReducer = (
  state = initialState,
  action: TodoActionNotificationCount,
) => {
  switch (action.type) {
    case 'SET_COUNT_NOTIFICATION_COUNT':
      return { count: action.payload };
    default:
      return state;
  }
};
