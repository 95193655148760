import React, { useCallback, useContext, useEffect, useState } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Modal,
  Row,
  Col,
  Select,
  Upload,
  Input,
  message,
  Collapse,
} from 'antd';
import { restApiHost } from '../../utils/appVariables';
import moment from 'moment';
import 'moment/locale/ru';
import CreationOfKpModal from '../sendKpOrPresentation/CreationOfKpModal';
import { convertObjFilterToUrl } from '../../utils/getFilters';
import { call } from '../../apiUtils/call';
import { userInformations } from '../../contex';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import MultipleSelectUseSearch from '../UiComponents/selects/MultipleSelectUseSearch';
import { ErrorBoundary } from '../ErrorBoundary';
import { showConfirm } from '../UiComponents/showConfirn/showConfirn';
import * as ClassicEditor from '../../ckeditor';
import formatBodyMessage from '../../utils/formatBodyMessage';
import { deleteEmailMessage } from '../../logic/email/EmailApiFunctions';

const { Option } = Select;
const { Dragger } = Upload;
const { Panel } = Collapse;

const hash = require('object-hash');

const SendMessageModal = ({
  open,
  profileInfo,
  //sendAllEmails,
  handleClose,
  activeFolder,
  back,
  inReplyTo,
  listEmails,
  selectedSendObj,
  getMessagesFolders,
  sendForContract,
  contractFiles,
  defaultValueForSelectEmail,
  sendContractForApproval,
}) => {
  const [form] = Form.useForm();
  const [contacts /*setContacts*/] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [loadingSend, setLoadingSend] = useState(false);
  const { userInfo } = useContext(userInformations);
  const [emailHtml, setEmailHtml] = useState('');

  /*attachKp*/
  const [loadingPreTasks, setLoadingPreTasks] = useState(false);
  const [preTasks, setPreTasks] = useState([]);
  const [totalPreTasks, setTotalPreTasks] = useState(0);
  const [visibleAttachKpModal, setVisibleAttachKpModal] = useState(false);
  const [preTaskTablePage, setPreTaskTablePage] = useState(1);
  const [filtersTablePreTasks, setFiltersTablePreTasks] = useState(null);
  const [fileHashes, setFileHashes] = useState([]);

  useEffect(() => {
    if (!open) {
      form.resetFields();
      setEmailHtml('');
      setFileList([]);
    }
  }, [open]);

  useEffect(() => {
    if (!selectedSendObj && profileInfo && activeFolder !== 'Drafts') {
      if (userInfo.current?.signature) {
        setEmailHtml(userInfo.current.signature);
      }
    }
  }, [userInfo, open, selectedSendObj]);

  useEffect(() => {
    if (sendForContract && contractFiles) {
      const arrFile = [...fileList];
      for (const file of contractFiles) {
        if (file) {
          arrFile.push(file);
        }
      }
      setFileList(arrFile);
    }
  }, [sendForContract, contractFiles]);

  useEffect(() => {
    form.setFieldsValue({
      emails: defaultValueForSelectEmail ? defaultValueForSelectEmail : [],
    });
  }, [defaultValueForSelectEmail]);

  const extractEmailFromStr = (str) => {
    if (!str) return;
    const emailPattern = /<([^>]+)>/;
    const match = str.match(emailPattern);

    return match ? match[1] : str;
  };

  useEffect(() => {
    if (selectedSendObj) {
      form.setFieldsValue({
        emails:
          activeFolder !== 'Sent' && activeFolder !== 'Drafts'
            ? [extractEmailFromStr(selectedSendObj.from_email)] ?? [
                selectedSendObj.from_email,
              ]
            : selectedSendObj.to_emails?.length &&
              selectedSendObj.to_emails.map((item) =>
                extractEmailFromStr(item),
              ),
        subject: selectedSendObj.subject,
        cc: selectedSendObj?.cc?.length ? [...selectedSendObj.cc] : [],
        bcc: selectedSendObj?.bcc?.length ? [...selectedSendObj.bcc] : [],
      });
      if (selectedSendObj?.html_body) {
        const date = selectedSendObj?.date
          ? `${moment(selectedSendObj?.date).format('L')}, ${moment(
              selectedSendObj?.date,
            ).format('LT')}`
          : `${moment().format('L')}, ${moment().format('LT')}`;
        const name = `"${
          selectedSendObj?.from_email
        }" &#60;${extractEmailFromStr(selectedSendObj.from_email)}&#62;`;
        let html = ``;
        if (profileInfo && activeFolder !== 'Drafts') {
          if (profileInfo?.signature) {
            html += profileInfo.signature;
          }
        }
        html +=
          activeFolder !== 'Drafts'
            ? `<br><br>${date}, ${name}:<blockquote>${formatBodyMessage(
                selectedSendObj?.html_body,
              )}</blockquote>`
            : formatBodyMessage(selectedSendObj?.html_body);
        setEmailHtml(html);
      }
    } else {
      form.resetFields();
    }
  }, [selectedSendObj, listEmails, profileInfo]);

  const putDownThatTheCPWasSent = async () => {
    try {
      const url = `${restApiHost}/leads/statistic/cp_sended`;
      const res = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });
      if (res.status === 500 || res.status === 400 || res.status === 404) {
        message.error('Ошибка запроса на подтверждение прикрепления КП');
        return;
      }
    } catch (e) {
      console.log(e);
      message.error('Ошибка запроса на поиск контактов');
    }
  };

  /* const handleSearchContacts = useCallback(
    async (keyword = null) => {
      const res = await getEmailContact(userInfo, keyword);
      setContacts(res ?? []);
    },
    [setContacts],
  );*/

  const props = {
    beforeUpload: () => false,
    onChange({ file, fileList }) {
      if (file.status !== 'uploading') {
        setFileList(fileList);
      }
    },
    multiple: true,
    defaultFileList: fileList,
    fileList,
  };

  const createHashInFile = async (file) => {
    try {
      const hashFile = await hash(file, {
        algorithm: 'md5',
        encoding: 'base64',
      });
      if (hashFile) {
        return hashFile;
      }
    } catch (e) {
      console.log(e);
      message.error('Ошибка при создании hash файла');
    }
  };
  const checkFileIsStillAttached = () => {
    let attachment = false;
    if (fileList?.length > 0 && fileHashes?.length > 0) {
      for (const file of fileList) {
        for (const fileHash of fileHashes) {
          if (file?.hash === fileHash) {
            attachment = true;
            break;
          }
        }
      }
    }
    return attachment;
  };

  const setFileToFileList = async (file) => {
    if (file) {
      const fileHash = await createHashInFile(file);
      const fileListCopy = fileList;
      const copyFileHashes = fileHashes;
      if (fileHash) {
        copyFileHashes.push(fileHash);
        setFileHashes(copyFileHashes);
      }
      fileListCopy.push({
        originFileObj: file,
        name: file?.name,
        hash: fileHash ? fileHash : null,
      });
      setFileList(fileListCopy);
    }
    message.success('PDF прикреплен.');
  };

  const sendEmail = async (values, isDraft = false) => {
    setLoadingSend(true);
    try {
      const formData = new FormData();
      for (let key in values) {
        if (
          (key === 'emails' && values[key]) ||
          (key === 'cc' && values[key]) ||
          (key === 'bcc' && values[key])
        ) {
          Array.isArray(values[key]) &&
            values[key]?.map((item) => formData.append(key, `${item}`));
        } else {
          values[key] && formData.append(key, `${values[key]}`);
        }
      }

      inReplyTo &&
        selectedSendObj.from_email &&
        formData.append(
          'in_reply_to ',
          `${extractEmailFromStr(selectedSendObj.from_email)}`,
        );

      isDraft && formData.append('draft', 'true');
      formData.append('footer', 'true');
      formData.append('html', emailHtml);

      /*selectedSendObj?.uid &&
        inReplyTo &&
        formData.append('in_reply_to', `${selectedSendObj?.uid}`);*/

      if (fileList.length > 0) {
        fileList.map((file) => {
          if (file.originFileObj) {
            formData.append('files', file.originFileObj, file.name);
          }
        });
      }

      const url = `${restApiHost}/mail/send/`;
      const json = await call(
        url,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
          body: formData,
        },
        userInfo.current?.haveViewBranch,
      );

      if (!json) return;

      const attachedFileRemains = checkFileIsStillAttached();
      if (attachedFileRemains) {
        await putDownThatTheCPWasSent();
        setFileHashes([]);
      }
      setFileList([]);
      form.resetFields();
      if (activeFolder === 'Drafts' && selectedSendObj?.uid) {
        await handleDeleteMessage(selectedSendObj?.uid);
        back && back();
      }
      !isDraft
        ? message.success('Письмо успешно отправлено!')
        : message.success('Черновик успешно создан!');

      getMessagesFolders && getMessagesFolders();
      handleClose();
    } catch (e) {
      console.log(e);
      message.error('Ошибка отправки письма');
    } finally {
      setLoadingSend(false);
    }
  };

  const handleDeleteMessage = useCallback(
    async (emailUid) => {
      try {
        await deleteEmailMessage(emailUid, activeFolder, userInfo);
      } catch (e) {
        console.log(e);
        message.error('Ошибка удаления черновика');
      }
    },
    [activeFolder],
  );

  const createDraft = async (values) => {
    try {
      await sendEmail(values, true);
    } catch (e) {
      console.log(e);
    }
  };

  const handleCancelModal = () => {
    form.resetFields();
    setEmailHtml('');
    setFileList([]);
    handleClose();
  };

  const handleCreateDraft = () => {
    form.validateFields().then((values) => createDraft(values));
  };

  /*attachKP*/

  const handleUpdate = async (page = 1, params, me) => {
    setLoadingPreTasks(true);
    setPreTaskTablePage(page);
    setFiltersTablePreTasks(params);
    const preTasks = await getPreTaskList(page, params, me);
    setPreTasks(preTasks?.preTask);
    setTotalPreTasks(preTasks?.total);
    setLoadingPreTasks(false);
  };

  const getPreTaskList = async (page = 1, params, me) => {
    let urlParams;

    if (params) {
      urlParams = convertObjFilterToUrl(params, null);
    }

    try {
      const url =
        urlParams && me
          ? `${restApiHost}/leads/pre_tasks/?filter=me&page=${page}${urlParams}`
          : urlParams && !me
          ? `${restApiHost}/leads/pre_tasks/?page=${page}${urlParams}`
          : !urlParams && me
          ? `${restApiHost}/leads/pre_tasks/?filter=me&page=${page}`
          : `${restApiHost}/leads/pre_tasks/?page=${page}`;

      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      return {
        preTask: json?.data ? json.data : [],
        total: json.count,
      };
    } catch (e) {
      console.log('fetch applications error', e);
    }
  };

  const openModalAttachKP = async () => {
    setLoadingPreTasks(true);
    setVisibleAttachKpModal(true);
    const preTasks = await getPreTaskList();
    setPreTasks(preTasks?.preTask);
    setTotalPreTasks(preTasks?.total);
    setLoadingPreTasks(false);
  };

  const closeModalAttachKp = () => {
    setVisibleAttachKpModal(false);
  };

  return (
    <Modal
      visible={open}
      title={'Отправка письма'}
      width={1000}
      onCancel={() => {
        if (!sendForContract && activeFolder !== 'Drafts') {
          showConfirm(
            handleCreateDraft,
            handleCancelModal,
            'Сохранить письмо как черновик?',
          );
        } else {
          handleCancelModal();
        }
      }}
      footer={[
        <Button
          key="close"
          onClick={() => {
            showConfirm(handleCreateDraft, handleCancelModal);
          }}
        >
          Закрыть
        </Button>,
        <Button
          key="back"
          type="primary"
          disabled={loadingSend}
          onClick={() => {
            form.validateFields().then((values) => {
              const body = {
                ...values,
                text_content: emailHtml,
                selectedFiles: fileList,
              };
              values && sendForContract
                ? sendContractForApproval(body)
                : sendEmail(values);
            });
          }}
          loading={loadingSend}
        >
          Отправить
        </Button>,
        // <Button
        //   onClick={() => {
        //     console.log(editorInstance?.current?.getData());
        //   }}
        // >
        //   +
        // </Button>,
      ]}
    >
      <Form form={form} className={'reset-margin-form'}>
        <Row gutter={15} className={'color-disabled'}>
          <Col lg={24} md={24} xs={24}>
            <Form.Item
              rules={[{ required: true, message: 'Обязательное поле!' }]}
              name={'emails'}
            >
              <MultipleSelectUseSearch
                onSearch={() => {}}
                placeholder="Кому"
                style={{
                  width: '100%',
                }}
                value={
                  defaultValueForSelectEmail ? defaultValueForSelectEmail : []
                }
                options={contacts.map((contact) => {
                  return (
                    <Option key={contact.email} value={contact.email}>
                      {contact.name} {`<${contact.email}>`}
                    </Option>
                  );
                })}
              />
            </Form.Item>
          </Col>

          <Col lg={24} md={24} xs={24}>
            <Form.Item name={'cc'}>
              <MultipleSelectUseSearch
                onSearch={() => {}}
                placeholder="Копия"
                style={{
                  width: '100%',
                }}
                options={contacts.map((contact) => {
                  return (
                    <Option key={contact.email} value={contact.email}>
                      {contact.name} {`<${contact.email}>`}
                    </Option>
                  );
                })}
              />
            </Form.Item>
          </Col>
          <Col lg={24} md={24} xs={24}>
            <Form.Item name={'bcc'}>
              <MultipleSelectUseSearch
                onSearch={() => {}}
                placeholder="Скрытая копия"
                style={{
                  width: '100%',
                }}
                options={contacts.map((contact) => {
                  return (
                    <Option key={contact.email} value={contact.email}>
                      {contact.name} {`<${contact.email}>`}
                    </Option>
                  );
                })}
              />
            </Form.Item>
          </Col>
          <Col lg={24} md={24} xs={24}>
            <Form.Item
              name={'subject'}
              rules={[{ required: true, message: 'Обязательное поле!' }]}
            >
              <Input placeholder={'Тема'} />
            </Form.Item>
          </Col>
          {!sendForContract ? (
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'flex-end',
                marginTop: '5px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  paddingRight: '7.5px',
                }}
              >
                <Button
                  key="back"
                  type="primary"
                  disabled={loadingSend}
                  onClick={async () => {
                    await openModalAttachKP();
                  }}
                  loading={loadingSend}
                >
                  Прикрепить КП
                </Button>
              </div>
            </div>
          ) : (
            <></>
          )}
          <Col lg={24} md={24} xs={24} style={{ margin: '10px 0 30px 0' }}>
            <Collapse defaultActiveKey={['1']} ghost>
              <Panel header="Загрузка файлов" key="1">
                <Dragger {...props}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Кликните или перенесите файл для загрузки
                  </p>
                </Dragger>
              </Panel>
            </Collapse>
          </Col>
          <Col lg={24} md={24} xs={24}>
            {!visibleAttachKpModal ? (
              <div className="email-editor">
                <CKEditor
                  editor={ClassicEditor}
                  data={emailHtml}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    if (data) {
                      setEmailHtml(data);
                    }
                  }}
                />
              </div>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </Form>
      <ErrorBoundary>
        <CreationOfKpModal
          visibleModal={visibleAttachKpModal}
          preTasks={preTasks}
          loading={loadingPreTasks}
          totalPreTask={totalPreTasks}
          pageTable={preTaskTablePage}
          tableFilters={filtersTablePreTasks}
          setFileToFileList={(file) => setFileToFileList(file)}
          closeVisibleModal={closeModalAttachKp}
          updatePreTask={(page, params, me) => handleUpdate(page, params, me)}
        />
      </ErrorBoundary>
    </Modal>
  );
};

export default SendMessageModal;
