import PropTypes from 'prop-types';
import { Button, Col, Form, Row, Select, Tooltip } from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';
import {
  getContactsCustomer,
  getCustomerCustomerWaypoints,
  searchCustomerInList,
} from '../../../logic/customer/CustomerApiFunctions';
import { userInformations } from '../../../contex';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { ErrorBoundary } from '../../ErrorBoundary';
import CreateCustomerModal from '../../CreateCustomer/CreateCustomerModal';
import UpdateCustomerModal from '../../UpdateCustomer/UpdateCustomerModal';
import { getCustomerById } from '../../customer/customerApiFunctions/getCustomerById';
import getLocations from '../../../utils/getLocations';

const { Option } = Select;

const WaypointRepresentativeForm = (props) => {
  const isMobile = useCheckMobileScreen();
  const { userInfo } = useContext(userInformations);
  const [customersList, setCustomersList] = useState([]);
  const [contactPersonSelectedCustomer, setContactPersonSelectedCustomer] =
    useState([]);
  const [, setDisableSaveBtn] = useState(false);
  const [representative, setRepresentative] = useState(null);
  const [contact, setContact] = useState(null);
  const [loading, setLoading] = useState(false);
  const [addressValue, setAddressValue] = useState(null);
  const [visibleModalCreateCustomer, setVisibleModalCreateCustomer] =
    useState(false);
  const [visibleModalUpdateCustomer, setVisibleModalUpdateCustomer] =
    useState(false);
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [locationsOptionsList, setLocationsOptionsList] = useState([]);

  const [resultForm, setResultForm] = useState({
    representative: null,
    contact: null,
  });

  useEffect(() => {
    return () => {
      setContact(null);
      setRepresentative(null);
      setContactPersonSelectedCustomer([]);
      setCustomersList([]);
      setLocationsOptionsList([]);
      setAddressValue(null);
      setResultForm({ representative: null, contact: null });
    };
  }, []);

  useEffect(() => {
    if (!props.visibleModal) {
      setContact(null);
      setRepresentative(null);
      setContactPersonSelectedCustomer([]);
      setCustomersList([]);
      setLocationsOptionsList([]);
      setAddressValue(null);
      setResultForm({ representative: null, contact: null });
    }
  }, [props.visibleModal]);

  useEffect(async () => {
    if (props.representative) {
      const resCustomers = await callSearchCustomerList(props.representative);
      if (resCustomers?.length && resCustomers[0] && resCustomers[0]?.id) {
        const customerId = resCustomers[0]?.id;
        customerId && setRepresentative([customerId]);
        customerId && (await handleGetCustomerContactPersons(customerId));
        customerId && (await handleGetCustomerWaypoints(customerId));
      } else {
        setRepresentative([props.representative]);
      }
      setResultForm((prevState) => ({
        ...prevState,
        representative: props.representative,
      }));
    } else {
      setRepresentative([]);
      await callSearchCustomerList();
      await handleGetLocationOptions();
    }
  }, [props.representative]);

  useEffect(() => {
    if (props.contact) {
      setContact([props.contact]);
      setResultForm((prevState) => ({
        ...prevState,
        contact: props.contact,
      }));
    } else {
      setContact([]);
    }
  }, [props.contact]);

  useEffect(() => {
    props.onChangeWaypointRepresentative &&
      props.onChangeWaypointRepresentative(resultForm);
  }, [resultForm]);

  const callSearchCustomerList = useCallback(async (keyword = null) => {
    const responseCustomers = await searchCustomerInList(
      userInfo,
      null,
      keyword,
    );
    if (responseCustomers?.data) {
      setCustomersList(responseCustomers.data);
      return responseCustomers.data;
    }
  }, []);

  const handleGetCustomerContactPersons = useCallback(
    async (customerId) => {
      try {
        setLoading(true);
        const res = await getContactsCustomer(customerId, userInfo);
        if (res?.data) {
          setContactPersonSelectedCustomer(res.data);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [getContactsCustomer],
  );

  const handleGetCustomerWaypoints = useCallback(
    async (customerId) => {
      const res = await getCustomerCustomerWaypoints(customerId, userInfo);
      setLocationsOptionsList(res.data ?? []);
    },
    [setLocationsOptionsList],
  );

  const onChangeSelectedStockCustomer = async (val, options) => {
    if (!val) {
      setRepresentative([]);
      setContactPersonSelectedCustomer([]);
      setContact([]);
      setResultForm(() => ({
        contact: null,
        representative: null,
      }));
      return;
    }

    props.mainForm && props.mainForm?.setFieldsValue({ address: '' });
    setAddressValue(null);

    const lastValue = val;

    setRepresentative([lastValue]);

    if (typeof lastValue === 'number' && options?.children) {
      setContact([]);
      setContactPersonSelectedCustomer([]);
      setResultForm(() => ({
        contact: null,
        representative: options?.children,
      }));
      await handleGetCustomerContactPersons(lastValue);
      await handleGetCustomerWaypoints(lastValue);
    }
  };

  const handleChangeContact = (val, options) => {
    if (!val) {
      setContact([]);
      setResultForm((prevState) => ({
        ...prevState,
        contact: null,
      }));
      return;
    }

    const lastValue = val;

    setContact([lastValue]);

    if (typeof lastValue === 'number' && options?.children) {
      setResultForm((prevState) => ({
        ...prevState,
        contact: options?.children,
      }));
    }
  };

  const handleClearVariables = async () => {
    setDisableSaveBtn(true);
    setContactPersonSelectedCustomer([]);
    setCustomersList([]);
  };

  const handleOpenModalCreateCustomer = useCallback(() => {
    setVisibleModalCreateCustomer(true);
  }, [setVisibleModalCreateCustomer]);

  const handleCancelModalCreateCustomer = useCallback(async () => {
    setVisibleModalCreateCustomer(false);
    await callSearchCustomerList();
  }, [setVisibleModalCreateCustomer]);

  const handleOpenModalEditContactPersonCustomer = useCallback(async () => {
    if (representative?.length > 0 && representative[0]) {
      await getCustomer(representative[0]);
      setVisibleModalUpdateCustomer(true);
    }
  }, [representative, setVisibleModalUpdateCustomer]);

  const handleCloseUpdateCustomerModal = useCallback(async () => {
    setVisibleModalUpdateCustomer(false);
    currentCustomer?.id &&
      (await handleGetCustomerContactPersons(currentCustomer.id));
  }, [setVisibleModalUpdateCustomer, currentCustomer]);

  const getCustomer = useCallback(
    async (customerId) => {
      const customerData = await getCustomerById(customerId, userInfo);
      if (customerData) {
        setCurrentCustomer(customerData);
      }
    },
    [setCurrentCustomer],
  );

  const handleGetLocationOptions = useCallback(
    async (value = null) => {
      if (!value) {
        setLocationsOptionsList([]);
        return;
      }
      const locations = await getLocations(value);
      setLocationsOptionsList(locations);
    },
    [setLocationsOptionsList],
  );

  const customerInSelected = () => {
    return representative?.length;
  };

  return (
    <>
      <Row>
        <Col xs={24} lg={24}>
          <div style={{ display: 'flex', gap: '0', alignItems: 'center' }}>
            <Form.Item style={{ width: '100%' }} label={'Контрагент'}>
              <Select
                getPopupContainer={(trigger) =>
                  isMobile ? trigger.parentNode : document.body
                }
                className={'storage-customer-select'}
                width={'100%'}
                filterOption={false}
                showSearch
                allowClear={true}
                onClear={async () => {
                  await handleClearVariables();
                }}
                value={representative}
                placeholder={'Контрагент'}
                onSearch={async (keyword) => {
                  await callSearchCustomerList(keyword);
                }}
                onChange={async (val, option) => {
                  await onChangeSelectedStockCustomer(val, option);
                }}
              >
                {customersList?.length > 0 && (
                  <>
                    {customersList.map((customer, index) => (
                      <Option key={index} value={customer?.id}>
                        {customer?.short_name ||
                          customer?.legal_name ||
                          'Юр.имя не задано'}
                      </Option>
                    ))}
                  </>
                )}
              </Select>
            </Form.Item>
            <Tooltip title={'Добавить контрагента'}>
              <Button
                style={{ top: '3px' }}
                onClick={() => handleOpenModalCreateCustomer()}
                autoFocus={false}
                type="primary"
                icon={<PlusOutlined />}
              />
            </Tooltip>
          </div>
        </Col>

        <Col xs={24} lg={24}>
          <Form.Item name={'address'} label={'Адрес'}>
            <Select
              getPopupContainer={(trigger) =>
                isMobile ? trigger.parentNode : document.body
              }
              filterOption={false}
              showSearch
              placeholder={'Адрес'}
              //disabled={!props.currentStockCustomer}
              onSearch={async (keyword) => {
                setAddressValue(keyword);
                if (!customerInSelected()) {
                  await handleGetLocationOptions(keyword);
                }
              }}
              onChange={async () => {
                if (!customerInSelected()) {
                  await handleGetLocationOptions();
                }
              }}
            >
              {locationsOptionsList.length > 0 ? (
                <>
                  {locationsOptionsList.map((waypoint, index) => (
                    <Option
                      key={index}
                      value={waypoint?.value || waypoint?.address}
                    >
                      {waypoint?.value || waypoint?.address}
                    </Option>
                  ))}
                </>
              ) : (
                <>
                  {addressValue ? (
                    <Option value={addressValue}>{addressValue}</Option>
                  ) : (
                    <Option disabled value={null}>
                      Нет доступных вариантов
                    </Option>
                  )}
                </>
              )}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} lg={24}>
          <div style={{ display: 'flex', gap: '0', alignItems: 'center' }}>
            <Form.Item style={{ width: '100%' }} label={'Контактное лицо'}>
              <Select
                getPopupContainer={(trigger) =>
                  isMobile ? trigger.parentNode : document.body
                }
                className={'storage-customer-select'}
                width={'100%'}
                filterOption={true}
                showSearch
                value={contact}
                allowClear={true}
                loading={loading}
                onChange={(value, option) => {
                  handleChangeContact(value, option);
                }}
                //disabled={contactPersonSelectedCustomer?.length <= 0}
                placeholder={'Контактное лицо'}
              >
                {contactPersonSelectedCustomer?.length > 0 && (
                  <>
                    {contactPersonSelectedCustomer.map(
                      (contactPerson, index) => (
                        <Option key={index} value={contactPerson?.id}>
                          {`${contactPerson?.full_name} | ${
                            contactPerson?.phone_number ||
                            contactPerson?.email ||
                            'телефон или почта не указаны'
                          }`}
                        </Option>
                      ),
                    )}
                  </>
                )}
              </Select>
            </Form.Item>
            <Tooltip title={'Перейти к контактам контрагента'}>
              <Button
                disabled={!representative?.length}
                onClick={async () => {
                  await handleOpenModalEditContactPersonCustomer();
                }}
                className={'additional-btn-select'}
                style={{ position: 'relative', top: '3px' }}
                autoFocus={false}
                type="primary"
                icon={<EditOutlined />}
              />
            </Tooltip>
          </div>
        </Col>
      </Row>
      <ErrorBoundary>
        <CreateCustomerModal
          visible={visibleModalCreateCustomer}
          handleCancel={async () => {
            await handleCancelModalCreateCustomer();
          }}
          handleOk={async () => {
            await handleCancelModalCreateCustomer();
          }}
        />
      </ErrorBoundary>

      <ErrorBoundary>
        <UpdateCustomerModal
          onlyUsedToAddSigner={true}
          visible={visibleModalUpdateCustomer}
          getDataCustomer={async (customerId) => await getCustomer(customerId)}
          handleOk={async () => {
            await handleCloseUpdateCustomerModal();
          }}
          handleCancel={async () => {
            await handleCloseUpdateCustomerModal();
          }}
          currentStep={() => 1}
          loading={false}
          data={currentCustomer}
        />
      </ErrorBoundary>
    </>
  );
};

WaypointRepresentativeForm.propTypes = {
  representative: PropTypes.any,
  contact: PropTypes.any,
  visibleModal: PropTypes.bool,
  mainForm: PropTypes.any,
  onChangeWaypointRepresentative: PropTypes.func,
};

export default WaypointRepresentativeForm;
