import { restApiHost } from '../../utils/appVariables';
import { call } from '../../apiUtils/call';
import { message } from 'antd';

export async function getListCustomerByCategory(userInfo, customerCategory) {
  if (!customerCategory) return;
  try {
    const url = `${restApiHost}/customers/list/?category=${customerCategory}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      },
      userInfo.current.haveViewBranch,
    );
    if (!json) return;
    return json;
  } catch (e) {
    console.log('call getListCustomerByCategory error', e);
    message.error('Ошибка запроса на получение контрагентов');
  }
}

export async function searchCustomerInList(
  userInfo,
  customerCategory,
  keyword,
) {
  const params = new URLSearchParams();
  if (keyword) {
    params.append('search', `${keyword}`);
  }
  if (customerCategory) {
    params.append('category', `${customerCategory}`);
  }
  try {
    const url = `${restApiHost}/customers/list/?${params}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      },
      userInfo.current.haveViewBranch,
    );
    if (!json) return;
    return json;
  } catch (e) {
    console.log('call searchCustomerInStockList error', e);
    message.error('Ошибка запроса на получение контрагентов');
  }
}

export async function editCustomer(customerId, body, userInfo) {
  try {
    const url = `${restApiHost}/customers/list/${customerId}/`;
    const json = await call(
      url,
      {
        method: 'PATCH',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      userInfo.current.haveViewBranch,
    );

    if (!json) return;
    message.success('Основная информация клиента успешно отредактирована!');
    return json;
  } catch (e) {
    console.log('edit customer err', e);
    message.error('Ошибка запроса на редактирование клиента');
  }
}

export async function getContactsCustomer(customerId, userInfo) {
  if (!customerId) return;
  try {
    const url = `${restApiHost}/customers/contact_persons/?customer=${customerId}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (json) {
      return json;
    }
  } catch (e) {
    message.error('Ошибка получение списка контактных лиц контрагента');
    console.log('getContactsCustomer err', e);
  }
}

export async function getCommPropHistoryCustomer(customerId, userInfo, page) {
  if (!customerId) return;
  try {
    const url = `${restApiHost}/customers/comm_prop_history/?customer=${customerId}&page=${page}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (json) {
      return json;
    }
  } catch (e) {
    message.error('Ошибка получение списка отправленых кп контрагента');
    console.log('getCommPropHistoryCustomer err', e);
  }
}

export async function getCustomerManagers(customerId, userInfo) {
  if (!customerId) return;
  try {
    const url = `${restApiHost}/customers/customer_manager/?customer=${customerId}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (json) {
      return json;
    }
  } catch (e) {
    message.error('Ошибка получение списка менеджеров контрагента');
    console.log('getCustomerManagers err', e);
  }
}

export async function getCustomerGroupCompanies(customerId, userInfo) {
  if (!customerId) return;
  try {
    const url = `${restApiHost}/customers/group_customer/?customer=${customerId}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (json) {
      return json;
    }
  } catch (e) {
    message.error('Ошибка получение списка групп компаний контрагента');
    console.log('getCustomerGroupCompanies err', e);
  }
}
export async function getCustomerCustomerWaypoints(customerId, userInfo) {
  if (!customerId) return;
  try {
    const url = `${restApiHost}/customers/waypoint_and_customer/?customer=${customerId}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (json) {
      return json;
    }
  } catch (e) {
    message.error('Ошибка получение списка транспортных пунктов контрагента');
    console.log('getCustomerCustomerWaypoints err', e);
  }
}
export async function createCustomerContactPersons(body, userInfo) {
  try {
    const url = `${restApiHost}/customers/contact_persons/`;
    const json = await call(
      url,
      {
        method: 'POST',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
        body: body,
      },
      userInfo.current.haveViewBranch,
    );

    if (json) {
      return json;
    }
  } catch (e) {
    console.log('createCustomerContactPersons err', e);
    message.error('Ошибка запроса на добавления контактного лица клиента');
  }
}

export async function getCustomerCustomerBanks(customerId, userInfo) {
  if (!customerId) return;
  try {
    const url = `${restApiHost}/customers/bank_details/?customer=${customerId}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (json) {
      return json;
    }
  } catch (e) {
    message.error('Ошибка получение списка банков контрагента');
    console.log('getCustomerCustomerBanks err', e);
  }
}

export async function getCustomerCustomerForeignBanks(customerId, userInfo) {
  if (!customerId) return;
  try {
    const url = `${restApiHost}/customers/bank_details_foreign/?customer=${customerId}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (json) {
      return json;
    }
  } catch (e) {
    message.error('Ошибка получение списка иностраных банков контрагента');
    console.log('getCustomerCustomerForeignBanks err', e);
  }
}

export async function getCustomerContractsList(customerId, userInfo) {
  if (!customerId) return;
  try {
    const url = `${restApiHost}/customers/contracts/?signer_customer__customer__id=${customerId}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (!json?.data) return;

    return json?.data;
  } catch (e) {
    message.error('Ошибка получение списка договоров клиента');
    console.log('get Customer Contracts List err', e);
  }
}
