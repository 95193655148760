import { Button, Card, List } from 'antd';
import { Trash2 } from 'react-feather';
import { restApiHost } from '../../../utils/appVariables';
import { call } from '../../../apiUtils/call';
import { useContext } from 'react';
import { userInformations } from '../../../contex';
import { Log } from '../../../types';

function get_time_diff(datetimeProps: string) {
  const datetime =
    typeof datetimeProps !== 'undefined'
      ? datetimeProps
      : '2014-01-01 01:02:03.123456';

  const new_datetime = new Date(datetime).getTime();
  const now = new Date().getTime();

  if (isNaN(new_datetime)) {
    return '';
  }

  let milisec_diff: null | number;

  if (new_datetime < now) {
    milisec_diff = now - new_datetime;
  } else {
    milisec_diff = new_datetime - now;
  }

  const days = Math.floor(milisec_diff / 1000 / 60 / (60 * 24));

  const date_diff = new Date(milisec_diff);

  return (
    days +
    'd ' +
    date_diff.getHours() +
    'h ' +
    date_diff.getMinutes() +
    'm ' +
    date_diff.getSeconds() +
    's'
  );
}

interface LoglogProps {
  log: Log;
  refreshLogs: () => void;
}

export default function Loglog({ log, refreshLogs }: LoglogProps) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const { userInfo } = useContext(userInformations);

  let event = undefined;
  let isjson = false;

  try {
    event = JSON.parse(log.event);
    isjson = true;
  } catch {
    event = log.event;
  }

  const deleteLog = () => {
    call(
      `${restApiHost}/administration/log/${log.id}/`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Token ${localStorage.getlog('token')}`,
        },
      },
      userInfo.current.haveViewBranch,
      null,
    ).then(() => {
      refreshLogs();
    });
  };

  return (
    <List.Item style={{ position: 'relative' }}>
      <Button
        onClick={deleteLog}
        style={{ position: 'absolute', right: '16px', top: '16px', zIndex: 9 }}
        type="text"
        danger
      >
        <Trash2 width={16} height={16} />
      </Button>
      <Card
        style={{ height: '100%' }}
        title={
          <div
            style={{ display: 'flex', flexDirection: 'column', height: '70px' }}
          >
            {isjson ? (
              <>
                <p style={{ margin: 0, marginBottom: 4 }}>
                  {event.exception_class_name}
                </p>
                <p style={{ margin: 0, marginBottom: 4, fontSize: 12 }}>
                  {event.method} {event.full_path}
                </p>
                <p style={{ fontSize: 12 }}>
                  {new Date(log.date).toLocaleDateString('ru', {
                    day: '2-digit',
                    month: '2-digit',
                    year: '2-digit',
                    hour: '2-digit',
                    second: '2-digit',
                  })}{' '}
                  | <span>{get_time_diff(log.date)} ago</span> |{' '}
                  <span style={{ color: '#c4c4c4' }}>ID: {log.id}</span>
                </p>
              </>
            ) : (
              <p style={{ fontSize: 12 }}>
                {new Date(log.date).toLocaleDateString('ru', {
                  day: '2-digit',
                  month: '2-digit',
                  year: '2-digit',
                  hour: '2-digit',
                  second: '2-digit',
                })}{' '}
                | <span>{get_time_diff(log.date)} ago</span> |{' '}
                <span style={{ color: '#c4c4c4' }}>ID: {log.id}</span>
              </p>
            )}
          </div>
        }
      >
        {isjson ? (
          Object.is(event.stack_trace, undefined) ? (
            <>
              <p style={{ fontWeight: 600, margin: 0 }}>Exception Arguments</p>
              <p>{event.exception_args}</p>
            </>
          ) : (
            <>
              <p style={{ fontWeight: 600, margin: 0 }}>Stack Trace</p>
              {event.stack_trace?.map((value: string, index: number) => (
                <p
                  key={index}
                  style={{
                    fontSize: value.startsWith('File') ? 16 : 12,
                    margin: 0,
                  }}
                >
                  {value}
                </p>
              ))}
            </>
          )
        ) : (
          <p>{event}</p>
        )}
      </Card>
    </List.Item>
  );
}
