import React, { useContext, useEffect, useState } from 'react';
import {
  ConfigProvider,
  Dropdown,
  Form,
  Menu,
  message,
  Popconfirm,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import EllipsisOutlined from '@ant-design/icons/lib/icons/EllipsisOutlined';
import locale from 'antd/lib/locale/ru_RU';
import { setPaginationSettings } from '../../../../localStorageUtils/paginationSettings';
import SearchDataFilter from '../../../leeds/filters/SearchData';
import { getFiltersTable } from '../../../../utils/getFilters';
import FilterDatePicker from '../../../leeds/filters/FilterDatePicker';
import RangeNumberFilters from '../../../leeds/filters/RangeNumberFilters';
import CreateContractModal from '../../../customer/CreateContractModal';
import { restApiHost } from '../../../../utils/appVariables';
import { call } from '../../../../apiUtils/call';
import { userInformations } from '../../../../contex';
import PdfViewerModal from '../../../contracts/templeteContract/PdfViewerModal';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import PaperClipOutlined from '@ant-design/icons/lib/icons/PaperClipOutlined';
import convertToSelectOptions from '../../../leeds/filters/filtersUtils/convertToSelectOptions';
import AddAttachmentModal from '../../../customer/attachments/AddAttachmentModal';
import FilterSelect from '../../../leeds/filters/FilterSelect';
import { ErrorBoundary } from '../../../ErrorBoundary';
import { getTempletesConstract } from '../../../contracts/getTempletesConstract';
import { getGeneratedContractPdfAndDocxByTemplateId } from '../../../contracts/getGeneratedContractPdfAndDocxByTemplateId';
import { exportFileFormUrl } from '../../../../utils/exportFileFormUrl';
import moment from 'moment';
import ContractModalMinimalInfoTheAbilityToAddAttachments from '../../../contracts/modal/ContractModalMinimalInfoTheAbilityToAddAttachments';
import { sendContractOnApproval } from '../../../contracts/sendContractOnApproval';
import { prepareAttachmentTypeForSelect } from '../../../contracts/attachments/prepareAttachmentTypeForSelect';
import UpdateCustomerModal from '../../UpdateCustomerModal';
import { getCustomerById } from '../../../customer/customerApiFunctions/getCustomerById';
import dayjs from 'dayjs';
import useCheckMobileScreen from '../../../../hooks/useCheckMobileScreen';
import ScrollingLoadedList from '../../../mobile/scrollingLoadedList/ScrollingLoadedList';
import ContractListItem from '../../../mobile/contracts/list/ContacrListItem';
import UniversalFiltersModal from '../../../mobile/filtersModal/UniversalFiltersModal';

const { Option } = Select;

const ContractStepTable = ({
  contractsList,
  customerId,
  loading,
  visibleFilters,
  page,
  total,
  tableFilters,
  updatePage,
  handleUpdate,
  defaultFilter,
  typeChoices,
  signerManagers,
  signerCustomers,
  visibleCreateDocModal,
  changeVisibleCreateContractModal,
  thisCreationContract,
  setThisCreationContract,
  allClients,
  getSignerCustomers,
  scrollingLoadedListRef,
  handleScrollToTopMobileList,
  setVisibleFilters,
}) => {
  const [columnFiltersFromTableComponent, setColumnFiltersFromTableComponent] =
    useState([]);
  const [copyFiltres, setCopyFiltres] = useState({});
  // const [visibleCreateDocModal, setVisibleCreateDocModal] = useState(false);
  const [visibleEditDocModal, setVisibleEditDocModal] = useState(false);
  const [disableDocBtn, setDisableDocBtn] = useState(true);
  const [createDocForm] = Form.useForm();
  const [editForm] = Form.useForm();
  const [editFormStatus, setEditFormStatus] = useState(null);
  const [pdfSendLoading, setPdfSendLoading] = useState(false);
  const { userInfo } = useContext(userInformations);
  const [uploadedDoc, setUploadedDoc] = useState(null);
  const [signerManager, setSignerManager] = useState(null);
  const [, setSignerCustomer] = useState(null);
  const [, setContactSend] = useState(null);
  const [attach] = useState(null);
  const [currentContract, setCurrentContract] = useState(null);
  const [
    disableDateBeginAndNumberAndStatus,
    setDisableDateBeginAndNumberAndStatus,
  ] = useState(false);
  const [visibleContractShowPdf, setVisibleContractShowPdf] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [currentFileForCreateAttachment, setCurrentFileForCreateAttachment] =
    useState([]);
  const [visibleAttachmentsModal, setVisibleAttachmentsModal] = useState(false);
  const [contractsStatuses, setContractsStatuses] = useState([]);
  const [docCommentChoices, setDocCommentChoices] = useState([]);
  const [contractPdfUrl, setContractPdfUrl] = useState(null);
  const [contractDocxUrl, setContractDocxUrl] = useState(null);
  const [
    createContractModalDraggerFileList,
    setCreateContractModalDraggerFileList,
  ] = useState([]);
  const [
    visibleSendEmailModalInCreateContractModal,
    setVisibleSendEmailModalInCreateContractModal,
  ] = useState(false);
  const [visibleUpdateCustomerModal, setVisibleUpdateCustomerModal] =
    useState(false);
  const [currentCustomerFullData, setCurrentCustomerFullData] = useState(null);
  const [
    usingUpdateCustomerModalWhenGeneratingContract,
    setUsingUpdateCustomerModalWhenGeneratingContract,
  ] = useState(false);
  const isMobile = useCheckMobileScreen();

  const propsDragger = {
    beforeUpload: () => false,
    async onChange({ file }) {
      if (file.status === 'removed') {
        await message.warning(
          'Удаление вложения договора, можно произвести в карточке контрагента в разделе "вложения"',
          2,
        );
        return;
      }
      fileSelection(file);
    },
    multiple: false,
    fileList,
  };
  const statusOptions = contractsStatuses.map((item) => {
    const itemEntries = item ? Object.entries(item)[0] : [];
    return {
      id: itemEntries[0] ? itemEntries[0] : null,
      title: itemEntries[1] ? itemEntries[1] : null,
    };
  });

  const typeContractsOptions = typeChoices?.contract_type
    ? Object.entries(typeChoices?.contract_type).map((item) => {
        return {
          title: item[1],
          id: item[0],
        };
      })
    : [];

  const typeBaseDocOptions = typeChoices?.base_doc
    ? Object.entries(typeChoices?.base_doc).map((item) => {
        return {
          title: item[1],
          id: item[0],
        };
      })
    : [];

  const columnsTable = [
    {
      title: '',
      key: 'action',
      width: 50,
      align: 'center',
      render: (contract) => (
        <Space size="middle">
          <Dropdown overlay={menu(contract)} trigger={['hover']}>
            <EllipsisOutlined style={{ cursor: 'pointer', fontSize: '24px' }} />
          </Dropdown>
        </Space>
      ),
    },
    {
      title: 'Наша компания',
      key: 'signer_manager__customer__legal_name__icontains',
      dataIndex: 'signer_manager',
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (signer_manager) =>
        signer_manager?.customer?.legal_name ? (
          <Tooltip
            placement={'topLeft'}
            title={signer_manager?.customer?.legal_name}
          >
            {signer_manager?.customer?.legal_name}
          </Tooltip>
        ) : null,
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Наша компания'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={
                columnFiltersFromTableComponent?.signer_manager__customer__legal_name__icontains
              }
              columnKey={'signer_manager__customer__legal_name__icontains'}
            />
          ),
          visible: visibleFilters,
          key: 'signer_manager__customer__legal_name__icontains',
          width: 200,
          dataIndex: 'signer_manager',
          ellipsis: {
            showTitle: false,
          },
          render: (signer_manager) =>
            signer_manager?.customer?.legal_name ? (
              <Tooltip
                placement={'topLeft'}
                title={signer_manager?.customer?.legal_name}
              >
                {signer_manager?.customer?.legal_name}
              </Tooltip>
            ) : null,
        },
      ],
    },
    {
      title: 'Номер договора',
      key: 'code_contract__icontains',
      width: 200,
      dataIndex: 'code_contract',
      ellipsis: {
        showTitle: false,
      },
      render: (code_contract) =>
        code_contract ? (
          <Tooltip placement={'topLeft'} title={code_contract}>
            {code_contract}
          </Tooltip>
        ) : null,
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Номер договора'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={
                columnFiltersFromTableComponent?.code_contract__icontains
              }
              columnKey={'code_contract__icontains'}
            />
          ),
          key: 'code_contract__icontains',
          width: 200,
          visible: visibleFilters,
          dataIndex: 'code_contract',
          ellipsis: {
            showTitle: false,
          },
          render: (code_contract) =>
            code_contract ? (
              <Tooltip placement={'topLeft'} title={code_contract}>
                {code_contract}
              </Tooltip>
            ) : null,
        },
      ],
    },
    {
      title: 'Статус',
      key: 'status',
      dataIndex: 'status',
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (status) => getStatusFromServer(status),
      children: [
        {
          title: (
            <FilterSelect
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={columnFiltersFromTableComponent?.status}
              columnKey={'status'}
              dataList={convertToSelectOptions(statusOptions, 'item?.title')}
            />
          ),
          visible: visibleFilters,
          key: 'status',
          dataIndex: 'status',
          width: 150,
          ellipsis: {
            showTitle: false,
          },
          render: (status) => getStatusFromServer(status),
        },
      ],
    },
    {
      title: 'Тип договора',
      key: 'type',
      dataIndex: 'type_str',
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (type) =>
        type ? (
          <Tooltip title={type} placement={'topLeft'}>
            {type}
          </Tooltip>
        ) : (
          'Без типа'
        ),
      children: [
        {
          title: (
            <FilterSelect
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={columnFiltersFromTableComponent?.type}
              columnKey={'type'}
              dataList={convertToSelectOptions(
                typeContractsOptions,
                'item?.title',
              )}
            />
          ),
          key: 'type',
          dataIndex: 'type_str',
          visible: visibleFilters,
          width: 200,
          ellipsis: {
            showTitle: false,
          },
          render: (type) =>
            type ? (
              <Tooltip title={type} placement={'topLeft'}>
                {type}
              </Tooltip>
            ) : (
              'Без типа'
            ),
        },
      ],
    },
    {
      title: 'Основание',
      key: 'signer_customer__base_doc',
      dataIndex: 'signer_customer',
      width: 150,
      uniqueIndex: 'base_company',
      index: 9,
      ellipsis: {
        showTitle: false,
      },
      render: (signer_customer) => {
        return signer_customer?.base_doc_str ? (
          <Tooltip title={signer_customer?.base_doc_str}>
            {signer_customer.base_doc_str}
          </Tooltip>
        ) : (
          <></>
        );
      },
      children: [
        {
          title: (
            <FilterSelect
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={
                columnFiltersFromTableComponent?.signer_customer__base_doc
              }
              columnKey={'signer_customer__base_doc'}
              dataList={convertToSelectOptions(
                typeBaseDocOptions,
                'item?.title',
              )}
            />
          ),
          visible: visibleFilters,
          key: 'signer_customer__base_doc',
          dataIndex: 'signer_customer',
          width: 150,
          ellipsis: {
            showTitle: false,
          },
          render: (signer_customer) => {
            return signer_customer?.base_doc_str ? (
              <Tooltip title={signer_customer?.base_doc_str}>
                {signer_customer.base_doc_str}
              </Tooltip>
            ) : (
              <></>
            );
          },
        },
      ],
    },
    {
      title: 'Подписавший (мы)',
      key: 'signer_manager__full_name__icontains',
      dataIndex: 'signer_manager',
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (signer_manager) =>
        signer_manager?.full_name ? (
          <Tooltip title={signer_manager.full_name}>
            {signer_manager.full_name}
          </Tooltip>
        ) : (
          <Tooltip title={signer_manager?.email || 'Не указано'}>
            {signer_manager?.email || 'Не указано'}
          </Tooltip>
        ),
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Подписавший (мы)'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={
                columnFiltersFromTableComponent?.signer_manager__full_name__icontains
              }
              columnKey={'signer_manager__full_name__icontains'}
            />
          ),
          visible: visibleFilters,
          key: 'signer_manager__full_name__icontains',
          dataIndex: 'signer_manager',
          width: 200,
          ellipsis: {
            showTitle: false,
          },
          render: (signer_manager) =>
            signer_manager?.full_name ? (
              <Tooltip title={signer_manager.full_name}>
                {signer_manager.full_name}
              </Tooltip>
            ) : (
              <Tooltip title={signer_manager?.email || 'Не указано'}>
                {signer_manager?.email || 'Не указано'}
              </Tooltip>
            ),
        },
      ],
    },
    {
      title: 'Комментарий',
      key: 'comment__icontains',
      dataIndex: 'comment',
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (comment) => {
        return comment ? (
          <Tooltip placement={'left'} title={comment}>
            {comment}
          </Tooltip>
        ) : (
          <></>
        );
      },
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Комментарий'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={columnFiltersFromTableComponent?.comment__icontains}
              columnKey={'comment__icontains'}
            />
          ),
          visible: visibleFilters,
          key: 'comment__icontains',
          dataIndex: 'comment',
          width: 150,
          ellipsis: {
            showTitle: false,
          },
          render: (comment) => {
            return comment ? (
              <Tooltip placement={'left'} title={comment}>
                {comment}
              </Tooltip>
            ) : (
              <></>
            );
          },
        },
      ],
    },
    {
      title: 'Дней в статусе',
      key: 'status_time__range',
      dataIndex: 'status_time',
      width: 80,
      ellipsis: {
        showTitle: false,
      },
      children: [
        {
          title: (
            <RangeNumberFilters
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={columnFiltersFromTableComponent?.status_time__range}
              columnKey={'status_time__range'}
            />
          ),
          visible: visibleFilters,
          key: 'status_time__range',
          dataIndex: 'status_time',
          width: 80,
          ellipsis: {
            showTitle: false,
          },
        },
      ],
    },
    {
      title: 'Дата начала',
      dataIndex: 'date_begin',
      key: 'date_begin__periodDate',
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (date_begin) =>
        date_begin ? (
          <Tooltip placement={'topLeft'} title={date_begin}>
            {date_begin}
          </Tooltip>
        ) : null,
      children: [
        {
          title: (
            <FilterDatePicker
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={
                columnFiltersFromTableComponent?.date_begin__periodDate
              }
              columnKey={'date_begin__periodDate'}
            />
          ),
          dataIndex: 'date_begin',
          key: 'date_begin__periodDate',
          width: 150,
          ellipsis: {
            showTitle: false,
          },
          render: (date_begin) =>
            date_begin ? (
              <Tooltip placement={'topLeft'} title={date_begin}>
                {date_begin}
              </Tooltip>
            ) : null,
          visible: visibleFilters,
        },
      ],
    },
    {
      title: 'Дата окончания',
      dataIndex: 'date_end',
      key: 'date_end__periodDate',
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (date_end) =>
        date_end ? (
          <Tooltip placement={'topLeft'} title={date_end}>
            {date_end}
          </Tooltip>
        ) : null,
      children: [
        {
          title: (
            <FilterDatePicker
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={
                columnFiltersFromTableComponent?.date_end__periodDate
              }
              columnKey={'date_end__periodDate'}
            />
          ),
          dataIndex: 'date_end__periodDate',
          key: 'date_end',
          width: 150,
          ellipsis: {
            showTitle: false,
          },
          render: (date_end) =>
            date_end ? (
              <Tooltip placement={'topLeft'} title={date_end}>
                {date_end}
              </Tooltip>
            ) : null,
          visible: visibleFilters,
        },
      ],
    },
    {
      title: 'Условия оплаты',
      key: 'postponement_condition__range',
      dataIndex: 'postponement_condition',
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (postponement_condition) =>
        postponement_condition ? (
          <Tooltip placement={'topLeft'} title={postponement_condition}>
            {postponement_condition}
          </Tooltip>
        ) : null,
      children: [
        {
          title: (
            <RangeNumberFilters
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loading}
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={
                columnFiltersFromTableComponent?.postponement_condition__range
              }
              columnKey={'postponement_condition__range'}
            />
          ),
          key: 'postponement_condition__range',
          dataIndex: 'postponement_condition',
          visible: visibleFilters,
          width: 150,
          ellipsis: {
            showTitle: false,
          },
          render: (postponement_condition) =>
            postponement_condition ? (
              <Tooltip placement={'topLeft'} title={postponement_condition}>
                {postponement_condition}
              </Tooltip>
            ) : null,
        },
      ],
    },
    {
      title: 'Имеет вложение',
      key: 'has_attachments',
      dataIndex: 'has_attachments',
      align: 'center',
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (has_attachments) => {
        return has_attachments ? <PaperClipOutlined /> : <></>;
      },
    },
  ];

  const getStatusFromServer = (status) => {
    switch (status) {
      case 'new':
        return <Tag color={'cyan'}>Новый</Tag>;
      case 'rec':
        return <Tag color={'purple'}>Отправлен на согласование</Tag>;
      case 'ret':
        return <Tag color={'purple'}>Повторное согласование</Tag>;
      case 'con':
        return <Tag color={'orange'}>Получена копия</Tag>;
      case 'sig':
        return <Tag color={'green'}>Подписан</Tag>;
      case 'arc':
        return <Tag color={'#A9A9A9'}>Архив</Tag>;
      default:
        return <Tag color={'magenta'}>Неизвестный статус</Tag>;
    }
  };

  useEffect(() => {
    checkUserIsAdmin(userInfo.current);
  }, []);

  useEffect(async () => {
    if (customerId) {
      await getCustomer(customerId);
    }
  }, [customerId]);

  useEffect(() => {
    if (!visibleCreateDocModal) {
      setDisableDocBtn(true);
      setContractPdfUrl(null);
      setContractDocxUrl(null);
      setCreateContractModalDraggerFileList([]);
    }
  }, [visibleCreateDocModal]);

  useEffect(() => {
    if (typeChoices?.contract_status) {
      const contractStatuses = Object.entries(typeChoices?.contract_status).map(
        (entry) => {
          return { [entry[0]]: entry[1] };
        },
      );
      setContractsStatuses(contractStatuses);
    }
    if (typeChoices?.contract_doc_comment) {
      setDocCommentChoices(Object.entries(typeChoices?.contract_doc_comment));
    }
  }, [typeChoices]);

  useEffect(() => {
    if (tableFilters) {
      const newFilters = {
        ...tableFilters?.filter,
        filter: {
          ...tableFilters?.filter,
          search: tableFilters?.filter?.search,
        },
      };
      setCopyFiltres(newFilters);
    }
  }, [tableFilters]);

  useEffect(() => {
    let updateFilters = columnFiltersFromTableComponent
      ? columnFiltersFromTableComponent
      : [];
    if (defaultFilter?.length > 0) {
      for (const filter of defaultFilter) {
        updateFilters[filter.name] = [filter?.value];
      }
    }
    setColumnFiltersFromTableComponent(updateFilters);
  }, [defaultFilter]);

  useEffect(() => {
    setDisableDocBtn(true);
  }, [createContractModalDraggerFileList]);

  const menu = (contract) => {
    return (
      <Menu style={{ width: 220 }}>
        <Menu.Item
          key={'edit'}
          icon={<EditOutlined />}
          onClick={async () => {
            if (checkWhatTheUserCanViewContract(isAdmin, contract?.status)) {
              const modalName = selectModalWindow(contract?.status);
              await openSendContractModal(contract, modalName);
            } else {
              message.warning(
                'Просмотр договорова с этим статусов не доступен менеджерам',
              );
            }
          }}
        >
          Редактирование договора
        </Menu.Item>
        {checkWhatTheUserCanViewContract(isAdmin, contract?.status) ? (
          <Popconfirm
            title="Вы действительно хотите удалить запись?"
            onConfirm={() => deleteContract(contract.id)}
            onCancel={() => console.log('Delete item')}
            okText="Да"
            cancelText="Нет"
            placement="rightBottom"
          >
            <Menu.Item key={'delete'} icon={<DeleteOutlined />}>
              Удалить
            </Menu.Item>
          </Popconfirm>
        ) : (
          <></>
        )}
      </Menu>
    );
  };

  const checkUserIsAdmin = (user) => {
    if (
      user?.groups?.some((o) => o.id === 1) ||
      user?.groups?.some((o) => o.id === 2) ||
      user?.groups?.some((o) => o.id === 4)
    ) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  };
  const selectModalWindow = (contractStatus) => {
    if (
      contractStatus === 'new' ||
      contractStatus === 'rec' ||
      contractStatus === 'ret'
    ) {
      return 'modalCreateContract';
    } else {
      return 'modalEditContract';
    }
  };
  const checkWhatTheUserCanViewContract = (isAdmin, contractStatus) => {
    if (isAdmin) {
      return true;
    }
    return contractStatus === 'new' || contractStatus === 'rec';
  };
  const filteringChildrenPillarByVisible = (columns) => {
    let filteredColumns = columns;
    for (const column of filteredColumns) {
      if (column?.children?.length > 0) {
        column.children = column.children.filter(
          (item) => item.visible === true,
        );
      }
    }
    return filteredColumns;
  };
  const handleTableChange = (pagination, filtersTable, sorter) => {
    const { page, filters } = getFiltersTable(pagination, filtersTable, sorter);
    updatePage(page);
    setColumnFiltersFromTableComponent(filtersTable);
    let newFilters = { ...filters };
    if (tableFilters?.filter?.search) {
      newFilters = {
        ...filters,
        filter: { ...filters.filter, search: tableFilters.filter.search },
      };
    }
    setCopyFiltres(newFilters);
    handleUpdate(page, newFilters);
  };

  const openSendContractModal = async (contractData, modalName) => {
    setPdfSendLoading(true);
    setCurrentContract(contractData);
    let body = {};
    if (modalName === 'modalEditContract') {
      body = {
        code_contract: contractData?.code_contract
          ? contractData.code_contract
          : null,
        customer: contractData?.signer_manager?.customer?.short_name
          ? contractData?.signer_manager.customer?.short_name
          : null,
        status: contractData?.status ? contractData.status : null,
        our_customer: contractData?.customer?.short_name
          ? contractData.customer?.short_name
          : null,
        doc_comment: contractData?.doc_comment
          ? contractData.doc_comment
          : null,
      };
      setEditFormStatus(
        contractData?.status ? contractData.status.toString() : null,
      );
      editForm.setFieldsValue(body);
      setVisibleEditDocModal(true);
    } else if (modalName === 'modalCreateContract') {
      body = {
        code_contract: contractData?.code_contract
          ? contractData.code_contract
          : null,
        status: contractData?.status ? contractData.status : null,
        postponement_condition: contractData?.postponement_condition
          ? contractData.postponement_condition
          : null,
        date_begin: contractData?.date_begin
          ? dayjs(contractData.date_begin).format('YYYY-MM-DD')
          : null,
        date_end: contractData?.date_end
          ? dayjs(contractData.date_end).format('YYYY-MM-DD')
          : null,
        signer_customer: contractData?.signer_customer?.id
          ? contractData.signer_customer.id
          : null,
        signer_manager: contractData?.signer_manager?.id
          ? contractData.signer_manager.id
          : null,
        contact_send: contractData?.contact_send
          ? contractData.contact_send
          : null,
        type: contractData?.type ? contractData.type : null,
      };
      createDocForm.setFieldsValue(body);
      changeVisibleCreateContractModal(true, false);
    }
    delete body.attachment;
    setSignerManager(contractData?.signer_manager);
    const createIsAdmin = contractData?.user?.is_priveleged
      ? contractData?.user?.is_priveleged
      : false;
    if (createIsAdmin && !isAdmin) {
      setDisableDateBeginAndNumberAndStatus(true);
    }
    setPdfSendLoading(false);
  };

  const validationBeforePreview = async () => {
    await createDocForm
      .validateFields()
      .then(async (values) => {
        if (createContractModalDraggerFileList[0]?.originFileObj) {
          await contractGeneration(
            values,
            signerManager,
            createContractModalDraggerFileList[0]?.originFileObj,
          );
        } else {
          await contractGeneration(values, signerManager);
        }
      })
      .catch((err) => {
        if (err?.errorFields?.length > 0) {
          message.warning('Не все поля заполнены');
          return;
        }
        message.error('Ошибка формирования договора');
        console.log('Ошибка формирования договора', err);
      });
  };

  const contractGeneration = async (
    contractData,
    signerManager,
    selectedFile,
  ) => {
    const singerMangerCompanyId =
      typeof signerManager?.customer === 'object'
        ? signerManager?.customer?.id
        : signerManager?.customer;
    let contractTemplates = [];
    const context = JSON.stringify({
      number: contractData?.code_contract ? contractData.code_contract : null,
      date_beg: contractData?.date_begin
        ? dayjs(contractData.date_begin).format('YYYY-MM-DD')
        : null,
      date_end: contractData?.date_end
        ? moment(contractData.date_end).format('YYYY-MM-DD')
        : null,
    });
    if (!singerMangerCompanyId) {
      message.error('Подписант с "Нашей строны" не подвязан к компании');
    }
    //setSelectedContract(contract);
    const hide = message.loading(
      selectedFile
        ? 'Формирование договора исходя из выбранного файла'
        : 'Формирование договора',
      5,
    );
    if (!selectedFile) {
      contractTemplates = await getTempletesConstract(
        singerMangerCompanyId,
        contractData?.type,
        userInfo,
      );
      if (!contractTemplates?.length > 0) {
        setDisableDocBtn(true);
        message.error('Шаблон договора не найден');
        hide();
        return;
      }
    }
    const pdfAndDocxContractFiles =
      await getGeneratedContractPdfAndDocxByTemplateId(
        contractData?.signer_customer,
        contractData?.signer_manager,
        contractTemplates[0]?.id,
        selectedFile,
        context,
        userInfo,
      );

    if (pdfAndDocxContractFiles?.pdf && pdfAndDocxContractFiles?.docx) {
      setContractPdfUrl(pdfAndDocxContractFiles?.pdf);
      setContractDocxUrl(pdfAndDocxContractFiles?.docx);
      setDisableDocBtn(false);
    }

    hide();
    setVisibleContractShowPdf(true);
  };

  const createContract = async (body) => {
    try {
      const url = `${restApiHost}/customers/contracts/`;
      const json = await call(
        url,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );
      if (json?.data) {
        message.success('Договор успешно создан');
        return Promise.resolve(json?.data);
      }
    } catch (e) {
      console.log(e);
      return Promise.reject('Ошибка запроса на создание договора!');
    }
  };

  const createAndSendContract = async (
    body,
    contractPdfUrl,
    contractDocxUrl,
    contract,
    customerId,
    createContact,
    valueFormSendEmailModal,
  ) => {
    setPdfSendLoading(true);
    try {
      if (createContact) {
        await createContract(body).then(async (res) => {
          setCurrentContract(res ? res : null);
          setThisCreationContract(false);
          await sendContractToCustomer(res.id, valueFormSendEmailModal);
          setDisableDocBtn(true);
        });
      } else {
        await sendContractToCustomer(contract?.id, valueFormSendEmailModal);
        setDisableDocBtn(true);
      }
    } catch (e) {
      console.log(e);
      message.error('Ошибка отправки договора на согласования');
    } finally {
      setPdfSendLoading(false);
    }
  };

  const sendContractToCustomer = async (
    contractId,
    valueFormSendEmailModal,
  ) => {
    try {
      const body = {
        to: valueFormSendEmailModal?.email
          ? valueFormSendEmailModal.email
          : null,
        cc: valueFormSendEmailModal?.cc ? valueFormSendEmailModal?.cc : null,
        bcc: valueFormSendEmailModal?.bcc ? valueFormSendEmailModal?.bcc : null,
        contract: contractId ? contractId : null,
        subject: valueFormSendEmailModal?.subject
          ? valueFormSendEmailModal.subject
          : null,
        files: valueFormSendEmailModal?.selectedFiles
          ? valueFormSendEmailModal?.selectedFiles
          : null,
        html: valueFormSendEmailModal?.text_content
          ? valueFormSendEmailModal?.text_content
          : null,
      };
      await sendContractOnApproval(body, userInfo).then((res) => {
        if (res.status === 200 || res.status === 201) {
          clearUpVariateCreateContractModal();
        }
      });
    } catch (e) {
      console.log(e);
      console.error('Ошибка отправки договора на согласования');
    }
  };

  const editContract = async (contractId, body) => {
    try {
      const url = `${restApiHost}/customers/contracts/${contractId}/`;
      const json = await call(
        url,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      message.success('Договор отредатирован!');
      isMobile && handleScrollToTopMobileList();
      handleUpdate(isMobile ? 1 : page, copyFiltres);
    } catch (e) {
      console.log('edit contract err', e);
      message.error('Ошибка запроса на редактирование договора');
    }
  };
  const deleteContract = async (contractId) => {
    try {
      const url = `${restApiHost}/customers/contracts/${contractId}/`;
      const response = await call(
        url,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!response) return;

      message.success('Удаление успешно выполнено!');
      isMobile && handleScrollToTopMobileList();
      handleUpdate(isMobile ? 1 : page, copyFiltres);
    } catch (e) {
      console.log('delete contract err', e);
      message.error('Ошибка запроса на удаление договора');
    }
  };

  const createAttachment = async (body) => {
    try {
      const url = `${restApiHost}/customers/contract_attachments/`;
      const formData = new FormData();
      formData.append('contract', body?.contract);
      formData.append('customer', body?.customer);
      formData.append('type_attachment', body?.type_attachment);
      if (body?.file.length > 0) {
        body?.file.map((file) => {
          if (file?.originFileObj) {
            formData.append(`file`, file.originFileObj, file.name);
          }
        });
      }
      const json = await call(
        url,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
          body: formData,
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      message.success('Вложение успешно добавлено');
      if (body?.file.length > 0) {
        body?.file.map((file) => {
          if (file) {
            addFileToSuccessfullyAddedAttachmentFileList(file);
          }
        });
      }
      closeModalAddAttachment();
    } catch (e) {
      console.log(e);
    }
  };

  const fileSelection = (file) => {
    const fileObject = { originFileObj: file, name: file?.name };
    setCurrentFileForCreateAttachment([
      file?.originFileObj ? file : fileObject,
    ]);
    setVisibleAttachmentsModal(true);
  };
  const addFileToSuccessfullyAddedAttachmentFileList = (file) => {
    if (file) {
      const fileObject = { originFileObj: file, name: file?.name };
      const copyFileList = fileList;
      copyFileList.push(fileObject);
      setFileList(copyFileList);
    }
  };

  const closeModalAddAttachment = () => {
    setCurrentFileForCreateAttachment([]);
    setVisibleAttachmentsModal(false);
  };

  const getClassNameForRow = (record, index) => {
    if (
      (record.status === 'rec' && record.status_time > 7) ||
      (record.status === 'ret' && record.status_time > 3)
    ) {
      return 'black-text table-row-red';
    }
    return index % 2 === 0
      ? 'black-text table-row-color'
      : 'black-text table-row-white';
  };

  const downloadDocxContract = async (contractDocxUrl) => {
    let isValid = false;
    let contractCode = null;
    await createDocForm
      .validateFields()
      .then(async (values) => {
        isValid = true;
        contractCode = values?.code_contract ? values.code_contract : null;
      })
      .catch((errors) => {
        console.log(errors);
        message.error('Не все поля заполнены');
        if (errors?.errorFields?.length > 0) {
          message.warning('Не все поля заполнены');
          return;
        }
      });
    if (isValid && contractDocxUrl) {
      try {
        setDisableDocBtn(true);
        await message.info('Началось формирование docx файла', 1);
        await exportFileFormUrl(
          contractDocxUrl,
          `Договор ${contractCode}.docx`,
        );
        message.success(`Файл Договор ${contractCode}.docx успешно скачан`);
        setDisableDocBtn(false);
      } catch (e) {
        console.log(e);
        message.error('Ошибка формирования docx файла');
      }
    }
  };
  /*const refreshContractData = async (contractId) => {
        if (contractId) {
            const contractData = await getContractById(contractId,userInfo)
            if  (contractData) {
                createDocForm.setFieldsValue({status: contractData?.status})
                setCurrentContract(contractData)
            }
        }
    }*/

  const openModalAddContactCustomer = () => {
    setVisibleUpdateCustomerModal(true);
    setUsingUpdateCustomerModalWhenGeneratingContract(true);
  };

  const getCustomer = async (customerId) => {
    const customer = await getCustomerById(customerId, userInfo);
    if (customer) {
      setCurrentCustomerFullData(customer);
    }
  };

  const clearUpVariateCreateContractModal = () => {
    if (visibleSendEmailModalInCreateContractModal) {
      setVisibleSendEmailModalInCreateContractModal(false);
    }
    changeVisibleCreateContractModal(false);
    setDisableDocBtn(true);
    setContractPdfUrl(null);
    setContractDocxUrl(null);
    setCreateContractModalDraggerFileList([]);
    setCurrentContract(null);
    createDocForm.resetFields();
    isMobile && handleScrollToTopMobileList();
    handleUpdate(isMobile ? 1 : page, copyFiltres);
  };

  return (
    <>
      <ErrorBoundary>
        <CreateContractModal
          visible={visibleCreateDocModal}
          onCancel={() => {
            clearUpVariateCreateContractModal();
          }}
          previewContract={async () => {
            await validationBeforePreview();
          }}
          disabled={disableDocBtn}
          disableDateBeginAndNumberAndStatus={
            disableDateBeginAndNumberAndStatus
          }
          isAdmin={isAdmin}
          useLocks={true}
          downloadDoc={async () => {
            if (contractDocxUrl) {
              await downloadDocxContract(contractDocxUrl);
            }
          }}
          handleUpdateAfterCloseModalSend={false}
          filesUrl={{
            docxUrl: contractDocxUrl
              ? `${restApiHost}${contractDocxUrl}`
              : null,
            pdfUrl: contractPdfUrl ? `${restApiHost}${contractPdfUrl}` : null,
          }}
          draggerFileList={createContractModalDraggerFileList}
          changeDraggerFileList={(fileList) =>
            setCreateContractModalDraggerFileList(fileList)
          }
          loading={pdfSendLoading}
          sendForApproval={async (valueFormSendEmailModal) => {
            if (thisCreationContract) {
              createDocForm.validateFields().then(async (values) => {
                let data = {
                  ...values,
                  postponement_condition: values.postponement_condition
                    ? values.postponement_condition
                    : 1,
                  customer: customerId,
                };
                delete data.attachment;
                try {
                  await createAndSendContract(
                    data,
                    `${restApiHost}${contractPdfUrl}`,
                    `${restApiHost}${contractDocxUrl}`,
                    currentContract,
                    customerId,
                    thisCreationContract,
                    valueFormSendEmailModal,
                  );
                } catch (e) {
                  console.log(e);
                  message.error('Ошибка отправки договора на согласование');
                }
              });
            } else {
              try {
                await createAndSendContract(
                  null,
                  `${restApiHost}${contractPdfUrl}`,
                  `${restApiHost}${contractDocxUrl}`,
                  currentContract,
                  customerId,
                  thisCreationContract,
                  valueFormSendEmailModal,
                );
              } catch (e) {
                console.log(e);
                message.error('Ошибка отправки договора на согласование');
              }
            }
          }}
          contract={currentContract}
          saveContract={() => {
            createDocForm.validateFields().then(async (values) => {
              let data = {
                ...values,
                date_begin: values?.date_begin
                  ? dayjs(values?.date_begin).format('YYYY-MM-DD')
                  : null,
                postponement_condition: values.postponement_condition
                  ? values.postponement_condition
                  : 1,
              };
              try {
                if (thisCreationContract) {
                  data['customer'] = customerId;
                  await createContract(data);
                } else {
                  await editContract(currentContract?.id, data);
                }
                /* changeVisibleCreateContractModal(false);
                                setDisableDocBtn(true)
                                createDocForm.resetFields();*/
                clearUpVariateCreateContractModal();
              } catch (e) {
                console.log(e);
                message.error('Ошибка создания договора');
              }
            });
          }}
          form={createDocForm}
          visibleSendEmailModal={visibleSendEmailModalInCreateContractModal}
          setVisibleSendEmailModal={(val) =>
            setVisibleSendEmailModalInCreateContractModal(val)
          }
          contractType={currentContract?.type ? currentContract.type : null}
          formOnValuesChange={() => {
            setTimeout(() => {
              setDisableDocBtn(true);
            }, 0);
          }}
          uploadedDoc={uploadedDoc}
          typeChoices={typeChoices}
          callbackfn={(item) => {
            return (
              <Option key={item[0]} value={item[0]}>
                {item[1]}
              </Option>
            );
          }}
          onChangeSignerManager={(value, data) =>
            setSignerManager(JSON.parse(data['data-value']))
          }
          signerManagers={signerManagers}
          onChangeSignerCustomer={async (value, data) => {
            if (value === 'addContact') {
              createDocForm.setFieldsValue({
                signer_customer: null,
              });
              openModalAddContactCustomer();
            } else {
              setSignerCustomer(JSON.parse(data['data-value']));
            }
          }}
          signerCustomers={signerCustomers}
          onChangeContactSend={(value) => setContactSend(value)}
          allClients={allClients}
          onChangeAttachment={(e) => {
            setUploadedDoc(e.target.files[0]);
            createDocForm.setFieldsValue({ status: 'con' });
          }}
          attach={attach}
          onChangeIsOriginalDocContract={(e) => {
            if (e.target.checked && uploadedDoc) {
              createDocForm.setFieldsValue({ status: 'sig' });
            } else if (!e.target.checked && uploadedDoc) {
              createDocForm.setFieldsValue({ status: 'con' });
            } else {
              createDocForm.setFieldsValue({ status: 'new' });
            }
          }}
        />
      </ErrorBoundary>

      <ErrorBoundary>
        <ContractModalMinimalInfoTheAbilityToAddAttachments
          visible={visibleEditDocModal}
          onCancel={() => {
            setVisibleEditDocModal(false);
            setFileList([]);
            setDisableDocBtn(true);
            editForm.resetFields();
            setEditFormStatus(null);
            isMobile && handleScrollToTopMobileList();
            handleUpdate(isMobile ? 1 : page, copyFiltres);
          }}
          saveContract={() => {
            editForm.validateFields().then(async (values) => {
              let data = {
                ...values,
                // date_begin: values?.date_begin ? dayjs(values?.date_begin).format('YYYY-MM-DD') : null,
              };
              if (editFormStatus) {
                data['status'] = editFormStatus;
              }
              delete data.customer;
              delete data.our_customer;
              try {
                await editContract(currentContract?.id, data);
                setVisibleEditDocModal(false);
                setFileList([]);
                setDisableDocBtn(true);
                editForm.resetFields();
                setEditFormStatus(null);
              } catch (e) {
                console.log(e);
                message.error('Ошибка редактирования договора');
              }
            });
          }}
          form={editForm}
          uploadedDoc={uploadedDoc}
          admin={isAdmin}
          contractStatus={editFormStatus}
          onChange={(val) => setEditFormStatus(val)}
          statusOptions={statusOptions}
          callbackfn={(item) => {
            return (
              <Option key={item.id} value={item.id}>
                {item.title}
              </Option>
            );
          }}
          propsDragger={propsDragger}
          docCommentChoices={docCommentChoices}
          callbackfn1={(item) => {
            return (
              <Option key={item[0]} value={item[0]}>
                {item[1]}
              </Option>
            );
          }}
        />
      </ErrorBoundary>

      {contractPdfUrl && (
        <ErrorBoundary>
          <PdfViewerModal
            visible={visibleContractShowPdf}
            setVisible={(visible) => setVisibleContractShowPdf(visible)}
            pdfFileUrl={`${restApiHost}${contractPdfUrl}`}
          />
        </ErrorBoundary>
      )}
      <ErrorBoundary>
        <AddAttachmentModal
          visible={visibleAttachmentsModal}
          creationAttachment={true}
          loading={false}
          typesAttachment={prepareAttachmentTypeForSelect(
            typeChoices?.attachment_type,
          )}
          customerId={customerId}
          fileList={currentFileForCreateAttachment}
          currentContract={currentContract}
          createAttachment={(body) => createAttachment(body)}
          disableSelectContract={true}
          handelCloseModal={closeModalAddAttachment}
        />
      </ErrorBoundary>

      <ErrorBoundary>
        <UpdateCustomerModal
          onlyUsedToAddSigner={true}
          visible={visibleUpdateCustomerModal}
          getDataCustomer={async (customerId) => await getCustomer(customerId)}
          handleOk={async () => {
            if (usingUpdateCustomerModalWhenGeneratingContract) {
              getSignerCustomers(customerId);
              setUsingUpdateCustomerModalWhenGeneratingContract(false);
            }
            setVisibleUpdateCustomerModal(false);
            setCurrentCustomerFullData(null);
          }}
          handleCancel={async () => {
            if (usingUpdateCustomerModalWhenGeneratingContract) {
              getSignerCustomers(customerId);
              setUsingUpdateCustomerModalWhenGeneratingContract(false);
            }
            setVisibleUpdateCustomerModal(false);
            setCurrentCustomerFullData(null);
          }}
          currentStep={() => 1}
          loading={false}
          data={currentCustomerFullData ? currentCustomerFullData : null}
        />
      </ErrorBoundary>
      {!isMobile ? (
        <ConfigProvider locale={locale}>
          <Table
            style={{ paddingBottom: '10px' }}
            rowClassName={(record, index) => getClassNameForRow(record, index)}
            locale={{
              triggerDesc: 'По убыванию',
              triggerAsc: 'По возрастанию',
              cancelSort: 'Сбросить сортировку',
            }}
            scroll={{ x: 1200 }}
            onRow={(record) => {
              return {
                onDoubleClick: async () => {
                  if (
                    checkWhatTheUserCanViewContract(isAdmin, record?.status)
                  ) {
                    const modalName = selectModalWindow(record?.status);
                    await openSendContractModal(record, modalName);
                  } else {
                    message.warning(
                      'Просмотр договора с этим статусов не доступен менеджерам',
                    );
                  }
                },
              };
            }}
            loading={loading}
            dataSource={contractsList}
            columns={filteringChildrenPillarByVisible(columnsTable)}
            size="small"
            bordered={true}
            pagination={setPaginationSettings(total, page, ['bottomRight'])}
            onChange={(val) => {
              handleTableChange(
                { current: val?.current },
                columnFiltersFromTableComponent,
              );
            }}
          />
        </ConfigProvider>
      ) : (
        <div style={{ paddingBottom: '10px', paddingTop: '5px' }}>
          <ErrorBoundary>
            <ScrollingLoadedList
              dataList={contractsList}
              totalData={total}
              scrollingLoadedListRef={scrollingLoadedListRef}
              setDefaultHeight={true}
              loadMoreItems={async () => {
                handleUpdate(page + 1, copyFiltres, false, true);
              }}
              renderListItem={(item) => (
                <ContractListItem
                  contract={item}
                  menuActions={(contact) => menu(contact)}
                />
              )}
            />
          </ErrorBoundary>
        </div>
      )}

      <ErrorBoundary>
        <UniversalFiltersModal
          visibleModal={visibleFilters && isMobile}
          handleClose={() => {
            setVisibleFilters(false);
          }}
          resetFilters={() => {
            setColumnFiltersFromTableComponent([]);
            isMobile && handleScrollToTopMobileList();
            setVisibleFilters(false);
            handleUpdate(1, {
              filter: { customer: customerId ? customerId.toString() : null },
              order: {},
            });
          }}
          columnsList={columnsTable}
        />
      </ErrorBoundary>
    </>
  );
};

export default ContractStepTable;
