import {
  Button,
  Col,
  Input,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Switch,
  Tabs,
  Tooltip,
} from 'antd';
import { KpTableSum } from '../leeds/editTemplateUtils/KpTableSum';
import { KpUi } from '../leeds/editTemplateUtils/KpUi';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import PreAppicationsTable from '../applications/PreAppicationsTable';
import SentKpTable from '../leeds/SentKpTable';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { getBranchId } from '../../localStorageUtils/getBranchId';
import { restApiHost } from '../../utils/appVariables';
import html2pdf from 'html2pdf.js/src';
import { userInformations } from '../../contex';
import { useNavigate } from 'react-router-dom';
import { call, getCompanyIdFromStorage } from '../../apiUtils/call';
import { formationOfShoulderFromPoints } from './formationOfShoulderFromWayPoints';
import { ErrorBoundary } from '../ErrorBoundary';
import { getCpTemplateById, getCpTemplates } from './getCpTemplates';
import { cpTemplateWithReplacedPlaceholders } from './replacePlaceholdersInCp/cpTemplateWithReplacedPlaceholders';
import { additionalStyleLine } from './replacePlaceholdersInCp/additionalStyleLine';
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import * as ClassicEditor from '../../ckeditor';
import useAsyncEffect from 'use-async-effect';
import { getPreTaskList } from '../../logic/preTasks/PreTaskApiFunctions';
import {
  getCommPropHistoryCustomer,
  getContactsCustomer,
} from '../../logic/customer/CustomerApiFunctions';
import {
  getCommPropHistoryLead,
  getContactsLead,
} from '../../logic/lead/LeadApiFunctions';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';

const { TextArea } = Input;
const { Option, OptGroup } = Select;
const { TabPane } = Tabs;

const SendKpOrPresentationModal = ({
  visibleModal,
  //preTask,
  closeVisibleModal,
  customer,
  currentLead,
  sendFromLeadPage,
  page,
  updateModelData,
  loading,
  //fromPreTaskPage,
  staffList,
  handleUpdate,
}) => {
  const navigate = useNavigate();
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [, setSelectedRowRows] = useState([]);
  const [preShowKp, setPreShowKp] = useState(false);
  const [kpData, setKpData] = useState('');
  const [editDataForTable, setEitDataForTable] = useState('');
  const [editDataForUi, setEitDataForUi] = useState('');
  const [kpEditingTable, setKpEditingTable] = useState(false);
  const [kpEditingUi, setKpEditingUi] = useState(false);
  const [activeTabMenuEditKp, setActiveTabMenuEditKp] = useState('2');
  const [isViewSentCP, setIsViewSentCP] = useState(false);
  const [commProp, setCommProp] = useState(null);
  const [isKpAndPres, setIsKpAndPres] = useState(false);
  const [isSendPresentation, setIsSendPresentation] = useState(false);
  const [sendKp, setSendKp] = useState(true);
  const [isAppeal] = useState(false);
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState(null);
  const [emailText, setEmailText] = useState('');
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [filters] = useState(null);
  const [showTableEditing, setShowTableEditing] = useState(false);
  const [openSelectContact, setOpenSelectContact] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [optionsValue, setOptionsValue] = useState('kp');
  const { userInfo } = useContext(userInformations);
  let dataForTable = useRef([]);
  const [activeKeyPreTaskOrSendKp, setActiveKeyPreTaskOrSendKp] = useState('1');
  const [inputSearchContactValue, setInputSearchContactValue] = useState('');
  const [staffListCopyProps, setStaffListCopyProps] = useState([]);
  const [htmlDataForKp, setHtmlDataForKp] = useState({ __html: `` });
  const [subject, setSubject] = useState('');
  const [disableBntSendCp, setDisableBntSendCp] = useState(true);
  const [cpTemplates, setCpTemplates] = useState([]);
  const [currentCpTemplateHtml, setCurrentCpTemplateHtml] = useState(null);
  const [currentCpTemplateId, setCurrentCpTemplateId] = useState(null);
  const [loadingPreviewCP, setLoadingPreviewCP] = useState(false);
  const innerHTMLRef = useRef();
  const [colorTableSum, setColorTableSum] = useState({
    colorHead: '#4471C4',
    colorBody: '#CFD4EA',
    colorTextHead: '#ffffff',
    colorTextBody: '#000000',
  });
  const [preTaskList, setPreTaskList] = useState([]);
  const [preTaskListTotal, setPreTaskListTotal] = useState(0);
  const [contactPersonsOptions, setContactPersonsOptions] = useState([]);
  const [commPropsList, setCommPropsList] = useState([]);
  const [totalCommPropsList, setTotalCommPropsList] = useState(0);
  const [pageCommPropsList, setPageCommPropsList] = useState(1);
  const isMobile = useCheckMobileScreen();

  useEffect(() => {
    setOpenSelectContact(false);
    setActiveKeyPreTaskOrSendKp('1');
  }, [visibleModal]);

  useAsyncEffect(async () => {
    if (visibleModal) {
      await getEmailTemplates();
      await getAndSetCpTemplates(userInfo);
    }
  }, [visibleModal]);

  useAsyncEffect(async () => {
    if ((customer?.id || currentLead?.id) && visibleModal) {
      await handleGetPreTaskList(currentPage, customer?.id, currentLead?.id);
      await handleGetLeadOrCustomerContact(customer?.id, currentLead?.id);
    }
  }, [currentPage, customer, currentLead, visibleModal]);

  useAsyncEffect(async () => {
    if ((customer?.id || currentLead?.id) && activeKeyPreTaskOrSendKp === '2') {
      await handleGetLeadOrCustomerSendKpList(
        pageCommPropsList,
        customer?.id,
        currentLead?.id,
      );
    }
  }, [pageCommPropsList, activeKeyPreTaskOrSendKp, customer, currentLead]);

  useEffect(() => {
    if (optionsValue !== 'presen' && !preShowKp) {
      setDisableBntSendCp(true);
    } else {
      setDisableBntSendCp(false);
    }
  }, [preShowKp, optionsValue]);

  useEffect(() => {
    getHtmlCP();
  }, [kpData, dataForTable, editDataForUi, editDataForTable]);

  /* useEffect(() => {
        const scriptChangeDisplay = `
        if  (document.getElementsByClassName('shoulder_table')[0]) {
        if (document.getElementsByClassName('shoulder_table')[0].offsetHeight > 200) {
        return false
           }
            }    return true`
        const exicutScript = new Function(scriptChangeDisplay)
        const viewImg =  exicutScript()
        setViewImgRuble(viewImg)
    },[htmlDataForKp,activeTabMenuEditKp])*/

  function addStyleToHtmlCP(innerHTMLRef) {
    let el = innerHTMLRef.current;
    if (el) {
      const style = additionalStyleLine(colorTableSum);
      const elementStyle = el.getElementsByTagName('style')[0];
      if (elementStyle?.textContent) {
        //const textContentWitchReplaceValue = `${elementStyle.textContent}`.replace('table, td { color: #000000; }', '')
        elementStyle.textContent = elementStyle?.textContent + style;
      }
    }
  }

  useEffect(() => {
    if (innerHTMLRef.current) {
      addStyleToHtmlCP(innerHTMLRef);
    }
  }, [htmlDataForKp, innerHTMLRef.current]);

  /*useEffect(() => {
            getHtmlCP()
        },[viewImgRuble])*/

  useEffect(() => {
    if (!preShowKp) {
      innerHTMLRef.current = null;
      setActiveTabMenuEditKp('2');
      setShowTableEditing(false);
    }
  }, [preShowKp]);

  useEffect(() => {
    setSendOptions(optionsValue);
  }, [optionsValue]);

  useEffect(() => {
    defaultValueSelectContact();
  }, [currentLead, customer]);

  useEffect(() => {
    setOpenSelectContact(false);
  }, [visibleModal]);

  useEffect(() => {
    if (staffList) {
      setStaffListCopyProps(staffList);
    }
  }, [staffList]);

  const getEmailTemplates = async () => {
    try {
      const url = `${restApiHost}/refbook/template/?active=true`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );
      if (json?.data) {
        setEmailTemplates(json.data);
      }
    } catch (e) {
      console.log('get email templates err', e);
    }
  };

  const getAndSetCpTemplates = async (userInfo) => {
    const templates = await getCpTemplates(userInfo);
    if (templates) {
      setCpTemplates(templates);
    }
  };

  const getCpHtmlTemplatesById = async (id, userInfo) => {
    const template = await getCpTemplateById(id, userInfo);
    if (template?.data?.html) {
      setCurrentCpTemplateHtml(
        `<div style="max-height: 1120px !important; overflow: hidden; position: relative">${template?.data?.html}</div>`,
      );
    }
    if (template?.data?.table_color) {
      const tableColorObject = JSON.parse(template?.data?.table_color);
      if (
        tableColorObject?.colorHead &&
        tableColorObject?.colorBody &&
        tableColorObject?.colorTextHead &&
        tableColorObject?.colorTextBody
      ) {
        setColorTableSum(tableColorObject);
      }
    }
  };

  //select pre-app rows
  const onSelectChange = (keys) => {
    setSelectedRowKeys(keys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record?.send_comm_prop, //record.send_comm_prop === true, - old value
    }),
  };

  const hasSelected = !isViewSentCP
    ? isSendPresentation
      ? selectedContacts.length > 0
      : selectedRowKeys.length > 0 && selectedContacts.length > 0
    : selectedContacts.length > 0;
  const hasSelected2 = !isViewSentCP
    ? selectedRowKeys.length > 0 &&
      selectedContacts.length > 0 &&
      currentCpTemplateId
    : true;

  const getPreviewKp = async (lead, pre_tasks, customer) => {
    try {
      setLoadingPreviewCP(true);
      let body = {
        pre_tasks: pre_tasks,
        contact_persons: selectedContacts,
        preview: true,
        comm_proposal: 'true',
        presentation: isKpAndPres ? 'true' : false,
      };

      if (sendFromLeadPage && lead) {
        body['lead'] = lead;
      }
      if (customer) {
        body['customer'] = customer?.id;
      }

      const url = `${restApiHost}/leads/status/send_commercial_proposal_and_presentation/`;
      const json = await call(
        url,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json?.data) {
        setPreShowKp(false);
        return;
      }
      await getCpHtmlTemplatesById(currentCpTemplateId, userInfo);
      dataForTable.current = formationOfShoulderFromPoints(json?.data.pretasks);
      setEitDataForTable('');
      setKpEditingTable(false);
      setEitDataForUi('');
      setKpEditingUi(false);
      setKpData(json.data);
      return json.data;
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingPreviewCP(false);
    }
  };

  const isEmptyObject = (obj) => {
    for (let i in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, i)) {
        return false;
      }
    }
    return true;
  };

  const previewSentCommercialOffers = async (offer) => {
    if (!currentCpTemplateId) {
      message.warning('Шаблон КП не выбран');
      return;
    }
    await getCpHtmlTemplatesById(currentCpTemplateId, userInfo);
    if (!isEmptyObject(offer?.html)) {
      const htmlData = offer.html;
      setEitDataForTable(htmlData.dataTable);
      setKpEditingTable(true);
      setEitDataForUi(htmlData.dataUi);
      setKpEditingUi(true);
    } else {
      if (offer?.template_data?.pretasks?.length !== 0) {
        dataForTable.current = formationOfShoulderFromPoints(
          offer.template_data.pretasks,
        );
      }
      setKpEditingTable('');
      setKpEditingTable(false);
      setEitDataForUi('');
      setKpEditingUi(false);
    }
    setPreShowKp(true);
    setKpData(offer?.template_data);
    setCommProp(offer);
    setShowTableEditing(true);
    setIsViewSentCP(true);
  };
  const export2PDF = (html, lead, pre_tasks, customer) => {
    const opt = {
      filename: `Коммерческое предложение Квиктранс.pdf`,
      image: { type: 'jpeg', quality: 1 },
      html2canvas: { scale: 3, useCORS: true },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    html2pdf()
      .set(opt)
      .from(html)
      .toPdf()
      .output('datauristring')
      .then(async (pdf) => {
        const blob = await (await fetch(pdf)).blob();
        let filename = `${opt.filename}`;
        const filePDF = new File([blob], filename, { type: 'application/pdf' });
        await sendCommercialProposal(lead, pre_tasks, filePDF, customer);
      });
  };

  const savePdfFileToStorage = (html) => {
    const opt = {
      filename: `Коммерческое предложение Квиктранс.pdf`,
      image: { type: 'jpeg', quality: 1 },
      html2canvas: { scale: 3, useCORS: true },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };
    if (html) {
      html2pdf()
        .set(opt)
        .from(html)
        .toPdf()
        .output('datauristring')
        .save()
        .then(() => message.success('PDF Сохранен'));
    }
  };

  const changeTabMenuEditKP = (activeTab) => {
    if (activeTab === '1') {
      setPreShowKp(false);
      setIsViewSentCP(false);
      setActiveKeyPreTaskOrSendKp('2');
      setPageCommPropsList(1);
    } else {
      setActiveTabMenuEditKp(activeTab);
    }
  };
  const changeTabMenuPreTaskOrSendKp = (tab) => {
    setActiveKeyPreTaskOrSendKp(tab);
  };
  const changeSelectContact = (data) => {
    setSelectedContacts(data);
    setOpenSelectContact(false);
  };

  const defaultValueSelectContact = () => {
    if (sendFromLeadPage && currentLead?.contact_persons?.length === 1) {
      if (currentLead?.contact_persons[0]?.id) {
        changeSelectContact([currentLead?.contact_persons[0]?.id]);
      }
    } else if (customer?.contact_persons?.length === 1) {
      if (customer?.contact_persons[0]?.id) {
        changeSelectContact([customer?.contact_persons[0]?.id]);
      }
    }
  };
  const setSendOptions = (option) => {
    switch (option) {
      case 'kp':
        setSendKp(true);
        setSubject('Коммерческое предложение (QuickTrans)');
        setIsSendPresentation(false);
        break;
      case 'presen':
        setIsSendPresentation(true);
        setSubject('Презентация (QuickTrans)');
        setSendKp(false);
        break;
      case 'kpAndPresen':
        setSendKp(true);
        setSubject('Коммерческое предложение, Презентация (QuickTrans)');
        setIsSendPresentation(true);
        break;
      default:
        setSendKp(false);
        setIsSendPresentation(false);
        break;
    }
  };
  const includeEmailInSelectedContact = (email) => {
    const copySelectedContacts = selectedContacts;
    let include = false;
    for (const emailOrId of copySelectedContacts) {
      if (typeof emailOrId == 'string') {
        if (emailOrId === email) {
          include = true;
        }
      } else if (typeof emailOrId == 'number') {
        for (const contact of sendFromLeadPage
          ? // eslint-disable-next-line no-unsafe-optional-chaining
            currentLead?.contact_persons
          : // eslint-disable-next-line no-unsafe-optional-chaining
            customer?.contact_persons) {
          if (contact?.email === email) {
            include = true;
          }
        }
      }
    }
    return include;
  };
  const addEmailForContact = (value) => {
    if (value.key === 'Enter' && value?.target?.value !== '') {
      if (!isEmail(value?.target?.value)) {
        message.warning('Email некорректный');
        return;
      }
      const copySelectedContacts = selectedContacts;
      if (!includeEmailInSelectedContact(value?.target?.value)) {
        copySelectedContacts.push(value?.target?.value);
        setSelectedContacts(copySelectedContacts);
        setInputSearchContactValue('');
      } else {
        message.warning('Контакт/Сотрудник с таким email уже выбран');
      }
      setOpenSelectContact(false);
    }
  };

  const isEmail = (val) => {
    let regEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regEmail.test(val);
  };
  const sendCommercialProposal = async (
    lead,
    pre_tasks,
    file_pdf,
    customer,
  ) => {
    message.info('Началась отправка письма');
    try {
      const url = `${restApiHost}/leads/status/send_commercial_proposal_and_presentation/`;
      const formData = new FormData();
      selectedContacts?.length > 0 &&
        selectedContacts.map((contact) =>
          formData.append('contact_persons', contact),
        );
      sendKp && formData.append('comm_proposal', sendKp.toString());
      subject && formData.append('subject', subject.toString());
      isSendPresentation &&
        formData.append('presentation', isSendPresentation.toString());
      isAppeal && formData.append('appeal', isAppeal.toString());
      selectedEmailTemplate &&
        formData.append('mail_template', selectedEmailTemplate.toString());
      emailText && formData.append('mail_text', emailText.toString());
      pre_tasks?.length > 0 &&
        pre_tasks.map((task) => formData.append('pre_tasks', task));
      /*pre_tasks?.length > 0 &&
            formData.append("pre_tasks", JSON.stringify(pre_tasks));*/
      file_pdf && formData.append('file_pdf', file_pdf, file_pdf.name);
      const dataTable = kpEditingTable
        ? editDataForTable
        : KpTableSum(dataForTable.current, colorTableSum);
      const dataUi = kpEditingUi ? editDataForUi : KpUi(kpData);
      formData.append('html', JSON.stringify({ dataTable, dataUi }));
      if (sendFromLeadPage && lead) {
        formData.append('lead', lead?.id);
      }
      if (customer) {
        formData.append('customer', customer?.id);
      }
      if (userInfo.current.haveViewBranch) {
        const branchId = getBranchId().toString();
        formData.append('branch', branchId);
      }
      if (getCompanyIdFromStorage()) {
        formData.append('company', `${getCompanyIdFromStorage()}`);
      }
      if (isViewSentCP) {
        formData.append('comm_prop_id', commProp.id);
      }

      const res = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
        body: formData,
      });

      if (res.status === 403) {
        message.warning('У вас недостаточно прав для взаимодействия с лидами');
        return;
      }

      const json = await res.json();

      if (json.status === 'error_connect_mail_server') {
        message.warning('Ошибка соединения с почтовым сервером');
        return;
      }

      if (json.status === 'no_email_pass') {
        message.warning('Вам не выдали пароль от почты');
        return;
      }

      if (res.status === 400) {
        message.warning(json.text);
        return;
      }
      if (res.status === 500) {
        message.error(
          'Ошибка запроса. Коммерческое предложение не удалось отправить',
        );
        return;
      }

      message.success('Письмо успешно отправлено');
      setPreShowKp(false);
      setIsKpAndPres(false);
      setEitDataForTable('');
      setKpEditingTable(false);
      setEitDataForUi('');
      setKpEditingUi(false);
      dataForTable.current = [];
      innerHTMLRef.current = null;
      setSelectedRowKeys([]);
      if (isViewSentCP) {
        setIsViewSentCP(false);
      }
      setCurrentCpTemplateHtml(null);
      setCurrentCpTemplateId(null);
      setActiveTabMenuEditKp('2');
      //handleUpdate(page, filters);
      sendFromLeadPage
        ? currentLead?.id && updateModelData(currentLead?.id)
        : customer?.id && updateModelData(customer?.id);
    } catch (e) {
      console.log(e);
      message.error(
        'Ошибка запроса. Коммерческое предложение не удалось отправить',
      );
    }
  };

  const getHtmlCP = () => {
    const data = {
      __html: cpTemplateWithReplacedPlaceholders(
        kpData,
        kpEditingTable
          ? editDataForTable
          : KpTableSum(dataForTable.current, colorTableSum),
        kpEditingUi ? editDataForUi : KpUi(kpData),
        currentCpTemplateHtml,
      ),
    };
    setHtmlDataForKp(data);
  };

  const handleGetPreTaskList = useCallback(
    async (page, customerId, leadId) => {
      let params = { page };
      if (customerId) {
        params['customer'] = customerId;
      }
      if (leadId) {
        params['lead'] = leadId;
      }
      const res = await getPreTaskList(params, userInfo);
      if (res?.data) {
        setPreTaskList(res?.data);
        setPreTaskListTotal(res?.count ? res.count : 0);
      }
    },
    [getPreTaskList],
  );

  const handleGetLeadOrCustomerContact = useCallback(
    async (customerId, leadId) => {
      let res = null;
      if (!customerId && !leadId) return;
      if (customerId) {
        res = await getContactsCustomer(customerId, userInfo);
      }
      if (leadId) {
        res = await getContactsLead(leadId, userInfo);
      }

      if (res?.data) {
        setContactPersonsOptions(res?.data);
      }
    },
    [getContactsCustomer, getContactsLead],
  );

  const handleGetLeadOrCustomerSendKpList = useCallback(
    async (page, customerId, leadId) => {
      let res = null;
      if (!customerId && !leadId) return;
      if (customerId) {
        res = await getCommPropHistoryCustomer(customerId, userInfo, page);
      }
      if (leadId) {
        res = await getCommPropHistoryLead(leadId, userInfo, page);
      }

      setCommPropsList(res?.data ? res.data : []);
      setTotalCommPropsList(res?.count ? res.count : 0);
    },
    [getCommPropHistoryCustomer, getCommPropHistoryLead],
  );

  return (
    <Modal
      width={1000}
      visible={visibleModal}
      destroyOnClose={true}
      title={'Отправить КП/Презентацию'}
      onCancel={() => {
        setPreShowKp(false);
        setSelectedContacts([]);
        setSelectedRowKeys([]);
        setSelectedRowRows([]);
        setCurrentCpTemplateId(null);
        setOpenSelectContact(false);
        setCurrentCpTemplateHtml(null);
        setCurrentPage(1);
        setPageCommPropsList(1);
        handleUpdate(page, filters);
        closeVisibleModal();
      }}
      footer={[
        <Button
          key="back"
          onClick={() => {
            closeVisibleModal();
            setCurrentPage(1);
            setPageCommPropsList(1);
            setPreShowKp(false);
            setSelectedContacts([]);
            setSelectedRowKeys([]);
            setSelectedRowRows([]);
            setCurrentCpTemplateId(null);
            setOpenSelectContact(false);
            setCurrentCpTemplateHtml(null);
            handleUpdate(page, filters);
          }}
        >
          Закрыть
        </Button>,
        <>
          {optionsValue !== 'presen' ? (
            <>
              {!hasSelected || disableBntSendCp ? (
                <Tooltip
                  title={
                    selectedContacts?.length > 0
                      ? 'Воспользуйтесь функцией предпросмотра'
                      : 'Выберите контакт'
                  }
                >
                  <Button
                    style={{ marginLeft: '8px' }}
                    type="primary"
                    disabled={true}
                  >
                    Скачать
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  onClick={async () => {
                    if (sendKp) {
                      message.info('Началось формирование pdf файла');
                      await savePdfFileToStorage(htmlDataForKp.__html);
                    }
                  }}
                  type="primary"
                  disabled={!hasSelected || disableBntSendCp}
                >
                  Скачать
                </Button>
              )}
            </>
          ) : (
            <></>
          )}
        </>,
        <>
          {!hasSelected || disableBntSendCp ? (
            <Tooltip
              title={
                selectedContacts?.length > 0
                  ? 'Воспользуйтесь функцией предпросмотра'
                  : 'Выберите контакт'
              }
            >
              <Button
                style={{ marginLeft: '8px' }}
                type="primary"
                disabled={true}
              >
                Отправить
              </Button>
            </Tooltip>
          ) : (
            <Button
              onClick={async () => {
                if (sendKp) {
                  message.info('Началось формирование pdf файла');
                  await export2PDF(
                    htmlDataForKp.__html,
                    currentLead,
                    selectedRowKeys,
                    customer,
                  );
                } else {
                  await sendCommercialProposal(
                    currentLead,
                    null,
                    null,
                    customer,
                  );
                }
              }}
              type="primary"
              disabled={!hasSelected || disableBntSendCp}
            >
              Отправить
            </Button>
          )}
        </>,
      ]}
    >
      <Spin spinning={loadingPreviewCP}>
        <div style={{ width: '100%', marginBottom: 10 }}>
          <Row gutter={15}>
            <Col lg={10} md={24} xs={24}>
              <Col lg={24} md={24} xs={24}>
                <div>
                  <p style={{ marginBottom: 5 }}>Контакт</p>
                  <Select
                    getPopupContainer={(trigger) =>
                      isMobile ? trigger.parentNode : document.body
                    }
                    value={selectedContacts}
                    open={openSelectContact}
                    showSearch
                    onSearch={(value) => setInputSearchContactValue(value)}
                    searchValue={inputSearchContactValue}
                    onInputKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.stopPropagation();
                        if (inputSearchContactValue.length === 0) {
                          setOpenSelectContact(false);
                        }
                      }
                    }}
                    optionFilterProp="children"
                    filterOption={(inputValue, option) => {
                      if (option?.children !== undefined) {
                        const data =
                          option['data-value'] !== undefined
                            ? JSON.parse(option['data-value'])
                            : ' ';
                        return (
                          option?.children
                            ?.toLowerCase()
                            .indexOf(inputValue?.toLowerCase()) >= 0 ||
                          data?.email
                            ?.toLowerCase()
                            .indexOf(inputValue?.toLowerCase()) >= 0
                        );
                      }
                    }}
                    autoFocus={false}
                    onKeyUp={(data) => addEmailForContact(data)}
                    onChange={(data, options) =>
                      changeSelectContact(data, options)
                    }
                    style={{ width: '100%' }}
                    onDropdownVisibleChange={() =>
                      !openSelectContact
                        ? setOpenSelectContact(true)
                        : setOpenSelectContact(false)
                    }
                    mode={'multiple'}
                    placeholder={'Выберите контакт из списка'}
                    notFoundContent={'Контакт не найден'}
                  >
                    <OptGroup label={'Контакты'}>
                      {contactPersonsOptions?.length > 0 && (
                        <>
                          {contactPersonsOptions.map((contact) => {
                            return (
                              <Option
                                key={contact?.id}
                                data-value={JSON.stringify({
                                  data: contact,
                                  isContact: true,
                                })}
                                value={contact?.id}
                              >
                                {contact.email
                                  ? contact.full_name + `(${contact.email})` ||
                                    contact.email ||
                                    'Не заданно'
                                  : contact.full_name +
                                    ' ' +
                                    '(email не задан)'}
                              </Option>
                            );
                          })}
                        </>
                      )}
                    </OptGroup>
                    {staffListCopyProps?.length > 0 ? (
                      <OptGroup label={'Сотрудники'}>
                        <>
                          {staffListCopyProps.map((staff) => {
                            return (
                              <Option
                                key={staff?.id}
                                value={staff?.email}
                                data-value={JSON.stringify(staff)}
                              >
                                {staff.email
                                  ? staff.full_name + `(${staff.email})` ||
                                    staff.email ||
                                    'Не заданно'
                                  : staff.full_name + ' ' + '(email не задан)'}
                              </Option>
                            );
                          })}
                        </>
                      </OptGroup>
                    ) : (
                      <></>
                    )}
                  </Select>
                </div>
              </Col>
              <Col lg={24} md={24} xs={24}>
                <div>
                  <p style={{ marginBottom: 5 }}>Опция</p>
                  <Select
                    getPopupContainer={(trigger) =>
                      isMobile ? trigger.parentNode : document.body
                    }
                    disabled={preShowKp}
                    style={{ width: '100%' }}
                    placeholder={'Выберите опцию из списка'}
                    defaultValue={'kp'}
                    onChange={(value) => setOptionsValue(value)}
                  >
                    <option value={'kp'}>Отправлять КП</option>
                    <option value={'presen'}>Отправлять презентацию</option>
                    <option value={'kpAndPresen'}>
                      Отправлять КП и презентацию
                    </option>
                  </Select>
                </div>
              </Col>
              {optionsValue === 'kp' || optionsValue === 'kpAndPresen' ? (
                <Col lg={24} md={24} xs={24}>
                  <div>
                    <p style={{ marginBottom: 5 }}>Шаблон КП</p>
                    <Select
                      getPopupContainer={(trigger) =>
                        isMobile ? trigger.parentNode : document.body
                      }
                      disabled={preShowKp}
                      value={currentCpTemplateId}
                      onChange={async (value) => {
                        setCurrentCpTemplateId(value);
                      }}
                      style={{ width: '100%' }}
                      filterOption={true}
                      placeholder={'Выберите шаблон КП из списка'}
                      notFoundContent={'Шаблоны отсутствуют'}
                    >
                      {cpTemplates?.length > 0 && (
                        <>
                          {cpTemplates.map((template, index) => {
                            return (
                              <Option key={index} value={template.id}>
                                {template?.name
                                  ? template.name
                                  : 'Название не заданно'}
                              </Option>
                            );
                          })}
                        </>
                      )}
                    </Select>
                  </div>
                </Col>
              ) : (
                <></>
              )}
              <Col lg={24} md={24} xs={24}>
                <div>
                  <p style={{ marginBottom: 5 }}>Тема письма</p>
                  <Input
                    type={'text'}
                    placeholder="Тема письма"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  />
                </div>
              </Col>
              <Col lg={24} md={24} xs={24}>
                <div>
                  <p style={{ marginBottom: 5 }}>Шаблон письма</p>
                  <Select
                    getPopupContainer={(trigger) =>
                      isMobile ? trigger.parentNode : document.body
                    }
                    onChange={(value, data) => {
                      setSelectedEmailTemplate(value);
                      const text = JSON.parse(data['data-value']);
                      text?.text && setEmailText(text.text);
                    }}
                    style={{ width: '100%' }}
                    placeholder={'Выберите шаблон из списка'}
                  >
                    {emailTemplates?.length > 0 && (
                      <>
                        {emailTemplates.map((template, index) => {
                          return (
                            <Option
                              key={index}
                              value={template.id}
                              data-value={JSON.stringify(template)}
                            >
                              {template?.template_name}
                            </Option>
                          );
                        })}
                      </>
                    )}
                  </Select>
                </div>
              </Col>
            </Col>
            <Col lg={14} md={24} xs={24}>
              <Col lg={24} xs={24}>
                <div>
                  <p style={{ marginBottom: 5 }}>Текст письма</p>
                  <TextArea
                    value={emailText}
                    onChange={(e) => setEmailText(e.target.value)}
                    //autoSize={{ minRows: 5, maxRows: 5 }}
                    style={
                      optionsValue === 'kp' || optionsValue === 'kpAndPresen'
                        ? { minHeight: '268px' }
                        : { minHeight: '208px' }
                    }
                    placeholder="Произвольный текст письма или из шаблона"
                  />
                </div>
              </Col>
            </Col>
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'flex-end',
                marginTop: '5px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                }}
              >
                <p>Предпросмотр</p>
                <Switch
                  style={{ width: '55px' }}
                  disabled={isViewSentCP ? true : !hasSelected2}
                  checked={preShowKp}
                  onChange={async (e) => {
                    setPreShowKp(e);
                    e &&
                      (await getPreviewKp(
                        currentLead?.id,
                        selectedRowKeys,
                        customer,
                      ));
                  }}
                />
              </div>
            </div>
          </Row>
        </div>

        {preShowKp && kpData ? (
          <div>
            <Tabs
              activeKey={activeTabMenuEditKp}
              onChange={changeTabMenuEditKP}
              defaultActiveKey="2"
            >
              {isViewSentCP ? <TabPane tab="Вернутсья назад" key="1" /> : ''}
              <TabPane tab="Просмотр КП" key="2" className="tab-panel-scroll">
                <div
                  className="view-CP"
                  ref={innerHTMLRef}
                  dangerouslySetInnerHTML={htmlDataForKp}
                />
              </TabPane>
              {!isViewSentCP ? (
                <TabPane tab="Редактировать КП" key="3">
                  <div className="email-editor">
                    <CKEditor
                      editor={ClassicEditor}
                      data={
                        isViewSentCP && kpEditingTable
                          ? editDataForUi
                          : KpUi(kpData)
                      }
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        if (!kpEditingUi) {
                          setKpEditingUi(true);
                        }
                        setEitDataForUi(data);
                      }}
                    />
                  </div>
                  <div style={{ marginTop: '10px', whiteSpace: 'nowrap' }}>
                    <Row
                      sm={5}
                      lg={2}
                      gutter={5}
                      style={{ display: 'flex', justifyContent: 'flex-start' }}
                    >
                      <Col>
                        <Switch
                          checked={showTableEditing}
                          disabled={isViewSentCP}
                          defaultChecked={isViewSentCP}
                          onChange={(e) => {
                            setShowTableEditing(e);
                            if (!e) {
                              if (isViewSentCP) {
                                setEitDataForTable(
                                  kpEditingTable
                                    ? editDataForTable
                                    : KpTableSum(
                                        dataForTable.current,
                                        colorTableSum,
                                      ),
                                );
                              } else {
                                setEitDataForTable(
                                  KpTableSum(
                                    dataForTable.current,
                                    colorTableSum,
                                  ),
                                );
                              }
                            }
                          }}
                        />
                      </Col>
                      <Col>
                        <p>
                          {/* eslint-disable-next-line react/no-unescaped-entities */}
                          Редактировать таблицу "Маршрут/Стоимость перевозки"
                        </p>
                      </Col>
                    </Row>
                    {showTableEditing ? (
                      <div className="email-editor">
                        <CKEditor
                          editor={ClassicEditor}
                          data={
                            isViewSentCP && kpEditingTable
                              ? editDataForTable
                              : KpTableSum(dataForTable.current, colorTableSum)
                          }
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            if (!kpEditingTable) {
                              setKpEditingTable(true);
                            }
                            setEitDataForTable(data);
                          }}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </TabPane>
              ) : (
                <></>
              )}
            </Tabs>
          </div>
        ) : (
          <Tabs
            activeKey={activeKeyPreTaskOrSendKp}
            onChange={changeTabMenuPreTaskOrSendKp}
            defaultActiveKey="1"
          >
            <TabPane tab={'Предварительные заявки'} key="1">
              <ErrorBoundary>
                <div style={{ paddingTop: '10px' }}>
                  <PreAppicationsTable
                    loading={loading}
                    total={preTaskListTotal}
                    pageProps={currentPage}
                    updtFromLead={async () => {
                      await handleGetPreTaskList(
                        currentPage,
                        customer?.id,
                        currentLead?.id,
                      );
                    }}
                    redirect={(state) => {
                      navigate('/pre-applications', { state });
                    }}
                    fromPreApplicationPage={false}
                    rowSel={rowSelection}
                    applications={preTaskList}
                    updateTable={(pageUp) => setCurrentPage(pageUp)}
                    renderInOutPagePreTask={true}
                  />
                </div>
              </ErrorBoundary>
            </TabPane>
            <TabPane tab="Отправленые КП/Презентации" key="2">
              <ErrorBoundary>
                <SentKpTable
                  sentCommercialOffers={commPropsList}
                  loading={loading}
                  total={totalCommPropsList}
                  page={pageCommPropsList}
                  updateList={async (page) => {
                    setPageCommPropsList(page);
                  }}
                  preview={previewSentCommercialOffers}
                />
              </ErrorBoundary>
            </TabPane>
          </Tabs>
        )}
      </Spin>
    </Modal>
  );
};

export default SendKpOrPresentationModal;
