import { Checkbox, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';

const { Option } = Select;

const FilterSelect = ({
  tableFilters,
  columnKey,
  confirm,
  dataList,
  useSingleSelect,
  useAdditionalFilterCheckboxOnNull,
  useApiSearch,
  onSearch,
  selectedKeys,
}) => {
  const [selectValue, setSelectValue] = useState([]);
  const [visibleSelectOptions, setVisibleSelectOptions] = useState(false);
  const [valueIsChanged, setValueIsChanged] = useState(false);
  const [valueAdditionalFilterCheckbox, setAdditionalFilterCheckbox] =
    useState(false);
  const refSelect = useRef(null);
  const isMobile = useCheckMobileScreen();

  const removeFocusedClass = () => {
    const elements = document.getElementsByClassName('ant-select-focused');
    for (let element of elements) {
      element.classList.remove('ant-select-focused');
    }
  };

  useEffect(() => {
    if (!visibleSelectOptions && valueIsChanged) {
      let filters = tableFilters;
      filters[columnKey] = useSingleSelect ? [selectValue] : selectValue;
      confirm(filters);
      setValueIsChanged(false);
      removeFocusedClass();
    }
  }, [visibleSelectOptions]);

  useEffect(() => {
    const value = selectedKeys;
    setSelectValue(value?.length && !value?.includes('no') ? value : []);
  }, [selectedKeys]);

  const onKeyUp = (e) => {
    if (e.key === 'Enter') {
      setVisibleSelectOptions(false);
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', onKeyUp);
    return () => window.removeEventListener('keyup', onKeyUp);
  }, [visibleSelectOptions]);

  const onChangeCheckbox = (val) => {
    setAdditionalFilterCheckbox(val);
    setValueIsChanged(true);
    if (val) {
      setSelectValue([]);
      let filters = tableFilters;
      filters[columnKey] = ['no'];
      confirm(filters);
    } else {
      setSelectValue([]);
      let filters = tableFilters;
      filters[columnKey] = null;
      confirm(filters);
    }
    removeFocusedClass();
  };

  return (
    <div
      style={{
        width: '100%',
        padding: '8px',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
      }}
    >
      <div ref={refSelect} style={{ width: '100%' }}>
        <Select
          getPopupContainer={(trigger) =>
            isMobile ? trigger.parentNode : document.body
          }
          style={{ width: '100%' }}
          allowClear={true}
          autoFocus={false}
          value={selectValue}
          disabled={valueAdditionalFilterCheckbox}
          placeholder={'Выберите из списка или начните поиск...'}
          open={visibleSelectOptions}
          maxTagCount={'responsive'}
          mode={useSingleSelect ? '' : 'multiple'}
          onInputKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.stopPropagation();
            }
          }}
          onSearch={(e) => {
            if (e && useApiSearch) {
              onSearch(e);
            }
          }}
          filterOption={
            useApiSearch
              ? (input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
              : false
          }
          onChange={(val) => {
            if (!visibleSelectOptions) {
              setVisibleSelectOptions(true);
            }
            setValueIsChanged(true);
            setSelectValue(val);
          }}
          onDropdownVisibleChange={(visible) => {
            setVisibleSelectOptions(visible);
          }}
        >
          <>
            {dataList?.map((item) => {
              return (
                <Option key={item?.value} value={item?.value}>
                  {item?.title ? item.title : 'Не задано'}
                </Option>
              );
            })}
          </>
        </Select>
      </div>
      {useAdditionalFilterCheckboxOnNull ? (
        <div
          style={{
            display: 'flex',
            alignContent: 'flex-end',
            alignItems: 'flex-end',
          }}
        >
          <Checkbox
            style={{ marginLeft: '10px' }}
            value={valueAdditionalFilterCheckbox}
            autoFocus={false}
            onChange={(e) => onChangeCheckbox(e.target.checked)}
          >
            <span style={{ color: '#ffffff' }}>Не указано</span>
          </Checkbox>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default FilterSelect;
