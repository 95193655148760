import PropTypes from 'prop-types';
import { Button, Col, Modal, Row } from 'antd';
import React, { useCallback } from 'react';
import '../leads/list/leadListItem.css';

const UniversalFiltersModal = (props) => {
  const handleCloseModal = useCallback(() => {
    props.handleClose && props.handleClose();
  }, []);

  const handleResetFilters = useCallback(() => {
    props.resetFilters && props.resetFilters();
  }, []);

  const renderList = useCallback(
    (columnsList) => {
      if (columnsList?.length <= 0) return;

      return columnsList?.map((item) => {
        const colChild = item.children;

        if (colChild && colChild[0]) {
          const element = { ...colChild[0] };
          return (
            <>
              <Col xs={24}>
                <div className={'mainText'} style={{ padding: '0 8px 0 8px' }}>
                  {item?.title}
                </div>
                <div className={'secondText'}>{element?.title}</div>
              </Col>
            </>
          );
        }
      });
    },
    [props.columnsList],
  );

  return (
    <>
      <Modal
        visible={props.visibleModal}
        title={'Фильтры'}
        onCancel={handleCloseModal}
        footer={[]}
      >
        <Row justify={'end'}>
          <Button
            style={{ color: '#0b7b7f' }}
            type="text"
            onClick={handleResetFilters}
          >
            Сбросить
          </Button>
        </Row>
        <Col xs={24}>{renderList(props.columnsList)}</Col>
      </Modal>
    </>
  );
};

UniversalFiltersModal.propTypes = {
  visibleModal: PropTypes.bool,
  columnsList: PropTypes.arrayOf(PropTypes.object),
  resetFilters: PropTypes.func,
  handleClose: PropTypes.func,
};
export default UniversalFiltersModal;
