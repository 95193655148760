import PropTypes from 'prop-types';
import TaskItemDayOfMonth from '../tasks/TaskItemDayOfMonth';
import DropdownViewAllTasks from './dropdownViewAllTasks/DropdownViewAllTasks';
import detectArray from '../../../../utils/detectArray';

const DayOfMonthBody = (props) => {
  const getCountHiddenTasks = (taskLength) => {
    return props.tasksLeadCall?.length > 0 ? taskLength - 3 : taskLength - 4;
  };

  const getCountViewTask = () => {
    return props.tasksLeadCall?.length > 0 ? 2 : 3;
  };

  return (
    <>
      <div className={'calendar-mouth-day-body'}>
        {props.tasksLeadCall?.length > 0 ? (
          <>
            <div>
              <DropdownViewAllTasks
                day={props.day}
                groupTitle={'ЗВОНКИ'}
                tasks={props.tasksLeadCall}
                isWeekDay={false}
                useForCallGroupLead={true}
                goToCompetitionDate={(day) => {
                  props.goToCompetitionDate(day);
                }}
                handleEditTask={(task) => props.handleEditTask(task)}
              />
            </div>
          </>
        ) : (
          <></>
        )}
        <div
          style={{ height: '100%' }}
          onDoubleClick={() => props.handleCreateTask(props.day)}
        >
          {props.tasks?.length > 0 &&
            props.tasks.map((task, index) => {
              if (index <= getCountViewTask()) {
                if (!detectArray(task)) {
                  return (
                    <TaskItemDayOfMonth
                      key={index}
                      task={task}
                      useForDayViewCalendar={props.useForDayViewCalendar}
                      handleEditTask={(task) => props.handleEditTask(task)}
                    />
                  );
                } else if (detectArray(task)) {
                  return (
                    <DropdownViewAllTasks
                      key={index}
                      day={props.day}
                      tasks={task[1] ? task[1] : []}
                      isWeekDay={true}
                      groupTitle={'ПЕРСОНАЛЬНЫЕ ЗАДАЧИ'}
                      useForDayViewCalendar={props.useForDayViewCalendar}
                      useForCallGroupLead={true}
                      timePeriodTitle={props.timePeriodTitle}
                      goToCompetitionDate={(day) => {
                        props.goToCompetitionDate(day);
                      }}
                      handleEditTask={(task) => props.handleEditTask(task)}
                    />
                  );
                }
              }
            })}
        </div>

        {props.tasks?.length >= 4 &&
          getCountHiddenTasks(props.tasks?.length) > 0 && (
            <div>
              <DropdownViewAllTasks
                day={props.day}
                tasks={props.tasks}
                goToCompetitionDate={(day) => {
                  props.goToCompetitionDate(day);
                }}
                countHiddenTasks={getCountHiddenTasks(props.tasks?.length)}
                handleEditTask={(task) => props.handleEditTask(task)}
              />
            </div>
          )}
      </div>
    </>
  );
};

DayOfMonthBody.propTypes = {
  day: PropTypes.any,
  tasks: PropTypes.any,
  tasksLeadCall: PropTypes.any,
  handleEditTask: PropTypes.func,
  handleCreateTask: PropTypes.func,
  goToCompetitionDate: PropTypes.func,
};

export default DayOfMonthBody;
