import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent } from '@material-ui/core';
import { Button as ButtonAntd, Tooltip, Dropdown, Input, Menu } from 'antd';
import FileExcelOutlined from '@ant-design/icons/lib/icons/FileExcelOutlined';
import ExcelStatModal from './ExcelStatModal';

const { Search } = Input;

const TelephonyToolBar = ({ search }) => {
  const [hideToolbar, setHideToolbar] = useState(false);
  const [visibleExcelModal, setVisibleExcelModal] = useState(false);

  useEffect(() => {
    let hidden = JSON.parse(localStorage.getItem('hide_toolbar'));
    hidden && hidden.telephony && setHideToolbar(hidden.telephony);
  }, []);

  useEffect(() => {
    let hidden = JSON.parse(localStorage.getItem('hide_toolbar'));
    if (hideToolbar) {
      localStorage.setItem(
        'hide_toolbar',
        JSON.stringify({ ...hidden, telephony: true }),
      );
    } else {
      localStorage.setItem(
        'hide_toolbar',
        JSON.stringify({ ...hidden, telephony: false }),
      );
    }
  }, [hideToolbar]);

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={() => setHideToolbar(true)}>
        Скрыть
      </Menu.Item>
    </Menu>
  );

  return (
    <Box>
      <Box sx={{ mt: 3 }}>
        {!hideToolbar ? (
          <>
            <Dropdown overlay={menu} trigger={['contextMenu']}>
              <Card className={'primary-bg-color'}>
                <CardContent>
                  <Box style={{ display: 'flex' }}>
                    <Search
                      placeholder="Поиск абонента CRM"
                      enterButton
                      onChange={(e) => search(e.target.value)}
                    />
                    <Tooltip title={'Отчет по звонкам'} placement={'topLeft'}>
                      <ButtonAntd
                        className={'icons-toolbar-color-white'}
                        onClick={() => setVisibleExcelModal(true)}
                        style={{ marginLeft: 10 }}
                        icon={<FileExcelOutlined />}
                        type={'link'}
                      />
                    </Tooltip>
                  </Box>
                </CardContent>
              </Card>
            </Dropdown>
          </>
        ) : (
          <b
            style={{ cursor: 'pointer', float: 'right', marginBottom: '20px' }}
            onClick={() => setHideToolbar(false)}
          >
            Вернуть панель
          </b>
        )}
      </Box>

      <ExcelStatModal
        visible={visibleExcelModal}
        onClose={() => setVisibleExcelModal(false)}
      />
    </Box>
  );
};

export default TelephonyToolBar;
