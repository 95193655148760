import { combineReducers } from 'redux';
import { chatNotificationReducer } from './chatNotificationReducer';
import { activeChatIdReducer } from './activeChatIdReducer';
import { notificationCountReducer } from './notificationCountReducer';
import { demoAccessReducer } from './demoAccessReducer';
import { userInfoReducer } from './userInfoReducer';

export const rootReducer = combineReducers({
  chatNotification: chatNotificationReducer,
  activeChatId: activeChatIdReducer,
  notificationCount: notificationCountReducer,
  demoAccess: demoAccessReducer,
  userInfo: userInfoReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
