import React from 'react';
import { Navigate, Routes, Route, useLocation } from 'react-router-dom';
import DashboardLayout from '../components/DashboardLayout';
import Account from '../pages/Account';
import CustomerList from '../pages/CustomerList';
import Dashboard from '../pages/Dashboard';
import Task from '../pages/Task';
import Departments from '../pages/Departments';
import Staff from '../pages/Staff';
import Leeds from '../pages/Leeds';
import Contracts from '../pages/Contracts';
import PreApplications from '../pages/PreApplications';
import Telephony from '../pages/Telephony';
import Refbook from '../pages/Refbook';
import Email from '../pages/Email';
import Stocks from '../pages/Stocks';
import MainLayout from '../components/MainLayout';
import Login from '../pages/Login';
import NotUserRole from '../pages/NotUserRole';
import AdminPanel from '../components/admin/AdminPanel';
import Branches from '../pages/Branches';
import { ErrorBoundary } from '../components/ErrorBoundary';
import Calendar from '../pages/Calendar';
import ContinuationAuthorizationPage from '../pages/ContinuationAuthorizationPage';

const getToken = () => {
  return localStorage.getItem('token');
};

const RouterForPage = () => {
  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <RequireAuth>
              <ErrorBoundary>
                <DashboardLayout />
              </ErrorBoundary>
            </RequireAuth>
          }
        >
          <Route
            path="account"
            element={
              <RequireAuth>
                <ErrorBoundary>
                  <Account />
                </ErrorBoundary>
              </RequireAuth>
            }
          />
          <Route
            path="all_customers"
            element={
              <RequireAuth>
                <ErrorBoundary>
                  <CustomerList />
                </ErrorBoundary>
              </RequireAuth>
            }
          />
          <Route
            path="customers"
            element={
              <RequireAuth>
                <ErrorBoundary>
                  <CustomerList
                    usedDefaultFilters={true}
                    defaultFilters={[{ name: 'category', value: ['cli'] }]}
                  />
                </ErrorBoundary>
              </RequireAuth>
            }
          />
          <Route
            path="purveyors"
            element={
              <RequireAuth>
                <ErrorBoundary>
                  <CustomerList
                    usedDefaultFilters={true}
                    defaultFilters={[{ name: 'category', value: ['prv'] }]}
                  />
                </ErrorBoundary>
              </RequireAuth>
            }
          />
          <Route
            path="dashboard"
            element={
              <RequireAuth>
                <ErrorBoundary>
                  <Dashboard />
                </ErrorBoundary>
              </RequireAuth>
            }
          />
          <Route
            path="applications"
            element={
              <RequireAuth>
                <ErrorBoundary>
                  <Task />
                </ErrorBoundary>
              </RequireAuth>
            }
          />
          <Route
            path="departments"
            element={
              <RequireAuth>
                <ErrorBoundary>
                  <Departments />
                </ErrorBoundary>
              </RequireAuth>
            }
          />
          <Route
            path="staff"
            element={
              <RequireAuth>
                <ErrorBoundary>
                  <Staff />
                </ErrorBoundary>
              </RequireAuth>
            }
          />
          <Route
            path="leeds"
            element={
              <ErrorBoundary>
                <Leeds />
              </ErrorBoundary>
            }
          />
          <Route
            path="contracts"
            element={
              <RequireAuth>
                <ErrorBoundary>
                  <Contracts />
                </ErrorBoundary>
              </RequireAuth>
            }
          />
          <Route
            path="pre-applications"
            element={
              <RequireAuth>
                <ErrorBoundary>
                  <PreApplications />
                </ErrorBoundary>
              </RequireAuth>
            }
          />
          <Route
            path="telephony"
            element={
              <RequireAuth>
                <ErrorBoundary>
                  <Telephony />
                </ErrorBoundary>
              </RequireAuth>
            }
          />
          <Route
            path="branches"
            element={
              <RequireAuth>
                <ErrorBoundary>
                  <Branches />
                </ErrorBoundary>
              </RequireAuth>
            }
          />
          <Route
            path="refbook"
            element={
              <RequireAuth>
                <ErrorBoundary>
                  <Refbook />
                </ErrorBoundary>
              </RequireAuth>
            }
          />
          {/*<Route path='email' element={<RolesAuthRoute roles={['admin']}><Email/></RolesAuthRoute>}/>*/}
          <Route
            path="email"
            element={
              <RequireAuth>
                <ErrorBoundary>
                  <Email />
                </ErrorBoundary>
              </RequireAuth>
            }
          />
          <Route
            path="logistics/stocks"
            element={
              <RequireAuth>
                <ErrorBoundary>
                  <Stocks />
                </ErrorBoundary>
              </RequireAuth>
            }
          />
          <Route
            path="/"
            element={
              <RequireAuth>
                {' '}
                <Navigate to="/dashboard" />{' '}
              </RequireAuth>
            }
          />
          <Route
            path="notUserRole"
            element={
              <RequireAuth>
                <ErrorBoundary>
                  <NotUserRole />
                </ErrorBoundary>
              </RequireAuth>
            }
          />
          <Route
            path="admin-panel"
            element={
              <RequireAuth>
                <ErrorBoundary>
                  <AdminPanel />
                </ErrorBoundary>
              </RequireAuth>
            }
          />
          <Route
            path="calendar"
            element={
              <RequireAuth>
                <ErrorBoundary>
                  <Calendar />
                </ErrorBoundary>
              </RequireAuth>
            }
          />

          <Route
            path="*"
            element={
              <RequireAuth>
                {' '}
                <Navigate to="/" />{' '}
              </RequireAuth>
            }
          />
        </Route>

        <Route
          path="/"
          element={
            <OnlyUnauthorized>
              <ErrorBoundary>
                <MainLayout />
              </ErrorBoundary>
            </OnlyUnauthorized>
          }
        >
          <Route
            path="login"
            element={
              <OnlyUnauthorized>
                <ErrorBoundary>
                  <Login />
                </ErrorBoundary>
              </OnlyUnauthorized>
            }
          />
          {/* <Route path='404' element={
                        <ErrorBoundary>
                            <NotFound/>
                        </ErrorBoundary>
                    }/>*/}
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Route>

        <Route
          path="/continuationAuthorization"
          element={<ContinuationAuthorizationPage />}
        />
      </Routes>
    </div>
  );
};

function RequireAuth({ children }) {
  let location = useLocation();
  if (!getToken()) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

function OnlyUnauthorized({ children }) {
  let location = useLocation();
  if (getToken()) {
    return <Navigate to="/dashboard" state={{ from: location }} replace />;
  }

  return children;
}

export default RouterForPage;
