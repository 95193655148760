import {
  horizontalListSortingStrategy,
  SortableContext,
  useSortable,
} from '@dnd-kit/sortable';
import ColumnsItem from './ColumnsItem';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

const ColumnContainer = (props) => {
  const columnsIds = useMemo(() => {
    return props.columnNodes?.map((column) => column?.index);
  }, [props.columnNodes]);

  const { setNodeRef } = useSortable({
    id: props.column?.id ? props.column.id : '',
    data: {
      type: 'area',
      column: props.column,
    },
  });

  return (
    <>
      {!props.column.tableStyle ? (
        <span
          style={{
            fontSize: '18px',
            fontWeight: '500',
            color: 'rgba(5,5,5,0.76)',
          }}
        >
          {props.column.name}
        </span>
      ) : (
        <></>
      )}
      <div
        id="custom-scroll"
        ref={setNodeRef}
        style={{
          /*    display: 'grid',
                    gridAutoFlow: 'column',
                    gridAutoColumns: 'auto',*/
          display: 'flex',
          flexDirection: 'row',
          minHeight: props.column.tableStyle ? '80px' : '150px',
          flexWrap: props.column.tableStyle ? 'nowrap' : 'wrap',
          overflowX: 'auto',
          overflowY: 'hidden',
          backgroundColor: props.column.tableStyle ? '#e2f3f1' : '',
          marginTop: props.column.tableStyle ? '100px' : '10px',
          borderRadius: '0 0 10px 10px',
        }}
      >
        <SortableContext
          items={columnsIds}
          strategy={horizontalListSortingStrategy}
        >
          {props.columnNodes?.map((column) => (
            <ColumnsItem
              key={column?.index}
              column={column}
              useTableStyle={props.column.tableStyle}
            />
          ))}
        </SortableContext>
      </div>
    </>
  );
};

ColumnContainer.props = {
  column: PropTypes.any,
  columnNodes: PropTypes.any,
};

export default ColumnContainer;
