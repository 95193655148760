import ExclamationCircleOutlined from '@ant-design/icons/lib/icons/ExclamationCircleOutlined';
import React from 'react';
import { Modal } from 'antd';
const { confirm } = Modal;

export function showConfirm(onOk, onCancel, title) {
  confirm({
    title: title || 'Хотите ли вы сохранить внесенные изменения?',
    icon: <ExclamationCircleOutlined />,
    okText: 'Подтвердить',
    cancelText: 'Отклонить',
    onOk() {
      onOk();
    },
    onCancel() {
      onCancel();
    },
  });
}
