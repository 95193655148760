import { restApiHost } from '../../utils/appVariables';
import { call } from '../../apiUtils/call';
import { message } from 'antd';
import { objectToFormData } from '../../utils/objectToFormData';

export async function getGeneratedContractPdfAndDocxByTemplateId(
  customerId,
  managerId,
  contractTemplateId,
  selectedFile,
  context,
  userInfo,
) {
  const body = {
    customer: customerId ? customerId : null,
    manager: managerId ? managerId : null,
    contract_template: contractTemplateId ? contractTemplateId : null,
    context: context ? context : null,
  };
  if (selectedFile) {
    body['contract'] = selectedFile;
  }
  let headers = {
    Authorization: `Token ${localStorage.getItem('token')}`,
  };

  try {
    const url = `${restApiHost}/functions/create_contract_docx/`;
    const json = await call(
      url,
      {
        method: 'POST',
        headers: headers,
        body: objectToFormData(body),
      },
      userInfo.current.haveViewBranch,
    );

    if (json?.data) {
      return json.data;
    }
  } catch (e) {
    console.log('get the generated contract err', e);
    message.error('Ошибка запроса на получение сгенерированного договора');
  }
}
