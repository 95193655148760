import CalendarHeader from '../components/calendar/CalendarHeader';
import { Helmet } from 'react-helmet';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, Container } from '@material-ui/core';
import { ErrorBoundary } from '../components/ErrorBoundary';
import { getMonth } from '../components/calendar/calendarUtils/getMouth';
import dayjs from 'dayjs';
import Month from '../components/calendar/month/Month';
import AdditionalPanelMonth from '../components/calendar/month/AdditionalPanelMonth';
import CalendarTaskModal from '../components/calendar/modals/CalendarTaskModal';
import {
  getCalendarEventById,
  getCalendarEventsTasks,
} from '../components/calendar/callApiFuctions/tasks/tasksCallApi';
import { userInformations } from '../contex';
import Week from '../components/calendar/week/Week';
import getIndexWeekByDay from '../components/calendar/calendarUtils/getIndexWeekByDay';
import CalendarSidebar from '../components/calendar/calendarSidebar/CalendarSidebar';
import moment from 'moment';
import Day from '../components/calendar/day/Day';
import { useLocation } from 'react-router-dom';
import getChatNotificationList from '../logic/notifications/ChatNotificationApiFunction';
import { globalStore } from '../store';
import { TodoActionTypes } from '../store/todo/chatNotification/chatNotificationTodo';
import { useTypedSelector } from '../store/hooks/useTypedSelector';
import formationDocumentTitle from '../utils/formationDocumentTitle';
import { message } from 'antd';
import useCheckMobileScreen from '../hooks/useCheckMobileScreen';

const Calendar = () => {
  dayjs.locale('ru');
  moment.locale('ru');
  const { userInfo } = useContext(userInformations);
  const location = useLocation();
  const [currentMonth, setCurrentMonth] = useState(
    getMonth(dayjs().month(), dayjs().year()),
  );
  const [currentMonthIndex, setCurrentMonthIndex] = useState(dayjs().month());
  const [currentYear, setCurrentYear] = useState(dayjs().year());
  const [visibleTaskModal, setVisibleTaskModal] = useState(false);
  const [editingTask, setEditingTask] = useState(null);
  const [loading, setLoading] = useState(false);
  const [defaultDateForTaskModal, setDefaultDateForTaskModal] = useState(null);
  const [defaultTimeForTaskModal, setDefaultTimeForTaskModal] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [currentTypeCalendar, setCurrentTypeCalendar] = useState('month');
  const [indexCurrentWeekMonth, setIndexCurrentWeekMonth] = useState(0);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [params, setParams] = useState();
  const wasViewingMessages = useTypedSelector(
    (state) => state.chatNotification.wasViewingMessages,
  );
  const notificationCount = useTypedSelector(
    (state) => state.notificationCount.count,
  );
  const [firstRender, setFirstRender] = useState(true);
  const firstAndLastDayOfMonth = useMemo(
    () => getFirstAndLastDay(currentTypeCalendar, currentDate, currentMonth),
    [currentMonth, currentDate],
  );
  const isMobile = useCheckMobileScreen();

  useEffect(async () => {
    if (location?.state?.eventId) {
      const hide = message.loading('Переход к задаче', 1000);
      try {
        await handleGetEventById(location?.state?.eventId, true);
      } catch (e) {
        console.log(e);
      } finally {
        hide();
      }
    }
  }, [location]);

  useEffect(async () => {
    if (firstRender) {
      await getTasks(params, firstAndLastDayOfMonth);
      setFirstRender(false);
    }
  }, [firstRender]);

  useEffect(async () => {
    !firstRender && (await getTasks(params, firstAndLastDayOfMonth));
  }, [firstAndLastDayOfMonth, selectedStaff]);

  useEffect(() => {
    if (currentDate) {
      switchCalendarCurrentDate(currentDate);
    }
  }, [currentTypeCalendar]);

  function changeAtTypeMonth(index, year) {
    if (index <= -1) {
      year--;
      setCurrentMonthIndex(11);
      setCurrentDate(new Date(currentYear, 11, 1));
      setCurrentMonth(getMonth(11, year));
      setCurrentYear(year);
      return;
    }
    if (index > 11) {
      year++;
      setCurrentMonthIndex(0);
      setCurrentDate(new Date(currentYear, 0, 1));
      setCurrentMonth(getMonth(0, year));
      setCurrentYear(year);
      return;
    }
    setCurrentMonthIndex(index);
    setCurrentDate(new Date(currentYear, index, 1));
    setCurrentMonth(getMonth(index, year));
  }

  function changeAtTypeWeek(indexMonth, year, indexWeek, action) {
    let copyIndexWeek = indexWeek;
    action === 'prev' ? copyIndexWeek-- : copyIndexWeek++;
    if (copyIndexWeek >= 0 && copyIndexWeek <= 4) {
      setIndexCurrentWeekMonth(copyIndexWeek);
      return;
    }
    if (copyIndexWeek > 4) {
      setIndexCurrentWeekMonth(0);
    }
    if (copyIndexWeek <= -1) {
      setIndexCurrentWeekMonth(4);
    }
    changeAtTypeMonth(indexMonth, year);
  }

  const changeMonth = (action, index, viewCalendarType, indexWeek) => {
    let year = currentYear;
    switch (viewCalendarType) {
      case 'month': {
        changeAtTypeMonth(index, year);
        break;
      }
      case 'week': {
        changeAtTypeWeek(index, year, indexWeek, action);
        break;
      }
      default:
        return;
    }
  };

  const switchToToday = () => {
    setCurrentDate(new Date(moment.now()));
    setCurrentTypeCalendar('day');
  };

  const switchCalendarCurrentDate = (date) => {
    const newIndexMonth = moment(date).month();
    const newYear = moment(date).year();
    const newMonthData = getMonth(newIndexMonth, newYear);
    setIndexCurrentWeekMonth(
      getIndexWeekByDay(newMonthData, dayjs(date).format('DD-MM-YY')),
    );
    setCurrentDate(new Date(date));
    setCurrentMonth(newMonthData);
    setCurrentMonthIndex(newIndexMonth);
    setCurrentYear(newYear);
  };

  const changeViewTypeCalendar = (viewCalendarType, currentDate) => {
    if (viewCalendarType === 'week' && dayjs().month() === currentMonthIndex) {
      setIndexCurrentWeekMonth(
        getIndexWeekByDay(currentMonth, moment(currentDate).format('DD-MM-YY')),
      );
    } else {
      setIndexCurrentWeekMonth(0);
    }
    setCurrentTypeCalendar(viewCalendarType);
  };

  const getTasks = async (params, firstAndLastDayOfMonth) => {
    setLoading(true);
    if (params) {
      setParams(params);
    }

    const resTasks = await getCalendarEventsTasks(
      params,
      userInfo,
      selectedStaff,
      firstAndLastDayOfMonth,
    );
    if (resTasks?.data) {
      setTasks(resTasks?.data);
    }
    setLoading(false);
  };

  const handleGetEventById = async (eventId, openModal) => {
    try {
      setLoading(true);
      if (!eventId) return;
      const event = await getCalendarEventById(eventId, userInfo);
      if (event) {
        switchCalendarCurrentDate(
          moment(event?.end).format('YYYY-MM-DD HH:mm'),
        );
        setEditingTask(event);
        openModal && setVisibleTaskModal(true);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const goToCompetitionDate = (day) => {
    if (!day) return;
    const newCurrentDate = new Date(dayjs(day).format('YYYY-MM-DD'));
    setCurrentDate(newCurrentDate);
    setCurrentTypeCalendar('day');
  };
  const renderCalendarView = (viewName, indexCurrentWeekMonth) => {
    switch (viewName) {
      case 'month':
        return (
          <Month
            month={currentMonth}
            tasks={tasks}
            loading={loading}
            selectedStaff={selectedStaff}
            useGroupCallLead={true}
            goToCompetitionDate={(day) => {
              goToCompetitionDate(day);
            }}
            handleEditTask={(task) => {
              setEditingTask(task);
              setVisibleTaskModal(true);
            }}
            handleCreateTask={(day) => {
              setDefaultDateForTaskModal(day.format('YYYY-MM-DD'));
              setVisibleTaskModal(true);
            }}
          />
        );
      case 'week':
        return (
          <Week
            month={currentMonth}
            indexCurrentWeekMonth={indexCurrentWeekMonth}
            tasks={tasks}
            loading={loading}
            selectedStaff={selectedStaff}
            goToCompetitionDate={(day) => {
              goToCompetitionDate(day);
            }}
            handleEditTask={(task) => {
              setEditingTask(task);
              setVisibleTaskModal(true);
            }}
            handleCreateTask={(day, time) => {
              setDefaultTimeForTaskModal(time);
              setDefaultDateForTaskModal(day.format('YYYY-MM-DD'));
              setVisibleTaskModal(true);
            }}
          />
        );
      case 'day':
        return (
          <Day
            currentDate={currentDate}
            tasks={tasks}
            loading={loading}
            selectedStaff={selectedStaff}
            handleEditTask={(task) => {
              setEditingTask(task);
              setVisibleTaskModal(true);
            }}
            handleCreateTask={(day, time) => {
              setDefaultTimeForTaskModal(time);
              setDefaultDateForTaskModal(day.format('YYYY-MM-DD'));
              setVisibleTaskModal(true);
            }}
          />
        );
      default:
        return <></>;
    }
  };

  function getFirstAndLastDay(calendarViewType, currentDate, currentMonth) {
    if (calendarViewType === 'day' && currentDate) {
      return getPeriodDay(currentDate);
    } else {
      return getFirstAndLastDaysMonth(currentMonth);
    }
  }

  function getPeriodDay(date) {
    if (!date) return;
    const start = dayjs(date)
      .set('hour', 0)
      .set('minute', 0)
      .set('second', 0)
      .format('YYYY-MM-DDTHH:ss:mm');
    const end = dayjs(date)
      .set('hour', 23)
      .set('minute', 59)
      .set('second', 59)
      .format('YYYY-MM-DDTHH:ss:mm');
    return {
      firstDay: start,
      lastDay: end,
    };
  }

  function getFirstAndLastDaysMonth(monthData) {
    if (!monthData) return;
    const firstWeek = monthData[0];
    const lastWeek = monthData[monthData?.length - 1];

    return {
      firstDay: firstWeek[0]
        ? dayjs(firstWeek[0])
            .set('hour', 0)
            .set('minute', 0)
            .set('second', 0)
            .format('YYYY-MM-DDTHH:ss:mm')
        : null,
      lastDay: lastWeek[lastWeek?.length - 1]
        ? dayjs(lastWeek[lastWeek?.length - 1])
            .set('hour', 23)
            .set('minute', 59)
            .set('second', 59)
            .format('YYYY-MM-DDTHH:ss:mm')
        : null,
    };
  }

  async function handleCloseTaskModal() {
    if (editingTask) {
      setEditingTask(null);
    }
    if (defaultDateForTaskModal) {
      setDefaultDateForTaskModal(null);
    }
    if (defaultTimeForTaskModal) {
      setDefaultTimeForTaskModal(null);
    }
    setVisibleTaskModal(false);
    await getTasks(params, firstAndLastDayOfMonth);
    if (wasViewingMessages) {
      await handleSetGlobalChatNotificationCount();
    }
  }

  const handleSetGlobalChatNotificationCount = async () => {
    const res = await getChatNotificationList(0, userInfo);
    if (res?.count !== null && res?.count !== undefined) {
      globalStore.dispatch({
        type: TodoActionTypes.SET_COUNT,
        payload: res.count,
      });
    }
    globalStore.dispatch({
      type: TodoActionTypes.SET_WAS_VIEWING_MESSAGE,
      payload: false,
    });
  };

  return (
    <>
      <Helmet>
        <title>
          {formationDocumentTitle('Планер | Квик Транс', notificationCount)}
        </title>
      </Helmet>
      <Box className={'CalendarWrapper'}>
        <ErrorBoundary>
          <CalendarSidebar
            currentDate={currentDate}
            switchCalendarCurrentDate={(date) =>
              switchCalendarCurrentDate(date, currentTypeCalendar)
            }
            filterEventByStaff={async (staffId) => setSelectedStaff(staffId)}
            handleUpdateTask={async (filters) =>
              await getTasks(filters, firstAndLastDayOfMonth)
            }
            isMobile={isMobile}
            tasks={tasks}
            openTaskModal={(val) => setVisibleTaskModal(val)}
            handleEditTask={(task) => {
              setEditingTask(task);
              setVisibleTaskModal(true);
            }}
          />
        </ErrorBoundary>
        <Container className={'Calendar-full-wrapper'} maxWidth={false}>
          <ErrorBoundary>
            <CalendarHeader
              openTaskModal={(val) => setVisibleTaskModal(val)}
              switcherValue={currentTypeCalendar}
              changeValueMainTabSwitcher={(val) =>
                changeViewTypeCalendar(val, currentDate)
              }
              viewCalendarType={currentTypeCalendar}
            />
          </ErrorBoundary>

          <ErrorBoundary>
            <AdditionalPanelMonth
              currentMonthIndex={currentMonthIndex}
              currentYear={currentYear}
              displayMonthSwitcher={currentTypeCalendar !== 'day'}
              changeMonthIndex={(index, action) =>
                changeMonth(
                  action,
                  index,
                  currentTypeCalendar,
                  indexCurrentWeekMonth,
                )
              }
              switchToToday={() => switchToToday()}
            />
          </ErrorBoundary>

          <ErrorBoundary>
            <div
              className={'Calendar-body-wrapper'}
              style={{ paddingTop: '10px' }}
            >
              {renderCalendarView(currentTypeCalendar, indexCurrentWeekMonth)}
            </div>
          </ErrorBoundary>

          <ErrorBoundary>
            <CalendarTaskModal
              visible={visibleTaskModal}
              editingItem={editingTask}
              setEditingTask={(item) => setEditingTask(item)}
              getEventById={async (eventId) =>
                await handleGetEventById(eventId)
              }
              handleUpdateTask={async () =>
                await getTasks(params, firstAndLastDayOfMonth)
              }
              defaultDate={defaultDateForTaskModal}
              defaultTime={defaultTimeForTaskModal}
              closeModal={async () => {
                await handleCloseTaskModal();
              }}
            />
          </ErrorBoundary>
        </Container>
      </Box>
    </>
  );
};

export default Calendar;
