import { Card, CardContent, Typography } from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { Tooltip } from 'antd';
import React from 'react';

const TotalProfit = (props) => (
  <Card {...props}>
    <CardContent className={'TaskProgress-item'}>
      <div className="TaskProgress-content-item">
        <Typography color="textSecondary" gutterBottom variant="h6">
          Суммы заявок
        </Typography>
        <Typography
          color="textPrimary"
          variant="h4"
          className={'typography-sums-preTasks'}
        >
          <Tooltip
            overlayStyle={{ fontSize: '17px' }}
            placement={'topLeft'}
            title={props.stat?.tasks?.sum || 0}
          >
            {props?.stat?.tasks?.sum || 0}
          </Tooltip>
        </Typography>
      </div>
      <div className="TaskProgress-content-item TaskProgress-avatar avatar-blue">
        <AttachMoneyIcon />
      </div>
    </CardContent>
  </Card>
);

export default TotalProfit;
