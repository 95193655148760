import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import LeedsToolbar from '../components/leeds/LeedsToolbar';
import LeedsTable from '../components/leeds/LeedsTable';
import { Tabs } from 'antd';
import { useLocation } from 'react-router-dom';
import { userInformations } from '../contex';
import { ErrorBoundary } from '../components/ErrorBoundary';
import getLeedsList, { getLeadById } from '../logic/lead/LeadApiFunctions';
import formationDocumentTitle from '../utils/formationDocumentTitle';
import { useTypedSelector } from '../store/hooks/useTypedSelector';
import useAsyncEffect from 'use-async-effect';
import useCheckMobileScreen from '../hooks/useCheckMobileScreen';

const { TabPane } = Tabs;

const Leeds = () => {
  const params = useLocation();
  const [loading, setLoading] = useState(false);
  const [leads, setLeads] = useState([]);
  const [totalLeads, setTotalLeads] = useState(0);
  const [page, setPage] = useState(1);
  const [currStatusFilter, setCurrStatusFilter] = useState(null);
  const isMobile = useCheckMobileScreen();
  const { userInfo } = useContext(userInformations);

  //modal redirect lead
  const [modalLead, setModalLead] = useState(null);
  const [leadForSearchAfterCreatePreTask, setLeadForSearchAfterCreatePreTask] =
    useState(null);

  const [visibleFilters, setVisibleFilters] = useState(false);
  const [visibleTableSetting, setVisibleTableSetting] = useState(false);
  const notificationCount = useTypedSelector(
    (state) => state.notificationCount.count,
  );
  const scrollingLoadedListRef = useRef(null);

  useAsyncEffect(async () => {
    await callGetLeedsList(page, null, false);
  }, []);

  useEffect(async () => {
    if (visibleFilters) {
      setVisibleFilters(false);
    }
    if (params?.state?.id) {
      await getLeadForRedirectModal(params.state?.id);
    } else {
      setModalLead(null);
    }
  }, [params]);

  const getLeadForRedirectModal = useCallback(
    async (leadId) => {
      const res = await getLeadById(leadId, userInfo);
      if (res?.data) {
        setModalLead(res.data);
      }
    },
    [setModalLead],
  );

  const handleUpdate = useCallback(
    async (page, params, afterCreatePreTask, concatData) => {
      setPage(page ? page : 1);
      if (afterCreatePreTask && leadForSearchAfterCreatePreTask) {
        setVisibleFilters(false);
        const newFilters = {
          filter: {
            search: leadForSearchAfterCreatePreTask?.company_name
              ? leadForSearchAfterCreatePreTask?.company_name
              : currStatusFilter?.filter?.search,
          },
        };
        setCurrStatusFilter(newFilters);
        await callGetLeedsList(1, newFilters, concatData);
      } else {
        setCurrStatusFilter(params);
        await callGetLeedsList(page, params, concatData);
      }
    },
    [
      setPage,
      leadForSearchAfterCreatePreTask,
      currStatusFilter,
      setCurrStatusFilter,
    ],
  );

  const callGetLeedsList = useCallback(
    async (page, params, concatData = false) => {
      setLoading(true);
      isMobile && setPage(page);
      try {
        const res = await getLeedsList(page, params, userInfo);
        if (res) {
          concatData
            ? setLeads((prevState) => prevState.concat(res?.data))
            : setLeads(res?.data);
          setTotalLeads(res?.count);
        }
        /*if (leadForSearchAfterCreatePreTask) {
                setLeadForSearchAfterCreatePreTask(null)
            }*/
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    [setLeads, setTotalLeads, setLeadForSearchAfterCreatePreTask],
  );

  const handleScrollToTopMobileList = useCallback(() => {
    if (scrollingLoadedListRef.current?.scrollTop) {
      scrollingLoadedListRef.current.scrollTop = 0;
    }
  }, [scrollingLoadedListRef]);

  return (
    <>
      <Helmet>
        <title>
          {formationDocumentTitle('Лиды | Квик Транс', notificationCount)}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container
          maxWidth={false}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <ErrorBoundary>
            <LeedsToolbar
              leadCompanyName={leadForSearchAfterCreatePreTask?.company_name}
              currStatusFilter={currStatusFilter}
              page={page}
              handleUpdate={async (page, filters, afterCreatePreTask) => {
                isMobile && handleScrollToTopMobileList();
                await handleUpdate(
                  isMobile ? 1 : page,
                  filters,
                  afterCreatePreTask,
                );
              }}
              loading={loading}
              totalLeads={totalLeads}
              leads={leads}
              clearLeadForSearchAfterCreatePreTask={() => {
                setLeadForSearchAfterCreatePreTask(null);
              }}
              handleScrollToTopMobileList={handleScrollToTopMobileList}
              changeVisibleFilters={async () => {
                setVisibleFilters(!visibleFilters);
                if (visibleFilters) {
                  await handleUpdate(1, null, false, false);
                }
              }}
              changeVisibleTableSetting={() =>
                setVisibleTableSetting(!visibleTableSetting)
              }
            />
          </ErrorBoundary>
          <Tabs
            type="card"
            defaultActiveKey="1"
            onChange={async () => await handleUpdate(1, null, null)}
          >
            <TabPane
              tabBarStyle={{ margin: '0px', position: 'relative' }}
              tab="Лиды CRM"
              key="1"
            >
              <ErrorBoundary>
                <LeedsTable
                  modalLead={modalLead}
                  currStatusFilter={currStatusFilter}
                  handleUpdate={async (
                    page,
                    filters,
                    afterCreatePreTask,
                    concatData,
                  ) => {
                    await handleUpdate(
                      page,
                      filters,
                      afterCreatePreTask,
                      concatData,
                    );
                  }}
                  page={page}
                  setPage={setPage}
                  totalLeads={totalLeads}
                  leads={leads}
                  setLoading={(val) => setLoading(val)}
                  loading={loading}
                  visibleFilters={visibleFilters}
                  setVisibleFilters={(visible) => {
                    setVisibleFilters(visible);
                  }}
                  scrollingLoadedListRef={scrollingLoadedListRef}
                  handleScrollToTopMobileList={handleScrollToTopMobileList}
                  visibleTableSetting={visibleTableSetting}
                  onCloseDrawerTableSetting={() =>
                    setVisibleTableSetting(false)
                  }
                  setPreTaskLead={(lead) =>
                    setLeadForSearchAfterCreatePreTask(lead)
                  }
                />
              </ErrorBoundary>
            </TabPane>
          </Tabs>
        </Container>
      </Box>
    </>
  );
};

export default Leeds;
