export default function filterActiveColumns(columnsList, expanded = false) {
  let filteredColumns = expanded
    ? columnsList?.filter(
        (item) => item?.key !== 'action' && item?.columnId === 'active',
      )
    : columnsList?.filter((column) => column?.columnId === 'active');

  for (const column of filteredColumns) {
    if (column?.isGroup) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      const columnGroupItems = [...column?.children];
      for (let item of columnGroupItems) {
        item.children = item.children.filter((item) => item.visible === true);
      }
      continue;
    }
    if (column?.children?.length > 0) {
      column.children = column.children.filter((item) => item.visible === true);
    }
  }
  return filteredColumns;
}

export function findObjectByUniqueIndex(arr, uniqueIndex) {
  for (const obj of arr) {
    if (obj.uniqueIndex === uniqueIndex) {
      return obj;
    }
  }

  return null;
}
