import { restApiHost } from '../../../utils/appVariables';
import { call } from '../../../apiUtils/call';
import { message } from 'antd';

export async function editTableColumn(columnId, body, userInfo) {
  if (!columnId) return;
  try {
    const url = `${restApiHost}/base/table_column_flags/${columnId}/`;
    const json = await call(
      url,
      {
        method: 'PATCH',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      userInfo?.current?.haveViewBranch,
    );

    if (!json) return;

    return json;
  } catch (e) {
    console.log('edit table column fetch err', e);
    message.error('Ошибка запроса на изменения данных колонки');
  }
}
