import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import CustomerListToolbar from '../components/customer/CustomerListToolbar';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { restApiHost } from '../utils/appVariables';
import CustomersTable from '../components/customer/CustomersTable';
import { message } from 'antd';
import { convertObjFilterToUrl } from '../utils/getFilters';
import { useLocation } from 'react-router-dom';
import { userInformations } from '../contex';
import { call } from '../apiUtils/call';
import { ErrorBoundary } from '../components/ErrorBoundary';
import formationDocumentTitle from '../utils/formationDocumentTitle';
import { useTypedSelector } from '../store/hooks/useTypedSelector';
import useCheckMobileScreen from '../hooks/useCheckMobileScreen';

const CustomerList = ({ defaultFilters, usedDefaultFilters }) => {
  const [customers, setCustomers] = useState([]);
  const [loadingCustomers, setLoadingCustomers] = useState(false);
  const [total, setTotal] = useState(0);
  const [modalCustomer, setModalCustomer] = useState(null);
  const { userInfo } = useContext(userInformations);
  const [
    customerForSearchAfterCreatePreTask,
    setCustomerForSearchAfterCreatePreTask,
  ] = useState(null);
  const params = useLocation();
  const [currStatusFilter, setCurrStatusFilter] = useState(null);
  const [page, setPage] = useState(1);
  const [visibleFilters, setVisibleFilters] = useState(false);
  const [visibleTableSetting, setVisibleTableSetting] = useState(false);
  const notificationCount = useTypedSelector(
    (state) => state.notificationCount.count,
  );
  const isMobile = useCheckMobileScreen();
  const scrollingLoadedListRef = useRef(null);

  useEffect(async () => {
    if (!usedDefaultFilters) {
      await handleUpdate(1, null);
    }
  }, [usedDefaultFilters]);

  useEffect(() => {
    if (visibleFilters) {
      setVisibleFilters(false);
    }
  }, [params]);

  useEffect(async () => {
    if (params?.state?.id) {
      await getCustomerForRedirectModal(params.state.id);
    } else {
      setModalCustomer(null);
    }
  }, [params?.state?.id]);

  const getCustomerForRedirectModal = async (id) => {
    try {
      const url = `${restApiHost}/customers/list/${id}`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (json) {
        setModalCustomer(json?.data);
      }
    } catch (e) {
      console.log('get customer err', e);
      message.error('Ошибка получения данных клиента');
    } finally {
      setLoadingCustomers(false);
    }
  };

  const searchCustomers = async (keyword) => {
    setLoadingCustomers(true);
    try {
      const url = `${restApiHost}/customers/list/?search=${keyword}`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      setCustomers(json?.data ? json.data : []);
      setTotal(json.count);
    } catch (e) {
      console.log('search clients err', e);
      message.error('Ошибка запроса для поиска клиента');
      setLoadingCustomers(false);
    } finally {
      setLoadingCustomers(false);
    }
  };

  const handleUpdate = async (
    page = 1,
    params,
    afterCreatePreTask,
    concatData = false,
  ) => {
    setPage(page);
    if (afterCreatePreTask && customerForSearchAfterCreatePreTask) {
      setVisibleFilters(false);
      let newFilters = { filter: { ...params } };
      if (usedDefaultFilters) {
        newFilters = {
          ...params,
          filter: {
            ...params.filter,
            search: customerForSearchAfterCreatePreTask?.short_name
              ? customerForSearchAfterCreatePreTask?.short_name
              : currStatusFilter?.filter?.search,
          },
        };
      } else {
        newFilters = {
          filter: {
            search: customerForSearchAfterCreatePreTask?.short_name
              ? customerForSearchAfterCreatePreTask?.short_name
              : currStatusFilter?.filter?.search,
          },
        };
      }
      setCurrStatusFilter(newFilters);
      await getCustomers(1, newFilters, concatData);
    } else {
      setCurrStatusFilter(params);
      await getCustomers(page, params, concatData);
    }
  };

  const getCustomers = async (page = 1, params, concatData = false) => {
    let urlParams;

    setLoadingCustomers(true);

    if (params) {
      urlParams = convertObjFilterToUrl(params, null);
    }

    try {
      const url = urlParams
        ? `${restApiHost}/customers/list/?page=${page}${urlParams}`
        : `${restApiHost}/customers/list/?page=${page}`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
        true,
      );

      if (!json) return;

      concatData
        ? setCustomers((prevState) => prevState.concat(json?.data))
        : setCustomers(json?.data ? json.data : []);
      setTotal(json.count);
    } catch (e) {
      console.log('fetch profile info error', e);
      setLoadingCustomers(false);
    } finally {
      setLoadingCustomers(false);
    }
  };

  const handleScrollToTopMobileList = useCallback(() => {
    if (scrollingLoadedListRef.current?.scrollTop) {
      scrollingLoadedListRef.current.scrollTop = 0;
    }
  }, [scrollingLoadedListRef]);

  return (
    <>
      <Helmet>
        <title>
          {formationDocumentTitle('Клиенты | Квик Транс', notificationCount)}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <ErrorBoundary>
            <CustomerListToolbar
              search={searchCustomers}
              customers={customers}
              handleUpdate={async (page, filters, afterCreatePreTask) => {
                isMobile && handleScrollToTopMobileList();
                await handleUpdate(
                  isMobile ? 1 : page,
                  filters,
                  afterCreatePreTask,
                );
              }}
              customerShortName={
                customerForSearchAfterCreatePreTask?.short_name
              }
              clearCustomerShortName={() => {
                setCustomerForSearchAfterCreatePreTask(null);
              }}
              handleScrollToTopMobileList={handleScrollToTopMobileList}
              page={page}
              currStatusFilter={currStatusFilter}
              loading={loadingCustomers}
              totalCustomers={total}
              changeVisibleFilters={async () => {
                setVisibleFilters(!visibleFilters);
                if (visibleFilters) {
                  await handleUpdate(1, null, false, false);
                }
              }}
              changeVisibleTableSetting={() =>
                setVisibleTableSetting(!visibleTableSetting)
              }
            />
          </ErrorBoundary>
          <Box sx={{ pt: 5 }}>
            {/*<CustomerListResults customers={customers} />*/}
            <ErrorBoundary>
              <CustomersTable
                modalCustomer={modalCustomer}
                handleUpdate={async (
                  page,
                  filters,
                  afterCreatePreTask,
                  concatData,
                ) =>
                  await handleUpdate(
                    page,
                    filters,
                    afterCreatePreTask,
                    concatData,
                  )
                }
                customers={customers}
                total={total}
                pageProps={page}
                currStatusFilter={currStatusFilter}
                setLoading={(value) => setLoadingCustomers(value)}
                loading={loadingCustomers}
                visibleFilters={visibleFilters}
                setVisibleFilters={(visible) => {
                  setVisibleFilters(visible);
                }}
                scrollingLoadedListRef={scrollingLoadedListRef}
                handleScrollToTopMobileList={handleScrollToTopMobileList}
                visibleTableSetting={visibleTableSetting}
                onCloseDrawerTableSetting={() => setVisibleTableSetting(false)}
                defaultFilters={defaultFilters}
                usedDefaultFilters={usedDefaultFilters}
                setPreTaskCustomer={(customer) =>
                  setCustomerForSearchAfterCreatePreTask(customer)
                }
              />
            </ErrorBoundary>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default CustomerList;
