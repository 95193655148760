import { Button, Form, Input, message, Modal } from 'antd';
import { call } from '../../../apiUtils/call';
import { restApiHost } from '../../../utils/appVariables';
import { useContext } from 'react';
import { userInformations } from '../../../contex';

export default function TableModal({ open, onClose, refreshTables }) {
  const [tableForm] = Form.useForm();
  const { userInfo } = useContext(userInformations);

  const onCloseModal = () => {
    tableForm.resetFields();
    onClose();
  };

  const addTable = (values) => {
    call(
      `${restApiHost}/administration/tables/`,
      {
        method: 'POST',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      },
      userInfo.current.haveViewBranch,
      null,
    ).then(() => {
      onCloseModal();
      refreshTables();
    });
  };

  const formErrorHandler = (reason) => {
    message.warning(JSON.stringify(reason)).then(() => {});
    for (const error of reason.errorFields) {
      for (const errorMessage of error.errors) {
        message.warning(errorMessage).then(() => {});
      }
    }
  };

  const onAddTable = () => {
    tableForm.validateFields().then(addTable).catch(formErrorHandler);
  };

  return (
    <Modal
      visible={open}
      title={'Добавление таблицы'}
      onCancel={onCloseModal}
      footer={[
        <Button onClick={onAddTable} key={'add'}>
          Добавить
        </Button>,
      ]}
    >
      <Form form={tableForm}>
        <Form.Item
          name="name"
          label="Идентификатор"
          help={
            'Уникальный код таблицы, по которому будет происходить идентификация и сопоставление меток отображения на клиенте.'
          }
          rules={[
            {
              required: true,
              message: 'Введите идентификатор!',
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
