import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Avatar, Box, Typography } from '@material-ui/core';
import { BarChart as BarChartIcon } from 'react-feather';
import NavItem from './NavItem';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {
  Button as ButtonAntd,
  Col,
  Form,
  Menu,
  message,
  Row,
  Select,
  Skeleton,
} from 'antd';
import { userInformations } from '../contex';
import { ReloadOutlined } from '@ant-design/icons';
import Tooltip from '@material-ui/core/Tooltip';
import { removeCompanyIdFromStorage } from '../apiUtils/call';
import { useTypedSelector } from '../store/hooks/useTypedSelector';
import { globalStore } from '../store';
import { TodoDemoAccessTypes } from '../store/todo/demoAccess/demoAccessTodo';
import { TodoNotificationCountActionTypes } from '../store/todo/notificationCount/notificationCountTodo';
import SortableList from './account/settings/settingUserMenuTabs/dragAndDropUserTabs/SortableList';
import SortableItemSidebarMenu from './account/settings/settingUserMenuTabs/dragAndDropUserTabs/SortableItemSidebarMenu';
import SubMenu from './sidebar/menu/SubMenu';
import useAsyncEffect from 'use-async-effect';
import {
  editUserSections,
  getProfileInfo,
  logout,
} from '../logic/account/AccountsApiFunctions';
import { TodoUserInfoActionTypes } from '../store/todo/userInfo/userInfoTodo';
import { userInfoHaveViewAllBranch } from '../logic/account/utils';
import NavItemWithContextMenu from './sidebar/menu/NavItemWithContextMenu';
import useCheckMobileScreen from '../hooks/useCheckMobileScreen';

const { Option } = Select;

const DashboardSidebar = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [profileInfo, setProfileInfo] = useState(undefined);
  const [loadingProfileInfo] = useState(false);
  const { userInfo } = useContext(userInformations);
  const [loadingBranchList] = useState(false);
  const [userSections, setUserSections] = useState([]);
  const [includeLogisticsSections, setIncludeLogisticsSections] =
    useState(false);
  const stubArrayFromPrintSkeleton = [0, 1, 2, 3];
  const blockedDemoAccessSectionsHrefList = [
    'applications',
    'contracts',
    'email',
    'refbook',
    'branches',
    'logistics/stocks',
    'telephony',
  ];
  const [mouseEnterItemHref, setMouseEnterItemHref] = useState(null);
  const [draggingItem, setDraggingItem] = useState(undefined);
  const demoAccessIsUsed = useTypedSelector(
    (state) => state.demoAccess.demoAccessIsUsed,
  );
  const storeUserInfo = useTypedSelector((state) => state.userInfo.userInfo);
  const [thereChangesInSections, setThereChangesInSections] = useState(false);
  const isMobile = useCheckMobileScreen();

  const routesLogistic = [
    {
      href: '/logistics/stocks',
      icon: BarChartIcon,
      section: 'Стоки',
    },
  ];

  useEffect(async () => {
    if (storeUserInfo) {
      setProfileInfo(storeUserInfo);
      if (storeUserInfo) {
        storeUserInfo.sections.map((section) => {
          if (section.href === 'logistics/stocks' && section.value === true) {
            setIncludeLogisticsSections(true);
          }
        });
        let filtersUserSections = storeUserInfo.sections
          ?.filter(
            (section) =>
              section?.href !== 'logistics/stocks' && section.value !== false,
          )
          .sort((a, b) => (a.index > b.index ? 1 : -1));
        if (demoAccessIsUsed) {
          filtersUserSections = filtersUserSections.map((section) => {
            if (blockedDemoAccessSectionsHrefList.includes(section.href)) {
              return {
                ...section,
                blocked: true,
              };
            }
            return {
              ...section,
            };
          });
        }

        if (filtersUserSections) {
          setUserSections(filtersUserSections);
        }
      }
    }
  }, [storeUserInfo]);

  const reloadPage = async () => {
    setTimeout(() => {
      window.location.reload();
    }, 300);
  };

  const setBranchToLocalStore = (value) => {
    if (value.branch) {
      localStorage.setItem('branchId', value.branch);
    }
  };
  /*useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
    }, [location.pathname]);*/

  useEffect(() => {
    const branchId = localStorage.getItem('branchId');
    if (branchId) {
      props.branchForm.setFieldsValue({
        branch: parseInt(branchId),
      });
    }
  });

  const updateUserInfoContext = useCallback(async () => {
    const res = await getProfileInfo(userInfo);
    if (res) {
      const haveViewBranch = userInfoHaveViewAllBranch(res);
      userInfo.current = res;
      if (haveViewBranch) {
        userInfo.current['haveViewBranch'] = true;
      }
      globalStore.dispatch({
        type: TodoUserInfoActionTypes.SET_USER_INFO,
        payload: userInfo.current,
      });
    }
  }, [userInfo]);

  const mergedActiveSectionAndSave = useCallback(async () => {
    const hide = message.loading('Сохранение изменений', 1000);
    // eslint-disable-next-line no-unsafe-optional-chaining
    const userInfoSections = [...userInfo.current?.sections];

    const userSectionsIds = userSections.map((item) => {
      return item?.section_id;
    });

    userInfoSections.forEach((item, index) => {
      if (!userSectionsIds.includes(item?.section_id)) {
        userInfoSections[index] = {
          ...item,
          value: false,
        };
      } else {
        delete userInfoSections[index];
      }
    });

    userSections.reverse().map((item) => userInfoSections.unshift(item));

    setUserSections(userInfoSections.filter((item) => item.value === true));
    setThereChangesInSections(false);

    try {
      const resultUserSectionsList = userInfoSections
        .filter((value) => Object.keys(value).length !== 0)
        .map((item, index) => {
          return {
            id: item?.id,
            value: item?.value,
            index: index + 1,
          };
        });
      await editUserSections(resultUserSectionsList, userInfo);
    } catch (e) {
      console.log(e);
    } finally {
      hide();
      await updateUserInfoContext();
    }
  }, [userInfo, userSections, editUserSections, updateUserInfoContext]);

  useAsyncEffect(async () => {
    if (props.closeFullMenu && thereChangesInSections) {
      await mergedActiveSectionAndSave();
    }
  }, [props.closeFullMenu]);

  const sectionHasAnAdditionalFilter = (sectionHref) => {
    switch (sectionHref) {
      case 'all_customers':
        return true;
      case 'contracts':
        return true;
      default:
        return false;
    }
  };

  const getSectionsDependingLocationsPath = () => {
    return location.pathname.includes('logistics')
      ? routesLogistic
      : userSections;
  };

  const handleGetItemMenu = useCallback(
    (item) => {
      if (
        sectionHasAnAdditionalFilter(item?.href) &&
        !item?.blocked &&
        draggingItem?.href !== 'all_customers' &&
        draggingItem?.href !== 'contracts'
      ) {
        return (
          <SubMenu
            itemMenu={item}
            closeFullMenu={props.closeFullMenu}
            onClick={() => {
              isMobile &&
                props.handleToggleMobileMenu &&
                props.handleToggleMobileMenu();
            }}
            handleChangeVisibleSection={(itemSectionId, visible) => {
              handleChangeVisibleSection(itemSectionId, visible);
            }}
          />
        );
      } else {
        return (
          <>
            <NavItemWithContextMenu
              itemMenu={item}
              closeFullMenu={props.closeFullMenu}
              onClick={() => {
                isMobile &&
                  props.handleToggleMobileMenu &&
                  props.handleToggleMobileMenu();
              }}
              handleChangeVisibleSection={(itemSectionId, visible) => {
                handleChangeVisibleSection(itemSectionId, visible);
              }}
            />
          </>
        );
      }
    },
    [
      sectionHasAnAdditionalFilter,
      draggingItem,
      setMouseEnterItemHref,
      mouseEnterItemHref,
    ],
  );

  const handleChangeVisibleSection = (itemSectionId, checkboxValue) => {
    const indexOfItemInArray = userSections.findIndex(
      (item) => item.section_id === itemSectionId,
    );
    let userSectionsListForUpdate = [...userSections];

    if (!userSectionsListForUpdate?.length) return;
    if (indexOfItemInArray > -1) {
      userSectionsListForUpdate[indexOfItemInArray] = {
        ...userSections[indexOfItemInArray],
        value: checkboxValue,
      };
      //userInfo.current.sections = userSectionsListForUpdate
      setUserSections(
        userSectionsListForUpdate.filter((item) => item.value === true),
      );
      if (!thereChangesInSections) {
        setThereChangesInSections(true);
      }
    }
  };

  const getUserInfoHaveViewBranch = useCallback(() => {
    return userInfo.current !== undefined
      ? userInfo.current?.haveViewBranch
      : false;
  }, [userInfo.current]);

  const handleLogout = useCallback(async (e) => {
    e.stopPropagation();
    navigate('/login', { replace: true });
    logout(userInfo);
    globalStore.dispatch({
      type: TodoNotificationCountActionTypes.SET_COUNT_NOTIFICATION_COUNT,
      payload: 0,
    });
    localStorage.removeItem('token');
    removeCompanyIdFromStorage();
    if (getUserInfoHaveViewBranch()) {
      localStorage.removeItem('branchId');
    }
    globalStore.dispatch({
      type: TodoDemoAccessTypes.SET_DEMO_ACCESS_IS_USED,
      payload: false,
    });
  }, []);

  const content = (
    <Box
      className={'background-main-color'}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh !important',
        zIndex: '9999 !important',
      }}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      {!props.closeFullMenu ? (
        <>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              p: 2,
            }}
          >
            <Avatar
              component={RouterLink}
              sx={{
                cursor: 'pointer',
                width: 64,
                height: 64,
              }}
              style={{
                backgroundColor: '#ececec',
                marginBottom: '10px',
              }}
              to="/app/account"
            >
              <AccountCircleIcon
                fontSize={'large'}
                style={{ color: '#0b7b7f' }}
              />
            </Avatar>
            <Typography color="#fff" variant="h5" textAlign={'center'}>
              {loadingProfileInfo && !profileInfo
                ? 'Загрузка...'
                : !loadingProfileInfo && profileInfo
                ? profileInfo.full_name || profileInfo.email
                : '-'}
            </Typography>
            <Typography color="#fff" variant="body2">
              {loadingProfileInfo && !profileInfo
                ? 'Загрузка...'
                : !loadingProfileInfo &&
                  profileInfo &&
                  profileInfo.groups?.length > 0
                ? profileInfo?.groups[0]?.name
                : 'Не известный'}
            </Typography>
          </Box>
          {getUserInfoHaveViewBranch() ? (
            <Row>
              <Col style={{ marginLeft: '17px' }}>
                <Form layout="vertical" form={props.branchForm}>
                  <Form.Item name={'branch'}>
                    <Select
                      getPopupContainer={(trigger) =>
                        isMobile ? trigger.parentNode : document.body
                      }
                      className={'select-bordered'}
                      style={{ borderRadius: '10px', width: '180px' }}
                      showSearch
                      placeholder="Филиал"
                      optionFilterProp="children"
                      loading={loadingBranchList}
                      onSearch={async (value) => {
                        props.handleSearchBranchList(value);
                      }}
                      onChange={() => {
                        props.branchForm
                          .validateFields()
                          .then(async (values) => {
                            setBranchToLocalStore(values);
                            await reloadPage();
                          })
                          .catch(() => {
                            message.warning('Ошибка выбора филиала');
                          });
                      }}
                      filterOption={false}
                    >
                      {props.branchList && props.branchList?.length > 0 ? (
                        <>
                          {Array.isArray(props.branchList) &&
                            props.branchList.map((item, index) => {
                              return (
                                <Option
                                  key={index}
                                  data-value={JSON.stringify(item)}
                                  value={item.id}
                                >
                                  {item.name}
                                </Option>
                              );
                            })}
                        </>
                      ) : (
                        <Option disabled>Нет доступных вариантов</Option>
                      )}
                    </Select>
                  </Form.Item>
                </Form>
              </Col>
              <Col>
                <Tooltip title={'Обновить список филиалов'}>
                  <ButtonAntd
                    disabled={loadingBranchList}
                    onClick={async () => {
                      props.getBranchList();
                    }}
                    style={{
                      marginLeft: '10px',
                      color: 'white',
                      width: '20px',
                      height: '20px',
                    }}
                    type="text"
                    icon={<ReloadOutlined />}
                  />
                </Tooltip>
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '37px 16px 0 16px',
          }}
        >
          <img
            style={{ width: '35px' }}
            alt="Logo"
            src="../static/svg/logo/qt_small_white.svg"
            {...props}
          />
        </div>
      )}
      <Box
        sx={{ p: 2 }}
        style={{ padding: '16px 16px 16px 16px' }}
        className={'background-main-color'}
      >
        {loadingProfileInfo ? (
          stubArrayFromPrintSkeleton.map((item, index) => {
            return (
              <Skeleton
                key={index}
                size={'small'}
                loading={true}
                active={true}
              />
            );
          })
        ) : (
          <Menu
            mode="vertical"
            className={'sub-menu'}
            selectedKeys={[]}
            expandIcon={<></>}
          >
            {userSections && userSections?.length > 0 ? (
              <SortableList
                noUseListStyle={true}
                items={getSectionsDependingLocationsPath()}
                onDragStart={(activeItem) => {
                  setDraggingItem(activeItem);
                }}
                renderItem={(item) =>
                  !isMobile ? (
                    <SortableItemSidebarMenu index={item?.index}>
                      {handleGetItemMenu(item)}
                    </SortableItemSidebarMenu>
                  ) : (
                    <>{handleGetItemMenu(item)}</>
                  )
                }
                onChange={(list) => {
                  if (!thereChangesInSections) {
                    setThereChangesInSections(true);
                  }
                  setUserSections(list);
                }}
              />
            ) : (
              <></>
            )}
            <NavItem
              href={'/'}
              iconName={'LogOut'}
              title={'Выход'}
              onlyIcon={props.closeFullMenu}
              onClick={async (e) => {
                await handleLogout(e);
              }}
            />
          </Menu>
        )}
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box
        style={{ padding: '0px 16px 0px 16px' }}
        className={'background-main-color'}
      >
        {location.pathname.includes('logistics') ? (
          <NavItem
            className={'nav-item'}
            href={'/'}
            key={'CRM'}
            onlyIcon={props.closeFullMenu}
            title={'Блок "ЦРМ"'}
            iconName={'BarChart'}
          />
        ) : (
          <>
            {includeLogisticsSections ? (
              <NavItem
                className={'nav-item'}
                href={'/logistics/stocks'}
                key={'Логистика'}
                title={'Блок "Логистика"'}
                onlyIcon={props.closeFullMenu}
                blocked={demoAccessIsUsed}
                iconName={'BarChart'}
              />
            ) : (
              <></>
            )}
          </>
        )}
      </Box>

      {!props.closeFullMenu ? (
        <Box
          style={{ padding: '0px 9px 16px 16px', textAlign: 'left' }}
          className={'background-main-color'}
        >
          <span
            style={{ color: '#ffffff', fontStyle: 'italic', fontSize: '12px' }}
          >
            Разрабатывается ООО «Логистика+»
          </span>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );

  return <>{content}</>;
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  closeFullMenu: PropTypes.bool,
  handleToggleMobileMenu: PropTypes.func,
  branchForm: PropTypes.any,
  getBranchList: PropTypes.func,
  handleSearchBranchList: PropTypes.func,
  branchList: PropTypes.arrayOf(PropTypes.object),
};

export default DashboardSidebar;
