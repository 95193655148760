import dayjs from 'dayjs';

export default function getDownIndentPercentage(endDate) {
  if (!endDate) return '0%';
  const minutes = dayjs(endDate).get('minute');

  if (minutes >= 0 && minutes < 15) {
    return '0%';
  }
  if (minutes >= 15 && minutes < 30) {
    return '10%';
  }
  if (minutes >= 30 && minutes < 35) {
    return '25%';
  }
  if (minutes >= 35 && minutes < 45) {
    return '35%';
  }
  if (minutes >= 45 && minutes <= 59) {
    return '40%';
  }
  return '0%';
}
