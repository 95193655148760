import PropTypes from 'prop-types';
import { Row } from 'antd';
import TodayButton from '../uiComponents/TodayButton';
import { Box } from '@material-ui/core';
import MonthSwitcher from './MonthSwitcher';
import React from 'react';
import { ErrorBoundary } from '../../ErrorBoundary';

const AdditionalPanelMonth = (props) => {
  return (
    <>
      <Box style={{ paddingTop: '20px', color: 'black' }}>
        <Row justify={'space-between'} style={{ alignItems: 'end' }}>
          <Row justify={'start'}>
            <ErrorBoundary>
              <TodayButton switchToToday={() => props.switchToToday()} />
            </ErrorBoundary>
          </Row>
          {props.displayMonthSwitcher ? (
            <Row>
              <ErrorBoundary>
                <MonthSwitcher
                  currentMonthIndex={props.currentMonthIndex}
                  currentYear={props.currentYear}
                  changeMonthIndex={(index, action) =>
                    props.changeMonthIndex(index, action)
                  }
                />
              </ErrorBoundary>
            </Row>
          ) : (
            <></>
          )}
        </Row>
      </Box>
    </>
  );
};
AdditionalPanelMonth.propTypes = {
  currentMonthIndex: PropTypes.any.isRequired,
  currentYear: PropTypes.number.isRequired,
  displayMonthSwitcher: PropTypes.bool.isRequired,
  changeMonthIndex: PropTypes.func.isRequired,
  switchToToday: PropTypes.func.isRequired,
};

export default React.memo(AdditionalPanelMonth);
