import { Button, Input } from 'antd';
import './MessageInput.css';
import { SendOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useState } from 'react';

const MessageInput = (props) => {
  const [message, setMessage] = useState('');

  const handleSendMessage = (message) => {
    if (message?.length > 0) {
      props.sendMessage(message);
      setMessage('');
    }
  };

  return (
    <>
      <div className={'message-input-wrapper'}>
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <Input
            onInput={(e) => {
              setMessage(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSendMessage(message);
              }
            }}
            value={message}
            style={{ borderRadius: '10px' }}
            width={'100%'}
            size="large"
            placeholder="Сообщение"
          />
          <Button
            onClick={() => {
              handleSendMessage(message);
            }}
            style={{ display: message?.length > 0 ? 'block' : 'none' }}
            type="primary"
            shape="circle"
            icon={<SendOutlined />}
          />
        </div>
      </div>
    </>
  );
};

MessageInput.propTypes = {
  sendMessage: PropTypes.func.isRequired,
};

export default MessageInput;
