import PropTypes from 'prop-types';
import { Form, message } from 'antd';
import EditForm from './EditForm';
import StaticForm from './StaticForm';
import { createChat } from '../../../../../../logic/chat/ChatApiFunction';
import { editEvent } from '../../../../callApiFuctions/tasks/tasksCallApi';
import { useContext } from 'react';
import { userInformations } from '../../../../../../contex';

const HeaderForm = (props) => {
  const { userInfo } = useContext(userInformations);
  const handleCreateChat = async () => {
    if (!props.editingItem?.id) return;
    const hide = message.loading('Создание чата', 100);
    const membersIds = props.editingItem?.members?.length
      ? props.editingItem?.members.map((member) => member?.staff_id)
      : [];
    const body = {
      members: membersIds,
      //event: props.editingItem?.id,
      name: props.editingItem?.name,
    };
    await createChat(userInfo, body)
      .then(async (res) => {
        const chatId = await res?.data?.id;
        const result = await editEvent(
          props.editingItem?.id,
          { chat: chatId },
          userInfo,
        );
        props.setEditingTask(result);
      })
      .catch((err) => {
        console.log(err);
        hide();
      });
    hide();
  };
  return (
    <>
      <Form
        form={props.form}
        className={'reset-margin-form'}
        layout={'vertical'}
      >
        {props.editFields ? (
          <EditForm
            form={props.form}
            editingItem={props.editingItem}
            callCreateEvent={() => props.callCreateEvent()}
            setEditFields={(val) => props.setEditFields(val)}
            onCreateEventTag={async (tag) => props.onCreateEventTag(tag)}
            onDeleteEventTag={async (tag) => props.onDeleteEventTag(tag)}
            createChat={async () => await handleCreateChat()}
            disableBtnCreateChat={!props.editingItem}
            chatIsCreated={props.editingItem?.chat}
            setEditingTask={props.setEditingTask}
          />
        ) : (
          <StaticForm
            form={props.form}
            editFields={props.editFields}
            editingItem={props.editingItem}
            setEditFields={(val) => props.setEditFields(val)}
            createChat={async () => await handleCreateChat()}
            disableBtnCreateChat={!props.editingItem}
            chatIsCreated={props.editingItem?.chat}
            setEditingTask={props.setEditingTask}
          />
        )}
      </Form>
    </>
  );
};

HeaderForm.propTypes = {
  form: PropTypes.any,
  editFields: PropTypes.bool,
  setEditFields: PropTypes.func,
  editingItem: PropTypes.object,
  onCreateEventTag: PropTypes.func,
  onDeleteEventTag: PropTypes.func,
  callCreateEvent: PropTypes.func,
  setEditingTask: PropTypes.func,
};

export default HeaderForm;
