import { restApiHost } from '../../../utils/appVariables';
import { call } from '../../../apiUtils/call';
import { message } from 'antd';

export default async function searchTimezone(keyword, userInfo) {
  const params = new URLSearchParams();
  params.append('active', 'true');
  if (keyword) {
    params.append('search', `${keyword}`);
  }

  try {
    const url = `${restApiHost}/refbook/timezone/?${params}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (json?.data) {
      return json.data;
    }
  } catch (e) {
    console.log(e);
    message.error('Ошибка запроса на получение списка временных зон');
  }
}
