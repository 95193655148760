import { restApiHost } from '../../utils/appVariables';
import { call } from '../../apiUtils/call';
import { message } from 'antd';
import { convertObjToUrlSearchQuery } from '../utils/convertObjToUrlSearchQuery';

export async function getPreTaskList(params, userInfo) {
  let paramsUrl = ``;
  if (params) {
    paramsUrl = convertObjToUrlSearchQuery(params);
  }
  try {
    const url = `${restApiHost}/leads/pre_tasks/?${paramsUrl}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (json) {
      return json;
    }
  } catch (e) {
    message.error('Ошибка получение пунктов Пред.заявки');
    console.log('get getContactsLead err', e);
  }
}

export async function getPreTaskWaypoints(preTaskId, userInfo) {
  if (!preTaskId) return;
  try {
    const url = `${restApiHost}/leads/pre_waypoints?task=${preTaskId}&ordering=number`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (json) {
      return json;
    }
  } catch (e) {
    message.error('Ошибка получение пунктов Пред.заявки');
    console.log('get getContactsLead err', e);
  }
}

export async function createTaskWaypoint(body, userInfo) {
  try {
    const url = `${restApiHost}/leads/pre_waypoints/`;
    const json = await call(
      url,
      {
        method: 'POST',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      userInfo.current.haveViewBranch,
    );

    if (json) {
      return json;
    }
  } catch (e) {
    console.log('createTaskWaypoint error', e);
    message.error('Ошибка запроса на добавления пункта');
  }
}
export async function editTaskWaypoint(id, body, userInfo) {
  if (!id) return;
  try {
    const url = `${restApiHost}/leads/pre_waypoints/${id}/`;
    const json = await call(
      url,
      {
        method: 'PATCH',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      userInfo.current.haveViewBranch,
    );

    if (json) {
      return json;
    }
  } catch (e) {
    console.log('editTaskWaypoint error', e);
    message.error('Ошибка запроса на редактирование пункта');
  }
}

export async function getPreTaskTransportCargo(preTaskId, userInfo) {
  if (!preTaskId) return;
  try {
    const url = `${restApiHost}/leads/pre_transport_cargo_task/?task=${preTaskId}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (json) {
      return json;
    }
  } catch (e) {
    message.error('Ошибка получение транспорта Пред.заявки');
    console.log('getPreTaskTransportCargo err', e);
  }
}

export async function deletePreTaskCargo(id, userInfo) {
  if (!id) return;
  try {
    const url = `${restApiHost}/leads/pre_transport_cargo_task/${id}/`;
    const response = await call(
      url,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (!response) return;

    message.success('Успешно удалено');
  } catch (e) {
    console.log('delete cargo error', e);
    message.error('Ошибка запроса на удаления груза');
  }
}

export async function getPreTaskCosts(preTaskId, userInfo) {
  if (!preTaskId) return;
  try {
    const url = `${restApiHost}/leads/pre_upfront_costs?task=${preTaskId}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (json) {
      return json;
    }
  } catch (e) {
    message.error('Ошибка получение транспорта Пред.заявки');
    console.log('getPreTaskTransportCargo err', e);
  }
}

export async function getPreTaskSummaries(preTaskId, userInfo) {
  if (!preTaskId) return;
  try {
    const url = `${restApiHost}/leads/pre_summaries?task=${preTaskId}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (json) {
      return json;
    }
  } catch (e) {
    message.error('Ошибка получение сумм Пред.заявки');
    console.log('getPreTaskSummaries err', e);
  }
}

export async function getPreTaskManagers(preTaskId, userInfo) {
  if (!preTaskId) return;
  try {
    const url = `${restApiHost}/shipping/task_manager/?task=${preTaskId}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (json) {
      return json;
    }
  } catch (e) {
    message.error('Ошибка получение менеджеров пред.заявки');
    console.log('getPreTaskManagers err', e);
  }
}
