import {
  Skeleton,
  Card,
  Avatar,
  Typography,
  Modal,
  Button,
  Form,
  Row,
  Col,
  message,
} from 'antd';
import { EditOutlined, LockOutlined, SettingOutlined } from '@ant-design/icons';
import { useState, useEffect, useContext, useCallback } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { restApiHost } from '../../../utils/appVariables';
import ChangePasswordModal from '../changePasswordModal';
import { editProfileInfo } from '../../../logic/profileInfo/ProfileInfoApiFunctions';
import { userInformations } from '../../../contex';
import { call } from '../../../apiUtils/call';
import { useTypedSelector } from '../../../store/hooks/useTypedSelector';
import { ErrorBoundary } from '../../ErrorBoundary';
import SettingUserMenuTabsDrawer from '../settings/settingUserMenuTabs/SettingUserMenuTabsDrawer';
import AccountProfileDetails from './AccountProfileDetails';
import { Container, Grid } from '@material-ui/core';

const { Meta } = Card;
const { Text } = Typography;

const AccountProfile = (props) => {
  const [form] = Form.useForm();
  const [openModal, setOpenModal] = useState(false);
  const [emailHtml, setEmailHtml] = useState('');
  const [openModalChangePassword, setOpenModelChangePassword] = useState(false);
  const [loadingChangePassword, setLoadingChangePassword] = useState(false);
  const [
    visibleUserMenuTabsSettingDrawer,
    setVisibleUserMenuTabsSettingDrawer,
  ] = useState(false);
  const { userInfo } = useContext(userInformations);
  const demoAccessIsUsed = useTypedSelector(
    (state) => state.demoAccess.demoAccessIsUsed,
  );

  useEffect(() => {
    if (props?.profileInfo) {
      if (props?.profileInfo?.signature) {
        setEmailHtml(props.profileInfo.signature);
      } else {
        const html = `<br><br><p style="margin:0;font-size:14px"><span style="font-size:16px;"><strong>ООО "Квиктранс"</strong></span></p>
                <p style="margin:0;font-size:14px;mso-line-height-alt:16.8px">&nbsp;</p>
                <p style="margin:0;font-size:14px">
                    <span style="font-size:13px;">С уважением, к Вам и Вашему бизнесу ${
                      props?.profileInfo?.full_name || ''
                    }</span><br>
                    <span style="font-size:13px;">${
                      props?.profileInfo?.groups?.length > 0
                        ? props?.profileInfo.groups[0].name
                        : ''
                    } ООО «Квиктранс»</span><br>
                    <span style="font-size:13px;">426032, г. Ижевск, ул. Карла Маркса, дом 1, литер Д, оф.414</span><br>
                    <span style="font-size:13px;">Тел: ${
                      props?.profileInfo?.phone_number || ''
                    }</span><br>
                    <span style="font-size:13px;">E-mail: ${
                      props?.profileInfo?.email || ''
                    }</span><br>
                    <span style="font-size:13px;">www.quicklytrans.ru</span><br>
                </p>`;
        setEmailHtml(html);
      }
    }
  }, [props.profileInfo]);

  const editProfile = async (emailHtml) => {
    try {
      const res = await editProfileInfo(
        userInfo?.current?.id,
        { signature: emailHtml },
        userInfo,
      );
      if (res) {
        message.success('Подпись успешно отредактирована');
        setOpenModal(false);
        props?.update?.(res?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const changePassword = async (body) => {
    setLoadingChangePassword(true);
    try {
      const url = `${restApiHost}/accounts/fast_restore_password/`;
      const json = await call(
        url,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) {
        return;
      }
      message.success('Пароль успешно изменен');

      setOpenModelChangePassword(false);
      setLoadingChangePassword(false);
    } catch (e) {
      message.error(`Ошибка изменения пароля ${e}`);
    }
  };

  const closeModalChangePassword = () => {
    setOpenModelChangePassword(false);
  };

  const handleOpenUserMenuTabsSettingDrawer = useCallback(() => {
    setVisibleUserMenuTabsSettingDrawer(true);
  }, [setVisibleUserMenuTabsSettingDrawer]);

  const handleCloseUserMenuTabsSettingDrawer = useCallback(() => {
    setVisibleUserMenuTabsSettingDrawer(false);
  }, [setVisibleUserMenuTabsSettingDrawer]);

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item lg={4} md={6} xs={12}>
          <ErrorBoundary>
            <Card
              className={'AccountProfile'}
              style={{ borderRadius: '10px', overflow: 'hidden' }}
              actions={
                !demoAccessIsUsed && [
                  <EditOutlined
                    key="edit"
                    onClick={() => setOpenModal(true)}
                  />,
                  <LockOutlined
                    key="changePass"
                    onClick={() => setOpenModelChangePassword(true)}
                  />,
                  <SettingOutlined
                    key="setting"
                    onClick={() => handleOpenUserMenuTabsSettingDrawer()}
                  />,
                ]
              }
            >
              <Skeleton loading={props.loading} avatar active>
                <Meta
                  avatar={
                    <Avatar src="https://static.tildacdn.com/tild3434-3864-4335-b364-353562323238/Logo.svg" />
                  }
                  title={
                    !props.loading &&
                    props.profileInfo &&
                    props.profileInfo.full_name
                      ? props.profileInfo.full_name
                      : props.profileInfo && props.profileInfo.email
                      ? props.profileInfo.email
                      : 'Не указано'
                  }
                />
                <div style={{ marginTop: '15px' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginBottom: '10px',
                    }}
                  >
                    <Text strong>Должность</Text>
                    <Text keyboard>
                      {!props.loading &&
                      props.profileInfo &&
                      props.profileInfo.groups.length > 0
                        ? props.profileInfo.groups[0].name
                        : 'Без должности'}
                    </Text>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Text strong>Дата создания</Text>
                    <Text keyboard>
                      {!props.loading &&
                      props.profileInfo &&
                      props.profileInfo.date_joined
                        ? props.profileInfo.date_joined.substr(0, 10)
                        : 'Не удалось получить'}
                    </Text>
                  </div>
                </div>
              </Skeleton>
            </Card>
          </ErrorBoundary>
        </Grid>
        <Grid item lg={8} md={6} xs={12}>
          <ErrorBoundary>
            <AccountProfileDetails
              profileInfo={props.profileInfo}
              loading={props.loadingProfileInfo}
            />
          </ErrorBoundary>
        </Grid>
      </Grid>

      <Modal
        visible={openModal}
        title="Редактирование подписи(email)"
        onCancel={() => {
          setOpenModal(false);
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setOpenModal(false);
            }}
          >
            Закрыть
          </Button>,
          <Button
            key="submit"
            type="primary"
            disabled={emailHtml.length === 0}
            onClick={() => {
              if (emailHtml) {
                editProfile(emailHtml);
              } else {
                message.error('Непредвиденная ошибка!');
              }
            }}
          >
            Сохранить
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Row gutter={15}>
            <Col lg={24}>
              <ReactQuill
                theme="snow"
                defaultValue={emailHtml}
                value={emailHtml}
                onChange={setEmailHtml}
                modules={{
                  toolbar: [
                    [{ header: '1' }, { header: '2' }, { font: [] }],
                    [{ size: [] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [
                      { list: 'ordered' },
                      { list: 'bullet' },
                      { indent: '-1' },
                      { indent: '+1' },
                    ],
                    ['link', 'image', 'video'],
                    ['clean'],
                  ],
                  clipboard: {
                    // toggle to add extra line breaks when pasting HTML:
                    matchVisual: false,
                  },
                }}
                formats={[
                  'header',
                  'font',
                  'size',
                  'bold',
                  'italic',
                  'underline',
                  'strike',
                  'blockquote',
                  'list',
                  'bullet',
                  'indent',
                  'link',
                  'image',
                  'video',
                ]}
                bounds={'.app'}
                placeholder={'Напишите что-нибудь...'}
              />
            </Col>
          </Row>
        </Form>
      </Modal>

      <ChangePasswordModal
        closeModal={closeModalChangePassword}
        changePassword={changePassword}
        loadingChangePassword={loadingChangePassword}
        visible={openModalChangePassword}
      />

      <ErrorBoundary>
        <SettingUserMenuTabsDrawer
          visible={visibleUserMenuTabsSettingDrawer}
          handleClose={() => {
            handleCloseUserMenuTabsSettingDrawer();
          }}
        />
      </ErrorBoundary>
    </Container>
  );
};

export default AccountProfile;
