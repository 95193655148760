import * as PropTypes from 'prop-types';
import {
  Button,
  Col,
  Form,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Tooltip,
} from 'antd';
import React, { useEffect, useState } from 'react';
import Dragger from 'antd/es/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';

const { Option } = Select;

const AddAttachmentModal = (props) => {
  const [attachmentForm] = Form.useForm();
  const [fileList, setFileList] = useState([]);

  const [fileInfo, setFileInfo] = useState(null);

  const [contractListOption, setContractListOption] = useState([]);

  const isMobile = useCheckMobileScreen();

  useEffect(() => {
    if (!props.creationAttachment && props.currentAttachment) {
      attachmentForm.setFieldsValue({
        contract: props.currentAttachment?.contract?.id,
        type_attachment: props.currentAttachment?.type_attachment,
      });
      if (props.currentAttachment?.file_info) {
        setFileInfo(props.currentAttachment.file_info);
      }
    }
  }, [props.currentAttachment]);

  useEffect(() => {
    if (props.fileList?.length > 0) {
      setFileList(props.fileList);
    }
  }, [props.fileList]);

  useEffect(() => {
    setContractListOption(props.contractList);
  }, [props.contractList]);

  useEffect(() => {
    if (!props.visible) {
      setFileList([]);
      attachmentForm.resetFields();
    }
  }, [props.visible]);

  useEffect(() => {
    if (props.currentContract) {
      attachmentForm.setFieldsValue({
        contract: props.currentContract?.id,
      });
    }
  }, [props.currentContract]);

  const propsDragger = {
    beforeUpload: () => false,
    async onChange({ fileList }) {
      setFileList(fileList);
    },
    multiple: true,
    fileList,
  };

  const addAttachment = () => {
    attachmentForm
      .validateFields()
      .then((values) => {
        let body = {
          ...values,
          file: fileList?.length > 0 ? fileList : [],
          customer: props.customerId,
        };
        if (props.currentContract?.id) {
          body['contract'] = props.currentContract.id;
        }
        if (fileList?.length > 0) {
          props.createAttachment(body);
        } else {
          message.warning('Выберите файл/файлы');
        }
      })
      .catch((error) => {
        console.log(error);
        message.warning('Не все поля заполнены');
      });
  };

  const searchContract = (keyword) => {
    if (keyword?.length > 0) {
      props.searchCustomerContracts(props.customerId, keyword);
    } else {
      props.searchCustomerContracts(props.customerId, null);
    }
  };

  return (
    <Modal
      width={400}
      style={{ zIndex: 102 }}
      visible={props.visible}
      title={
        props.creationAttachment ? 'Добавить вложение' : 'Просмотр вложения'
      }
      onCancel={() => {
        props.handelCloseModal();
        attachmentForm.resetFields();
        setFileList([]);
      }}
      footer={[
        <Button
          key="back"
          onClick={() => {
            props.handelCloseModal();
            attachmentForm.resetFields();
            setFileList([]);
          }}
        >
          Закрыть
        </Button>,
        <>
          {props.creationAttachment ? (
            <Button
              key="submit"
              type="primary"
              disabled={props.loading}
              onClick={() => {
                addAttachment();
              }}
            >
              Добавить
            </Button>
          ) : (
            <></>
          )}
        </>,
      ]}
    >
      <Spin spinning={props.loading}>
        <Form form={attachmentForm} layout="vertical">
          <Row>
            {!props.currentContract ? (
              <Col lg={24} xs={24}>
                <Form.Item
                  label={'Договор'}
                  rules={[{ required: true, message: 'Обязательно поле!' }]}
                  name={'contract'}
                >
                  <Select
                    getPopupContainer={(trigger) =>
                      isMobile ? trigger.parentNode : document.body
                    }
                    disabled={
                      !props.creationAttachment || props.disableSelectContract
                    }
                    placeholder={'Договор'}
                    showSearch
                    filterOption={false}
                    onSearch={async (val) => await searchContract(val)}
                  >
                    {contractListOption?.length > 0 ? (
                      <>
                        {contractListOption.map((item) => {
                          return (
                            <Option key={item?.id} value={item?.id}>
                              {item.code_contract}
                            </Option>
                          );
                        })}
                      </>
                    ) : (
                      <Option disabled>Нет доступных вариантов</Option>
                    )}
                  </Select>
                </Form.Item>
              </Col>
            ) : (
              <></>
            )}

            <Col lg={24} xs={24}>
              <Form.Item
                label={'Тип вложения'}
                rules={[{ required: true, message: 'Обязательно поле!' }]}
                name={'type_attachment'}
              >
                <Select
                  getPopupContainer={(trigger) =>
                    isMobile ? trigger.parentNode : document.body
                  }
                  placeholder={'Тип вложения'}
                  disabled={!props.creationAttachment}
                >
                  {props.typesAttachment &&
                  props.typesAttachment?.length > 0 ? (
                    <>
                      {props.typesAttachment.map((item, key) => {
                        return (
                          <Option key={key} value={item?.id}>
                            {item.title}
                          </Option>
                        );
                      })}
                    </>
                  ) : (
                    <Option disabled>Нет доступных вариантов</Option>
                  )}
                </Select>
              </Form.Item>
            </Col>
            {props.creationAttachment ? (
              <Col lg={24} xs={24}>
                <Form.Item
                  style={{ overflow: 'hidden' }}
                  rules={[{ required: true, message: 'Обязательно поле!' }]}
                >
                  <Dragger {...propsDragger}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Кликните или перенесите файлы для загрузки
                    </p>
                  </Dragger>
                </Form.Item>
              </Col>
            ) : (
              <></>
            )}
            {fileInfo && !props.creationAttachment ? (
              <Col lg={24} xs={24}>
                <Button
                  style={{
                    marginRight: 10,
                    marginBottom: 10,
                    width: '100%',
                  }}
                  onClick={() => props.handleDownloadFile(fileInfo)}
                >
                  <Tooltip placement={'topLeft'} title={'Скачать файл'}>
                    <span
                      style={{
                        width: '100%',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        display: 'block',
                        overflow: 'hidden',
                      }}
                    >
                      {fileInfo?.name + '.' + fileInfo?.extension}
                    </span>
                  </Tooltip>
                </Button>
              </Col>
            ) : (
              <></>
            )}
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default AddAttachmentModal;

AddAttachmentModal.propTypes = {
  visible: PropTypes.bool,
  currentAttachment: PropTypes.object,
  creationAttachment: PropTypes.bool,
  typesAttachment: PropTypes.array,
  customerId: PropTypes.number,
  contractList: PropTypes.array,
  disableSelectContract: PropTypes.bool,
  fileList: PropTypes.array,
  currentContract: PropTypes.number,
  loading: PropTypes.bool,
  createAttachment: PropTypes.func,
  searchCustomerContracts: PropTypes.func,
  handelCloseModal: PropTypes.func,
  handleDownloadFile: PropTypes.func,
};
