import { Button, Input, Tag } from 'antd';
import React, { useState, useRef, useContext } from 'react';
import { updatePermission } from '../../../logic/permissions/PermissionsApiFunctions';
import { userInformations } from '../../../contex';
import { Permission, PermissionSection } from '../../../types';

interface PermissionItemProps {
  permission: Permission;
  handleAddSection: (permission: Permission) => void;
  handleDeleteSection: (permission_section: PermissionSection) => void;
  update: () => void;
}

const PermissionItem = ({
  permission,
  handleAddSection,
  handleDeleteSection,
  update,
}: PermissionItemProps) => {
  const [showSave, setShowSave] = useState(false);
  const inputRef = useRef<Input | null>(null);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const { userInfo } = useContext(userInformations);

  const ChangeNameHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowSave(!Object.is(e.target.value, permission.name));
  };

  const SaveHandler = () => {
    if (inputRef.current)
      updatePermission(
        permission.id,
        { name: inputRef.current?.input?.value },
        userInfo,
      ).then(() => {
        update();
      });
  };

  const handleDeleteSectionHandler = (section: PermissionSection) => {
    handleDeleteSection(section);
  };

  return (
    <div
      style={{
        border: '1px solid #c4c4c4',
        padding: 16,
        borderRadius: 8,
        backgroundColor: 'white',
        position: 'relative',
        marginTop: 8,
      }}
    >
      <Input
        ref={inputRef}
        onChange={ChangeNameHandler}
        defaultValue={permission.name}
        value={permission.name}
        style={{ fontWeight: 600, fontSize: 24, padding: 0, margin: 0 }}
        bordered={false}
      />
      <Button
        style={{ position: 'absolute', right: 16, top: 16 }}
        hidden={!showSave}
        onClick={SaveHandler}
      >
        Сохранить
      </Button>
      <p style={{ fontWeight: 600, fontSize: 18, margin: 0, padding: 0 }}>
        {permission?.content_type?.app_label}.{permission?.content_type?.model}{' '}
        | {permission?.codename}
      </p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          flexWrap: 'wrap',
        }}
      >
        {permission?.sections?.map(
          (section: PermissionSection, index: number) => (
            <Tag
              key={index}
              style={{ marginTop: 8 }}
              onClose={() => handleDeleteSectionHandler(section)}
              closable={true}
            >
              {section?.section}
            </Tag>
          ),
        )}
        <Button
          size="small"
          hidden={permission.name.includes('служебное')}
          onClick={() => handleAddSection(permission)}
          style={{ marginTop: 8 }}
        >
          Добавить секцию
        </Button>
      </div>
    </div>
  );
};

export default PermissionItem;
