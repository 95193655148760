import PropTypes from 'prop-types';
import { Button, Dropdown } from 'antd';
import React, { useState } from 'react';
import DropdownOverlayRender from './DropdownOverlayRender';
import LeadCallGroupTask from '../../tasks/leadCallGroup/LeadCallGroupTask';

const DropdownViewAllTasks = (props) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Dropdown
        trigger={['click']}
        visible={visible}
        onClick={() => setVisible(!visible)}
        onVisibleChange={(visible) => setVisible(visible)}
        placement={'topCenter'}
        overlay={
          <DropdownOverlayRender
            day={props.day}
            tasks={props.tasks}
            timePeriodTitle={props.timePeriodTitle}
            isWeekDay={props.isWeekDay}
            //useForDayViewCalendar={props.useForDayViewCalendar}
            useForDayViewCalendar={false}
            goToCompetitionDate={(day) => {
              props.goToCompetitionDate(day);
            }}
            setVisibleDropdown={(visible) => setVisible(visible)}
            handleEditTask={(task) => {
              props.handleEditTask(task);
              setVisible(false);
            }}
          />
        }
      >
        {!props.useForCallGroupLead ? (
          <Button
            style={{
              width: '100%',
              maxWidth: props.useForDayViewCalendar ? '50px' : '',
            }}
            className={'calendar-mouth-day-more-tasks-btn'}
            type={'text'}
          >
            Ещё {props?.countHiddenTasks}
          </Button>
        ) : (
          <LeadCallGroupTask
            title={props.groupTitle ? props.groupTitle : 'ГРУППА'}
            useForDayViewCalendar={props.useForDayViewCalendar}
            countTasks={props.tasks?.length}
          />
        )}
      </Dropdown>
    </>
  );
};

DropdownViewAllTasks.propTypes = {
  day: PropTypes.any,
  tasks: PropTypes.any,
  countHiddenTasks: PropTypes.number,
  useForDayViewCalendar: PropTypes.bool,
  groupTitle: PropTypes.string,
  handleEditTask: PropTypes.func,
  timePeriodTitle: PropTypes.string,
  isWeekDay: PropTypes.bool,
  useForCallGroupLead: PropTypes.bool,
  goToCompetitionDate: PropTypes.func,
};

export default DropdownViewAllTasks;
