import React from 'react';
import { Content } from 'antd/es/layout/layout';
import { Outlet } from 'react-router-dom';

const LayoutOutletContent = () => {
  return (
    <>
      <Content className="site-layout-background">
        <Outlet />
      </Content>
    </>
  );
};

export default React.memo(LayoutOutletContent);
