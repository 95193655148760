import PropTypes from 'prop-types';
import { Button, Col, Form, Rate, Row, Tag, Tooltip } from 'antd';
import { EditOutlined, FireOutlined } from '@ant-design/icons';
import '../HeaderCalendarTaskDrawer.css';
import React from 'react';
import getColorTaskByStatus from '../../../../month/tasks/tasksUtils/getColorTaskByStatus';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import useCheckMobileScreen from '../../../../../../hooks/useCheckMobileScreen';

const StaticForm = (props) => {
  const isMobile = useCheckMobileScreen();

  const getTaskTag = () => {
    if (props.editingItem?.tags?.length <= 0) return [];
    return props.editingItem?.tags?.map((tag) => {
      return tag;
    });
  };

  const renderStatus = () => {
    const status = props.editingItem?.status;
    const statusStr = props.editingItem?.status_str;
    return (
      <div
        style={{
          color: 'white',
          borderRadius: '10px',
          width: '120px',
          height: '30px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: getColorTaskByStatus(status),
        }}
      >
        {statusStr}
      </div>
    );
  };

  return (
    <>
      <Col style={{ color: 'white' }}>
        <Row justify={'space-between'}>
          <Col span={isMobile ? 10 : 22} style={{ fontSize: '16px' }}>
            {props.form.getFieldsValue(['name'])?.name || 'Без названия'}
          </Col>
          <Col span={2}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Tooltip title="Редактировать">
                <Button
                  onClick={() => {
                    props.setEditFields(true);
                  }}
                  shape="circle"
                  icon={<EditOutlined />}
                />
              </Tooltip>
            </div>
          </Col>
        </Row>
        <Row className={'gutter-top'}>
          {getTaskTag()?.length > 0 &&
            getTaskTag().map((item, index) => {
              return <Tag key={index}>{item?.tag_name}</Tag>;
            })}
        </Row>
        <Row className={'gutter-top'}>
          <Col span={12} style={{ fontSize: '16px' }}>
            {`От кого: ${
              props.form.getFieldsValue(['memberCreator'])?.memberCreator ||
              'Система'
            }`}
          </Col>
          <Col span={12}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Form.Item name={'priority'}>
                <Rate
                  onChange={() => {
                    props.setEditFields(true);
                  }}
                  character={<FireOutlined />}
                  allowHalf={false}
                  count={3}
                  style={{
                    fontSize: 25,
                  }}
                />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row className={'gutter-top'}>{renderStatus()}</Row>
        {!props.editingItem?.chat ? (
          <Row className={'Chat-CreateChatButton-wrapper-row'}>
            <Button
              className={'Chat-CreateChatButton'}
              onClick={() => {
                props.createChat();
              }}
              disabled={!props.editingItem}
              type="default"
              shape="round"
              icon={<PlusOutlined />}
              size={'middle'}
            >
              Создать чат
            </Button>
          </Row>
        ) : (
          ''
        )}
      </Col>
    </>
  );
};

StaticForm.propsTypes = {
  form: PropTypes.any,
  editFields: PropTypes.bool,
  editingItem: PropTypes.object,
  setEditFields: PropTypes.func,
  createChat: PropTypes.func.isRequired,
  disableBtnCreateChat: PropTypes.bool,
  chatIsCreated: PropTypes.bool.isRequired,
};

export default StaticForm;
