import { restApiHost } from '../../utils/appVariables';
import { call } from '../../apiUtils/call';

export async function callGetProfileInfo(userInfo) {
  try {
    const url = `${restApiHost}/accounts/profile/`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      },
      userInfo,
    );
    if (json?.data) {
      return json.data;
    }
  } catch (e) {
    console.log('fetch profile info error', e);
    message.error('Ошибка получения информации о пользователе');
  }
}
