import * as PropTypes from 'prop-types';
import {
  Button as ButtonAntd,
  Button,
  Col,
  ConfigProvider,
  Dropdown,
  Menu,
  message,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import React, { useContext, useEffect, useState } from 'react';
import locale from 'antd/lib/locale/ru_RU';
import { setPaginationSettings } from '../../../localStorageUtils/paginationSettings';
import { getFiltersTable } from '../../../utils/getFilters';
import {
  DeleteOutlined,
  EditOutlined,
  FileOutlined,
  FilterOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import EllipsisOutlined from '@ant-design/icons/lib/icons/EllipsisOutlined';
import SearchDataFilter from '../../leeds/filters/SearchData';
import convertToSelectOptions from '../../leeds/filters/filtersUtils/convertToSelectOptions';
import AddAttachmentModal from './AddAttachmentModal';
import { restApiHost } from '../../../utils/appVariables';
import { call } from '../../../apiUtils/call';
import { userInformations } from '../../../contex';
import { ErrorBoundary } from '../../ErrorBoundary';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';
import ScrollingLoadedList from '../../mobile/scrollingLoadedList/ScrollingLoadedList';
import AttachmentListItem from '../../mobile/attachments/list/AttachmentListItem';
import UniversalFiltersModal from '../../mobile/filtersModal/UniversalFiltersModal';
import FilterSelect from '../../leeds/filters/FilterSelect';

const AttachmentsTable = (props) => {
  const [columnFiltersFromTableComponent, setColumnFiltersFromTableComponent] =
    useState(null);
  const [filtersTable, setFiltersTable] = useState(null);
  const [visibleFilters, setVisibleFilters] = useState(false);
  const [visibleAddAttachmentModal, setVisibleAddAttachmentModal] =
    useState(false);
  const [creatingAnAttachment, setCreatingAnAttachment] = useState(false);
  const { userInfo } = useContext(userInformations);
  const [currentAttachment, setCurrentAttachment] = useState(null);
  const [contractListOption, setContractListOption] = useState([]);
  const [loading, setLoading] = useState(false);
  const isMobile = useCheckMobileScreen();

  const contractsStatuses = [
    {
      key: 'new',
      value: 'Новый',
    },
    {
      key: 'rec',
      value: 'На согласовании у клиента',
    },
    {
      key: 'con',
      value: 'Условно подписан',
    },
    {
      key: 'sig',
      value: 'Подписан',
    },
  ];
  const contractStatusOptions = contractsStatuses?.map((item) => {
    return {
      id: item.key,
      title: item.value,
    };
  });

  const columnsTable = [
    {
      title: '',
      key: 'action',
      width: 7,
      align: 'center',
      render: (contract) => (
        <Space size="middle">
          <Dropdown overlay={menu(contract)} trigger={['hover']}>
            <EllipsisOutlined style={{ cursor: 'pointer', fontSize: '24px' }} />
          </Dropdown>
        </Space>
      ),
    },
    {
      title: 'Номер договора',
      key: 'contract__code_contract__icontains',
      width: 50,
      dataIndex: 'contract',
      ellipsis: {
        showTitle: false,
      },
      render: (contract) =>
        contract?.code_contract ? (
          <Tooltip placement={'topLeft'} title={contract?.code_contract}>
            {contract?.code_contract}
          </Tooltip>
        ) : null,
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Номер договора'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={
                columnFiltersFromTableComponent?.contract__code_contract__icontains
              }
              columnKey={'contract__code_contract__icontains'}
            />
          ),
          key: 'contract__code_contract__icontains',
          width: 50,
          visible: visibleFilters,
          dataIndex: 'contract',
          ellipsis: {
            showTitle: false,
          },
          render: (contract) =>
            contract?.code_contract ? (
              <Tooltip placement={'topLeft'} title={contract?.code_contract}>
                {contract?.code_contract}
              </Tooltip>
            ) : null,
        },
      ],
    },
    {
      title: 'Статус Договора',
      key: 'contract__status',
      dataIndex: 'contract',
      width: 50,
      ellipsis: {
        showTitle: false,
      },
      render: (contract) => getStatusFromServer(contract?.status),
      children: [
        {
          title: (
            <FilterSelect
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={columnFiltersFromTableComponent?.contract__status}
              columnKey={'contract__status'}
              dataList={convertToSelectOptions(
                contractStatusOptions,
                'item?.title',
              )}
            />
          ),
          visible: visibleFilters,
          key: 'contract__status',
          dataIndex: 'contract',
          width: 50,
          ellipsis: {
            showTitle: false,
          },
          render: (contract) => getStatusFromServer(contract?.status),
        },
      ],
    },
    {
      title: 'Название вложения',
      key: 'file_info',
      width: 100,
      dataIndex: 'file_info',
      ellipsis: {
        showTitle: false,
      },
      render: (file_info) => (
        <>
          {file_info?.name ? (
            <Tooltip title={file_info.name}>{file_info?.name}</Tooltip>
          ) : (
            <></>
          )}
        </>
      ),
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Название файла'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={columnFiltersFromTableComponent?.file__icontains}
              columnKey={'file__icontains'}
            />
          ),
          key: 'file__icontains',
          width: 100,
          visible: visibleFilters,
          dataIndex: 'file_info',
          ellipsis: {
            showTitle: false,
          },
          render: (file_info) => (
            <>
              {file_info?.name ? (
                <Tooltip title={file_info.name}>{file_info?.name}</Tooltip>
              ) : (
                <></>
              )}
            </>
          ),
        },
      ],
    },
    {
      title: 'Тип вложения',
      key: 'type_attachment',
      dataIndex: 'type_attachment',
      width: 80,
      ellipsis: {
        showTitle: false,
      },
      render: (type_attachment) =>
        type_attachment ? (
          <Tooltip
            placement={'topLeft'}
            title={prepareAttachmentTypeForColumn(
              type_attachment,
              props.typesAttachmentList,
            )}
          >
            {prepareAttachmentTypeForColumn(
              type_attachment,
              props.typesAttachmentList,
            )}
          </Tooltip>
        ) : null,
      children: [
        {
          title: (
            <FilterSelect
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={columnFiltersFromTableComponent?.type_attachment}
              columnKey={'type_attachment'}
              dataList={convertToSelectOptions(
                props.typesAttachmentList,
                'item?.title',
              )}
            />
          ),
          visible: visibleFilters,
          key: 'type_attachment',
          dataIndex: 'type_attachment',
          width: 80,
          ellipsis: {
            showTitle: false,
          },
          render: (type_attachment) =>
            type_attachment ? (
              <Tooltip
                placement={'topLeft'}
                title={prepareAttachmentTypeForColumn(
                  type_attachment,
                  props.typesAttachmentList,
                )}
              >
                {prepareAttachmentTypeForColumn(
                  type_attachment,
                  props.typesAttachmentList,
                )}
              </Tooltip>
            ) : null,
        },
      ],
    },
  ];

  useEffect(() => {
    if (props.filter) {
      const newFilters = {
        ...props.filter?.filter,
        filter: {
          ...props.filter?.filter,
          search: props.filter?.filter?.search,
        },
      };
      setFiltersTable(newFilters);
    }
  }, [props.filter]);

  useEffect(() => {
    setContractListOption(props.contractList);
  }, [props.contractList]);

  useEffect(() => {
    let updateFilters = columnFiltersFromTableComponent
      ? columnFiltersFromTableComponent
      : [];
    if (props.defaultFilter?.length > 0) {
      for (const filter of props.defaultFilter) {
        updateFilters[filter.name] = [filter?.value];
      }
    }
    setColumnFiltersFromTableComponent(updateFilters);
  }, [props.defaultFilter]);

  const menu = (attachment) => {
    return (
      <Menu style={{ width: 220 }}>
        <Menu.Item
          key={'edit'}
          icon={<EditOutlined />}
          onClick={async () => {
            setCurrentAttachment(attachment);
            openModal(false);
          }}
        >
          Просмотреть вложение
        </Menu.Item>

        <Menu.Item
          key={'download'}
          icon={<FileOutlined />}
          onClick={async () => {
            await handleDownloadFile(attachment.file_info);
          }}
        >
          Скачать файл вложения
        </Menu.Item>

        <Popconfirm
          title="Вы действительно хотите удалить запись?"
          onConfirm={async () => await deleteAttachment(attachment?.id)}
          okText="Да"
          cancelText="Нет"
          placement="rightBottom"
        >
          <Menu.Item key={'delete'} icon={<DeleteOutlined />}>
            Удалить
          </Menu.Item>
        </Popconfirm>
      </Menu>
    );
  };

  const getStatusFromServer = (status) => {
    switch (status) {
      case 'new':
        return <Tag color={'cyan'}>Новый</Tag>;
      case 'rec':
        return <Tag color={'purple'}>Отправлен на согласование</Tag>;
      case 'ret':
        return <Tag color={'purple'}>Повторное согласование</Tag>;
      case 'con':
        return <Tag color={'orange'}>Получена копия</Tag>;
      case 'sig':
        return <Tag color={'green'}>Подписан</Tag>;
      case 'arc':
        return <Tag color={'#A9A9A9'}>Архив</Tag>;
      default:
        return <Tag color={'magenta'}>Неизвестный статус</Tag>;
    }
  };

  const prepareAttachmentTypeForColumn = (type, attachmentTypeList) => {
    if (attachmentTypeList) {
      const types = attachmentTypeList.filter(
        (item) => item.id === type?.toString(),
      );
      if (types[0]?.title) {
        return types[0].title;
      } else {
        return type;
      }
    }
  };
  const handleTableChange = (pagination, filtersTable, sorter) => {
    const { page, filters } = getFiltersTable(pagination, filtersTable, sorter);
    props.updatePage(page);
    setColumnFiltersFromTableComponent(filtersTable);
    const newFilters = {
      ...filters,
      filter: { ...filters.filter },
    };
    console.log(newFilters);
    setFiltersTable(newFilters);
    props.handleUpdate(page, newFilters);
  };

  const filteringChildrenPillarByVisible = (columns) => {
    let filteredColumns = columns;
    for (const column of filteredColumns) {
      if (column?.children?.length > 0) {
        column.children = column.children.filter(
          (item) => item.visible === true,
        );
      }
    }
    return filteredColumns;
  };

  const createAttachment = async (body) => {
    try {
      setLoading(true);
      const hide = message.loading('Создание вложения', 100);
      const url = `${restApiHost}/customers/contract_attachments/`;
      const formData = new FormData();
      formData.append('contract', body?.contract);
      formData.append('customer', body?.customer);
      formData.append('type_attachment', body?.type_attachment);
      if (body?.file.length > 0) {
        body?.file.map((file) => {
          if (file?.originFileObj) {
            formData.append(`file`, file.originFileObj, file.name);
          }
        });
      }
      const json = await call(
        url,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
          body: formData,
        },
        userInfo.current.haveViewBranch,
      );

      hide();
      if (!json) return;

      message.success('Вложение успешно добавлено', 4);
      closeModal();
      isMobile && props.handleScrollToTopMobileList();
      props.handleUpdate(isMobile ? 1 : props.page, filtersTable);
    } catch (e) {
      console.log(e);
      message.error('Ошибка запроса на добавление вложения');
    } finally {
      setLoading(false);
    }
  };

  const deleteAttachment = async (attachmentId) => {
    try {
      const url = `${restApiHost}/customers/contract_attachments/${attachmentId}/`;
      const response = await call(
        url,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!response) return;

      message.success('Удаление успешно выполнено!');
      isMobile && props.handleScrollToTopMobileList();
      props.handleUpdate(isMobile ? 1 : props.page, filtersTable);
    } catch (e) {
      console.log('delete contract err', e);
      message.error('Ошибка запроса на удаление вложений');
    }
  };
  const changeVisibleFilters = () => {
    setVisibleFilters(!visibleFilters);
  };

  const openModal = (creation) => {
    setCreatingAnAttachment(creation);
    setVisibleAddAttachmentModal(true);
  };

  const closeModal = () => {
    setCreatingAnAttachment(false);
    setVisibleAddAttachmentModal(false);
    setCurrentAttachment(null);
  };

  const getBlobFile = async (linkToFile) => {
    try {
      const response = await fetch(linkToFile);
      return response.blob();
    } catch (error) {
      console.log(error);
      message.error('Ошибка получения файла');
    }
  };

  const handleDownloadFile = async (fileInfo) => {
    if (fileInfo) {
      try {
        const hide = message.loading('Скачивание файла вложения', 100);
        const blob = await getBlobFile(`${restApiHost}${fileInfo?.url}`);
        const url = URL.createObjectURL(blob);
        const downloadLink = document.createElement('a');
        const filename =
          fileInfo.name && fileInfo.extension
            ? fileInfo.name + `.${fileInfo?.extension}`
            : null;
        document.body.appendChild(downloadLink);
        if (navigator.msSaveOrOpenBlob) {
          navigator.msSaveOrOpenBlob(blob, filename ? filename : '');
        } else {
          downloadLink.href = url;
          downloadLink.download = filename ? filename : '';
          downloadLink.click();
        }
        hide();
        message.success('Вложение успешно скачено', 2);
      } catch (error) {
        console.log(error);
        message.error('Ошибка получения файла');
      }
    }
  };

  return (
    <>
      <div className={'top-table-actions'}>
        <Row justify={'space-between'} style={{ width: '100%' }}>
          <Col>
            <Tooltip placement={'topLeft'} title={'Добавить вложение'}>
              <Button
                size={'small'}
                icon={<PlusOutlined />}
                type={'primary'}
                onClick={() => {
                  openModal(true);
                }}
              />
            </Tooltip>
          </Col>
          <Col>
            <Tooltip title={'Обновить'}>
              <ButtonAntd
                onClick={() => {
                  isMobile && props.handleScrollToTopMobileList();
                  props.handleUpdate(isMobile ? 1 : props.page, filtersTable);
                }}
                style={{ marginLeft: '10px' }}
                type="text"
                icon={<ReloadOutlined />}
              />
            </Tooltip>
            <Tooltip title={'Отобразить фильтры'}>
              <ButtonAntd
                style={{ margin: '0 10px' }}
                disabled={props.loading}
                onClick={() => {
                  isMobile && props.handleScrollToTopMobileList();
                  changeVisibleFilters();
                }}
                type="text"
                icon={<FilterOutlined />}
              />
            </Tooltip>
          </Col>
        </Row>
      </div>
      {!isMobile ? (
        <ConfigProvider locale={locale}>
          <Table
            //scroll={{x: 'auto', y: 'auto'}}
            rowClassName={(record, index) =>
              index % 2 === 0
                ? 'black-text table-row-color'
                : 'black-text table-row-white'
            }
            locale={{
              triggerDesc: 'По убыванию',
              triggerAsc: 'По возрастанию',
              cancelSort: 'Сбросить сортировку',
            }}
            onRow={(record) => {
              return {
                onDoubleClick: async () => {
                  setCurrentAttachment(record);
                  openModal(false);
                },
              };
            }}
            showHeader
            bordered={true}
            loading={props.loading}
            dataSource={props.attachmentList}
            columns={filteringChildrenPillarByVisible(columnsTable)}
            size="small"
            pagination={setPaginationSettings(props.total, props.page, [
              'bottomRight',
            ])}
            onChange={(val) => {
              handleTableChange(
                { current: val?.current },
                columnFiltersFromTableComponent,
              );
            }}
          />
        </ConfigProvider>
      ) : (
        <div style={{ paddingBottom: '10px', paddingTop: '5px' }}>
          <ErrorBoundary>
            <ScrollingLoadedList
              dataList={props.attachmentList}
              totalData={props.total}
              scrollingLoadedListRef={props.scrollingLoadedListRef}
              setDefaultHeight={true}
              loadMoreItems={async () => {
                props.handleUpdate(props.page + 1, filtersTable, true);
              }}
              renderListItem={(item) => (
                <AttachmentListItem
                  attachment={item}
                  getStatusContractFromServer={getStatusFromServer}
                  prepareAttachmentTypeForColumn={(type) =>
                    prepareAttachmentTypeForColumn(
                      type,
                      props.typesAttachmentList,
                    )
                  }
                  menuActions={(attach) => menu(attach)}
                />
              )}
            />
          </ErrorBoundary>
        </div>
      )}
      <ErrorBoundary>
        <AddAttachmentModal
          visible={visibleAddAttachmentModal}
          creationAttachment={creatingAnAttachment}
          currentAttachment={currentAttachment ? currentAttachment : null}
          customerId={props.customerId}
          contractList={contractListOption}
          typesAttachment={props.typesAttachmentList}
          loading={loading}
          createAttachment={async (body) => await createAttachment(body)}
          searchCustomerContracts={(customerId, contractCode) =>
            props.searchCustomerContracts(customerId, contractCode)
          }
          handelCloseModal={closeModal}
          handleDownloadFile={async (fileInfo) =>
            await handleDownloadFile(fileInfo)
          }
        />
      </ErrorBoundary>

      <ErrorBoundary>
        <UniversalFiltersModal
          visibleModal={visibleFilters && isMobile}
          handleClose={() => {
            setVisibleFilters(false);
          }}
          resetFilters={() => {
            setColumnFiltersFromTableComponent([]);
            isMobile && props.handleScrollToTopMobileList();
            setVisibleFilters(false);
            props.handleUpdate(1, {
              filter: {
                customer: props.customerId ? props.customerId.toString() : null,
              },
              order: {},
            });
          }}
          columnsList={columnsTable}
        />
      </ErrorBoundary>
    </>
  );
};

export default AttachmentsTable;

AttachmentsTable.propTypes = {
  attachmentList: PropTypes.array,
  loading: PropTypes.bool,
  page: PropTypes.number,
  customerId: PropTypes.number,
  filter: PropTypes.object,
  total: PropTypes.number,
  defaultFilter: PropTypes.array,
  contractList: PropTypes.array,
  typesAttachmentList: PropTypes.array,
  searchCustomerContracts: PropTypes.func,
  scrollingLoadedListRef: PropTypes.any,
  handleScrollToTopMobileList: PropTypes.func,
  handleUpdate: PropTypes.func,
  updatePage: PropTypes.func,
};
