import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Modal, Tabs, Spin } from 'antd';
import 'moment/locale/ru';

import { restApiHost } from '../../utils/appVariables';
import CustomerBill from './CustomerBill';
import BillTable from '../Bill/BillTable';
import { userInformations } from '../../contex';
import { call } from '../../apiUtils/call';

import useAsyncEffect from 'use-async-effect';
import { ErrorBoundary } from '../ErrorBoundary';
import BasicInformationForm from './modal/taskModal/components/BasicInformationForm';
import {
  editShippingTask,
  getShippingTaskById,
} from '../../logic/task/TaskApiFunctions';
import Details from './modal/taskModal/components/tabs/detailsTab/Details';
import WaypointsTab from './modal/taskModal/components/tabs/waypointsTab/WaypointsTab';
import TransportationTab from './modal/taskModal/components/tabs/transportationTab/TransportationTab';
import ManagersTab from './modal/taskModal/components/tabs/managersTab/ManagersTab';
import CostsTab from './modal/taskModal/components/tabs/costsTab/CostsTab';
import useStateRef from 'react-usestateref';

const { TabPane } = Tabs;

const TaskModal = ({
  updateData,
  visible,
  handleOk,
  handleCancel,
  data,
  handleUpdate,
  choices,
}) => {
  const [createdTask, setCreatedTask] = useState(null);
  const [, setCurrentTab, currentTabRef] = useStateRef(1);
  const [customers, setCustomers] = useState([]);
  const [dataCustomerBill, setDataCustomerBill] = useState([]);
  const [upfrontSum, setUpfrontSum] = useState(0);
  const [actualSum, setActualSum] = useState(0);
  const [customerBillSum, setCustomerBillSum] = useState(0);
  const [loadingModal, setLoadingModal] = useState(false);

  const { userInfo } = useContext(userInformations);

  useAsyncEffect(async () => {
    if (visible) {
      !createdTask && !data && (await getCustomers());
    } else {
      setCurrentTab(1);
    }
  }, [visible]);

  useEffect(async () => {
    if (data) {
      setLoadingModal(true);
      setCreatedTask(data.id);
      setDataCustomerBill(data.customer_bill);
      setUpfrontSum(data?.upfront_costs_sum ?? 0);
      setActualSum(data?.actual_costs_sum ?? 0);
      setCustomerBillSum(data.customer_bill_sum);
      setLoadingModal(false);
    }
  }, [data]);

  const getCustomers = async (keyword) => {
    try {
      const url = keyword
        ? `${restApiHost}/customers/list/?search=${keyword}`
        : `${restApiHost}/customers/list/`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (json?.data) {
        setCustomers(json.data);
      }
    } catch (e) {
      console.log('get customers err', e);
    }
  };

  const refreshTaskInfo = useCallback(
    async (taskId) => {
      try {
        if (!taskId) return;
        setLoadingModal(true);
        const json = await getShippingTaskById(taskId, userInfo);

        if (!json) return;

        updateData(json);
      } catch (e) {
        console.error(e);
      } finally {
        setLoadingModal(false);
      }
    },
    [setLoadingModal, updateData],
  );

  const taskEdit = useCallback(
    async (taskId, body) => {
      if (!taskId) return;
      const json = await editShippingTask(taskId, body, userInfo);
      if (!json) return;

      await refreshTaskInfo(taskId);
    },
    [refreshTaskInfo],
  );

  return (
    <Modal
      destroyOnClose={true}
      width={1000}
      zIndex={199}
      visible={visible}
      title={data ? 'Редактировать заявку' : 'Создать заявку'}
      onOk={handleOk}
      onCancel={() => {
        handleUpdate();
        handleCancel();
      }}
      footer={[
        <Button
          key="back"
          onClick={() => {
            handleUpdate();
            handleCancel();
          }}
        >
          Закрыть
        </Button>,
        currentTabRef.current >= 0 && currentTabRef.current < 6 && data?.id && (
          <Button
            type={'primary'}
            key="next"
            onClick={() => {
              setCurrentTab(currentTabRef.current + 1);
            }}
          >
            Далее
          </Button>
        ),
      ]}
    >
      <Spin spinning={loadingModal}>
        <ErrorBoundary>
          <BasicInformationForm
            taskData={data}
            refreshTaskInfo={async (taskId) => {
              await refreshTaskInfo(taskId);
            }}
            taskEdit={async (body, taskId) => {
              await taskEdit(taskId, body);
            }}
          />
        </ErrorBoundary>
        <Tabs
          destroyInactiveTabPane={true}
          className={'tabs-scroll'}
          centered={true}
          activeKey={currentTabRef.current.toString()}
          onChange={(key) => setCurrentTab(Number(key))}
        >
          <TabPane tab="Детали" key="1">
            <Details taskData={data} />
          </TabPane>

          <TabPane key={'2'} tab={'Сотрудники'}>
            <ErrorBoundary>
              <ManagersTab taskData={data} choices={choices} />
            </ErrorBoundary>
          </TabPane>

          <TabPane tab="Пункты" key="3" disabled={!createdTask}>
            <ErrorBoundary>
              <WaypointsTab taskData={data} />
            </ErrorBoundary>
          </TabPane>

          <TabPane tab={'Перевозки'} key="4" disabled={!createdTask}>
            <ErrorBoundary>
              <TransportationTab
                taskData={data}
                taskEdit={async (body, taskId) => {
                  await taskEdit(taskId, body);
                }}
              />
            </ErrorBoundary>
          </TabPane>

          <TabPane
            tab="Предварительные расходы"
            key="5"
            disabled={!createdTask}
          >
            <ErrorBoundary>
              <CostsTab
                taskData={data}
                apiEndpoint={'upfront_costs'}
                totalSum={upfrontSum}
                refreshTaskInfo={async () => {
                  await refreshTaskInfo(data?.id);
                }}
              />
            </ErrorBoundary>
          </TabPane>

          <TabPane tab="Фактические расходы" key="6" disabled={!createdTask}>
            <ErrorBoundary>
              <CostsTab
                taskData={data}
                apiEndpoint={'actual_costs'}
                totalSum={actualSum}
                refreshTaskInfo={async () => {
                  await refreshTaskInfo(data?.id);
                }}
              />
            </ErrorBoundary>
          </TabPane>

          <TabPane tab={'Суммы клиенту'} key={'7'} disabled={!createdTask}>
            <CustomerBill
              customers={customers}
              data={dataCustomerBill}
              taskData={data}
              // transportList={transportationData}
              refresh={(task) => refreshTaskInfo(task)}
              customerBillSum={customerBillSum}
              preTaskId={data && data.id}
            />
          </TabPane>

          <TabPane tab={'Счета'} key={'8'} disabled={!createdTask}>
            <BillTable
              task={createdTask}
              refresh={() => refreshTaskInfo(createdTask)}
              data={data && data.customer_invoice_pay}
              loading={loadingModal}
              number={data && data.number_task}
            />
          </TabPane>

          {/* <TabPane
            tab={<PaperClipOutlined />}
            key={'9'}
            disabled={!createdTask}
          >
            <TaskFileList
              //defaultFileList={defaultFileList}
              taskId={data && data.id}
            />
          </TabPane>
          <TabPane tab="Переписка" key="10" disabled={!data?.related_message}>
            {data?.related_message ? (
              <>
                {!selectedEmail ? (
                  <EmailListItem
                    email={data.related_message}
                    taskType={true}
                    showBodyEmail={handleShowBodyEmail}
                  />
                ) : (
                  <EmailBody
                    back={() => {
                      setSelectedEmail(null);
                    }}
                    loading={false}
                    taskType={true}
                    email={selectedEmail}
                  />
                )}
              </>
            ) : (
              'пусто'
            )}
          </TabPane>*/}
        </Tabs>
      </Spin>
    </Modal>
  );
};

export default TaskModal;
