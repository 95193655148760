import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useCallback, useMemo, useState } from 'react';
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import SortableOverlay from './SortableOverlay';
import './SortableList.css';

const SortableList = (props) => {
  const [draggableItem, setDraggableItem] = useState(null);

  const activeItem = useMemo(
    () => props.items.find((item) => item.index === draggableItem?.id),
    [draggableItem, props.items],
  );

  useEffect(() => {
    props.onDragStart && props.onDragStart(activeItem);
  }, [activeItem]);

  const itemsIds = useMemo(() => {
    return props.items?.map((item) => item?.index);
  }, [props.items]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleOnDragStart = useCallback(
    (activeItem) => {
      setDraggableItem(activeItem);
    },
    [setDraggableItem],
  );

  const handleOnDragEnd = useCallback(
    (active, over) => {
      if (over && active.id !== over?.id) {
        const activeIndex = props.items.findIndex(
          ({ index }) => index === active.id,
        );
        const overIndex = props.items.findIndex(
          ({ index }) => index === over.id,
        );

        props.onChange(arrayMove(props.items, activeIndex, overIndex));
      }
      setDraggableItem(null);
    },
    [props.items, props.onChange, setDraggableItem],
  );

  const handleOnDragCancel = useCallback(() => {
    setDraggableItem(null);
  }, [setDraggableItem]);

  return (
    <>
      <DndContext
        sensors={sensors}
        onDragStart={({ active }) => {
          handleOnDragStart(active);
        }}
        onDragEnd={({ active, over }) => {
          handleOnDragEnd(active, over);
        }}
        onDragCancel={() => {
          handleOnDragCancel();
        }}
      >
        <SortableContext items={itemsIds}>
          {props.noUseListStyle ? (
            <>
              {props.items?.map((item, index) => (
                <React.Fragment key={index}>
                  {props.renderItem(item)}
                </React.Fragment>
              ))}
            </>
          ) : (
            <ul className="SortableList">
              {props.items?.map((item, index) => (
                <React.Fragment key={index}>
                  {props.renderItem(item)}
                </React.Fragment>
              ))}
            </ul>
          )}
        </SortableContext>
        <SortableOverlay>
          {activeItem && props.renderItem(activeItem)}
        </SortableOverlay>
      </DndContext>
    </>
  );
};

SortableList.propTypes = {
  items: PropTypes.array,
  noUseListStyle: PropTypes.bool,
  onChange: PropTypes.func,
  renderItem: PropTypes.func,
  onDragStart: PropTypes.func,
};

export default SortableList;
