export default function prepareObjectForFilter(choiceObject) {
  return choiceObject
    ? Object.entries(choiceObject).map((item) => {
        return {
          title: item[1],
          id: item[0],
        };
      })
    : [];
}
