export enum TodoDemoAccessTypes {
  SET_DEMO_ACCESS_IS_USED = 'SET_DEMO_ACCESS_IS_USED',
}

interface SetDemoAccessIsUsed {
  type: TodoDemoAccessTypes.SET_DEMO_ACCESS_IS_USED;
  payload: boolean;
}

export type TodoActionDemoAccess = SetDemoAccessIsUsed;
