const getLocations = async (query) => {
  try {
    const api_url = `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address`;
    const token = '3d7421d88f3c61447899bad9cc47a61040d20dbd';
    const options = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Token ' + token,
      },
      body: query
        ? JSON.stringify({ count: 10, query: query })
        : JSON.stringify({ count: 10, query: 'а' }),
    };

    const res = await fetch(api_url, options);
    const json = await res.json();
    return json?.suggestions ? json.suggestions : [];
  } catch (e) {
    console.log('query locations err', e);
    return [];
  }
};

export default getLocations;
