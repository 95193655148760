import PropTypes from 'prop-types';
import { ErrorBoundary } from '../../../ErrorBoundary';
import CellsWithTime from '../../cellsWithTime/CellsWithTime';

const DayOfWeekBody = (props) => {
  return (
    <>
      <ErrorBoundary>
        <CellsWithTime
          day={props.day}
          displayTimeInCell={props.displayTimeInCell}
          tasks={props.tasks}
          selectedStaff={props.selectedStaff}
          useForDayViewCalendar={props.useForDayViewCalendar}
          goToCompetitionDate={(day) => {
            props.goToCompetitionDate(day);
          }}
          handleEditTask={(task) => props.handleEditTask(task)}
          handleCreateTask={(day, time) =>
            props.handleCreateTask(props.day, time)
          }
        />
      </ErrorBoundary>
    </>
  );
};
DayOfWeekBody.propTypes = {
  day: PropTypes.any,
  task: PropTypes.any,
  displayTimeInCell: PropTypes.bool,
  useForDayViewCalendar: PropTypes.bool,
  selectedStaff: PropTypes.number,
  handleEditTask: PropTypes.func,
  handleCreateTask: PropTypes.func,
  goToCompetitionDate: PropTypes.func,
};

export default DayOfWeekBody;
