import { restApiHost } from '../../../utils/appVariables';
import { call } from '../../../apiUtils/call';
import { message } from 'antd';

export async function getListService(userInfo, keyword) {
  const params = new URLSearchParams();
  params.set('active', 'true');

  if (keyword) {
    params.set('search', `${keyword}`);
  }

  try {
    const url = `${restApiHost}/refbook/service/?${params}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      },
      userInfo.current.haveViewBranch,
    );
    if (!json) return;
    return json;
  } catch (e) {
    console.log('call getListService error', e);
    message.error('Ошибка запроса на получение списка услуг');
  }
}
