type resultOptionsForSelect = {
  [key: string]: unknown;
  title?: unknown;
  value?: unknown;
};

const convertToSelectOptions = (
  options: resultOptionsForSelect[],
  variationTitleOption: string,
) => {
  let dataList: resultOptionsForSelect[] = [];
  if (options?.length > 0) {
    dataList = options.map((item) => {
      return {
        title: variationTitleOption ? eval(variationTitleOption) : null,
        value: item.id,
      };
    });
  }
  return dataList;
};

export default convertToSelectOptions;
