import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import {
  Col,
  Dropdown,
  Menu,
  message,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
  Button,
  Modal,
  ConfigProvider,
} from 'antd';
import locale from 'antd/lib/locale/ru_RU';
import {
  DeleteOutlined,
  EllipsisOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { restApiHost } from '../../utils/appVariables';
import TaskModal from './TaskModal';
import 'moment/locale/ru';
import TaskHistoryTable from './TaskHistoryTable';
import { getFiltersTable } from '../../utils/getFilters';
import { priceFormatter } from '../../utils/priceFormatter';
import { dateStrRevers } from '../../utils/dateStrRevers';
import { userInformations } from '../../contex';
import { call } from '../../apiUtils/call';
import { setPaginationSettings } from '../../localStorageUtils/paginationSettings';
import RangeNumberFilters from '../leeds/filters/RangeNumberFilters';
import SearchDataFilter from '../leeds/filters/SearchData';
import FilterByStaff from '../leeds/filters/FilterByStaff';
import FilterDatePicker from '../leeds/filters/FilterDatePicker';
import convertToSelectOptions from '../leeds/filters/filtersUtils/convertToSelectOptions';
import FilterSelect from '../leeds/filters/FilterSelect';
import {
  prepareColumnsForResizing,
  sortingColumnsTable,
} from '../settingTable/sortingColumnsTable';
import { ErrorBoundary } from '../ErrorBoundary';
import SettingTableColumnsDrawer from '../settingTable/SettingTableColumnsDrawer';
import { callGetProfileInfo } from '../account/callGetProfileInfo';
import useAsyncEffect from 'use-async-effect';
import ResizableTableColumnHeader from '../table/ResizableTableColumnHeader';
import { editTableColumn } from '../../logic/profileInfo/mainTable/MainTableApiFunctions';
import { getProfileInfo } from '../../logic/account/AccountsApiFunctions';
import { userInfoHaveViewAllBranch } from '../../logic/account/utils';
import useStateRef from 'react-usestateref';
import filterActiveColumns from '../table/utils';
import changeWidthStyleMainTable from '../table/changeWidthStyleMainTable';
import ScrollingLoadedList from '../mobile/scrollingLoadedList/ScrollingLoadedList';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import TaskListItem from '../mobile/task/list/TaskListItem';
import UniversalFiltersModal from '../mobile/filtersModal/UniversalFiltersModal';

const TaskTable = ({
  //filterTable,
  loading,
  applications,
  updateTable,
  totalRecords,
  //filters,
  //setFilters,
  choices,
  modalTask,
  tableFilters,
  setLoading,
  page,
  visibleFilters,
  visibleTableSetting,
  onCloseDrawerTableSetting,
  taskIdForRedirect,
  clearTaskIdForRedirect,
  scrollingLoadedListRef,
  setVisibleFilters,
  handleScrollToTopMobileList,
}) => {
  const tableName = 'tasks';
  const [currEditApplication, setCurrEditApplication] = useState(null);
  const [visibleEditApplicationModal, setVisibleEditApplicationModal] =
    useState(false);
  const [, setParams, paramsRef] = useStateRef(null);
  const [transportList, setTransportList] = useState([]);
  const isMobile = useCheckMobileScreen();
  //history task
  const [visibleTaskHistoryModal, setVisibleTaskHistoryModal] = useState(false);
  const [taskHistory, setTaskHistory] = useState([]);
  const [taskHistoryCount, setTaskHistoryCount] = useState(0);
  const [loadingTaskHistory, setLoadingTaskHistory] = useState(false);
  const { userInfo } = useContext(userInformations);
  const [staffList, setStaffList] = useState([]);
  const [loadingStaffList, setLoadingStaffList] = useState(false);
  const [columnFiltersFromTableComponent, setColumnFiltersFromTableComponent] =
    useState([]);
  const [pageHistoryTable, setPageHistoryTable] = useState(1);
  const [baseColumns, setBaseColumns, baseColumnsRef] = useStateRef([]);
  const mainTableRef = useRef(null);
  const [statusTaskOptionsForFilters] = useState([
    {
      title: 'Новая',
      id: 'cre',
    },
    {
      title: 'Ожидает логиста',
      id: 'log',
    },
    {
      title: 'На оплате',
      id: 'pay',
    },
    {
      title: 'В работе',
      id: 'wor',
    },
    {
      title: 'Ожидает вагон',
      id: 'car',
    },
    {
      title: 'В пути',
      id: 'way',
    },
    {
      title: 'Исполнена',
      id: 'suc',
    },
    {
      title: 'Закрыта',
      id: 'clo',
    },
  ]);
  const [templateTaskOptionsForFilters] = useState([
    {
      title: 'Авто',
      id: 1,
    },
    {
      title: 'Импорт',
      id: 2,
    },
    {
      title: 'Отправление',
      id: 3,
    },
    {
      title: 'Отпр.-Порожний',
      id: 4,
    },
    {
      title: 'Предоставление вагона',
      id: 5,
    },
    {
      title: 'Предоставление платформы',
      id: 6,
    },
    {
      title: 'Прибытие',
      id: 7,
    },
    {
      title: 'Продажа контейнера',
      id: 8,
    },
    {
      title: 'Ремонт',
      id: 9,
    },
    {
      title: 'Склад - Склад',
      id: 10,
    },
    {
      title: 'Франшиза',
      id: 11,
    },
  ]);

  const costAvailabilityOptions = [
    {
      title: 'Да',
      id: true,
    },
    {
      title: 'Нет',
      id: false,
    },
  ];

  useEffect(() => {
    getStaffList();
    getTransportList();
  }, []);

  useAsyncEffect(async () => {
    if (taskIdForRedirect) {
      await getTaskInfo(taskIdForRedirect);
      clearTaskIdForRedirect();
      setVisibleEditApplicationModal(true);
    }
  }, [taskIdForRedirect]);

  useEffect(() => {
    if (!tableFilters) {
      setColumnFiltersFromTableComponent([]);
    }
  }, [tableFilters]);

  useEffect(() => {
    const col = sortingColumnsTable(
      userInfo,
      tableName,
      columns,
      visibleFilters,
    );
    const preparedColumnsForResizing = prepareColumnsForResizing(
      col,
      handleResize,
    );
    setBaseColumns(preparedColumnsForResizing);
  }, [visibleFilters, staffList]);

  useEffect(() => {
    if (tableFilters) {
      const newFilters = {
        ...tableFilters?.filter,
        filter: {
          ...tableFilters?.filter,
          search: tableFilters?.filter?.search,
        },
      };
      setParams(newFilters);
    }
  }, [tableFilters]);

  useEffect(() => {
    if (modalTask) {
      setVisibleEditApplicationModal(true);
      setCurrEditApplication(modalTask);
    }
  }, [modalTask]);

  const getTaskInfo = async (id) => {
    try {
      const url = `${restApiHost}/shipping/tasks/${id}/`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (json?.data) {
        setCurrEditApplication(json?.data);
      }
    } catch (e) {
      console.log('fetch profile info error', e);
      message.error('Ошибка запроса на получение информации по пред. заявке');
    }
  };
  const getTransportList = async () => {
    try {
      const url = `${restApiHost}/refbook/transport_units/?active=true`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (json?.data) {
        setTransportList(json.data);
      }
    } catch (e) {
      console.log(e);
      message.error('Ошибка получения списка вида тр. ед.');
    }
  };
  const deleteApplication = async (task) => {
    try {
      const url = `${restApiHost}/shipping/tasks/${task}/`;
      const response = await call(
        url,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!response) return;

      message.success('Запись успешно удалена!');

      isMobile && handleScrollToTopMobileList();
      handleUpdate &&
        handleUpdate(isMobile ? 1 : page, paramsRef.current, false);
    } catch (e) {
      console.log('error delete application', e);
      // message.error('Ошибка запроса на удаление!')
    }
  };

  const handleUpdate = (page = 1, params, concatData = false) => {
    updateTable(page, params, concatData);
  };

  const getTaskHistory = async (id, page = 1) => {
    setLoadingTaskHistory(true);
    try {
      const url = `${restApiHost}/history/task/${id}?page=${page}`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      setTaskHistory(json?.history_log);
      setTaskHistoryCount(json?.total);
      setLoadingTaskHistory(false);
    } catch (e) {
      console.log(e);
      setLoadingTaskHistory(false);
      message.error('Ошибка запроса на получение истории заявки');
    }
  };

  const menu = (application) => {
    return (
      <Menu style={{ width: 200 }}>
        {/*<Menu.Item icon={<EyeOutlined/>} onClick={() => {*/}
        {/*    setCurrEditApplication(application)*/}
        {/*    setShowApplicationInfo(true)*/}
        {/*}}>Просмотреть</Menu.Item>*/}
        {/*<Menu.Item icon={<EditOutlined/>} onClick={() => {*/}
        {/*    setCurrEditApplication(application)*/}
        {/*    setVisibleEditApplicationModal(true)*/}
        {/*}}>*/}
        {/*    Редактировать*/}
        {/*</Menu.Item>*/}
        {/*<Menu.Item
          icon={<CopyOutlined />}
          onClick={() => {
            taskCopy(application.id);
          }}
        >
          Копировать
        </Menu.Item>*/}
        <Menu.Item
          icon={<EditOutlined />}
          onClick={() => {
            setCurrEditApplication(application);
            setVisibleEditApplicationModal(true);
          }}
        >
          Редактировать
        </Menu.Item>
        {/*<Menu.Item
          icon={<HistoryOutlined />}
          onClick={() => {
            setVisibleTaskHistoryModal(true);
            setCurrEditApplication(application);
            getTaskHistory(application.id);
          }}
        >
          История изменений
        </Menu.Item>*/}
        <Popconfirm
          title="Вы действительно хотите удалить запись?"
          onConfirm={() => deleteApplication(application.id)}
          onCancel={() => console.log('Delete item')}
          okText="Да"
          cancelText="Нет"
          placement="rightBottom"
        >
          <Menu.Item icon={<DeleteOutlined />}>Удалить</Menu.Item>
        </Popconfirm>
      </Menu>
    );
  };

  /*  const taskCopy = async (task) => {
    try {
      const url = `${restApiHost}/shipping/task/copy/`;
      const json = await call(
        url,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ task }),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      message.success('Заявка успешно скопирована!');
      updateTable(page, params);
    } catch (e) {
      console.log('copy task err', e);
      message.error('Ошибка запроса на копирование заявки!');
    }
  };*/
  const getStaffList = async () => {
    try {
      const url = `${restApiHost}/accounts/staff_users/`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (json?.data) {
        setStaffList(json.data);
      }
    } catch (e) {
      console.log('fetch profile info error', e);
    }
  };

  const searchStaff = async (keyword) => {
    try {
      setLoadingStaffList(true);
      const url = `${restApiHost}/accounts/staff_users/?search=${keyword}`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );
      if (json?.data) {
        setStaffList(json?.data);
      }
    } catch (e) {
      console.log(e);
      message.error('Ошибка запроса! Не удалось найти лида');
    } finally {
      setLoadingStaffList(false);
    }
  };
  const getStatusFromServer = (status) => {
    switch (status) {
      case 'cre':
        return <Tag color={'cyan'}>Новая</Tag>;
      case 'log':
        return <Tag color={'purple'}>Ожидает логиста</Tag>;
      case 'pay':
        return <Tag color={'orange'}>На оплате</Tag>;
      case 'wor':
        return <Tag color={'gold'}>В работе</Tag>;
      case 'car':
        return <Tag color={'volcano'}>Ожидает вагон</Tag>;
      case 'way':
        return <Tag color={'blue'}>В пути</Tag>;
      case 'suc':
        return <Tag color={'green'}>Исполнена</Tag>;
      case 'clo':
        return <Tag color={'red'}>Закрыта</Tag>;
      default:
        return <Tag color={'magenta'}>Неизвестный статус</Tag>;
    }
  };

  const updateUserInfo = async (userInfoForCallApi) => {
    setLoading(true);
    const profileInfo = await callGetProfileInfo(userInfoForCallApi);

    const haveViewBranch =
      profileInfo?.data?.permissions.some(
        (o) => o.codename === 'switch_branch',
      ) &&
      profileInfo?.data?.permissions.some((o) => o.codename === 'view_branch');

    if (haveViewBranch) {
      profileInfo['haveViewBranch'] = true;
    }
    userInfo.current = profileInfo;
    const col = sortingColumnsTable(
      userInfo,
      tableName,
      columns,
      visibleFilters,
    );
    const preparedColumnsForResizing = prepareColumnsForResizing(
      col,
      handleResize,
    );
    setBaseColumns(preparedColumnsForResizing);
    setLoading(false);
  };

  const filteringChildrenPillarByVisible = (columns) => {
    let filteredColumns = columns?.filter(
      (column) => column?.columnId === 'active',
    );

    for (const column of filteredColumns) {
      if (column?.children?.length > 0) {
        column.children = column.children.filter(
          (item) => item.visible === true,
        );
      }
    }

    return filteredColumns;
  };

  const handleResize = useCallback(
    (index) =>
      async (e, { size }) => {
        const copyColumns = [...baseColumnsRef.current];

        setBaseColumns((columns) => {
          const nextColumns = [...columns];
          const children = nextColumns[index]?.children
            ? // eslint-disable-next-line no-unsafe-optional-chaining
              [...nextColumns[index]?.children]
            : null;
          if (children && children[0]?.width) {
            children[0].width = size;
          }
          nextColumns[index] = {
            ...nextColumns[index],
            width: size,
            children: children ? [...children] : [],
          };
          return nextColumns;
        });

        const databaseColumnsId = copyColumns[index]?.idInDatabase;
        if (!databaseColumnsId) return;

        await editTableColumn(databaseColumnsId, { width: size }, userInfo);
        const res = await getProfileInfo(userInfo);
        if (res) {
          userInfo.current = res;
          const haveViewBranch = userInfoHaveViewAllBranch(res);
          if (haveViewBranch) {
            userInfo.current['haveViewBranch'] = true;
          }
        }
      },
    [baseColumnsRef.current],
  );

  const columns = [
    {
      title: '',
      key: 'action',
      name: 'Действия',
      uniqueIndex: 'action',
      index: 1,
      width: 50,
      align: 'center',
      render: (application) => (
        <Space size="middle">
          <Dropdown overlay={menu(application)} trigger={['hover']}>
            <EllipsisOutlined style={{ cursor: 'pointer', fontSize: '24px' }} />
          </Dropdown>
        </Space>
      ),
    },
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      width: 80,
      uniqueIndex: 'id',
      index: 2,
      children: [
        {
          title: '',
          visible: visibleFilters,
          key: 'id',
          dataIndex: 'id',
          width: 150,
        },
      ],
    },
    {
      title: 'Номер заявки',
      key: 'task_number__search',
      dataIndex: 'task_number',
      width: 150,
      uniqueIndex: 'task_number',
      index: 3,
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Номер'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={
                columnFiltersFromTableComponent?.task_number__search
              }
              columnKey={'task_number__search'}
            />
          ),
          visible: visibleFilters,
          key: 'task_number__search',
          dataIndex: 'task_number',
          width: 150,
        },
      ],
    },
    {
      title: 'Клиент',
      width: 200,
      key: 'lead__company_name__search',
      ellipsis: {
        showTitle: false,
      },
      uniqueIndex: 'customer',
      index: 4,
      dataIndex: 'customer',
      render: (customer) => {
        if (customer && customer.short_name) {
          return customer.short_name;
        } else if (customer && customer.legal_name) {
          return (
            <Tooltip placement="topLeft" title={customer.legal_name}>
              {customer.legal_name}
            </Tooltip>
          );
        } else {
          return '';
        }
      },
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Клиент'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={
                columnFiltersFromTableComponent?.customer__short_name__icontains
              }
              columnKey={'customer__short_name__icontains'}
            />
          ),
          key: 'lead__company_name__icontains',
          width: 150,
          visible: visibleFilters,
          ellipsis: {
            showTitle: false,
          },
          dataIndex: 'customer',
          render: (customer) => {
            if (customer && customer.short_name) {
              return customer.short_name;
            } else if (customer && customer.legal_name) {
              return (
                <Tooltip placement="topLeft" title={customer.legal_name}>
                  {customer.legal_name}
                </Tooltip>
              );
            } else {
              return '';
            }
          },
        },
      ],
    },
    {
      title: 'Ответственный',
      key: 'list_managers__manager__id',
      width: 250,
      ellipsis: {
        showTitle: false,
      },
      uniqueIndex: 'otv',
      index: 5,
      dataIndex: 'otv_manager',
      render: (otv_manager) => {
        return <Tooltip>{otv_manager ?? '-'}</Tooltip>;
      },
      children: [
        {
          title: (
            <FilterByStaff
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              viewSearch={true}
              visibleSelect={false}
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={columnFiltersFromTableComponent?.otv}
              columnKey={'otv'}
              dataList={convertToSelectOptions(
                staffList,
                'item?.full_name || item?.email',
              )}
              onSearch={(e) => {
                if (e) {
                  searchStaff(e);
                } else {
                  getStaffList();
                }
              }}
            />
          ),
          visible: visibleFilters,
          width: 270,
          key: 'list_managers__manager__id',
          dataIndex: 'otv_manager',
          render: (otv_manager) => {
            return <Tooltip>{otv_manager ?? '-'}</Tooltip>;
          },
        },
      ],
    },
    {
      title: 'Статус',
      key: 'status',
      dataIndex: 'status',
      width: 200,
      uniqueIndex: 'status',
      index: 6,
      render: (status) => status && getStatusFromServer(status),
      children: [
        {
          title: (
            <FilterSelect
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={columnFiltersFromTableComponent?.status}
              columnKey={'status'}
              dataList={convertToSelectOptions(
                statusTaskOptionsForFilters,
                'item?.title',
              )}
            />
          ),
          key: 'status',
          dataIndex: 'status',
          visible: visibleFilters,
          width: 200,
          ellipsis: {
            showTitle: false,
          },
          render: (status) => status && getStatusFromServer(status),
        },
      ],
    },
    {
      title: 'Тип заявки',
      key: 'template',
      dataIndex: 'template',
      width: 180,
      uniqueIndex: 'template',
      index: 8,
      render: (template) => (template?.name ? template.name : ''),
      children: [
        {
          title: (
            <FilterSelect
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={columnFiltersFromTableComponent?.template}
              columnKey={'template'}
              dataList={convertToSelectOptions(
                templateTaskOptionsForFilters,
                'item?.title',
              )}
            />
          ),
          visible: visibleFilters,
          key: 'template',
          dataIndex: 'template',
          width: 180,
          render: (template) => (template?.name ? template.name : ''),
        },
      ],
    },
    {
      title: 'Дата создания',
      key: 'date_create__periodDate',
      dataIndex: 'date_create',
      width: 200,
      uniqueIndex: 'date_create',
      index: 9,
      render: (date_create) =>
        date_create ? dateStrRevers(date_create.split('T')[0]) : '',
      children: [
        {
          title: (
            <FilterDatePicker
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={
                columnFiltersFromTableComponent?.date_create__periodDate
              }
              columnKey={'date_create__periodDate'}
            />
          ),
          visible: visibleFilters,
          key: 'date_create__periodDate',
          dataIndex: 'date_create',
          width: 200,
          render: (date_create) =>
            date_create ? dateStrRevers(date_create.split('T')[0]) : '',
        },
      ],
    },
    {
      title: 'Кол-во тр. ед.',
      key: 'transport_count',
      dataIndex: 'transport_count',
      width: 150,
      uniqueIndex: 'transport_count',
      index: 10,
      children: [
        {
          title: (
            <RangeNumberFilters
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={
                columnFiltersFromTableComponent?.transport_count__range
              }
              columnKey={'transport_count__range'}
            />
          ),
          visible: visibleFilters,
          key: 'transport_count__range',
          dataIndex: 'transport_count',
          width: 150,
        },
      ],
    },
    {
      title: 'Наименования тр. ед.',
      key: 'transport_unit_types',
      dataIndex: 'transport_unit_types',
      width: 300,
      ellipsis: {
        showTitle: false,
      },
      uniqueIndex: 'transport',
      index: 11,
      render: (transport_unit_types) => {
        if (transport_unit_types && transport_unit_types?.length > 0) {
          return transport_unit_types.map((item) => (
            <Tag key={item}>{item}</Tag>
          ));
        } else {
          return '';
        }
      },
      children: [
        {
          title: (
            <FilterSelect
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={
                columnFiltersFromTableComponent?.transport_cargo_task__transport__transport_unit_id
              }
              columnKey={'transport_cargo_task__transport__transport_unit_id'}
              dataList={convertToSelectOptions(
                transportList,
                "item?.type_full || item?.type_short || 'Без названия'",
              )}
            />
          ),
          visible: visibleFilters,
          key: 'transport_cargo_task__transport__transport_unit__id',
          dataIndex: 'transport_unit_types',
          ellipsis: {
            showTitle: false,
          },
          width: 200,
          render: (transport_unit_types) => {
            if (transport_unit_types && transport_unit_types?.length > 0) {
              return transport_unit_types.map((item) => (
                <Tag key={item}>{item}</Tag>
              ));
            } else {
              return '';
            }
          },
        },
      ],
    },
    {
      title: 'Маршрут',
      key: 'route',
      width: 250,
      dataIndex: 'route',
      ellipsis: {
        showTitle: false,
      },
      uniqueIndex: 'route',
      index: 12,
      render: (route) =>
        route ? (
          <Tooltip placement={'topLeft'} title={route}>
            {route}
          </Tooltip>
        ) : null,
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Маршрут'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={columnFiltersFromTableComponent?.route__search}
              columnKey={'route__search'}
            />
          ),
          visible: visibleFilters,
          key: 'route__search',
          width: 250,
          dataIndex: 'route',
          ellipsis: {
            showTitle: false,
          },
          render: (route) =>
            route ? (
              <Tooltip placement={'topLeft'} title={route}>
                {route}
              </Tooltip>
            ) : null,
        },
      ],
    },
    {
      title: 'Номер счета',
      key: 'number_cost',
      dataIndex: 'number_cost',
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      uniqueIndex: 'number_cost',
      index: 13,
      render: (number_cost) => {
        if (number_cost) {
          return (
            <Tooltip placement="topLeft" title={number_cost}>
              {number_cost}
            </Tooltip>
          );
        } else {
          return '';
        }
      },
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Номер счета'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={
                columnFiltersFromTableComponent?.number_cost__search
              }
              columnKey={'number_cost__search'}
            />
          ),
          visible: visibleFilters,
          key: 'number_cost__search',
          dataIndex: 'number_cost',
          width: 150,
          ellipsis: {
            showTitle: false,
          },
          render: (number_cost) => {
            if (number_cost) {
              return (
                <Tooltip placement="topLeft" title={number_cost}>
                  {number_cost}
                </Tooltip>
              );
            } else {
              return '';
            }
          },
        },
      ],
    },
    {
      title: 'Сумма клиенту',
      key: 'price',
      dataIndex: 'price__range',
      width: 200,
      uniqueIndex: 'sum_client',
      index: 14,
      render: (price) => (price ? priceFormatter(price) : 0),
      children: [
        {
          title: (
            <RangeNumberFilters
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={columnFiltersFromTableComponent?.price__range}
              columnKey={'price__range'}
            />
          ),
          visible: visibleFilters,
          key: 'price__range',
          dataIndex: 'price',
          width: 180,
          render: (price) => (price ? priceFormatter(price) : 0),
        },
      ],
    },
    {
      title: 'Есть счет',
      key: 'cost_availability',
      dataIndex: 'cost_availability',
      width: 120,
      uniqueIndex: 'cost_availability',
      index: 15,
      ellipsis: {
        showTitle: false,
      },
      render: (cost_availability) => (cost_availability ? 'ДА' : 'НЕТ'),
      children: [
        {
          title: (
            <FilterSelect
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={columnFiltersFromTableComponent?.cost_availability}
              columnKey={'cost_availability'}
              useSingleSelect={true}
              dataList={convertToSelectOptions(
                costAvailabilityOptions,
                'item?.title',
              )}
            />
          ),
          visible: visibleFilters,
          key: 'cost_availability',
          dataIndex: 'cost_availability',
          ellipsis: {
            showTitle: false,
          },
          width: 120,
          render: (cost_availability) => (cost_availability ? 'ДА' : 'НЕТ'),
        },
      ],
    },
    {
      title: 'Предв. расходы',
      key: 'upfront_costs_sum',
      dataIndex: 'upfront_costs_sum',
      width: 200,
      uniqueIndex: 'upfront_costs_sum',
      index: 16,
      render: (upfront_costs_sum) =>
        upfront_costs_sum ? priceFormatter(upfront_costs_sum) : 0,
      children: [
        {
          title: (
            <RangeNumberFilters
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={
                columnFiltersFromTableComponent?.upfront_costs_sum__range
              }
              columnKey={'upfront_costs_sum__range'}
            />
          ),
          visible: visibleFilters,
          key: 'upfront_costs_sum__range',
          dataIndex: 'upfront_costs_sum',
          width: 200,
          render: (upfront_costs_sum) =>
            upfront_costs_sum ? priceFormatter(upfront_costs_sum) : 0,
        },
      ],
    },
    {
      title: 'Дельта',
      key: 'delta',
      dataIndex: 'delta',
      width: 150,
      uniqueIndex: 'delta',
      index: 17,
      render: (delta) => (delta ? priceFormatter(delta) : 0),
      children: [
        {
          title: (
            <RangeNumberFilters
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={columnFiltersFromTableComponent?.delta__range}
              columnKey={'delta__range'}
            />
          ),
          visible: visibleFilters,
          key: 'delta__range',
          dataIndex: 'delta',
          width: 150,
          render: (delta) => (delta ? priceFormatter(delta) : 0),
        },
      ],
    },
    {
      title: 'Ведет',
      key: 'vdt',
      width: 250,
      ellipsis: {
        showTitle: false,
      },
      uniqueIndex: 'vdt',
      index: 5,
      dataIndex: 'vdt_manager',
      render: (prv_manager) => {
        return <Tooltip>{prv_manager ?? '-'}</Tooltip>;
      },
      children: [
        {
          title: (
            <FilterByStaff
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              viewSearch={true}
              visibleSelect={false}
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={columnFiltersFromTableComponent?.vdt}
              columnKey={'vdt'}
              dataList={convertToSelectOptions(
                staffList,
                'item?.full_name || item?.email',
              )}
              onSearch={(e) => {
                if (e) {
                  searchStaff(e);
                } else {
                  getStaffList();
                }
              }}
            />
          ),
          visible: visibleFilters,
          width: 270,
          key: 'list_managers__manager__id',
          dataIndex: 'vdt_manager',
          render: (prv_manager) => {
            return <Tooltip>{prv_manager ?? '-'}</Tooltip>;
          },
        },
      ],
    },
  ];

  const handleTableChange = (pagination, filtersTable, sorter) => {
    setColumnFiltersFromTableComponent(filtersTable);
    const { page, filters } = getFiltersTable(pagination, filtersTable, sorter);
    let newFilters = { ...filters };
    if (tableFilters?.filter?.search) {
      newFilters = {
        ...filters,
        filter: { ...filters.filter, search: tableFilters.filter.search },
      };
    }
    setParams(newFilters);
    handleUpdate(page, newFilters);
  };

  const mainTableWidth = useMemo(() => {
    const copyColumns = [...baseColumns];
    let filteredColumns = filterActiveColumns(copyColumns);
    return filteredColumns.reduce((n, { width }) => n + width, 0);
  }, [baseColumns]);

  const getMainTableWidth = useCallback(() => {
    return mainTableWidth;
  }, [mainTableWidth]);

  useEffect(() => {
    if (mainTableRef.current.children[0]) {
      changeWidthStyleMainTable(mainTableRef, mainTableWidth);
    }
  }, [mainTableWidth]);

  return (
    <>
      <div style={{ position: 'relative' }}>
        <ConfigProvider locale={locale}>
          <div ref={mainTableRef}>
            {!isMobile ? (
              <Table
                rowClassName={(record, index) =>
                  index % 2 === 0
                    ? 'black-text table-row-color'
                    : 'black-text table-row-white'
                }
                locale={{
                  triggerDesc: 'По убыванию',
                  triggerAsc: 'По возрастанию',
                  cancelSort: 'Сбросить сортировку',
                }}
                onRow={(record) => {
                  return {
                    onDoubleClick: () => {
                      setCurrEditApplication(record);
                      setVisibleEditApplicationModal(true);
                    },
                  };
                }}
                showHeader
                page={page}
                scroll={{ x: getMainTableWidth() }}
                sticky={true}
                loading={loading}
                dataSource={applications}
                bordered={true}
                columns={filteringChildrenPillarByVisible(baseColumns)}
                onChange={(val) => {
                  handleTableChange(
                    { current: val?.current },
                    columnFiltersFromTableComponent,
                  );
                }}
                components={{
                  header: {
                    cell: ResizableTableColumnHeader,
                  },
                }}
                size="small"
                pagination={setPaginationSettings(totalRecords, page, [
                  'bottomRight',
                ])}
              />
            ) : (
              <ErrorBoundary>
                <ScrollingLoadedList
                  dataList={applications}
                  totalData={totalRecords}
                  loadMoreItems={async () => {
                    await handleUpdate(page + 1, paramsRef.current, true);
                  }}
                  scrollingLoadedListRef={scrollingLoadedListRef}
                  renderListItem={(item) => (
                    <TaskListItem
                      task={item}
                      menuActions={(task) => menu(task)}
                      getStatusStr={getStatusFromServer}
                    />
                  )}
                />
              </ErrorBoundary>
            )}
          </div>
        </ConfigProvider>
      </div>

      <ErrorBoundary>
        <UniversalFiltersModal
          visibleModal={visibleFilters && isMobile}
          handleClose={() => {
            setVisibleFilters(false);
          }}
          resetFilters={async () => {
            setVisibleFilters(false);
            isMobile && handleScrollToTopMobileList();
            await handleUpdate(1, null, false, false);
          }}
          columnsList={columns}
        />
      </ErrorBoundary>

      <TaskModal
        choices={choices}
        visible={visibleEditApplicationModal}
        handleUpdate={() => {
          isMobile && handleScrollToTopMobileList();
          handleUpdate &&
            handleUpdate(isMobile ? 1 : page, paramsRef.current, false);
        }}
        updateData={(data) => setCurrEditApplication(data)}
        handleOk={() => setVisibleEditApplicationModal(false)}
        handleCancel={() => setVisibleEditApplicationModal(false)}
        loading={false}
        data={modalTask || currEditApplication}
      />

      {/*history task modal*/}
      <Modal
        width={1000}
        title={`История заявки ${currEditApplication?.id}`}
        visible={visibleTaskHistoryModal}
        onCancel={() => {
          setVisibleTaskHistoryModal(false);
          setPageHistoryTable(1);
          setTaskHistory(null);
        }}
        footer={[
          <Button
            key={'close'}
            onClick={() => {
              setVisibleTaskHistoryModal(false);
              setTaskHistory(null);
            }}
          >
            Закрыть
          </Button>,
        ]}
      >
        <Row gutter={16}>
          <Col lg={24}>
            <TaskHistoryTable
              getHistory={async (id, page) => {
                setPageHistoryTable(page);
                await getTaskHistory(id, page);
              }}
              loading={loadingTaskHistory}
              data={taskHistory}
              total={taskHistoryCount}
              task={currEditApplication?.id || null}
              page={pageHistoryTable}
            />
          </Col>
        </Row>
      </Modal>
      <ErrorBoundary>
        <SettingTableColumnsDrawer
          visible={visibleTableSetting}
          loading={false}
          columns={baseColumns}
          onCloseDrawer={async () => {
            onCloseDrawerTableSetting();
            await updateUserInfo(userInfo);
          }}
        />
      </ErrorBoundary>
    </>
  );
};

export default TaskTable;
