import React, { useContext, useState } from 'react';
import { Button, Col, Form, message, Modal, Row, Select } from 'antd';
import { restApiHost } from '../../utils/appVariables';
import { userInformations } from '../../contex';
import { call } from '../../apiUtils/call';

const { Option, OptGroup } = Select;

const ChainWithUserModal = ({
  visible,
  handleChangeVisible,
  user,
  loadingStaffList,
  staff,
  searchStaff,
  getStaffList,
}) => {
  const [mainForm] = Form.useForm();
  const [currUser, setCurrUser] = useState(null);
  const { userInfo } = useContext(userInformations);

  const chainWithCrm = async (id, body) => {
    try {
      const url = `${restApiHost}/accounts/staff_users/${id}/`;
      const json = await call(
        url,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      message.success('Абонент успешно привязан к сотруднику');
      handleChangeVisible(false);
    } catch (e) {
      console.log('chain telephony with crm err', e);
      message.error('Ошибка запроса!');
    }
  };

  const getPositionUser = (position) => {
    switch (position) {
      case 'dir':
        return 'Директор';
      case 'led':
        return 'Руководитель ОП';
      case 'man':
        return 'Менеджер';
      default:
        return 'Не определен';
    }
  };

  return (
    <Modal
      visible={visible}
      title={`Связать абонента ${user?.firstName} ${user?.lastname} с CRM`}
      onOk={() => {
        handleChangeVisible(false);
      }}
      onCancel={() => {
        handleChangeVisible(false);
      }}
      footer={[
        <Button
          key="back"
          onClick={() => {
            handleChangeVisible(false);
          }}
        >
          Закрыть
        </Button>,
        <Button
          disabled={!currUser}
          key="submit"
          type="primary"
          onClick={() => {
            // console.log(currUser, user.userId)
            currUser &&
              currUser.id &&
              chainWithCrm(currUser.id, { abonent: user.userId });
          }}
        >
          Связать
        </Button>,
      ]}
    >
      <Form form={mainForm}>
        <Row gutter={16}>
          <Col lg={24}>
            <Form.Item label="Ответственный">
              <Select
                showSearch
                placeholder="Ответственный"
                optionFilterProp="children"
                loading={loadingStaffList}
                onChange={(value) => {
                  setCurrUser(JSON.parse(value));
                }}
                onInput={(e) => {
                  if (e.target.value.length > 0) {
                    searchStaff(e.target.value);
                  } else {
                    getStaffList();
                  }
                }}
              >
                {staff && staff.length > 0 ? (
                  <>
                    {staff.map((item, idx) => {
                      return (
                        <OptGroup key={idx} label={getPositionUser(item[0])}>
                          {item[1] && item[1].length > 0 ? (
                            <>
                              {item[1].map((user) => {
                                return (
                                  <Option
                                    key={user.id}
                                    value={JSON.stringify(user)}
                                  >
                                    {user.full_name
                                      ? user.full_name
                                      : user.email}
                                  </Option>
                                );
                              })}
                            </>
                          ) : (
                            <Option disabled value={'Загрузка...'}>
                              Загрузка...
                            </Option>
                          )}
                        </OptGroup>
                      );
                    })}
                  </>
                ) : (
                  <OptGroup label={'Поиск сотрудников'}>Загрузка...</OptGroup>
                )}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ChainWithUserModal;
