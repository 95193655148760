import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import ColumnContainer from './ColumnContainer';
import ColumnsItem from './ColumnsItem';
import { useEffect, useState } from 'react';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import PropTypes from 'prop-types';

const baseColumns = [
  {
    id: 'additional',
    name: 'Дополнительные поля',
    tableStyle: false,
  },
  {
    id: 'active',
    name: 'Активные поля',
    tableStyle: true,
  },
];

const DragAndDropTableColumns = (props) => {
  const [activeColumn, setActiveColumn] = useState(null);
  const [columns] = useState(baseColumns);
  const [columnsNodes, setColumnsNodes] = useState([]);
  //const [activeColumnIndexAndColumn, setActiveColumnIndexAndColumn] = useState(null)

  useEffect(() => {
    if (props.columns) {
      setColumnsNodes(props.columns);
    }
  }, [props.columns]);

  useEffect(() => {
    props.setChangedColumns(columnsNodes);
  }, [columnsNodes]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const onDragStart = (event) => {
    if (event.active.data?.current?.type === 'column') {
      setActiveColumn(event.active.data?.current?.column);
      /* const index = columnsNodes.findIndex((t) => t?.index === event.active.data?.current?.column.index)
            setActiveColumnIndexAndColumn({
                index,
                column: event.active.data?.current?.column?.columnId
            })*/
    }
  };

  const onDragOver = (event) => {
    const { active, over } = event;
    if (!over) return;

    const activeId = active.id;
    const overId = over.id;

    if (activeId === overId) return;

    const isActiveATask = active.data.current?.type === 'column';
    const isOverATask = over.data.current?.type === 'column';

    if (!isActiveATask) return;

    if (isActiveATask && isOverATask) {
      setColumnsNodes((col) => {
        const activeIndex = col.findIndex((t) => t.index === activeId);
        const overIndex = col.findIndex((t) => t.index === overId);

        if (col[activeIndex]?.columnId !== col[overIndex]?.columnId) {
          col[activeIndex].columnId = col[overIndex]?.columnId;
          return arrayMove(col, activeIndex, overIndex - 1);
        }

        return arrayMove(col, activeIndex, overIndex);
      });
    }

    const isOverAColumn = over.data.current?.type === 'area';

    if (isActiveATask && isOverAColumn) {
      setColumnsNodes((col) => {
        const activeIndex = col.findIndex((t) => t?.index === activeId);

        col[activeIndex].columnId = overId;

        return arrayMove(col, activeIndex, activeIndex);
      });
    }
  };

  const onDragEnd = () => {
    /* let isChanged = false;
        const activeIndex = columnsNodes.findIndex((t) => t?.index === activeColumn.index)
        if ((activeIndex !== activeColumnIndexAndColumn?.index && activeColumn?.columnId === 'active')
            || (activeColumn?.columnId !== activeColumnIndexAndColumn?.column)) {
            isChanged = true
        }
        if (isChanged) {
            props.editTableColumns(activeColumn.idInDatabase,activeIndex, activeColumn?.columnId === 'active')
        }
        setActiveColumnIndexAndColumn(null)*/
    setActiveColumn(null);
  };

  return (
    <div>
      <DndContext
        //collisionDetection={closestCorners}
        sensors={sensors}
        onDragStart={(event) => onDragStart(event)}
        onDragEnd={(event) => onDragEnd(event)}
        onDragOver={(event) => onDragOver(event)}
      >
        {columns.map((col, index) => {
          return (
            <ColumnContainer
              key={index}
              column={col}
              columnNodes={columnsNodes.filter(
                (item) => item.columnId === col.id,
              )}
            />
          );
        })}

        <DragOverlay adjustScale={false}>
          {activeColumn ? (
            <ColumnsItem column={activeColumn} key={activeColumn?.id} />
          ) : (
            <></>
          )}
        </DragOverlay>
      </DndContext>
    </div>
  );
};

DragAndDropTableColumns.props = {
  columns: PropTypes.any,
  setChangedColumns: PropTypes.any,
  editTableColumns: PropTypes.func,
};

export default DragAndDropTableColumns;
