import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  changeGroupPermissions,
  getPermissionsBySectionId,
} from '../../../../../../../logic/permissions/PermissionsApiFunctions';
import { Checkbox, ConfigProvider, Input, Table } from 'antd';
import { userInformations } from '../../../../../../../contex';
import { Card, Box, CardContent } from '@material-ui/core';
import locale from 'antd/lib/locale/ru_RU';
import useCheckMobileScreen from '../../../../../../../hooks/useCheckMobileScreen';
import { ErrorBoundary } from '../../../../../../ErrorBoundary';
import ScrollingLoadedList from '../../../../../../mobile/scrollingLoadedList/ScrollingLoadedList';
import PermissionsListItem from '../../../../../../mobile/staff/list/PermissionsListItem';
const { Search } = Input;

const TableEditPermissions = (props) => {
  const [loading, setLoading] = useState(false);
  const [columnsTable, setColumnsTable] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [searchPermissionValue, setSearchPermissionValue] = useState('');
  const [page, setPage] = useState(1);
  const { userInfo } = useContext(userInformations);
  const isMobile = useCheckMobileScreen();

  useEffect(async () => {
    if (props.currentSection) {
      await formationColumnsAndDataTablePermission();
    }
  }, [props.currentSection, props.selectedGroups]);

  const formationColumnsAndDataTablePermission = async (searchKeyword) => {
    const { columns, data } = await synchronizationPermissionsBelongedToGroups(
      props.currentSection,
      props.selectedGroups,
      searchKeyword,
    );
    setColumnsTable(columns);
    setTableData(data);
  };
  const synchronizationPermissionsBelongedToGroups = async (
    section,
    selectedGroups,
    keyword,
  ) => {
    setLoading(true);
    const mapIdsPermissionsSections = new Map();
    const arrIdsPermissionsSectionsForFilters = [];
    let columnsTable = [
      {
        title: 'Название права',
        width: 250,
        key: 'name',
        dataIndex: 'name',
        align: 'center',
      },
    ];

    const permissionsSections = await getPermissionsBySectionId(
      section?.section_id,
      keyword,
      userInfo,
    );

    for (const permission of permissionsSections) {
      if (permission?.id) {
        mapIdsPermissionsSections.set(permission.id, permission);
        arrIdsPermissionsSectionsForFilters.push(permission.id);
      }
    }

    for (const group of selectedGroups) {
      if (group?.permissions) {
        const groupPermissions = group?.permissions.filter((item) =>
          arrIdsPermissionsSectionsForFilters.includes(item?.id),
        );
        for (const groupPermission of groupPermissions) {
          if (mapIdsPermissionsSections.has(groupPermission?.id)) {
            const itemInMap = mapIdsPermissionsSections.get(
              groupPermission?.id,
            );
            mapIdsPermissionsSections.delete(groupPermission?.id);
            const newObject = {
              ...itemInMap,
            };
            newObject[group?.id] = true;
            mapIdsPermissionsSections.set(groupPermission?.id, newObject);
          }
        }
        columnsTable.push({
          title: group?.name,
          width: 100,
          key: group?.name,
          align: 'center',
          render: (data) => {
            const groupId = group?.id;
            return data[groupId] && JSON.parse(data[groupId]) ? (
              <Checkbox
                checked={true}
                onChange={async (e) => {
                  await multipleChangePermission(data?.id, e.target.checked);
                }}
              />
            ) : (
              <Checkbox
                checked={false}
                onChange={async (e) => {
                  await multipleChangePermission(data?.id, e.target.checked);
                }}
              />
            );
          },
        });
      }
    }

    const resultPermissionsSections = [];
    mapIdsPermissionsSections.forEach((value) => {
      resultPermissionsSections.push({
        ...value,
      });
    });

    setLoading(false);

    return {
      columns: columnsTable,
      data: resultPermissionsSections,
    };
  };

  const multipleChangePermission = async (permissionsId, action) => {
    const groupIds = props.selectedGroups?.map((group) => {
      return group?.id;
    });

    const body = {
      groups: groupIds,
      permissions: [permissionsId],
      action: action ? 'add' : 'delete',
    };
    const res = await changeGroupPermissions(body, userInfo);

    if (res) {
      props.handleUpdateGroupList();
    }
  };

  const handleSearch = async (keyword) => {
    setPage(1);
    await formationColumnsAndDataTablePermission(keyword ? keyword : null);
  };

  return (
    <>
      <Box sx={{ mt: 3 }}>
        <Card className={'primary-bg-color'}>
          <CardContent>
            <Box style={{ display: 'flex' }}>
              <Search
                disabled={loading}
                value={searchPermissionValue}
                placeholder="Поиск права"
                allowClear
                enterButton
                onSearch={async (value) => await handleSearch(value)}
                onChange={(e) => {
                  setSearchPermissionValue(e.target.value);
                }}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Box sx={{ pt: 3 }}>
        {!isMobile ? (
          <ConfigProvider locale={locale}>
            <Table
              className={'edit-permissions-table'}
              rowClassName={(record, index) =>
                index % 2 === 0
                  ? 'black-text table-row-color'
                  : 'black-text table-row-white'
              }
              sticky={true}
              dataSource={tableData}
              columns={columnsTable}
              size="small"
              bordered={true}
              loading={loading}
              onChange={(value) => {
                setPage(value?.current ? value.current : 1);
              }}
              rowKey={(record) => record.id}
              pagination={{
                current: page,
                total: tableData?.length || 0,
                pageSizeOptions: [10, 20, 50],
              }}
            />
          </ConfigProvider>
        ) : (
          <>
            <ErrorBoundary>
              <ScrollingLoadedList
                dataList={tableData}
                totalData={tableData?.length}
                loadMoreItems={async () => {
                  //await handleUpdate(page + 1, params, false,true)
                }}
                renderListItem={(item) => (
                  <PermissionsListItem
                    permission={item}
                    fields={columnsTable}
                  />
                )}
              />
            </ErrorBoundary>
          </>
        )}
      </Box>
    </>
  );
};

TableEditPermissions.propTypes = {
  currentSection: PropTypes.object.isRequired,
  selectedGroups: PropTypes.arrayOf(PropTypes.object),
  handleUpdateGroupList: PropTypes.func.isRequired,
};

export default TableEditPermissions;
