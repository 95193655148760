import PropTypes from 'prop-types';
import { Col, Form, Row, Select } from 'antd';
import React, { useCallback, useContext, useState } from 'react';
import useCheckMobileScreen from '../../../../../../../../hooks/useCheckMobileScreen';

import { searchStaffList } from '../../../../../../../../logic/staff/StaffApiFunctions';
import { userInformations } from '../../../../../../../../contex';
import useAsyncEffect from 'use-async-effect';

const { Option, OptGroup } = Select;

const ManagerModalForm = (props) => {
  const isMobile = useCheckMobileScreen();
  const { userInfo } = useContext(userInformations);
  const [loadingManagersList, setLoadingManagersList] = useState(false);
  const [managerListOptions, setManagerListOptions] = useState([]);

  useAsyncEffect(async () => {
    await handleGetManagersList();
  }, []);

  useAsyncEffect(async () => {
    if (props.editingManager?.manager?.full_name) {
      await handleGetManagersList(props.editingManager?.manager?.full_name);
    }
  }, [props.editingManager]);

  const handleGetManagersList = useCallback(
    async (keyword = null) => {
      try {
        setLoadingManagersList(true);
        const res = await searchStaffList(keyword, userInfo);
        if (res?.data) {
          setManagerListOptions(res.data);
        }
      } finally {
        setLoadingManagersList(false);
      }
    },
    [setManagerListOptions],
  );

  return (
    <>
      <Form form={props.form} layout={'vertical'}>
        <Row gutter={16}>
          <Col xs={24} lg={12}>
            <Form.Item
              name={'group'}
              label={'Группа'}
              rules={[
                {
                  required: true,
                  message: 'Обязательно для заполнения!',
                },
              ]}
            >
              <Select
                getPopupContainer={(trigger) =>
                  isMobile ? trigger.parentNode : document.body
                }
                placeholder={'Выберите группу'}
              >
                <Option value={'vdt'}>Ведет</Option>
                <Option value={'otv'}>Ответственный</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              name={'manager'}
              label={'Менеджер'}
              rules={[
                {
                  required: true,
                  message: 'Обязательно для заполнения!',
                },
              ]}
            >
              <Select
                getPopupContainer={(trigger) =>
                  isMobile ? trigger.parentNode : document.body
                }
                showSearch
                placeholder="Менеджер"
                optionFilterProp="children"
                loading={loadingManagersList}
                onInput={async (e) => {
                  await handleGetManagersList(e.target.value);
                }}
              >
                {managerListOptions && managerListOptions?.length > 0 ? (
                  <>
                    {Array.isArray(managerListOptions) &&
                      managerListOptions.map((item, index) => {
                        return (
                          <Option key={index} value={item.id}>
                            {item.full_name || item.email}
                          </Option>
                        );
                      })}
                  </>
                ) : (
                  <OptGroup label={'Поиск сотрудников'}>
                    Начните поиск...
                  </OptGroup>
                )}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default ManagerModalForm;

ManagerModalForm.propTypes = {
  form: PropTypes.any.isRequired,
  editingManager: PropTypes.object,
  visibleModal: PropTypes.bool,
};
