import { Tabs } from 'antd';
import useStateRef from 'react-usestateref';
import { useCallback } from 'react';

import './styles/TaskPageTabs.css';
import TasksTab from './components/TasksTab';
import BillsTab from './components/BillsTab';
import PaymentsTab from './components/PaymentsTab';

const { TabPane } = Tabs;

const TaskPageTabs = () => {
  const [, setCurrentTab, currentTabRef] = useStateRef(1);

  const handleTabChange = useCallback((key) => {
    setCurrentTab(Number(key));
  }, []);

  return (
    <Tabs
      destroyInactiveTabPane={true}
      className={'task-page-tabs'}
      centered={false}
      activeKey={currentTabRef.current.toString()}
      onChange={handleTabChange}
    >
      <TabPane tab="Заявки" key="1">
        <TasksTab />
      </TabPane>
      <TabPane tab="Счета" key="2">
        <BillsTab />
      </TabPane>
      <TabPane tab="Платежи" key="3">
        <PaymentsTab />
      </TabPane>
    </Tabs>
  );
};

export default TaskPageTabs;
