import { Virtuoso } from 'react-virtuoso';
import React, { useCallback } from 'react';
import NotificationListItem from './NotificationListItem';
import PropTypes from 'prop-types';
import ChatNotificationListItem from './chatNotification/ChatNotificationListItem';

const ScrollingLoadedNotification = (props) => {
  const itemContent = useCallback(
    (index, rowData) => (
      <div style={{ marginRight: '5px' }}>
        {props.useForChatNotification ? (
          <ChatNotificationListItem
            notification={rowData}
            redirectToCalendar={(eventId) => props.redirectToCalendar(eventId)}
            handleViewNotifications={(notificationId) =>
              props.handleViewNotifications(notificationId)
            }
          />
        ) : (
          <NotificationListItem
            notification={rowData}
            redirectToCalendar={(eventId) => props.redirectToCalendar(eventId)}
            handleViewNotifications={(notificationId) =>
              props.handleViewNotifications(notificationId)
            }
          />
        )}
      </div>
    ),
    [],
  );

  return (
    <>
      <div
        style={{
          display: 'flex',
          height: '100%',
          width: '100%',
          flexFlow: 'column',
          overflow: 'auto',
        }}
      >
        <Virtuoso
          className={'notification-drawer-list'}
          //ref={props.virtuosoRef}
          //initialTopMostItemIndex={internalMessages.length - 1}
          //firstItemIndex={Math.max(0, firstItemIndex)}
          itemContent={itemContent}
          data={props.notificationList}
          //startReached={() => console.log('startReached')}
          //startReached={() => props.loadMoreMessages()}
          endReached={() => props.loadMoreNotification()}
          followOutput={'auto'}
          style={{
            display: 'flex',
            flex: '1 1 auto',
            overscrollBehavior: 'contain',
          }}
        />
      </div>
    </>
  );
};

ScrollingLoadedNotification.propTypes = {
  notificationList: PropTypes.arrayOf(PropTypes.object).isRequired,
  useForChatNotification: PropTypes.bool,
  loadMoreNotification: PropTypes.func.isRequired,
  redirectToCalendar: PropTypes.func.isRequired,
  handleViewNotifications: PropTypes.func.isRequired,
};

export default ScrollingLoadedNotification;
