import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Tabs,
} from 'antd';
import { restApiHost } from '../../utils/appVariables';
import ContactsLeed from '../leeds/ContactsLeed';
import moment from 'moment';
import PhoneInput from 'react-phone-input-2';
import ru from 'react-phone-input-2/lang/ru.json';
import { userInformations } from '../../contex';
const { Option } = Select;
const { TabPane } = Tabs;
import { call } from '../../apiUtils/call';
import getCallResult from '../../logic/refbook/callResult/CallResultApiFunction';

const AddLeadModal = ({
  cities,
  searchCities,
  callProps,
  redirect,
  openModal,
  setOpenModal,
  handleUpdate,
  choices,
}) => {
  const [mainForm] = Form.useForm();
  const [closeForm] = Form.useForm();
  const [disableSaveBtn, setDisableSaveBtn] = useState(true);
  const [messengerOtherSelected, setMessengerOtherSelected] = useState(false);
  const [sourceOtherSelected, setSourceOtherSelected] = useState(false);
  const [editLead, setEditLead] = useState(false);
  const [openCloseModal, setOpenCloseModal] = useState(false);
  const [disableCloseBtn, setDisableCloseBtn] = useState(true);
  const [contacts, setContacts] = useState([]);
  const [contactTab] = useState('1');
  const [currentLead, setCurrentLead] = useState(null);
  const [isArchive, setIsArchive] = useState(false);
  const [callResultOptions, setCallResultOptions] = useState([]);

  const [countryCode, setCountryCode] = useState('ru');
  const phoneNumberRef = useRef(null);
  const { userInfo } = useContext(userInformations);

  const handleChangePhone = (value, data, event) => {
    if (event.type === 'click') {
      setCountryCode(data.countryCode);
      phoneNumberRef?.current?.numberInputRef?.focus();
      setCountryCode('ru');
    }
  };

  useEffect(() => {
    callGetCallResult();
  }, []);

  useEffect(() => {
    if (openModal && callProps) {
      if (callProps.lead) {
        setEditLead(true);
        getLeadInfo(callProps.lead.id);
      } else {
        mainForm.setFieldsValue({
          call_result: callProps?.call_result?.id
            ? +callProps.call_result.id
            : null,
          phone_number: callProps.phone_number,
          date_recent_request: callProps?.start_time.split(' ')[0] || null,
          comment: callProps.call_duration
            ? `${
                callProps.call_direction == 'Originator'
                  ? 'Исходящий звонок'
                  : 'Входящий звонок'
              }, время вызова ${callProps.call_duration} сек.`
            : 'Не ответили на звонок',
        });
      }
    }
  }, [openModal, callProps]);

  const archiveLead = async (id) => {
    closeForm.validateFields().then((values) => {
      editLeadreq(id, values).then(() => {
        closeForm.resetFields();
        setOpenCloseModal(false);
        redirect && redirect(currentLead);
        setCurrentLead(null);
      });
    });
  };

  const getLeadInfo = async (id) => {
    try {
      const url = `${restApiHost}/leads/list/${id}/`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );
      if (!json) return;
      json.source_other
        ? setSourceOtherSelected(true)
        : setSourceOtherSelected(false);
      json.messenger_other
        ? setMessengerOtherSelected(true)
        : setMessengerOtherSelected(false);
      mainForm.setFieldsValue({
        ...json,
        manager: json.manager?.full_name || ',',
      });
      setContacts(json.contact_persons);
    } catch (e) {
      console.log('get lead info err', e);
      message.error('Ошибка получения информации по лиду');
    }
  };

  const editLeadreq = async (id, data) => {
    try {
      const url = `${restApiHost}/leads/list/${id}/`;
      const json = await call(
        url,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-type': 'application/json',
          },
          body: JSON.stringify(data),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      await handleUpdate();
      setOpenModal(false);
      mainForm.resetFields();
      setDisableSaveBtn(true);
      message.success('Успешно отредактировано!');
    } catch (e) {
      console.log('lead delete error', e);
      message.error('Ошибка редактирования лида');
    }
  };

  const callGetCallResult = async () => {
    const res = await getCallResult('lead', userInfo);
    if (res) {
      setCallResultOptions(res);
    }
  };

  const addNewLead = async (data) => {
    try {
      const url = `${restApiHost}/leads/list/`;
      const json = await call(
        url,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-type': 'application/json',
          },
          body: JSON.stringify(data),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      message.success('Успешно добавлено!');
      await handleUpdate();
      setOpenModal(false);
      mainForm.resetFields();
      setDisableSaveBtn(true);
      setCurrentLead(json?.lead);
      redirect && redirect(json?.lead);
    } catch (e) {
      console.log('add error new lead', e);
      message.error('Ошибка добавления лида');
    }
  };

  const handleFormSubmit = () => {
    if (editLead) {
      mainForm.validateFields().then(async (values) => {
        const formattedData = {
          ...values,
          messenger_other: messengerOtherSelected
            ? values.messenger_other
            : null,
          source_other: sourceOtherSelected ? values.source_other : null,
          date_recent_request: values.date_recent_request || null,
          date_next_request: values.date_next_request || null,
          contact_persons: contacts,
        };
        delete formattedData.manager;

        if (callProps?.lead?.id) {
          await editLeadreq(callProps.lead.id, formattedData);
        } else {
          message.error('Непредвиденная ошибка');
        }
      });
    } else {
      mainForm
        .validateFields()
        .then(async (values) => {
          let body = {
            ...values,
            color: values.color == 'none' ? null : values.color,
            manager: callProps?.abonent?.id || null,
            messenger_other: messengerOtherSelected
              ? values.messenger_other
              : null,
            source_other: sourceOtherSelected ? values.source_other : null,
            date_recent_request: values.date_recent_request || null,
            date_next_request: values.date_next_request || null,
            contact_persons: contacts,
          };
          await addNewLead(body);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  return (
    <>
      <Modal
        visible={openModal}
        width={1000}
        title={editLead ? 'Редактировать лида' : 'Добавить лида'}
        onCancel={() => {
          setOpenModal(false);
          editLead && mainForm.resetFields();
          setDisableSaveBtn(true);
          setEditLead(false);
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setOpenModal(false);
              editLead && mainForm.resetFields();
              setDisableSaveBtn(true);
              setEditLead(false);
            }}
          >
            Закрыть
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleFormSubmit}
            disabled={disableSaveBtn}
          >
            {editLead ? 'Сохранить' : 'Добавить'}
          </Button>,
        ]}
      >
        <Tabs
          defaultActiveKey={contactTab}
          onChange={(val) => setContacts(val)}
        >
          <TabPane tab="Основная информация" key="1">
            <Form
              form={mainForm}
              onValuesChange={() => {
                setTimeout(() => {
                  mainForm
                    .validateFields()
                    .then((values) => {
                      const index = contacts.findIndex(
                        (item) => item.ghostId === 'mainContact',
                      );
                      if (index >= 0) {
                        const copyContacts = [...contacts];
                        copyContacts[index] = {
                          ...copyContacts[index],
                          full_name: values.contact_person,
                          phone_number: values.phone_number,
                          email: values.email,
                        };
                        setContacts(copyContacts);
                      } else {
                        setContacts([
                          {
                            full_name: values.contact_person,
                            phone_number: values.phone_number,
                            email: values.email,
                            ghostId: 'mainContact',
                          },
                          ...contacts,
                        ]);
                      }
                      setDisableSaveBtn(false);
                    })
                    .catch(() => {
                      setDisableSaveBtn(true);
                    });
                }, 0);
              }}
              layout="vertical"
            >
              <Row gutter={16}>
                <Col xs={24} lg={4}>
                  <Form.Item label={'Цвет'} name={'color'}>
                    <Select placeholder={'Выберите цвет'}>
                      {choices && choices.color ? (
                        <>
                          {Object.entries(choices.color).map((item) => {
                            return (
                              <Option
                                key={item[0]}
                                style={{ background: `rgb(${item[1]})` }}
                                value={item[0]}
                              >
                                <div
                                  style={{
                                    color: '#fff',
                                    background: `rgb(${item[1]})`,
                                  }}
                                >
                                  {item[0]}
                                </div>
                              </Option>
                            );
                          })}
                          <Option value={'none'}>
                            <div>Без цвета</div>
                          </Option>
                        </>
                      ) : (
                        <Option disabled value={null}>
                          Нет доступных вариантов
                        </Option>
                      )}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} lg={4}>
                  <Form.Item label={'ABC'} name={'abc'}>
                    <Select placeholder={'Выберите классификацию ABC'}>
                      {choices && choices.abc ? (
                        <>
                          {Object.entries(choices.abc).map((item) => {
                            return (
                              <Option key={item[0]} value={item[0]}>
                                {item[1]}
                              </Option>
                            );
                          })}
                        </>
                      ) : (
                        <Option disabled value={null}>
                          Нет доступных вариантов
                        </Option>
                      )}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} lg={4}>
                  <Form.Item label={'XYZ'} name={'xyz'}>
                    <Select placeholder={'Выберите классификацию XYZ'}>
                      {choices && choices.xyz ? (
                        <>
                          {Object.entries(choices.xyz).map((item) => {
                            return (
                              <Option key={item[0]} value={item[0]}>
                                {item[1]}
                              </Option>
                            );
                          })}
                        </>
                      ) : (
                        <Option disabled value={null}>
                          Нет доступных вариантов
                        </Option>
                      )}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} lg={6}>
                  <Form.Item label="Компания" name={'company_name'}>
                    <Input placeholder="Введите наименование компании" />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={6}>
                  <Form.Item label="ОКПО" name={'okpo'}>
                    <Input placeholder="Введите ОКПО" />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={6} md={6}>
                  <Form.Item
                    label="ФИО (контактное лицо)"
                    name={'contact_person'}
                    rules={[{ required: true, message: 'Обязательное поле!' }]}
                  >
                    <Input placeholder="Введите ФИО" />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={6} md={6}>
                  <Form.Item label="Email" name={'email'}>
                    <Input placeholder="Введите email" />
                  </Form.Item>
                </Col>
                <Col lg={4} md={4} xs={24}>
                  <Form.Item label={'Доб. номер'} name={'add_tel_number'}>
                    <Input placeholder={'Введите добавочный номер'} />
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  lg={8}
                  md={8}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <Form.Item
                    label="Телефон (контактное лицо)"
                    name={'phone_number'}
                    rules={[{ required: true, message: 'Обязательное поле!' }]}
                  >
                    <PhoneInput
                      ref={phoneNumberRef}
                      localization={ru}
                      value={countryCode}
                      country={'ru'}
                      onlyCountries={[
                        'ru',
                        'us',
                        'fr',
                        'by',
                        'az',
                        'ge',
                        'ua',
                        'kz',
                        'am',
                        'kg',
                        'md',
                        'tr',
                        'uz',
                        'tj',
                      ]}
                      onChange={handleChangePhone}
                    />

                    {/*{*/}
                    {/*    formatNumber ? (*/}
                    {/*        <MaskedInput*/}
                    {/*            style={{ width: 'auto', marginRight: 10 }}*/}
                    {/*            mask="+7 111 111 1111"*/}
                    {/*            name="phone_numbers"*/}
                    {/*            placeholder="+7 111 111 1111"*/}
                    {/*        />*/}

                    {/*    ) : (*/}
                    {/*        <Input*/}
                    {/*            style={{ width: 'auto', marginRight: 10 }}*/}
                    {/*            placeholder="Введите номер телефона"*/}
                    {/*        />*/}
                    {/*    )*/}
                    {/*}*/}
                  </Form.Item>
                  {/*<Switch*/}
                  {/*    checked={formatNumber}*/}
                  {/*    onChange={(value) => setFormatNumber(value)}*/}
                  {/*    checkedChildren="+7"*/}
                  {/*    unCheckedChildren="Своб. формат"*/}
                  {/*    defaultChecked*/}
                  {/*/>*/}
                </Col>
                <Col
                  xs={24}
                  lg={messengerOtherSelected && sourceOtherSelected ? 4 : 6}
                >
                  <Form.Item label={'Город'} name={'city'}>
                    <Select
                      optionFilterProp="children"
                      showSearch
                      placeholder={'Выберите город'}
                      onSearch={searchCities}
                    >
                      {cities?.length > 0 ? (
                        <>
                          {cities.map((item) => {
                            return (
                              <Option key={item.id} value={item.id}>
                                {item.name}
                              </Option>
                            );
                          })}
                        </>
                      ) : (
                        <Option disabled value={null}>
                          Нет доступных вариантов
                        </Option>
                      )}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  lg={
                    messengerOtherSelected && sourceOtherSelected
                      ? 5
                      : messengerOtherSelected || sourceOtherSelected
                      ? 6
                      : 9
                  }
                >
                  <Form.Item label={'Мессенджер'} name={'messenger'}>
                    <Select
                      onChange={(value) => {
                        value === 'oth'
                          ? setMessengerOtherSelected(true)
                          : setMessengerOtherSelected(false);
                      }}
                      placeholder={'Выберите статус'}
                    >
                      {choices && choices.messenger ? (
                        <>
                          {Object.entries(choices.messenger).map((item) => {
                            return (
                              <Option key={item[0]} value={item[0]}>
                                {item[1]}
                              </Option>
                            );
                          })}
                        </>
                      ) : (
                        <Option disabled value={null}>
                          Нет доступных вариантов
                        </Option>
                      )}
                    </Select>
                  </Form.Item>
                </Col>
                {messengerOtherSelected && (
                  <Col
                    xs={24}
                    lg={
                      messengerOtherSelected && sourceOtherSelected
                        ? 5
                        : messengerOtherSelected || sourceOtherSelected
                        ? 6
                        : 9
                    }
                  >
                    <Form.Item
                      name={'messenger_other'}
                      label={'Др. мессенджер'}
                    >
                      <Input placeholder={'Введите свой мессенджер'} />
                    </Form.Item>
                  </Col>
                )}
                <Col
                  xs={24}
                  lg={
                    messengerOtherSelected && sourceOtherSelected
                      ? 5
                      : messengerOtherSelected || sourceOtherSelected
                      ? 6
                      : 9
                  }
                >
                  <Form.Item label="Источник" name={'source_select'}>
                    <Select
                      onChange={(value) => {
                        value === 'oth'
                          ? setSourceOtherSelected(true)
                          : setSourceOtherSelected(false);
                      }}
                      placeholder={'Выберите источник'}
                    >
                      {choices && choices.source ? (
                        <>
                          {Object.entries(choices.source).map((item) => {
                            return (
                              <Option key={item[0]} value={item[0]}>
                                {item[1]}
                              </Option>
                            );
                          })}
                        </>
                      ) : (
                        <Option disabled value={null}>
                          Нет доступных вариантов
                        </Option>
                      )}
                    </Select>
                  </Form.Item>
                </Col>
                {sourceOtherSelected && (
                  <Col
                    xs={24}
                    lg={
                      messengerOtherSelected && sourceOtherSelected
                        ? 5
                        : messengerOtherSelected || sourceOtherSelected
                        ? 6
                        : 9
                    }
                  >
                    <Form.Item name={'source_other'} label={'Др. источник'}>
                      <Input placeholder={'Введите свой источник'} />
                    </Form.Item>
                  </Col>
                )}
                <Col lg={12} xs={24}>
                  <Row gutter={16}>
                    <Col xs={24} lg={12}>
                      <Form.Item
                        label="Посл. общение"
                        name={'date_recent_request'}
                      >
                        <input
                          className={'ant-input'}
                          max="9999-12-31"
                          type={'date'}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} lg={12}>
                      <Form.Item
                        label="След. общение"
                        name={'date_next_request'}
                        rules={[
                          { required: true, message: 'Обязательное поле!' },
                        ]}
                      >
                        <input
                          className={'ant-input'}
                          type={'date'}
                          max="9999-12-31"
                          min={moment().format('YYYY-MM-DD')}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col lg={12} md={12} xs={24}>
                      <Form.Item label={'Комментарий по лиду'} name={'comment'}>
                        <Input.TextArea placeholder="Введите комментарий по лиду" />
                      </Form.Item>
                    </Col>
                    <Col lg={12} md={12} xs={24}>
                      <Form.Item
                        label={'Сайт'}
                        name={'website'}
                        rules={[
                          {
                            required: false,
                          },
                          {
                            pattern:
                              /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g,
                            message: 'Введите правильный URL',
                          },
                        ]}
                      >
                        <Input placeholder="Введите адрес вебсайта" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col lg={12} xs={24}>
                  <Row gutter={16}>
                    <Col xs={24} md={12} lg={12}>
                      <Form.Item
                        label="Итог звонка"
                        name={'call_result'}
                        rules={[
                          { required: true, message: 'Обязательное поле!' },
                        ]}
                      >
                        <Select
                          placeholder={'Выберите итог звонка'}
                          onChange={(value) => {
                            value === 2
                              ? setIsArchive(true)
                              : setIsArchive(false);
                          }}
                        >
                          {callResultOptions &&
                          callResultOptions?.length > 0 ? (
                            <>
                              {callResultOptions.map((item) => {
                                return (
                                  <Option key={item?.id} value={item?.id}>
                                    {item?.result}
                                  </Option>
                                );
                              })}
                            </>
                          ) : (
                            <Option disabled value={null}>
                              Нет доступных вариантов
                            </Option>
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12} ld={12}>
                      <Form.Item
                        label="Франчайзи"
                        name={'franchise'}
                        valuePropName={'checked'}
                      >
                        <Checkbox />
                      </Form.Item>
                    </Col>
                  </Row>
                  {isArchive && (
                    <Row gutter={16}>
                      <Col xs={24} md={12} lg={12}>
                        <Form.Item
                          label="Причина страт. архива"
                          name={'strat_arch_reason'}
                          rules={[
                            { required: true, message: 'Обязательное поле!' },
                          ]}
                        >
                          <Select placeholder={'Выберите причину'}>
                            {choices?.strat_arch_reasons ? (
                              <>
                                {Object.entries(choices.strat_arch_reasons).map(
                                  (item) => {
                                    return (
                                      <Option key={item[0]} value={+item[0]}>
                                        {item[1]}
                                      </Option>
                                    );
                                  },
                                )}
                              </>
                            ) : (
                              <Option disabled value={null}>
                                Нет доступных вариантов
                              </Option>
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={12} ld={12}>
                        <Form.Item
                          label="Комментарий (страт. архив)"
                          name={'strat_arch_reason_comment'}
                        >
                          <Input.TextArea placeholder="Введите комментарий (страт. архив)" />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </Form>
          </TabPane>
          <TabPane tab="Контактные лица" key="2">
            <ContactsLeed
              lead={callProps?.lead}
              contacts={contacts}
              onChangeContacts={(data) => setContacts(data)}
            />
          </TabPane>
        </Tabs>
      </Modal>
      {/*descr modal close lead*/}
      <Modal
        visible={openCloseModal}
        title="Причина попадания в страт. архив"
        onCancel={() => {
          setOpenCloseModal(false);
          setCurrentLead(null);
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setOpenCloseModal(false);
              setCurrentLead(null);
            }}
          >
            Закрыть
          </Button>,
          <Button
            key="submit"
            type="primary"
            disabled={disableCloseBtn}
            onClick={async () => {
              await archiveLead(currentLead?.id);
            }}
          >
            Отправить
          </Button>,
        ]}
      >
        <Form
          form={closeForm}
          onChange={() =>
            closeForm
              .validateFields()
              .then(() => setDisableCloseBtn(false))
              .catch(() => setDisableCloseBtn(true))
          }
          layout="vertical"
        >
          <Form.Item
            label="Причина"
            name={'strat_arch_reason'}
            rules={[{ required: true, message: 'Обязательное поле!' }]}
          >
            <Select placeholder={'Выберите причину'}>
              {choices && choices.strat_arch_reasons ? (
                <>
                  {Object.entries(choices.strat_arch_reasons).map((item) => {
                    return (
                      <Option key={item[0]} value={item[0]}>
                        {item[1]}
                      </Option>
                    );
                  })}
                </>
              ) : (
                <Option disabled value={null}>
                  Нет доступных вариантов
                </Option>
              )}
            </Select>
          </Form.Item>
          <Form.Item label="Комментарий" name={'strat_arch_reason_comment'}>
            <Input.TextArea placeholder="Введите подробный комментарий" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddLeadModal;
