import PropTypes from 'prop-types';
import { GroupedVirtuoso } from 'react-virtuoso';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import MessageBox from './MessegesBox';
import { userInformations } from '../../../contex';
import dayjs from 'dayjs';
import TimeStamp from './TimeStamp';
import groupingMessageByDate from './utilsFunctions/groupingMessageByDate';

const MessagesList = (props) => {
  const { userInfo } = useContext(userInformations);
  const [groupCountsMessage, setGroupCountsMessage] = useState([]);
  const [groupTitles, setGroupTitles] = useState([]);
  const [firstItemIndex, setFirstItemIndex] = useState(
    props.totalMessages - props.messagesList?.length >= 0
      ? props.totalMessages - props.messagesList?.length
      : 0,
  );

  useEffect(() => {
    if (props.messagesList?.length > 0) {
      const groupingMessage = groupingMessageByDate(props.messagesList);
      preparedMessages(groupingMessage);
    }
  }, [props.messagesList]);

  const preparedMessages = (messages) => {
    const groupCountItem = [];
    let resMessagesArr = [];
    const groupTitleDates = [];
    for (const groupMessage of messages) {
      if (groupMessage[1]?.length > 0) {
        groupCountItem.push(groupMessage[1].length);
        resMessagesArr = resMessagesArr.concat(groupMessage[1]);
      }
      if (groupMessage[0]) {
        groupTitleDates.push(groupMessage[0]);
      }
    }
    setGroupCountsMessage(groupCountItem);
    setGroupTitles(groupTitleDates);
  };

  const itemContent = (index) => {
    const matchABCIndex = Math.abs(firstItemIndex - index);
    return props.messagesList[matchABCIndex]?.type === 'inf' ? (
      <TimeStamp date={props.messagesList[matchABCIndex]?.text} />
    ) : (
      <MessageBox
        isMine={
          props.messagesList[matchABCIndex]?.creator?.staff_id ==
          userInfo.current?.id
        }
        text={props.messagesList[matchABCIndex]?.text}
        isViewed={props.messagesList[matchABCIndex]?.viewed}
        timestamp={dayjs(props.messagesList[matchABCIndex]?.created).format(
          'HH:mm',
        )}
        userName={props.messagesList[matchABCIndex]?.creator?.full_name}
      />
    );
  };

  const internalMessages = useMemo(() => {
    const nextFirstItemIndex =
      props.totalMessages - props.messagesList?.length >= 0
        ? props.totalMessages - props.messagesList?.length
        : 0;
    setFirstItemIndex(nextFirstItemIndex);
    return props.messagesList;
  }, [props.messagesList]);

  const followOutput = useCallback((isAtBottom) => {
    return isAtBottom ? 'smooth' : false;
  }, []);

  return (
    <>
      <GroupedVirtuoso
        className={'chat-virtuoso-scroller'}
        ref={props.virtuosoRef}
        groupCounts={groupCountsMessage}
        initialTopMostItemIndex={internalMessages.length - 1}
        firstItemIndex={Math.max(0, firstItemIndex)}
        groupContent={(index) => {
          return <TimeStamp date={groupTitles[index]?.toString()} />;
        }}
        components={{
          Item: (children) => (
            <div
              data-index={children['data-index']}
              data-known-size={children['data-known-size']}
              data-item-index={children['data-item-index']}
              style={{
                display: 'flex',
                overflowAnchor: 'none',
                justifyContent: children?.children.props.isMine
                  ? 'flex-end'
                  : 'flex-start',
              }}
            >
              {children.children}
            </div>
          ),
        }}
        itemContent={(index) => itemContent(index)}
        startReached={() => props.loadMoreMessages()}
        followOutput={followOutput}
        style={{
          display: 'flex',
          flex: '1 1 auto',
          overscrollBehavior: 'contain',
        }}
      />
      <div style={{ flex: '0 1 20px' }}></div>
    </>
  );
};

MessagesList.propTypes = {
  chatId: PropTypes.number.isRequired,
  totalMessages: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  firstItemIndex: PropTypes.number,
  messagesList: PropTypes.arrayOf(PropTypes.object).isRequired,
  loadMoreMessages: PropTypes.func.isRequired,
  virtuosoRef: PropTypes.any.isRequired,
};

export default MessagesList;
