import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { getHistoryDigest } from '../../../../../logic/customer/history/historyApiFunctions';
import { userInformations } from '../../../../../contex';
import TaskHistoryTable from '../../../../tasks/TaskHistoryTable';

const SubItem = (props) => {
  const [loading, setLoading] = useState(false);
  const [historyList, setHistoryList] = useState([]);
  const [total, setTotal] = useState(0);
  const { userInfo } = useContext(userInformations);
  const [page, setPage] = useState(1);

  useEffect(async () => {
    await callGetHistoryDigest(props.subUrlParamsGetHistory, page);
  }, []);

  const callGetHistoryDigest = async (urlParamsGetHistory, page) => {
    setLoading(true);
    const res = await getHistoryDigest(urlParamsGetHistory, page, userInfo);
    setHistoryList(res?.history ? res.history : []);
    setTotal(res?.total ? res.total : 0);
    setLoading(false);
  };

  return (
    <>
      <TaskHistoryTable
        getHistory={async (id, page) => {
          setPage(page);
          await callGetHistoryDigest(props.subUrlParamsGetHistory, page);
        }}
        loading={loading}
        data={historyList}
        total={total}
        page={page}
      />
    </>
  );
};

SubItem.propTypes = {
  subUrlParamsGetHistory: PropTypes.string,
};

export default SubItem;
