import React, { useEffect, useRef, useState } from 'react';
import { Select } from 'antd';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';

const { Option } = Select;

const FilterByStaff = ({
  selectedKeys,
  tableFilters,
  columnKey,
  confirm,
  dataList,
  onSearch,
  useAdditionalFilter,
  nameAdditionalFilter,
  valueAdditionalFilter,
}) => {
  const [selectValue, setSelectValue] = useState([]);
  const [visibleSelectOptions, setVisibleSelectOptions] = useState(false);
  const [valueIsChanged, setValueIsChanged] = useState(false);
  const [addedElement, setAddedElement] = useState([]);
  const [searchWasUsed, setSearchWasUsed] = useState(false);
  const isMobile = useCheckMobileScreen();

  const refSelect = useRef(null);

  useEffect(() => {
    const value = selectedKeys;
    setSelectValue(value?.length ? value : []);
  }, [selectedKeys]);

  const removeFocusedClass = () => {
    const elements = document.getElementsByClassName('ant-select-focused');
    for (let element of elements) {
      element.classList.remove('ant-select-focused');
    }
    refSelect?.current?.blur();
  };

  const onKeyUp = (e) => {
    if (e.key === 'Enter') {
      setVisibleSelectOptions(false);
    }
  };

  useEffect(() => {
    confirmFilters(visibleSelectOptions);
  }, [visibleSelectOptions]);

  useEffect(() => {
    window.addEventListener('keyup', onKeyUp);
    return () => window.removeEventListener('keyup', onKeyUp);
  }, [visibleSelectOptions]);

  const confirmFilters = (visible) => {
    if (!visible && valueIsChanged) {
      let filters = tableFilters;
      const copyFilters = filters[columnKey] ? filters[columnKey] : [];
      const resultToDelete = addedElement.filter(
        (item) => !selectValue.includes(Number(item)),
      );
      if (copyFilters.length > 0) {
        for (const item of resultToDelete) {
          if (copyFilters.includes(Number(item))) {
            copyFilters.splice(copyFilters.indexOf(Number(item)), 1);
          }
        }
        for (const item of selectValue.filter(
          (item) => !addedElement.includes(Number(item)),
        )) {
          copyFilters.push(item);
        }
      } else {
        const copySelectValue = selectValue;
        for (const item of copySelectValue.filter(
          (item) => !addedElement.includes(Number(item)),
        )) {
          copyFilters.push(Number(item));
        }
      }
      if (useAdditionalFilter && selectValue.length > 0) {
        const copyFilters = filters[nameAdditionalFilter]
          ? filters[nameAdditionalFilter]
          : [];
        copyFilters.push(valueAdditionalFilter);
        filters[nameAdditionalFilter] = [...new Set(copyFilters)];
      } else if (useAdditionalFilter) {
        const copyFilters = filters[nameAdditionalFilter]
          ? filters[nameAdditionalFilter]
          : [];
        filters[nameAdditionalFilter] = copyFilters.filter(
          (item) => item !== valueAdditionalFilter,
        );
      }
      filters[columnKey] = copyFilters;
      confirm(filters);
      setValueIsChanged(false);
      setAddedElement(selectValue);
    }
    removeFocusedClass();
  };

  return (
    <div style={{ width: '100%', padding: '8px' }}>
      <Select
        getPopupContainer={(trigger) =>
          isMobile ? trigger.parentNode : document.body
        }
        ref={refSelect}
        style={{ width: '100%' }}
        filterOption={false}
        allowClear={true}
        value={selectValue}
        autoFocus={false}
        placeholder={'Выберите из списка или начните поиск...'}
        mode={'multiple'}
        open={visibleSelectOptions}
        maxTagCount={'responsive'}
        onInputKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.stopPropagation();
          }
        }}
        onSearch={(e) => {
          setSearchWasUsed(true);
          onSearch(e);
        }}
        onChange={(val) => {
          setValueIsChanged(true);
          if (searchWasUsed) {
            onSearch(null);
            setSearchWasUsed(false);
          }
          if (!visibleSelectOptions) {
            setVisibleSelectOptions(true);
          }
          setSelectValue(val);
        }}
        onDropdownVisibleChange={(visible) => {
          setVisibleSelectOptions(visible);
        }}
      >
        <>
          {dataList?.map((item) => {
            return (
              <Option key={item?.value} value={item?.value}>
                {item?.title ? item.title : 'Имя не задано'}
              </Option>
            );
          })}
        </>
      </Select>
    </div>
  );
};

export default FilterByStaff;
