import { Button, Descriptions, Input, Space, Spin } from 'antd';
import { call } from '../../../apiUtils/call';
import dayjs from 'dayjs';
import { restApiHost } from '../../../utils/appVariables';
import { useContext, useEffect, useState } from 'react';
import { userInformations } from '../../../contex';

function getRangeLeft(from, to = dayjs()) {
  const from_datetime = dayjs(from);
  const to_datetime = dayjs(to);
  let different = to_datetime.diff(from_datetime, 'seconds');
  const days = different / 86400;
  different %= 86400;
  const hours = different / 3600;
  different %= 3600;
  const minutes = different / 60;
  different %= 60;
  let result = '';
  if (days) result += `${days.toFixed(0)} дней, `;
  if (hours) result += `${hours.toFixed(0)} часов, `;
  if (minutes) result += `${minutes.toFixed(0)} минут, `;
  result += `${different} секунд`;
  return result;
}

export const ScheduleItem = ({ schedule, updateSchedules }) => {
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const { userInfo } = useContext(userInformations);

  useEffect(() => {
    setActive(schedule.enabled);
  }, []);

  const defineLaunchMethod = (schedule) => {
    if (schedule.clocked)
      return (
        <p>
          only on{' '}
          {dayjs(schedule.clocked.clocked_time).format('YYYY-MM-DD HH:mm:ssZ')}
        </p>
      );
    if (schedule.interval)
      return (
        <p>
          every {schedule.interval.every} {schedule.interval.period}
        </p>
      );
    if (schedule.crontab)
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <p>Минуты: {schedule.crontab.minute}</p>
          <p>Часы: {schedule.crontab.hour}</p>
          <p>Дни недели: {schedule.crontab.day_of_week}</p>
          <p>Дни месяца: {schedule.crontab.day_of_month}</p>
          <p>Месяцы года: {schedule.crontab.month_of_year}</p>
        </div>
      );

    return <p>Не известно.</p>;
  };

  const styleHeader = (label) => (
    <p style={{ fontWeight: 600, fontSize: 16, margin: 0 }}>{label}</p>
  );

  const generateschedules = (schedule) => {
    return [
      {
        key: crypto.randomUUID(),
        span: 1,
        label: styleHeader('Название'),
        children: (
          <div
            style={{
              position: 'relative',
            }}
          >
            <Input
              defaultValue={schedule.name}
              style={{
                border: 'none',
              }}
            />
            {/* <Button
                        style={{ 
                            position: 'absolute', 
                            right: 2, 
                            top: 2
                        }}
                        size='small'
                    >
                        Сохранить
                    </Button> */}
          </div>
        ),
      },
      {
        key: crypto.randomUUID(),
        label: styleHeader('Функция'),
        children: schedule.task,
      },
      {
        key: crypto.randomUUID(),
        span: 3,
        label: styleHeader('Описание'),
        children: (
          <Input
            defaultValue={schedule.description}
            style={{ border: 'none' }}
          />
        ),
      },
      {
        key: crypto.randomUUID(),
        label: styleHeader('Аргументы'),
        children: (
          <p>
            {schedule.args}, {schedule.kwargs}
          </p>
        ),
      },
      {
        key: crypto.randomUUID(),
        label: styleHeader('Дата изменения'),
        children: (
          <p>
            {dayjs(schedule.date_changed).format('YYYY-MM-DD HH:mm:ssZ')}{' '}
            <span style={{ color: '#c4c4c4' }}>
              {getRangeLeft(schedule.date_changed)} назад
            </span>
          </p>
        ),
      },
      {
        key: crypto.randomUUID(),
        label: styleHeader('Последний запуск'),
        children: (
          <p>
            {schedule.last_run_at
              ? schedule.last_run_at
              : 'Еще не запускалось.'}
          </p>
        ),
      },
      {
        key: crypto.randomUUID(),
        label: styleHeader('Общее кол-во исполнений'),
        children: schedule.total_run_count,
      },
      {
        key: crypto.randomUUID(),
        label: styleHeader('Условие запуска'),
        children: defineLaunchMethod(schedule),
      },
      {
        key: crypto.randomUUID(),
        span: 3,
        label: styleHeader('Действия'),
        children: (
          <Space size={8}>
            <Button
              onClick={toggleEnable}
              style={{
                borderColor: active ? 'red' : 'green',
                color: active ? 'red' : 'green',
              }}
            >
              {active ? 'Деактивировать' : 'Активировать'}
            </Button>
            <Button onClick={deleteScheduleschedule}>Удалить</Button>
          </Space>
        ),
      },
    ];
  };

  const toggleEnable = () => {
    setLoading(true);
    call(
      `${restApiHost}/administration/periodic/${schedule.id}/`,
      {
        method: 'PATCH',
        body: JSON.stringify({
          enabled: !active,
        }),
        headers: {
          Authorization: `Token ${localStorage.getschedule('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
      null,
    ).then((json) => {
      setActive(json.data.enabled);
      setLoading(false);
    });
  };

  const deleteScheduleschedule = () => {
    call(
      `${restApiHost}/administration/periodic/${schedule.id}/`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Token ${localStorage.getschedule('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
      null,
    ).then(() => {
      updateSchedules();
    });
  };

  return (
    <Spin spinning={loading}>
      <Descriptions
        style={{ marginTop: 8, border: '1px solid #c4c4c4' }}
        size="small"
        bordered
        layout="vertical"
      >
        {generateschedules(schedule).map((val) => (
          <Descriptions.Item
            key={val.key}
            label={val.label}
            style={{ backgroundColor: 'white' }}
          >
            {val.children}
          </Descriptions.Item>
        ))}
      </Descriptions>
    </Spin>
  );
};
