import { restApiHost } from '../../utils/appVariables';
import { call } from '../../apiUtils/call';
import { message } from 'antd';

export async function getTempletesConstract(
  singerMangerCompanyId,
  contractType,
  userInfo,
) {
  try {
    const url = `${restApiHost}/refbook/contract_template/?contract_type=${contractType}&customer=${singerMangerCompanyId}&active=true`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (json?.data) {
      return json?.data;
    }
  } catch (e) {
    console.log('get contract template err', e);
    message.error('Ошибка получения шаблонов договоров');
  }
}
