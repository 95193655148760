export enum TodoActionTypes {
  SET_COUNT = 'SET_COUNT',
  SET_WAS_VIEWING_MESSAGE = 'SET_WAS_VIEWING_MESSAGE',
}

interface SetCount {
  type: TodoActionTypes.SET_COUNT;
  payload: number | null;
}

interface SetWasViewingMessage {
  type: TodoActionTypes.SET_WAS_VIEWING_MESSAGE;
  payload: boolean;
}

export type TodoActionChatNotification = SetCount | SetWasViewingMessage;
