import React, { useContext, useEffect, useState } from 'react';
import { Box, Card, CardContent } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { Button, Button as ButtonAntd, Dropdown, Input, message } from 'antd';
import {
  FileExcelOutlined,
  FilterOutlined,
  PlusOutlined,
  ReloadOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { restApiHost } from '../../utils/appVariables';
import { userInformations } from '../../contex';
import { call } from '../../apiUtils/call';
import { convertFiltersToObj } from '../../utils/getFilters';
import { ErrorBoundary } from '../ErrorBoundary';
import LeadModal from './modal/mainModal/LeadModal';
import { useTypedSelector } from '../../store/hooks/useTypedSelector';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';

const { Search } = Input;

const LeedsToolbar = ({
  handleUpdate,
  page,
  currStatusFilter,
  totalLeads,
  leadCompanyName,
  loading,
  changeVisibleFilters,
  changeVisibleTableSetting,
  clearLeadForSearchAfterCreatePreTask,
  handleScrollToTopMobileList,
}) => {
  const [visibleModalEditLead, setVisibleModalEditLead] = useState(false);
  const [currentLead, setCurrentLead] = useState(null);
  //download excel leads
  const [loadingExcelDownload, setLoadingExcelDownLoad] = useState(false);
  const { userInfo } = useContext(userInformations);
  const [searchLead, setSearchLead] = useState('');
  const demoAccessIsUsed = useTypedSelector(
    (state) => state.demoAccess.demoAccessIsUsed,
  );
  const [openDropdownMenu, setOpenDropdownMenu] = useState(false);
  const isMobile = useCheckMobileScreen();

  useEffect(() => {
    if (leadCompanyName) {
      setSearchLead(leadCompanyName);
    }
  }, [leadCompanyName]);

  const handleSearch = async (value) => {
    const firstPage = 1;
    if (value) {
      currStatusFilter?.filter
        ? await handleUpdate(firstPage, {
            ...currStatusFilter,
            filter: { ...currStatusFilter.filter, search: value },
          })
        : await handleUpdate(firstPage, {
            ...currStatusFilter,
            filter: { search: value },
          });
    } else {
      const newFilter = { ...currStatusFilter };
      delete newFilter?.filter?.search;
      await handleUpdate(firstPage, newFilter);
    }
  };

  const downloadExcelFile = async (filters) => {
    let urlParamsObj = {};
    if (filters) {
      urlParamsObj = convertFiltersToObj(filters);
    }
    try {
      setLoadingExcelDownLoad(true);
      const url = `${restApiHost}/stats/export_excel/`;
      const json = await call(
        url,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json; charset=UTF-8',
          },
          body: JSON.stringify(urlParamsObj),
        },
        userInfo.current.haveViewBranch,
        false,
      );
      if (json?.data?.url) {
        const nameFile = json?.data?.filename
          ? json.data?.filename
          : 'Лиды CRM';
        const downloadLink = document.createElement('a');
        downloadLink.href = `${restApiHost}${json?.data?.url}`;
        downloadLink.download = nameFile;
        document.body.appendChild(downloadLink);
        downloadLink.click();
      }
    } catch (e) {
      message.error(`Ошибка скачивания файла: ${e.message}`);
    } finally {
      setLoadingExcelDownLoad(false);
    }
  };

  const toolbars = () => {
    return (
      <>
        <aside className={'Toolbars-wrapper'}>
          <Tooltip title={'Обновить'}>
            <Button
              className={'icons-toolbar-color-white'}
              disabled={loading}
              loading={loading}
              style={{ margin: '0 10px' }}
              onClick={() => {
                openDropdownMenu && setOpenDropdownMenu(false);
                handleUpdate(page, currStatusFilter);
              }}
              type="text"
              icon={<ReloadOutlined />}
            >
              {isMobile ? 'Обновить' : ''}
            </Button>
          </Tooltip>
          <Tooltip title={'Отобразить фильтры'}>
            <ButtonAntd
              className={'icons-toolbar-color-white'}
              style={{ margin: '0 10px' }}
              disabled={loading}
              onClick={() => {
                isMobile && handleScrollToTopMobileList();
                openDropdownMenu && setOpenDropdownMenu(false);
                changeVisibleFilters();
              }}
              type="text"
              icon={<FilterOutlined />}
            >
              {isMobile ? 'Отобразить фильтры' : ''}
            </ButtonAntd>
          </Tooltip>
          <Tooltip title={'Настройка таблицы'}>
            <ButtonAntd
              className={'icons-toolbar-color-white'}
              style={{ marginRight: '10px' }}
              disabled={loading || demoAccessIsUsed}
              onClick={() => {
                isMobile && handleScrollToTopMobileList();
                openDropdownMenu && setOpenDropdownMenu(false);
                changeVisibleTableSetting();
              }}
              type="text"
              icon={<SettingOutlined />}
            >
              {isMobile ? 'Настройка таблицы' : ''}
            </ButtonAntd>
          </Tooltip>
          <Tooltip title={'Выгрузка лидов в excel'}>
            <Button
              className={'icons-toolbar-color-white'}
              disabled={loading || demoAccessIsUsed}
              loading={loadingExcelDownload}
              onClick={async () => await downloadExcelFile(currStatusFilter)}
              style={{ marginRight: 10 }}
              icon={<FileExcelOutlined />}
              type={'link'}
            >
              {isMobile ? 'Выгрузка лидов в excel' : ''}
            </Button>
          </Tooltip>
          <Button
            className={'toolbars-additions'}
            icon={<PlusOutlined />}
            disabled={demoAccessIsUsed}
            onClick={() => {
              openDropdownMenu && setOpenDropdownMenu(false);
              setVisibleModalEditLead(true);
            }}
          >
            Добавить
          </Button>
        </aside>
      </>
    );
  };

  return (
    <Box>
      <Box sx={{ mt: 3, mb: 3 }}>
        <Card className={'primary-bg-color'}>
          <CardContent>
            <Box style={{ display: 'flex' }}>
              <Search
                disabled={loading}
                value={searchLead}
                placeholder="Поиск лида CRM (название компании, контакт, телефон)"
                allowClear
                enterButton
                onSearch={(value) => handleSearch(value)}
                onChange={(e) => {
                  if (e.target.value?.length <= 0) {
                    clearLeadForSearchAfterCreatePreTask();
                  }
                  setSearchLead(e.target.value);
                }}
              />

              {isMobile ? (
                <Dropdown
                  visible={openDropdownMenu}
                  onVisibleChange={(visible) => {
                    setOpenDropdownMenu(visible);
                  }}
                  overlay={toolbars()}
                  placement="bottomRight"
                  trigger={['click']}
                >
                  <Button className={'Toolbars-button'}>
                    <svg
                      width="14"
                      height="12"
                      viewBox="0 0 14 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1C14 1.26522 13.8946 1.51957 13.7071 1.70711C13.5196 1.89464 13.2652 2 13 2H1C0.734784 2 0.48043 1.89464 0.292893 1.70711C0.105357 1.51957 0 1.26522 0 1ZM0 6C0 5.73478 0.105357 5.48043 0.292893 5.29289C0.48043 5.10536 0.734784 5 1 5H13C13.2652 5 13.5196 5.10536 13.7071 5.29289C13.8946 5.48043 14 5.73478 14 6C14 6.26522 13.8946 6.51957 13.7071 6.70711C13.5196 6.89464 13.2652 7 13 7H1C0.734784 7 0.48043 6.89464 0.292893 6.70711C0.105357 6.51957 0 6.26522 0 6ZM6 11C6 10.7348 6.10536 10.4804 6.29289 10.2929C6.48043 10.1054 6.73478 10 7 10H13C13.2652 10 13.5196 10.1054 13.7071 10.2929C13.8946 10.4804 14 10.7348 14 11C14 11.2652 13.8946 11.5196 13.7071 11.7071C13.5196 11.8946 13.2652 12 13 12H7C6.73478 12 6.48043 11.8946 6.29289 11.7071C6.10536 11.5196 6 11.2652 6 11Z"
                        fill="#0B7B7F"
                      />
                    </svg>
                  </Button>
                </Dropdown>
              ) : (
                toolbars()
              )}
            </Box>
          </CardContent>
        </Card>
      </Box>

      {totalLeads ? (
        <b className={'total-rows-table'}>Итого: {totalLeads}</b>
      ) : (
        <></>
      )}

      <ErrorBoundary>
        <LeadModal
          visibleModal={visibleModalEditLead}
          editingLead={currentLead}
          onCloseModal={() => {
            setVisibleModalEditLead(false);
            setCurrentLead(null);
            handleUpdate(page, currStatusFilter);
          }}
          setCurrentLeadData={(leadData) => {
            setCurrentLead(leadData);
          }}
        />
      </ErrorBoundary>
    </Box>
  );
};

export default LeedsToolbar;
