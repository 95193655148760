import PropTypes from 'prop-types';
import { useSortable } from '@dnd-kit/sortable';
import { createContext, useCallback, useContext, useMemo } from 'react';
import { CSS } from '@dnd-kit/utilities';
import './sortableItem.css';

const SortableItemContext = createContext({
  attributes: {},
  listeners: undefined,
  ref() {},
});

const SortableItem = (props) => {
  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
  } = useSortable({
    id: props.index ? props.index : '',
  });

  const context = useMemo(
    () => ({
      attributes,
      listeners,
      ref: setActivatorNodeRef,
    }),
    [attributes, listeners, setActivatorNodeRef],
  );

  const style = useCallback(() => {
    return {
      transition,
      transform: CSS.Transform.toString(transform),
      opacity: isDragging ? 0.4 : undefined,
    };
  }, [transition, transform, isDragging]);

  return (
    <>
      <SortableItemContext.Provider value={context}>
        <li className="SortableItem" ref={setNodeRef} style={style()}>
          {props.children}
        </li>
      </SortableItemContext.Provider>
    </>
  );
};

export function DragHandle() {
  const { attributes, listeners, ref } = useContext(SortableItemContext);

  return (
    <button className="DragHandle" {...attributes} {...listeners} ref={ref}>
      <svg viewBox="0 0 20 20" width="12">
        <path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"></path>
      </svg>
    </button>
  );
}

SortableItem.propTypes = {
  children: PropTypes.any,
  index: PropTypes.number,
};
export default SortableItem;
