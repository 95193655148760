import PropTypes from 'prop-types';
import React from 'react';
import { Col, Form, Input } from 'antd';
import { priceFormatter } from '../../../../utils/priceFormatter';

const TotalSumsGroupInput = (props) => {
  return (
    <>
      <Col lg={24} md={8} xs={24}>
        {/*{isImportTask ?*/}
        <div style={{ height: '59px' }}>
          <p
            style={{ color: '#000000D9', height: '22px', marginBottom: '0px' }}
          >
            {props.groupTitle || 'Не указано'}
          </p>
          {props.notZeroValueField(props.totalSumsObj) ? (
            <>
              <div
                style={{ width: '100%', display: 'flex', flexDirection: 'row' }}
              >
                {props.totalSumsObj.totalSumRub !== 0 ? (
                  <div style={{ width: '100%', minWidth: '72px' }}>
                    <Input
                      disabled
                      value={
                        priceFormatter(props.totalSumsObj.totalSumRub) + '₽'
                      }
                    />
                  </div>
                ) : (
                  <></>
                )}
                {props.totalSumsObj.totalSumDol !== 0 ? (
                  <div
                    style={{
                      width: '100%',
                      minWidth: '72px',
                      marginLeft: '5px',
                    }}
                  >
                    <Form.Item>
                      <Input
                        disabled
                        value={
                          priceFormatter(props.totalSumsObj.totalSumDol) + '$'
                        }
                      />
                    </Form.Item>
                  </div>
                ) : (
                  <></>
                )}
                {props.totalSumsObj.totalSumEur !== 0 ? (
                  <div
                    style={{
                      width: '100%',
                      minWidth: '72px',
                      marginLeft: '5px',
                    }}
                  >
                    <Form.Item>
                      <Input
                        disabled
                        value={
                          priceFormatter(props.totalSumsObj.totalSumEur) + '€'
                        }
                      />
                    </Form.Item>
                  </div>
                ) : (
                  <></>
                )}
                {props.totalSumsObj.totalSumCny !== 0 ? (
                  <div
                    style={{
                      width: '100%',
                      minWidth: '72px',
                      marginLeft: '5px',
                    }}
                  >
                    <Form.Item>
                      <Input
                        disabled
                        value={
                          priceFormatter(props.totalSumsObj.totalSumCny) + '¥'
                        }
                      />
                    </Form.Item>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </>
          ) : (
            <div style={{ width: '100%', minWidth: '72px', marginLeft: '5px' }}>
              <Input disabled value={0} />
            </div>
          )}{' '}
        </div>
      </Col>
    </>
  );
};

TotalSumsGroupInput.propTypes = {
  totalSumsObj: PropTypes.object,
  groupTitle: PropTypes.string,
  notZeroValueField: PropTypes.func,
};
export default React.memo(TotalSumsGroupInput);
