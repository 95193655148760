import React from 'react';
import { Button, message, Popconfirm, Space, Table, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

const DepartamentsTable = ({ data, loading }) => {
  const columns = [
    {
      title: 'Действия',
      key: 'action',
      width: 100,
      align: 'center',
      render: (customer) => (
        <Space size="middle">
          <Tooltip title="Редактировать">
            <Button
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => console.log(customer)}
            />
          </Tooltip>

          <Tooltip title="Удалить" onClick={() => console.log(customer)}>
            <Popconfirm
              title="Вы действительно хотите удалить запись?"
              onConfirm={() => message.success('Успешно удалено')}
              onCancel={() => console.log('Delete item')}
              okText="Да"
              cancelText="Нет"
              placement="rightBottom"
            >
              <Button shape="circle" icon={<DeleteOutlined />} />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
    {
      title: 'Наименование',
      key: 'name',
      dataIndex: 'name',
    },
  ];
  return (
    <Table
      showHeader
      dataSource={data}
      columns={columns}
      bordered={true}
      loading={loading}
    />
  );
};

export default DepartamentsTable;
