import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import {
  Avatar,
  Button,
  Popconfirm,
  Space,
  Table,
  Tooltip,
  Modal,
  Form,
  Drawer,
  Row,
  Col,
  Divider,
  Dropdown,
  Menu,
  Select,
  List,
  Tag,
  ConfigProvider,
} from 'antd';
import locale from 'antd/lib/locale/ru_RU';
import getInitials from '../../utils/getInitials';
import {
  EditOutlined,
  DeleteOutlined,
  FileDoneOutlined,
  UserOutlined,
  FilePdfOutlined,
} from '@ant-design/icons';
import { message } from 'antd';
import { restApiHost } from '../../utils/appVariables';

import 'moment/locale/ru';
import EllipsisOutlined from '@ant-design/icons/lib/icons/EllipsisOutlined';
import IncomingCall from '../IncomingCall';
import UpdateCustomerModal from '../UpdateCustomer/UpdateCustomerModal';
import { getFiltersTable } from '../../utils/getFilters';
import HistoryCallCustomerModal from '../HistoryCalls/Customers/HistoryCallCustomerModal';
import HistoryOutlined from '@ant-design/icons/lib/icons/HistoryOutlined';
import * as _ from 'lodash';
import ContainerOutlined from '@ant-design/icons/lib/icons/ContainerOutlined';
import PreApplicationModal from '../pre-applications/PreApplicationModal';
import { userInformations } from '../../contex';
import SendKpOrPresentationModal from '../sendKpOrPresentation/SendKpOrPresentationModal';
import { call } from '../../apiUtils/call';
import SearchDataFilter from '../leeds/filters/SearchData';
import FilterByStaff from '../leeds/filters/FilterByStaff';
import { setPaginationSettings } from '../../localStorageUtils/paginationSettings';
import convertToSelectOptions from '../leeds/filters/filtersUtils/convertToSelectOptions';
import { useLocation, useNavigate } from 'react-router-dom';
import PdfViewerModal from '../contracts/templeteContract/PdfViewerModal';
import CreateContractModal from './CreateContractModal';
import FilterSelect from '../leeds/filters/FilterSelect';
import { ErrorBoundary } from '../ErrorBoundary';
import { getTempletesConstract } from '../contracts/getTempletesConstract';
import { getGeneratedContractPdfAndDocxByTemplateId } from '../contracts/getGeneratedContractPdfAndDocxByTemplateId';
import { exportFileFormUrl } from '../../utils/exportFileFormUrl';
import moment from 'moment/moment';
import { sendContractOnApproval } from '../contracts/sendContractOnApproval';
import {
  prepareColumnsForResizing,
  sortingColumnsTable,
} from '../settingTable/sortingColumnsTable';
import SettingTableColumnsDrawer from '../settingTable/SettingTableColumnsDrawer';
import { callGetProfileInfo } from '../account/callGetProfileInfo';
import FilterDatePicker from '../leeds/filters/FilterDatePicker';
import { customerIsStock } from './cutomerModalUtils/customerModalUtilsFunctions';
import CustomerChangeHistory from '../UpdateCustomer/customerStepsComponents/customerChangeHistory/CustomerChangeHistory';
import getCallResult from '../../logic/refbook/callResult/CallResultApiFunction';
import { useTypedSelector } from '../../store/hooks/useTypedSelector';
import useAsyncEffect from 'use-async-effect';
import dayjs from 'dayjs';
import useStateRef from 'react-usestateref';
import ResizableTableColumnHeader from '../table/ResizableTableColumnHeader';
import filterActiveColumns from '../table/utils';
import changeWidthStyleMainTable from '../table/changeWidthStyleMainTable';
import { editTableColumn } from '../../logic/profileInfo/mainTable/MainTableApiFunctions';
import { getProfileInfo } from '../../logic/account/AccountsApiFunctions';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import ScrollingLoadedList from '../mobile/scrollingLoadedList/ScrollingLoadedList';
import CustomerListItem from '../mobile/customers/list/CustomerListItem';
import { userInfoHaveViewAllBranch } from '../../logic/account/utils';
import UniversalFiltersModal from '../mobile/filtersModal/UniversalFiltersModal';

const DescriptionItem = ({ title, content }) => (
  <div className="site-description-item-profile-wrapper">
    <p className="site-description-item-profile-p-label">
      {title}: {content}{' '}
    </p>
  </div>
);

const { Option } = Select;

const CustomersTable = ({
  modalCustomer,
  customers,
  currStatusFilter,
  pageProps,
  handleUpdate,
  total,
  loading,
  setLoading,
  setPreTaskCustomer,
  visibleFilters,
  defaultFilters,
  usedDefaultFilters,
  visibleTableSetting,
  onCloseDrawerTableSetting,
  setVisibleFilters,
  scrollingLoadedListRef,
  handleScrollToTopMobileList,
}) => {
  const tableName = 'customers';
  const location = useLocation();
  const navigate = useNavigate();
  const [joinComanyForm] = Form.useForm();
  const [createDocForm] = Form.useForm();
  const [editForm] = Form.useForm();
  const [visibleCreateDocModal, setVisibleCreateDocModal] = useState(false);
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [visibleEditModal, setVisibleEditModal] = useState(false);
  const [showCustomerInfo, setShowCustomerInfo] = useState(false);
  const [visibleCall, setVisibleCall] = useState(false);
  const [page, setPage, pageRef] = useStateRef(1);
  const [params, setParams, paramsRef] = useStateRef(null);
  const [typeChoices, setTypeChoices] = useState(null);
  const [signerManagers, setSignerManagers] = useState([]);
  const [signerManager, setSignerManager] = useState(null);
  const [signerCustomers, setSignerCustomers] = useState([]);
  const [allClients, setAllClients] = useState([]);
  const savingSum = useRef(false);
  const savingSumWereEditing = useRef(false);
  const [visitSumsClient, setVisitSumsClient] = useState(false);
  const [sumsWereEdited, setSumsWereEdited] = useState(false);
  const [disableBtnAtSaveSums, setDisableBtnAtSaveSums] = useState(false);
  const [loadingCustomers, setLoadingCustomers] = useState(false);
  const [loadingStaffList, setLoadingStaffList] = useState(false);
  const [currentStepByUpdateCustomer, setCurrentStepByUpdateCustomer] =
    useState(null);
  const [callResultOptions, setCallResultOptions] = useState([]);

  // Связка с дочерними компаниями
  const [visibleJoinGroupModal, setVisibleJoinGroupModal] = useState(false);
  const [joinGroupOptions, setJoinGroupOptions] = useState([]);
  const [currSubsidiaryList, setCurrSubsidiaryList] = useState([]);

  //download contract
  const [visibleContractShowPdf, setVisibleContractShowPdf] = useState(false);
  const [staffList, setStaffList] = useState([]);
  const [disableDocBtn, setDisableDocBtn] = useState(true);
  const [pdfSendLoading, setPdfSendLoading] = useState(false);
  const [uploadedDoc, setUploadedDoc] = useState(null);
  const [contractPdfUrl, setContractPdfUrl] = useState(null);
  const [contractDocxUrl, setContractDocxUrl] = useState(null);
  const [
    createContractModalDraggerFileList,
    setCreateContractModalDraggerFileList,
  ] = useState([]);
  const [
    usingUpdateCustomerModalWhenGeneratingContract,
    setUsingUpdateCustomerModalWhenGeneratingContract,
  ] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [createdContractId, setCreatedContractId] = useState(null);
  const [defaultOpenContractStep, setDefaultOpenContractStep] = useState(false);
  //history calls
  const [historyCallsModalOpen, setHistoryCallsModalOpen] = useState(false);
  const [historyCallsData, setHistoryCallsData] = useState(null);

  //customer history modal
  const [visibleHistoryModal, setVisibleHistoryModal] = useState(false);

  // customer pre application
  const [visiblePreApplicationModal, setVisiblePreApplicationModal] =
    useState(false);
  const [taskData, setTaskData] = useState(null);

  const { userInfo } = useContext(userInformations);
  const [visibleSentKpModal, setVisibleSentKpModal] = useState(false);
  const [
    ,
    setColumnFiltersFromTableComponent,
    columnFiltersFromTableComponentRef,
  ] = useStateRef([]);
  const [
    visibleSendEmailModalInCreateContractModal,
    setVisibleSendEmailModalInCreateContractModal,
  ] = useState(false);
  const mainTableRef = useRef(null);
  const isMobile = useCheckMobileScreen();

  const typeClientOptions = typeChoices?.type
    ? Object.entries(typeChoices.type).map((item) => {
        return {
          title: item[1],
          id: item[0],
        };
      })
    : [];
  const categoryOptions = typeChoices?.category
    ? Object.entries(typeChoices.category).map((item) => {
        return {
          title: item[1],
          id: item[0],
        };
      })
    : [];

  const callResultsOptions =
    callResultOptions?.length > 0
      ? callResultOptions.map((item) => {
          return {
            title: item?.result,
            id: item?.id,
          };
        })
      : [];

  const [baseColumns, setBaseColumns, baseColumnsRef] = useStateRef([]);

  const demoAccessIsUsed = useTypedSelector(
    (state) => state.demoAccess.demoAccessIsUsed,
  );

  const columns = [
    {
      title: '',
      name: 'Действия',
      key: 'action',
      width: 50,
      align: 'center',
      uniqueIndex: 'action',
      index: 1,
      ellipsis: {
        showTitle: false,
      },
      render: (customer) => (
        <Space size="middle">
          <Dropdown overlay={menu(customer)} trigger={['hover']}>
            <EllipsisOutlined style={{ cursor: 'pointer', fontSize: '24px' }} />
          </Dropdown>
        </Space>
      ),
    },
    {
      title: 'Префикс',
      dataIndex: 'designation',
      key: 'designation__search',
      uniqueIndex: 'designation',
      index: 2,
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Префикс'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={
                columnFiltersFromTableComponentRef.current?.designation__search
              }
              columnKey={'designation__search'}
            />
          ),
          ellipsis: {
            showTitle: false,
          },
          visible: visibleFilters,
          dataIndex: 'designation',
          key: 'designation__search',
          width: 150,
        },
      ],
    },
    {
      title: 'Краткое наимнование',
      dataIndex: 'short_name',
      key: 'short_name__search',
      uniqueIndex: 'short_name',
      index: 3,
      width: 250,
      ellipsis: {
        showTitle: false,
      },
      render: (short_name) => <>{short_name ? short_name : ''}</>,
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Краткое наимнование'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={
                columnFiltersFromTableComponentRef.current?.short_name__search
              }
              columnKey={'short_name__search'}
            />
          ),
          visible: visibleFilters,
          dataIndex: 'short_name',
          key: 'short_name__search',
          width: 250,
          ellipsis: {
            showTitle: false,
          },
          render: (short_name) => <>{short_name ? short_name : ''}</>,
        },
      ],
    },
    {
      title: 'Юр. имя',
      dataIndex: 'legal_name',
      key: 'legal_name__search',
      uniqueIndex: 'legal_name',
      index: 4,
      width: 250,
      ellipsis: {
        showTitle: false,
      },
      render: (legal_name) => (
        <Tooltip title={legal_name}>{legal_name ? legal_name : ''}</Tooltip>
      ),
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Юр. имя'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={
                columnFiltersFromTableComponentRef.current?.legal_name__search
              }
              columnKey={'legal_name__search'}
            />
          ),
          visible: visibleFilters,
          dataIndex: 'legal_name',
          key: 'legal_name__search',
          width: 250,
          ellipsis: {
            showTitle: false,
          },
          render: (legal_name) => (
            <Tooltip title={legal_name}>{legal_name ? legal_name : ''}</Tooltip>
          ),
        },
      ],
    },
    {
      title: 'ИНН',
      key: 'inn__search',
      dataIndex: 'inn',
      uniqueIndex: 'inn',
      index: 5,
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'ИНН'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={
                columnFiltersFromTableComponentRef.current?.inn__search
              }
              columnKey={'inn__search'}
            />
          ),
          visible: visibleFilters,
          key: 'inn__search',
          dataIndex: 'inn',
          width: 150,
          ellipsis: {
            showTitle: false,
          },
        },
      ],
    },
    {
      title: 'ОКПО',
      key: 'okpo__search',
      dataIndex: 'okpo',
      uniqueIndex: 'okpo',
      index: 6,
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'ОКПО'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={
                columnFiltersFromTableComponentRef.current?.okpo__search
              }
              columnKey={'okpo__search'}
            />
          ),
          visible: visibleFilters,
          ellipsis: {
            showTitle: false,
          },
          key: 'okpo__search',
          dataIndex: 'okpo',
          width: 120,
        },
      ],
    },
    {
      title: 'Контактное лицо',
      key: 'contact_persons__full_name__icontains',
      dataIndex: 'contact_person',
      uniqueIndex: 'contact_persons',
      index: 7,
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (contact_person) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {contact_person ? (
            <>
              <Avatar
                size={'small'}
                style={{ marginRight: '10px', minWidth: '24px' }}
              >
                {getInitials(contact_person)}
              </Avatar>
              <Tooltip title={contact_person} placement={'topLeft'}>
                <p style={{ margin: 0 }}>{contact_person}</p>
              </Tooltip>
            </>
          ) : (
            ''
          )}
        </div>
      ),
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Контактное лицо'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={
                columnFiltersFromTableComponentRef.current
                  ?.contact_persons__full_name__icontains
              }
              columnKey={'contact_persons__full_name__icontains'}
            />
          ),
          visible: visibleFilters,
          key: 'contact_persons__full_name__icontains',
          dataIndex: 'contact_person',
          width: 150,
          ellipsis: {
            showTitle: false,
          },
          render: (contact_person) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {contact_person ? (
                <>
                  <Avatar
                    size={'small'}
                    style={{ marginRight: '10px', minWidth: '24px' }}
                  >
                    {getInitials(contact_person)}
                  </Avatar>
                  <Tooltip title={contact_person} placement={'topLeft'}>
                    <p style={{ margin: 0 }}>{contact_person}</p>
                  </Tooltip>
                </>
              ) : (
                ''
              )}
            </div>
          ),
        },
      ],
    },

    {
      title: 'Контактная почта',
      dataIndex: 'email',
      key: 'email__search',
      width: 190,
      uniqueIndex: 'email',
      index: 8,
      ellipsis: {
        showTitle: false,
      },
      render: (email) =>
        email ? (
          <Tooltip title={email} placement={'topLeft'}>
            {email}
          </Tooltip>
        ) : null,
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Контактная почта'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={
                columnFiltersFromTableComponentRef.current?.email__search
              }
              columnKey={'email__search'}
            />
          ),
          visible: visibleFilters,
          dataIndex: 'email',
          key: 'email__search',
          width: 190,
          ellipsis: {
            showTitle: false,
          },
          render: (email) =>
            email ? (
              <Tooltip title={email} placement={'topLeft'}>
                {email}
              </Tooltip>
            ) : null,
        },
      ],
    },
    {
      title: 'Юр. Адрес',
      dataIndex: 'address',
      key: 'address__search',
      width: 300,
      uniqueIndex: 'address',
      index: 9,
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip title={address}>{address ? address : ''}</Tooltip>
      ),
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Контактная почта'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={
                columnFiltersFromTableComponentRef.current?.address__search
              }
              columnKey={'address__search'}
            />
          ),
          visible: visibleFilters,
          dataIndex: 'address',
          key: 'address__search',
          width: 300,
          ellipsis: {
            showTitle: false,
          },
          render: (address) => (
            <Tooltip title={address}>{address ? address : ''}</Tooltip>
          ),
        },
      ],
    },
    {
      title: 'Тип клиента',
      dataIndex: 'type',
      key: 'type',
      width: 250,
      uniqueIndex: 'type',
      index: 10,
      ellipsis: {
        showTitle: false,
      },
      render: (type) => <>{type ? `${getCustomerType(type)}` : ''}</>,
      children: [
        {
          title: (
            <FilterSelect
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={columnFiltersFromTableComponentRef.current?.type}
              columnKey={'type'}
              dataList={convertToSelectOptions(
                typeClientOptions,
                'item?.title',
              )}
            />
          ),
          dataIndex: 'type',
          key: 'type',
          width: 250,
          visible: visibleFilters,
          ellipsis: {
            showTitle: false,
          },
          render: (type) => <>{type ? `${getCustomerType(type)}` : ''}</>,
        },
      ],
    },
    {
      title: 'Тип компании',
      dataIndex: 'category',
      key: 'category',
      width: 250,
      uniqueIndex: 'category',
      index: 11,
      ellipsis: {
        showTitle: false,
      },
      render: (category) => {
        if (category?.length > 0) {
          return (
            <Tooltip
              title={category.map((item) => item?.category_str).join(', ')}
            >
              {category.map((item, index) => {
                return <Tag key={index}>{item?.category_str}</Tag>;
              })}
            </Tooltip>
          );
        }
      },
      children: [
        {
          title: (
            <FilterSelect
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={
                columnFiltersFromTableComponentRef.current?.category
              }
              columnKey={'category'}
              disable={usedDefaultFilters}
              dataList={convertToSelectOptions(categoryOptions, 'item?.title')}
            />
          ),
          dataIndex: 'category',
          key: 'category',
          width: 250,
          ellipsis: {
            showTitle: false,
          },
          visible: visibleFilters,
          render: (category) => {
            if (category?.length > 0) {
              return (
                <Tooltip
                  title={category.map((item) => item?.category_str).join(', ')}
                >
                  {category.map((item, index) => {
                    return <Tag key={index}>{item?.category_str}</Tag>;
                  })}
                </Tooltip>
              );
            }
          },
        },
      ],
    },
    {
      title: 'Привлек',
      key: 'prv',
      width: 250,
      ellipsis: {
        showTitle: false,
      },
      dataIndex: 'prv_manager',
      uniqueIndex: 'prv',
      index: 12,
      render: (prv_manager) => {
        return (
          <Tooltip placement={'topLeft'} title={prv_manager}>
            {prv_manager}
          </Tooltip>
        );
      },
      children: [
        {
          title: (
            <FilterByStaff
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              viewSearch={true}
              visibleSelect={false}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={columnFiltersFromTableComponentRef.current?.prv}
              /*useAdditionalFilter={true}
                        nameAdditionalFilter={'list_managers__group'}
                        valueAdditionalFilter={'prv'}*/
              columnKey={'prv'}
              disable={true}
              dataList={convertToSelectOptions(
                staffList,
                'item?.full_name || item?.email',
              )}
              onSearch={async (e) => {
                if (e) {
                  await searchStaff(e);
                } else {
                  await getStaffList();
                }
              }}
            />
          ),
          visible: visibleFilters,
          key: 'prv',
          width: 250,
          ellipsis: {
            showTitle: false,
          },
          dataIndex: 'prv_manager',
          render: (prv_manager) => {
            return (
              <Tooltip placement={'topLeft'} title={prv_manager}>
                {prv_manager}
              </Tooltip>
            );
          },
        },
      ],
    },
    {
      title: 'Ведет',
      key: 'vdt',
      width: 250,
      ellipsis: {
        showTitle: false,
      },
      uniqueIndex: 'vdt',
      index: 13,
      dataIndex: 'vdt_manager',
      render: (vdt_manager) => {
        return (
          <Tooltip placement={'topLeft'} title={vdt_manager}>
            {vdt_manager}
          </Tooltip>
        );
      },
      children: [
        {
          title: (
            <FilterByStaff
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              viewSearch={true}
              visibleSelect={false}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={columnFiltersFromTableComponentRef.current?.vdt}
              columnKey={'vdt'}
              /*useAdditionalFilter={true}
                        nameAdditionalFilter={'list_managers__group'}
                        valueAdditionalFilter={'vdt'}*/
              disable={true}
              dataList={convertToSelectOptions(
                staffList,
                'item?.full_name || item?.email',
              )}
              onSearch={async (e) => {
                if (e) {
                  await searchStaff(e);
                } else {
                  await getStaffList();
                }
              }}
            />
          ),
          visible: visibleFilters,
          key: 'vdt',
          width: 250,
          ellipsis: {
            showTitle: false,
          },
          dataIndex: 'vdt_manager',
          render: (vdt_manager) => {
            return (
              <Tooltip placement={'topLeft'} title={vdt_manager}>
                {vdt_manager}
              </Tooltip>
            );
          },
        },
      ],
    },
    {
      title: 'Ответственный',
      key: 'otv',
      width: 250,
      ellipsis: {
        showTitle: false,
      },
      uniqueIndex: 'otv',
      index: 14,
      dataIndex: 'otv_manager',
      render: (otv_manager) => {
        return (
          <Tooltip placement={'topLeft'} title={otv_manager}>
            {otv_manager}
          </Tooltip>
        );
      },
      children: [
        {
          title: (
            <FilterByStaff
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              viewSearch={true}
              visibleSelect={false}
              disable={true}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={columnFiltersFromTableComponentRef.current?.otv}
              columnKey={'otv'}
              /*useAdditionalFilter={true}
                        nameAdditionalFilter={'list_managers__group'}
                        valueAdditionalFilter={'otv'}*/
              dataList={convertToSelectOptions(
                staffList,
                'item?.full_name || item?.email',
              )}
              onSearch={async (e) => {
                if (e) {
                  await searchStaff(e);
                } else {
                  await getStaffList();
                }
              }}
            />
          ),
          visible: visibleFilters,
          key: 'list_managers__manager__id',
          width: 250,
          ellipsis: {
            showTitle: false,
          },
          dataIndex: 'otv_manager',
          render: (otv_manager) => {
            return (
              <Tooltip placement={'topLeft'} title={otv_manager}>
                {otv_manager}
              </Tooltip>
            );
          },
        },
      ],
    },
    {
      title: 'Источник',
      dataIndex: 'source',
      key: 'source__search',
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      uniqueIndex: 'source',
      index: 15,
      render: (source) => <>{source ? source : ''}</>,
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Источник'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={
                columnFiltersFromTableComponentRef.current?.source__search
              }
              columnKey={'source__search'}
            />
          ),
          dataIndex: 'source',
          key: 'source__search',
          width: 200,
          ellipsis: {
            showTitle: false,
          },
          visible: visibleFilters,
          render: (source) => <>{source ? source : ''}</>,
        },
      ],
    },
    {
      title: 'Комментарий',
      dataIndex: 'comment',
      key: 'comment__search',
      width: 250,
      ellipsis: {
        showTitle: false,
      },
      uniqueIndex: 'comment',
      index: 16,
      render: (comment) =>
        comment ? (
          <Tooltip title={comment} placement={'topLeft'}>
            {comment}
          </Tooltip>
        ) : null,
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Комментарий'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={
                columnFiltersFromTableComponentRef.current?.comment__search
              }
              columnKey={'comment__search'}
            />
          ),
          visible: visibleFilters,
          key: 'comment__search',
          dataIndex: 'comment',
          ellipsis: {
            showTitle: false,
          },
          width: 250,
        },
      ],
    },
    {
      title: 'След. общение',
      key: 'date_next_request__periodDate',
      width: 200,
      dataIndex: 'date_next_request',
      uniqueIndex: 'date_next_request',
      index: 17,
      ellipsis: {
        showTitle: false,
      },
      render: (date_next_request) =>
        date_next_request ? (
          moment(date_next_request).format('DD-MM-YYYY HH:mm')
        ) : (
          <></>
        ),
      children: [
        {
          title: (
            <FilterDatePicker
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={
                columnFiltersFromTableComponentRef.current
                  ?.date_next_request__periodDate
              }
              columnKey={'date_next_request__periodDate'}
            />
          ),
          key: 'date_next_request__periodDate',
          width: 200,
          dataIndex: 'date_next_request',
          visible: visibleFilters,
          ellipsis: {
            showTitle: false,
          },
          render: (date_next_request) =>
            date_next_request ? (
              moment(date_next_request).format('DD-MM-YYYY HH:mm')
            ) : (
              <></>
            ),
        },
      ],
    },
    {
      title: 'Итог звонка',
      key: 'call_result',
      dataIndex: 'call_result',
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      uniqueIndex: 'call_result',
      index: 18,
      render: (call_result) =>
        call_result?.result ? (
          <Tooltip placement={'topLeft'} title={call_result.result}>
            {call_result.result}
          </Tooltip>
        ) : null,
      children: [
        {
          title: (
            <FilterSelect
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={
                columnFiltersFromTableComponentRef.current?.call_result
              }
              columnKey={'call_result'}
              dataList={convertToSelectOptions(
                callResultsOptions,
                'item?.title',
              )}
            />
          ),
          key: 'call_result',
          dataIndex: 'call_result',
          width: 200,
          ellipsis: {
            showTitle: false,
          },
          visible: visibleFilters,
          render: (call_result) =>
            call_result?.result ? (
              <Tooltip placement={'topLeft'} title={call_result.result}>
                {call_result.result}
              </Tooltip>
            ) : null,
        },
      ],
    },
  ];

  useEffect(() => {
    checkUserIsAdmin(userInfo.current);
  }, []);

  useAsyncEffect(async () => {
    if (visibleFilters) {
      await callGetCallResult();
      await getStaffList();
    }
  }, [visibleFilters]);

  useAsyncEffect(async () => {
    if (visibleSentKpModal) {
      await getStaffList();
    }
  }, [visibleSentKpModal]);

  useAsyncEffect(async () => {
    await getChoices();
  }, []);

  useEffect(() => {
    const col = sortingColumnsTable(
      userInfo,
      tableName,
      columns,
      visibleFilters,
    );
    const preparedColumnsForResizing = prepareColumnsForResizing(
      col,
      handleResize,
    );
    setBaseColumns(preparedColumnsForResizing);
  }, [visibleFilters, staffList]);

  useEffect(() => {
    if (!visibleCreateDocModal) {
      setCurrentCustomer(null);
      setDisableDocBtn(true);
    } else {
      setCreatedContractId(null);
    }
  }, [visibleCreateDocModal]);

  useEffect(() => {
    if (currentCustomer) {
      editForm.setFieldsValue({
        address: currentCustomer.address,
        comment: currentCustomer.comment,
        company_type: currentCustomer.company_type,
        customer_type: currentCustomer.customer_type,
        email: currentCustomer.email,
        contact_person: currentCustomer.contact_person,
        lead: currentCustomer.lead,
        legal_name: currentCustomer.legal_name,
        phone_numbers:
          currentCustomer.phone_numbers?.length > 0
            ? currentCustomer.phone_numbers[0]?.phone_number
            : '',
        source: currentCustomer.source,
      });
    }
  }, [currentCustomer]);

  useEffect(() => {
    if (modalCustomer) {
      setVisibleEditModal(true);
      setCurrentCustomer(modalCustomer);
    } else {
      setVisibleEditModal(false);
      setCurrentCustomer(null);
    }
  }, [modalCustomer]);

  useEffect(() => {
    if (currStatusFilter) {
      const newFilters = {
        ...currStatusFilter?.filter,
        filter: {
          ...currStatusFilter?.filter,
          search: currStatusFilter?.filter?.search,
        },
      };
      setParams(newFilters);
    } else {
      setParams(null);
      setColumnFiltersFromTableComponent([]);
    }
  }, [currStatusFilter]);

  useEffect(() => {
    if (pageProps) {
      setPage(pageProps);
    }
  }, [pageProps]);

  useEffect(() => {
    if (defaultFilters && defaultFilters?.length > 0) {
      let updateFilters = [];
      for (const filter of defaultFilters) {
        updateFilters[filter.name] =
          filter.value?.length > 0 ? filter.value : [filter.value];
      }
      handleTableChange({ currentPage: 1 }, updateFilters, null);
    } else {
      handleTableChange({ currentPage: 1 }, [], null);
    }
    setPage(1);
  }, [defaultFilters]);

  useEffect(() => {
    setDisableDocBtn(true);
  }, [createContractModalDraggerFileList]);

  const checkUserIsAdmin = (user) => {
    if (
      user?.groups?.some((o) => o.id === 1) ||
      user?.groups?.some((o) => o.id === 2) ||
      user?.groups?.some((o) => o.id === 4)
    ) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  };
  const searchCustomers = async (keyword) => {
    try {
      const url = `${restApiHost}/customers/list/?search=${keyword}`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      let filteredOptions = json?.data.filter(
        (item) => item.id !== currentCustomer.id,
      );

      const selectedSubsidiary =
        currSubsidiaryList?.length > 0 &&
        currSubsidiaryList.map((item) => item.subsidiary.id);

      selectedSubsidiary?.length > 0 &&
        selectedSubsidiary.map((value) => {
          filteredOptions = filteredOptions.filter((item) => item.id !== value);
        });

      setJoinGroupOptions(filteredOptions);
    } catch (e) {
      console.log('search clients err', e);
      message.error('Ошибка запроса для поиска клиента');
    }
  };

  // const getSignerManagers = async () => {
  //     try {
  //         const url = `${restApiHost}/accounts/staff_users/`;
  //         const res = await fetch(url, {
  //             method: "GET",
  //             headers: {
  //                 Authorization: `Token ${localStorage.getItem("token")}`,
  //                 "Content-Type": "application/json",
  //             },
  //         });
  //         const json = await res.json();
  //         setSignerManagers(json.results);
  //     } catch (e) {
  //         console.log("get staff err", e);
  //     }
  // };

  const getSignerCustomers = async (id) => {
    try {
      const url = `${restApiHost}/customers/filter_our_customer/?customer=${id}`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!json?.data) return;

      const clients = _.groupBy(
        json?.data?.clients,
        (contact) => contact.customer_legal_name,
      );
      const our = _.groupBy(
        json.data?.our,
        (contact) => contact.customer_legal_name,
      );
      const all = _.groupBy(
        json.data?.all_clients,
        (contact) => contact.customer_legal_name,
      );

      setSignerCustomers(clients);
      setSignerManagers(our);
      setAllClients(all);
    } catch (e) {
      console.log('get staff err', e);
    }
  };

  const callGetCallResult = async () => {
    const res = await getCallResult('customer', userInfo);
    if (res) {
      setCallResultOptions(res);
    }
  };

  const getChoices = async () => {
    try {
      const url = `${restApiHost}/customers/type_choices/`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );
      if (json?.data) {
        setTypeChoices(json?.data);
      }
    } catch (e) {
      console.log('get choices err', e);
    }
  };

  const deleteCustomer = async (id) => {
    try {
      const url = `${restApiHost}/customers/list/${id}/`;
      const response = await call(
        url,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!response) return;

      message.success('Клиент успешно удален');
      isMobile && handleScrollToTopMobileList();
      handleUpdate(isMobile ? 1 : pageRef.current, paramsRef.current);
    } catch (e) {
      console.log('delete customer err', e);
      message.error('Ошибка запроса на удаление клиента');
    }
  };
  const getCustomer = async (id) => {
    setLoadingCustomers(true);
    try {
      const url = `${restApiHost}/customers/list/${id}/`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      /* handleUpdate(page, params);*/
      setCurrentCustomer(json?.data);
      setLoadingCustomers(false);
      return json;
    } catch (e) {
      console.log('delete customer err', e);
      message.error('Ошибка запроса на получение клиента');
    } finally {
      setLoadingCustomers(false);
    }
  };
  const addSubsidiary = async (body) => {
    try {
      const url = `${restApiHost}/customers/group_customer/`;
      const json = await call(
        url,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      setCurrSubsidiaryList([...currSubsidiaryList, json]);
      message.success('Успешно добавлено');
    } catch (e) {
      console.log('add subsidiart err', e);
    }
  };

  const deleteSubsidiary = async (id) => {
    try {
      const url = `${restApiHost}/customers/group_customer/${id}/`;
      const response = await call(
        url,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!response) return;

      const newList = [...currSubsidiaryList].filter((item) => item.id !== id);
      setCurrSubsidiaryList(newList);

      let filteredOptions = customers.filter(
        (item) => item.id !== currentCustomer.id,
      );

      const selectedSubsidiary =
        newList?.length > 0 && newList.map((item) => item.subsidiary.id);

      selectedSubsidiary?.length > 0 &&
        selectedSubsidiary.map((value) => {
          filteredOptions = filteredOptions.filter((item) => item.id !== value);
        });

      setJoinGroupOptions(filteredOptions);

      message.success('Успешно удалено');
    } catch (e) {
      console.log('delete subsidiary err', e);
      message.error('Ошибка запроса на удаление дочерней компании');
    }
  };

  const getCustomerHistory = async (id, page = 1) => {
    try {
      const url = `${restApiHost}/history/customer/${id}?page=${page}`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;
    } catch (e) {
      console.log(e);
      message.error('Ошибка запроса на получение истории заявки');
    }
  };
  const handleGetPreTaskForSendKp = async () => {
    setLoading(true);
    const hide = message.loading('Поиск пред.заявок клиента', 0);
    setVisibleSentKpModal(true);
    hide();
    setLoading(false);
  };
  const closeVisibleModal = () => {
    setVisibleSentKpModal(false);
  };

  function menu(customer) {
    return (
      <Menu style={{ width: 220 }}>
        <Menu.Item
          icon={<EditOutlined />}
          onClick={() => {
            setCurrentCustomer(customer);
            setVisibleEditModal(true);
          }}
        >
          Редактировать
        </Menu.Item>
        <Menu.Item
          icon={<ContainerOutlined />}
          onClick={() => {
            setCurrentCustomer(customer);
            setVisiblePreApplicationModal(true);
            setTaskData(null);
            setPreTaskCustomer(customer);
          }}
        >
          Оформить пред. заявку
        </Menu.Item>
        <Menu.Item
          disabled={demoAccessIsUsed}
          icon={<HistoryOutlined />}
          onClick={() => {
            setVisibleHistoryModal(true);
            setCurrentCustomer(customer);
            getCustomerHistory(customer.id);
          }}
        >
          История изменений
        </Menu.Item>
        {/*<Menu.Item
                    icon={<FileDoneOutlined/>}
                    onClick={() => {

                        setCurrentCustomer(customer)
                    }}
                >
                    Отправить док-ты
                </Menu.Item>*/}
        <Menu.Item
          disabled={demoAccessIsUsed}
          icon={<FilePdfOutlined />}
          /* onClick={() => {
                        getStaffList()
                        setCurrentCustomer(customer);
                        setVisibleSentKpModal(true);
                    }}*/
          onClick={async () => {
            getStaffList();
            await handleGetPreTaskForSendKp(customer?.id);
          }}
        >
          Отправить КП/Презентацию
        </Menu.Item>
        <Menu.Item
          disabled={demoAccessIsUsed}
          icon={<FileDoneOutlined />}
          onClick={() => {
            setVisibleCreateDocModal(true);
            setCurrentCustomer(customer);
            getSignerCustomers(customer.id);
          }}
        >
          Оформить договор
        </Menu.Item>
        <Menu.Item
          disabled={demoAccessIsUsed}
          onClick={() => {
            handleOpenHistoryCallModal(customer);
          }}
          icon={<HistoryOutlined />}
        >
          История вызовов
        </Menu.Item>
        <Popconfirm
          title="Вы действительно хотите удалить запись?"
          onConfirm={() => deleteCustomer(customer.id)}
          onCancel={() => console.log('Delete item')}
          okText="Да"
          cancelText="Нет"
          placement="rightBottom"
        >
          <Menu.Item disabled={demoAccessIsUsed} icon={<DeleteOutlined />}>
            Удалить
          </Menu.Item>
        </Popconfirm>
      </Menu>
    );
  }
  const updateUserInfo = async (userInfoForCallApi) => {
    setLoading(true);
    const profileInfo = await callGetProfileInfo(userInfoForCallApi);
    const haveViewBranch =
      profileInfo?.data?.permissions.some(
        (o) => o.codename === 'switch_branch',
      ) &&
      profileInfo?.data?.permissions.some((o) => o.codename === 'view_branch');
    if (haveViewBranch) {
      profileInfo['haveViewBranch'] = true;
    }
    userInfo.current = profileInfo;
    const col = sortingColumnsTable(
      userInfo,
      tableName,
      columns,
      visibleFilters,
    );
    const preparedColumnsForResizing = prepareColumnsForResizing(
      col,
      handleResize,
    );
    setBaseColumns(preparedColumnsForResizing);
    setLoading(false);
  };

  const filteringColumnsChildrenPillarByVisible = useMemo(() => {
    const copyColumns = [...baseColumns];
    return filterActiveColumns(copyColumns);
  }, [baseColumns]);

  const mainTableWidth = useMemo(() => {
    const copyColumns = [...baseColumns];
    let filteredColumns = filterActiveColumns(copyColumns);
    return filteredColumns.reduce((n, { width }) => n + width, 0);
  }, [baseColumns]);

  const getMainTableWidth = useCallback(() => {
    return mainTableWidth;
  }, [mainTableWidth]);

  useEffect(() => {
    if (mainTableRef.current.children[0]) {
      changeWidthStyleMainTable(mainTableRef, mainTableWidth);
    }
  }, [mainTableWidth]);

  const handleResize = useCallback(
    (index) =>
      async (e, { size }) => {
        const copyColumns = [...baseColumnsRef.current];

        setBaseColumns((columns) => {
          const nextColumns = [...columns];
          const children = nextColumns[index]?.children
            ? // eslint-disable-next-line no-unsafe-optional-chaining
              [...nextColumns[index]?.children]
            : null;
          if (children && children[0]?.width) {
            children[0].width = size;
          }
          nextColumns[index] = {
            ...nextColumns[index],
            width: size,
            children: children ? [...children] : [],
          };
          return nextColumns;
        });

        const databaseColumnsId = copyColumns[index]?.idInDatabase;
        if (!databaseColumnsId) return;

        await editTableColumn(databaseColumnsId, { width: size }, userInfo);
        const res = await getProfileInfo(userInfo);
        if (res) {
          userInfo.current = res;
          const haveViewBranch = userInfoHaveViewAllBranch(res);
          if (haveViewBranch) {
            userInfo.current['haveViewBranch'] = true;
          }
        }
      },
    [baseColumnsRef.current],
  );

  const createAndSendContract = async (
    body,
    valueFormSendEmailModal,
    createdContractId,
  ) => {
    // Promise.all()
    try {
      if (!createdContractId) {
        await createContract(body).then(async (res) => {
          setCreatedContractId(res?.id ? res.id : null);
          await sendContractToCustomer(res?.id, valueFormSendEmailModal);
          //setVisibleCreateDocModal(false);
          setCreateContractModalDraggerFileList([]);
          setDisableDocBtn(true);
          //createDocForm.resetFields();
        });
      } else {
        await sendContractToCustomer(
          createdContractId,
          valueFormSendEmailModal,
        );
        setDisableDocBtn(true);
      }
    } catch (err) {
      console.log(err);
      message.error('Ошибка отправки договора на согласования');
    }
  };

  const createContract = async (body) => {
    try {
      const url = `${restApiHost}/customers/contracts/`;
      const json = await call(
        url,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json?.data) return;

      message.success('Договор успешно создан');
      return Promise.resolve(json?.data);
    } catch (e) {
      console.log(e);
      return Promise.reject('Ошибка запроса на создание договора!');
    }
  };

  const editContract = async (contractId, body) => {
    try {
      const url = `${restApiHost}/customers/contracts/${contractId}/`;
      const json = await call(
        url,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      message.success('Договор отредатирован!');
    } catch (e) {
      console.log('edit contract err', e);
      message.error('Ошибка запроса на редактирование договора');
    }
  };

  const getStaffList = async () => {
    setLoadingStaffList(true);
    try {
      const url = `${restApiHost}/accounts/staff_users/`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (json?.data) {
        setStaffList(json?.data);
      }
    } catch (e) {
      console.log('fetch profile info error', e);
    } finally {
      setLoadingStaffList(false);
    }
  };

  const searchStaff = async (keyword) => {
    try {
      setLoadingStaffList(true);
      const url = `${restApiHost}/accounts/staff_users/?search=${keyword}`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (json?.data) {
        setStaffList(json?.data);
      }
    } catch (e) {
      console.log(e);
      message.error('Ошибка запроса! Не удалось найти лида');
    } finally {
      setLoadingStaffList(false);
    }
  };

  const getCustomerType = (type) => {
    switch (type) {
      case 'ipr':
        return 'ИП';
      case 'yul':
        return 'Юридическое лицо';
      case 'phl':
        return 'Физическое лицо';
      case 'ino':
        return 'Иностранная компания';
      default:
        return 'Не определен';
    }
  };

  const getCompanyType = (type) => {
    switch (type) {
      case 'avt':
        return 'Автоперевозчик';
      case 'cli':
        return 'Клиент';
      case 'skl':
        return 'Контрагент на складе';
      case 'sts':
        return 'Собственник ТС';
      default:
        return 'Не определен';
    }
  };

  const sendContractToCustomer = async (
    contractId,
    valueFormSendEmailModal,
  ) => {
    try {
      const body = {
        to: valueFormSendEmailModal?.email
          ? valueFormSendEmailModal.email
          : null,
        cc: valueFormSendEmailModal?.cc ? valueFormSendEmailModal?.cc : null,
        bcc: valueFormSendEmailModal?.bcc ? valueFormSendEmailModal?.bcc : null,
        contract: contractId ? contractId : null,
        subject: valueFormSendEmailModal?.subject
          ? valueFormSendEmailModal.subject
          : null,
        files: valueFormSendEmailModal?.selectedFiles
          ? valueFormSendEmailModal?.selectedFiles
          : null,
        html: valueFormSendEmailModal?.text_content
          ? valueFormSendEmailModal?.text_content
          : null,
      };
      await sendContractOnApproval(body, userInfo).then(async (res) => {
        if (res.status === 200 || res.status === 201) {
          setVisibleSendEmailModalInCreateContractModal(false);
          setVisibleCreateDocModal(false);
          setDisableDocBtn(true);
          createDocForm.resetFields();
          if (!customerIsStock(currentCustomer)) {
            setCurrentStepByUpdateCustomer(8);
            setVisibleEditModal(true);
          }
        }
      });
    } catch (e) {
      console.log(e);
      console.error('Ошибка отправки договора на согласования');
    }
  };

  const handleTableChange = useCallback(
    (pagination, filtersTable, sorter) => {
      const { page, filters } = getFiltersTable(
        pagination,
        filtersTable,
        sorter,
      );
      setColumnFiltersFromTableComponent(filtersTable);
      setPage(page);
      let newFilters = { ...filters };
      if (currStatusFilter?.filter?.search) {
        newFilters = {
          ...filters,
          filter: { ...filters.filter, search: currStatusFilter.filter.search },
        };
      }
      setParams(newFilters);
      handleUpdate(page, newFilters);
    },
    [handleUpdate, setParams, setColumnFiltersFromTableComponent, setPage],
  );

  //history calls
  const getCustomerCallsHistory = async (id, page = 1) => {
    try {
      const url = `${restApiHost}/telephony/customer_calls/${id}?page=${page}`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      setHistoryCallsData(json);
    } catch (e) {
      console.log('get history calls err', e);
      setHistoryCallsData(null);
      message.error('Ошибка получения истории вызовов');
    }
  };

  const handleOpenHistoryCallModal = async (customer) => {
    if (customer?.id || currentCustomer?.id) {
      await getCustomerCallsHistory(
        customer.id || currentCustomer.id,
        1,
        customer || currentCustomer,
      );
    } else {
      message.warning('Непредвиденная ошибка');
      return;
    }
    setCurrentCustomer(customer);
    setHistoryCallsModalOpen(true);
  };

  const handleCloseHistoryCallModal = () => {
    setCurrentCustomer(null);
    setHistoryCallsData(null);
    setHistoryCallsModalOpen(false);
  };
  const handleSendKpApplication = async (application) => {
    setLoading(true);
    const hide = message.loading('Поиск клиента', 0);
    if (application?.customer) {
      await getCustomer(application.customer.id);
    }
    setVisibleSentKpModal(true);
    hide();
    setLoading(false);
  };
  const refreshHistoryCalls = async () => {
    currentCustomer?.id
      ? await getCustomerCallsHistory(currentCustomer.id, 1, currentCustomer)
      : message.warning('Что-то пошло не так');
  };
  const setSavingSum = () => {
    if (visitSumsClient) {
      setSumsWereEdited(false);
      columnFiltersFromTableComponentRef.current &&
        setColumnFiltersFromTableComponent([]);
      isMobile && handleScrollToTopMobileList();
      handleUpdate(isMobile ? 1 : pageRef.current, paramsRef.current, true);
      setVisiblePreApplicationModal(false);
      setCurrentCustomer(null);
      setDisableBtnAtSaveSums(false);
      setVisitSumsClient(false);
      savingSum.current = false;
    }
  };
  const setVisitSums = () => {
    setVisitSumsClient(true);
  };
  const saveSumsWhenSwitching = () => {
    savingSumWereEditing.current = true;
    setDisableBtnAtSaveSums(true);
  };
  const endSavingSumsWhenSwitching = () => {
    setSumsWereEdited(false);
    setDisableBtnAtSaveSums(false);
    setVisitSumsClient(false);
    savingSumWereEditing.current = false;
  };

  const previewContract = async () => {
    await createDocForm
      .validateFields()
      .then(async (values) => {
        if (createContractModalDraggerFileList[0]?.originFileObj) {
          await contractGeneration(
            values,
            signerManager,
            createContractModalDraggerFileList[0]?.originFileObj,
          );
        } else {
          await contractGeneration(values, signerManager);
        }
      })
      .catch((error) => {
        console.log(error);
        message.warning('Не все поля заполнены');
      });
  };

  const contractGeneration = async (
    contractData,
    signerManager,
    selectedFile,
  ) => {
    const singerMangerCompanyId = signerManager?.customer;
    let contractTemplates = [];
    const context = JSON.stringify({
      number: contractData?.code_contract ? contractData.code_contract : null,
      date_beg: contractData?.date_begin
        ? dayjs(contractData.date_begin).format('YYYY-MM-DD')
        : null,
      date_end: contractData?.date_end
        ? dayjs(contractData.date_end).format('YYYY-MM-DD')
        : null,
    });
    if (!singerMangerCompanyId) {
      message.error('Подписант с "Нашей строны" не подвязан к компании');
    }
    const hide = message.loading(
      selectedFile
        ? 'Формирование договора исходя из выбранного файла'
        : 'Формирование договора',
      5,
    );
    if (!selectedFile) {
      contractTemplates = await getTempletesConstract(
        singerMangerCompanyId,
        contractData?.type,
        userInfo,
      );
      if (!contractTemplates?.length > 0) {
        setDisableDocBtn(true);
        message.error('Шаблон договора не найден');
        hide();
        return;
      }
    }
    const pdfAndDocxContractFiles =
      await getGeneratedContractPdfAndDocxByTemplateId(
        contractData?.signer_customer,
        contractData?.signer_manager,
        contractTemplates[0]?.id,
        selectedFile,
        context,
        userInfo,
      );
    if (pdfAndDocxContractFiles?.pdf && pdfAndDocxContractFiles?.docx) {
      setContractPdfUrl(pdfAndDocxContractFiles?.pdf);
      setContractDocxUrl(pdfAndDocxContractFiles?.docx);
      setDisableDocBtn(false);
    }

    hide();
    setVisibleContractShowPdf(true);
  };

  const openModalAddContactCustomer = () => {
    setVisibleEditModal(true);
    //customerIsStock(currentCustomer) ? setCurrentStepByUpdateCustomer(0) : setCurrentStepByUpdateCustomer(1)
    setCurrentStepByUpdateCustomer(1);
    setUsingUpdateCustomerModalWhenGeneratingContract(true);
  };

  const downloadDocxContract = async (contractDocxUrl) => {
    let isValid = false;
    let contractCode = null;
    await createDocForm
      .validateFields()
      .then(async (values) => {
        isValid = true;
        contractCode = values?.code_contract ? values.code_contract : null;
      })
      .catch((errors) => {
        console.log(errors);
        message.error('Не все поля заполнены');
        if (errors?.errorFields?.length > 0) {
          message.warning('Не все поля заполнены');
          return;
        }
      });
    if (isValid && contractDocxUrl) {
      try {
        setDisableDocBtn(true);
        await message.info('Началось формирование docx файла', 1);
        await exportFileFormUrl(
          contractDocxUrl,
          `Договор ${contractCode}.docx`,
        );
        message.success(`Файл Договор ${contractCode}.docx успешно скачан`);
        setDisableDocBtn(false);
      } catch (e) {
        console.log(e);
        message.error('Ошибка формирования docx файла');
      }
    }
  };

  return (
    <>
      {/* Таблица с клиентами */}
      <ConfigProvider locale={locale}>
        <div ref={mainTableRef}>
          {!isMobile ? (
            <Table
              rowClassName={(record, index) =>
                index % 2 === 0
                  ? 'black-text table-row-color'
                  : 'black-text table-row-white'
              }
              onRow={(record) => {
                return {
                  onDoubleClick: () => {
                    setCurrentCustomer(record);
                    setVisibleEditModal(true);
                  },
                };
              }}
              components={{
                header: {
                  cell: ResizableTableColumnHeader,
                },
              }}
              scroll={{ x: getMainTableWidth() }}
              sticky={true}
              bordered={true}
              loading={loading}
              dataSource={customers}
              columns={filteringColumnsChildrenPillarByVisible}
              size="small"
              pagination={setPaginationSettings(total, pageRef.current)}
              onChange={(val) => {
                handleTableChange(
                  { current: val?.current },
                  columnFiltersFromTableComponentRef.current,
                );
              }}
            />
          ) : (
            <>
              <ErrorBoundary>
                <ScrollingLoadedList
                  dataList={customers}
                  totalData={total}
                  loadMoreItems={async () => {
                    await handleUpdate(page + 1, params, false, true);
                  }}
                  scrollingLoadedListRef={scrollingLoadedListRef}
                  renderListItem={(item) => (
                    <CustomerListItem
                      customer={item}
                      menuActions={(customer) => menu(customer)}
                    />
                  )}
                />
              </ErrorBoundary>
            </>
          )}
        </div>
      </ConfigProvider>

      {/*    send docs to email
            <ErrorBoundary>
                <SendDocModal
                    handleUpdate={() => handleUpdate(page, params)}
                    typeChoices={typeChoices}
                    customer={currentCustomer}
                    visible={visibleSendDocModal}
                    onClose={() => {
                        setCurrentCustomer(null);
                    }}
                />
            </ErrorBoundary>*/}

      <ErrorBoundary>
        <UniversalFiltersModal
          visibleModal={visibleFilters && isMobile}
          handleClose={() => {
            setVisibleFilters(false);
          }}
          resetFilters={() => {
            setVisibleFilters(false);
            handleUpdate(1, null, false, false);
          }}
          columnsList={columns}
        />
      </ErrorBoundary>

      {/*pre application modal*/}
      <ErrorBoundary>
        <PreApplicationModal
          updateData={(data) => setTaskData(data)}
          data={taskData}
          customer={currentCustomer}
          visible={visiblePreApplicationModal}
          handleUpdate={() => {
            columnFiltersFromTableComponentRef.current &&
              setColumnFiltersFromTableComponent([]);
            isMobile && handleScrollToTopMobileList();
            handleUpdate(pageRef.current, paramsRef.current, true);
          }}
          handleOk={() => {
            setVisiblePreApplicationModal(false);
            setCurrentCustomer(null);
            columnFiltersFromTableComponentRef.current &&
              setColumnFiltersFromTableComponent([]);
            isMobile && handleScrollToTopMobileList();
            handleUpdate(pageRef.current, paramsRef.current, true);
          }}
          handleCancel={() => {
            if (visitSumsClient && sumsWereEdited) {
              savingSum.current = true;
              setDisableBtnAtSaveSums(true);
            } else {
              columnFiltersFromTableComponentRef.current &&
                setColumnFiltersFromTableComponent([]);
              isMobile && handleScrollToTopMobileList();
              handleUpdate(pageRef.current, paramsRef.current, true);
              setTaskData(null);
              setVisiblePreApplicationModal(false);
              setCurrentCustomer(null);
            }
          }}
          handleOpenSendKpModal={async (application) => {
            await handleSendKpApplication(application);
          }}
          savingSum={savingSum.current}
          savingSumWereEditing={savingSumWereEditing.current}
          setSavingSum={setSavingSum}
          setVisitSums={setVisitSums}
          saveSumsWhenSwitching={() => saveSumsWhenSwitching()}
          changeSumsWereEdited={(sumEditing) => setSumsWereEdited(sumEditing)}
          sumsWereEdited={sumsWereEdited}
          endSavingSumsWhenSwitching={() => endSavingSumsWhenSwitching()}
          disableBtnAtSaveSums={disableBtnAtSaveSums}
          renderInOutPagePreTask={false}
        />
      </ErrorBoundary>

      {/*history calls modal*/}
      <ErrorBoundary>
        <HistoryCallCustomerModal
          data={historyCallsData}
          customer={currentCustomer}
          refreshData={refreshHistoryCalls}
          open={historyCallsModalOpen}
          getData={getCustomerCallsHistory}
          handleClose={handleCloseHistoryCallModal}
          handleOpen={handleOpenHistoryCallModal}
        />
      </ErrorBoundary>

      {/* Редактирование клиента */}
      <ErrorBoundary>
        <UpdateCustomerModal
          onlyUsedToAddSigner={usingUpdateCustomerModalWhenGeneratingContract}
          visible={visibleEditModal}
          getDataCustomer={async (customerId) => await getCustomer(customerId)}
          handleOk={async () => {
            if (usingUpdateCustomerModalWhenGeneratingContract) {
              await getSignerCustomers(currentCustomer?.id);
              setUsingUpdateCustomerModalWhenGeneratingContract(false);
            } else {
              isMobile && handleScrollToTopMobileList();
              handleUpdate(isMobile ? 1 : pageRef.current, paramsRef.current);
            }
            setCurrentStepByUpdateCustomer(0);
            setDefaultOpenContractStep(false);
            setVisibleEditModal(false);
            if (modalCustomer) {
              navigate(location?.pathname, { replace: true });
            }
          }}
          handleCancel={async () => {
            if (usingUpdateCustomerModalWhenGeneratingContract) {
              await getSignerCustomers(currentCustomer?.id);
              setUsingUpdateCustomerModalWhenGeneratingContract(false);
            } else {
              isMobile && handleScrollToTopMobileList();
              handleUpdate(isMobile ? 1 : pageRef.current, paramsRef.current);
            }
            setCurrentStepByUpdateCustomer(0);
            setDefaultOpenContractStep(false);
            setVisibleEditModal(false);
            if (modalCustomer) {
              navigate(location?.pathname, { replace: true });
            }
          }}
          currentStep={currentStepByUpdateCustomer}
          defaultOpenContractStep={defaultOpenContractStep}
          setDefaultOpenContractStep={(val) => setDefaultOpenContractStep(val)}
          loading={false}
          data={currentCustomer}
        />
      </ErrorBoundary>

      {/* Связка с дочерней компанией */}

      <Modal
        visible={visibleJoinGroupModal}
        title="Входит в группу компаний"
        onCancel={() => {
          setVisibleJoinGroupModal(false);
          joinComanyForm.resetFields();
          setCurrSubsidiaryList([]);
          handleUpdate(pageRef.current, paramsRef.current);
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setVisibleJoinGroupModal(false);
              joinComanyForm.resetFields();
              setCurrSubsidiaryList([]);
              handleUpdate(pageRef.current, paramsRef.current);
            }}
          >
            Закрыть
          </Button>,
        ]}
      >
        <>
          <Form form={joinComanyForm} layout="vertical">
            <Row gutter={16}>
              <Col xs={24} lg={24}>
                <Form.Item label={'Компания'} name={'subsidiary'}>
                  <Select
                    getPopupContainer={(trigger) =>
                      isMobile ? trigger.parentNode : document.body
                    }
                    placeholder="Начните поиск или выберите из списка"
                    // mode="multiple"
                    showSearch
                    filterOption={false}
                    onSearch={(val) => {
                      if (val?.length > 0) {
                        searchCustomers(val);
                      } else {
                        let filteredOptions = joinGroupOptions.filter(
                          (item) => item.id !== currentCustomer.id,
                        );

                        const selectedSubsidiary =
                          currSubsidiaryList?.length > 0 &&
                          currSubsidiaryList.map((item) => item.subsidiary.id);

                        selectedSubsidiary?.length > 0 &&
                          selectedSubsidiary.map((value) => {
                            filteredOptions = filteredOptions.filter(
                              (item) => item.id !== value,
                            );
                          });
                        setJoinGroupOptions(filteredOptions);
                      }
                    }}
                    onChange={(value) => {
                      addSubsidiary({
                        parent: currentCustomer.id,
                        subsidiary: value,
                      });
                      let newGroupOptions = joinGroupOptions.filter(
                        (item) => item.id !== value,
                      );

                      setJoinGroupOptions(newGroupOptions);

                      joinComanyForm.setFieldsValue({
                        subsidiary: null,
                      });
                    }}
                  >
                    {joinGroupOptions && joinGroupOptions?.length > 0 ? (
                      <>
                        {joinGroupOptions.map((customer) => {
                          return (
                            <Option
                              key={customer.id}
                              data-value={JSON.stringify(customer)}
                              value={customer.id}
                            >
                              {customer.short_name
                                ? customer.short_name
                                : customer.legal_name
                                ? customer.legal_name
                                : customer.email}
                            </Option>
                          );
                        })}
                      </>
                    ) : (
                      <Option disabled>Нет доступных вариантов</Option>
                    )}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <h4 style={{ marign: '20px 0' }}>Список групп компаний</h4>
          <List
            className="demo-loadmore-list"
            itemLayout="horizontal"
            dataSource={currSubsidiaryList}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <a
                    key="list-delete"
                    onClick={(e) => {
                      e.preventDefault();
                      deleteSubsidiary(item.id);
                    }}
                  >
                    Удалить
                  </a>,
                ]}
              >
                <List.Item.Meta
                  avatar={<Avatar icon={<UserOutlined />} />}
                  title={
                    item.subsidiary.legal_name
                      ? item.subsidiary.legal_name
                      : item.subsidiary.short_name
                      ? item.subsidiary.short_name
                      : item.subsidiary.email
                  }
                  description={
                    <div>
                      <div>
                        <b>Полное наименование: </b>{' '}
                        {item.subsidiary.legal_name}
                      </div>
                      <div>
                        <b>Сокращенное наименование: </b>{' '}
                        {item.subsidiary.short_name}
                      </div>
                      <div>
                        <b>Email: </b> {item.subsidiary.email}
                      </div>
                      <div>
                        <b>Телефон: </b>{' '}
                        {item.subsidiary.phone_numbers?.length > 0
                          ? item.subsidiary.phone_numbers[0].phone_number
                          : 'Не указан'}
                      </div>
                    </div>
                  }
                />
              </List.Item>
            )}
          />
        </>
      </Modal>
      {/*Отправка КП/Презентации*/}
      <ErrorBoundary>
        <SendKpOrPresentationModal
          visibleModal={visibleSentKpModal}
          preTask={currentCustomer?.pre_tasks}
          customer={currentCustomer}
          staffList={staffList}
          closeVisibleModal={closeVisibleModal}
          handleUpdate={() => {
            isMobile && handleScrollToTopMobileList();
            handleUpdate(page ? 1 : pageRef.current, paramsRef.current);
          }}
          updateModelData={getCustomer}
          page={page}
          sendFromLeadPage={false}
          loading={loadingCustomers}
        />
      </ErrorBoundary>

      {/* Модальное окно оформление договора */}
      <ErrorBoundary>
        <CreateContractModal
          visible={visibleCreateDocModal}
          onCancel={() => {
            setVisibleCreateDocModal(false);
            setContractPdfUrl(null);
            setContractDocxUrl(null);
            setCreateContractModalDraggerFileList([]);
            setDisableDocBtn(true);
            createDocForm.resetFields();
          }}
          previewContract={async () => {
            await previewContract();
          }}
          filesUrl={{
            docxUrl: contractDocxUrl
              ? `${restApiHost}${contractDocxUrl}`
              : null,
            pdfUrl: contractPdfUrl ? `${restApiHost}${contractPdfUrl}` : null,
          }}
          draggerFileList={createContractModalDraggerFileList}
          changeDraggerFileList={(fileList) =>
            setCreateContractModalDraggerFileList(fileList)
          }
          disabled={disableDocBtn}
          disableDateBeginAndNumberAndStatus={false}
          isAdmin={isAdmin}
          useLocks={false}
          downloadDoc={async () => {
            if (contractDocxUrl) {
              await downloadDocxContract(contractDocxUrl);
            }
          }}
          loading={pdfSendLoading}
          handleUpdateAfterCloseModalSend={false}
          visibleSendEmailModal={visibleSendEmailModalInCreateContractModal}
          setVisibleSendEmailModal={(val) =>
            setVisibleSendEmailModalInCreateContractModal(val)
          }
          sendForApproval={async (valueFormSendEmailModal) => {
            if (!createdContractId) {
              createDocForm.validateFields().then(async (values) => {
                let data = {
                  ...values,
                  postponement_condition: values.postponement_condition
                    ? values.postponement_condition
                    : 1,
                  customer: currentCustomer?.id,
                };
                delete data.attachment;
                try {
                  setPdfSendLoading(true);
                  setDisableDocBtn(true);
                  await createAndSendContract(
                    data,
                    valueFormSendEmailModal,
                    null,
                  );
                  setPdfSendLoading(false);
                } catch (e) {
                  console.log(e);
                  message.error('Ошибка отправки договора на согласование');
                }
              });
            } else {
              try {
                setPdfSendLoading(true);
                setDisableDocBtn(true);
                await createAndSendContract(
                  null,
                  valueFormSendEmailModal,
                  createdContractId,
                );
                setPdfSendLoading(false);
                setDisableDocBtn(false);
              } catch (e) {
                console.log(e);
                message.error('Ошибка отправки договора на согласование');
              }
            }
          }}
          saveContract={() => {
            createDocForm.validateFields().then(async (values) => {
              let data = {
                ...values,
                date_begin: values?.date_begin
                  ? dayjs(values?.date_begin).format('YYYY-MM-DD')
                  : null,
                postponement_condition: values.postponement_condition
                  ? values.postponement_condition
                  : 1,
                customer: currentCustomer?.id,
              };
              try {
                if (createdContractId !== null) {
                  await editContract(createdContractId, data);
                } else {
                  await createContract(data);
                }
                setVisibleCreateDocModal(false);
                setDisableDocBtn(true);
                createDocForm.resetFields();
                if (!customerIsStock(currentCustomer)) {
                  setCurrentStepByUpdateCustomer(8);
                  setDefaultOpenContractStep(true);
                  setVisibleEditModal(true);
                }
              } catch (e) {
                console.log(e);
                message.error('Ошибка создания договора');
              }
            });
          }}
          form={createDocForm}
          formOnValuesChange={() => {
            setTimeout(() => {
              setDisableDocBtn(true);
            }, 0);
          }}
          uploadedDoc={uploadedDoc}
          typeChoices={typeChoices}
          callbackfn={(item) => {
            return (
              <Option key={item[0]} value={item[0]}>
                {item[1]}
              </Option>
            );
          }}
          onChangeSignerManager={(value, data) =>
            setSignerManager(JSON.parse(data['data-value']))
          }
          signerManagers={signerManagers}
          onChangeSignerCustomer={async (value) => {
            if (value === 'addContact') {
              createDocForm.setFieldsValue({
                signer_customer: null,
              });
              openModalAddContactCustomer();
            }
          }}
          signerCustomers={signerCustomers}
          allClients={allClients}
          onChangeAttachment={(e) => {
            setUploadedDoc(e.target.files[0]);
            createDocForm.setFieldsValue({ status: 'con' });
          }}
          attach={null}
          onChangeIsOriginalDocContract={(e) => {
            if (e.target.checked && uploadedDoc) {
              createDocForm.setFieldsValue({ status: 'sig' });
            } else if (!e.target.checked && uploadedDoc) {
              createDocForm.setFieldsValue({ status: 'con' });
            } else {
              createDocForm.setFieldsValue({ status: 'new' });
            }
          }}
        />
      </ErrorBoundary>

      {/*pre show pdf contarct*/}
      {contractPdfUrl && (
        <ErrorBoundary>
          <PdfViewerModal
            visible={visibleContractShowPdf}
            setVisible={(visible) => setVisibleContractShowPdf(visible)}
            pdfFileUrl={`${restApiHost}${contractPdfUrl}`}
          />
        </ErrorBoundary>
      )}

      {/*history task modal*/}
      <Modal
        width={1000}
        title={`История клиента ${currentCustomer?.id}`}
        visible={visibleHistoryModal}
        onCancel={() => {
          setVisibleHistoryModal(false);
        }}
        footer={[
          <Button
            key={'close'}
            onClick={() => {
              setVisibleHistoryModal(false);
            }}
          >
            Закрыть
          </Button>,
        ]}
      >
        <Col lg={24}>
          <ErrorBoundary>
            <CustomerChangeHistory customerId={currentCustomer?.id || null} />
          </ErrorBoundary>
        </Col>
      </Modal>

      {/* Просмотр информации по клиенту */}

      <Drawer
        width={640}
        placement="right"
        closable={false}
        onClose={() => setShowCustomerInfo(false)}
        visible={showCustomerInfo}
      >
        <h3
          className="site-description-item-profile-p"
          style={{ marginBottom: 24 }}
        >
          Просмотр информации
        </h3>
        <b className="site-description-item-profile-p">Основная информация</b>
        <Row>
          <Col span={12}>
            <DescriptionItem
              title="Юр. имя"
              content={`${
                currentCustomer ? currentCustomer.legal_name : '...'
              }`}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title="Юр. адрес"
              content={`${currentCustomer ? currentCustomer.address : '...'}`}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title="Тип компании"
              content={`${
                currentCustomer
                  ? getCompanyType(currentCustomer.company_type)
                  : '...'
              }`}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title="Тип клиента"
              content={`${
                currentCustomer
                  ? getCustomerType(currentCustomer.customer_type)
                  : '...'
              }`}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title="Источник"
              content={`${currentCustomer ? currentCustomer.source : '...'}`}
            />
          </Col>
          <Col span={24}>
            <DescriptionItem
              title="Комментарий"
              content={`${currentCustomer ? currentCustomer.comment : '...'}`}
            />
          </Col>
        </Row>
        <Divider />
        <b className="site-description-item-profile-p">Контакты</b>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="Контактное лицо"
              content={`${
                currentCustomer ? currentCustomer.contact_person : '...'
              }`}
            />
          </Col>
          <Col span={24}>
            <DescriptionItem
              title="Email"
              content={
                currentCustomer && currentCustomer.email ? (
                  <a href={`mailto:${currentCustomer.email}`}>
                    {currentCustomer.email}
                  </a>
                ) : (
                  <>...</>
                )
              }
            />
          </Col>
          {currentCustomer &&
          currentCustomer.phone_numbers &&
          currentCustomer.phone_numbers?.length > 0
            ? currentCustomer.phone_numbers.map((phone, idx) => {
                return (
                  <Col span={24} key={phone.phone_number}>
                    <DescriptionItem
                      title={`Контактный телефон ${idx + 1}`}
                      content={
                        phone && phone.phone_number ? (
                          <a
                            href={`tel:${phone.phone_number}`}
                            onClick={(e) => {
                              e.preventDefault();
                              setVisibleCall(true);
                            }}
                          >
                            {phone.phone_number}
                          </a>
                        ) : (
                          <>...</>
                        )
                      }
                    />
                  </Col>
                );
              })
            : null}
        </Row>
        <ErrorBoundary>
          <IncomingCall
            setVisible={(value) => setVisibleCall(value)}
            visible={visibleCall}
            user={currentCustomer}
          />
        </ErrorBoundary>
      </Drawer>

      <ErrorBoundary>
        <SettingTableColumnsDrawer
          visible={visibleTableSetting}
          loading={false}
          columns={baseColumns}
          onCloseDrawer={async () => {
            onCloseDrawerTableSetting();
            await updateUserInfo(userInfo);
          }}
        />
      </ErrorBoundary>
    </>
  );
};

export default CustomersTable;
