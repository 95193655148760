import dayjs from 'dayjs';
import moment from 'moment';
dayjs.locale('ru');
moment.locale('ru');

export function getMonth(month = dayjs().month(), year = dayjs().year()) {
  month = Math.floor(month);
  const firstDayOfTheMonth = dayjs(new Date(year, month, 0)).day();
  let currentMonthCount = 0 - firstDayOfTheMonth;
  return new Array(5).fill([]).map(() => {
    return new Array(7).fill(null).map(() => {
      currentMonthCount++;
      return dayjs(new Date(year, month, currentMonthCount));
    });
  });
}
