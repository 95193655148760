export function convertObjToUrlSearchQuery(paramsObj) {
  if (paramsObj?.length <= 0) return;
  const url = new URLSearchParams();
  for (const param in paramsObj) {
    if (Object.prototype.hasOwnProperty.call(paramsObj, param)) {
      url.set(`${param}`, `${paramsObj[param]}`);
    }
  }
  return url;
}
