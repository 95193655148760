import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const useMousePosition = () => {
  const [mousePosition, setMousePosition] = useState({
    x: 0,
    y: 0,
  });
  useEffect(() => {
    const updateMousePosition = (ev) => {
      setMousePosition({ x: ev.clientX, y: ev.clientY });
    };
    window.addEventListener('mousemove', updateMousePosition);
    return () => {
      window.removeEventListener('mousemove', updateMousePosition);
    };
  }, []);
  return mousePosition;
};

const SafeAreaMenu = (props) => {
  const { x: mouseX, y: mouseY } = useMousePosition();

  const {
    // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
    width: submenuWidth,
    height: submenuHeight,
    x: submenuX,
    y: submenuY,
  } = props.submenu.getBoundingClientRect();

  const svgWidth = submenuX - mouseX + 4;

  return (
    <>
      <svg
        style={{
          position: 'fixed',
          width: svgWidth,
          height: '100vh',
          pointerEvents: 'none',
          zIndex: 999999,
          opacity: '0',
          top: submenuY,
          left: mouseX - 1,
        }}
      >
        <path
          pointerEvents="auto"
          d={`M 0, ${
            mouseY - submenuY
          } L ${svgWidth},${submenuHeight}L ${svgWidth},0 z`}
        ></path>
      </svg>
    </>
  );
};

SafeAreaMenu.propTypes = {
  anchor: PropTypes.any,
  submenu: PropTypes.any,
};

export default SafeAreaMenu;
