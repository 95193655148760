import { restApiHost } from '../../../utils/appVariables';
import { call } from '../../../apiUtils/call';
import { message } from 'antd';

export async function createAttachmentFunction(body, userInfo) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const url = `${restApiHost}/customers/contract_attachments/`;
      const formData = new FormData();
      formData.append('contract', body?.contract);
      formData.append('customer', body?.customer);
      formData.append('type_attachment', body?.type_attachment);
      if (body?.file.length > 0) {
        body?.file.map((file) => {
          if (file?.originFileObj) {
            formData.append(`file`, file.originFileObj, file.name);
          }
        });
      }
      const json = await call(
        url,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
          body: formData,
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      message.success('Вложение успешно добавлено');
      resolve(true);
    } catch (e) {
      reject(false);
      console.log(e);
      message.error('Ошибка запроса на добавление вложения');
    }
  });
}
