import { restApiHost } from '../../utils/appVariables';
import { call } from '../../apiUtils/call';
import { message } from 'antd';

export async function getCpTemplates(userInfo) {
  try {
    const url = `${restApiHost}/refbook/comm_prop_template/?active=true`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (json?.data) {
      return json.data;
    }
  } catch (e) {
    message.error('Ошибка получения шаблонов КП');
    console.log('get CP templates err', e);
  }
}

export async function getCpTemplateById(id, userInfo) {
  try {
    const url = `${restApiHost}/refbook/comm_prop_template/${id}/`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      },
      userInfo.current.haveViewBranch,
    );
    if (json) {
      return json;
    }
  } catch (e) {
    message.error('Ошибка получения шаблона КП');
    console.log('get CP templates err', e);
  }
}
