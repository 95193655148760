import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  Empty,
  Form,
  message,
  Modal,
  Row,
  Select,
  Table,
  Tabs,
} from 'antd';
import { restApiHost } from '../../utils/appVariables';
import AreaChartOutlined from '@ant-design/icons/lib/icons/AreaChartOutlined';
import DownloadOutlined from '@ant-design/icons/lib/icons/DownloadOutlined';
import { userInformations } from '../../contex';
import { getBranchId } from '../../localStorageUtils/getBranchId';
import { call } from '../../apiUtils/call';

const { Option } = Select;
const { TabPane } = Tabs;

const ExcelStatModal = ({ visible, onClose }) => {
  const [form] = Form.useForm();
  const [statData, setStatData] = useState([]);
  const [file, setFile] = useState(null);
  const [staffList, setStaffList] = useState({ data: [], count: 0 });
  const [loadingStaffList, setLoadingStaffList] = useState(false);
  const { userInfo } = useContext(userInformations);

  const currDate = new Intl.DateTimeFormat('ru-RU')
    .format(Date.now())
    .split('.')
    .reverse()
    .join('-');

  useEffect(() => {
    getStaffList();
  }, []);

  const searchStaff = async (keyword) => {
    try {
      const url = `${restApiHost}/accounts/staff_users/?search=${keyword}`;
      const json = await call(url, {
        method: 'POST',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      });

      if (!json) return;
      // eslint-disable-next-line no-unsafe-optional-chaining
      setStaffList({ data: [...json?.data], count: json?.count });
    } catch (e) {
      console.log(e);
      message.error('Ошибка запроса! Не удалось найти лида');
    }
  };

  const getStaffList = async (page = 1) => {
    setLoadingStaffList(true);
    try {
      const url = `${restApiHost}/accounts/staff_users/?page=${page}${
        userInfo.current.haveViewBranch ? '&' + 'branch=' + getBranchId() : ''
      }`;
      const res = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      });
      const json = await res.json();

      if (res.status == 403) {
        message.warning(
          'У вас недостаточно прав для взаимодействия с сотрудниками',
        );
        setLoadingStaffList(false);
        return;
      }

      setStaffList({ data: json?.data, count: json?.count });
      setLoadingStaffList(false);
    } catch (e) {
      console.log('fetch profile info error', e);
      setLoadingStaffList(false);
    }
  };

  const getStats = async (params) => {
    if (!params) {
      message.error(
        'Для получения статистики необходимы диапазон дат и список пользователей',
      );
      return;
    }

    try {
      const url = `${restApiHost}/stats/calls/${params}`;
      const res = await fetch(url, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });

      if (res.status === 403) {
        message.warning(
          'У вас недостаточно прав для просмотра статистики по звонкам!',
        );
        return;
      }

      const json = await res.json();

      if (json.error) {
        message.error(json.error, 5);
        return;
      }

      let sheetsData = json.sheets ? Object.entries(json.sheets) : [];

      if (sheetsData.length > 0) {
        sheetsData = sheetsData.map((item) => {
          return {
            tabTitle: item[0],
            data: groupStatData(item[1]),
          };
        });
      } else {
        message.warning(
          'По выбранным параметрам нет данных для формирования статистики!',
        );
      }

      setStatData(sheetsData);
      setFile(json.file);
    } catch (e) {
      console.log(e);
      message.error('Ошибка запроса на получение статистики по звонкам!');
    }
  };

  const previewStat = () => {
    form.validateFields().then((values) => {
      let params = '';
      if (values.direction !== 'all') {
        params = `?start_date=${values.start_date}&end_date=${
          values.end_date
        }&users=${values.users.join(',')}&direction=${
          values.direction
        }&positive=${values.positive}&overall=${values.overall}&preview=true`;
      } else {
        params = `?start_date=${values.start_date}&end_date=${
          values.end_date
        }&users=${values.users.join(',')}&positive=${values.positive}&overall=${
          values.overall
        }&preview=true`;
      }
      getStats(params);
    });
  };

  const getFileAndStat = () => {
    form.validateFields().then((values) => {
      let params = '';
      if (values.direction !== 'all') {
        params = `?start_date=${values.start_date}&end_date=${
          values.end_date
        }&users=${values.users.join(',')}&direction=${
          values.direction
        }&positive=${values.positive}&overall=${values.overall}&preview=false`;
      } else {
        params = `?start_date=${values.start_date}&end_date=${
          values.end_date
        }&users=${values.users.join(',')}&positive=${values.positive}&overall=${
          values.overall
        }&preview=false`;
      }
      getStats(params);
    });
  };

  const groupStatData = (data) => {
    let newData = [];
    if (data?.day?.length > 0) {
      for (let i = 0; i < data.day.length; i++) {
        newData.push({
          day: data.day[i],
          new: data.new[i],
          repeat: data.repeat[i],
          total: data.total[i],
        });
      }
    }
    return newData;
  };

  const handleCancel = () => {
    onClose && onClose();
    form.resetFields();
    setFile(null);
    setStatData([]);
  };

  const columns = [
    {
      title: 'Дата',
      key: 'day',
      dataIndex: 'day',
    },
    {
      title: 'Всего',
      key: 'total',
      dataIndex: 'total',
    },
    {
      title: 'Новых',
      key: 'new',
      dataIndex: 'new',
    },
    {
      title: 'Повторных',
      key: 'repeat',
      dataIndex: 'repeat',
    },
  ];

  return (
    <Modal
      title={'Статистика звонков'}
      visible={visible}
      onCancel={handleCancel}
      width={1000}
      footer={[]}
    >
      <Row gutter={15}>
        <Col lg={8} xs={24}>
          <Form form={form} layout={'vertical'}>
            <Row gutter={5}>
              <Col lg={12} md={12} xs={24}>
                <Form.Item
                  label={'От'}
                  name={'start_date'}
                  rules={[{ required: true, message: 'Обязательное поле' }]}
                >
                  <input
                    className={'ant-input'}
                    type={'date'}
                    max="9999-12-31"
                  />
                </Form.Item>
              </Col>
              <Col lg={12} md={12} xs={24}>
                <Form.Item
                  label={'До'}
                  name={'end_date'}
                  initialValue={currDate}
                  rules={[{ required: true, message: 'Обязательное поле' }]}
                >
                  <input
                    className={'ant-input'}
                    type={'date'}
                    max="9999-12-31"
                  />
                </Form.Item>
              </Col>
              <Col lg={12} md={12} xs={24}>
                <Form.Item
                  initialValue={'all'}
                  name={'direction'}
                  label={'Тип звонков'}
                >
                  <Select placeholder={'Выберите из списка'}>
                    <Option value={'terminator'}>Входящие</Option>
                    <Option value={'originator'}>Исходящие</Option>
                    <Option value={'all'}>Все</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={12} md={12} xs={24}>
                <Form.Item
                  initialValue={true}
                  name={'positive'}
                  label={'Сост./Несост. звонки'}
                >
                  <Select placeholder={'Выберите из списка'}>
                    <Option value={true}>Состоявшиеся</Option>
                    <Option value={false}>Несостоявшиеся</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={24} md={24} xs={24}>
                <Form.Item valuePropName="checked" name={'overall'}>
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  <Checkbox>Добавить вкладу "Общее"</Checkbox>
                </Form.Item>
              </Col>
              <Col lg={24} md={24} xs={24}>
                <Form.Item
                  label={'Менеджеры'}
                  name={'users'}
                  rules={[{ required: true, message: 'Обязательное поле' }]}
                >
                  <Select
                    style={{ minWidth: 200, width: '100%' }}
                    showSearch
                    filterOption={false}
                    placeholder={'Выберите из списка или начните поиск...'}
                    loading={loadingStaffList}
                    mode={'multiple'}
                    maxTagCount={'responsive'}
                    onSearch={(value) => {
                      if (value.length > 0) {
                        searchStaff(value);
                      } else {
                        getStaffList();
                      }
                    }}
                  >
                    <>
                      {staffList?.data?.length > 0 &&
                        staffList.data.map((user) => {
                          return (
                            <Option key={user.id} value={user.id}>
                              {user.full_name ? user.full_name : user.email}
                            </Option>
                          );
                        })}
                    </>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row justify={'end'}>
              <Col lg={12} md={12} xs={24}>
                <Form.Item>
                  <Button
                    type={'primary'}
                    icon={<DownloadOutlined />}
                    onClick={getFileAndStat}
                  >
                    Excel файл
                  </Button>
                </Form.Item>
              </Col>
              <Col lg={12} md={12} xs={24}>
                <Form.Item>
                  <Button
                    type={'primary'}
                    icon={<AreaChartOutlined />}
                    onClick={previewStat}
                  >
                    Предпросмотр
                  </Button>
                </Form.Item>
              </Col>
              {file && (
                <Col lg={24} xs={24}>
                  <a target={'_blank'} href={file} rel="noreferrer">
                    {file.split('/')[file.split('/').length - 1]}
                  </a>
                </Col>
              )}
            </Row>
          </Form>
        </Col>
        <Col lg={16} xs={24}>
          {statData.length > 0 ? (
            <>
              <Tabs defaultActiveKey="0">
                {statData.map((tab, idx) => {
                  return (
                    <TabPane tab={tab.tabTitle} key={idx}>
                      <Table
                        scroll={{ y: 300 }}
                        pagination={false}
                        columns={columns}
                        dataSource={tab.data}
                      />
                    </TabPane>
                  );
                })}
              </Tabs>
            </>
          ) : (
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{
                height: 60,
              }}
              description={
                <b>
                  Для предварительного просмотра выберите диапазон дат, затем
                  менеджеров, по которым хотите просмотреть статистику.
                </b>
              }
            />
          )}
        </Col>
      </Row>
    </Modal>
  );
};

export default ExcelStatModal;
