import PropTypes from 'prop-types';
import { Button, Row, message } from 'antd';
import { ErrorBoundary } from '../ErrorBoundary';
import { useContext } from 'react';
import {
  viewAllChatNotification,
  viewNotification,
} from '../../logic/notifications/NotificationsApiFunctions';
import { userInformations } from '../../contex';
import ScrollingLoadedNotification from './ScrollingLoadedNotification';

const NotificationList = (props) => {
  const { userInfo } = useContext(userInformations);

  const handleViewNotifications = async (notificationId) => {
    if (!notificationId) return;
    try {
      const res = await viewNotification(userInfo, {
        notifications: [notificationId],
      });
      if (res?.status === 'ok') {
        props.removeNotificationFormList(notificationId);
      }
    } catch (err) {
      message.error('Ошибка прочтения уведомления');
      console.log(err);
    }
  };

  const handleViewAllNotifications = async (notificationList) => {
    if (!notificationList?.length > 0) return;
    try {
      const res = await viewNotification(userInfo, { notifications: null });
      if (res?.status === 'ok') {
        props.removeAllNotificationsFromList();
      }
    } catch (err) {
      console.log(err);
      message.error('Ошибка прочтения уведомлений');
    }
  };
  const handleViewAllChatNotifications = async () => {
    const hide = message.loading('Очистка уведомлений', 1000);
    try {
      const res = await viewAllChatNotification(userInfo);
      if (res?.status === 'ok') {
        props.removeAllNotificationsFromList();
      }
    } catch (err) {
      console.log(err);
      message.error('Ошибка прочтения уведомлений');
    } finally {
      hide();
    }
  };
  return (
    <>
      <div style={{ height: '97%' }}>
        <Row justify={'end'}>
          <Button
            type="primary"
            shape="round"
            size={'small'}
            onClick={async () => {
              props.useForChatNotification
                ? await handleViewAllChatNotifications()
                : await handleViewAllNotifications(props.notificationList);
            }}
          >
            Очистить
          </Button>
        </Row>
        <ErrorBoundary>
          <ScrollingLoadedNotification
            notificationList={props.notificationList}
            useForChatNotification={props.useForChatNotification}
            loadMoreNotification={() => props.loadMoreNotification()}
            redirectToCalendar={(eventId) => props.redirectToCalendar(eventId)}
            handleViewNotifications={(notificationId) =>
              handleViewNotifications(notificationId)
            }
          />
        </ErrorBoundary>
      </div>
    </>
  );
};

NotificationList.propTypes = {
  notificationList: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  useForChatNotification: PropTypes.bool,
  loadMoreNotification: PropTypes.func.isRequired,
  redirectToCalendar: PropTypes.func.isRequired,
  handleGetNotificationList: PropTypes.func.isRequired,
  removeNotificationFormList: PropTypes.func.isRequired,
  removeAllNotificationsFromList: PropTypes.func,
};

export default NotificationList;
