import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, ListItem } from '@material-ui/core';
import * as icons from 'react-feather';
import { BarChart } from 'react-feather';
import { useEffect, useState } from 'react';

const NavItem = ({
  href,
  iconName,
  title,
  linkState,
  style,
  onlyIcon,
  blocked,
  hover,
  ...rest
}) => {
  const location = useLocation();
  const active = href
    ? !!matchPath(
        {
          path: href,
          end: false,
        },
        location.pathname,
      )
    : false;

  const [copyHover, setCopyHover] = useState(hover);
  const IconTag = iconName ? icons[iconName] : <BarChart />;

  useEffect(() => {
    setCopyHover(hover);
  }, [hover]);

  const styles = style
    ? style
    : {
        color: blocked ? 'rgb(255 255 255 / 43%) !important' : '#fff',
        fontWeight: 'medium',
        justifyContent: 'flex-start',
        letterSpacing: 0,
        py: 1.25,
        textTransform: 'none',
        width: '100%',
        ...(active ||
          (copyHover && {
            color: '#b2fcff',
          })),
        '& svg': {
          mr: 1,
        },
      };

  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        py: 0,
      }}
      {...rest}
    >
      <Button
        disabled={blocked}
        component={RouterLink}
        sx={styles}
        to={href}
        state={linkState}
      >
        <>
          {onlyIcon ? (
            <IconTag size="20" />
          ) : (
            <>
              {IconTag && <IconTag size="20" />}
              <span>{title}</span>
            </>
          )}
        </>
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  href: PropTypes.string,
  iconName: PropTypes.string,
  title: PropTypes.string,
  onlyIcon: PropTypes.bool,
};

export default NavItem;
