import PropTypes from 'prop-types';
import { Drawer, message } from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { userInformations } from '../../../../contex';
import { ErrorBoundary } from '../../../ErrorBoundary';
import DragAndDropUserSections from './dragAndDropUserTabs/DragAndDropUserSections';
import {
  editUserSections,
  getProfileInfo,
} from '../../../../logic/account/AccountsApiFunctions';
import { globalStore } from '../../../../store';
import { TodoUserInfoActionTypes } from '../../../../store/todo/userInfo/userInfoTodo';
import { useTypedSelector } from '../../../../store/hooks/useTypedSelector';
import { userInfoHaveViewAllBranch } from '../../../../logic/account/utils';

const SettingUserMenuTabsDrawer = (props) => {
  const { userInfo } = useContext(userInformations);
  const [userSectionsList, setUserSectionsList] = useState(null);
  const storeUserInfo = useTypedSelector((state) => state.userInfo.userInfo);

  useEffect(() => {
    if (storeUserInfo?.sections?.length > 0) {
      setUserSectionsList(
        storeUserInfo?.sections
          .sort((a, b) => a.index - b.index)
          .sort((a, b) => b.value - a.value),
      );
    }
  }, [storeUserInfo]);

  const handleCloseDrawer = useCallback(async () => {
    const hide = message.loading('Сохранение изменений', 1000);
    try {
      const resultUserSectionsList = userSectionsList.map((item, index) => {
        return {
          id: item?.id,
          value: item?.value,
          index: index + 1,
        };
      });
      await editUserSections(resultUserSectionsList, userInfo);
    } catch (e) {
      console.log(e);
    } finally {
      props.handleClose();
      hide();
      await updateUserInfoContext();
    }
  }, [props.handleClose, userSectionsList]);

  const updateUserInfoContext = useCallback(async () => {
    const res = await getProfileInfo(userInfo);
    if (res) {
      const haveViewBranch = userInfoHaveViewAllBranch(res);
      userInfo.current = res;
      if (haveViewBranch) {
        userInfo.current['haveViewBranch'] = true;
      }
      globalStore.dispatch({
        type: TodoUserInfoActionTypes.SET_USER_INFO,
        payload: userInfo.current,
      });
    }
  }, [userInfo]);

  const handleChangList = useCallback(
    (list) => {
      if (!list) return;
      setUserSectionsList(list);
    },
    [setUserSectionsList],
  );

  return (
    <>
      <Drawer
        title={'Настройка вкладок меню'}
        placement="right"
        width={300}
        onClose={() => handleCloseDrawer()}
        visible={props.visible}
      >
        <ErrorBoundary>
          <DragAndDropUserSections
            userSectionsList={userSectionsList}
            onChangeList={(list) => handleChangList(list)}
          />
        </ErrorBoundary>
      </Drawer>
    </>
  );
};

SettingUserMenuTabsDrawer.propTypes = {
  visible: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default SettingUserMenuTabsDrawer;
