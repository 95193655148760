import PropTypes from 'prop-types';
import { Button, Form, message, Modal, Steps } from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { userInformations } from '../../../../../../../../contex';
import { ErrorBoundary } from '../../../../../../../ErrorBoundary';
import AddTransport from '../../../../../../transportation/new/AddTransport';
import AddCargoFormTask from '../../../../../../transportation/AddCargoFormTask';
import getTransportList, {
  getTransportById,
} from '../../../../../../../../logic/refbook/transportUnits/TransportUnitsApiFunction';
import useAsyncEffect from 'use-async-effect';
import {
  addShippingTaskCargo,
  addShippingTaskTransport,
  editShippingTaskCargo,
  editShippingTaskTransport,
} from '../../../../../../../../logic/task/TaskApiFunctions';

const { Step } = Steps;

const TransportationModal = (props) => {
  const { userInfo } = useContext(userInformations);
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [disableNextBtn, setDisableNextBtn] = useState(true);
  const [disableStepTransportCreate] = useState(false);
  const [transportOptions, setTransportOptions] = useState([]);

  useAsyncEffect(async () => {
    await handleGetTransportList();
  }, []);

  useEffect(() => {
    if (props.editingTransport && !props.forCreate) {
      handleSetFormFieldsValues(props.editingTransport);
    }
  }, [props.editingTransport, props.forCreate]);

  const handleSetFormFieldsValues = useCallback(
    (editingTransport) => {
      form.setFieldsValue({
        cargo: editingTransport?.cargo,
        cargo_description: editingTransport.cargo_description,
        cargo_gng: editingTransport.cargo_gng,
        cargo_places: editingTransport.cargo_places,
        cargo_security: editingTransport.cargo_security,
        cargo_type_packing:
          typeof editingTransport.cargo_type_packing === 'object'
            ? editingTransport.cargo_type_packing?.id
            : editingTransport.cargo_type_packing,
        cargo_weight: editingTransport.cargo_weight,
        identification_number: editingTransport?.identification_number || null,
        platform: editingTransport?.platform || null,
      });
    },
    [form],
  );

  const handleCloseModal = useCallback(() => {
    form.resetFields();
    setCurrentStep(0);
    setDisableNextBtn(true);
    setDisableNextBtn(false);
    props.closeModal && props.closeModal();
  }, [props.closeModal]);

  const handleChangeStep = useCallback(
    (step) => {
      setCurrentStep(step);
    },
    [setCurrentStep],
  );

  const handleChangeCargoForm = useCallback(() => {
    setTimeout(() => {
      form
        .validateFields()
        .then((values) => {
          setDisableNextBtn(true);
          props.updateValueEditingTransport({
            ...props.editingTransport,
            ...values,
          });
          // setDisableStepTransportCreate(true);
        })
        .catch(() => setDisableNextBtn(true));
    }, 0);
  }, [form, props.updateValueEditingTransport, setDisableNextBtn]);

  const handleChangeFormTransport = useCallback(
    (form, includable) => {
      form
        .validateFields()
        .then((values) => {
          setDisableNextBtn(false);
          const body = {
            ...values,
            transport_unit: {
              id: values?.transport_unit,
              count: values?.count,
              includable,
            },
          };
          if (includable) {
            body['number_container'] = null;
          }

          props.updateValueEditingTransport({
            ...props.editingTransport,
            transport: { ...body },
          });
        })
        .catch((e) => {
          setDisableNextBtn(true);
          console.log(e);
        });
    },
    [setDisableNextBtn, props.updateValueEditingTransport],
  );

  const handleGetTransportList = useCallback(
    async (keyword = null, id = null) => {
      const res = !id
        ? await getTransportList(userInfo, keyword)
        : await getTransportById(userInfo, id);
      if (res) {
        setTransportOptions(res);
      }
    },
    [userInfo, setTransportOptions],
  );

  const handleEditOrCreateShippingTaskTransport = useCallback(
    async (id, body) => {
      const res = id
        ? await editShippingTaskTransport(id, body, userInfo)
        : await addShippingTaskTransport(body, userInfo);
      if (res) {
        message.success(
          id
            ? 'Транспорт успешно обновлен'
            : 'Транспорт успешно добавлен к заявки',
        );
        props.updateValueEditingTransport({
          ...props.editingTransport,
          transportId: res?.id,
        });
        setCurrentStep(currentStep + 1);
        setDisableNextBtn(true);
      }
    },
    [setCurrentStep, props.editingTransport, props.updateValueEditingTransport],
  );

  const handleEditOrCreateShippingTaskCargo = useCallback(async (id, body) => {
    const res = id
      ? await editShippingTaskCargo(id, body, userInfo)
      : await addShippingTaskCargo(body, userInfo);
    if (res) {
      message.success(
        id ? 'Груз успешно обновлен' : 'Груз успешно добавлен к заявки',
      );
      handleCloseModal();
    }
  }, []);

  const handleSaveFormTransport = useCallback(async () => {
    let body = {
      ...props.editingTransport,
      number_container:
        props.editingTransport?.transport?.number_container ?? null,
      platform: props.editingTransport?.transport?.platform ?? null,
      platform_number:
        props.editingTransport?.transport?.platform_number ?? null,
      transport_unit:
        props.editingTransport?.transport?.transport_unit?.id ?? null,
      task: props.taskData?.id,
    };
    delete body['transport'];
    props.forCreate && !props.editingTransport?.transportId
      ? await handleEditOrCreateShippingTaskTransport(null, body)
      : await handleEditOrCreateShippingTaskTransport(
          props.editingTransport?.transportId,
          body,
        );
  }, [props.editingTransport, props.taskData, setCurrentStep]);

  const handleSaveFormCargo = useCallback(async () => {
    let body = {
      ...props.editingTransport,
      transport: props.editingTransport?.transportId,
      task: props.taskData?.id,
      cargo_type_packing:
        typeof props.editingTransport.cargo_type_packing === 'object'
          ? props.editingTransport.cargo_type_packing?.id
          : props.editingTransport.cargo_type_packing,
    };
    delete body['transportId'];
    props.forCreate
      ? await handleEditOrCreateShippingTaskCargo(null, body)
      : await handleEditOrCreateShippingTaskCargo(
          props.editingTransport.id,
          body,
        );
  }, [props.editingTransport, props.taskData, setCurrentStep]);

  const modalSteps = [
    {
      title: 'Транспорт',
      // disabled: disableStepTransportCreate,
      content: () => {
        return (
          <ErrorBoundary>
            <AddTransport
              data={props.editingTransport?.transport ?? null}
              forShippingTask={true}
              handleFormChange={handleChangeFormTransport}
              onSearchTransportOptions={async (keyword, id) => {
                await handleGetTransportList(keyword, id);
              }}
              //disableSelect={disableStepTransportCreate}
              options={transportOptions}
            />
          </ErrorBoundary>
        );
      },
    },
    {
      title: 'Груз',
      disabled: !props.editingTransport?.transportId,
      content: () => {
        return (
          <ErrorBoundary>
            <AddCargoFormTask
              form={form}
              forShippingTask={true}
              createdCargo={props.taskData?.cargo ?? null}
              task={props.taskData?.id}
              taskEdit={props.taskEdit}
              cargoDetailsValues={props.editingTransport}
              handleChangeForm={handleChangeCargoForm}
            />
          </ErrorBoundary>
        );
      },
    },
  ];

  return (
    <>
      <Modal
        visible={props.visible}
        title={
          props.editingTransport?.id && !props.forCreate
            ? 'Редактирование перевозки'
            : 'Добавление перевозки'
        }
        onCancel={() => handleCloseModal()}
        destroyOnClose={true}
        footer={[
          <Button key="back" onClick={() => handleCloseModal()}>
            Закрыть
          </Button>,

          /*<>
            {currentStep === 0 && props.editingTransport && !props.forCreate ? (
              <Button
                key="submit"
                type="primary"
                disabled={disableNextBtn}
                onClick={async () => {
                  await handleSaveFormTransport();
                }}
              >
                Обновить
              </Button>
            ) : null}
          </>,*/
          <>
            {currentStep === 0 ? (
              <Button
                key="submit"
                type="primary"
                disabled={disableNextBtn}
                onClick={async () => {
                  await handleSaveFormTransport();
                }}
              >
                Далее
              </Button>
            ) : null}
          </>,
          <>
            {currentStep === 1 &&
            props.editingTransport &&
            !disableStepTransportCreate ? (
              <Button
                key="submit"
                type="primary"
                onClick={() => {
                  setCurrentStep(currentStep - 1);
                }}
              >
                Назад
              </Button>
            ) : null}
          </>,
          <>
            {currentStep === 1 ? (
              <Button
                key="submit"
                type="primary"
                onClick={async () => {
                  await handleSaveFormCargo();
                }}
              >
                Сохранить
              </Button>
            ) : null}
          </>,
        ]}
      >
        <Steps
          style={{ marginBottom: '20px' }}
          current={currentStep}
          onChange={(step) => handleChangeStep(step)}
        >
          {modalSteps.map((step, index) => (
            <Step key={index} disabled={step?.disabled} title={step.title} />
          ))}
        </Steps>
        {modalSteps[currentStep].content()}
      </Modal>
    </>
  );
};

export default TransportationModal;

TransportationModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  transportationDataListLength: PropTypes.number,
  taskData: PropTypes.object.isRequired,
  editingTransport: PropTypes.object,
  updateValueEditingTransport: PropTypes.func.isRequired,
  forCreate: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  taskEdit: PropTypes.func.isRequired,
};
