import logo from '../../../icons/fireIcon.gif';

export default function getFireImagesTask(taskPriority) {
  const stubForOutOutFireIcon = [0, 1, 2];
  return (
    <>
      {stubForOutOutFireIcon.map((item, index) => {
        if (index < taskPriority) {
          return (
            <img
              key={index}
              style={{ marginBottom: '6px' }}
              width={22}
              height={22}
              src={logo}
            />
          );
        }
      })}
    </>
  );
}
