import { Button, Col } from 'antd';
import { Box } from '@material-ui/core';
import MainTabSwitcher from './uiComponents/MainTabSwitcher';
import { ErrorBoundary } from '../ErrorBoundary';
import { PlusOutlined } from '@ant-design/icons';
import React from 'react';
import PropTypes from 'prop-types';

const CalendarHeader = (props) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Col>
          <ErrorBoundary>
            <MainTabSwitcher
              value={props.switcherValue}
              changeValue={(val) => props.changeValueMainTabSwitcher(val)}
            />
          </ErrorBoundary>
        </Col>
        <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={() => props.openTaskModal(true)}
            style={{ color: '#0b7b7f', borderColor: '#0b7b7f' }}
            type="default"
            icon={<PlusOutlined />}
          >
            Добавить
          </Button>
        </Col>
      </Box>
    </>
  );
};

CalendarHeader.propTypes = {
  openTaskModal: PropTypes.func.isRequired,
  switcherValue: PropTypes.string,
  changeValueMainTabSwitcher: PropTypes.func,
  filterEventByStaff: PropTypes.func,
};

export default React.memo(CalendarHeader);
