import { ConfigProvider, DatePicker } from 'antd';
import locale from 'antd/es/locale/ru_RU';
import React from 'react';

const DataPicker = ({
  setSelectedKeys,
  visible,
  openDataPickerCelendar,
  SetOpenDataPickerCelendar,
  setVisibleDataPicker,
  periodDates,
}) => {
  const formatedTimestamp = (date) => {
    const d = new Date(date);
    d.setHours(24, 0, 0, 0);
    return d.toISOString();
  };

  return (
    <>
      {visible ? (
        <ConfigProvider locale={locale}>
          <DatePicker.RangePicker
            style={{
              width: 250,
              height: 37,
              borderRadius: '0 10px 10px 0',
              border: 'none',
              boxShadow: 'none',
            }}
            format={'DD-MM-YYYY'}
            autoFocus={true}
            allowClear={false}
            inputReadOnly={true}
            open={openDataPickerCelendar}
            onOpenChange={(isOpen) => {
              if (isOpen) {
                SetOpenDataPickerCelendar(true);
              } else {
                if (typeof periodDates !== 'object') {
                  setVisibleDataPicker(false);
                }
                SetOpenDataPickerCelendar(false);
              }
            }}
            onChange={(e) => {
              const keys =
                e?.length > 0 ? e.map((item) => formatedTimestamp(item)) : [];
              setSelectedKeys(keys);
              SetOpenDataPickerCelendar(false);
            }}
          />
        </ConfigProvider>
      ) : (
        <></>
      )}
    </>
  );
};

export default DataPicker;
