import { Button, Col, Form, Input, Modal, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { Option } from 'antd/es/mentions';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';

const AddShoulderModal = ({ visible, closeModal, createShoulder, taskId }) => {
  const [shoulderForm] = Form.useForm();
  const [disableBtn, setDisableBtn] = useState(false);
  const [price, setPrice] = useState('');
  const isMobile = useCheckMobileScreen();

  useEffect(() => {
    shoulderForm.resetFields();
    setPrice('');
  }, [visible]);

  useEffect(() => {
    shoulderForm.setFieldsValue({
      currency: null,
      vat_rate: null,
    });
  }, []);

  const validatePrice = (price) => {
    if (price) {
      const value = price.target.value.replace(/,/g, '.');
      const countNumberOfValue = Math.round(value).toString()?.length;
      if (value < 0) {
        setDisableBtn(true);
        return {
          validateStatus: 'error',
          errorMsg: 'Сумма меньше 0',
        };
      } else if (countNumberOfValue >= 11) {
        setDisableBtn(true);
        return {
          validateStatus: 'error',
          errorMsg: 'Слишком большое значение',
        };
      } else if (Math.abs(Number(Number(value).toFixed(2) - value)) !== 0) {
        setDisableBtn(true);
        return {
          validateStatus: 'error',
          errorMsg: 'Округлите до сотых',
        };
      } else {
        setDisableBtn(false);
        return {
          validateStatus: 'success',
          errorMsg: '',
        };
      }
    }
  };

  const onPriceChange = (value) => {
    setPrice({
      ...validatePrice(value),
      value,
    });
  };

  const handelClose = () => {
    shoulderForm.resetFields();
    setPrice('');
    closeModal();
  };

  const addShoulder = () => {
    shoulderForm
      .validateFields()
      .then(async (values) => {
        let body = {
          task: taskId,
          custom: true,
          ...values,
        };
        createShoulder(body);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal
      width={400}
      visible={visible}
      title="Добавить"
      onOk={() => {
        addShoulder();
      }}
      onCancel={() => {
        handelClose();
      }}
      footer={[
        <Button
          key="back"
          onClick={() => {
            handelClose();
          }}
        >
          Закрыть
        </Button>,
        <Button
          disabled={disableBtn}
          key="submit"
          type="primary"
          onClick={() => {
            addShoulder();
          }}
        >
          Добавить
        </Button>,
      ]}
    >
      <Form form={shoulderForm} layout={'vertical'}>
        <Col gutter={5}>
          <Col xs={24} lg={24}>
            <Form.Item name={'name'} label={'Название'}>
              <Input type={'text'} placeholder="Название" />
            </Form.Item>
          </Col>
          <Col xs={24} lg={24}>
            <Form.Item
              name={'price'}
              label={'Сумма'}
              help={price.errorMsg}
              validateStatus={price.validateStatus}
              rules={[{ required: false }]}
            >
              <Input
                type={'number'}
                placeholder="Сумма"
                onChange={(e) => onPriceChange(e)}
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={24}>
            <Form.Item
              name={'currency'}
              rules={[{ required: true, message: 'Обязательное поле' }]}
              label={'Валюта'}
            >
              <Select
                getPopupContainer={(trigger) =>
                  isMobile ? trigger.parentNode : document.body
                }
              >
                <Option value={'rub'}>Рубли</Option>
                <Option value={'dol'}>Доллар</Option>
                <Option value={'eur'}>Евро</Option>
                <Option value={'cny'}>Юань</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={24}>
            <Form.Item name={'vat_rate'} label={'НДС'}>
              <Select
                getPopupContainer={(trigger) =>
                  isMobile ? trigger.parentNode : document.body
                }
                defaultValue={null}
              >
                <Option value={0}>0%</Option>
                <Option value={20}>20%</Option>
                <Option value={null}>Без НДС</Option>
              </Select>
            </Form.Item>
          </Col>
        </Col>
      </Form>
    </Modal>
  );
};
export default AddShoulderModal;
