import React, { useState, useEffect, useContext } from 'react';
import { List, Button, Pagination, Drawer } from 'antd';
import { RotateCw } from 'react-feather';
import LogItem from './LogItem';
import { call } from '../../../apiUtils/call';
import { restApiHost } from '../../../utils/appVariables';
import { userInformations } from '../../../contex';

export default function Logs({ open, logs, setLogs, onClose }) {
  const { userInfo } = useContext(userInformations);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loadingLogs, setLoadingLogs] = useState(false);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    if (!logs?.length) updateLogs();
  }, []);

  useEffect(() => {
    updateLogs();
  }, [currentPage]);

  async function updateLogs() {
    setLoadingLogs(true);
    const params = new URLSearchParams();
    params.append('page', currentPage.toString());
    params.append('size', pageSize.toString());
    const json = await call(
      `${restApiHost}/administration/log/?${params.toString()}`,
      {
        method: 'GET',
        headers: { Authorization: `Token ${localStorage.getItem('token')}` },
      },
      userInfo.current.haveViewBranch,
      null,
    );
    if (!json) return;
    setLogs(json?.data ? json.data : []);
    setTotalPages(json.count);
    setCurrentPage(1);
    setLoadingLogs(false);
  }

  const renderLog = (log) => <LogItem log={log} refreshLogs={updateLogs} />;

  return (
    <Drawer title={'Логи'} visible={open} onClose={onClose} width={'100%'}>
      <div>
        <Button
          icon={<RotateCw size={12} />}
          loading={loadingLogs}
          onClick={updateLogs}
        ></Button>

        <List
          dataSource={logs}
          style={{ marginTop: 8 }}
          grid={{ gutter: 16, column: 2 }}
          rowKey={() => crypto.randomUUID()}
          renderItem={renderLog}
        />

        <Pagination
          current={currentPage}
          onChange={(page) => setCurrentPage(page)}
          onShowSizeChange={(current, size) => setPageSize(size)}
          total={totalPages}
          pageSize={pageSize}
        />
      </div>
    </Drawer>
  );
}
