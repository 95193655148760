import { Button, Col, Form, Input, Modal } from 'antd';
import React, { useEffect } from 'react';

const EditShoulderModal = ({
  visible,
  shoulder,
  closeModal,
  editCustomShoulder,
  taskId,
}) => {
  const [shoulderForm] = Form.useForm();

  useEffect(() => {
    shoulderForm.setFieldsValue({
      name: shoulder?.shoulderName ? shoulder.shoulderName : '',
    });
  }, [shoulder]);

  const handelClose = () => {
    shoulderForm.resetFields();
    closeModal();
  };

  const editShoulder = () => {
    shoulderForm
      .validateFields()
      .then(async (values) => {
        let body = {
          task: taskId,
          custom: true,
          sumsId: shoulder?.sumsId,
          ...values,
        };
        editCustomShoulder(body);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal
      width={400}
      visible={visible}
      title="Редактировать"
      onOk={() => {
        editShoulder();
      }}
      onCancel={() => {
        handelClose();
      }}
      footer={[
        <Button
          key="back"
          onClick={() => {
            handelClose();
          }}
        >
          Закрыть
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            editShoulder();
          }}
        >
          Редактировать
        </Button>,
      ]}
    >
      <Form form={shoulderForm} layout={'vertical'}>
        <Col gutter={5}>
          <Col xs={24} lg={24}>
            <Form.Item name={'name'} label={'Название'}>
              <Input type={'text'} placeholder="Название" />
            </Form.Item>
          </Col>
        </Col>
      </Form>
    </Modal>
  );
};
export default EditShoulderModal;
