import PropTypes from 'prop-types';
import { Card, Col, Divider, Form, Input, Row, Tag } from 'antd';
import { priceFormatter } from '../../../../../../../utils/priceFormatter';
import React, { useCallback } from 'react';

const Details = (props) => {
  const renderTransportUnits = useCallback(
    (task_transport_identical) => {
      if (!task_transport_identical || task_transport_identical?.length <= 0)
        return;

      return (
        <div
          style={{
            color: 'white',
            backgroundColor: '#0b7b7f',
            padding: '5px',
            fontSize: '12px',
          }}
        >
          {task_transport_identical.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
              >
                {item} <br />
                {index !== task_transport_identical?.length - 1 && (
                  <Divider
                    style={{ borderTop: ' 1px solid white', margin: '10px 0' }}
                  />
                )}
              </div>
            );
          })}
        </div>
      );
    },
    [props.taskData],
  );

  return (
    <>
      <Form layout="vertical">
        <Row gutter={16}>
          <Col lg={12} xs={24}>
            <Row>
              <Col lg={24} xs={24}>
                <h4>Тр. единицы</h4>
                {renderTransportUnits(
                  props.taskData?.task_transport_identical,
                ) || 'Не указано'}
              </Col>
            </Row>
            <Row>
              <Col lg={24}>
                <h4>Груз</h4>
                <Tag color={'#0b7b7f'} style={{ marginBottom: '5px' }}>
                  {props.taskData?.cargo?.name
                    ? `${props.taskData.cargo.name} - ${props.taskData?.cargo?.code}`
                    : 'Не указано'}
                </Tag>
              </Col>
            </Row>
            <Row>
              <Col lg={24}>
                <h4>Маршрут</h4>
                <Tag
                  color={'#0b7b7f'}
                  style={{ marginBottom: '5px', maxWidth: '100%' }}
                >
                  <div style={{ textOverflow: 'clip', textWrap: 'wrap' }}>
                    {props.taskData?.route || 'Не указано'}
                  </div>
                </Tag>
              </Col>
            </Row>
          </Col>
          <Col lg={6} xs={24} style={{ marginTop: '28px' }}>
            <Card title="Деньги в валюте" className={'modal-card'}>
              <Form.Item className={'fixed-width-label'} label={'Валюта'}>
                <Input disabled value={'Руб.'} placeholder="Валюта" />
              </Form.Item>
              <Form.Item className={'fixed-width-label'} label={'Курс'}>
                <Input disabled value={1.0} placeholder="Курс" />
              </Form.Item>
              <Form.Item className={'fixed-width-label'} label={'Клиенту'}>
                <Input
                  disabled
                  value={props.taskData?.price || 0}
                  placeholder="Клиенту"
                />
              </Form.Item>
            </Card>
          </Col>
          <Col lg={6} xs={24} style={{ marginTop: '28px' }}>
            <Card title="Деньги в валюте учета" className={'modal-card'}>
              <Form.Item className={'fixed-width-label'} label={'Клиенту'}>
                <Input
                  disabled
                  value={props.taskData?.price || 0}
                  placeholder="Клиенту"
                />
              </Form.Item>
              <Form.Item className={'fixed-width-label'} label={'Расходы'}>
                <Input
                  disabled
                  value={props.taskData?.upfront_costs_sum || 0}
                  placeholder="Расходы"
                />
              </Form.Item>
              <Form.Item className={'fixed-width-label'} label={'Дельта'}>
                <Input
                  className={'green-color'}
                  disabled
                  value={
                    props.taskData?.delta
                      ? priceFormatter(+props.taskData.delta)
                      : 0
                  }
                  placeholder="Дельта"
                />
              </Form.Item>
            </Card>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default Details;

Details.propTypes = {
  taskData: PropTypes.object.isRequired,
};
