import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  Menu,
  message,
  Popconfirm,
  Space,
  Table,
  Tooltip,
} from 'antd';
import EllipsisOutlined from '@ant-design/icons/lib/icons/EllipsisOutlined';
import { priceFormatter } from '../../../../../../../utils/priceFormatter';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ErrorBoundary } from '../../../../../../ErrorBoundary';
import CostModal from './modal/CostModal';
import { deleteShippingTaskCost } from '../../../../../../../logic/task/TaskApiFunctions';
import { userInformations } from '../../../../../../../contex';
import useCheckMobileScreen from '../../../../../../../hooks/useCheckMobileScreen';
import ScrollingLoadedList from '../../../../../../mobile/scrollingLoadedList/ScrollingLoadedList';
import CostListItem from '../../../../../../mobile/task/modal/CostListItem';
import useStateRef from 'react-usestateref';
import {
  changeColumnSizeInColumnsGroup,
  changeSizeColumn,
  convertingColumnsWithGroupsIntoRegularOnesForUseInMobileFilters,
  prepareColumnsForResizing,
  sortingColumnsTable,
} from '../../../../../../settingTable/sortingColumnsTable';
import { editTableColumn } from '../../../../../../../logic/profileInfo/mainTable/MainTableApiFunctions';
import { getProfileInfo } from '../../../../../../../logic/account/AccountsApiFunctions';
import { userInfoHaveViewAllBranch } from '../../../../../../../logic/account/utils';
import filterActiveColumns from '../../../../../../table/utils';
import SearchDataFilter from '../../../../../../leeds/filters/SearchData';
import ResizableTableColumnHeader from '../../../../../../table/ResizableTableColumnHeader';
import { getFiltersTable } from '../../../../../../../utils/getFilters';
import RangeNumberFilters from '../../../../../../leeds/filters/RangeNumberFilters';
import FilterSelect from '../../../../../../leeds/filters/FilterSelect';
import UniversalFiltersModal from '../../../../../../mobile/filtersModal/UniversalFiltersModal';

const CostsTable = (props) => {
  const { userInfo } = useContext(userInformations);
  const [editingCost, setEditingCost] = useState(null);
  const [visibleModal, setVisibleModal] = useState(false);
  const isMobile = useCheckMobileScreen();
  const [baseColumns, setBaseColumns, baseColumnsRef] = useStateRef([]);
  const [
    ,
    setColumnFiltersFromTableComponent,
    columnFiltersFromTableComponentRef,
  ] = useStateRef([]);

  useEffect(() => {
    const tableName = `tasks.${props.apiEndpoint}`;
    const col = sortingColumnsTable(
      userInfo,
      tableName,
      columns,
      props.visibleFilters,
    );
    const preparedColumnsForResizing = prepareColumnsForResizing(
      col,
      handleResize,
    );
    setBaseColumns(preparedColumnsForResizing);
  }, [props.visibleFilters, props.apiEndpoint]);

  useEffect(() => {
    if (!props.tableFilters) {
      setColumnFiltersFromTableComponent([]);
    }
  }, [props.tableFilters]);

  const handleEditConst = useCallback(
    (constRow) => {
      if (!constRow) return;
      setEditingCost(constRow);
      setVisibleModal(true);
    },
    [setEditingCost, setVisibleModal],
  );

  const handleCloseModal = useCallback(async () => {
    setVisibleModal(false);
    props.handleUpdateList(props.tableFilters);
  }, [setVisibleModal, props.handleUpdateList, props.tableFilters]);

  const handleDeleteCost = useCallback(
    async (costId) => {
      const res = await deleteShippingTaskCost(
        costId,
        props.apiEndpoint,
        userInfo,
      );
      if (res) {
        message.success('Расход успешно удален');
        props.handleUpdateList(props.tableFilters);
      }
    },
    [props.handleUpdateList, props.tableFilters],
  );

  const menu = (cost) => {
    return (
      <Menu style={{ width: 200 }}>
        <Menu.Item
          icon={<EditOutlined />}
          onClick={() => {
            handleEditConst(cost);
          }}
        >
          Редактировать
        </Menu.Item>
        <Popconfirm
          title="Вы действительно хотите удалить запись?"
          onConfirm={async () => {
            await handleDeleteCost(cost?.id);
          }}
          onCancel={() => console.log('Delete item')}
          okText="Да"
          cancelText="Нет"
          placement="rightBottom"
        >
          <Menu.Item icon={<DeleteOutlined />}>Удалить</Menu.Item>
        </Popconfirm>
      </Menu>
    );
  };

  const handleRenderCurrency = useCallback((currency) => {
    switch (currency) {
      case 'rub':
        return 'Рубли';
      case 'dol':
        return 'Доллар';
      case 'eur':
        return 'Евро';
      case 'cny':
        return 'Юань';
      default:
        return '-';
    }
  }, []);

  const handleTableChange = (filtersTable) => {
    const { filters } = getFiltersTable({ current: 1 }, filtersTable);
    setColumnFiltersFromTableComponent(filtersTable);
    props.handleUpdateList(filters);
  };

  const columns = [
    {
      title: '',
      key: 'action',
      width: 50,
      align: 'center',
      index: 1,
      uniqueIndex: 'action',
      visible: true,
      render: (costs) => (
        <Space size="middle">
          <Dropdown overlay={menu(costs)} trigger={['hover']}>
            <EllipsisOutlined style={{ cursor: 'pointer', fontSize: '24px' }} />
          </Dropdown>
        </Space>
      ),
    },
    // {
    //     title: 'Перевозка',
    //     key: 'type_transport',
    //     ellipsis: {
    //         showTitle: false
    //     },
    //     width: 150,
    //     render: (data) => data?.transport?.transport?.number_container || ''
    // },
    {
      title: 'Операция',
      dataIndex: 'operation',
      key: 'operation',
      width: 200,
      index: 4,
      uniqueIndex: 'operation',
      ellipsis: {
        showTitle: false,
      },
      render: (operation) =>
        operation?.name ? (
          <Tooltip title={operation.name} placement={'topLeft'}>
            {operation.name}
          </Tooltip>
        ) : (
          ''
        ),
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Операция'}
              confirm={(filtersTable) => handleTableChange(filtersTable)}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={
                columnFiltersFromTableComponentRef.current
                  ?.operation__name__icontains
              }
              columnKey={'operation__name__icontains'}
            />
          ),
          dataIndex: 'operation',
          key: 'operation',
          width: 200,
          index: 4,
          uniqueIndex: 'operation',
          ellipsis: {
            showTitle: false,
          },
          visible: props.visibleFilters,
          render: (operation) =>
            operation?.name ? (
              <Tooltip title={operation.name} placement={'topLeft'}>
                {operation.name}
              </Tooltip>
            ) : (
              ''
            ),
        },
      ],
    },
    {
      title: 'Наименование',
      dataIndex: 'service',
      key: 'service',
      index: 6,
      uniqueIndex: 'service',
      width: 190,
      ellipsis: {
        showTitle: false,
      },
      render: (service) =>
        service?.name ? (
          <Tooltip title={service.name} placement={'topLeft'}>
            {service.name}
          </Tooltip>
        ) : (
          ''
        ),
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Наименование'}
              confirm={(filtersTable) => handleTableChange(filtersTable)}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={
                columnFiltersFromTableComponentRef.current
                  ?.service__name__icontains
              }
              columnKey={'service__name__icontains'}
            />
          ),
          dataIndex: 'service',
          index: 6,
          uniqueIndex: 'service',
          width: 190,
          ellipsis: {
            showTitle: false,
          },
          visible: props.visibleFilters,
          render: (service) =>
            service?.name ? (
              <Tooltip title={service.name} placement={'topLeft'}>
                {service.name}
              </Tooltip>
            ) : (
              ''
            ),
        },
      ],
    },
    {
      title: 'Валюта',
      uniqueIndex: 'currencyGroup',
      isGroup: true,
      columnId: 'active',
      disableResize: true,
      index: 1,
      children: [
        {
          title: 'Вал.',
          key: 'currency',
          dataIndex: 'currency',
          uniqueIndex: 'currency',
          index: 8,
          width: 100,
          render: (currency) => {
            return handleRenderCurrency(currency);
          },
          children: [
            {
              title: (
                <FilterSelect
                  confirm={(filtersTable) => handleTableChange(filtersTable)}
                  tableFilters={columnFiltersFromTableComponentRef.current}
                  selectedKeys={
                    columnFiltersFromTableComponentRef.current?.currency
                  }
                  columnKey={'currency'}
                  dataList={[
                    { title: 'Рубли', value: 'rub' },
                    { title: 'Доллар', value: 'dol' },
                    { title: 'Евро', value: 'eur' },
                    { title: 'Юань', value: 'cny' },
                  ]}
                />
              ),
              key: 'currency',
              dataIndex: 'currency',
              uniqueIndex: 'currency',
              index: 8,
              width: 100,
              visible: props.visibleFilters,
              render: (currency) => {
                return handleRenderCurrency(currency);
              },
            },
          ],
        },
        {
          title: 'Курс',
          key: 'currency_rates',
          dataIndex: 'currency_rates',
          width: 70,
          uniqueIndex: 'currency_rates',
          index: 10,
          children: [
            {
              title: (
                <RangeNumberFilters
                  confirm={(filtersTable) => handleTableChange(filtersTable)}
                  tableFilters={columnFiltersFromTableComponentRef.current}
                  selectedKeys={
                    columnFiltersFromTableComponentRef.current
                      ?.currency_rates__range
                  }
                  columnKey={'currency_rates__range'}
                />
              ),
              key: 'currency_rates',
              dataIndex: 'currency_rates',
              width: 70,
              uniqueIndex: 'currency_rates',
              index: 10,
              visible: props.visibleFilters,
            },
          ],
        },
      ],
    },
    {
      title: 'Цена',
      uniqueIndex: 'priceGroup',
      isGroup: true,
      columnId: 'active',
      disableResize: true,
      index: 2,
      children: [
        {
          title: 'Вал.',
          key: 'price_currency',
          width: 150,
          uniqueIndex: 'price_currency',
          index: 12,
          render: (data) =>
            data?.in_currency
              ? priceFormatter(+data.price_currency)
              : priceFormatter(data.price),
          children: [
            {
              title: (
                <RangeNumberFilters
                  confirm={(filtersTable) => handleTableChange(filtersTable)}
                  tableFilters={columnFiltersFromTableComponentRef.current}
                  selectedKeys={
                    columnFiltersFromTableComponentRef.current
                      ?.price_currency__range
                  }
                  columnKey={'price_currency__range'}
                />
              ),
              key: 'price_currency',
              width: 150,
              uniqueIndex: 'price_currency',
              index: 12,
              visible: props.visibleFilters,
              render: (data) =>
                data?.in_currency
                  ? priceFormatter(+data.price_currency)
                  : priceFormatter(data.price),
            },
          ],
        },
        {
          title: 'Вал.(У)',
          key: 'price',
          dataIndex: 'price',
          width: 150,
          uniqueIndex: 'price',
          index: 14,
          render: (price) => (price ? priceFormatter(price) : 0),
          children: [
            {
              title: (
                <RangeNumberFilters
                  confirm={(filtersTable) => handleTableChange(filtersTable)}
                  tableFilters={columnFiltersFromTableComponentRef.current}
                  selectedKeys={
                    columnFiltersFromTableComponentRef.current?.price__range
                  }
                  columnKey={'price__range'}
                />
              ),
              key: 'price',
              dataIndex: 'price',
              width: 150,
              uniqueIndex: 'price',
              index: 14,
              visible: props.visibleFilters,
              render: (price) => (price ? priceFormatter(price) : 0),
            },
          ],
        },
      ],
    },
    {
      title: 'Единицы',
      uniqueIndex: 'unitsGroup',
      isGroup: true,
      columnId: 'active',
      disableResize: true,
      index: 3,
      children: [
        {
          title: 'Кол-во',
          key: 'count_services',
          dataIndex: 'count_services',
          uniqueIndex: 'count_services',
          width: 80,
          index: 16,
          children: [
            {
              title: (
                <RangeNumberFilters
                  confirm={(filtersTable) => handleTableChange(filtersTable)}
                  tableFilters={columnFiltersFromTableComponentRef.current}
                  selectedKeys={
                    columnFiltersFromTableComponentRef.current
                      ?.count_services__range
                  }
                  columnKey={'count_services__range'}
                />
              ),
              key: 'count_services',
              dataIndex: 'count_services',
              uniqueIndex: 'count_services',
              index: 16,
              width: 80,
              visible: props.visibleFilters,
            },
          ],
        },
        {
          title: 'Ед. изм.',
          dataIndex: 'service',
          key: 'service',
          uniqueIndex: 'serviceUnits',
          width: 150,
          index: 18,
          render: (service) => (service ? service.units : ''),
          children: [
            {
              title: (
                <SearchDataFilter
                  inputPlaceholder={'Ед. изм.'}
                  confirm={(filtersTable) => handleTableChange(filtersTable)}
                  tableFilters={columnFiltersFromTableComponentRef.current}
                  selectedKeys={
                    columnFiltersFromTableComponentRef.current
                      ?.service__name__icontains
                  }
                  columnKey={'service__name__icontains'}
                />
              ),
              dataIndex: 'service',
              key: 'service',
              uniqueIndex: 'serviceUnits',
              index: 18,
              width: 150,
              visible: props.visibleFilters,
              render: (service) => (service ? service.units : ''),
            },
          ],
        },
      ],
    },
    {
      title: 'НДС',
      uniqueIndex: 'vatGroup',
      isGroup: true,
      columnId: 'active',
      disableResize: true,
      index: 4,
      children: [
        {
          title: 'Ставка',
          dataIndex: 'var_rate_str',
          key: 'vat_rate_str',
          uniqueIndex: 'vat_rate_str',
          width: 150,
          index: 20,
          children: [
            {
              title: (
                <FilterSelect
                  confirm={(filtersTable) => handleTableChange(filtersTable)}
                  tableFilters={columnFiltersFromTableComponentRef.current}
                  selectedKeys={
                    columnFiltersFromTableComponentRef.current?.var_rate
                  }
                  columnKey={'var_rate'}
                  dataList={[
                    { title: 'Без НДС', value: 'null' },
                    { title: '0%', value: 0 },
                    { title: '20', value: 20 },
                  ]}
                />
              ),
              dataIndex: 'var_rate_str',
              key: 'vat_rate_str',
              uniqueIndex: 'vat_rate_str',
              index: 20,
              width: 150,
              visible: props.visibleFilters,
            },
          ],
        },
        {
          title: 'Валюта(У)',
          key: 'sum_vat',
          dataIndex: 'sum_vat',
          uniqueIndex: 'sum_vat',
          width: 150,
          index: 22,
          children: [
            {
              title: (
                <RangeNumberFilters
                  confirm={(filtersTable) => handleTableChange(filtersTable)}
                  tableFilters={columnFiltersFromTableComponentRef.current}
                  selectedKeys={
                    columnFiltersFromTableComponentRef.current?.sum_vat__range
                  }
                  columnKey={'sum_vat__range'}
                />
              ),
              key: 'sum_vat',
              dataIndex: 'sum_vat',
              uniqueIndex: 'sum_vat',
              index: 22,
              width: 150,
              visible: props.visibleFilters,
            },
          ],
        },
      ],
    },
    {
      title: 'Всего',
      uniqueIndex: 'sumGroup',
      isGroup: true,
      columnId: 'active',
      disableResize: true,
      index: 5,
      children: [
        {
          title: 'Всего',
          key: 'sum_currency',
          width: 150,
          uniqueIndex: 'sum_currency',
          index: 24,
          render: (data) =>
            data?.in_currency
              ? priceFormatter(+data?.sum_currency?.sum_with_vat || 0)
              : priceFormatter(data?.sum_with_vat || 0),
          children: [
            {
              title: (
                <RangeNumberFilters
                  confirm={(filtersTable) => handleTableChange(filtersTable)}
                  tableFilters={columnFiltersFromTableComponentRef.current}
                  selectedKeys={
                    columnFiltersFromTableComponentRef.current
                      ?.sum_currency__range
                  }
                  columnKey={'sum_currency__range'}
                />
              ),
              key: 'sum_currency',
              width: 150,
              uniqueIndex: 'sum_currency',
              index: 24,
              visible: props.visibleFilters,
              render: (data) =>
                data?.in_currency
                  ? priceFormatter(+data?.sum_currency?.sum_with_vat || 0)
                  : priceFormatter(data?.sum_with_vat || 0),
            },
          ],
        },
        {
          title: 'Всего(У)',
          key: 'sum_with_vat',
          dataIndex: 'sum_with_vat',
          width: 150,
          uniqueIndex: 'sum_with_vat',
          index: 26,
          render: (sum) => (sum ? priceFormatter(sum) : 0),
          children: [
            {
              title: (
                <RangeNumberFilters
                  confirm={(filtersTable) => handleTableChange(filtersTable)}
                  tableFilters={columnFiltersFromTableComponentRef.current}
                  selectedKeys={
                    columnFiltersFromTableComponentRef.current
                      ?.sum_with_vat__range
                  }
                  columnKey={'sum_with_vat__range'}
                />
              ),
              key: 'sum_with_vat',
              dataIndex: 'sum_with_vat',
              width: 150,
              uniqueIndex: 'sum_with_vat',
              index: 26,
              visible: props.visibleFilters,
              render: (sum) => (sum ? priceFormatter(sum) : 0),
            },
          ],
        },
      ],
    },
  ];

  const handleResize = useCallback(
    (index) =>
      async (e, { size, uniqueIndex, isGroupItem }) => {
        let databaseColumnsId = null;
        const copyColumns = [...baseColumnsRef.current];

        if (!isGroupItem) {
          setBaseColumns((columns) => {
            return changeSizeColumn(columns, index, size);
          });

          databaseColumnsId = copyColumns[index]?.idInDatabase;
        } else {
          setBaseColumns((columns) => {
            const { nextColumns, idInDatabase } =
              changeColumnSizeInColumnsGroup(columns, index, uniqueIndex, size);
            databaseColumnsId = idInDatabase;
            return nextColumns;
          });
        }

        if (!databaseColumnsId) return;

        await editTableColumn(databaseColumnsId, { width: size }, userInfo);
        const res = await getProfileInfo(userInfo);
        if (res) {
          userInfo.current = res;
          const haveViewBranch = userInfoHaveViewAllBranch(res);
          if (haveViewBranch) {
            userInfo.current['haveViewBranch'] = true;
          }
        }
      },
    [baseColumnsRef.current],
  );

  const filteringColumnsChildrenPillarByVisible = useMemo(() => {
    const copyColumns = [...baseColumns];
    return filterActiveColumns(copyColumns);
  }, [baseColumns]);

  return (
    <>
      {!isMobile ? (
        <Table
          scroll={{ x: 1000 }}
          showHeader
          bordered={true}
          dataSource={props.costsDataList}
          columns={filteringColumnsChildrenPillarByVisible}
          onRow={(cost) => {
            return {
              onDoubleClick: () => {
                handleEditConst(cost);
              },
            };
          }}
          components={{
            header: {
              cell: ResizableTableColumnHeader,
            },
          }}
          loading={props.loading}
          rowClassName={(record, index) =>
            index % 2 === 0
              ? 'black-text table-row-color'
              : 'black-text table-row-white'
          }
          size="small"
          pagination={{
            position: ['none'],
            size: 'middle',
            showSizeChanger: false,
          }}
        />
      ) : (
        <>
          <div style={{ paddingTop: '5px' }}>
            <ErrorBoundary>
              <ScrollingLoadedList
                dataList={props.costsDataList}
                totalData={props.costsDataList?.length}
                loadMoreItems={async () => {
                  //handleUpdate(page + 1, filters, false,true)
                }}
                renderListItem={(item) => (
                  <CostListItem
                    cost={item}
                    menuActions={(manager) => menu(manager)}
                    handleRenderCurrency={handleRenderCurrency}
                    priceFormatter={priceFormatter}
                  />
                )}
              />
            </ErrorBoundary>
          </div>
        </>
      )}
      <ErrorBoundary>
        <CostModal
          visible={visibleModal}
          editingCost={editingCost}
          taskData={props.taskData}
          apiEndpoint={props.apiEndpoint}
          closeModal={async () => await handleCloseModal()}
          refreshTaskInfo={() => {
            props.refreshTaskInfo();
          }}
        />
      </ErrorBoundary>

      <ErrorBoundary>
        <UniversalFiltersModal
          visibleModal={props.visibleFilters && isMobile}
          handleClose={() => {
            props.setVisibleFilters(false);
          }}
          resetFilters={async () => {
            props.setVisibleFilters(false);
            await props.handleUpdateList(null);
          }}
          columnsList={convertingColumnsWithGroupsIntoRegularOnesForUseInMobileFilters(
            columns,
          )}
        />
      </ErrorBoundary>
    </>
  );
};

export default CostsTable;

CostsTable.propTypes = {
  costsDataList: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
  taskData: PropTypes.object.isRequired,
  apiEndpoint: PropTypes.string.isRequired,
  visibleFilters: PropTypes.bool,
  tableFilters: PropTypes.any,
  handleUpdateList: PropTypes.func.isRequired,
  refreshTaskInfo: PropTypes.func.isRequired,
};
