import { restApiHost } from '../../../utils/appVariables';
import { call } from '../../../apiUtils/call';
import { message } from 'antd';

export default async function getHistoryDigestSubs(
  customerId,
  app,
  model,
  userInfo,
) {
  try {
    const url = `${restApiHost}/history/digest_subs?app=${app}&model=${model}&id=${customerId}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (json?.data?.subs) {
      return json.data.subs;
    }
  } catch (e) {
    console.log('get history digest_subs err', e);
    message.error('Ошибка получения списка разделов истории изменений');
  }
}

export async function getHistoryDigest(params, page = 1, userInfo) {
  try {
    const url = `${restApiHost}/history/digest${params}&page=${page}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (json?.data) {
      return json.data;
    }
  } catch (e) {
    console.log('get history history_digest err', e);
    message.error('Ошибка получения истории изменений');
  }
}
