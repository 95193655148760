import React, { useCallback, useEffect } from 'react';
import { Col, Form, Input, InputNumber, Row, Select } from 'antd';
import useCheckMobileScreen from '../../../../hooks/useCheckMobileScreen';
import useStateRef from 'react-usestateref';

const { Option } = Select;

const AddTransport = ({
  //waypoints,
  data,
  options,
  onSearchTransportOptions,
  handleFormChange,
  disableSelect,
  forShippingTask,
}) => {
  const [addTransportForm] = Form.useForm();
  const [, setTransportIsPlatform, transportIsPlatformRef] = useStateRef(false);
  const isMobile = useCheckMobileScreen();

  useEffect(async () => {
    await handleSetTransportForm(data);
  }, [data]);

  const handleSetTransportForm = useCallback(
    async (data) => {
      if (!data) {
        addTransportForm.resetFields();
        return;
      }

      setTransportIsPlatform(data?.transport_unit?.includable);
      addTransportForm.setFieldsValue({
        ...data,
        transport_unit: forShippingTask
          ? data.transport_unit?.id || null
          : data.transport_unit?.id || data.transport_unit || null,
        waypoint: data.waypoint?.id || data.waypoint || null,
      });

      data.transport_unit?.type_full && !forShippingTask
        ? await onSearchTransportOptions(data.transport_unit?.type_full)
        : await onSearchTransportOptions(null, data.transport_unit?.id);

      await onSearchTransportOptions();
    },
    [data],
  );

  const onChangeTransportSelect = useCallback(
    (option) => {
      if (option['data-value']?.length) {
        const fullOption = JSON.parse(option['data-value']) ?? null;
        setTransportIsPlatform(fullOption?.includable ?? false);
      }
    },
    [setTransportIsPlatform],
  );

  return (
    <Form
      layout={'vertical'}
      form={addTransportForm}
      onValuesChange={() =>
        setTimeout(() => {
          const includable = transportIsPlatformRef.current;
          handleFormChange(addTransportForm, includable);
        }, 0)
      }
    >
      <Row gutter={5}>
        <Col xs={!transportIsPlatformRef.current && forShippingTask ? 12 : 24}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Обязательно для заполнения!',
              },
            ]}
            name={'transport_unit'}
            label={'Транспорт'}
          >
            <Select
              getPopupContainer={(trigger) =>
                isMobile ? trigger.parentNode : document.body
              }
              showSearch
              disabled={disableSelect}
              placeholder="Поиск транспорта"
              filterOption={false}
              onSearch={(keyword) => {
                onSearchTransportOptions(keyword);
              }}
              onChange={(data, option) => {
                onChangeTransportSelect(option);
              }}
            >
              {options && options.length > 0 ? (
                <>
                  {Array.isArray(options) &&
                    options.map((item) => {
                      return (
                        <Option
                          key={item.id}
                          value={item.id}
                          data-value={JSON.stringify(item)}
                        >
                          {item?.type_transport
                            ? item?.type_transport
                            : item?.type_full || item?.type_short}
                        </Option>
                      );
                    })}
                </>
              ) : (
                <Option disabled value="Загрузка">
                  Загрузка
                </Option>
              )}
            </Select>
          </Form.Item>
        </Col>
        {forShippingTask && !transportIsPlatformRef.current && (
          <Col xs={12}>
            <Form.Item name={'number_container'} label={'Номер контейнера'}>
              <Input placeholder={'Номер контейнера'} />
            </Form.Item>
          </Col>
        )}
        {forShippingTask && (
          <>
            <Col xs={12}>
              <Form.Item name={'platform'} label={'Платформа'}>
                <Input placeholder={'Платформа'} />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item name={'platform_number'} label={'Номер'}>
                <Input placeholder={'Номер'} />
              </Form.Item>
            </Col>
          </>
        )}
        {!forShippingTask && (
          <Col lg={24} md={24} xs={24}>
            <Form.Item name={'count'} label={'Количество'}>
              <InputNumber
                disabled={disableSelect}
                placeholder={'Количество'}
                defaultValue={1}
                min={1}
                max={100000}
              />
            </Form.Item>
          </Col>
        )}
      </Row>
    </Form>
  );
};

export default AddTransport;
