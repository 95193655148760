import dayjs from 'dayjs';

export default function getIndexWeekByDay(
  monthDataWeeks,
  day = dayjs().format('DD-MM-YY'),
) {
  for (const [index, week] of monthDataWeeks.entries()) {
    for (const dayWeek of week) {
      if (dayWeek.format('DD-MM-YY') === day) {
        return index;
      }
    }
  }
  return 0;
}
