import { Button, Col, Form, message, Modal, Row, Space, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { CheckOutlined, InboxOutlined } from '@ant-design/icons';
import Dragger from 'antd/es/upload/Dragger';
import PdfViewerModal from '../../contracts/templeteContract/PdfViewerModal';
import { call } from '../../../apiUtils/call';
import { userInformations } from '../../../contex';
import getBlobFromUrl from '../../contracts/genereteContracts/getBlobFromUrl';
import { restApiHost } from '../../../utils/appVariables';
import { ErrorBoundary } from '../../ErrorBoundary';

const RefBookContractModal = ({
  visible,
  editItem,
  deleteItem,
  form,
  submitForm,
  setDeleteItem,
  fields,
  getComponent,
  handleCancelModal,
  contactPlaceholders,
}) => {
  const [visibleModal, setVisible] = useState(false);
  const [, setTemplateContractHtml] = useState(``);
  const [disablePreviewContractTemplate, setDisablePreviewContractTemplate] =
    useState(true);
  const [loading] = useState(false);
  const [downloadNewTemplate, setDownloadNewTemplate] = useState(true);
  const [visiblePreviewContractModal, setVisiblePreviewContractModal] =
    useState(false);
  const [fileList, setFileList] = useState([]);
  const [formFileUrl, setFormFileUrl] = useState(null);
  const { userInfo } = useContext(userInformations);
  const [pdfFileUrl, setPdfFileUrl] = useState(null);

  const props = {
    beforeUpload: () => false,
    accept: '.docx',
    maxCount: 1,
    async onChange({ fileList }) {
      /* if (file?.status !== 'uploaded' && file?.status !== 'removed') {
                setLoading(true)
                await getHtmlFromDocxFile(file)
                setLoading(false)
            }*/
      setFileList(fileList);
    },
    multiple: false,
    fileList,
  };

  useEffect(() => {
    if (form) {
      const fileUrl = form.getFieldValue('file');
      if (!fileUrl) {
        setDownloadNewTemplate(false);
      } else {
        setDisablePreviewContractTemplate(false);
        setFormFileUrl(`${restApiHost}${fileUrl}`);
      }
    }
  }, [form, visibleModal]);

  useEffect(() => {
    setVisible(visible);
    if (!visible) {
      clearUp();
    }
  }, [visible]);

  useEffect(() => {
    if (!visibleModal) {
      clearUp();
    }
  }, [visibleModal]);

  /*  const getHtmlFromDocxFile = async (docxFile) => {
        if (docxFile) {
            try {
             const html = await convertDocxToHtml(docxFile)
                if (html) {
                    setTemplateContractHtml(html)
                    setDisablePreviewContractTemplate(false)
                }
            } catch (e) {
                console.error(e)
                message.error('Ошибка конвертации docx в html')
            }
        }
    }*/

  const submit = (values, editItem, deleteItem) => {
    const resValue = { ...values };
    if (fileList[0]?.originFileObj) {
      resValue['file'] = fileList[0].originFileObj;
    }
    editItem
      ? deleteItem
        ? submitForm(resValue, editItem, deleteItem)
        : submitForm(resValue, editItem, null, true)
      : submitForm(resValue, null, null, true);
    //clearUp()
  };

  const clearUp = () => {
    setFileList([]);
    setPdfFileUrl(null);
    setFormFileUrl(null);
    //setTemplateContractHtml(``)
    setDisablePreviewContractTemplate(true);
    setDownloadNewTemplate(true);
    setVisiblePreviewContractModal(false);
  };

  const getPdfFileFromDocxFileUrl = async (fileUrl) => {
    try {
      if (!fileUrl) {
        return;
      }
      const filename = fileUrl.split('/').pop();
      const blob = await getBlobFromUrl(fileUrl);
      const pdfFileUrl = await getPdfFile(blob, filename);
      if (!pdfFileUrl) {
        message.error('Ошибка генерации PDF файла');
        return;
      }
      setPdfFileUrl(pdfFileUrl);
      setVisiblePreviewContractModal(true);
    } catch (e) {
      console.log('Ошибка генерации PDF', e);
    }
  };

  const getPdfFileFromDocxFile = async (file) => {
    try {
      if (!file?.originFileObj) {
        return;
      }
      const pdfFileUrl = await getPdfFile(
        file?.originFileObj,
        file?.originFileObj?.name,
      );
      if (!pdfFileUrl) {
        message.error('Ошибка генерации PDF файла');
        return;
      }
      setPdfFileUrl(pdfFileUrl);
      setVisiblePreviewContractModal(true);
    } catch (e) {
      console.log('Ошибка генерации PDF', e);
    }
  };

  async function prepareAndGettingPdfFileUrl() {
    try {
      const hide = message.loading('Формирование PDF файла', 5);
      if (fileList[0]) {
        await getPdfFileFromDocxFile(fileList[0]);
      } else if (formFileUrl) {
        await getPdfFileFromDocxFileUrl(formFileUrl);
      }
      hide();
    } catch (e) {
      console.log('Ошибка генегации договора', e);
    }
  }

  const getPdfFile = async (blobFile, filename) => {
    if (!blobFile) {
      return;
    }
    try {
      const formData = new FormData();
      formData.append('file', blobFile, filename);
      const url = `${restApiHost}/functions/create_pdf_from_docx/`;
      const headers = {
        Authorization: `Token ${localStorage.getItem('token')}`,
      };
      const json = await call(
        url,
        {
          method: 'POST',
          headers,
          body: formData,
        },
        userInfo.current.haveViewBranch,
      );

      if (json?.data?.file) {
        return json.data.file;
      }
    } catch (e) {
      console.log(e);
      message.error('Ошибка запроса на генерацию pdf файла');
    }
  };

  const renderLabelAndTextForFormFile = (label) => {
    return (
      <>
        <Space size={1}>
          <div>{label}</div>
          <div>
            <CheckOutlined style={{ color: 'green', fontSize: '20px' }} />
          </div>
        </Space>
      </>
    );
  };

  return (
    <>
      <Modal
        title={`${editItem ? 'Редактирование' : 'Добавление'}`}
        visible={visibleModal}
        onCancel={handleCancelModal()}
        footer={[]}
      >
        <Spin spinning={loading}>
          <Form
            layout={'vertical'}
            form={form}
            onFinish={(values) => {
              submit(values, editItem, deleteItem);
            }}
          >
            <Row gutter={16}>
              {fields?.length > 0 &&
                fields.map((item) => {
                  return item[0] !== 'markup' && item[0] !== 'file'
                    ? getComponent(item[1]?.type, item)
                    : '';
                })}
              <Col lg={12} xs={24}>
                <Form.Item
                  style={{ overflow: 'hidden' }}
                  label={
                    downloadNewTemplate
                      ? renderLabelAndTextForFormFile(
                          'Шаблон договора уже загружен',
                        )
                      : 'Шаблон договора'
                  }
                >
                  <Dragger {...props}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      {downloadNewTemplate
                        ? 'Кликните или перенесите файл для замены шаблона'
                        : 'Кликните или перенесите файл для загрузки'}
                    </p>
                  </Dragger>
                </Form.Item>
              </Col>
            </Row>
            <Row justify={'end'} gutter={10}>
              {editItem ? (
                <Col>
                  <Button
                    type={'primary'}
                    disabled={disablePreviewContractTemplate}
                    onClick={async () => {
                      await prepareAndGettingPdfFileUrl();
                    }}
                  >
                    Предпросмотр
                  </Button>
                </Col>
              ) : (
                <></>
              )}
              <Col>
                <Button type={'primary'} htmlType={'submit'}>
                  {editItem ? 'Сохранить' : 'Добавить'}
                </Button>
              </Col>
              <Col style={{ marginLeft: '10px' }}>
                {editItem ? (
                  <Button
                    onClick={() => setDeleteItem(true)}
                    htmlType={'submit'}
                  >
                    {'Удалить'}
                  </Button>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
      <ErrorBoundary>
        <PdfViewerModal
          visible={visiblePreviewContractModal}
          setVisible={(visible) => setVisiblePreviewContractModal(visible)}
          pdfFileUrl={`${restApiHost}${pdfFileUrl}`}
          contactPlaceholders={contactPlaceholders}
          changeHtmlContact={(html) => setTemplateContractHtml(html)}
        />
      </ErrorBoundary>
    </>
  );
};

export default RefBookContractModal;
