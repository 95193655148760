import { restApiHost } from '../../utils/appVariables';
import { call } from '../call';
import { message } from 'antd';

export async function multipleEditFunction(appName, data, itemsIds, userInfo) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const body = {
      app_name: appName,
      data: data,
      items: itemsIds,
    };
    try {
      const url = `${restApiHost}/functions/bulk_update/`;
      const json = await call(
        url,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );
      if (json) {
        message.success('Редактирования успешно!');
        resolve('success');
      }
    } catch (e) {
      console.log('multiple edit err', e);
      message.error('Ошибка запроса на множественное редактирование');
      reject('bad');
    }
  });
}
