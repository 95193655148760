import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { restApiHost } from '../../../../../utils/appVariables';
import { call } from '../../../../../apiUtils/call';
import { Checkbox, Drawer, List, message, Spin } from 'antd';
import { userInformations } from '../../../../../contex';
import getSectionsForCurrentGroup from '../../getSectionsForCurrentGroup';

const SettingSectionsDrawer = (props) => {
  const { userInfo } = useContext(userInformations);
  const [sectionsCurrentGroup, setSectionsCurrentGroup] = useState([]);
  const [loading, setLoading] = useState(false);

  async function getSectionsForGroup() {
    setLoading(true);
    const sectionsJson = await getSectionsForCurrentGroup(
      props.groupId,
      props.branchId,
      userInfo,
    );
    if (sectionsJson) {
      setSectionsCurrentGroup(sectionsJson);
    }
    setLoading(false);
  }

  useEffect(async () => {
    if (props.groupId) {
      await getSectionsForGroup();
    }
  }, [props.groupId]);

  const changeVisibleSection = async (sectionId, visible) => {
    if (sectionId) {
      const body = {
        value: visible,
        branch: props.branchId,
      };
      await editVisibleSection(body, sectionId);
    }
  };

  const editVisibleSection = async (body, sectionId) => {
    try {
      const url = `${restApiHost}/accounts/group_section_visibility/${sectionId}/`;
      const json = await call(
        url,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      message.success('Отображение раздела успешно отредатировано!');
      await getSectionsForGroup();
    } catch (e) {
      console.log('create branch err', e);
      message.error('Ошибка запроса для создания филиала');
    }
  };

  const closeDrawer = () => {
    setSectionsCurrentGroup([]);
    props.closeSettingSectionsDrawer();
  };

  return (
    <>
      <Drawer
        title="Разделы"
        placement="right"
        onClose={() => closeDrawer()}
        visible={props.visible}
      >
        <Spin spinning={loading}>
          {sectionsCurrentGroup && sectionsCurrentGroup.length > 0 ? (
            <List
              size="small"
              dataSource={sectionsCurrentGroup}
              renderItem={(item) => (
                <List.Item>
                  <Checkbox
                    onChange={(e) =>
                      changeVisibleSection(item?.id, e.target.checked)
                    }
                    checked={item?.value}
                  >
                    <span style={{ color: 'black' }}>{item?.section}</span>
                  </Checkbox>
                </List.Item>
              )}
            />
          ) : (
            <>
              <List.Item>
                <span style={{ color: 'black' }}>Разделы отсутствуют</span>
              </List.Item>
            </>
          )}
        </Spin>
      </Drawer>
    </>
  );
};

SettingSectionsDrawer.props = {
  groupId: PropTypes.number,
  branchId: PropTypes.number,
  visible: PropTypes.bool,
  closeSettingSectionsDrawer: PropTypes.func,
};

export default SettingSectionsDrawer;
