import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ErrorBoundary } from '../ErrorBoundary';
import MessageInput from './messegeInput/MessegeInput';
import PropTypes from 'prop-types';
import './Chat.css';
import MessagesList from './messeges/MessegesList';
import useWebSocket from 'react-use-websocket';
import { werSocketHost } from '../../utils/appVariables';
import {
  getChatsMessages,
  sendChatMessage,
  viewAllChatMessages,
} from '../../logic/chat/ChatApiFunction';
import { userInformations } from '../../contex';
import { Spin } from 'antd';
import { globalStore } from '../../store';
import { useTypedSelector } from '../../store/hooks/useTypedSelector';
import { ActionActiveChatIdTypes } from '../../store/todo/activeChatId/activeChatId';

const Chat = (props) => {
  const { userInfo } = useContext(userInformations);
  const [messages, setMessages] = useState([]);
  const WS_URL = `${werSocketHost}/pipe/?token=Token ${localStorage.getItem(
    'token',
  )}`;
  const { lastJsonMessage } = useWebSocket(WS_URL, { share: true });
  const [page] = useState(1);
  const [totalMessages, setTotalMessages] = useState(0);
  const virtuosoRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const activeChatId = useTypedSelector((state) => state.activeChatId.chatId);

  useEffect(async () => {
    if (props.chatId) {
      globalStore.dispatch({
        type: ActionActiveChatIdTypes.SET_ACTIVE_CHAT_ID,
        payload: props.chatId,
      });
      globalStore.dispatch({
        type: ActionActiveChatIdTypes.SET_WAS_TASK_CARD_OPENING,
        payload: true,
      });
      await handleGetChatMessages(0, true);
      await handleViewAllChatMessages(props.chatId);
    }
  }, [props.chatId]);

  useEffect(() => {
    return () => {
      setTotalMessages(0);
      setMessages([]);
      virtuosoRef.current = null;
      globalStore.dispatch({
        type: ActionActiveChatIdTypes.SET_ACTIVE_CHAT_ID,
        payload: null,
      });
    };
  }, []);

  useEffect(() => {
    if (lastJsonMessage?.type === 'chat_message.new' && activeChatId !== null) {
      console.log('lastJsonMessage');
      addNewMessage(lastJsonMessage?.data);
    }
    if (lastJsonMessage?.type === 'websocket.disconnect') {
      console.log(lastJsonMessage, 'websocket.disconnect');
    }
  }, [lastJsonMessage]);

  const handleGetChatMessages = useCallback(
    async (offset, setLoad) => {
      if (!props.chatId) return;
      setLoad && setLoading(true);
      const res = await getChatsMessages(props.chatId, offset, userInfo);
      if (res?.count) {
        setTotalMessages(res.count);
      }
      if (res?.data?.length > 0) {
        setMessages((prevState) => res?.data.reverse().concat(prevState));
        // await handleViewMessage(res?.data)
      }
      setLoad && setLoading(false);
    },
    [props.chatId],
  );

  const handleViewAllChatMessages = useCallback(
    async (chatId) => {
      if (!chatId) return;
      await viewAllChatMessages(chatId, userInfo);
    },
    [userInfo],
  );

  const loadMoreMessages = async () => {
    console.log('loadMoreMessages');
    const offset = messages?.length;
    if (offset !== null && offset !== undefined) {
      await handleGetChatMessages(offset);
    }
  };

  const handleSendMessage = useCallback(
    async (messageText) => {
      if (!props.chatId) return;
      const body = {
        chat: props.chatId,
        text: messageText,
      };
      await sendChatMessage(body, userInfo);
    },
    [props.chatId],
  );

  /*const handleViewMessage = useCallback(
    async (messages) => {
      if (messages.length <= 0) return;
      const notViewMessages = messages.filter(
        (message) =>
          !message?.cu_viewed &&
          message?.creator?.staff_id !== userInfo?.current?.id,
      );
      if (notViewMessages?.length > 0 && props.chatId) {
        const body = {
          chat: props.chatId,
          messages: notViewMessages?.map((item) => item?.id),
        };
        const successfulViewed = await viewChatMessages(body, userInfo);
        if (successfulViewed) {
          globalStore.dispatch({
            type: TodoActionTypes.SET_WAS_VIEWING_MESSAGE,
            payload: true,
          });
        }
      }
    },
    [props.chatId],
  );*/

  const addNewMessage = (messageData) => {
    setMessages((prevState) => prevState.concat([messageData]));
    setTotalMessages((prevState) => prevState++);

    if (
      virtuosoRef?.current &&
      messageData?.creator?.staff_id === userInfo.current?.id
    ) {
      virtuosoRef.current.scrollToIndex({
        index: messages.length - 1,
        behavior: 'smooth',
      });
    }
  };
  return (
    <>
      <div className={'chat-wrapper'} style={{ height: '88%', width: '100%' }}>
        {props.chatIsCreated ? (
          <div
            className={'chat-content'}
            style={{ width: '100%', height: '100%' }}
          >
            {loading ? (
              <Spin
                spinning={true}
                style={{ position: 'relative', top: '50%', left: '50%' }}
              />
            ) : (
              <ErrorBoundary>
                <div className={'Chat-body'}>
                  <div className={'Chat-body-content'}>
                    <MessagesList
                      chatId={props.chatId}
                      messagesList={messages}
                      loading={loading}
                      page={page}
                      totalMessages={totalMessages}
                      loadMoreMessages={async () => {
                        await loadMoreMessages();
                      }}
                      virtuosoRef={virtuosoRef}
                    />
                  </div>
                </div>
              </ErrorBoundary>
            )}

            <ErrorBoundary>
              <MessageInput
                sendMessage={(messageText) => handleSendMessage(messageText)}
              />
            </ErrorBoundary>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

Chat.propTypes = {
  chatId: PropTypes.number.isRequired,
  chatIsCreated: PropTypes.bool.isRequired,
};

export default Chat;
