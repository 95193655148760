import PropTypes from 'prop-types';
import { Drawer, message } from 'antd';
import { EmailEditor } from 'react-email-editor';
import { useContext, useRef } from 'react';
import { ErrorBoundary } from '../ErrorBoundary';
import PlaceholdersList from '../refbook/refbookCP/PlaceholdersList';
import { call } from '../../apiUtils/call';
import { userInformations } from '../../contex';
import { restApiHost } from '../../utils/appVariables';
import { getBranchId } from '../../localStorageUtils/getBranchId';

const CpEditorDrawer = (props) => {
  const emailEditorRef = useRef(null);
  const optionsEditor = {
    locale: 'ru',
    features: {
      preview: false,
    },
    defaultDevice: 'desktop',
    devices: ['desktop'],
    fonts: {
      showDefaultFonts: true,
      customFonts: [
        {
          label: 'Comic Sans',
          value: "'Comic Sans MS', cursive, sans-serif",
        },
        {
          label: 'DotGothic16',
          value: "'DotGothic16',cursive",
          url: 'https://fonts.googleapis.com/css?family=DotGothic16',
        },
        {
          label: 'RUBIK',
          value: "'RUBIK',cursive",
          url: 'https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Rubik&display=swap',
        },
      ],
    },
  };
  const placeholderList = [
    {
      placeholdersName: '{{branchName}}',
      translateName: 'Название компании',
    },
    {
      placeholdersName: '{{shoulderTable}}',
      translateName: 'Таблицы с плечами/суммами',
    },
    {
      placeholdersName: '{{services}}',
      translateName: 'Список услуг',
    },
    {
      placeholdersName: '{{signature}}',
      translateName: 'Подпись(email)',
    },
  ];
  const { userInfo } = useContext(userInformations);

  /*useEffect(() => {
        if (emailEditorRef.current && props.jsonDesign) {
            emailEditorRef.current.loadDesign(props.jsonDesign)
        }
    }, [emailEditorRef])*/

  const closeDrawerAndExportHtmlAndJsonDesign = async () => {
    if (emailEditorRef?.current?.editor) {
      emailEditorRef.current.editor.exportHtml((data) => {
        props.closeDrawer(data?.html, data?.design);
      });
    }
  };

  const onReady = () => {
    if (emailEditorRef.current) {
      if (props.jsonDesign) {
        emailEditorRef.current.loadDesign(props.jsonDesign);
      } else {
        emailEditorRef.current.editor.setBodyValues({
          backgroundColor: '#FFFFFF !important',
          contentWidth: '735px',
        });
      }
    }

    emailEditorRef.current?.editor.registerCallback(
      'image',
      function (file, done) {
        const formData = new FormData();
        formData.append('file', file.attachments[0]);
        formData.append('cpt', props.cpTemplateId);
        if (userInfo.current?.haveViewBranch) {
          const branchId = getBranchId().toString();
          formData.append('branch', branchId);
        }
        const url = `${restApiHost}/base/comm_prop_template_images/`;
        call(
          url,
          {
            method: 'POST',
            headers: {
              Authorization: `Token ${localStorage.getItem('token')}`,
            },
            body: formData,
          },
          userInfo.current.haveViewBranch,
        )
          .then((response) => {
            // Make sure the response was valid
            if (response.status >= 200 && response.status < 300) {
              return response;
            } else {
              const error = new Error(response.statusText);
              error.response = response;
              message.error('Ошибка загрузки изображения');
              console.log(error);
            }
          })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            // Pass the URL back to Unlayer to mark this upload as completed
            done({ progress: 100, url: data?.file });
          });
      },
    );
  };

  return (
    <Drawer
      className={'global-style-drawer'}
      visible={props.visible}
      placement="right"
      title={'Шаблон коммерческого предложения'}
      //width={'Calc(100% - 255px'}
      bodyStyle={{ display: 'flex', padding: 0, overflow: 'hidden' }}
      onClose={() => closeDrawerAndExportHtmlAndJsonDesign()}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
        }}
      >
        <ErrorBoundary>
          <div style={{ padding: '10px' }}>
            <PlaceholdersList placeholders={placeholderList} />
          </div>
        </ErrorBoundary>
        <ErrorBoundary>
          <EmailEditor
            ref={emailEditorRef}
            options={optionsEditor}
            onReady={onReady}
          />
        </ErrorBoundary>
      </div>
    </Drawer>
  );
};

CpEditorDrawer.props = {
  visible: PropTypes.bool,
  html: PropTypes.string,
  jsonDesign: PropTypes.any,
  cpTemplateId: PropTypes.string,
  closeDrawer: PropTypes.func,
};
export default CpEditorDrawer;
