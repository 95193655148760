import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Card, Col, Divider, Dropdown, Row, Space } from 'antd';
import EllipsisOutlined from '@ant-design/icons/lib/icons/EllipsisOutlined';
import dayjs from 'dayjs';

const TaskWayPointsListItem = (props) => {
  const cardTitle = useCallback(() => {
    return (
      <>
        <Row justify={'space-between'} wrap={false}>
          <Col>
            <Col className={'mainText'}>
              <Col xs={24}>
                <div className={'secondText'}>{'Операция'}</div>
                <div className={'valueText'}>
                  {props.wayPoint?.operation?.name || '-'}
                </div>
              </Col>
            </Col>
          </Col>
          <Row justify={'end'}>
            <Space size="middle" align={'start'}>
              <Dropdown
                overlay={props.menuActions(props.wayPoint)}
                trigger={['click']}
              >
                <EllipsisOutlined
                  style={{ cursor: 'pointer', fontSize: '24px' }}
                />
              </Dropdown>
            </Space>
          </Row>
        </Row>
      </>
    );
  }, [props.wayPoint, props.menuActions]);

  return (
    <>
      <Card className={'mainCard'} bodyStyle={{ padding: '12px' }}>
        {cardTitle()}
        <Divider style={{ margin: '10px 0' }} />

        <Row gutter={[0, 10]}>
          <Col xs={24}>
            <div className={'secondText'}>{'Пункт'}</div>
            <div className={'valueText'}>
              {props.wayPoint?.waypoint?.name || '-'}
            </div>
          </Col>

          <Col xs={24}>
            <div className={'secondText'}>{'Контрагент'}</div>
            <div className={'valueText'}>
              {props.wayPoint?.agent_line || '-'}
            </div>
          </Col>

          <Col xs={24}>
            <div className={'secondText'}>{'Контактное лицо'}</div>
            <div className={'valueText'}>
              {props.wayPoint?.phone_line || '-'}
            </div>
          </Col>

          <Col xs={24}>
            <div className={'secondText'}>{'Адрес'}</div>
            <div className={'valueText'}>{props.wayPoint?.address || '-'}</div>
          </Col>

          <Col xs={10}>
            <div className={'secondText'}>{'Дата'}</div>
            <div className={'valueText'}>
              {props.wayPoint?.load_plan
                ? dayjs(props.wayPoint?.load_plan).format('DD-MM-YYYY')
                : '-'}
            </div>
          </Col>

          <Col xs={14}>
            <div className={'secondText'}>{'Планируемое вр. погрузки'}</div>
            <div className={'valueText'}>
              {props.wayPoint?.load_plan
                ? dayjs(props.wayPoint?.load_plan).format('HH:mm')
                : '-'}
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default TaskWayPointsListItem;

TaskWayPointsListItem.propTypes = {
  wayPoint: PropTypes.object,
  menuActions: PropTypes.func,
};
