import PropTypes from 'prop-types';
import { Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';

const MultipleSelectUseSearch = (props) => {
  const refSelect = useRef(null);
  const [visibleSelectOptions, setVisibleSelectOptions] = useState(false);
  const isMobile = useCheckMobileScreen();

  const onKeyUp = (e) => {
    if (e.key === 'Enter') {
      setVisibleSelectOptions(false);
      refSelect?.current?.blur();
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', onKeyUp);
    return () => window.removeEventListener('keyup', onKeyUp);
  }, [visibleSelectOptions]);

  const renderOptions = () => {
    return props.options;
  };
  return (
    <Select
      getPopupContainer={(trigger) =>
        isMobile ? trigger.parentNode : document.body
      }
      ref={refSelect}
      style={props.style}
      open={visibleSelectOptions}
      value={props.value}
      mode={props.mode ? props.mode : 'tags'}
      maxTagCount={'responsive'}
      placeholder={props.placeholder}
      onChange={(values) => {
        props.onChange(values);
      }}
      onSearch={(search) => {
        props.onSearch(search);
      }}
      onInputKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.stopPropagation();
        }
      }}
      onDropdownVisibleChange={(visible) => {
        setVisibleSelectOptions(visible);
      }}
    >
      {renderOptions()}
    </Select>
  );
};

export default MultipleSelectUseSearch;

MultipleSelectUseSearch.propTypes = {
  value: PropTypes.any,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.any,
  onSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.any.isRequired,
  style: PropTypes.any,
  mode: PropTypes.string,
};
