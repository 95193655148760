import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Card, Col, Divider, Dropdown, Row, Space } from 'antd';
import EllipsisOutlined from '@ant-design/icons/lib/icons/EllipsisOutlined';
import { priceFormatter } from '../../../../utils/priceFormatter';

const ServicesListItem = (props) => {
  const cardTitle = useCallback(() => {
    return (
      <>
        <Row justify={'space-between'} wrap={false}>
          <Col>
            <Col className={'mainText'}>
              {props.cost?.operation?.name || '-'}
            </Col>
          </Col>
          <Row justify={'end'}>
            <Space size="middle" align={'start'}>
              <Dropdown
                overlay={props.menuActions(props.cost)}
                trigger={['click']}
              >
                <EllipsisOutlined
                  style={{ cursor: 'pointer', fontSize: '24px' }}
                />
              </Dropdown>
            </Space>
          </Row>
        </Row>
      </>
    );
  }, [props.cost, props.menuActions]);

  function renderCurrency(currency) {
    return currency === 'rub'
      ? 'Рубли'
      : currency === 'dol'
      ? 'Доллар'
      : currency === 'eur'
      ? 'Евро'
      : currency === 'cny'
      ? 'Юань '
      : '-';
  }

  return (
    <>
      <Card className={'mainCard'} bodyStyle={{ padding: '12px' }}>
        {cardTitle()}
        <Divider style={{ margin: '10px 0' }} />

        <Row gutter={[0, 10]}>
          <Row gutter={25}>
            <Col>
              <div className={'secondText'}>{'Наименование'}</div>
              <div className={'valueText'}>
                {props.cost?.service?.name || '-'}
              </div>
            </Col>
            <Col>
              <div className={'secondText'}>{'Валюта'}</div>
              <div className={'valueText'}>
                {renderCurrency(props.cost?.currency) || '-'}
              </div>
            </Col>
            <Col>
              <div className={'secondText'}>{'Цена'}</div>
              <div className={'valueText'}>
                {props.cost?.in_currency
                  ? priceFormatter(+props.cost?.price_currency)
                  : priceFormatter(props.cost?.price) || '-'}
              </div>
            </Col>
          </Row>

          <Row gutter={25}>
            <Col>
              <div className={'secondText'}>{'Кол-во тран. ед.'}</div>
              <div className={'valueText'}>
                {props.cost?.transport?.transport?.count
                  ? props.cost.transport.transport.count
                  : 1 || '-'}
              </div>
            </Col>
            <Col>
              <div className={'secondText'}>{'Кол-во услуг'}</div>
              <div className={'valueText'}>
                {props.cost?.count_services || '-'}
              </div>
            </Col>

            <Col>
              <div className={'secondText'}>{'Ед. изм.'}</div>
              <div className={'valueText'}>
                {props.cost?.service?.units || '-'}
              </div>
            </Col>
          </Row>

          <Row gutter={25}>
            <Col>
              <div className={'secondText'}>{'Ставка'}</div>
              <div className={'valueText'}>
                {props.cost?.vat_rate_str || '-'}
              </div>
            </Col>
            <Col>
              <div className={'secondText'}>{'Валюта (НДС)'}</div>
              <div className={'valueText'}>
                {props.cost?.sum_vat === 0 ? '0' : '-'}
              </div>
            </Col>
          </Row>

          <Row gutter={25}>
            <Col>
              <div className={'secondText'}>{'Кол-во тран. ед.'}</div>
              <div className={'valueText'}>
                {props.cost?.transport?.transport?.count
                  ? props.cost.transport.transport.count
                  : 1 || '-'}
              </div>
            </Col>
            <Col>
              <div className={'secondText'}>{'Кол-во услуг'}</div>
              <div className={'valueText'}>
                {props.cost?.count_services || '-'}
              </div>
            </Col>

            <Col>
              <div className={'secondText'}>{'Ед. изм.'}</div>
              <div className={'valueText'}>
                {props.cost?.service?.units || '-'}
              </div>
            </Col>
          </Row>

          <Col xs={24} gutter={25}>
            <Col>
              <div className={'valueText'}>{'Всего'}</div>
              <div className={'valueText'}>
                {props.cost?.in_currency
                  ? priceFormatter(props.cost?.sum_currency?.sum_with_vat || 0)
                  : priceFormatter(props.cost.sum_with_vat || 0) || '-'}
              </div>
            </Col>
          </Col>
        </Row>
      </Card>
    </>
  );
};

ServicesListItem.propTypes = {
  cost: PropTypes.object,
  menuActions: PropTypes.func,
};

export default ServicesListItem;
