import PropTypes from 'prop-types';
import '../../leads/list/leadListItem.css';
import React, { useCallback } from 'react';
import { Card, Col, Divider, Row } from 'antd';

const PermissionsListItem = (props) => {
  const cardTitle = useCallback(() => {
    return (
      <>
        <Col>
          <Col className={'mainText'}>{props.permission?.name || '-'}</Col>
        </Col>
      </>
    );
  }, [props.permission]);

  const renderCheckbox = () => {
    const x = `item.render(props.permission)`;
    return eval(x);
  };

  return (
    <>
      <Card className={'mainCard'} bodyStyle={{ padding: '12px' }}>
        {cardTitle()}
        <Divider style={{ margin: '10px 0' }} />

        <Row gutter={[0, 15]}>
          {props.fields?.map((item) => {
            return (
              item.key !== 'name' && (
                <Col xs={24}>
                  <div className={'secondText'}>{`${item?.title}`}</div>
                  <div className={'valueText'}>{renderCheckbox(item)}</div>
                </Col>
              )
            );
          })}
        </Row>
      </Card>
    </>
  );
};

PermissionsListItem.propTypes = {
  permission: PropTypes.object,
  fields: PropTypes.arrayOf(PropTypes.object),
};

export default PermissionsListItem;
