import { Tree } from 'antd';
import { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getListTags } from '../../../../logic/refbook/calendarTags/CalendarTagsApiFunctions';
import { userInformations } from '../../../../contex';

const TreeFilters = (props) => {
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [taskTags, setTaskTags] = useState([]);
  const { userInfo } = useContext(userInformations);

  useEffect(() => {
    callGetTagsList().then((tags) => {
      setTaskTags(tags);
    });
  }, []);

  const callGetTagsList = async () => {
    const tags = await getListTags(userInfo);
    if (tags?.data?.length > 0) {
      return tags.data;
    }
  };

  const onExpand = (expandedKeysValue) => {
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  const getTreeData = useCallback((tags) => {
    return [
      {
        title: 'Тип',
        key: 'group',
        checkable: false,
        children: [
          {
            title: 'Входящие',
            key: 'in',
            val: 'in',
          },
          {
            title: 'Исходящие',
            key: 'out',
            val: 'out',
          },
        ],
      },
      {
        title: 'Статусы',
        key: 'statuses',
        checkable: false,
        children: [
          {
            title: 'Поставлена',
            key: 'ass',
            val: 'ass',
          },
          {
            title: 'Выполнена',
            key: 'don',
            val: 'don',
          },
          {
            title: 'Не выполнена',
            key: 'unc',
            val: 'unc',
          },
        ],
      },
      {
        title: 'Тэги',
        key: 'tag',
        checkable: false,
        children:
          tags?.length > 0 &&
          tags.map((tag) => {
            return {
              title: tag?.name,
              key: tag?.id,
              val: tag?.id,
            };
          }),
      },
    ];
  }, []);

  const onCheck = (checkedKeysValue) => {
    const typesSet = new Set();
    const statusesSet = new Set();
    const tagsSet = new Set();
    if (checkedKeysValue?.length > 0) {
      checkedKeysValue.map((item) => {
        if (item === 'in' || item === 'out') {
          typesSet.add(item);
        } else if (item === 'ass' || item === 'don' || item === 'unc') {
          statusesSet.add(item);
        } else if (typeof item === 'number') {
          tagsSet.add(item);
        }
      });
    }
    const arrayTypes = [...typesSet];
    const arrayStatuses = [...statusesSet];
    const arrayTags = [...tagsSet];

    const filters = {
      filter: {
        status: arrayStatuses.join(','),
        type: arrayTypes.join(','),
        tags__tag__id: arrayTags.join(','),
      },
    };
    setCheckedKeys(checkedKeysValue);
    props.handleUpdateTask(filters);
  };

  return (
    <>
      <Tree
        style={{ fontSize: '16px', color: 'black' }}
        checkable
        selectable={false}
        onExpand={onExpand}
        expandedKeys={expandedKeys}
        autoExpandParent={autoExpandParent}
        onCheck={(keys) => onCheck(keys)}
        checkedKeys={checkedKeys}
        treeData={getTreeData(taskTags)}
      />
    </>
  );
};

TreeFilters.propTypes = {
  handleUpdateTask: PropTypes.func.isRequired,
};

export default TreeFilters;
