import PropTypes from 'prop-types';
import { getMonthName } from '../../calendarUtils/getMonthName';
import { getTodayClass } from './getTodayClass';
import 'dayjs/locale/ru';

const DayOfMonthHeader = (props) => {
  return (
    <div
      onDoubleClick={() => {
        props.useDoubleClick && props.handleCreateTask(props.day);
      }}
      style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
    >
      {props.rowIndex === 0 ? (
        <div>{props.day.locale('ru').format('dd').toUpperCase()}</div>
      ) : (
        <></>
      )}
      {props.day.date() === 1 && !props.timePeriodTitle ? (
        <div style={{ fontWeight: 'bold' }}>
          <div
            onClick={() => {
              if (!props.useForDayViewCalendar) {
                props.goToCompetitionDate(props.day);
              }
            }}
            style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
            className={getTodayClass(
              props.day.format('DD-MM-YY'),
              props.useForDayViewCalendar,
            )}
          >
            <div>{props.day.date()}</div>
            <div style={{ marginLeft: '5px' }}>
              {getMonthName(props.day.month())}
            </div>
          </div>
          <div>{props.timePeriodTitle ? `(${props.timePeriodTitle})` : ''}</div>
        </div>
      ) : (
        <div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div
              onClick={() => {
                if (!props.useForDayViewCalendar) {
                  props.goToCompetitionDate(props.day);
                }
              }}
              className={getTodayClass(
                props.day.format('DD-MM-YY'),
                props.useForDayViewCalendar,
              )}
            >
              {props.day.date()}
            </div>
            {props.timePeriodTitle ? (
              <div style={{ marginLeft: '5px' }}>
                {`(${props.timePeriodTitle})`}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
DayOfMonthHeader.PropTypes = {
  day: PropTypes.any,
  rowIndex: PropTypes.number,
  useDoubleClick: PropTypes.bool,
  useForDayViewCalendar: PropTypes.bool,
  handleCreateTask: PropTypes.func,
  goToCompetitionDate: PropTypes.func,
  timePeriodTitle: PropTypes.string,
};

export default DayOfMonthHeader;
