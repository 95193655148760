import { Formik } from 'formik';
import { Box, Button, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import * as Yup from 'yup';
import MuiAlert from '@material-ui/lab/Alert';

const EmailCodeRequest = ({
  sendCode,
  loadingSendCode,
  handleCloseSnack,
  seconds,
  timerActive,
  userEmail,
  errorSendEmail,
  backToStepTwo,
  errorText,
}) => {
  const [, setDisableRequestCodeBtn] = useState(false);
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  return (
    <>
      {errorSendEmail ? (
        <Box sx={{ py: 2 }}>
          <Alert onClose={handleCloseSnack} severity="error">
            {errorText}
          </Alert>
        </Box>
      ) : null}

      <Formik
        initialValues={{
          email: userEmail ? userEmail : '',
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email('Email должен быть валиден')
            .max(255)
            .required('Пожалуйста, введите email'),
        })}
        onSubmit={(values) => {
          sendCode(values);
          setDisableRequestCodeBtn(true);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          //isSubmitting,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box sx={{ mb: 3 }}>
              <Typography color="textPrimary" variant="h2">
                Восстановление пароля
              </Typography>
              <Typography color="textSecondary" gutterBottom variant="body2">
                CRM система КвикТранс
              </Typography>
            </Box>
            <TextField
              disabled={timerActive}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              label="Email"
              margin="normal"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
              variant="outlined"
            />
            {timerActive ? (
              <>
                <p>Запросить код повторно через: {seconds}</p>
                <p
                  onClick={() => {
                    backToStepTwo();
                  }}
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                >
                  Вернуться к вводу кода
                </p>
              </>
            ) : (
              <></>
            )}

            <Box sx={{ py: 2 }}>
              <Button
                disabled={timerActive}
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                {loadingSendCode ? 'Загрузка...' : 'Запросить код'}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default EmailCodeRequest;
