import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

import { Pie } from 'react-chartjs-2';

import { Card, CardContent } from '@material-ui/core';
import { ConfigProvider, Empty } from 'antd';
import { useMemo } from 'react';
import locale from 'antd/lib/locale/ru_RU';

ChartJS.register(ArcElement, Tooltip, Legend);

const TrafficByDevice = (props) => {
  //const theme = useTheme();

  const leeds = () => {
    if (props?.stat?.leads?.data) {
      return props?.stat?.leads?.data;
    } else {
      return [];
    }
  };

  const displayGraph = useMemo(() => {
    let display = false;
    props?.stat?.leads?.data.map((item) => {
      if (item.count) {
        display = true;
      }
    });
    return display;
  }, [props?.stat?.leads?.data]);

  const labels = () => {
    let arrLabels = [];
    if (props?.stat?.leads?.data?.length > 0) {
      arrLabels = props?.stat?.leads?.data.map((item) => item.color);
    }

    return arrLabels;
  };

  const colors = () => {
    // color_code
    let arrColors = [];
    if (props?.stat?.leads?.data?.length > 0) {
      arrColors = props?.stat?.leads?.data.map(
        (item) => `rgba(${item.color_code}, 0.3)`,
      );
    }

    return arrColors;
  };

  const afterBody = (tooltipItems) => {
    let lines = [];
    tooltipItems &&
      tooltipItems.forEach(function (tooltipItem) {
        return tooltipItem?.raw?.details.forEach((item) => {
          lines.push(`${item.status}: ${item.count}`);
        });
      });
    return lines;
  };

  const dataSet = {
    labels: labels(),
    datasets: [
      {
        label: '',
        data: leeds(),
        backgroundColor: colors(),
        borderColor: colors(),
        borderWidth: 1,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: `${
          props?.type === 'global' ? 'Общая статистика' : 'Статистика'
        } лидов (${props?.stat?.leads?.count_all || 0})`,
      },
      tooltip: {
        callbacks: {
          afterBody: afterBody,
        },
      },
    },
    parsing: {
      key: 'count',
    },
  };

  return (
    <Card sx={{ height: '100%' }} {...props}>
      <CardContent
        sx={{
          height: '100%',
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {displayGraph ? (
          <Pie data={dataSet} options={options} />
        ) : (
          <ConfigProvider locale={locale}>
            <Empty
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            />
          </ConfigProvider>
        )}
      </CardContent>
    </Card>
  );
};

export default TrafficByDevice;
