import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TimeStampWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
const TimeStampBody = styled.p`
  display: flex;
  margin: 10px auto;
  font-size: 13px;
  font-weight: 500;
  line-height: 115%;
  color: var(--accent-color);
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  padding: 5px 12px;
  background-color: rgba(77, 77, 77, 0.35);
  border-radius: 10px;
`;

const TimeStamp = (props) => {
  return (
    <>
      <TimeStampWrapper>
        <TimeStampBody>{props.date}</TimeStampBody>
      </TimeStampWrapper>
    </>
  );
};

TimeStamp.propTypes = {
  date: PropTypes.string,
};

export default TimeStamp;
