import React from 'react';
import { Box, Card, CardContent } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { Button as ButtonAntd, Input } from 'antd';
import {
  DownloadOutlined,
  FilterOutlined,
  PlusOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import ExcelExport from '../../__mocks__/excel-export/ExcelExport';
const { Search } = Input;

const DepartmentsListToolbar = ({ handleUpdate }) => {
  return (
    <Box>
      {/*<Box*/}
      {/*    sx={{*/}
      {/*        display: 'flex',*/}
      {/*        justifyContent: 'flex-end'*/}
      {/*    }}*/}
      {/*>*/}
      {/*    <Button style={{color: '#0b7b7f'}}>*/}
      {/*        Изменить*/}
      {/*    </Button>*/}
      {/*    <Button sx={{ mx: 1 }} style={{color: '#0b7b7f'}}>*/}
      {/*        Удалить*/}
      {/*    </Button>*/}
      {/*    <Button*/}
      {/*        color="primary"*/}
      {/*        variant="contained"*/}
      {/*    >*/}
      {/*        Добавить отдел*/}
      {/*    </Button>*/}
      {/*</Box>*/}
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Box style={{ display: 'flex' }}>
              <Search placeholder="Поиск отдела" enterButton />
              <Tooltip title={'Фильтры'}>
                <ButtonAntd
                  style={{ margin: '0 5px' }}
                  type="text"
                  icon={<FilterOutlined />}
                />
              </Tooltip>
              <Tooltip title={'Обновить'}>
                <ButtonAntd
                  onClick={handleUpdate}
                  type="text"
                  icon={<ReloadOutlined />}
                />
              </Tooltip>
              <Tooltip title={'Выгрузить в xlsx'}>
                <ExcelExport>
                  <ButtonAntd
                    style={{ margin: '0 5px' }}
                    type="text"
                    icon={<DownloadOutlined />}
                  />
                </ExcelExport>
              </Tooltip>
              <ButtonAntd type="ghost" icon={<PlusOutlined />}>
                Добавить
              </ButtonAntd>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default DepartmentsListToolbar;
