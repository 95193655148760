import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Input, Button, message, Select, Tooltip, Spin } from 'antd';
import { restApiHost } from '../../utils/appVariables';
import ShoulderForm from './ShoulderForm';
import { userInformations } from '../../contex';
import { call } from '../../apiUtils/call';
import AddShoulderModal from './AddShoulderModal';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import EditShoulderModal from './EditShoulderModal';
import { ErrorBoundary } from '../ErrorBoundary';
import { getPreTaskWaypoints } from '../../logic/preTasks/PreTaskApiFunctions';
import useAsyncEffect from 'use-async-effect';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';

const { Option } = Select;

const TotalCostApplication = ({
  refresh,
  value,
  valueVatRate,
  taskId,
  typeApplication,
  //waypointsData,
  currentTab,
  dataSums,
  taskTemplete,
  savingSum,
  setSavingSum,
  calculationSumsClientAtInputSum,
  assignThatSumsEdited,
  sumsWereEdited,
  endSavingSumsWhenSwitching,
  savingSumWereEditing,
  setLoadingModalSpiner,
  saveSumsWhenSwitching,
}) => {
  const [currentValue, setCurrentValue] = useState('');
  const [currentValueVatRate, setCurrentValueVatRate] = useState(20);
  const [shoulders, setShoulders] = useState([]);
  const { userInfo } = useContext(userInformations);
  const [visibleAddShoulderModal, setVisibleAddShoulderModal] = useState(false);
  const [visibleEditShoulderModal, setVisibleEditShoulderModal] =
    useState(false);
  const [currentCustomShoulderForEdit, setCurrentCustomShoulderForEdit] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const isMobile = useCheckMobileScreen();

  useAsyncEffect(async () => {
    if (currentTab === '4') {
      await handleFormationOfShoulderFromPoints();
    }
  }, [dataSums, currentTab]);

  useEffect(() => {
    if (value) {
      setCurrentValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (valueVatRate !== undefined) {
      setCurrentValueVatRate(valueVatRate);
    }
  }, [valueVatRate]);

  useEffect(async () => {
    if (sumsWereEdited) {
      if (
        savingSumWereEditing &&
        taskTemplete?.short !== 'ИП' &&
        taskTemplete?.short !== 'ОП-Э'
      ) {
        await handleSubmit();
        endSavingSumsWhenSwitching();
        taskId && refresh(taskId);
      } else if (
        savingSum &&
        taskTemplete?.short !== 'ИП' &&
        taskTemplete?.short !== 'ОП-Э'
      ) {
        await handleSubmit();
        setSavingSum();
      }
    }
  }, [savingSum, savingSumWereEditing]);

  useEffect(() => {
    if (
      shoulders &&
      (taskTemplete?.short === 'ИП' || taskTemplete?.short === 'ОП-Э')
    ) {
      calculationSumsClientAtInputSum(shoulders);
    }
  }, [shoulders]);

  const callGetWaypoints = useCallback(
    async (taskId) => {
      const res = await getPreTaskWaypoints(taskId, userInfo);
      if (res?.data) {
        return res.data;
      }
    },
    [getPreTaskWaypoints],
  );

  const handleSubmit = useCallback(async () => {
    setLoadingModalSpiner(true);

    try {
      let subUrl =
        typeApplication !== 'pre' ? '/shipping/tasks/' : '/leads/pre_tasks/';
      const url = `${restApiHost}${subUrl}${taskId}/`;
      const json = await call(
        url,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            price:
              currentValue !== '' && currentValue !== null ? currentValue : 0,
            vat_rate: currentValueVatRate,
          }),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      message.success('Успешно отредакитровано');
    } catch (e) {
      console.log('path task err', e);
      message.error('Ошибка запроса на изменение данных');
    } finally {
      setLoadingModalSpiner(false);
    }
  }, [currentValue, typeApplication, setLoadingModalSpiner]);

  const addShoulderFromCustomSum = (shoulderPars, dataSums, lastIndex) => {
    let lastIndexCopy = lastIndex;
    if (dataSums?.length > 0) {
      for (const sum of dataSums) {
        lastIndexCopy += 1;
        if (sum?.custom) {
          const operationParInSum = {
            index: lastIndexCopy,
            shoulderName: sum?.name ? sum.name : 'Без названия',
            currency: sum.currency,
            vat_rate: sum.vat_rate,
            price: sum?.price ? sum?.price : 0,
            inSum: true,
            isCustom: true,
            sumsId: sum?.id,
          };
          shoulderPars.push(operationParInSum);
        }
      }
    }
    return shoulderPars;
  };

  const formationOfShoulderFromPoints = useCallback(
    (waypoints) => {
      const shoulderPars = [];
      const sortWaypoints = waypoints?.sort((a, b) =>
        a['number'] > b['number'] ? 1 : -1,
      );

      let lastIndex = 0;
      for (let i = 0; i <= sortWaypoints.length; i++) {
        let pushInSums = false;
        if (sortWaypoints[i]?.waypoint?.operation?.type === 'other') {
          continue;
        }
        const waypoint = sortWaypoints[i];
        if (waypoint?.operation?.type !== 'out') {
          continue;
        }
        if (sortWaypoints[i + 1] !== undefined) {
          const nextWaypoint = sortWaypoints[i + 1];
          if (nextWaypoint.operation.type !== 'in') {
            continue;
          }
          const operationPar = {
            index: i,
            operationOut: waypoint.id,
            operationIn: nextWaypoint.id,
            type_transport: waypoint?.operation?.type_transport,
            shoulderName:
              (waypoint?.waypoint?.name !== undefined
                ? waypoint.waypoint.name
                : 'Без названия') +
              '-' +
              (nextWaypoint?.waypoint?.name !== undefined
                ? nextWaypoint.waypoint.name
                : 'Без названия'),
            out: waypoint?.operation,
            in: nextWaypoint?.operation,
            inSum: false,
          };

          if (dataSums?.length > 0) {
            for (const sum of dataSums) {
              if (!sum?.custom) {
                if (
                  waypoint.id === sum?.operation_out?.id &&
                  nextWaypoint?.id === sum.operation_in?.id
                ) {
                  const operationParInSum = {
                    index: i,
                    operationOut: sum.operation_out.id,
                    operationIn: sum.operation_in.id,
                    type_transport: sum.operation_out.operation?.type_transport,
                    shoulderName:
                      (sum.operation_out?.waypoint?.name !== undefined
                        ? sum.operation_out?.waypoint?.name
                        : 'Без названия') +
                      '-' +
                      (sum.operation_in?.waypoint?.name !== undefined
                        ? sum.operation_in?.waypoint?.name
                        : 'Без названия'),
                    out: sum.operation_out,
                    in: sum.operation_in,
                    currency: sum.currency,
                    vat_rate: sum.vat_rate,
                    price: sum.price,
                    inSum: true,
                    sumsId: sum?.id,
                  };
                  pushInSums = true;
                  shoulderPars.push(operationParInSum);
                }
              }
            }
          }
          if (!pushInSums) {
            shoulderPars.push(operationPar);
          }
        }
        lastIndex = i;
      }
      const updateWithCustomSumsShoulderPars = addShoulderFromCustomSum(
        shoulderPars,
        dataSums,
        lastIndex,
      );

      setShoulders(updateWithCustomSumsShoulderPars);
    },
    [dataSums, setShoulders, addShoulderFromCustomSum],
  );

  const handleFormationOfShoulderFromPoints = useCallback(async () => {
    setLoading(true);
    const waypointsData = await callGetWaypoints(taskId);
    if (waypointsData) {
      formationOfShoulderFromPoints(waypointsData);
    }
    setLoading(false);
  }, [callGetWaypoints, formationOfShoulderFromPoints, setLoading]);

  const changeSumsClientAtInputSum = useCallback(
    (sumClient) => {
      assignThatSumsEdited(true);
      const newShoulder = shoulders.map((shoulder) =>
        shoulder?.index === sumClient?.index
          ? {
              ...shoulder,
              currency: sumClient?.currency,
              vat_rate: sumClient?.vat_rate,
              price: sumClient?.price,
            }
          : shoulder,
      );
      setShoulders(newShoulder);
    },
    [assignThatSumsEdited, setShoulders],
  );

  const createShoulder = async (body) => {
    setLoadingModalSpiner(true);
    try {
      const url = `${restApiHost}/leads/pre_summaries/`;
      const json = await call(
        url,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      message.success('Сумма успешно добавлена');
      setVisibleAddShoulderModal(false);
    } catch (e) {
      console.log('add cargo err', e);
      message.error('Ошибка выполнения операции!');
    } finally {
      setLoadingModalSpiner(false);
    }
  };
  const editeSum = async (body) => {
    setLoadingModalSpiner(true);
    try {
      const url = `${restApiHost}/leads/pre_summaries/${body.sumsId}/`;
      const json = await call(
        url,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      message.success('Сумма успешно обновлена');
      setCurrentCustomShoulderForEdit(null);
      setVisibleEditShoulderModal(false);
    } catch (e) {
      console.log('add cargo err', e);
      message.error('Ошибка выполнения операции!');
    } finally {
      setLoadingModalSpiner(false);
    }
  };

  const editCustomSum = (shoulder) => {
    if (shoulder) {
      setCurrentCustomShoulderForEdit(shoulder);
      setVisibleEditShoulderModal(true);
    }
  };

  const getShoulders = useCallback(() => {
    return shoulders;
  }, [shoulders]);

  return (
    <>
      {taskTemplete?.short !== 'ИП' && taskTemplete?.short !== 'ОП-Э' ? (
        <>
          <h4>Общая сумма клиенту</h4>
          <div style={{ display: 'flex' }}>
            <Input
              prefix="₽"
              suffix="РУБ."
              onChange={(e) => {
                const sum = {
                  price: e.target.value,
                  currency: 'rub',
                };
                assignThatSumsEdited(true);
                calculationSumsClientAtInputSum([sum]);
                setCurrentValue(e.target.value);
              }}
              onFocus={() => {
                if (currentValue === '0.00') {
                  setCurrentValue('');
                }
              }}
              onBlur={() => {
                {
                  if (!currentValue.trim()) {
                    setCurrentValue('0.00');
                  }
                }
              }}
              value={currentValue}
              style={{ marginRight: '10px', width: '430px' }}
            />
            <Select
              getPopupContainer={(trigger) =>
                isMobile ? trigger.parentNode : document.body
              }
              value={currentValueVatRate}
              defaultValue={20}
              onChange={(value) => {
                setCurrentValueVatRate(value);
                assignThatSumsEdited(true);
              }}
              style={{ width: 100 }}
              placeholder={'НДС'}
            >
              <Option value={null}>Без НДС</Option>
              <Option value={0}>0%</Option>
              <Option value={20}>20%</Option>
            </Select>
          </div>
        </>
      ) : (
        <></>
      )}
      {taskTemplete?.short === 'ИП' || taskTemplete?.short === 'ОП-Э' ? (
        <>
          <div className={'top-table-actions'}>
            <Tooltip placement={'topLeft'} title={'Добавить'}>
              <Button
                type="primary"
                size={'small'}
                icon={<PlusOutlined />}
                onClick={() => {
                  if (sumsWereEdited) {
                    saveSumsWhenSwitching();
                  }
                  setVisibleAddShoulderModal(true);
                }}
              />
            </Tooltip>
          </div>
          <ErrorBoundary>
            <AddShoulderModal
              visible={visibleAddShoulderModal}
              taskId={taskId}
              closeModal={() => setVisibleAddShoulderModal(false)}
              createShoulder={async (body) => {
                await createShoulder(body);
                taskId && refresh(taskId);
              }}
            />
          </ErrorBoundary>
          <ErrorBoundary>
            <EditShoulderModal
              visible={visibleEditShoulderModal}
              taskId={taskId}
              closeModal={() => setVisibleEditShoulderModal(false)}
              shoulder={currentCustomShoulderForEdit}
              editCustomShoulder={async (body) => {
                await editeSum(body);
                taskId && refresh(taskId);
              }}
            />
          </ErrorBoundary>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: '162px',
            }}
          >
            <Spin spinning={loading}>
              {getShoulders().map((shoulder, index) => {
                return (
                  <ErrorBoundary key={index}>
                    <ShoulderForm
                      refresh={(taskId) => refresh(taskId)}
                      shoulder={shoulder}
                      taskId={taskId}
                      loading={loading}
                      savingSum={savingSum}
                      sumsWereEdited={sumsWereEdited}
                      endSavingSumsWhenSwitching={() =>
                        endSavingSumsWhenSwitching()
                      }
                      savingSumWereEditing={savingSumWereEditing}
                      setLoadingModalSpiner={(isLoad) =>
                        setLoadingModalSpiner(isLoad)
                      }
                      editCustomSum={(shoulder) => editCustomSum(shoulder)}
                      changeSumsClientAtInputSum={(sums) =>
                        changeSumsClientAtInputSum(sums)
                      }
                      setSavingSum={setSavingSum}
                    />
                  </ErrorBoundary>
                );
              })}
            </Spin>
          </div>{' '}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default TotalCostApplication;
