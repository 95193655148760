import React, { useState } from 'react';
import { Box, Card, CardContent } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { Button, Button as ButtonAntd, Dropdown, Input } from 'antd';
import {
  FilterOutlined,
  ReloadOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';

const { Search } = Input;

const ContractsListToolbar = ({
  handleUpdate,
  //onSearch,
  tableFilters,
  pageProps,
  loading,
  changeVisibleFilters,
  changeVisibleTableSetting,
  totalContracts,
  handleScrollToTopMobileList,
}) => {
  const [searchContracts, setSearchContracts] = useState('');
  const [openDropdownMenu, setOpenDropdownMenu] = useState(false);
  const isMobile = useCheckMobileScreen();

  const handleSearch = async (value) => {
    const firstPage = 1;
    if (value) {
      tableFilters?.filter
        ? await handleUpdate(firstPage, {
            ...tableFilters,
            filter: { ...tableFilters.filter, search: value },
          })
        : await handleUpdate(firstPage, {
            ...tableFilters,
            filter: { search: value },
          });
    } else {
      const newFilter = { ...tableFilters };
      delete newFilter?.filter?.search;
      await handleUpdate(firstPage, newFilter);
    }
  };

  const toolbars = () => {
    return (
      <>
        <aside className="Toolbars-wrapper">
          <Tooltip title={'Обновить'}>
            <ButtonAntd
              className={'icons-toolbar-color-white'}
              onClick={() => {
                openDropdownMenu && setOpenDropdownMenu(false);
                handleUpdate(pageProps, tableFilters);
              }}
              style={{ marginLeft: '10px' }}
              type="text"
              icon={<ReloadOutlined />}
            >
              {isMobile ? 'Обновить' : ''}
            </ButtonAntd>
          </Tooltip>
          <Tooltip title={'Отобразить фильтры'}>
            <ButtonAntd
              className={'icons-toolbar-color-white'}
              style={{ margin: '0 10px' }}
              disabled={loading}
              onClick={() => {
                isMobile && handleScrollToTopMobileList();
                openDropdownMenu && setOpenDropdownMenu(false);
                changeVisibleFilters();
              }}
              type="text"
              icon={<FilterOutlined />}
            >
              {isMobile ? 'Отобразить фильтры' : ''}
            </ButtonAntd>
          </Tooltip>
          <Tooltip title={'Настройка таблицы'}>
            <ButtonAntd
              className={'icons-toolbar-color-white'}
              style={{ marginRight: '10px' }}
              disabled={loading}
              onClick={() => {
                isMobile && handleScrollToTopMobileList();
                openDropdownMenu && setOpenDropdownMenu(false);
                changeVisibleTableSetting();
              }}
              type="text"
              icon={<SettingOutlined />}
            >
              {isMobile ? 'Настройка таблицы' : ''}
            </ButtonAntd>
          </Tooltip>
        </aside>
      </>
    );
  };

  return (
    <Box>
      <Box sx={{ mt: 3 }}>
        <Card className={'primary-bg-color'}>
          <CardContent>
            <Box style={{ display: 'flex' }}>
              <Search
                disabled={loading}
                value={searchContracts}
                placeholder="Поиск договора(Номер договора, Подписавший (клиент), Подписавший (мы))"
                allowClear
                enterButton
                onSearch={(value) => handleSearch(value)}
                onChange={(e) => {
                  setSearchContracts(e.target.value);
                }}
              />

              {isMobile ? (
                <Dropdown
                  visible={openDropdownMenu}
                  onVisibleChange={(visible) => {
                    setOpenDropdownMenu(visible);
                  }}
                  overlay={toolbars()}
                  placement="bottomRight"
                  trigger={['click']}
                >
                  <Button className={'Toolbars-button'}>
                    <svg
                      width="14"
                      height="12"
                      viewBox="0 0 14 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1C14 1.26522 13.8946 1.51957 13.7071 1.70711C13.5196 1.89464 13.2652 2 13 2H1C0.734784 2 0.48043 1.89464 0.292893 1.70711C0.105357 1.51957 0 1.26522 0 1ZM0 6C0 5.73478 0.105357 5.48043 0.292893 5.29289C0.48043 5.10536 0.734784 5 1 5H13C13.2652 5 13.5196 5.10536 13.7071 5.29289C13.8946 5.48043 14 5.73478 14 6C14 6.26522 13.8946 6.51957 13.7071 6.70711C13.5196 6.89464 13.2652 7 13 7H1C0.734784 7 0.48043 6.89464 0.292893 6.70711C0.105357 6.51957 0 6.26522 0 6ZM6 11C6 10.7348 6.10536 10.4804 6.29289 10.2929C6.48043 10.1054 6.73478 10 7 10H13C13.2652 10 13.5196 10.1054 13.7071 10.2929C13.8946 10.4804 14 10.7348 14 11C14 11.2652 13.8946 11.5196 13.7071 11.7071C13.5196 11.8946 13.2652 12 13 12H7C6.73478 12 6.48043 11.8946 6.29289 11.7071C6.10536 11.5196 6 11.2652 6 11Z"
                        fill="#0B7B7F"
                      />
                    </svg>
                  </Button>
                </Dropdown>
              ) : (
                toolbars()
              )}
            </Box>
          </CardContent>
        </Card>
      </Box>
      {totalContracts ? (
        <b className={'total-rows-table'}>Итого: {totalContracts}</b>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default ContractsListToolbar;
