import { restApiHost } from '../../utils/appVariables';
import { call } from '../../apiUtils/call';
import { message } from 'antd';

export async function createChat(userInfo, body) {
  try {
    const url = `${restApiHost}/chats/chats/`;
    const json = await call(
      url,
      {
        method: 'POST',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      userInfo.current.haveViewBranch,
    );

    if (!json) return;

    return json;
  } catch (e) {
    console.log('create chat fetch err', e);
    message.error('Ошибка запроса на создание чата');
  }
}

export async function getChatsMessages(chatId, offset, userInfo) {
  if (!chatId) return;
  try {
    const url = `${restApiHost}/chats/messages/?chat=${chatId}&offset=${offset}&limit=15`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (!json) return;

    return json;
  } catch (e) {
    console.log('get chats messages fetch err', e);
    message.error('Ошибка запрос на получение списка сообщений');
  }
}

export async function sendChatMessage(body, userInfo) {
  try {
    const url = `${restApiHost}/chats/send_message`;
    const json = await call(
      url,
      {
        method: 'POST',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      userInfo.current.haveViewBranch,
    );

    if (!json) return;

    return json;
  } catch (e) {
    console.log('send chat message fetch err', e);
    message.error('Ошибка запроса на отправку собщения');
  }
}

export async function viewChatMessages(body, userInfo) {
  try {
    const url = `${restApiHost}/chats/view_messages`;
    const json = await call(
      url,
      {
        method: 'POST',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      userInfo.current.haveViewBranch,
    );

    if (!json) return;

    return json;
  } catch (e) {
    console.log('view chat message fetch err', e);
    message.error('Ошибка запроса на прочтение собщений');
  }
}

export async function viewAllChatMessages(chatId, userInfo) {
  if (!chatId) return;
  try {
    const url = `${restApiHost}/chats/read_all_messages/`;
    const json = await call(
      url,
      {
        method: 'POST',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ chat_id: chatId }),
      },
      userInfo.current.haveViewBranch,
    );

    if (!json) return;

    return json;
  } catch (e) {
    console.log('view all chat message fetch err', e);
    message.error('Ошибка запроса на прочтение всех собщений');
  }
}
