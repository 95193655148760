import PropTypes from 'prop-types';
import { getTime } from '../../calendarUtils/getTime';
import getColorTaskByStatus from './tasksUtils/getColorTaskByStatus';
import getDownIndentPercentage from '../../cellsWithTime/cellsWithTimeUtils/getDownIndentPercentage';
import BriefInformationAboutTaskPopover from './BriefInformationAboutTaskPopover';
import getFireImagesTask from '../../calendarUtils/getFireImagesTask';

const TaskItemDayOfMonth = (props) => {
  return (
    <>
      <BriefInformationAboutTaskPopover
        task={props.task}
        handleEditTask={(task) => {
          props.handleEditTask(task);
        }}
      >
        <div
          onClick={() => props.handleEditTask(props.task)}
          className={'task-item-day-month'}
          style={{
            backgroundColor: getColorTaskByStatus(props.task?.status),
            maxWidth: props.useForDayViewCalendar ? '200px' : '',
            marginLeft: props.useForDayViewCalendar ? '10px' : '',
            top: props.useForDayViewCalendar
              ? getDownIndentPercentage(props.task?.end)
              : '0%',
          }}
        >
          <div
            className={'task-item-day-month-name'}
            style={{ color: props.task?.status === 'my' ? 'black' : '#ffffff' }}
          >
            {getFireImagesTask(props.task?.priority || 0)}{' '}
            {getTime(
              props.task?.lead || props.task?.customer
                ? props.task.start
                : props.task.end,
            )}{' '}
            {props.task?.name}
          </div>
        </div>
      </BriefInformationAboutTaskPopover>
    </>
  );
};

TaskItemDayOfMonth.propTypes = {
  task: PropTypes.object.isRequired,
  useForDayViewCalendar: PropTypes.bool,
  handleEditTask: PropTypes.func.isRequired,
};

export default TaskItemDayOfMonth;
