import React, { useEffect, useMemo, useState } from 'react';
import { Checkbox, Avatar, Tooltip } from 'antd';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/ru';

export function getDate(date) {
  if (moment().diff(date, 'days') >= 1) {
    return moment(date).format('MMM Do YY');
  }

  return moment(date).calendar().split(',')[0];
}

const EmailListItem = ({
  disabledCanDoActions,
  email,
  showBodyEmail,
  activeAll,
  activeFolder,
  selectedEmails,
  setSelectedEmails,
  setImportantFlag,
  taskType,
}) => {
  const [selected, setSelected] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (email?.uid) {
      const emailIsSelected = selectedEmails.some(
        (item) => item.uid === email.uid,
      );
      setSelected(emailIsSelected);
    }
  }, [selectedEmails]);

  useEffect(() => {
    if (activeAll) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [activeAll]);

  const handleCheckEmail = (e) => {
    setSelected(e.target.checked);
    if (e.target.checked) {
      setSelectedEmails([...selectedEmails, email]);
    } else {
      const newEmails = [...selectedEmails].filter(
        (item) => item.uid !== email.uid,
      );
      setSelectedEmails(newEmails);
    }
  };

  const handleExpandThread = (e) => {
    e.stopPropagation();
    setExpanded(!expanded);
  };

  const emailInFlagged = useMemo(() => {
    return email?.flags.includes('\\Flagged');
  }, [email]);

  const emailInSeen = useMemo(() => {
    return email?.flags.includes('\\Seen');
  }, [email]);

  const makeImportant = (e) => {
    if (disabledCanDoActions) {
      return;
    }
    e.stopPropagation();
    setImportantFlag?.(email, emailInFlagged);
  };

  return (
    <>
      <div className={`email-messages__item${selected ? ' active' : ''}`}>
        {!taskType && (
          <>
            <Checkbox
              disabled={Boolean(disabledCanDoActions)}
              checked={selected}
              onClick={(e) => e.stopPropagation()}
              onChange={handleCheckEmail}
            />
            <div className="email-flag" onClick={makeImportant}>
              {!emailInFlagged ? (
                <svg
                  fill="#e8e8e8"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M5 2h14a1 1 0 0 1 1 1v19.143a.5.5 0 0 1-.766.424L12 18.03l-7.234 4.536A.5.5 0 0 1 4 22.143V3a1 1 0 0 1 1-1zm13 2H6v15.432l6-3.761 6 3.761V4z" />
                </svg>
              ) : (
                <svg
                  fill="#ffdb4d"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M5 2h14a1 1 0 0 1 1 1v19.143a.5.5 0 0 1-.766.424L12 18.03l-7.234 4.536A.5.5 0 0 1 4 22.143V3a1 1 0 0 1 1-1z" />
                </svg>
              )}
            </div>
          </>
        )}

        <div
          style={{ width: '100%', display: 'flex', alignItems: 'center' }}
          onClick={() => showBodyEmail(email)}
        >
          <Tooltip
            title={
              activeFolder === 'Sent' ? email.to_email : `${email.from_email}`
            }
          >
            <div
              style={emailInSeen ? { fontWeight: 400 } : { fontWeight: 700 }}
              className={'email-messages__item-from'}
            >
              {activeFolder === 'Sent' ? (
                <>
                  Кому:{' '}
                  {email?.to_emails?.length && email?.to_emails.join(', ')}
                </>
              ) : (
                <>
                  <Avatar>
                    {email?.from_email &&
                      email.from_email.slice(0, 1).toUpperCase()}
                  </Avatar>
                  {email?.from_email}
                </>
              )}
            </div>
          </Tooltip>
          <div
            style={emailInSeen ? { fontWeight: 400 } : { fontWeight: 700 }}
            className="email-message__item-subject"
          >
            {email?.thread?.length > 0 && (
              <div className={'thread-btn'} onClick={handleExpandThread}>
                {email.thread.length}
                {expanded ? (
                  <UpOutlined style={{ marginLeft: 5, fontSize: 10 }} />
                ) : (
                  <DownOutlined style={{ marginLeft: 5, fontSize: 10 }} />
                )}
              </div>
            )}

            <span
              className="email-message__item-subject"
              style={{ display: 'block' }}
            >
              {email.subject === 'None' ? 'Без темы' : email.subject}
            </span>
          </div>
          {email.date && (
            <div className="email-message__item-date">
              {email?.attach && (
                <Tooltip placement="bottomLeft" title="В письме есть вложения">
                  <svg
                    style={{ marginLeft: 10 }}
                    fill="#009688"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M14.828 7.757l-5.656 5.657a1 1 0 1 0 1.414 1.414l5.657-5.656A3 3 0 1 0 12 4.929l-5.657 5.657a5 5 0 1 0 7.071 7.07L19.071 12l1.414 1.414-5.657 5.657a7 7 0 1 1-9.9-9.9l5.658-5.656a5 5 0 0 1 7.07 7.07L12 16.244A3 3 0 1 1 7.757 12l5.657-5.657 1.414 1.414z" />
                  </svg>
                </Tooltip>
              )}
              {moment(email.date).format('L')}
            </div>
          )}
        </div>
      </div>
      {expanded && email?.thread?.length > 0 && (
        <div style={{ marginLeft: 20, background: '#f4f6f869' }}>
          {email.thread.map((mail, index) => {
            return (
              <div
                className={`email-messages__item`}
                key={index}
                onClick={() => showBodyEmail(mail)}
              >
                <Tooltip title={`${mail.from_email}`}>
                  <div className={'email-messages__item-from'}>
                    <Avatar>{mail.from_email.slice(0, 1).toUpperCase()}</Avatar>
                    {mail.from_email}
                  </div>
                </Tooltip>
                <div className="email-message__item-subject">
                  {mail.subject === 'None' ? 'Без темы' : mail.subject}
                </div>
                {mail.date && (
                  <div className="email-message__item-date">
                    {moment(mail.date).format('L')}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default EmailListItem;
