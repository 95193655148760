import PropTypes from 'prop-types';
import { Badge, Card, Col, Row } from 'antd';
import dayjs from 'dayjs';

const ChatNotificationListItem = (props) => {
  return (
    <>
      <Card
        className={'user-select-none'}
        onDoubleClick={() => {
          props.redirectToCalendar(props.notification?.event?.id);
        }}
        hoverable={true}
        bodyStyle={{ padding: '20px 10px 20px 10px' }}
        style={{
          width: '100%',
          marginTop: '10px',
          minHeight: '84px',
          borderRadius: '20px',
          border: '1px solid #0b7a7f',
        }}
      >
        <Col>
          <Row justify={'space-between'}>
            <Col
              lg={18}
              md={18}
              style={{
                fontWeight: 'bold',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              {props.notification?.event?.name
                ? props.notification?.event?.name
                : 'Без названия'}
            </Col>
            <Col
              lg={6}
              md={6}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              {props.notification?.last_message?.created
                ? dayjs(props.notification?.last_message?.created)
                    .locale('RU')
                    .format('MMM DD HH:mm')
                : ''}
            </Col>
          </Row>
          <Row justify={'space-between'} style={{ marginTop: '5px' }}>
            <Col
              lg={20}
              md={20}
              style={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              <span style={{ fontWeight: '600' }}>
                {props.notification?.last_message?.creator
                  ? `${props.notification?.last_message?.creator}:`
                  : ''}
              </span>{' '}
              {props.notification?.last_message?.text}
            </Col>
            <Col
              lg={4}
              md={4}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Badge
                count={props.notification?.unread_count}
                style={{ backgroundColor: '#0b7b7f' }}
              />
            </Col>
          </Row>
        </Col>
      </Card>
    </>
  );
};

ChatNotificationListItem.propsTypes = {
  notification: PropTypes.object.isRequired,
  redirectToCalendar: PropTypes.func,
};

export default ChatNotificationListItem;
