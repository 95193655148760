import PropTypes from 'prop-types';
import DayOfMonthHeader from './DayOfMonthHeader';
import { ErrorBoundary } from '../../../ErrorBoundary';
import { getClassNameByDay } from './getClassNameByDay';
import DayOfMonthBody from './DayOfMonthBody';

const DayOfMonth = (props) => {
  return (
    <>
      <div
        style={props.rowIndex === 0 ? { height: '182px' } : {}}
        className={getClassNameByDay(props.day.format('dd').toUpperCase())}
        key={props.index}
      >
        <ErrorBoundary>
          <DayOfMonthHeader
            day={props.day}
            rowIndex={props.rowIndex}
            useDoubleClick={true}
            goToCompetitionDate={(day) => {
              props.goToCompetitionDate(day);
            }}
            handleCreateTask={(day) => props.handleCreateTask(day)}
          />
        </ErrorBoundary>
        <ErrorBoundary>
          <DayOfMonthBody
            day={props.day}
            tasks={props.tasks}
            tasksLeadCall={props.tasksLeadCall}
            goToCompetitionDate={(day) => {
              props.goToCompetitionDate(day);
            }}
            handleCreateTask={(day) => props.handleCreateTask(day)}
            handleEditTask={(task) => props.handleEditTask(task)}
          />
        </ErrorBoundary>
      </div>
    </>
  );
};
DayOfMonth.propTypes = {
  day: PropTypes.any,
  rowIndex: PropTypes.number,
  index: PropTypes.number,
  tasks: PropTypes.any,
  tasksLeadCall: PropTypes.any,
  goToCompetitionDate: PropTypes.func,
  handleEditTask: PropTypes.func,
  handleCreateTask: PropTypes.func,
};

export default DayOfMonth;
