import PropTypes from 'prop-types';
import { Card, Checkbox, Col, Divider, Dropdown, Row, Space } from 'antd';
import React, { useCallback } from 'react';
import EllipsisOutlined from '@ant-design/icons/lib/icons/EllipsisOutlined';

const TransportationListItem = (props) => {
  const cardTitle = useCallback(() => {
    return (
      <>
        <Row justify={'space-between'} wrap={false}>
          <Col>
            <Col className={'mainText'}>
              {props.transport?.transport?.transport_unit?.type_full || '-'}
            </Col>
          </Col>
          <Row justify={'end'}>
            <Space size="middle" align={'start'}>
              <Dropdown
                overlay={props.menuActions(props.transport)}
                trigger={['click']}
              >
                <EllipsisOutlined
                  style={{ cursor: 'pointer', fontSize: '24px' }}
                />
              </Dropdown>
            </Space>
          </Row>
        </Row>
      </>
    );
  }, [props.transport, props.menuActions]);

  return (
    <>
      <Card className={'mainCard'} bodyStyle={{ padding: '12px' }}>
        {cardTitle()}
        <Divider style={{ margin: '10px 0' }} />

        <Row gutter={[0, 10]}>
          <Row gutter={25}>
            <Col>
              <div className={'secondText'}>{'Наименование (груз)'}</div>
              <div className={'valueText'}>
                {props.transport?.task?.cargo?.name || '-'}
              </div>
            </Col>
            <Col>
              <div className={'secondText'}>{'Код (груз)'}</div>
              <div className={'valueText'}>
                {props.transport?.task?.cargo?.code || '-'}
              </div>
            </Col>
            <Col>
              <div className={'secondText'}>{'Охрана (груз)'}</div>
              <div className={'valueText'}>
                <Row justify={'center'}>
                  {props.transport?.cargo_security ? (
                    <Checkbox disabled={true} checked={true} />
                  ) : (
                    <Checkbox disabled={true} checked={false} />
                  )}
                </Row>
              </div>
            </Col>
          </Row>
          <Row gutter={25}>
            <Col>
              <div className={'secondText'}>{'Упаковка'}</div>
              <div className={'valueText'}>
                {props.transport?.cargo_type_packing?.name || '-'}
              </div>
            </Col>
            <Col>
              <div className={'secondText'}>{'Мест'}</div>
              <div className={'valueText'}>
                {props.transport?.cargo_places || '-'}
              </div>
            </Col>

            <Col>
              <div className={'secondText'}>{'Вес(кг)'}</div>
              <div className={'valueText'}>
                {props.transport?.cargo_weight || '-'}
              </div>
            </Col>
          </Row>
        </Row>
      </Card>
    </>
  );
};

TransportationListItem.propTypes = {
  transport: PropTypes.object,
  menuActions: PropTypes.func,
};

export default TransportationListItem;
