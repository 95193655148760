export function changeVisibleFiltersInColumnsArr(columns, visibleColumns) {
  let resColumns = [...columns];
  for (const col of resColumns) {
    if (col.children) {
      col.children.visible = visibleColumns;
    }
  }

  return resColumns;
}
