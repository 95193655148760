import PropTypes from 'prop-types';
import { Button, Form, Modal, message } from 'antd';
import React, { useCallback, useContext, useEffect } from 'react';
import WaypointsModalForm from './WaypointsModalForm';
import useStateRef from 'react-usestateref';
import { getTime } from '../../../../../../../calendar/calendarUtils/getTime';
import {
  createShippingTaskWaypoint,
  editShippingTaskWaypoint,
} from '../../../../../../../../logic/task/TaskApiFunctions';
import { userInformations } from '../../../../../../../../contex';
import dayjs from 'dayjs';

const WaypointsModal = (props) => {
  const { userInfo } = useContext(userInformations);
  const [form] = Form.useForm();
  const [, setWaypointRepresentative, waypointRepresentativeRef] = useStateRef({
    representative: null,
    contact: null,
  });

  useEffect(() => {
    handleSetFieldValuesForm();
  }, [props.editingWaypoint]);

  const handleSetFieldValuesForm = useCallback(() => {
    if (!props.editingWaypoint) {
      const number = props.waypointsDataListLength + 1 || 1;
      form.setFieldsValue({ number });
      return;
    }
    form.setFieldsValue({
      ...props.editingWaypoint,
      date: props.editingWaypoint?.load_plan
        ? dayjs(props.editingWaypoint?.load_plan).format('YYYY-MM-DD')
        : null,
      load_plan: props.editingWaypoint.load_plan
        ? getTime(props.editingWaypoint.load_plan)
        : null,
      operation: props.editingWaypoint.operation
        ? props.editingWaypoint.operation.id
        : null,
      waypoint: props.editingWaypoint.waypoint
        ? props.editingWaypoint.waypoint.name
        : '',
    });
  }, [props.editingWaypoint, props.waypointsDataListLength, form]);

  const handleCloseModal = useCallback(() => {
    props.closeModal && props.closeModal();
    form.resetFields();
  }, [props.closeModal]);

  const handleCreateOrEditWaypoint = useCallback(() => {
    form.validateFields().then(async (values) => {
      const body = {
        ...values,
        waypoint:
          typeof values?.waypoint === 'string'
            ? props.editingWaypoint.waypoint?.id
            : values?.waypoint,
        task: props.taskData?.id,
        agent_line: waypointRepresentativeRef.current?.representative ?? null,
        phone_line: waypointRepresentativeRef.current?.contact ?? null,
        date: values?.date
          ? dayjs(`${values.date}`).format('YYYY-MM-DD')
          : null,
        load_plan: values.load_plan
          ? dayjs(`${values.date}T${values.load_plan}`)
          : null,
      };
      const res = props.editingWaypoint?.id
        ? await editShippingTaskWaypoint(
            props.editingWaypoint.id,
            body,
            userInfo,
          )
        : await createShippingTaskWaypoint(body, userInfo);

      if (res) {
        message.success(
          props.editingWaypoint?.id
            ? 'Пукнт успешно отредактирован'
            : 'Пукнт успешно добавлен',
        );
        handleCloseModal();
      }
    });
  }, [form, props.editingWaypoint, waypointRepresentativeRef]);

  return (
    <>
      <Modal
        visible={props.visible}
        destroyOnClose={true}
        zIndex={199}
        title={
          props.editingWaypoint?.id ? 'Редактировать пункт' : 'Добавить пункт'
        }
        onOk={() => {
          handleCloseModal();
        }}
        onCancel={() => {
          handleCloseModal();
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              handleCloseModal();
            }}
          >
            Закрыть
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              handleCreateOrEditWaypoint();
            }}
          >
            Сохранить
          </Button>,
        ]}
      >
        <WaypointsModalForm
          form={form}
          editingWaypoint={props.editingWaypoint}
          visibleModal={props.visible}
          onChangeWaypointRepresentative={(waypointRepresentativeValue) => {
            setWaypointRepresentative({
              representative:
                waypointRepresentativeValue.representative ?? null,
              contact: waypointRepresentativeValue?.contact ?? null,
            });
          }}
        />
      </Modal>
    </>
  );
};

export default WaypointsModal;

WaypointsModal.propTypes = {
  visible: PropTypes.bool,
  waypointsDataListLength: PropTypes.number,
  taskData: PropTypes.object,
  editingWaypoint: PropTypes.object,
  closeModal: PropTypes.func,
};
