import locale from 'antd/lib/locale/ru_RU';
import {
  Button,
  ConfigProvider,
  Dropdown,
  Menu,
  Space,
  Table,
  Tag,
} from 'antd';
import { setPaginationSettings } from '../../localStorageUtils/paginationSettings';
import React, { useContext, useState } from 'react';
import getGroupList from '../../logic/permissions/PermissionsApiFunctions';
import { userInformations } from '../../contex';
import EllipsisOutlined from '@ant-design/icons/lib/icons/EllipsisOutlined';
import { EditOutlined } from '@ant-design/icons';
import EditPermissionsDrawer from '../staff/permissions/editPermissionsGroups/editPermissionsGroupsDrawer/EditPermissionsDrawer';
import PropTypes from 'prop-types';

const PermissionsTable = (props) => {
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [selectedGroupsInTable, setSelectedGroupsInTable] = useState(null);
  const { userInfo } = useContext(userInformations);

  const menuSecure = (group) => {
    return (
      <Menu style={{ width: 200 }}>
        <Menu.Item
          icon={<EditOutlined />}
          onClick={() => {
            openDrawerSettingPermissions(group);
          }}
        >
          Редактировать
        </Menu.Item>
      </Menu>
    );
  };

  const columns = [
    {
      title: '',
      key: 'action',
      width: 50,
      align: 'center',
      render: (group) => (
        <Space size="middle">
          <Dropdown overlay={menuSecure(group)} trigger={['hover']}>
            <EllipsisOutlined style={{ cursor: 'pointer', fontSize: '24px' }} />
          </Dropdown>
        </Space>
      ),
    },
    {
      title: 'Группа',
      width: 200,
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'Права доступа',
      key: 'permissions',
      dataIndex: 'permissions',
      ellipsis: true,
      render: (permissions) =>
        permissions.length > 0 ? (
          <>
            {permissions.map((permission) => {
              return <Tag key={permission.id}>{permission.name}</Tag>;
            })}
          </>
        ) : (
          'Нет прав доступа'
        ),
    },
  ];

  const openDrawerSettingPermissions = (group) => {
    setSelectedGroupsInTable([group]);
    setVisibleDrawer(true);
  };

  const renderFooterTable = (selectedGroupsInTable) => {
    return (
      <div style={{ padding: '5px' }}>
        {selectedGroupsInTable?.length > 1 ? (
          <Button size={'middle'} onClick={() => setVisibleDrawer(true)}>
            Применить к выбранным
          </Button>
        ) : (
          <text style={{ fontStyle: 'italic' }}>
            Выберите группы для множественного редактирваония
          </text>
        )}
      </div>
    );
  };
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedGroupsInTable(selectedRows);
    },
  };

  const handleUpdateGroupList = async () => {
    const res = await getGroupList(userInfo);
    if (!res.data) return;
    const selectedGroupIds = selectedGroupsInTable?.map((group) => {
      return group.id;
    });
    const filteredGroups = res.data.filter((group) =>
      selectedGroupIds.includes(group.id),
    );
    setSelectedGroupsInTable(filteredGroups);
  };
  return (
    <>
      <ConfigProvider locale={locale}>
        <Table
          rowClassName={(record, index) =>
            index % 2 === 0
              ? 'black-text table-row-color'
              : 'black-text table-row-white'
          }
          onRow={(record) => {
            return {
              onDoubleClick: () => {
                openDrawerSettingPermissions(record);
              },
            };
          }}
          size="small"
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          rowKey={(record) => record.id}
          scroll={{ x: 1500 }}
          footer={() => renderFooterTable(selectedGroupsInTable)}
          sticky={true}
          bordered={true}
          pagination={setPaginationSettings(props.totalGroup, 1, [
            'bottomRight',
          ])}
          columns={columns}
          dataSource={props.groupList}
        />
      </ConfigProvider>

      <EditPermissionsDrawer
        visible={visibleDrawer}
        selectedGroups={selectedGroupsInTable}
        handleUpdateGroupList={async () => {
          await handleUpdateGroupList();
        }}
        closeDrawer={async () => {
          setVisibleDrawer(false);
          await props.callGetGroup();
        }}
      />
    </>
  );
};
PermissionsTable.propsTypes = {
  callGetGroup: PropTypes.func,
  groupList: PropTypes.arrayOf(PropTypes.object),
  totalGroup: PropTypes.number,
};
export default PermissionsTable;
