import React, { useContext, useState } from 'react';
import { Box, Card, CardContent } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import {
  Button as ButtonAntd,
  Col,
  Form,
  Input,
  Modal,
  message,
  Row,
  Select,
  Dropdown,
  Button,
} from 'antd';
import {
  FilterOutlined,
  PlusOutlined,
  ReloadOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { MaskedInput } from 'antd-mask-input';
import { restApiHost } from '../../utils/appVariables';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { call } from '../../apiUtils/call';
import { userInformations } from '../../contex';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';

const { Search } = Input;
const { Option } = Select;
const StaffListToolbar = ({
  handleUpdate,
  //data,
  loading,
  tableFilters,
  page,
  changeVisibleFilters,
  changeVisibleTableSetting,
  totalStaff,
  activeTab,
  branchList,
  handleGetBranchList,
  handleScrollToTopMobileList,
}) => {
  const [addStaffForm] = Form.useForm();
  const [visibleModal, setVisibleModal] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);
  const [searchStaff, setSearchStaff] = useState('');
  const { userInfo } = useContext(userInformations);
  const [openDropdownMenu, setOpenDropdownMenu] = useState(false);
  const isMobile = useCheckMobileScreen();

  const handleSearch = async (value) => {
    const firstPage = 1;
    if (value) {
      tableFilters?.filter
        ? await handleUpdate(firstPage, {
            ...tableFilters,
            filter: { ...tableFilters.filter, search: value },
          })
        : await handleUpdate(firstPage, {
            ...tableFilters,
            filter: { search: value },
          });
    } else {
      const newFilter = { ...tableFilters };
      delete newFilter?.filter?.search;
      await handleUpdate(firstPage, newFilter);
    }
  };

  const createUser = async (body) => {
    try {
      const url = `${restApiHost}/accounts/create_user/`;
      const json = await call(
        url,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        userInfo?.current?.haveViewBranch,
      );

      if (!json) return;

      message.success('Сотрудник успешно создан!');
      addStaffForm.resetFields();
      setVisibleModal(false);
      handleUpdate(page, tableFilters);
    } catch (e) {
      console.log('create user err', e);
      message.error('Ошибка запроса для создания сотрудника');
    }
  };

  const toolbars = () => {
    return (
      <>
        <aside className="Toolbars-wrapper">
          <Tooltip title={'Обновить'}>
            <ButtonAntd
              className={'icons-toolbar-color-white'}
              style={{ margin: '0 10px' }}
              disabled={activeTab !== '1'}
              onClick={() => {
                openDropdownMenu && setOpenDropdownMenu(false);
                handleUpdate(page, tableFilters);
              }}
              type="text"
              icon={<ReloadOutlined />}
            >
              {isMobile ? 'Обновить' : ''}
            </ButtonAntd>
          </Tooltip>
          <Tooltip title={'Отобразить фильтры'}>
            <ButtonAntd
              className={'icons-toolbar-color-white'}
              style={{ margin: '0 10px' }}
              disabled={loading || activeTab !== '1'}
              onClick={() => {
                isMobile && handleScrollToTopMobileList();
                openDropdownMenu && setOpenDropdownMenu(false);
                changeVisibleFilters();
              }}
              type="text"
              icon={<FilterOutlined />}
            >
              {isMobile ? 'Отобразить фильтры' : ''}
            </ButtonAntd>
          </Tooltip>
          <Tooltip title={'Настройка таблицы'}>
            <ButtonAntd
              className={'icons-toolbar-color-white'}
              style={{ marginRight: '10px' }}
              disabled={loading || activeTab !== '1'}
              onClick={() => {
                isMobile && handleScrollToTopMobileList();
                openDropdownMenu && setOpenDropdownMenu(false);
                changeVisibleTableSetting();
              }}
              type="text"
              icon={<SettingOutlined />}
            >
              {isMobile ? 'Настройка таблицы' : ''}
            </ButtonAntd>
          </Tooltip>
          <ButtonAntd
            className={'toolbars-additions'}
            disabled={activeTab !== '1'}
            onClick={() => {
              openDropdownMenu && setOpenDropdownMenu(false);
              setVisibleModal(true);
              handleGetBranchList();
            }}
            icon={<PlusOutlined />}
          >
            Добавить
          </ButtonAntd>
        </aside>
      </>
    );
  };

  return (
    <>
      <Box>
        <Box sx={{ mt: 3 }}>
          <Card className={'primary-bg-color'}>
            <CardContent>
              <Box style={{ display: 'flex' }}>
                <Search
                  placeholder="Поиск сотрудника (ФИО)"
                  disabled={loading || activeTab !== '1'}
                  value={searchStaff}
                  enterButton
                  allowClear
                  onSearch={(value) => handleSearch(value)}
                  onChange={(e) => {
                    setSearchStaff(e.target.value);
                  }}
                />
                {isMobile ? (
                  <Dropdown
                    visible={openDropdownMenu}
                    onVisibleChange={(visible) => {
                      setOpenDropdownMenu(visible);
                    }}
                    overlay={toolbars()}
                    placement="bottomRight"
                    trigger={['click']}
                  >
                    <Button className={'Toolbars-button'}>
                      <svg
                        width="14"
                        height="12"
                        viewBox="0 0 14 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0 1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1C14 1.26522 13.8946 1.51957 13.7071 1.70711C13.5196 1.89464 13.2652 2 13 2H1C0.734784 2 0.48043 1.89464 0.292893 1.70711C0.105357 1.51957 0 1.26522 0 1ZM0 6C0 5.73478 0.105357 5.48043 0.292893 5.29289C0.48043 5.10536 0.734784 5 1 5H13C13.2652 5 13.5196 5.10536 13.7071 5.29289C13.8946 5.48043 14 5.73478 14 6C14 6.26522 13.8946 6.51957 13.7071 6.70711C13.5196 6.89464 13.2652 7 13 7H1C0.734784 7 0.48043 6.89464 0.292893 6.70711C0.105357 6.51957 0 6.26522 0 6ZM6 11C6 10.7348 6.10536 10.4804 6.29289 10.2929C6.48043 10.1054 6.73478 10 7 10H13C13.2652 10 13.5196 10.1054 13.7071 10.2929C13.8946 10.4804 14 10.7348 14 11C14 11.2652 13.8946 11.5196 13.7071 11.7071C13.5196 11.8946 13.2652 12 13 12H7C6.73478 12 6.48043 11.8946 6.29289 11.7071C6.10536 11.5196 6 11.2652 6 11Z"
                          fill="#0B7B7F"
                        />
                      </svg>
                    </Button>
                  </Dropdown>
                ) : (
                  toolbars()
                )}
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Row style={{ height: '22px' }} justify={'end'}>
          {totalStaff && activeTab === '1' ? (
            <b style={{ margin: '5px 0 0px 5px' }}>Итого: {totalStaff}</b>
          ) : (
            <></>
          )}
        </Row>
      </Box>

      <Modal
        visible={visibleModal}
        title="Добавление сотрудника"
        onCancel={() => {
          addStaffForm.resetFields();
          setVisibleModal(false);
        }}
        footer={[
          <ButtonAntd
            key="back"
            onClick={() => {
              addStaffForm.resetFields();
              setVisibleModal(false);
            }}
          >
            Закрыть
          </ButtonAntd>,
          <ButtonAntd
            key="submit"
            type="primary"
            disabled={disableBtn}
            onClick={async () => {
              addStaffForm
                .validateFields()
                .then((values) => {
                  createUser(values);
                })
                .catch(() => {
                  message.warning(
                    'Возможно вы ввели не все обязательные поля!',
                  );
                });
            }}
          >
            Создать
          </ButtonAntd>,
        ]}
      >
        <Form
          form={addStaffForm}
          onValuesChange={() =>
            setTimeout(() => {
              addStaffForm
                .validateFields()
                .then(() => setDisableBtn(false))
                .catch(() => setDisableBtn(true));
            }, 0)
          }
          layout="vertical"
        >
          <Row gutter={15}>
            <Col lg={12} xs={24}>
              <Form.Item label="ФИО сотрудника" name={'full_name'}>
                <Input
                  autoComplete={'off'}
                  placeholder="Введите ФИО сотрудника"
                />
              </Form.Item>
            </Col>
            <Col lg={12} xs={24}>
              <Form.Item
                label="Телефон (контактный номер)"
                name={'phone_number'}
                rules={[{ required: true, message: 'Обязательное поле!' }]}
              >
                <MaskedInput
                  autoComplete={'off'}
                  mask="+1 (111) 111 11 11"
                  name="phone_number"
                  placeholder="+_ (___) ___ __ __"
                />
              </Form.Item>
            </Col>
            <Col lg={12} xs={24}>
              <Form.Item
                label="Пароль в CRM"
                name={'password1'}
                rules={[{ required: true, message: 'Обязательное поле!' }]}
              >
                <Input.Password
                  autoComplete={'off'}
                  placeholder="Введите пароль"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
            </Col>
            <Col lg={12} xs={24}>
              <Form.Item
                label="Подтверждение пароля CRM"
                name={'password2'}
                rules={[{ required: true, message: 'Обязательное поле!' }]}
              >
                <Input.Password
                  autoComplete={'off'}
                  placeholder="Подтвердите пароль"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
            </Col>
            <Col lg={12} xs={24}>
              <Form.Item
                label="Почта сотрудника"
                name={'email'}
                rules={[{ required: true, message: 'Обязательное поле!' }]}
              >
                <Input
                  autoComplete={'off'}
                  placeholder="Введите email сотрудника"
                />
              </Form.Item>
            </Col>
            <Col lg={12} xs={24}>
              <Form.Item
                label="Пароль от почты сотрудника"
                name={'auth_pass_mail'}
                rules={[{ required: true, message: 'Обязательное поле!' }]}
              >
                <Input.Password
                  autoComplete={'off'}
                  placeholder="Введите пароль от почты сотрудника"
                />
              </Form.Item>
            </Col>
            {userInfo?.current?.haveViewBranch ? (
              <Col lg={12} xs={24}>
                <Form.Item
                  label="Филиал"
                  rules={[{ required: true, message: 'Обязательное поле!' }]}
                  name={'branch'}
                >
                  <Select
                    getPopupContainer={(trigger) =>
                      isMobile ? trigger.parentNode : document.body
                    }
                    showSearch
                    placeholder="Филиал"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {branchList && branchList.length > 0 ? (
                      <>
                        {branchList.map((item, index) => {
                          return (
                            <Option
                              key={index}
                              data-value={JSON.stringify(item)}
                              value={item.id}
                            >
                              {item.name}
                            </Option>
                          );
                        })}
                      </>
                    ) : (
                      <Option disabled>Нет доступных вариантов</Option>
                    )}
                  </Select>
                </Form.Item>
              </Col>
            ) : (
              ''
            )}
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default StaffListToolbar;
