import React from 'react';
import { Col, Row } from 'antd';
import {
  CalendarOutlined,
  FieldTimeOutlined,
  UserOutlined,
  TagOutlined,
} from '@ant-design/icons';
import getMemberCreatorsEvent from '../../modals/utils/getMemberCreatorsEvent';
import PropTypes from 'prop-types';
import { getTime } from '../../calendarUtils/getTime';

const BriefInformationAboutTaskPopoverContent = (props) => {
  /* const getDate = (taskEndDate) => {
    if (!taskEndDate) return;
    return dayjs(taskEndDate).format('HH:mm, DD-MM-YYYY');
  };*/

  const getStringTags = (taskTags) => {
    if (!taskTags) return;
    return taskTags
      .map((tag) => {
        return tag?.tag_name;
      })
      .join(', ');
  };

  const getStringMembers = (taskMembers) => {
    if (!taskMembers) return;
    return taskMembers
      .filter((member) => member?.group === 'mem')
      .map((member) => {
        return member?.full_name;
      })
      .join(', ');
  };

  const getCreatorTask = (members) => {
    if (!members) return '';
    return getMemberCreatorsEvent(members);
  };

  return (
    <>
      <Col
        className={'user-select-none'}
        onClick={(event) => {
          event.preventDefault();
        }}
        onDoubleClick={() => {
          props.handleEditTask(props.task);
        }}
      >
        <Col>
          <Row gutter={10}>
            <Col>
              <FieldTimeOutlined style={{ fontSize: '18px' }} />
            </Col>
            <Col>
              {getTime(
                props.task?.lead || props.task?.customer
                  ? props.task.start
                  : props.task.end,
                'HH:mm, DD-MM-YYYY',
              )}
            </Col>
          </Row>
        </Col>
        <Col style={{ marginTop: '10px' }}>
          <Row gutter={10}>
            <Col>
              <TagOutlined style={{ fontSize: '18px' }} />
            </Col>
            <Col>{getStringTags(props.task?.tags)}</Col>
          </Row>
        </Col>

        {!props.task?.lead && !props.task?.customer ? (
          <Col style={{ marginTop: '10px' }}>
            <Row gutter={10}>
              <Col>
                <CalendarOutlined style={{ fontSize: '18px' }} />
              </Col>
              <Col>{'От кого:'}</Col>
              <Col>{`${getCreatorTask(props.task?.members)}`}</Col>
            </Row>
          </Col>
        ) : (
          <></>
        )}
        <Col style={{ marginTop: '10px', maxWidth: '250px' }}>
          <Row gutter={10}>
            <Col>
              <UserOutlined style={{ fontSize: '18px' }} />
            </Col>
            <Col>{'Кому:'}</Col>
            <Col>{`${getStringMembers(props.task?.members)}`}</Col>
          </Row>
        </Col>
      </Col>
    </>
  );
};

BriefInformationAboutTaskPopoverContent.propTypes = {
  task: PropTypes.object.isRequired,
  handleEditTask: PropTypes.func.isRequired,
};

export default React.memo(BriefInformationAboutTaskPopoverContent);
