import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
  Form,
  Row,
  Col,
  Select,
  Input,
  Checkbox,
  InputNumber,
  message,
} from 'antd';
import { restApiHost } from '../../../utils/appVariables';
import { call } from '../../../apiUtils/call';
import { userInformations } from '../../../contex';
import getTypesPack from '../../../logic/refbook/typeCargoPacking/TypeCargoPackingApiFunction';
import useAsyncEffect from 'use-async-effect';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';

const { Option } = Select;

const AddCargoFormTask = ({
  form,
  handleChangeForm,
  createdCargo,
  cargoDetailsValues,
  task,
  taskEdit,
}) => {
  const [cargos, setCargos] = useState([]);
  const [typePackArr, setTypePackArr] = useState([]);
  const { userInfo } = useContext(userInformations);
  const isMobile = useCheckMobileScreen();

  useEffect(async () => {
    await handleGetTypesPack(null);
    await searchCargos(null);
  }, []);

  useEffect(() => {
    if (createdCargo) {
      createdCargo?.id && form.setFieldsValue({ cargo: createdCargo.id });
      createdCargo?.name && searchCargos(createdCargo.name);
      form.setFieldsValue({ security: createdCargo?.security });
    }
  }, [createdCargo]);

  useAsyncEffect(async () => {
    if (cargoDetailsValues?.cargo_type_packing?.name) {
      await handleGetTypesPack(cargoDetailsValues?.cargo_type_packing?.name);
    }
  }, [cargoDetailsValues]);

  const searchCargos = async (keyword) => {
    const params = new URLSearchParams();
    params.set('active', 'true');
    if (keyword?.length > 0) {
      params.set('search', keyword);
    }

    try {
      const url = `${restApiHost}/refbook/cargo/?${params}`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (json?.data) {
        setCargos(json.data);
      }
    } catch (e) {
      console.log('search cargo err', e);
      message.error('Ошибка поиска грузов');
      setCargos([]);
    }
  };

  const handleGetTypesPack = useCallback(
    async (keyword) => {
      const res = await getTypesPack(userInfo, keyword);
      if (res) {
        setTypePackArr(res);
      }
    },
    [setTypePackArr],
  );

  return (
    <Form form={form} layout={'vertical'} onValuesChange={handleChangeForm}>
      <Row gutter={16}>
        <Col xs={24} lg={24}>
          <Form.Item name={'cargo'} label={'Груз'}>
            <Select
              getPopupContainer={(trigger) =>
                isMobile ? trigger.parentNode : document.body
              }
              disabled={!task}
              showSearch
              filterOption={false}
              onChange={(value, data) => {
                let cargo = JSON.parse(data['data-value']).id;
                if (cargo && task) {
                  taskEdit({ cargo }, task);
                } else {
                  message.warning('Что-то пошло не так...');
                }
              }}
              onSearch={(value) => searchCargos(value)}
              placeholder="Найти груз"
            >
              {cargos.length > 0 ? (
                <>
                  {Array.isArray(cargos) &&
                    cargos.map((item) => {
                      return (
                        <Option
                          key={item.id}
                          value={item.id}
                          data-value={JSON.stringify(item)}
                        >
                          {item.name}
                        </Option>
                      );
                    })}
                </>
              ) : null}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item name={'cargo_type_packing'} label={'Тип упаковки'}>
            <Select
              getPopupContainer={(trigger) =>
                isMobile ? trigger.parentNode : document.body
              }
              onSearch={async (keyword) => {
                await handleGetTypesPack(keyword);
              }}
              showSearch={true}
              filterOption={false}
              placeholder="Выберите тип упаковки"
            >
              {typePackArr &&
                typePackArr.map((item) => {
                  return (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} lg={12}>
          <Form.Item
            valuePropName="checked"
            name={'security'}
            label={'Охрана груза'}
          >
            <Checkbox disabled>Охрана</Checkbox>
          </Form.Item>
        </Col>
        {/*<Col xs={24} lg={12}>*/}
        {/*    <Form.Item name={"cargo_etsng"} label={"Груз ЕТСНГ"}>*/}
        {/*        <Input placeholder={"Груз ЕТСНГ"} />*/}
        {/*    </Form.Item>*/}
        {/*</Col>*/}
        <Col xs={24} lg={12}>
          <Form.Item name={'cargo_gng'} label={'Груз ГНГ'}>
            <Input placeholder={'Груз ГНГ'} />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item name={'cargo_weight'} label={'Вес (кг)'}>
            <Input placeholder={'Вес общий, кг'} />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item name={'cargo_volume'} label={'Объем, м3'}>
            <Input placeholder={'Объем, м3'} />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item name={'cargo_places'} label={'Мест'}>
            <InputNumber
              min={1}
              max={100000}
              defaultValue={1}
              placeholder={'Мест'}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default AddCargoFormTask;
