import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
  Modal,
  Button,
  Form,
  Input,
  Row,
  Col,
  message,
  Select,
  Alert,
  Steps,
  Popover,
  Tooltip,
  Empty,
} from 'antd';
import { ExpandAltOutlined } from '@ant-design/icons';
import { restApiHost } from '../../utils/appVariables';
import moment from 'moment';

import 'moment/locale/ru';
import AddTransportation from './transportation/AddTransportation';
import ManagersTable from '../customer/ManagersTable';
import EmailsForClients from '../email/EmailsForClients';
import { userInformations } from '../../contex';
import { call } from '../../apiUtils/call';
import ContractStep from './customerStepsComponents/ContractStepComponents/ContractStep';
import AttachmentTab from '../customer/attachments/AttachmentTab';
import MultipleSelectFromForm from '../UiComponents/selects/MultipleSelectFromForm';
import { ErrorBoundary } from '../ErrorBoundary';
import CustomerGroupCompany from './customerStepsComponents/groupCompany/CustomerGroupCompany';
import ContactPersonTable from './customerStepsComponents/contactPerson/ContactPersonTable';
import { getDisplayedSteps } from '../customer/cutomerModalUtils/customerModalUtilsFunctions';
import CustomerChangeHistory from './customerStepsComponents/customerChangeHistory/CustomerChangeHistory';
import getCallResult from '../../logic/refbook/callResult/CallResultApiFunction';
import CustomDot from '../UiComponents/steps/CustomDot';
import { useTypedSelector } from '../../store/hooks/useTypedSelector';
import useAsyncEffect from 'use-async-effect';
import CustomerBanks from '../customer/CustomerBanks';
import { getListStrategicArchReason } from '../../logic/refbook/strategicArchReason/StrategicArchReasonApiFunctions';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
const { Option } = Select;
const { Step } = Steps;

const UpdateCustomerModal = ({
  visible,
  handleOk,
  data,
  handleCancel,
  loading,
  currentStep,
  getDataCustomer,
  onlyUsedToAddSigner,
  defaultOpenContractStep,
  setDefaultOpenContractStep,
}) => {
  const [mainInfoForm] = Form.useForm();
  const [joinComanyForm] = Form.useForm();
  const [typesClient, setTypesClient] = useState([]);
  const [categoriesClient, setCategoriesClient] = useState([]);
  const [docBases, setDocBases] = useState([]);
  const [email, setEmail] = useState('');
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [createdCustomerData, setCreatedCustomerData] = useState(null);
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const [current, setCurrent] = React.useState(0);
  const [mainInfoClient, setMainInfoClient] = useState(null);
  const [stratArchReasonsOptions, setStratArchReasonsOptions] = useState([]);
  //change type category client
  const [selectedCategoriesClient, setSelectedCategoriesClient] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [defaultCategories, setDefaultCategories] = useState([]);
  const [callResultOptions, setCallResultOptions] = useState([]);

  const [currSubsidiaryList, setCurrSubsidiaryList] = useState([]);

  //source client
  const [choices, setChoices] = useState(null);
  const [sourceOtherSelected, setSourceOtherSelected] = useState(false);
  const [isArchive, setIsArchive] = useState(false);

  //contact person comment
  const [contactComment, setContactComment] = useState([]);

  const [clientHasCategoryOur, setClientHasCategoryOur] = useState(false);
  const [clientHasCategorySkl, setClientHasCategorySkl] = useState(false);

  const [visiblePopover, setVisiblePopover] = useState(false);
  const { userInfo } = useContext(userInformations);
  const demoAccessIsUsed = useTypedSelector(
    (state) => state.demoAccess.demoAccessIsUsed,
  );
  const isMobile = useCheckMobileScreen();

  useAsyncEffect(async () => {
    if (visible) {
      await getCustomerTypes();
      await getChoices();
      await callGetCallResult();
      await callGetStratArchReasons();
    }
  }, [visible]);

  useEffect(() => {
    if (currentStep) {
      setCurrent(currentStep);
    }
  }, [currentStep]);

  useEffect(() => {
    if (Number(current) !== 7 && defaultOpenContractStep) {
      setDefaultOpenContractStep(false);
    }
  }, [current]);

  useEffect(() => {
    if (!visible) {
      setCurrent(0);
      setIsArchive(false);
      mainInfoForm.resetFields();
    }
  }, [visible]);

  useEffect(() => {
    if (data) {
      setCreatedCustomerData(data);
      mainInfoForm.setFieldsValue({
        ...data,
        category: null,
        call_result: data?.call_result?.id,
        date_recent_request: data?.date_recent_request
          ? moment(data.date_recent_request).format('YYYY-MM-DD')
          : null,
        date_next_request: data?.date_next_request
          ? moment(data.date_next_request).format('YYYY-MM-DD HH:mm')
          : null,
      });
      //setCurrent(0);

      if (data.source) {
        const choicesArr = choices?.source
          ? Object.entries(choices.source).map((item) => item[1])
          : [];

        if (choicesArr.includes(data.source)) {
          mainInfoForm.setFieldsValue({ source: data.source });
        } else {
          setSourceOtherSelected(true);
          mainInfoForm.setFieldsValue({ source_other: data.source });
          mainInfoForm.setFieldsValue({ source: 'Другое' });
        }
      }

      if (data?.category && data?.category.length > 0) {
        setCategoriesList(data.category);
        setSelectedCategoriesClient(
          data.category.map((item) => item?.category),
        );
        setDefaultCategories(data.category.map((item) => item?.category));
        mainInfoForm.setFieldsValue({
          category: data.category.map((item) => item?.category),
        });
      }

      if (data?.subsidiary) {
        setCurrSubsidiaryList(data?.subsidiary);
      }

      if (data?.call_result?.id === 2) {
        setIsArchive(true);
      }

      data.email && setEmail(data.email);
      data.phone_numbers &&
        data.phone_numbers.length > 0 &&
        setPhoneNumbers(data.phone_numbers);

      // data.contact_persons && setContactPersons(data.contact_persons);
    }
  }, [data]);

  useEffect(() => {
    if (
      selectedCategoriesClient?.length > 0 &&
      selectedCategoriesClient?.includes('our')
    ) {
      setClientHasCategoryOur(true);
    } else {
      setClientHasCategoryOur(false);
    }
    if (
      selectedCategoriesClient?.length === 1 &&
      selectedCategoriesClient?.includes('skl')
    ) {
      setClientHasCategorySkl(true);
    } else {
      setClientHasCategorySkl(false);
    }
  }, [selectedCategoriesClient]);

  const deleteCategoryClient = async (id) => {
    try {
      const url = `${restApiHost}/customers/customer_categories/${id}/`;
      const response = await call(
        url,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!response) return;

      let newCategoriesList = categoriesList.filter((item) => item.id !== id);
      setCategoriesList(newCategoriesList);
      message.success('Успешно удалено!');
    } catch (e) {
      console.log('delete category client err', e);
      message.error('Ошибка запроса на удаление типа клиента');
    }
  };
  const getChoices = async () => {
    try {
      const url = `${restApiHost}/leads/type_choices`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );
      if (json?.data) {
        setChoices(json.data);
      }
    } catch (e) {
      console.log('get choices err', e);
    }
  };

  const callGetCallResult = async () => {
    const res = await getCallResult('customer', userInfo);
    if (res) {
      setCallResultOptions(res);
    }
  };

  const addCategoryClient = async (body) => {
    try {
      const url = `${restApiHost}/customers/customer_categories/`;
      const json = await call(
        url,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json?.data) return;

      setCategoriesList([...categoriesList, json?.data]);
      message.success('Успешно обновлено!');
    } catch (e) {
      console.log('add category to client err', e);
      message.error('Ошибка запроса на добавление типа компании');
    }
  };

  const callGetStratArchReasons = useCallback(async () => {
    const res = await getListStrategicArchReason(userInfo);
    if (res?.data) {
      setStratArchReasonsOptions(res.data);
    }
  }, [getListStrategicArchReason, setStratArchReasonsOptions]);

  const handleVisibleChange = (newVisible) => {
    setVisiblePopover(newVisible);
  };

  const steps = [
    {
      title: 'Основная информация',
      disabled: onlyUsedToAddSigner,
      visible: true,
      content: () => {
        return (
          <Form
            className={'reset-margin-form'}
            form={mainInfoForm}
            layout={'vertical'}
            onValuesChange={() => {
              setTimeout(() => {
                mainInfoForm
                  .validateFields()
                  .then(() => {
                    setDisableSaveBtn(false);
                  })
                  .catch(() => {
                    setDisableSaveBtn(true);
                  });
              }, 0);
            }}
          >
            <Row gutter={16}>
              <Col xs={24} lg={24}>
                <Row gutter={16}>
                  <Col lg={6} xs={24}>
                    <Form.Item
                      label={'Тип контрагента'}
                      name={'type'}
                      rules={[
                        { required: true, message: 'Обязательное поле!' },
                      ]}
                    >
                      <Select
                        getPopupContainer={(trigger) =>
                          isMobile ? trigger.parentNode : document.body
                        }
                        placeholder={'Тип контрагента'}
                        showSearch
                      >
                        {typesClient.length > 0 ? (
                          <>
                            {typesClient.map((item) => {
                              return (
                                <Option key={item[0]} value={item[0]}>
                                  {item[1]}
                                </Option>
                              );
                            })}
                          </>
                        ) : (
                          <Option disabled>Нет доступных вариантов</Option>
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col lg={6} xs={24}>
                    <Form.Item
                      label={'Тип компании'}
                      name={'category'}
                      rules={[
                        { required: true, message: 'Обязательное поле!' },
                      ]}
                    >
                      <MultipleSelectFromForm
                        placeholder={'Тип компании'}
                        value={defaultCategories}
                        onChange={async (values) => {
                          setSelectedCategoriesClient(values);
                          if (values.length < selectedCategoriesClient.length) {
                            let difference = selectedCategoriesClient.filter(
                              (x) => !values.includes(x),
                            );

                            if (categoriesList.length > 0) {
                              let deleteItem = categoriesList.filter(
                                (item) => item?.category === difference[0],
                              );

                              deleteItem.length === 1 &&
                                (await deleteCategoryClient(deleteItem[0].id));
                            }
                          } else {
                            let difference = values.filter(
                              (x) => !selectedCategoriesClient.includes(x),
                            );
                            await addCategoryClient({
                              customer: createdCustomerData.id,
                              category: difference[0],
                            });
                          }
                        }}
                        options={
                          categoriesClient.length > 0 ? (
                            <>
                              {categoriesClient.map((item) => {
                                return (
                                  <Option key={item[0]} value={item[0]}>
                                    {item[1]}
                                  </Option>
                                );
                              })}
                            </>
                          ) : (
                            <Option disabled>Нет доступных вариантов</Option>
                          )
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={6} xs={24}>
                    <Form.Item
                      label={'Юр. наименование'}
                      name={'legal_name'}
                      rules={[
                        { required: true, message: 'Обязательное поле!' },
                      ]}
                    >
                      <Input placeholder={'Юридическое наименование'} />
                    </Form.Item>
                  </Col>
                  <Col lg={6} xs={24}>
                    <Form.Item
                      label={'Краткое юр. наименование'}
                      name={'short_name'}
                      rules={[
                        { required: true, message: 'Обязательное поле!' },
                      ]}
                    >
                      <Input
                        placeholder={'Сокращенное юридическое наименование'}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              {!clientHasCategorySkl ? (
                <>
                  <Col lg={8} xs={24}>
                    <Form.Item
                      label={'Фактический адрес'}
                      name={'address'}
                      rules={[
                        { required: true, message: 'Обязательное поле!' },
                      ]}
                    >
                      <Input placeholder={'Фактический адрес'} />
                    </Form.Item>
                  </Col>
                  <Col lg={8} xs={24}>
                    <Form.Item label={'Юр. адрес'} name={'legal_address'}>
                      <Input placeholder={'Юридический адрес'} />
                    </Form.Item>
                  </Col>
                  <Col lg={8} xs={24}>
                    <Form.Item label={'Почтовый адрес'} name={'post_address'}>
                      <Input placeholder={'Почтовый адрес'} />
                    </Form.Item>
                  </Col>
                </>
              ) : (
                <></>
              )}
              {!clientHasCategorySkl ? (
                <Col lg={24}>
                  <Row gutter={16}>
                    <Col lg={6} xs={24}>
                      <Row gutter={16}>
                        <Col xs={24} lg={24}>
                          <Form.Item label="Источник" name={'source'}>
                            <Select
                              getPopupContainer={(trigger) =>
                                isMobile ? trigger.parentNode : document.body
                              }
                              onChange={(value) => {
                                value === 'Другое'
                                  ? setSourceOtherSelected(true)
                                  : setSourceOtherSelected(false);
                              }}
                              placeholder={'Выберите источник'}
                            >
                              {choices && choices.source ? (
                                <>
                                  {Object.entries(choices.source).map(
                                    (item) => {
                                      return (
                                        <Option key={item[0]} value={item[1]}>
                                          {item[1]}
                                        </Option>
                                      );
                                    },
                                  )}
                                </>
                              ) : (
                                <Option disabled value={null}>
                                  Нет доступных вариантов
                                </Option>
                              )}
                            </Select>
                          </Form.Item>
                        </Col>
                        {sourceOtherSelected && (
                          <Col xs={24} lg={24}>
                            <Form.Item
                              name={'source_other'}
                              label={'Др. источник'}
                            >
                              <Input placeholder={'Введите свой источник'} />
                            </Form.Item>
                          </Col>
                        )}
                        <Col lg={12} xs={24}>
                          <Form.Item label={'Email компании'} name={'email'}>
                            <Input placeholder={'example@example.com'} />
                          </Form.Item>
                        </Col>
                        <Col lg={12} xs={24}>
                          <Form.Item
                            name={'has_group_company'}
                            label={'Голов. компания'}
                          >
                            <Select
                              getPopupContainer={(trigger) =>
                                isMobile ? trigger.parentNode : document.body
                              }
                              placeholder={'Головная компания'}
                            >
                              <Option value={true}>Да</Option>
                              <Option value={false}>Нет</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        {selectedCategoriesClient?.length > 0 &&
                          selectedCategoriesClient?.includes('our') && (
                            <Col lg={24} xs={24}>
                              <Form.Item label={'Префикс'} name={'designation'}>
                                <Input
                                  placeholder={'Введите префикс для компании'}
                                />
                              </Form.Item>
                            </Col>
                          )}
                        <Col xs={24} lg={24}>
                          <Form.Item label="Итог звонка" name={'call_result'}>
                            <Select
                              getPopupContainer={(trigger) =>
                                isMobile ? trigger.parentNode : document.body
                              }
                              onChange={(value) => {
                                value === 2
                                  ? setIsArchive(true)
                                  : setIsArchive(false);
                              }}
                              placeholder={'Выберите итог звонка'}
                            >
                              {callResultOptions &&
                              callResultOptions?.length > 0 ? (
                                <>
                                  {callResultOptions.map((item) => {
                                    return (
                                      <Option key={item?.id} value={item?.id}>
                                        {item?.result}
                                      </Option>
                                    );
                                  })}
                                </>
                              ) : (
                                <Option disabled value={null}>
                                  Нет доступных вариантов
                                </Option>
                              )}
                            </Select>
                          </Form.Item>
                        </Col>
                        {isArchive ? (
                          <>
                            <Col xs={24} lg={24}>
                              <Form.Item
                                label="Причина страт. архива"
                                name={'strat_arch_reason'}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Обязательное поле!',
                                  },
                                ]}
                              >
                                <Select
                                  getPopupContainer={(trigger) =>
                                    isMobile
                                      ? trigger.parentNode
                                      : document.body
                                  }
                                  placeholder={'Выберите причину'}
                                >
                                  {stratArchReasonsOptions &&
                                  stratArchReasonsOptions?.length ? (
                                    <>
                                      {stratArchReasonsOptions.map((item) => {
                                        return (
                                          <Option
                                            key={item?.id}
                                            value={item?.id}
                                          >
                                            {item?.reason}
                                          </Option>
                                        );
                                      })}
                                    </>
                                  ) : (
                                    <Option disabled value={null}>
                                      Нет доступных вариантов
                                    </Option>
                                  )}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col xs={24} lg={24}>
                              <Form.Item
                                label="Комментарий (страт. архив)"
                                name={'strat_arch_reason_comment'}
                              >
                                <Input.TextArea placeholder="Введите комментарий (страт. архив)" />
                              </Form.Item>
                            </Col>
                          </>
                        ) : (
                          <></>
                        )}
                      </Row>
                    </Col>
                    <Col lg={6} xs={24}>
                      <Row gutter={16}>
                        <Col lg={24} xs={24}>
                          <Form.Item
                            label="Посл. общение"
                            name={'date_recent_request'}
                          >
                            <input
                              className={'ant-input'}
                              max="9999-12-31"
                              type={'date'}
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={24} xs={24}>
                          <Form.Item
                            label="След. общение"
                            name={'date_next_request'}
                            rules={[
                              {
                                required:
                                  !clientHasCategoryOur && !demoAccessIsUsed,
                                message: 'Обязательное поле!',
                              },
                            ]}
                          >
                            <input
                              className={'ant-input'}
                              type="datetime-local"
                              max="9999-12-31"
                              pattern="[0-2][0-9]:[0-5][0-9]"
                              min={moment().format('YYYY-MM-DD HH:mm')}
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={24} xs={24}>
                          <Form.Item
                            label={
                              <>
                                Комментарий
                                <Popover
                                  content={
                                    <>
                                      {data?.comment ? (
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: urlify(data?.comment),
                                          }}
                                        ></div>
                                      ) : (
                                        ''
                                      )}
                                    </>
                                  }
                                  title={
                                    data?.short_name ||
                                    data?.legal_name ||
                                    'Комментарий по клиенту'
                                  }
                                  trigger="click"
                                  visible={visiblePopover}
                                  onVisibleChange={handleVisibleChange}
                                >
                                  <Tooltip
                                    placement="topRight"
                                    title="Показать полностью"
                                  >
                                    <ExpandAltOutlined
                                      style={{ marginLeft: 10 }}
                                    />
                                  </Tooltip>
                                </Popover>
                              </>
                            }
                            name={'comment'}
                          >
                            <Input.TextArea
                              style={{ height: 90 }}
                              placeholder="Введите комментарий по клиенту"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={12} xs={24}>
                      <Row gutter={16}>
                        <Col lg={12} xs={24}>
                          <Form.Item
                            label={'ИНН (автозаполнение по РФ)'}
                            name={'inn'}
                          >
                            <Input
                              placeholder={'ИНН'}
                              maxLength={10}
                              onChange={(e) => {
                                if (
                                  e.target.value.length > 0 &&
                                  e.target.value.length <= 10
                                ) {
                                  searchInfoByInn(e.target.value);
                                }

                                if (e.target.value.length > 10) {
                                  message.warning(
                                    'Максимальное кол-во знаков ИНН - 10',
                                  );
                                }
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={12} xs={24}>
                          <Form.Item label={'КПП'} name={'kpp'}>
                            <Input placeholder={'КПП'} maxLength={9} />
                          </Form.Item>
                        </Col>
                        <Col lg={12} xs={24}>
                          <Form.Item label={'ОКПО'} name={'okpo'}>
                            <Input placeholder={'ОКПО'} />
                          </Form.Item>
                        </Col>

                        <Col lg={12} xs={24}>
                          <Form.Item label={'ОГРН'} name={'ogrn'}>
                            <Input placeholder={'ОГРН'} />
                          </Form.Item>
                        </Col>
                        <Col lg={24} xs={24}>
                          <Form.Item label={'ОКВЭД'} name={'okved'}>
                            <Input placeholder={'ОКВЭД'} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              ) : (
                <></>
              )}
            </Row>
          </Form>
        );
      },
    },
    {
      title: 'Контактные лица',
      disabled: onlyUsedToAddSigner,
      visible: true,
      content: () => {
        return (
          <ErrorBoundary>
            <ContactPersonTable
              clientHasCategoryOur={clientHasCategoryOur}
              clientHasCategorySkl={clientHasCategorySkl}
              docBasesList={docBases}
              contactCommentList={contactComment}
              customerId={createdCustomerData?.id}
              handleUpdateCustomerData={async (customerId) => {
                await handleGetCustomer(customerId);
              }}
            />
          </ErrorBoundary>
        );
      },
    },
    {
      title: 'Менеджеры',
      visible: true,
      disabled: onlyUsedToAddSigner,
      content: () => {
        return (
          <>
            {createdCustomerData ? (
              <>
                <ErrorBoundary>
                  <ManagersTable
                    loadingManagers={false}
                    customerId={createdCustomerData?.id}
                    handleUpdate={(customerId) => handleGetCustomer(customerId)}
                  />
                </ErrorBoundary>
              </>
            ) : (
              <Alert
                style={{ margin: '20px 0' }}
                message="Раздел на данном этапе недоступен"
                description="Чтобы добавить информацию к контрагенту, сначала, вам необходимо нажать на кнопку создания клиента с уже заполненными полями информации"
                type="info"
                showIcon
              />
            )}
          </>
        );
      },
    },
    {
      title: 'Группа компаний',
      disabled: onlyUsedToAddSigner,
      visible: !clientHasCategorySkl,
      content: () => {
        return (
          <ErrorBoundary>
            <CustomerGroupCompany
              form={joinComanyForm}
              customerId={createdCustomerData?.id}
              currSubsidiaryList={currSubsidiaryList}
            />
          </ErrorBoundary>
        );
      },
    },
    {
      title: 'Транспортные пункты',
      disabled: onlyUsedToAddSigner,
      visible: true,
      content: () => {
        return (
          <ErrorBoundary>
            <AddTransportation
              mainCustomerData={data}
              customerId={data?.id}
              customerWaypoints={data?.waypoints ? data?.waypoints : []}
              customerContactPerson={
                data?.contact_persons ? data?.contact_persons : []
              }
              clientHasCategorySkl={clientHasCategorySkl}
              handleUpdate={(customerId) => handleGetCustomer(customerId)}
            />
          </ErrorBoundary>
        );
      },
    },
    {
      title: 'Банки',
      disabled: onlyUsedToAddSigner,
      visible: !clientHasCategorySkl,
      content: () => {
        return (
          <>
            <ErrorBoundary>
              <CustomerBanks customerId={data?.id} />
            </ErrorBoundary>
          </>
        );
      },
    },
    {
      title: 'История изменений',
      disabled: onlyUsedToAddSigner,
      visible: !clientHasCategorySkl,
      content: () => {
        return (
          <>
            <ErrorBoundary>
              <CustomerChangeHistory customerId={createdCustomerData?.id} />
            </ErrorBoundary>
          </>
        );
      },
    },
    {
      title: 'Переписка',
      visible: !clientHasCategorySkl,
      disabled: onlyUsedToAddSigner || demoAccessIsUsed,
      content: () => {
        return (
          <>
            {createdCustomerData?.id ? (
              <ErrorBoundary>
                <EmailsForClients customer={createdCustomerData.id} />
              </ErrorBoundary>
            ) : (
              <Empty description={<b>Нет писем</b>} />
            )}
          </>
        );
      },
    },
    {
      title: 'Договоры',
      disabled: onlyUsedToAddSigner || demoAccessIsUsed,
      visible: !clientHasCategorySkl,
      content: () => {
        return (
          <>
            <ErrorBoundary>
              <ContractStep
                customerId={createdCustomerData?.id}
                renderAfterCreationContract={defaultOpenContractStep}
              />
            </ErrorBoundary>
          </>
        );
      },
    },
    {
      title: 'Вложения',
      disabled: onlyUsedToAddSigner || demoAccessIsUsed,
      visible: !clientHasCategorySkl,
      content: () => {
        return (
          <>
            <ErrorBoundary>
              <AttachmentTab customerId={createdCustomerData?.id} />
            </ErrorBoundary>
          </>
        );
      },
    },
  ];

  const handleGetCustomer = async (customerId) => {
    if (customerId) {
      getDataCustomer(customerId);
    }
  };

  const getCustomerTypes = async () => {
    try {
      const url = `${restApiHost}/customers/type_choices/`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!json?.data) return;

      setTypesClient(Object.entries(json.data?.type));
      setCategoriesClient(Object.entries(json.data?.category));
      setDocBases(Object.entries(json.data?.base_doc));
      setContactComment(Object.entries(json.data?.doc_comment));
    } catch (e) {
      console.log('get customer types err', e);
      message.error('Ошибка запроса на получение типа клиента');
    }
  };

  const searchInfoByInn = async (inn) => {
    try {
      const url = `${restApiHost}/customers/get_company_details?inn=${inn}`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      const data = Object.entries(json?.result);

      if (data.length > 0) {
        mainInfoForm.setFieldsValue({
          short_name: data[0][1].short_name,
          legal_name: data[0][1].full_name,
          kpp: data[0][1].kpp,
          ogrn: data[0][1].ogrn,
          okpo: data[0][1].okpo,
          okved: data[0][1].okved,
          address: data[0][1].address,
          legal_address: data[0][1].address,
          post_address: data[0][1].address,
        });
      }
    } catch (e) {
      console.log('get bank list err', e);
      message.error('Ошибка запроса поиска информации по ИНН!');
    }
  };

  const createCustomer = async (body) => {
    try {
      const url = `${restApiHost}/customers/create/`;
      const json = await call(
        url,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      setCreatedCustomerData(json);
      message.success('Клиент успешно создан!');
    } catch (e) {
      console.log('create customer err', e);
      message.error('Ошибка запроса для создания клиента');
    }
  };

  const next = () => {
    if (current === 0) {
      mainInfoForm.validateFields().then((values) => {
        setMainInfoClient(values);
        setCurrent(current + 1);
      });
      return;
    }
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  function urlify(text) {
    var urlRegex =
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;
    return text.replace(urlRegex, function (url) {
      return '<a target="_blank" href="' + url + '">' + url + '</a>';
    });
    // or alternatively
    // return text.replace(urlRegex, '<a href="$1">$1</a>')
  }

  const editCustomer = async (id, body) => {
    const dateNextRequest = body?.date_next_request
      ? new Date(`${body?.date_next_request}`)
      : null;

    const bodyFormat = {
      ...body,
      date_next_request: dateNextRequest
        ? moment(dateNextRequest).format()
        : null,
    };

    !bodyFormat.date_next_request && delete bodyFormat.date_next_request;
    !bodyFormat.date_recent_request && delete bodyFormat.date_recent_request;

    try {
      const url = `${restApiHost}/customers/list/${id}/`;
      const json = await call(
        url,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(bodyFormat),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      message.success('Основная информация клиента успешно отредактирована!');
    } catch (e) {
      console.log('edit customer err', e);
      message.error('Ошибка запроса на редактирование клиента');
    }
  };

  return (
    <Modal
      // bodyStyle={{zIndex: onlyUsedToAddSigner ? 200 : 100,position: 'relative'}}
      zIndex={onlyUsedToAddSigner ? 200 : 98}
      visible={visible}
      width={1350}
      title="Редактирование контрагента"
      onOk={() => {
        joinComanyForm.resetFields();
        handleOk();
      }}
      onCancel={() => {
        joinComanyForm.resetFields();
        handleCancel();
      }}
      footer={[]}
    >
      <>
        <Steps
          className={'customer-modal-steps'}
          size="small"
          progressDot={CustomDot}
          current={current}
          onChange={(current) => setCurrent(current)}
        >
          {getDisplayedSteps(steps).map((item) => (
            <Step
              style={{ marginTop: '7px' }}
              key={item.title}
              title={item.title}
              disabled={item.disabled}
            />
          ))}
        </Steps>
        <div className="steps-content" style={{ marginTop: '10px' }}>
          {getDisplayedSteps(steps)[current]?.content()}
        </div>
        <div
          className="steps-action"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          {current > 0 && (
            <Button
              disabled={onlyUsedToAddSigner}
              style={{ margin: '0 8px' }}
              onClick={() => prev()}
            >
              Назад
            </Button>
          )}
          {current === 0 || current === getDisplayedSteps(steps).length - 1 ? (
            <Button
              style={{ marginRight: '10px' }}
              disabled={disableSaveBtn || demoAccessIsUsed}
              onClick={() => {
                mainInfoForm
                  .validateFields()
                  .then(async (values) => {
                    await editCustomer(createdCustomerData.id, { ...values });
                  })
                  .catch((e) => {
                    console.log(e);
                    message.error(
                      'Возможно не все обязательные поля заполнены!',
                    );
                  });
              }}
            >
              Сохранить
            </Button>
          ) : (
            <></>
          )}
          {current <
            (demoAccessIsUsed && !clientHasCategorySkl
              ? getDisplayedSteps(steps).length - 4
              : getDisplayedSteps(steps).length - 1) && (
            <Button
              disabled={onlyUsedToAddSigner}
              type="primary"
              onClick={() => next()}
            >
              Далее
            </Button>
          )}
          {current ===
            (demoAccessIsUsed && !clientHasCategorySkl
              ? getDisplayedSteps(steps).length - 4
              : getDisplayedSteps(steps).length - 1) &&
            createdCustomerData && (
              <Button
                type="primary"
                onClick={() => {
                  handleCancel();
                }}
              >
                Закрыть
              </Button>
            )}
          {current === steps.length - 1 && !createdCustomerData && (
            <Button
              type="primary"
              loading={loading}
              onClick={async () => {
                let body = {
                  ...mainInfoClient,
                  email,
                  phone_numbers: phoneNumbers,
                };
                await createCustomer(body);
              }}
            >
              Создать клиента
            </Button>
          )}
        </div>
      </>
    </Modal>
  );
};

export default UpdateCustomerModal;
