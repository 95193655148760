import PropTypes from 'prop-types';
import { Checkbox, Col, Form, Input, Row, Select } from 'antd';
import { ErrorBoundary } from '../../../ErrorBoundary';
import UploaderImage from '../../../CreateCustomer/UploaderImage';
import { MaskedInput, createDefaultMaskGenerator } from 'react-hook-mask';
import React, { useState } from 'react';
import useCheckMobileScreen from '../../../../hooks/useCheckMobileScreen';
const { Option } = Select;

const ContactPersonModalForm = (props) => {
  /*const [formatNumber, setFormatNumber] = useState(true);*/
  const [visibleModalPreviewStamp, setVisibleModalPreviewStamp] =
    useState(false);
  const [visibleModalPreviewFacsimile, setVisibleModalPreviewFacsimile] =
    useState(false);
  const maskGenerator = createDefaultMaskGenerator('9 (999) 999 99 99');
  const [phoneNumberValue, setPhoneNumberValue] = useState('');
  const isMobile = useCheckMobileScreen();

  const previewImage = (file, useForFacsimile) => {
    if (useForFacsimile) {
      setVisibleModalPreviewFacsimile(true);
      return;
    }
    setVisibleModalPreviewStamp(true);
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleUploaderImageChange = (info, facsimile) => {
    if (info?.file?.originFileObj && info?.file?.status !== 'removed') {
      getBase64(info.file.originFileObj, (url) => {
        if (facsimile) {
          props.setFacsimileImage({
            originFileObj: info.file.originFileObj,
            url,
          });
          props.setFacsimileImageUrl(url);
        } else {
          props.setStampImageUrl(url);
          props.setStampImage({ originFileObj: info.file.originFileObj, url });
        }
      });
      return;
    }

    if (facsimile) {
      props.setFacsimileImage(null);
      props.setFacsimileImageUrl(null);
    } else {
      props.setStampImageUrl(null);
      props.setStampImage(null);
    }
  };

  return (
    <>
      <Form form={props.form} layout={'vertical'}>
        <Row gutter={16}>
          <Col lg={12} md={12} xs={24}>
            <Form.Item
              label={'ФИО'}
              name={'full_name'}
              rules={[{ required: true, message: 'Обязательное поле!' }]}
            >
              <Input placeholder={'Введите ФИО'} />
            </Form.Item>
          </Col>
          {props.isSignatoryContact ? (
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label={
                  <label>
                    <span
                      style={{
                        display: 'inline-block',
                        marginRight: '4px',
                        color: '#ff4d4f',
                        fontSize: '14px',
                        fontFamily: 'SimSun, sans-serif',
                      }}
                    >
                      *
                    </span>
                    ФИО для док-ов
                  </label>
                }
              >
                <Form.Item
                  rules={[{ required: true, message: 'Обязательное поле!' }]}
                  name={'full_name_rp'}
                  noStyle
                >
                  <Input placeholder={'Иванов Иван Иванович'} />
                </Form.Item>
                <span style={{ color: '#f5222d', fontStyle: 'italic' }}>
                  *Введите фио в родительном падеже
                </span>
              </Form.Item>
            </Col>
          ) : (
            <></>
          )}
          <Col lg={12} xs={24}>
            <Form.Item
              label={'Должность'}
              name={'position'}
              rules={[
                {
                  required: !props.clientHasCategorySkl,
                  message: 'Обязательное поле!',
                },
              ]}
            >
              <Input placeholder={'Введите должность в компании'} />
            </Form.Item>
          </Col>
          {props.isSignatoryContact ? (
            <Col lg={12} xs={24}>
              <Form.Item
                label={
                  <label>
                    <span
                      style={{
                        display: 'inline-block',
                        marginRight: '4px',
                        color: '#ff4d4f',
                        fontSize: '14px',
                        fontFamily: 'SimSun, sans-serif',
                      }}
                    >
                      *
                    </span>
                    Должность для док-ов
                  </label>
                }
              >
                <Form.Item
                  rules={[{ required: true, message: 'Обязательное поле!' }]}
                  name={'position_rp'}
                  noStyle
                >
                  <Input placeholder={'Директора'} />
                </Form.Item>
                <span style={{ color: '#f5222d', fontStyle: 'italic' }}>
                  *Введите должность в родительном падеже
                </span>
              </Form.Item>
            </Col>
          ) : (
            <></>
          )}
          <Col lg={12} xs={24}>
            <Form.Item
              name={'can_sign_contract'}
              valuePropName="checked"
              label={'Подписант'}
            >
              <Checkbox
                checked={props.isSignatoryContact}
                onChange={(e) => {
                  props.changeIsSignatoryContact(e.target.checked);
                }}
              >
                <span style={{ color: 'black' }}>
                  Имеет право подписывать договор
                </span>
              </Checkbox>
            </Form.Item>
          </Col>
          <Col lg={12} xs={24}>
            <Form.Item name={'sex'} label={'Пол'}>
              <Select
                getPopupContainer={(trigger) =>
                  isMobile ? trigger.parentNode : document.body
                }
                placeholder={'Выберите пол'}
              >
                <Option value={'m'}>Мужской</Option>
                <Option value={'f'}>Женский</Option>
              </Select>
            </Form.Item>
          </Col>
          {props.clientHasCategoryOur ? (
            <Col lg={12} xs={24}>
              <Form.Item label={'Подпись'}>
                <>
                  <ErrorBoundary>
                    <UploaderImage
                      handleUploaderChange={(info) =>
                        handleUploaderImageChange(info, true)
                      }
                      imgUrl={props.facsimileImageUrl}
                      image={props.facsimileImage}
                      visibleModalPreview={visibleModalPreviewFacsimile}
                      previewImage={(file) => previewImage(file, true)}
                      closeModalPreview={() =>
                        setVisibleModalPreviewFacsimile(false)
                      }
                    />
                  </ErrorBoundary>
                </>
              </Form.Item>
            </Col>
          ) : (
            <></>
          )}
          {props.clientHasCategoryOur ? (
            <Col lg={12} xs={24}>
              <Form.Item label={'Печать'}>
                <>
                  <ErrorBoundary>
                    <UploaderImage
                      handleUploaderChange={(info) =>
                        handleUploaderImageChange(info, false)
                      }
                      imgUrl={props.stampImageUrl}
                      image={props.stampImage}
                      visibleModalPreview={visibleModalPreviewStamp}
                      previewImage={(file) => previewImage(file, false)}
                      closeModalPreview={() =>
                        setVisibleModalPreviewStamp(false)
                      }
                    />
                  </ErrorBoundary>
                </>
              </Form.Item>
            </Col>
          ) : (
            <></>
          )}
          <Col
            lg={8}
            md={8}
            xs={24}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Form.Item label={'Номер телефона'} name={'phone_number'}>
              <MaskedInput
                className={'ant-input'}
                style={{ width: 'auto', marginRight: 10 }}
                placeholder="_ (___) ___ __ __"
                maskGenerator={maskGenerator}
                value={phoneNumberValue}
                onChange={(val) => {
                  setPhoneNumberValue(val);
                }}
              />
            </Form.Item>
          </Col>
          <Col lg={8} md={8} xs={24}>
            <Form.Item label={'Почта'} name={'email'}>
              <Input placeholder={'Введите email'} />
            </Form.Item>
          </Col>

          <Col lg={8} md={8} xs={24}>
            <Form.Item label={'Дата рождения'} name={'birthday'}>
              <input
                className={'ant-input'}
                type={'date'}
                max="9999-12-31"
                /*defaultValue={birthdayState}
                                onChange={e => setBirthday(e.target.value)}*/
              />
            </Form.Item>
          </Col>
          {props.isSignatoryContact ? (
            <Col lg={12} xs={24}>
              <Form.Item label={'Основание документа'} name={'base_doc'}>
                <Select
                  getPopupContainer={(trigger) =>
                    isMobile ? trigger.parentNode : document.body
                  }
                >
                  {props.docBasesList.length > 0 ? (
                    <>
                      {props.docBasesList.map((item) => {
                        return (
                          <Option key={item[0]} value={item[0]}>
                            {item[1]}
                          </Option>
                        );
                      })}
                    </>
                  ) : (
                    <Option disabled>Нет доступных вариантов</Option>
                  )}
                </Select>
              </Form.Item>
            </Col>
          ) : (
            <></>
          )}
          {props.isSignatoryContact ? (
            <Col lg={12} xs={24}>
              <Form.Item label={'Номер документа'} name={'base_doc_number'}>
                <Input placeholder={'Введите номер документа'} />
              </Form.Item>
            </Col>
          ) : (
            <></>
          )}
          {props.isSignatoryContact ? (
            <Col lg={12} xs={24}>
              <Form.Item
                label={'Начальная дата документа'}
                name={'base_doc_date_begin'}
              >
                <input
                  className={'ant-input'}
                  type={'date'}
                  max="9999-12-31"
                  /*defaultValue={baseDocBegin}
                                    onChange={e => setBaseDocBegin(e.target.value)}*/
                />
              </Form.Item>
            </Col>
          ) : (
            <></>
          )}
          <Col lg={12} xs={24}>
            <Form.Item label={'Комментарий'} name={'doc_comment'}>
              <Select
                getPopupContainer={(trigger) =>
                  isMobile ? trigger.parentNode : document.body
                }
                placeholder={'Комментарий'}
                showSearch
              >
                {props.contactCommentList.length > 0 &&
                  props.contactCommentList.map((item) => {
                    return (
                      <Option key={item[0]} value={item[0]}>
                        {item[1]}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

ContactPersonModalForm.propTypes = {
  form: PropTypes.any,
  facsimileImage: PropTypes.any,
  facsimileImageUrl: PropTypes.string,
  stampImage: PropTypes.any,
  stampImageUrl: PropTypes.string,
  setFacsimileImage: PropTypes.func,
  setFacsimileImageUrl: PropTypes.func,
  setStampImageUrl: PropTypes.func,
  setStampImage: PropTypes.func,
  clientHasCategoryOur: PropTypes.bool,
  clientHasCategorySkl: PropTypes.bool,
  docBasesList: PropTypes.any,
  contactCommentList: PropTypes.any,
  isSignatoryContact: PropTypes.bool,
  changeIsSignatoryContact: PropTypes.func,
};

export default ContactPersonModalForm;
