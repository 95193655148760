import { restApiHost } from '../../utils/appVariables';
import { call } from '../../apiUtils/call';
import { message } from 'antd';
import { convertObjFilterToUrl } from '../../utils/getFilters';

export async function createShippingTask(body, userInfo) {
  try {
    const url = `${restApiHost}/shipping/tasks/`;
    const json = await call(
      url,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(body),
      },
      userInfo.current.haveViewBranch,
    );

    if (!json?.data) return;

    return json.data;
  } catch (e) {
    console.log('make task err', e);
    message.error('Ошибка создания заявки');
  }
}

export async function editShippingTask(taskId, body, userInfo) {
  if (!taskId) return;
  try {
    const url = `${restApiHost}/shipping/tasks/${taskId}/`;
    const json = await call(
      url,
      {
        method: 'PATCH',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      userInfo.current.haveViewBranch,
    );

    if (!json?.data) return;

    return json.data;
  } catch (e) {
    console.log('edit task err', e);
    message.error('Ошибка запроса на редактирование заявки');
  }
}

export async function getShippingTaskById(taskId, userInfo) {
  if (!taskId) return;
  try {
    const url = `${restApiHost}/shipping/tasks/${taskId}/`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (!json.data) return;

    return json.data;
  } catch (e) {
    console.log('fetch get Shipping Task By Id error', e);
    message.error('Ошибка запроса на получение информации об заявке');
  }
}

export async function changeShippingTaskTemplate(taskId, body, userInfo) {
  if (!taskId) return;
  try {
    const url = `${restApiHost}/shipping/change_template/`;
    const json = await call(
      url,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(body),
      },
      userInfo.current.haveViewBranch,
    );

    if (!json?.data) return;

    return json.data;
  } catch (e) {
    console.log('change Shipping Task Tempalate err', e);
    message.error('Ошибка изменения шаблона заявки');
  }
}

/**
 * Пункты заявки
 */
export async function getShippingTaskWaypointsList(taskId, params, userInfo) {
  if (!taskId) return;
  let urlParams = ``;
  if (params) {
    urlParams = convertObjFilterToUrl(params);
  }
  try {
    const url = `${restApiHost}/shipping/waypoint/?task=${taskId}${urlParams}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (!json.data) return;

    return json.data;
  } catch (e) {
    console.log('fetch get Shipping Task Waypoints List error', e);
    message.error('Ошибка запроса на получение пунктов заявки');
  }
}

export async function createShippingTaskWaypoint(body, userInfo) {
  try {
    const url = `${restApiHost}/shipping/waypoint/`;
    const json = await call(
      url,
      {
        method: 'POST',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      userInfo.current.haveViewBranch,
    );

    if (!json?.data) return;

    return json.data;
  } catch (e) {
    console.log('fetch create Shipping Task Waypoint error', e);
    message.error('Ошибка запроса на создание пункта');
  }
}

export async function editShippingTaskWaypoint(waypointId, body, userInfo) {
  if (!waypointId) return;
  try {
    const url = `${restApiHost}/shipping/waypoint/${waypointId}/`;
    const json = await call(
      url,
      {
        method: 'PATCH',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      userInfo.current.haveViewBranch,
    );

    if (!json?.data) return;

    return json.data;
  } catch (e) {
    console.log('fetch edit Shipping Task Waypoint error', e);
    message.error('Ошибка запроса на редактирование пункта');
  }
}

export async function deleteShippingTaskWaypoint(waypointId, userInfo) {
  if (!waypointId) return;
  try {
    const url = `${restApiHost}/shipping/waypoint/${waypointId}/`;
    const json = await call(
      url,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (!json?.data) return;

    return json.data;
  } catch (e) {
    console.log('fetch delete Shipping Task Waypoint error', e);
    message.error('Ошибка запроса на удаление пункта');
  }
}

/**
 * Перевозки заявки
 */
export async function getShippingTaskTransportationList(
  taskId,
  params,
  userInfo,
) {
  if (!taskId) return;
  let urlParams = ``;
  if (params) {
    urlParams = convertObjFilterToUrl(params);
  }
  try {
    const url = `${restApiHost}/shipping/transport_task/?task=${taskId}${urlParams}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (!json.data) return;

    return json.data;
  } catch (e) {
    console.log('fetch get Shipping Task Transportation List error', e);
    message.error('Ошибка запроса на получение перевозок заявки');
  }
}

export async function addShippingTaskTransport(body, userInfo) {
  try {
    const url = `${restApiHost}/shipping/transport/`;
    const json = await call(
      url,
      {
        method: 'POST',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      userInfo.current.haveViewBranch,
    );

    if (!json?.data) return;

    return json.data;
  } catch (e) {
    console.log('fetch add Shipping Task Transport error', e);
    message.error('Ошибка запроса на добаление транспорта к заявке');
  }
}

export async function editShippingTaskTransport(id, body, userInfo) {
  if (!id) return;
  try {
    const url = `${restApiHost}/shipping/transport/${id}/`;
    const json = await call(
      url,
      {
        method: 'PATCH',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      userInfo.current.haveViewBranch,
    );

    if (!json?.data) return;

    return json.data;
  } catch (e) {
    console.log('fetch edit Shipping Task Cargo error', e);
    message.error('Ошибка запроса на редактирование транспорта');
  }
}

export async function addShippingTaskCargo(body, userInfo) {
  try {
    const url = `${restApiHost}/shipping/transport_task/`;
    const json = await call(
      url,
      {
        method: 'POST',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      userInfo.current.haveViewBranch,
    );

    if (!json?.data) return;

    return json.data;
  } catch (e) {
    console.log('fetch add Shipping Task Cargo error', e);
    message.error('Ошибка запроса на добаление груза к заявке');
  }
}

export async function editShippingTaskCargo(id, body, userInfo) {
  if (!id) return;
  try {
    const url = `${restApiHost}/shipping/transport_task/${id}/`;
    const json = await call(
      url,
      {
        method: 'PATCH',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      userInfo.current.haveViewBranch,
    );

    if (!json?.data) return;

    return json.data;
  } catch (e) {
    console.log('fetch edit Shipping Task Cargo error', e);
    message.error('Ошибка запроса на изменение груза');
  }
}

export async function deleteShippingTaskCargo(cargoId, userInfo) {
  if (!cargoId) return;
  try {
    const url = `${restApiHost}/shipping/transport_task/${cargoId}/`;
    const json = await call(
      url,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (!json?.data) return;

    return json.data;
  } catch (e) {
    console.log('fetch delete Shipping Task Cargo error', e);
    message.error('Ошибка запроса на удаление перевозки');
  }
}

/**
 * Сотрудники (менеджеры) заявки
 */

export async function getShippingTaskManagersList(taskId, params, userInfo) {
  if (!taskId) return;
  let urlParams = ``;
  if (params) {
    urlParams = convertObjFilterToUrl(params);
  }
  try {
    const url = `${restApiHost}/shipping/task_manager/?task=${taskId}${urlParams}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (!json.data) return;

    return json.data;
  } catch (e) {
    console.log('fetch get Shipping Task Managers List error', e);
    message.error('Ошибка запроса на получение менеджеров заявки');
  }
}

export async function addShippingTaskManagers(body, userInfo) {
  try {
    const url = `${restApiHost}/shipping/task_manager/`;
    const json = await call(
      url,
      {
        method: 'POST',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      userInfo.current.haveViewBranch,
    );

    if (!json?.data) return;

    return json.data;
  } catch (e) {
    console.log('fetch add Shipping Task Managers error', e);
    message.error('Ошибка запроса на добаление менеджера к заявке');
  }
}

export async function editShippingTaskManager(id, body, userInfo) {
  if (!id) return;
  try {
    const url = `${restApiHost}/shipping/task_manager/${id}/`;
    const json = await call(
      url,
      {
        method: 'PATCH',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      userInfo.current.haveViewBranch,
    );

    if (!json?.data) return;

    return json.data;
  } catch (e) {
    console.log('fetch edit Shipping Task Manager error', e);
    message.error('Ошибка запроса на изменение менеджера');
  }
}

export async function deleteShippingTaskManager(managerId, userInfo) {
  if (!managerId) return;
  try {
    const url = `${restApiHost}/shipping/task_manager/${managerId}`;
    const json = await call(
      url,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (!json?.data) return;

    return json.data;
  } catch (e) {
    console.log('fetch delete Shipping Task Manager error', e);
    message.error('Ошибка запроса на удаление менеджера заявки');
  }
}

/**
 * Расходы заявки
 * @param taskId
 * @param endpoint  - либо для Предварительных расходов либо для Фактических расходов
 * @param params
 * @param userInfo
 */
export async function getShippingTaskCostsList(
  taskId,
  endpoint,
  params,
  userInfo,
) {
  if (!taskId || !endpoint) return;
  let urlParams = ``;
  if (params) {
    urlParams = convertObjFilterToUrl(params);
  }
  try {
    const url = `${restApiHost}/shipping/${endpoint}/?task=${taskId}${urlParams}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (!json.data) return;

    return json.data;
  } catch (e) {
    console.log('fetch get Shipping Task Costs List error', e);
    message.error('Ошибка запроса на получение расходов заявки');
  }
}

export async function addShippingTaskCost(body, endpoint, userInfo) {
  if (!endpoint) return;
  try {
    const url = `${restApiHost}/shipping/${endpoint}/`;
    const json = await call(
      url,
      {
        method: 'POST',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      userInfo.current.haveViewBranch,
    );

    if (!json?.data) return;

    return json.data;
  } catch (e) {
    console.log('fetch addShippingTaskCost error', e);
    message.error('Ошибка запроса на добаление расхода');
  }
}

export async function editShippingTaskCost(id, body, endpoint, userInfo) {
  if (!id || !endpoint) return;
  try {
    const url = `${restApiHost}/shipping/${endpoint}/${id}/`;
    const json = await call(
      url,
      {
        method: 'PATCH',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      userInfo.current.haveViewBranch,
    );

    if (!json?.data) return;

    return json.data;
  } catch (e) {
    console.log('fetch edit Shipping Task Cost error', e);
    message.error('Ошибка запроса на изменение расхода');
  }
}

export async function deleteShippingTaskCost(id, endpoint, userInfo) {
  if (!id || !endpoint) return;
  try {
    const url = `${restApiHost}/shipping/${endpoint}/${id}/`;
    const json = await call(
      url,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (!json?.data) return;

    return json.data;
  } catch (e) {
    console.log('fetch delete Shipping Task Cost error', e);
    message.error('Ошибка запроса на удаление расхода');
  }
}
