import dayjs from 'dayjs';

export default function groupingMessageByDate(messagesList) {
  const resultSortingGroup = {};
  if (messagesList?.length > 0) {
    const result = _.mapValues(
      _.groupBy(messagesList, (i) => dayjs(i?.created).format('YYYY-MM-DD')),
    );

    Object.keys(result)
      .sort(function (a, b) {
        return dayjs(a).toDate() - dayjs(b).toDate();
      })
      .forEach(function (key) {
        resultSortingGroup[key] = result[key];
      });
    return Object.entries(resultSortingGroup);
  }
}
