import { Tabs } from 'antd';
import PropTypes from 'prop-types';
import SubItem from './SubItem';
import { useState } from 'react';

const SubsTabs = (props) => {
  const [activeKeyTab, setActiveKeyTab] = useState('0');
  return (
    <>
      <Tabs
        className={'tabs-scroll'}
        activeKey={activeKeyTab}
        type="card"
        destroyInactiveTabPane={true}
        defaultActiveKey={'0'}
        onChange={(activeTab) => setActiveKeyTab(activeTab)}
      >
        {props.subs.map((sub, key) => {
          return (
            <Tabs.TabPane
              className={'tab-panel-scroll'}
              disabled={sub?.length === 1}
              tab={sub[0] ? sub[0] : 'Не заданно'}
              key={key.toString()}
            >
              <SubItem subUrlParamsGetHistory={sub[1] ? sub[1] : null} />
            </Tabs.TabPane>
          );
        })}
      </Tabs>
    </>
  );
};

SubsTabs.propTypes = {
  subs: PropTypes.array,
  activeKeyTabs: PropTypes.string,
};

export default SubsTabs;
