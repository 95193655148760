import PropTypes from 'prop-types';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const ColumnsItem = (props) => {
  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: props.column?.index ? props.column.index : '',
    data: {
      type: 'column',
      column: props.column,
    },
  });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  const inlineStylesDefault = {
    margin: !props.useTableStyle ? '10px' : '0px',
    opacity: props.withOpacity ? '0.5' : '1',
    height: '40px',
    width: '250px',
    minWidth: '250px',
    fontWeight: props.useTableStyle ? '600' : '400',
    color: '#282828',
    borderRadius: !props.useTableStyle ? '10px' : '0',
    border: isDragging && !props.useTableStyle ? '1px solid #0b7a7f' : '',
    cursor: isDragging ? 'grabbing' : 'grab',
    backgroundColor: isDragging && !props.useTableStyle ? '' : '#ffffff',
    borderLeft: props.useTableStyle
      ? '1px solid #808080'
      : isDragging
      ? '1px solid #0b7a7f'
      : '',
    borderBottom: props.useTableStyle
      ? '1px solid #808080'
      : isDragging
      ? '1px solid #0b7a7f'
      : '',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: '19px',
    padding: '10px',
    boxShadow: isDragging
      ? 'rgb(68 68 68 / 5%) 0px 5px 0px 5px, rgb(34 33 81 / 15%) 0px 2px 3px 2px'
      : 'rgb(63 63 68 / 5%) 0px 5px 5px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px',
    transform: isDragging ? 'scale(1.05)' : 'scale(1)',
    ...style,
  };

  return (
    <div
      style={inlineStylesDefault}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
    >
      <>
        {isDragging ? (
          <></>
        ) : (
          props.column?.title || props.column?.name || 'Не задано'
        )}
      </>
    </div>
  );
};

ColumnsItem.props = {
  column: PropTypes.any,
  withOpacity: PropTypes.bool,
  isDragging: PropTypes.bool,
  key: PropTypes.number,
  overlay: PropTypes.bool,
  useTableStyle: PropTypes.bool,
};

export default ColumnsItem;
