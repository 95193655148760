import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Button,
  message,
  Popconfirm,
  Space,
  Table,
  Tag,
  Menu,
  Dropdown,
  Form,
  ConfigProvider,
  Select,
  Tooltip,
} from 'antd';
import locale from 'antd/lib/locale/ru_RU';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { restApiHost } from '../../utils/appVariables';
import EllipsisOutlined from '@ant-design/icons/lib/icons/EllipsisOutlined';
import 'moment/locale/ru';
import PaperClipOutlined from '@ant-design/icons/lib/icons/PaperClipOutlined';
const { Option } = Select;
import { userInformations } from '../../contex';
import { call } from '../../apiUtils/call';
import { setPaginationSettings } from '../../localStorageUtils/paginationSettings';
import { getFiltersTable } from '../../utils/getFilters';
import FilterDatePicker from '../leeds/filters/FilterDatePicker';
import SearchDataFilter from '../leeds/filters/SearchData';
import convertToSelectOptions from '../leeds/filters/filtersUtils/convertToSelectOptions';
import AddAttachmentModal from '../customer/attachments/AddAttachmentModal';
import CreateContractModal from '../customer/CreateContractModal';
import * as _ from 'lodash';
import PdfViewerModal from './templeteContract/PdfViewerModal';
import RangeNumberFilters from '../leeds/filters/RangeNumberFilters';
import FilterSelect from '../leeds/filters/FilterSelect';
import { ErrorBoundary } from '../ErrorBoundary';
import { getTempletesConstract } from './getTempletesConstract';
import { getGeneratedContractPdfAndDocxByTemplateId } from './getGeneratedContractPdfAndDocxByTemplateId';
import { exportFileFormUrl } from '../../utils/exportFileFormUrl';
import moment from 'moment/moment';

import ContractModalMinimalInfoTheAbilityToAddAttachments from './modal/ContractModalMinimalInfoTheAbilityToAddAttachments';
import { sendContractOnApproval } from './sendContractOnApproval';
import {
  prepareColumnsForResizing,
  sortingColumnsTable,
} from '../settingTable/sortingColumnsTable';
import SettingTableColumnsDrawer from '../settingTable/SettingTableColumnsDrawer';
import { callGetProfileInfo } from '../account/callGetProfileInfo';
import { prepareAttachmentTypeForSelect } from './attachments/prepareAttachmentTypeForSelect';
import { getCustomerById } from '../customer/customerApiFunctions/getCustomerById';
import UpdateCustomerModal from '../UpdateCustomer/UpdateCustomerModal';
import useAsyncEffect from 'use-async-effect';
import dayjs from 'dayjs';
import ResizableTableColumnHeader from '../table/ResizableTableColumnHeader';
import filterActiveColumns from '../table/utils';
import changeWidthStyleMainTable from '../table/changeWidthStyleMainTable';
import { editTableColumn } from '../../logic/profileInfo/mainTable/MainTableApiFunctions';
import useStateRef from 'react-usestateref';
import { getProfileInfo } from '../../logic/account/AccountsApiFunctions';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import ScrollingLoadedList from '../mobile/scrollingLoadedList/ScrollingLoadedList';
import ContractListItem from '../mobile/contracts/list/ContacrListItem';
import { userInfoHaveViewAllBranch } from '../../logic/account/utils';
import UniversalFiltersModal from '../mobile/filtersModal/UniversalFiltersModal';

export const getBase64FromUrl = async (url) => {
  const data = await fetch(`https://cors-anywhere.herokuapp.com/${url}`);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

const ContractsListTable = ({
  data,
  handleUpdate,
  loading,
  total,
  tableFilters,
  pageProps,
  visibleFilters,
  locationUrl,
  visibleTableSetting,
  onCloseDrawerTableSetting,
  setLoading,
  setVisibleFilters,
  scrollingLoadedListRef,
  handleScrollToTopMobileList,
}) => {
  const tableName = 'contracts';
  const [currContract, setCurrentContract] = useState(null);
  const [visibleEditModal, setVisibleEditModal] = useState(false);
  const [page, setPage] = useState(1);
  const [editForm] = Form.useForm();
  const [editFormStatus, setEditFormStatus] = useState(null);
  const [docCommentChoices, setDocCommentChoices] = useState([]);
  const { userInfo } = useContext(userInformations);
  const [params, setParams] = useState([]);
  const [columnFiltersFromTableComponent, setColumnFiltersFromTableComponent] =
    useState([]);
  const [choise, setChoise] = useState([]);
  const [disableCategoryFilters, setDisableCategoryFilters] = useState(false);
  const [disableTypeContractsFilters, setDisableTypeContractsFilters] =
    useState(false);
  const [uploadedDoc, setUploadedDoc] = useState(null);
  const [visibleAttachmentsModal, setVisibleAttachmentsModal] = useState(false);
  const [currentCustomerId, setCurrentCustomerId] = useState(null);
  const [currentFileForCreateAttachment, setCurrentFileForCreateAttachment] =
    useState([]);
  const [visibleCreateContractModal, setVisibleCreateContractModal] =
    useState(false);
  const [disableDocBtn, setDisableDocBtn] = useState(true);
  const [createDocForm] = Form.useForm();
  const [contractPdfUrl, setContractPdfUrl] = useState(null);
  const [contractDocxUrl, setContractDocxUrl] = useState(null);
  const [signerManager, setSignerManager] = useState(null);
  const [
    disableDateBeginAndNumberAndStatus,
    setDisableDateBeginAndNumberAndStatus,
  ] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [signerCustomers, setSignerCustomers] = useState([]);
  const [signerManagers, setSignerManagers] = useState([]);
  const [allClients, setAllClients] = useState([]);
  const [
    createContractModalDraggerFileList,
    setCreateContractModalDraggerFileList,
  ] = useState([]);
  const [currentCustomerData, setCurrentCustomerData] = useState(null);
  const [visibleEditContactCustomer, setVisibleEditContactCustomer] =
    useState(false);
  const [currentStepByUpdateCustomer, setCurrentStepByUpdateCustomer] =
    useState(null);
  const [
    usingUpdateCustomerModalWhenGeneratingContract,
    setUsingUpdateCustomerModalWhenGeneratingContract,
  ] = useState(false);
  //dowload contarct
  const [visibleContractShowPdf, setVisibleContractShowPdf] = useState(false);
  const [loadingCreateContractModal, setLoadingCreateContractModal] =
    useState(false);
  const [fileList, setFileList] = useState([]);
  const [
    visibleSendEmailModalInCreateContractModal,
    setVisibleSendEmailModalInCreateContractModal,
  ] = useState(false);
  const [baseColumns, setBaseColumns, baseColumnsRef] = useStateRef([]);
  const mainTableRef = useRef(null);
  const isMobile = useCheckMobileScreen();

  useEffect(() => {
    const col = sortingColumnsTable(
      userInfo,
      tableName,
      columns,
      visibleFilters,
    );
    const preparedColumnsForResizing = prepareColumnsForResizing(
      col,
      handleResize,
    );
    setBaseColumns(preparedColumnsForResizing);
  }, [visibleFilters]);

  useEffect(() => {
    if (tableFilters) {
      const newFilters = {
        ...tableFilters?.filter,
        filter: {
          ...tableFilters?.filter,
          search: tableFilters?.filter?.search,
        },
      };
      setParams(newFilters);
    } else {
      setParams(null);
      setColumnFiltersFromTableComponent([]);
    }
  }, [tableFilters]);

  useEffect(() => {
    let updateFilters = [];
    if (locationUrl.state?.filters?.length > 0) {
      const disableCategoryFilters = !!locationUrl.state.filters.find(
        (item) => item.name === 'customer__category',
      );
      const disableTypeContractsFilters = !!locationUrl.state.filters.find(
        (item) => item.name === 'type',
      );
      for (const filter of locationUrl.state.filters) {
        updateFilters[filter.name] =
          filter.value?.length > 0 ? filter.value : [filter.value];
      }
      setDisableTypeContractsFilters(disableTypeContractsFilters);
      setDisableCategoryFilters(disableCategoryFilters);
      handleTableChange({ currentPage: 1 }, updateFilters, null);
    } else {
      handleTableChange({ currentPage: 1 }, [], null);
      setDisableTypeContractsFilters(false);
      setDisableCategoryFilters(false);
    }
    setPage(1);
  }, [locationUrl]);

  useEffect(() => {
    if (pageProps) {
      setPage(pageProps);
    }
  }, [pageProps]);

  useEffect(() => {
    setDisableDocBtn(true);
  }, [createContractModalDraggerFileList]);

  const typeCompanyCategoryOptions = choise?.category
    ? Object.entries(choise.category).map((item) => {
        return {
          title: item[1],
          id: item[0],
        };
      })
    : [];

  const typeContractsOptions = choise?.contract_type
    ? Object.entries(choise?.contract_type).map((item) => {
        return {
          title: item[1],
          id: item[0],
        };
      })
    : [];

  const statusOptions = choise?.contract_status
    ? Object.entries(choise?.contract_status).map((item) => {
        return {
          title: item[1],
          id: item[0],
        };
      })
    : [];

  const typeBaseDocOptions = choise?.base_doc
    ? Object.entries(choise?.base_doc).map((item) => {
        return {
          title: item[1],
          id: item[0],
        };
      })
    : [];

  useEffect(async () => {
    checkUserIsAdmin(userInfo.current);
  }, []);

  useAsyncEffect(async () => {
    await getChoices();
  }, []);

  const checkUserIsAdmin = (user) => {
    if (
      user?.groups?.some((o) => o.id === 1) ||
      user?.groups?.some((o) => o.id === 2) ||
      user?.groups?.some((o) => o.id === 4)
    ) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  };
  const getChoices = async () => {
    try {
      const url = `${restApiHost}/customers/type_choices/`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );
      if (!json?.data) return;
      setDocCommentChoices(
        json?.data?.contract_doc_comment
          ? Object.entries(json.data.contract_doc_comment)
          : [],
      );
      setChoise(json?.data);
    } catch (e) {
      console.log('get choices err', e);
    }
  };

  const editContract = async (id, body, file, fileExist = false) => {
    try {
      const url = `${restApiHost}/customers/contracts/${id}/`;
      const json = await call(
        url,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      if (file) {
        await uploadFile(id, file, fileExist);
      }
      message.success('Договор успешно отредактирован!');
      isMobile && handleScrollToTopMobileList();
      handleUpdate(isMobile ? 1 : page, params);
    } catch (e) {
      console.log('edit contract err', e);
      message.error('Ошибка запроса на редактирование договора');
    }
  };

  const uploadFile = async (id, file) => {
    try {
      const formData = new FormData();
      formData.append('attachment', file, file.name);
      const url = `${restApiHost}/customers/contracts/${id}/`;
      const json = await call(
        url,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
          body: formData,
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      message.success('Договор успешно загружен!');
    } catch (e) {
      console.log('file upload err', e);
      message.error('Ошибка загрузки договора');
    }
  };

  const deleteContract = async (id) => {
    try {
      const url = `${restApiHost}/customers/contracts/${id}/`;
      const response = await call(
        url,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!response) return;
      message.success('Действие успешно выполнено!');
      isMobile && handleScrollToTopMobileList();
      handleUpdate(isMobile ? 1 : page, params);
    } catch (e) {
      console.log('delete contract err', e);
      message.error('Ошибка запроса на удаление договора');
    }
  };

  const getStatusFromServer = (status) => {
    switch (status) {
      case 'new':
        return <Tag color={'cyan'}>Новый</Tag>;
      case 'rec':
        return <Tag color={'purple'}>Отправлен на согласование</Tag>;
      case 'ret':
        return <Tag color={'purple'}>Повторное согласование</Tag>;
      case 'con':
        return <Tag color={'orange'}>Получена копия</Tag>;
      case 'sig':
        return <Tag color={'green'}>Подписан</Tag>;
      case 'arc':
        return <Tag color={'#A9A9A9'}>Архив</Tag>;
      default:
        return <Tag color={'magenta'}>Неизвестный статус</Tag>;
    }
  };

  const menu = (contract) => {
    return (
      <Menu style={{ width: 220 }}>
        <Menu.Item
          key={'edit'}
          icon={<EditOutlined />}
          onClick={async () => {
            if (checkWhatTheUserCanViewContract(isAdmin, contract?.status)) {
              const modalName = selectModalWindow(contract?.status);
              await openSendContractModal(contract, modalName);
            } else {
              message.warning(
                'Просмотр договора в этом разделе не доступен менеджерам',
              );
            }
          }}
        >
          Редактирование договора
        </Menu.Item>
        {/*<Menu.Item key={'download'} onClick={async () => {*/}
        {/*    openDownloadFileContractModal(contract)*/}
        {/*}} icon={<DownloadOutlined/>}>*/}
        {/*    Скачать договор*/}
        {/*</Menu.Item>*/}
        {isAdmin ? (
          <Popconfirm
            title="Вы действительно хотите удалить запись?"
            onConfirm={() => deleteContract(contract.id)}
            onCancel={() => console.log('Delete item')}
            okText="Да"
            cancelText="Нет"
            placement="rightBottom"
          >
            <Menu.Item key={'delete'} icon={<DeleteOutlined />}>
              Удалить
            </Menu.Item>
          </Popconfirm>
        ) : (
          <></>
        )}
      </Menu>
    );
  };
  const updateUserInfo = async (userInfoForCallApi) => {
    setLoading(true);
    const profileInfo = await callGetProfileInfo(userInfoForCallApi);
    const haveViewBranch =
      profileInfo?.data?.permissions.some(
        (o) => o.codename === 'switch_branch',
      ) &&
      profileInfo?.data?.permissions.some((o) => o.codename === 'view_branch');
    if (haveViewBranch) {
      profileInfo['haveViewBranch'] = true;
    }
    userInfo.current = profileInfo;
    const col = sortingColumnsTable(
      userInfo,
      tableName,
      columns,
      visibleFilters,
    );
    const preparedColumnsForResizing = prepareColumnsForResizing(
      col,
      handleResize,
    );
    setBaseColumns(preparedColumnsForResizing);
    setLoading(false);
  };

  const filteringColumnsChildrenPillarByVisible = useMemo(() => {
    const copyColumns = [...baseColumns];
    return filterActiveColumns(copyColumns);
  }, [baseColumns]);

  const mainTableWidth = useMemo(() => {
    const copyColumns = [...baseColumns];
    let filteredColumns = filterActiveColumns(copyColumns);
    return filteredColumns.reduce((n, { width }) => n + width, 0);
  }, [baseColumns]);

  const getMainTableWidth = useCallback(() => {
    return mainTableWidth;
  }, [mainTableWidth]);

  useEffect(() => {
    if (mainTableRef.current.children[0]) {
      changeWidthStyleMainTable(mainTableRef, mainTableWidth);
    }
  }, [mainTableWidth]);

  const handleResize = useCallback(
    (index) =>
      async (e, { size }) => {
        const copyColumns = [...baseColumnsRef.current];

        setBaseColumns((columns) => {
          const nextColumns = [...columns];
          const children = nextColumns[index]?.children
            ? // eslint-disable-next-line no-unsafe-optional-chaining
              [...nextColumns[index]?.children]
            : null;
          if (children && children[0]?.width) {
            children[0].width = size;
          }
          nextColumns[index] = {
            ...nextColumns[index],
            width: size,
            children: children ? [...children] : [],
          };
          return nextColumns;
        });

        const databaseColumnsId = copyColumns[index]?.idInDatabase;
        if (!databaseColumnsId) return;

        await editTableColumn(databaseColumnsId, { width: size }, userInfo);
        const res = await getProfileInfo(userInfo);
        if (res) {
          userInfo.current = res;
          const haveViewBranch = userInfoHaveViewAllBranch(res);
          if (haveViewBranch) {
            userInfo.current['haveViewBranch'] = true;
          }
        }
      },
    [baseColumnsRef.current],
  );

  async function downloadScanCopyContract(last_scan_copy_contr) {
    if (!last_scan_copy_contr) return;
    try {
      await exportFileFormUrl(`/media/${last_scan_copy_contr}`);
    } catch (err) {
      message.error('Ошибка скачивания скан-копии договора');
      console.log(err, 'downloadScanCopyContract');
    }
  }

  const columns = [
    {
      title: '',
      key: 'action',
      name: 'Действия',
      uniqueIndex: 'action',
      index: 1,
      width: 50,
      align: 'center',
      ellipsis: true,
      render: (lead) => (
        <Space size="middle">
          <Dropdown overlay={menu(lead)} trigger={['hover']}>
            <EllipsisOutlined style={{ cursor: 'pointer', fontSize: '24px' }} />
          </Dropdown>
        </Space>
      ),
    },
    {
      title: 'Номер договора',
      key: 'code_contract__search',
      dataIndex: 'code_contract',
      width: 150,
      uniqueIndex: 'code_contract',
      index: 2,
      ellipsis: true,
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Номер договора'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={
                columnFiltersFromTableComponent?.code_contract__search
              }
              columnKey={'code_contract__search'}
            />
          ),
          visible: visibleFilters,
          key: 'code_contract__search',
          dataIndex: 'code_contract',
          width: 150,
          ellipsis: true,
        },
      ],
    },
    {
      title: 'Наша компания',
      key: 'signer_manager__customer__legal_name__icontains',
      dataIndex: 'signer_manager',
      width: 150,
      uniqueIndex: 'signer_manager_customer',
      index: 3,
      ellipsis: true,
      render: (signer_manager) =>
        signer_manager?.customer?.legal_name ? (
          <Tooltip
            placement={'topLeft'}
            title={signer_manager?.customer?.legal_name}
          >
            {signer_manager?.customer?.legal_name}
          </Tooltip>
        ) : null,
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Наша компания'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={
                columnFiltersFromTableComponent?.signer_manager__customer__legal_name__icontains
              }
              columnKey={'signer_manager__customer__legal_name__icontains'}
            />
          ),
          visible: visibleFilters,
          key: 'signer_manager__customer__legal_name__icontains',
          width: 150,
          dataIndex: 'signer_manager',
          ellipsis: true,
          render: (signer_manager) =>
            signer_manager?.customer?.legal_name ? (
              <Tooltip
                placement={'topLeft'}
                title={signer_manager?.customer?.legal_name}
              >
                {signer_manager?.customer?.legal_name}
              </Tooltip>
            ) : null,
        },
      ],
    },
    {
      title: 'Клиент',
      key: 'customer__legal_name__icontains',
      width: 150,
      dataIndex: 'customer',
      uniqueIndex: 'customer',
      index: 4,
      ellipsis: true,
      render: (customer) => {
        return (
          <Tooltip
            placement={'topLeft'}
            title={
              customer?.legal_name || customer?.short_name || customer?.email
            }
          >
            {customer?.legal_name || customer?.short_name || customer?.email}
          </Tooltip>
        );
      },
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Клиент'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={
                columnFiltersFromTableComponent?.customer__legal_name__icontains
              }
              columnKey={'customer__legal_name__icontains'}
            />
          ),
          key: 'customer__legal_name__icontains',
          width: 150,
          visible: visibleFilters,
          dataIndex: 'customer',
          ellipsis: true,
          render: (customer) => {
            return (
              <Tooltip
                placement={'topLeft'}
                title={
                  customer?.legal_name ||
                  customer?.short_name ||
                  customer?.email
                }
              >
                {customer?.legal_name ||
                  customer?.short_name ||
                  customer?.email}
              </Tooltip>
            );
          },
        },
      ],
    },
    {
      title: 'Тип компании',
      dataIndex: 'customer',
      key: 'customer__category',
      width: 250,
      ellipsis: true,
      uniqueIndex: 'category',
      index: 5,
      render: (customer) => {
        if (customer?.category?.length > 0) {
          return (
            <Tooltip
              title={customer?.category
                ?.map((item) => item?.category_str)
                .join(', ')}
            >
              {customer?.category?.map((item, index) => {
                return <Tag key={index}>{item?.category_str}</Tag>;
              })}
            </Tooltip>
          );
        }
      },
      children: [
        {
          title: (
            <FilterSelect
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={columnFiltersFromTableComponent?.customer__category}
              columnKey={'customer__category'}
              disable={disableCategoryFilters}
              dataList={convertToSelectOptions(
                typeCompanyCategoryOptions,
                'item?.title',
              )}
            />
          ),
          dataIndex: 'customer',
          key: 'customer__category',
          width: 250,
          ellipsis: true,
          visible: visibleFilters,
          render: (customer) => {
            if (customer?.category?.length > 0) {
              return (
                <Tooltip
                  title={customer?.category
                    ?.map((item) => item?.category_str)
                    .join(', ')}
                >
                  {customer?.category?.map((item, index) => {
                    return <Tag key={index}>{item?.category_str}</Tag>;
                  })}
                </Tooltip>
              );
            }
          },
        },
      ],
    },
    {
      title: 'Тип договора',
      key: 'type',
      dataIndex: 'type_str',
      width: 250,
      ellipsis: true,
      uniqueIndex: 'type',
      index: 6,
      render: (type) =>
        type ? (
          <Tooltip title={type} placement={'topLeft'}>
            {type}
          </Tooltip>
        ) : (
          'Без типа'
        ),
      children: [
        {
          title: (
            <FilterSelect
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={columnFiltersFromTableComponent?.type}
              columnKey={'type'}
              disable={disableTypeContractsFilters}
              dataList={convertToSelectOptions(
                typeContractsOptions,
                'item?.title',
              )}
            />
          ),
          key: 'type',
          dataIndex: 'type_str',
          visible: visibleFilters,
          width: 250,
          ellipsis: true,
          render: (type) =>
            type ? (
              <Tooltip title={type} placement={'topLeft'}>
                {type}
              </Tooltip>
            ) : (
              'Без типа'
            ),
        },
      ],
    },
    {
      title: 'Статус',
      key: 'status',
      dataIndex: 'status',
      width: 200,
      ellipsis: true,
      uniqueIndex: 'status',
      index: 7,
      render: (status) => getStatusFromServer(status),
      children: [
        {
          title: (
            <FilterSelect
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={columnFiltersFromTableComponent?.status}
              columnKey={'status'}
              dataList={convertToSelectOptions(statusOptions, 'item?.title')}
            />
          ),
          visible: visibleFilters,
          key: 'status',
          dataIndex: 'status',
          width: 200,
          ellipsis: true,
          render: (status) => getStatusFromServer(status),
        },
      ],
    },
    {
      title: 'Дней в статусе',
      key: 'status_time__range',
      dataIndex: 'status_time',
      width: 150,
      ellipsis: true,
      uniqueIndex: 'status_time',
      index: 8,
      children: [
        {
          title: (
            <RangeNumberFilters
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={columnFiltersFromTableComponent?.status_time__range}
              columnKey={'status_time__range'}
            />
          ),
          visible: visibleFilters,
          key: 'status_time__range',
          dataIndex: 'status_time',
          width: 150,
          ellipsis: true,
        },
      ],
    },
    {
      title: 'Основание',
      key: 'signer_customer__base_doc',
      dataIndex: 'signer_customer',
      width: 150,
      uniqueIndex: 'base_company',
      index: 9,
      ellipsis: true,
      render: (signer_customer) => {
        return signer_customer?.base_doc_str ? (
          <Tooltip title={signer_customer?.base_doc_str}>
            {signer_customer.base_doc_str}
          </Tooltip>
        ) : (
          <></>
        );
      },
      children: [
        {
          title: (
            <FilterSelect
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={
                columnFiltersFromTableComponent?.signer_customer__base_doc
              }
              columnKey={'signer_customer__base_doc'}
              dataList={convertToSelectOptions(
                typeBaseDocOptions,
                'item?.title',
              )}
            />
          ),
          visible: visibleFilters,
          key: 'signer_customer__base_doc',
          dataIndex: 'signer_customer',
          width: 150,
          ellipsis: true,
          render: (signer_customer) => {
            return signer_customer?.base_doc_str ? (
              <Tooltip title={signer_customer?.base_doc_str}>
                {signer_customer.base_doc_str}
              </Tooltip>
            ) : (
              <></>
            );
          },
        },
      ],
    },
    {
      title: 'Дата начала',
      key: 'date_begin__periodDate',
      dataIndex: 'date_begin',
      width: 150,
      uniqueIndex: 'date_begin',
      index: 10,
      ellipsis: true,
      children: [
        {
          title: (
            <FilterDatePicker
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={
                columnFiltersFromTableComponent?.date_begin__periodDate
              }
              columnKey={'date_begin__periodDate'}
            />
          ),
          visible: visibleFilters,
          key: 'date_begin__periodDate',
          dataIndex: 'date_begin',
          width: 150,
          ellipsis: true,
        },
      ],
    },
    {
      title: 'Дата окончания',
      key: 'date_end__periodDate',
      dataIndex: 'date_end',
      width: 150,
      uniqueIndex: 'date_end',
      index: 11,
      ellipsis: true,
      children: [
        {
          title: (
            <FilterDatePicker
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={
                columnFiltersFromTableComponent?.date_end__periodDate
              }
              columnKey={'date_end__periodDate'}
            />
          ),
          visible: visibleFilters,
          key: 'date_end__periodDate',
          dataIndex: 'date_end',
          width: 150,
          ellipsis: true,
        },
      ],
    },
    {
      title: 'Подписавший (клиент)',
      key: 'signer_customer__full_name__icontains',
      dataIndex: 'signer_customer',
      width: 200,
      ellipsis: true,
      uniqueIndex: 'signer_customer',
      index: 12,
      render: (signer_customer) =>
        signer_customer?.full_name ? (
          <Tooltip title={signer_customer.full_name}>
            {signer_customer.full_name}
          </Tooltip>
        ) : (
          <Tooltip title={signer_customer?.email || 'Не указано'}>
            {signer_customer?.email || 'Не указано'}
          </Tooltip>
        ),
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Подписавший (клиент)'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={
                columnFiltersFromTableComponent?.signer_customer__full_name__icontains
              }
              columnKey={'signer_customer__full_name__icontains'}
            />
          ),
          visible: visibleFilters,
          key: 'signer_customer__full_name__icontains',
          dataIndex: 'signer_customer',
          width: 250,
          ellipsis: true,
          render: (signer_customer) =>
            signer_customer?.full_name ? (
              <Tooltip title={signer_customer.full_name}>
                {signer_customer.full_name}
              </Tooltip>
            ) : (
              <Tooltip title={signer_customer?.email || 'Не указано'}>
                {signer_customer?.email || 'Не указано'}
              </Tooltip>
            ),
        },
      ],
    },
    {
      title: 'Подписавший (мы)',
      key: 'signer_manager__full_name__icontains',
      dataIndex: 'signer_manager',
      width: 200,
      ellipsis: true,
      uniqueIndex: 'signer_manager',
      index: 13,
      render: (signer_manager) =>
        signer_manager?.full_name ? (
          <Tooltip title={signer_manager.full_name}>
            {signer_manager.full_name}
          </Tooltip>
        ) : (
          <Tooltip title={signer_manager?.email || 'Не указано'}>
            {signer_manager?.email || 'Не указано'}
          </Tooltip>
        ),
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Подписавший (мы)'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={
                columnFiltersFromTableComponent?.signer_manager__full_name__icontains
              }
              columnKey={'signer_manager__full_name__icontains'}
            />
          ),
          visible: visibleFilters,
          key: 'signer_manager__full_name__icontains',
          dataIndex: 'signer_manager',
          width: 250,
          ellipsis: true,
          render: (signer_manager) =>
            signer_manager?.full_name ? (
              <Tooltip title={signer_manager.full_name}>
                {signer_manager.full_name}
              </Tooltip>
            ) : (
              <Tooltip title={signer_manager?.email || 'Не указано'}>
                {signer_manager?.email || 'Не указано'}
              </Tooltip>
            ),
        },
      ],
    },
    {
      title: 'Комментарий',
      key: 'comment__icontains',
      dataIndex: 'comment',
      width: 150,
      uniqueIndex: 'comment',
      index: 14,
      ellipsis: true,
      render: (comment) => {
        return comment ? (
          <Tooltip placement={'left'} title={comment}>
            {comment}
          </Tooltip>
        ) : (
          <></>
        );
      },
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Комментарий'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={columnFiltersFromTableComponent?.comment__icontains}
              columnKey={'comment__icontains'}
            />
          ),
          visible: visibleFilters,
          key: 'comment__icontains',
          dataIndex: 'comment',
          width: 150,
          ellipsis: true,
          render: (comment) => {
            return comment ? (
              <Tooltip placement={'left'} title={comment}>
                {comment}
              </Tooltip>
            ) : (
              <></>
            );
          },
        },
      ],
    },
    {
      title: 'Копия договора',
      key: 'lastScanCopy',
      name: 'Последняя копия договора',
      uniqueIndex: 'last_scan_copy_contr',
      dataIndex: 'last_scan_copy_contr',
      index: 15,
      width: 200,
      ellipsis: true,
      align: 'center',
      render: (last_scan_copy_contr) => {
        return last_scan_copy_contr ? (
          <Tooltip title={'Скачать скан-копию договора'}>
            <Button
              onClick={() => downloadScanCopyContract(last_scan_copy_contr)}
              icon={<PaperClipOutlined />}
            />
          </Tooltip>
        ) : (
          <></>
        );
      },
    },
  ];

  const handleEditContract = () => {
    editForm.validateFields().then((values) => {
      let data = {
        ...values,
        date_begin: values?.date_begin
          ? dayjs(values?.date_begin).format('YYYY-MM-DD')
          : null,
      };
      if (editFormStatus) {
        data['status'] = editFormStatus;
      }
      delete data.signer_customer;
      delete data.attachment;
      delete data.customer;
      delete data.our_customer;
      if (currContract && currContract.id) {
        editContract(currContract.id, data, uploadedDoc);
        setVisibleEditModal(false);
        setFileList([]);
      } else {
        message.warning('Что-то пошло не так...');
      }
    });
  };

  const handleCloseContractModal = () => {
    setVisibleEditModal(false);
    setFileList([]);
    setCurrentContract(null);
    setUploadedDoc(null);
    editForm.resetFields();
    setEditFormStatus(null);
    isMobile && handleScrollToTopMobileList();
    handleUpdate(isMobile ? 1 : page, params);
  };

  const handleTableChange = (pagination, filtersTable, sorter) => {
    const { page, filters } = getFiltersTable(pagination, filtersTable, sorter);
    setPage(page);
    setColumnFiltersFromTableComponent(filtersTable);
    let newFilters = { ...filters };
    if (tableFilters?.filter?.search) {
      newFilters = {
        ...filters,
        filter: { ...filters.filter, search: tableFilters.filter.search },
      };
    }
    setParams(newFilters);
    handleUpdate(page, newFilters);
  };

  /*const openCreateContractModal = (record) => {
        let body = {
            ...record,
            base_customer: record?.signer_customer?.doc_comment_str,
            date_begin: record?.date_begin ? dateStrRevers(record.date_begin) : null,
            date_end: record?.date_end ? dateStrRevers(record.date_end) : null,
            signer_customer: record?.signer_customer?.full_name,
            customer: record?.signer_manager.customer?.short_name ? record?.signer_manager.customer?.short_name  : null
    }

        delete body.attachment
        editForm.setFieldsValue(body);
        setCurrentContract(record);
        setVisibleEditModal(true);
        setAttach(record?.attachment || null)
        setCurrentCustomerId(record?.signer_customer?.customer?.id)
    }*/

  const openSendContractModal = async (contractData, modalName) => {
    setCurrentContract(contractData);
    if (modalName === 'modalEditContract') {
      let body = {
        ...contractData,
        signer_customer: contractData?.signer_customer?.full_name,
        customer: contractData?.signer_manager?.customer?.short_name
          ? contractData?.signer_manager.customer?.short_name
          : null,
        our_customer: contractData?.customer?.short_name
          ? contractData.customer?.short_name
          : null,
      };
      setEditFormStatus(
        contractData?.status ? contractData.status.toString() : null,
      );
      delete body.attachment;
      editForm.setFieldsValue(body);
      setCurrentContract(contractData);
      setVisibleEditModal(true);
      setCurrentCustomerId(contractData?.signer_customer?.customer?.id);
    } else if (modalName === 'modalCreateContract') {
      try {
        setLoadingCreateContractModal(true);
        setVisibleCreateContractModal(true);
        let body = {
          code_contract: contractData?.code_contract
            ? contractData.code_contract
            : null,
          status: contractData?.status ? contractData.status : null,
          postponement_condition: contractData?.postponement_condition
            ? contractData.postponement_condition
            : null,
          date_begin: contractData?.date_begin
            ? dayjs(contractData.date_begin).format('YYYY-MM-DD')
            : null,
          date_end: contractData?.date_end
            ? dayjs(contractData.date_end).format('YYYY-MM-DD')
            : null,
          signer_customer: contractData?.signer_customer?.id
            ? contractData.signer_customer.id
            : null,
          signer_manager: contractData?.signer_manager?.id
            ? contractData.signer_manager.id
            : null,
          contact_send: contractData?.contact_send
            ? contractData.contact_send
            : null,
          type: contractData?.type ? contractData.type : null,
        };
        const customerId = contractData?.signer_customer?.customer?.id
          ? contractData.signer_customer.customer.id
          : null;
        await getSignerCustomers(customerId);
        delete body.attachment;
        createDocForm.setFieldsValue(body);
        setSignerManager(contractData?.signer_manager);
        setCurrentCustomerId(customerId);
      } catch (e) {
        console.log(e);
      } finally {
        setLoadingCreateContractModal(false);
      }
    }
    const createIsAdmin = contractData?.user?.is_priveleged
      ? contractData?.user?.is_priveleged
      : false;
    if (createIsAdmin && !isAdmin) {
      setDisableDateBeginAndNumberAndStatus(true);
    }
  };

  const createAttachment = async (body) => {
    try {
      const url = `${restApiHost}/customers/contract_attachments/`;
      const formData = new FormData();
      formData.append('contract', body?.contract);
      formData.append('customer', body?.customer);
      formData.append('type_attachment', body?.type_attachment);
      if (body?.file.length > 0) {
        body?.file.map((file) => {
          if (file?.originFileObj) {
            formData.append(`file`, file.originFileObj, file.name);
          }
        });
      }
      const json = await call(
        url,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
          body: formData,
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      message.success('Вложение успешно добавлено');
      if (body?.file.length > 0) {
        body?.file.map((file) => {
          if (file) {
            addFileToSuccessfullyAddedAttachmentFileList(file);
          }
        });
      }
      closeModalAddAttachment();
    } catch (e) {
      console.log(e);
    }
  };

  const selectModalWindow = (contractStatus) => {
    if (
      contractStatus === 'new' ||
      contractStatus === 'rec' ||
      contractStatus === 'ret'
    ) {
      return 'modalCreateContract';
    } else {
      return 'modalEditContract';
    }
  };
  const checkWhatTheUserCanViewContract = (isAdmin, contractStatus) => {
    if (isAdmin) {
      return true;
    }
    return contractStatus === 'new' || contractStatus === 'rec';
  };

  const propsDragger = {
    beforeUpload: () => false,
    async onChange({ file, fileList }) {
      if (file?.status === 'removed') {
        await message.warning(
          'Удаление вложения договора, можно произвести в карточке контрагента в разделе "вложения"',
          2,
        );
        return;
      }
      fileSelection(fileList);
    },
    multiple: true,
    fileList: fileList,
  };

  const fileSelection = (fileList) => {
    if (fileList?.length > 0) {
      setCurrentFileForCreateAttachment(fileList);
      setVisibleAttachmentsModal(true);
    }
  };

  const addFileToSuccessfullyAddedAttachmentFileList = (file) => {
    if (file) {
      const fileObject = { originFileObj: file, name: file?.name };
      const copyFileList = fileList;
      copyFileList.push(fileObject);
      setFileList(copyFileList);
    }
  };

  const prepareSendContract = async (body) => {
    try {
      setLoadingCreateContractModal(true);
      sendContractOnApproval(body, userInfo).then((res) => {
        if (res.status === 200 || res.status === 201) {
          clearUpVariateCreateContractModal();
        }
      });
      setDisableDocBtn(true);
    } catch (e) {
      console.log(e);
      console.error('Ошибка отправки договора на согласования');
    } finally {
      setLoadingCreateContractModal(false);
    }
  };

  const getSignerCustomers = async (id) => {
    try {
      const url = `${restApiHost}/customers/filter_our_customer/?customer=${id}`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!json?.data) return;

      const clients = _.groupBy(
        json?.data?.clients,
        (contact) => contact.customer_legal_name,
      );
      const our = _.groupBy(
        json.data?.our,
        (contact) => contact.customer_legal_name,
      );
      const all = _.groupBy(
        json.data?.all_clients,
        (contact) => contact.customer_legal_name,
      );

      setSignerCustomers(clients);
      setSignerManagers(our);
      setAllClients(all);
    } catch (e) {
      console.log('get staff err', e);
    }
  };

  const closeModalAddAttachment = () => {
    setVisibleAttachmentsModal(false);
  };

  const validationBeforePreview = async () => {
    await createDocForm
      .validateFields()
      .then(async (values) => {
        if (createContractModalDraggerFileList[0]?.originFileObj) {
          await contractGeneration(
            values,
            signerManager,
            createContractModalDraggerFileList[0]?.originFileObj,
          );
        } else {
          await contractGeneration(values, signerManager);
        }
      })
      .catch((err) => {
        if (err?.errorFields?.length > 0) {
          message.warning('Не все поля заполнены');
          return;
        }
        message.error('Ошибка формирования договора');
        console.log('Ошибка формирования договора', err);
      });
  };

  const contractGeneration = async (
    contractData,
    signerManager,
    selectedFile,
  ) => {
    const singerMangerCompanyId = signerManager?.customer?.id;
    let contractTemplates = [];

    const context = JSON.stringify({
      number: contractData?.code_contract ? contractData.code_contract : null,
      date_beg: contractData?.date_begin
        ? moment(contractData.date_begin).format('YYYY-MM-DD')
        : null,
      date_end: contractData?.date_end
        ? moment(contractData.date_end).format('YYYY-MM-DD')
        : null,
    });

    if (!singerMangerCompanyId) {
      message.error('Подписант с "Нашей строны" не подвязан к компании');
    }
    //setSelectedContract(contract);
    const hide = message.loading(
      selectedFile
        ? 'Формирование договора исходя из выбранного файла'
        : 'Формирование договора',
      5,
    );
    if (!selectedFile) {
      contractTemplates = await getTempletesConstract(
        singerMangerCompanyId,
        contractData?.type,
        userInfo,
      );
      if (!contractTemplates?.length > 0) {
        setDisableDocBtn(true);
        message.error('Шаблон договора не найден');
        hide();
        return;
      }
    }
    const pdfAndDocxContractFiles =
      await getGeneratedContractPdfAndDocxByTemplateId(
        contractData?.signer_customer,
        contractData?.signer_manager,
        contractTemplates[0]?.id,
        selectedFile,
        context,
        userInfo,
      );
    if (pdfAndDocxContractFiles?.pdf && pdfAndDocxContractFiles?.docx) {
      setContractPdfUrl(pdfAndDocxContractFiles?.pdf);
      setContractDocxUrl(pdfAndDocxContractFiles?.docx);
      setDisableDocBtn(false);
    }

    hide();
    setVisibleContractShowPdf(true);
  };

  const getClassNameForRow = (record, index) => {
    if (
      (record.status === 'rec' && record.status_time > 7) ||
      (record.status === 'ret' && record.status_time > 3)
    ) {
      return 'black-text table-row-red';
    }
    return index % 2 === 0
      ? 'black-text table-row-color'
      : 'black-text table-row-white';
  };
  const downloadDocxContract = async (contractDocxUrl) => {
    let isValid = false;
    let contractCode = null;
    await createDocForm
      .validateFields()
      .then(async (values) => {
        isValid = true;
        contractCode = values?.code_contract ? values.code_contract : null;
      })
      .catch((errors) => {
        console.log(errors);
        message.error('Не все поля заполнены');
        if (errors?.errorFields?.length > 0) {
          message.warning('Не все поля заполнены');
        }
      });
    if (isValid && contractDocxUrl) {
      try {
        setDisableDocBtn(true);
        await message.info('Началось формирование docx файла', 1);
        await exportFileFormUrl(
          contractDocxUrl,
          `Договор ${contractCode}.docx`,
        );
        message.success(`Файл Договор ${contractCode}.docx успешно скачан`);
        setDisableDocBtn(false);
      } catch (e) {
        console.log(e);
        message.error('Ошибка формирования docx файла');
      }
    }
  };

  const clearUpVariateCreateContractModal = () => {
    if (visibleSendEmailModalInCreateContractModal) {
      setVisibleSendEmailModalInCreateContractModal(false);
    }
    setVisibleCreateContractModal(false);
    setDisableDocBtn(true);
    setContractPdfUrl(null);
    setContractDocxUrl(null);
    setCreateContractModalDraggerFileList([]);
    createDocForm.resetFields();
    setCurrentCustomerData(null);
    isMobile && handleScrollToTopMobileList();
    handleUpdate(isMobile ? 1 : page, params);
  };

  const openModalAddContactCustomer = async () => {
    setVisibleEditContactCustomer(true);
    const customerData = await getCustomerById(currentCustomerId, userInfo);
    //customerIsStock(customerData) ? setCurrentStepByUpdateCustomer(1) : setCurrentStepByUpdateCustomer(2)
    setCurrentStepByUpdateCustomer(1);
    setCurrentCustomerData(customerData);
    setUsingUpdateCustomerModalWhenGeneratingContract(true);
  };

  const getCustomer = async (customerId) => {
    const customerData = await getCustomerById(customerId, userInfo);
    setCurrentCustomerData(customerData ? customerData : null);
  };

  return (
    <>
      <ConfigProvider locale={locale}>
        <div ref={mainTableRef}>
          {!isMobile ? (
            <Table
              rowClassName={(record, index) =>
                getClassNameForRow(record, index)
              }
              onRow={(record) => {
                return {
                  onDoubleClick: async () => {
                    //openCreateContractModal(record);
                    if (
                      checkWhatTheUserCanViewContract(isAdmin, record?.status)
                    ) {
                      const modalName = selectModalWindow(record?.status);
                      await openSendContractModal(record, modalName);
                    } else {
                      message.warning(
                        'Просмотр договорова с этим статусов не доступен менеджерам',
                      );
                    }
                  },
                };
              }}
              components={{
                header: {
                  cell: ResizableTableColumnHeader,
                },
              }}
              scroll={{ x: getMainTableWidth() }}
              sticky={true}
              bordered={true}
              onChange={(val) => {
                handleTableChange(
                  { current: val?.current },
                  columnFiltersFromTableComponent,
                );
              }}
              dataSource={data}
              columns={filteringColumnsChildrenPillarByVisible}
              loading={loading}
              size="small"
              pagination={setPaginationSettings(total, page)}
            />
          ) : (
            <>
              <ErrorBoundary>
                <ScrollingLoadedList
                  dataList={data}
                  totalData={total}
                  scrollingLoadedListRef={scrollingLoadedListRef}
                  loadMoreItems={async () => {
                    await handleUpdate(page + 1, params, false, true);
                  }}
                  renderListItem={(item) => (
                    <ContractListItem
                      contract={item}
                      menuActions={(contact) => menu(contact)}
                    />
                  )}
                />
              </ErrorBoundary>
            </>
          )}
        </div>
      </ConfigProvider>

      <ErrorBoundary>
        <UniversalFiltersModal
          visibleModal={visibleFilters && isMobile}
          handleClose={() => {
            setVisibleFilters(false);
          }}
          resetFilters={() => {
            setVisibleFilters(false);
            handleUpdate(1, null, false, false);
          }}
          columnsList={columns}
        />
      </ErrorBoundary>

      {/*download modal*/}

      {/*<Modal
                title={`Скачать договор №${currContract?.code_contract}`}
                visible={visibleContractDownload}
                onCancel={() => {
                    setVisibleContractDownload(false);
                    setContractImage(null);
                    downloadForm.resetFields();
                }}
                footer={[]}
            >
                <Form form={downloadForm} layout={'vertical'}>
                    <Row gutter={16}>
                        <Col lg={24}>
                            <Form.Item label={'Оригинал'}>
                                <Checkbox
                                    checked={originalDoc}
                                    onChange={(e) => setOriginalDoc(e.target.checked)}>
                                    Оригинал
                                </Checkbox>
                            </Form.Item>
                        </Col>
                        {
                            !originalDoc && (
                                <Col lg={24} xs={24}>
                                    <Form.Item
                                        rules={[{required: true, message: 'Обязательное поле!'}]}
                                        label={'Тип файла'}
                                        initialValue={downloadTypeFile}
                                    >
                                        <Select
                                            defaultValue={downloadTypeFile}
                                            onChange={(val) => setDownloadTypeFile(val)}
                                        >
                                            <Option value={'pdf'}>.pdf</Option>
                                            <Option value={'doc'}>.doc</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            )
                        }
                        {
                            originalDoc && currContract ? (
                                <Col lg={24} xs={24}>
                                    <Form.Item label={'Файл'}>
                                        {currContract.attachment ?
                                            <a target={'_blank'}
                                               href={`${currContract.attachment}`}>Договор</a> : 'Нет приложенного файла'}
                                    </Form.Item>
                                </Col>
                            ) : null
                        }
                    </Row>
                    <Row justify={'end'}>
                        {!originalDoc && currContract && (
                            <>
                                <Button style={{marginRight: 10}}
                                        onClick={() => setVisibleContractShowPdf(true)}>
                                    Предпросмотр
                                </Button>

                            </>
                        )}
                        {!originalDoc && currContract && (
                            <Button loading={loadingCreateContractModal} style={{marginRight: 10}} type={'primary'} onClick={async () => {
                                if (downloadTypeFile === 'pdf') {
                                  try {
                                      await export2PDF(contractTempleteWithReplacePlaceholder,currContract)
                                  } catch (e) {
                                      console.log(e)
                                      message.error('Ошибка формирования PDF файла')
                                  }
                                }

                               if (downloadTypeFile === 'doc') {
                                   try {
                                       await Export2Word(contractTempleteWithReplacePlaceholder, `Договор ${currContract?.code_contract}.docx`)
                                   } catch (e) {
                                       console.log(e)
                                       message.error('Ошибка формирвоания docx файл')
                                   }
                               }
                            }}>
                                Скачать .{downloadTypeFile} файл
                            </Button>
                        )}
                    </Row>
                </Form>
            </Modal>*/}

      {/*show pdf contract view*/}

      {/* <Modal
                width={1000}
                title={`Предпросмотр договора №${currContract?.code_contract}`}
                visible={visibleContractShowPdf}
                onCancel={() => {
                    setVisibleContractShowPdf(false);
                }}
                footer={[]}
            >
                <Row gutter={16}>
                    <Col lg={24} xs={24}>
                        {
                            currContract && currentCustomerId && (
                                <div
                                    dangerouslySetInnerHTML={{__html: contractTemplateHtml(currContract, true)}}/>
                            )
                        }
                    </Col>
                </Row>
            </Modal>*/}

      {/*edit contract modal*/}
      <ErrorBoundary>
        <ContractModalMinimalInfoTheAbilityToAddAttachments
          visible={visibleEditModal}
          onCancel={() => handleCloseContractModal()}
          saveContract={handleEditContract}
          form={editForm}
          uploadedDoc={uploadedDoc}
          admin={isAdmin}
          contractStatus={editFormStatus}
          onChange={(val) => setEditFormStatus(val)}
          statusOptions={statusOptions}
          callbackfn={(item) => {
            return (
              <Option key={item.id} value={item.id}>
                {item.title}
              </Option>
            );
          }}
          propsDragger={propsDragger}
          docCommentChoices={docCommentChoices}
          callbackfn1={(item) => {
            return (
              <Option key={item[0]} value={item[0]}>
                {item[1]}
              </Option>
            );
          }}
        />
      </ErrorBoundary>

      <ErrorBoundary>
        <AddAttachmentModal
          visible={visibleAttachmentsModal}
          creationAttachment={true}
          loading={false}
          typesAttachment={prepareAttachmentTypeForSelect(
            choise?.attachment_type,
          )}
          customerId={currentCustomerId}
          fileList={currentFileForCreateAttachment}
          currentContract={currContract}
          createAttachment={(body) => createAttachment(body)}
          disableSelectContract={true}
          handelCloseModal={closeModalAddAttachment}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <CreateContractModal
          visible={visibleCreateContractModal}
          onCancel={() => {
            clearUpVariateCreateContractModal();
          }}
          previewContract={async () => {
            await validationBeforePreview();
          }}
          handleUpdateAfterCloseModalSend={false}
          draggerFileList={createContractModalDraggerFileList}
          changeDraggerFileList={(fileList) =>
            setCreateContractModalDraggerFileList(fileList)
          }
          filesUrl={{
            docxUrl: contractDocxUrl
              ? `${restApiHost}${contractDocxUrl}`
              : null,
            pdfUrl: contractPdfUrl ? `${restApiHost}${contractPdfUrl}` : null,
          }}
          disabled={disableDocBtn}
          disableDateBeginAndNumberAndStatus={
            disableDateBeginAndNumberAndStatus
          }
          isAdmin={isAdmin}
          useLocks={true}
          downloadDoc={async () => {
            if (contractDocxUrl) {
              await downloadDocxContract(contractDocxUrl);
            }
          }}
          loading={loadingCreateContractModal}
          sendForApproval={async (valueFormSendEmailModal) => {
            try {
              const body = {
                to: valueFormSendEmailModal?.email
                  ? valueFormSendEmailModal.email
                  : null,
                cc: valueFormSendEmailModal?.cc
                  ? valueFormSendEmailModal?.cc
                  : null,
                bcc: valueFormSendEmailModal?.bcc
                  ? valueFormSendEmailModal?.bcc
                  : null,
                contract: currContract.id,
                subject: valueFormSendEmailModal?.subject
                  ? valueFormSendEmailModal.subject
                  : null,
                files: valueFormSendEmailModal?.selectedFiles
                  ? valueFormSendEmailModal?.selectedFiles
                  : null,
                html: valueFormSendEmailModal?.text_content
                  ? valueFormSendEmailModal?.text_content
                  : null,
              };
              await prepareSendContract(body);
            } catch (e) {
              console.log(e);
              message.error('Ошибка отправки договора на согласование');
            }
          }}
          saveContract={() => {
            createDocForm.validateFields().then(async (values) => {
              let data = {
                ...values,
                date_begin: values?.date_begin
                  ? dayjs(values?.date_begin).format('YYYY-MM-DD')
                  : null,
                postponement_condition: values.postponement_condition
                  ? values.postponement_condition
                  : 1,
              };
              try {
                await editContract(currContract.id, data);
                setVisibleCreateContractModal(false);
                setDisableDocBtn(true);
                createDocForm.resetFields();
                setCurrentCustomerData(null);
              } catch (e) {
                console.log(e);
                message.error('Ошибка создания договора');
              }
            });
          }}
          form={createDocForm}
          contract={currContract}
          contractType={currContract?.type ? currContract.type : null}
          formOnValuesChange={() => {
            setTimeout(() => {
              setDisableDocBtn(true);
            }, 0);
          }}
          uploadedDoc={uploadedDoc}
          typeChoices={choise}
          callbackfn={(item) => {
            return (
              <Option key={item[0]} value={item[0]}>
                {item[1]}
              </Option>
            );
          }}
          visibleSendEmailModal={visibleSendEmailModalInCreateContractModal}
          setVisibleSendEmailModal={(val) =>
            setVisibleSendEmailModalInCreateContractModal(val)
          }
          onChangeSignerManager={(value, data) =>
            setSignerManager(JSON.parse(data['data-value']))
          }
          signerManagers={signerManagers}
          signerCustomers={signerCustomers}
          allClients={allClients}
          onChangeAttachment={(e) => {
            setUploadedDoc(e.target.files[0]);
            createDocForm.setFieldsValue({ status: 'con' });
          }}
          attach={null}
          onChangeSignerCustomer={async (value) => {
            if (value === 'addContact') {
              createDocForm.setFieldsValue({
                signer_customer: null,
              });
              await openModalAddContactCustomer();
            } /*else {
                            setSignerCustomer(JSON.parse(data['data-value']))
                        }*/
          }}
          onChangeIsOriginalDocContract={(e) => {
            if (e.target.checked && uploadedDoc) {
              createDocForm.setFieldsValue({ status: 'sig' });
            } else if (!e.target.checked && uploadedDoc) {
              createDocForm.setFieldsValue({ status: 'con' });
            } else {
              createDocForm.setFieldsValue({ status: 'new' });
            }
          }}
        />
      </ErrorBoundary>
      {contractPdfUrl && (
        <ErrorBoundary>
          <PdfViewerModal
            visible={visibleContractShowPdf}
            setVisible={(visible) => setVisibleContractShowPdf(visible)}
            pdfFileUrl={`${restApiHost}${contractPdfUrl}`}
          />
        </ErrorBoundary>
      )}

      <ErrorBoundary>
        <SettingTableColumnsDrawer
          visible={visibleTableSetting}
          loading={false}
          columns={baseColumns}
          onCloseDrawer={async () => {
            onCloseDrawerTableSetting();
            await updateUserInfo(userInfo);
          }}
        />
      </ErrorBoundary>

      <ErrorBoundary>
        <UpdateCustomerModal
          onlyUsedToAddSigner={usingUpdateCustomerModalWhenGeneratingContract}
          visible={visibleEditContactCustomer}
          getDataCustomer={async (customerId) => await getCustomer(customerId)}
          handleOk={async () => {
            if (usingUpdateCustomerModalWhenGeneratingContract) {
              await getSignerCustomers(currentCustomerId);
              setUsingUpdateCustomerModalWhenGeneratingContract(false);
            } else {
              isMobile && handleScrollToTopMobileList();
              handleUpdate(isMobile ? 1 : page, params);
            }
            setCurrentStepByUpdateCustomer(0);
            setVisibleEditContactCustomer(false);
            setCurrentCustomerData(null);
          }}
          handleCancel={async () => {
            if (usingUpdateCustomerModalWhenGeneratingContract) {
              await getSignerCustomers(currentCustomerId);
              setUsingUpdateCustomerModalWhenGeneratingContract(false);
            } else {
              isMobile && handleScrollToTopMobileList();
              handleUpdate(isMobile ? 1 : page, params);
            }
            setCurrentStepByUpdateCustomer(0);
            setVisibleEditContactCustomer(false);
            setCurrentCustomerData(null);
          }}
          currentStep={currentStepByUpdateCustomer}
          loading={false}
          data={currentCustomerData}
        />
      </ErrorBoundary>
    </>
  );
};

export default ContractsListTable;
