import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
  Modal,
  Button,
  Form,
  Input,
  Row,
  Col,
  message,
  Select,
  Alert,
  Steps,
  Popover,
} from 'antd';
import { restApiHost } from '../../utils/appVariables';
import AddTransportation from '../UpdateCustomer/transportation/AddTransportation';
import ManagersTable from '../customer/ManagersTable';
import { dateStrRevers } from '../../utils/dateStrRevers';
import moment from 'moment';
import { userInformations } from '../../contex';
import { call } from '../../apiUtils/call';
import MultipleSelectFromForm from '../UiComponents/selects/MultipleSelectFromForm';
import { ErrorBoundary } from '../ErrorBoundary';
import CustomerGroupCompany from '../UpdateCustomer/customerStepsComponents/groupCompany/CustomerGroupCompany';
import ContactPersonTable from '../UpdateCustomer/customerStepsComponents/contactPerson/ContactPersonTable';
import {
  getDisplayedSteps,
  objToFormDataContact,
} from '../customer/cutomerModalUtils/customerModalUtilsFunctions';
import getCallResult from '../../logic/refbook/callResult/CallResultApiFunction';
import CustomerBanks from '../customer/CustomerBanks';
import { createCustomerContactPersons } from '../../logic/customer/CustomerApiFunctions';
import { getListStrategicArchReason } from '../../logic/refbook/strategicArchReason/StrategicArchReasonApiFunctions';
import useAsyncEffect from 'use-async-effect';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';

const { Option } = Select;
const { Step } = Steps;

const customDot = (dot, { status, index }) => (
  <Popover
    content={
      <span>
        Шаг {index + 1}, статус: {getStatusDot(status)}
      </span>
    }
  >
    <span style={{ fontSize: '13px !important' }}>{dot}</span>
  </Popover>
);

const getStatusDot = (status) => {
  switch (status) {
    case 'process':
      return 'в процессе';
    case 'wait':
      return 'в ожидании';
    case 'finish':
      return 'завершен';
    default:
      return status;
  }
};

const CreateCustomerModal = ({
  visible,
  customers,
  handleOk,
  lead,
  handleCancel,
  defaultCategoriesClientArr,
}) => {
  const [mainInfoForm] = Form.useForm();
  const [joinComanyForm] = Form.useForm();
  const [bankFormEdit] = Form.useForm();
  const [bankForeignEdit] = Form.useForm();
  const [typesClient, setTypesClient] = useState([]);
  const [categoriesClient, setCategoriesClient] = useState([]);
  const [docBases, setDocBases] = useState([]);
  const [createdCustomerData, setCreatedCustomerData] = useState(null);
  const [contactPersons, setContactPersons] = useState([]);
  const [current, setCurrent] = React.useState(0);
  const [currEditBank] = useState(null);
  const [currEditBankForiegn] = useState(null);

  //change type category client
  const [clientHasCategoryOur, setClientHasCategoryOur] = useState(false);
  const [clientHasCategorySkl, setClientHasCategorySkl] = useState(false);
  const [selectedCategoriesClient, setSelectedCategoriesClient] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  //const [defaultCategories, setDefaultCategories] = useState([]);
  //const [createdCategories, setCreatedCategories] = useState([]);
  const [isArchive, setIsArchive] = useState(false);
  const [callResultOptions, setCallResultOptions] = useState([]);
  const [stratArchReasonsOptions, setStratArchReasonsOptions] = useState([]);

  // Связка с дочерними компаниями
  const [joinGroupOptions, setJoinGroupOptions] = useState([]);
  const [currSubsidiaryList, setCurrSubsidiaryList] = useState([]);

  //staff
  const [loadingStaffList, setLoadingStaffList] = useState(false);
  const [, setStaffList] = useState([]);

  //errors create
  const [errors] = useState(null);

  //source client
  const [choices, setChoices] = useState(null);
  const [sourceOtherSelected, setSourceOtherSelected] = useState(false);

  //list managers
  const [listManagers, setListManagers] = useState([]);

  //contact person comment
  const [contactComment, setContactComment] = useState([]);
  const { userInfo } = useContext(userInformations);

  const isMobile = useCheckMobileScreen();

  useAsyncEffect(async () => {
    if (visible) {
      await getStaffList();
      await getChoices();
      await getCustomerTypes();
      await callGetCallResult();
      await callGetStratArchReasons();
    }
  }, [visible]);

  //if lead exist
  useEffect(() => {
    if (lead) {
      const contactPerson = {
        full_name: lead.contact_person,
        email: lead.email,
        phone_number: lead.phone_number,
      };

      setContactPersons([...contactPersons, contactPerson]);

      if (lead.source_other) {
        setSourceOtherSelected(true);
        mainInfoForm.setFieldsValue({
          source: 'Другое',
          source_other: lead.source_other,
        });
      } else {
        setSourceOtherSelected(false);
        mainInfoForm.setFieldsValue({
          source: lead.source_str,
        });
      }

      if (lead?.call_result?.id === 2) {
        setIsArchive(true);
      }

      mainInfoForm.setFieldsValue({
        legal_name: lead.company_name,
        comment: lead.comment,
        address: lead.address,
        email: lead.email,
        call_result: lead?.call_result?.id ? lead?.call_result.id : null,
      });
    } else {
      setContactPersons([]);
    }
  }, [lead]);

  useEffect(() => {
    if (createdCustomerData) {
      createdCustomerData.list_managers &&
        setListManagers(createdCustomerData.list_managers);
      mainInfoForm.setFieldsValue({
        ...createdCustomerData,
        date_recent_request: createdCustomerData?.date_recent_request
          ? moment(createdCustomerData.date_recent_request).format('YYYY-MM-DD')
          : null,

        date_next_request: createdCustomerData?.date_next_request
          ? moment(createdCustomerData.date_next_request).format(
              'YYYY-MM-DD HH:mm',
            )
          : null,
      });
      if (
        createdCustomerData?.category &&
        createdCustomerData?.category.length > 0
      ) {
        setCategoriesList(createdCustomerData.category);
        setSelectedCategoriesClient(
          createdCustomerData.category.map((item) => item?.category),
        );
        /* setDefaultCategories(
                    createdCustomerData.category.map((item) => item?.category)
                );*/
        mainInfoForm.setFieldsValue({
          category: createdCustomerData.category.map((item) => item?.category),
        });
      }
      if (createdCustomerData?.call_result?.id === 2) {
        setIsArchive(true);
      }

      if (createdCustomerData?.subsidiary) {
        setCurrSubsidiaryList(createdCustomerData?.subsidiary);
      }

      if (customers?.length > 0) {
        let filteredOptions = customers.filter(
          (item) => item.id !== createdCustomerData.id,
        );
        setJoinGroupOptions(filteredOptions);
      }

      createdCustomerData.phone_numbers &&
        createdCustomerData.phone_numbers.length > 0 &&
        createdCustomerData.contact_persons &&
        setContactPersons(createdCustomerData.contact_persons);
    }
  }, [createdCustomerData]);

  useEffect(() => {
    if (defaultCategoriesClientArr?.length > 0) {
      setSelectedCategoriesClient(defaultCategoriesClientArr);
      mainInfoForm.setFieldsValue({
        category: defaultCategoriesClientArr,
      });
    }
  }, [defaultCategoriesClientArr]);

  useEffect(() => {
    if (
      selectedCategoriesClient?.length > 0 &&
      selectedCategoriesClient?.includes('our')
    ) {
      setClientHasCategoryOur(true);
    } else {
      setClientHasCategoryOur(false);
    }
    if (
      selectedCategoriesClient?.length === 1 &&
      selectedCategoriesClient?.includes('skl')
    ) {
      setClientHasCategorySkl(true);
    } else {
      setClientHasCategorySkl(false);
    }
  }, [selectedCategoriesClient]);

  const resetData = () => {
    setIsArchive(false);
    setListManagers([]);
    mainInfoForm.resetFields();
    setCategoriesList([]);
    setSelectedCategoriesClient([]);
    setJoinGroupOptions([]);
    setContactPersons([]);
  };

  /* useEffect(() => {
        if (createdCategories) {
            setCreatedCustomerData({
                ...createdCustomerData,
                category: createdCategories,
            });
        }
    }, [createdCategories]);*/

  useEffect(() => {
    if (currEditBank) {
      bankFormEdit.setFieldsValue({
        ...currEditBank,
        bank_details: currEditBank.bank.id,
        ...currEditBank.bank,
      });
    }
  }, [currEditBank]);

  useEffect(() => {
    if (currEditBankForiegn) {
      bankForeignEdit.setFieldsValue({
        ...currEditBankForiegn,
      });
    }
  }, [currEditBankForiegn]);

  useEffect(() => {
    if (!visible) {
      mainInfoForm.resetFields();
      setCreatedCustomerData(null);
      setContactPersons([]);
      setCurrent(0);
    }
  }, [visible]);

  const searchCustomers = async (keyword) => {
    try {
      const url = `${restApiHost}/customers/list/?search=${keyword}`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (json) {
        let filteredOptions = json?.data.filter(
          (item) => item.id !== createdCustomerData.id,
        );

        const selectedSubsidiary =
          currSubsidiaryList.length > 0 &&
          currSubsidiaryList.map((item) => item.subsidiary.id);

        selectedSubsidiary.length > 0 &&
          selectedSubsidiary.map((value) => {
            filteredOptions = filteredOptions.filter(
              (item) => item.id !== value,
            );
          });

        setJoinGroupOptions(filteredOptions);
      }
    } catch (e) {
      console.log('search clients err', e);
      message.error('Ошибка запроса для поиска клиента');
    }
  };

  const addSubsidiary = async (body) => {
    try {
      const url = `${restApiHost}/customers/group_customer/`;
      const json = await call(
        url,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );
      if (!json) return;
      message.success('Успешно добавлено');
      createdCustomerData.id &&
        (await handleGetCustomer(createdCustomerData.id));
    } catch (e) {
      console.log('add subsidiart err', e);
    }
  };

  const deleteSubsidiary = async (id) => {
    try {
      const url = `${restApiHost}/customers/group_customer/${id}/`;
      const json = await call(
        url,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      createdCustomerData.id &&
        (await handleGetCustomer(createdCustomerData.id));

      message.success('Успешно удалено');
    } catch (e) {
      console.log('delete subsidiary err', e);
      message.error('Ошибка запроса на удаление дочерней компании');
    }
  };

  const deleteCategoryClient = async (id) => {
    try {
      const url = `${restApiHost}/customers/customer_categories/${id}/`;
      const json = await call(
        url,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      let newCategoriesList = categoriesList.filter((item) => item.id != id);
      setCategoriesList(newCategoriesList);
      message.success('Успешно удалено!');
    } catch (e) {
      console.log('delete category client err', e);
      message.error('Ошибка запроса на удаление типа клиента');
    }
  };

  const addCategoryClient = async (body, type) => {
    try {
      const url = `${restApiHost}/customers/customer_categories/`;
      const json = await call(
        url,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json?.data) return;

      !type && setCategoriesList([...categoriesList, json?.data]);
      !type && message.success('Успешно обновлено!');

      if (type) {
        return json;
      }
    } catch (e) {
      console.log('add category to client err', e);
      !type && message.error('Ошибка запроса на добавление типа компании');
    }
  };

  const groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, []);
  };

  const callGetCallResult = async () => {
    const res = await getCallResult('customer', userInfo);
    if (res) {
      setCallResultOptions(res);
    }
  };

  const callGetStratArchReasons = useCallback(async () => {
    const res = await getListStrategicArchReason(userInfo);
    if (res?.data) {
      setStratArchReasonsOptions(res.data);
    }
  }, [getListStrategicArchReason, setStratArchReasonsOptions]);

  const getStaffList = async () => {
    setLoadingStaffList(true);
    try {
      const url = `${restApiHost}/accounts/staff_users/`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );
      if (!json) return;
      const groupedUsers = groupBy(json?.data, 'staff_position');
      setStaffList(Object.entries(groupedUsers));
      setLoadingStaffList(false);
    } catch (e) {
      console.log('fetch profile info error', e);
      setLoadingStaffList(false);
    }
  };

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const steps = [
    {
      title: 'Основная информация',
      content: () => {
        return (
          <Form
            form={mainInfoForm}
            className={'reset-margin-form'}
            layout={'vertical'}
          >
            <Row gutter={16}>
              <Col xs={24} lg={24}>
                <Row gutter={16}>
                  <Col lg={6} xs={24}>
                    <Form.Item
                      label={'Тип контрагента'}
                      name={'type'}
                      rules={[
                        { required: true, message: 'Обязательное поле!' },
                      ]}
                    >
                      <Select
                        getPopupContainer={(trigger) =>
                          isMobile ? trigger.parentNode : document.body
                        }
                        placeholder={'Тип контрагента'}
                        showSearch
                      >
                        {typesClient.length > 0 ? (
                          <>
                            {typesClient.map((item) => {
                              return (
                                <Option key={item[0]} value={item[0]}>
                                  {item[1]}
                                </Option>
                              );
                            })}
                          </>
                        ) : (
                          <Option disabled>Нет доступных вариантов</Option>
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col lg={6} xs={24}>
                    <Form.Item
                      label={'Тип компании'}
                      name={'category'}
                      rules={[
                        { required: true, message: 'Обязательное поле!' },
                      ]}
                    >
                      <MultipleSelectFromForm
                        placeholder={'Тип компании'}
                        onChange={(values) => {
                          setSelectedCategoriesClient(values);
                          if (createdCustomerData) {
                            if (
                              values.length < selectedCategoriesClient.length
                            ) {
                              let difference = selectedCategoriesClient.filter(
                                (x) => !values.includes(x),
                              );

                              if (categoriesList.length > 0) {
                                let deleteItem = categoriesList.filter(
                                  (item) => item?.category == difference[0],
                                );

                                deleteItem.length == 1 &&
                                  deleteCategoryClient(deleteItem[0].id);
                              }
                            } else {
                              let difference = values.filter(
                                (x) => !selectedCategoriesClient.includes(x),
                              );
                              addCategoryClient({
                                customer: createdCustomerData.id,
                                category: difference[0],
                              });
                            }
                          }
                        }}
                        options={
                          categoriesClient.length > 0 ? (
                            <>
                              {categoriesClient.map((item) => {
                                return (
                                  <Option key={item[0]} value={item[0]}>
                                    {item[1]}
                                  </Option>
                                );
                              })}
                            </>
                          ) : (
                            <Option disabled>Нет доступных вариантов</Option>
                          )
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={6} xs={24}>
                    <Form.Item
                      label={'Юр. наименование'}
                      name={'legal_name'}
                      /* validateStatus={errors?.legal_name ? "error" : ""}
                                            help={errors?.legal_name ? errors.legal_name[0] : ""}*/
                      rules={[
                        { required: true, message: 'Обязательное поле!' },
                      ]}
                    >
                      <Input placeholder={'Юридическое наименование'} />
                    </Form.Item>
                  </Col>
                  <Col lg={6} xs={24}>
                    <Form.Item
                      label={'Краткое юр. наименование'}
                      name={'short_name'}
                      rules={[
                        { required: true, message: 'Обязательное поле!' },
                      ]}
                    >
                      <Input
                        placeholder={'Сокращенное юридическое наименование'}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              {!clientHasCategorySkl ? (
                <>
                  <Col lg={8} xs={24}>
                    <Form.Item
                      label={'Фактический адрес'}
                      name={'address'}
                      /*validateStatus={errors?.address ? "error" : ""}
                                    help={errors?.address ? errors.address[0] : ""}*/
                      rules={[
                        { required: true, message: 'Обязательное поле!' },
                      ]}
                    >
                      <Input placeholder={'Фактический адрес'} />
                    </Form.Item>
                  </Col>
                  <Col lg={8} xs={24}>
                    <Form.Item label={'Юр. адрес'} name={'legal_address'}>
                      <Input placeholder={'Юридический адрес'} />
                    </Form.Item>
                  </Col>
                  <Col lg={8} xs={24}>
                    <Form.Item label={'Почтовый адрес'} name={'post_address'}>
                      <Input placeholder={'Почтовый адрес'} />
                    </Form.Item>
                  </Col>
                </>
              ) : (
                <></>
              )}
              {!clientHasCategorySkl ? (
                <Col lg={24}>
                  <Row gutter={16}>
                    <Col lg={6} xs={24}>
                      <Row gutter={16}>
                        <Col xs={24} lg={24}>
                          <Form.Item label="Источник" name={'source'}>
                            <Select
                              getPopupContainer={(trigger) =>
                                isMobile ? trigger.parentNode : document.body
                              }
                              onChange={(value) => {
                                value === 'Другое'
                                  ? setSourceOtherSelected(true)
                                  : setSourceOtherSelected(false);
                              }}
                              placeholder={'Выберите источник'}
                            >
                              {choices && choices.source ? (
                                <>
                                  {Object.entries(choices.source).map(
                                    (item) => {
                                      return (
                                        <Option key={item[0]} value={item[1]}>
                                          {item[1]}
                                        </Option>
                                      );
                                    },
                                  )}
                                </>
                              ) : (
                                <Option disabled value={null}>
                                  Нет доступных вариантов
                                </Option>
                              )}
                            </Select>
                          </Form.Item>
                        </Col>
                        {sourceOtherSelected && (
                          <Col xs={24} lg={24}>
                            <Form.Item
                              name={'source_other'}
                              label={'Др. источник'}
                            >
                              <Input placeholder={'Введите свой источник'} />
                            </Form.Item>
                          </Col>
                        )}
                        <Col lg={12} xs={24}>
                          <Form.Item label={'Email компании'} name={'email'}>
                            <Input placeholder={'example@example.com'} />
                          </Form.Item>
                        </Col>
                        <Col lg={12} xs={24}>
                          <Form.Item
                            initialValue={false}
                            name={'has_group_company'}
                            label={'Голов. компания'}
                          >
                            <Select
                              getPopupContainer={(trigger) =>
                                isMobile ? trigger.parentNode : document.body
                              }
                              defaultValue={false}
                              placeholder={'Головная компания'}
                            >
                              <Option value={true}>Да</Option>
                              <Option value={false}>Нет</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        {selectedCategoriesClient?.length > 0 &&
                          selectedCategoriesClient?.includes('our') && (
                            <Col lg={24} xs={24}>
                              <Form.Item label={'Префикс'} name={'designation'}>
                                <Input
                                  placeholder={'Введите префикс для компании'}
                                />
                              </Form.Item>
                            </Col>
                          )}
                        <Col xs={24} lg={24}>
                          <Form.Item label="Итог звонка" name={'call_result'}>
                            <Select
                              getPopupContainer={(trigger) =>
                                isMobile ? trigger.parentNode : document.body
                              }
                              onChange={(value) => {
                                value === 2
                                  ? setIsArchive(true)
                                  : setIsArchive(false);
                              }}
                              placeholder={'Выберите итог звонка'}
                            >
                              {callResultOptions &&
                              callResultOptions?.length > 0 ? (
                                <>
                                  {callResultOptions.map((item) => {
                                    return (
                                      <Option key={item?.id} value={item?.id}>
                                        {item?.result}
                                      </Option>
                                    );
                                  })}
                                </>
                              ) : (
                                <Option disabled value={null}>
                                  Нет доступных вариантов
                                </Option>
                              )}
                            </Select>
                          </Form.Item>
                        </Col>
                        {isArchive ? (
                          <>
                            <Col xs={24} lg={24}>
                              <Form.Item
                                label="Причина страт. архива"
                                name={'strat_arch_reason'}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Обязательное поле!',
                                  },
                                ]}
                              >
                                <Select
                                  getPopupContainer={(trigger) =>
                                    isMobile
                                      ? trigger.parentNode
                                      : document.body
                                  }
                                  placeholder={'Выберите причину'}
                                >
                                  {stratArchReasonsOptions &&
                                  stratArchReasonsOptions?.length > 0 ? (
                                    <>
                                      {stratArchReasonsOptions.map((item) => {
                                        return (
                                          <Option
                                            key={item?.id}
                                            value={item?.id}
                                          >
                                            {item?.reason}
                                          </Option>
                                        );
                                      })}
                                    </>
                                  ) : (
                                    <Option disabled value={null}>
                                      Нет доступных вариантов
                                    </Option>
                                  )}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col xs={24} lg={24}>
                              <Form.Item
                                label="Комментарий (страт. архив)"
                                name={'strat_arch_reason_comment'}
                              >
                                <Input.TextArea placeholder="Введите комментарий (страт. архив)" />
                              </Form.Item>
                            </Col>
                          </>
                        ) : (
                          <></>
                        )}
                      </Row>
                    </Col>
                    <Col lg={6} xs={24}>
                      <Row gutter={16}>
                        <Col lg={24} xs={24}>
                          <Form.Item
                            label="Посл. общение"
                            name={'date_recent_request'}
                          >
                            <input
                              className={'ant-input'}
                              max="9999-12-31"
                              type={'date'}
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={24} xs={24}>
                          <Form.Item
                            label="След. общение"
                            name={'date_next_request'}
                          >
                            <input
                              className={'ant-input'}
                              type="datetime-local"
                              max="9999-12-31"
                              pattern="[0-2][0-9]:[0-5][0-9]"
                              min={moment().format('YYYY-MM-DD hh:mm')}
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={24} xs={24}>
                          <Form.Item label="Комментарий" name={'comment'}>
                            <Input.TextArea
                              style={{ height: 90 }}
                              placeholder="Введите комментарий по клиенту"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={12} xs={24}>
                      <Row gutter={16}>
                        <Col lg={12} xs={24}>
                          <Form.Item
                            label={'ИНН (автозаполнение по РФ)'}
                            name={'inn'}
                            rules={[
                              { required: true, message: 'Обязательное поле!' },
                            ]}
                          >
                            <Input
                              placeholder={'ИНН'}
                              maxLength={10}
                              onChange={(e) => {
                                if (
                                  e.target.value.length > 0 &&
                                  e.target.value.length <= 10
                                ) {
                                  searchInfoByInn(e.target.value);
                                }

                                if (e.target.value.length > 10) {
                                  message.warning(
                                    'Максимальное кол-во знаков ИНН - 10',
                                  );
                                }
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={12} xs={24}>
                          <Form.Item label={'КПП'} name={'kpp'}>
                            <Input maxLength={9} placeholder={'КПП'} />
                          </Form.Item>
                        </Col>
                        <Col lg={12} xs={24}>
                          <Form.Item label={'ОКПО'} name={'okpo'}>
                            <Input placeholder={'ОКПО'} />
                          </Form.Item>
                        </Col>

                        <Col lg={12} xs={24}>
                          <Form.Item label={'ОГРН'} name={'ogrn'}>
                            <Input placeholder={'ОГРН'} />
                          </Form.Item>
                        </Col>
                        <Col lg={24} xs={24}>
                          <Form.Item label={'ОКВЭД'} name={'okved'}>
                            <Input placeholder={'ОКВЭД'} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              ) : (
                <></>
              )}
            </Row>
          </Form>
        );
      },
      disabled: false,
      visible: true,
    },
    {
      title: 'Контактные лица',
      content: () => {
        return (
          <ErrorBoundary>
            <ContactPersonTable
              contactPersonsList={contactPersons}
              clientHasCategoryOur={clientHasCategoryOur}
              clientHasCategorySkl={clientHasCategorySkl}
              docBasesList={docBases}
              contactCommentList={contactComment}
              customerId={createdCustomerData?.id}
              handleUpdateCustomerData={async (customerId) => {
                await handleGetCustomer(customerId);
              }}
            />
          </ErrorBoundary>
        );
      },
      disabled: !createdCustomerData,
      visible: true,
    },
    {
      title: 'Менеджеры',
      content: () => {
        return (
          <>
            {createdCustomerData ? (
              <>
                <ErrorBoundary>
                  <ManagersTable
                    data={listManagers}
                    loadingManagers={loadingStaffList}
                    customerId={createdCustomerData?.id}
                    handleUpdate={(customerId) => handleGetCustomer(customerId)}
                  />
                </ErrorBoundary>
              </>
            ) : (
              <Alert
                style={{ margin: '20px 0' }}
                message="Раздел на данном этапе недоступен"
                description="Чтобы добавить информацию к контрагенту, сначала, вам необходимо нажать на кнопку создания клиента с уже заполненными полями информации"
                type="info"
                showIcon
              />
            )}
          </>
        );
      },
      disabled: !createdCustomerData,
      visible: true,
    },
    {
      title: 'Группа компаний',
      content: () => {
        return (
          <ErrorBoundary>
            <CustomerGroupCompany
              form={joinComanyForm}
              createdCustomerData={createdCustomerData}
              joinGroupOptions={joinGroupOptions}
              currSubsidiaryList={currSubsidiaryList}
              customerId={createdCustomerData?.id}
              setJoinGroupOptions={(val) => setJoinGroupOptions(val)}
              deleteSubsidiary={async (id) => {
                await deleteSubsidiary(id);
              }}
              addSubsidiary={async (valueBody) => {
                await addSubsidiary(valueBody);
              }}
              searchCustomers={async (val) => {
                await searchCustomers(val);
              }}
            />
          </ErrorBoundary>
        );
      },
      disabled: !createdCustomerData,
      visible: !clientHasCategorySkl,
    },
    {
      title: 'Транспортные пункты',
      content: () => {
        return (
          <>
            {createdCustomerData ? (
              <ErrorBoundary>
                <AddTransportation
                  mainCustomerData={createdCustomerData}
                  customerId={createdCustomerData?.id}
                  clientHasCategorySkl={clientHasCategorySkl}
                  zIndexModal={201}
                  customerWaypoints={
                    createdCustomerData?.waypoints
                      ? createdCustomerData?.waypoints
                      : []
                  }
                  customerContactPerson={
                    createdCustomerData?.contact_persons
                      ? createdCustomerData?.contact_persons
                      : []
                  }
                  handleUpdate={(customerId) => handleGetCustomer(customerId)}
                />
              </ErrorBoundary>
            ) : (
              <Alert
                style={{ margin: '20px 0' }}
                message="Раздел на данном этапе недоступен"
                description="Чтобы добавить информацию к контрагенту, сначала, вам необходимо нажать на кнопку создания клиента с уже заполненными полями информации"
                type="info"
                showIcon
              />
            )}
          </>
        );
      },
      disabled: !createdCustomerData,
      visible: true,
    },
    {
      title: 'Банки',
      content: () => {
        return (
          <>
            <ErrorBoundary>
              <CustomerBanks customerId={createdCustomerData?.id} />
            </ErrorBoundary>
          </>
        );
      },
      disabled: !createdCustomerData,
      visible: !clientHasCategorySkl,
    },
  ];

  const handleGetCustomer = async (customerId) => {
    if (customerId) {
      const customer = await getCustomer(customerId);
      customer && setCreatedCustomerData(customer);
    }
  };

  const getCustomer = async (id) => {
    try {
      const url = `${restApiHost}/customers/list/${id}/`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (json?.data) {
        return json.data;
      }
    } catch (e) {
      console.log('get customer err', e);
      message.error('Ошибка запроса на получение данных клиента');
    }
  };

  const getChoices = async () => {
    try {
      const url = `${restApiHost}/leads/type_choices`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );
      if (json?.data) {
        setChoices(json?.data);
      }
    } catch (e) {
      console.log('get choices err', e);
    }
  };

  const getCustomerTypes = async () => {
    try {
      const url = `${restApiHost}/customers/type_choices/`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );
      if (!json?.data) return;
      setTypesClient(Object.entries(json.data?.type));
      setCategoriesClient(Object.entries(json.data?.category));
      setDocBases(Object.entries(json.data?.base_doc));
      setContactComment(Object.entries(json.data?.doc_comment));
    } catch (e) {
      console.log('get customer types err', e);
      message.error('Ошибка запроса на получение типа клиента');
    }
  };

  const searchInfoByInn = async (inn) => {
    try {
      const url = `${restApiHost}/customers/get_company_details?inn=${inn}`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!json?.data) return;

      const data = Object.entries(json?.data);

      if (data.length > 0) {
        mainInfoForm.setFieldsValue({
          short_name: data[0][1].short_name,
          legal_name: data[0][1].full_name,
          kpp: data[0][1].kpp,
          ogrn: data[0][1].ogrn,
          okpo: data[0][1].okpo,
          okved: data[0][1].okved,
          address: data[0][1].address,
          legal_address: data[0][1].address,
          post_address: data[0][1].address,
        });
      }
    } catch (e) {
      console.log('get bank list err', e);
      message.error('Ошибка запроса поиска информации по ИНН!');
    }
  };

  const handleCreateCustomerContactPerson = useCallback(
    async (body) => {
      if (!body) return;
      const formDateObj = objToFormDataContact(body);
      const res = await createCustomerContactPersons(formDateObj, userInfo);
      if (res?.data) return res?.data;
    },
    [createCustomerContactPersons],
  );

  const createCustomer = async (body) => {
    const dateNextRequest = body?.date_next_request
      ? new Date(`${body?.date_next_request}`)
      : null;

    const bodyFormat = {
      ...body,
      date_next_request: dateNextRequest
        ? moment(dateNextRequest).format()
        : null,
    };

    !bodyFormat.date_next_request && delete bodyFormat.date_next_request;
    !bodyFormat.date_recent_request && delete bodyFormat.date_recent_request;

    try {
      const url = `${restApiHost}/customers/list/`;
      const json = await call(
        url,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(bodyFormat),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      setCreatedCustomerData({
        ...json?.data,
        id: json?.data?.id,
        call_result: json?.data?.call_result?.id
          ? json?.data.call_result.id
          : null,
        birthday: json?.data?.birthday
          ? dateStrRevers(json.data.birthday)
          : null,
        base_doc_date_begin: json?.data?.base_doc_date_begin
          ? dateStrRevers(json?.data?.base_doc_date_begin)
          : null,
      });

      if (body?.category && body?.category.length > 0) {
        Promise.all(
          body?.category.map(async (item) =>
            addCategoryClient(
              { customer: json?.data?.id, category: item },
              'arr',
            ).then((data) => data),
          ),
        ).then((category) => {
          //setCreatedCustomerData({...json, category});
          setCategoriesList(category);
          mainInfoForm.setFieldsValue({
            category: body?.category.map((item) => item),
          });
        });
        // body.category.map((item) => {
        //   addCategoryClient({ customer: json.id, category: item }, "arr");
        // });
      }

      // handleRedirect && handleRedirect(json)
      if (
        json?.data?.id &&
        body?.contact_persons &&
        body?.contact_persons?.length > 0
      ) {
        await Promise.all(
          body.contact_persons.map(
            async (item) =>
              await handleCreateCustomerContactPerson({
                ...item,
                customer: json.data.id,
              }),
          ),
        );
      }

      message.success('Клиент успешно создан!');

      next();
    } catch (e) {
      console.log('create customer err', e);
      message.error('Ошибка запроса для создания клиента');
    }
  };

  const editCustomer = async (id, body) => {
    const dateNextRequest = body?.date_next_request
      ? new Date(`${body?.date_next_request}`)
      : null;

    const bodyFormat = {
      ...body,
      date_next_request: dateNextRequest
        ? moment(dateNextRequest).format()
        : null,
    };

    !bodyFormat.date_next_request && delete bodyFormat.date_next_request;
    !bodyFormat.date_recent_request && delete bodyFormat.date_recent_request;

    try {
      const url = `${restApiHost}/customers/list/${id}/`;
      const json = await call(
        url,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(bodyFormat),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      message.success('Основная информация клиента успешно отредактирована!');
    } catch (e) {
      console.log('edit customer err', e);
      message.error('Ошибка запроса на редактирование клиента');
    }
  };

  const getStatus = (idx, errors) => {
    if (
      idx == 0 &&
      (Boolean(errors?.address) ||
        Boolean(errors?.ogrn) ||
        Boolean(errors?.legal_name) ||
        Boolean(errors?.email))
    ) {
      return 'error';
    } else if (idx == 1 && Boolean(errors?.contact_persons)) {
      return 'error';
    } else {
      return '';
    }
  };

  return (
    <Modal
      visible={visible}
      width={1200}
      zIndex={200}
      title="Добавить контрагента"
      onOk={() => {
        joinComanyForm.resetFields();
        setCurrSubsidiaryList([]);
        setContactPersons([]);
        handleOk();
        resetData();
      }}
      onCancel={() => {
        joinComanyForm.resetFields();
        setCurrSubsidiaryList([]);
        setContactPersons([]);
        handleCancel();
        resetData();
      }}
      footer={[]}
    >
      <>
        <Steps
          className={'customer-modal-steps'}
          progressDot={customDot}
          current={current}
          size="small"
          onChange={(currentStep) => {
            setCurrent(currentStep);
          }}
        >
          {getDisplayedSteps(steps).map((item, idx) => {
            return (
              <Step
                status={getStatus(idx, errors)}
                style={{ marginTop: '7px' }}
                key={item.title}
                title={item.title}
                disabled={item.disabled}
              />
            );
          })}
        </Steps>

        <div className="steps-content" style={{ marginTop: '10px' }}>
          {getDisplayedSteps(steps)[current]?.content()}
        </div>

        <div
          className="steps-action"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          {current > 0 && (
            <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
              Назад
            </Button>
          )}
          {current === 0 || current === getDisplayedSteps(steps).length - 1 ? (
            createdCustomerData && (
              <Button
                style={{ marginRight: '10px' }}
                onClick={() => {
                  mainInfoForm
                    .validateFields()
                    .then(async (values) => {
                      await editCustomer(createdCustomerData.id, { ...values });
                    })
                    .catch(() => {
                      message.warning(
                        'Возможно не все обязательные поля заполнены!',
                      );
                    });
                }}
              >
                Сохранить
              </Button>
            )
          ) : (
            <></>
          )}
          {current < getDisplayedSteps(steps).length - 1 && (
            <Button
              type="primary"
              onClick={() => {
                if (current === 0 && !createdCustomerData) {
                  let body;
                  const contacts =
                    contactPersons?.length > 0
                      ? contactPersons.map((contact) => {
                          return {
                            ...contact,
                            birthday: contact?.birthday
                              ? dateStrRevers(contact.birthday)
                              : null,
                            base_doc_date_begin: contact?.base_doc_date_begin
                              ? dateStrRevers(contact.base_doc_date_begin)
                              : null,
                          };
                        })
                      : [];
                  mainInfoForm.validateFields().then(async (value) => {
                    if (lead) {
                      body = {
                        ...value,
                        contact_persons: contacts,
                        lead: lead.id,
                      };
                    } else {
                      body = {
                        ...value,
                        contact_persons: contacts,
                      };
                    }
                    await createCustomer(body);
                  });
                } else {
                  next();
                }
              }}
            >
              Далее
            </Button>
          )}
        </div>
      </>
    </Modal>
  );
};

export default CreateCustomerModal;
