import { restApiHost } from '../../utils/appVariables';
import { call } from '../../apiUtils/call';
import { message } from 'antd';

export default async function getNotificationList(offset, userInfo) {
  const params = new URLSearchParams();
  if (offset !== null && offset !== undefined) {
    params.append('offset', `${offset}`);
  }

  params.append('limit', '15');

  try {
    const url = `${restApiHost}/accounts/notifications/?viewed__isnull=True&${params}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (!json) return;

    return json;
  } catch (e) {
    console.log('notification list fetch err', e);
    message.error('Ошибка запрос на получение списка уведомлений');
  }
}

export async function viewNotification(userInfo, body) {
  try {
    const url = `${restApiHost}/accounts/view_notifications/`;
    const json = await call(
      url,
      {
        method: 'POST',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      userInfo.current.haveViewBranch,
    );

    if (!json) return null;

    return json;
  } catch (e) {
    console.log('notification view notification fetch err', e);
    message.error('Ошибка запроса на прочтение уведомлений');
  }
}

export async function viewAllChatNotification(userInfo, body) {
  try {
    const url = `${restApiHost}/chats/read_all_messages_in_all_chats/`;
    const json = await call(
      url,
      {
        method: 'POST',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      userInfo.current.haveViewBranch,
    );

    if (!json) return null;

    return json;
  } catch (e) {
    console.log('view chat notification fetch err', e);
    message.error('Ошибка запроса на прочтение уведомлений чата');
  }
}
