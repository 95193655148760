import { werSocketHost } from '../../utils/appVariables';
import useWebSocket from 'react-use-websocket';
import { notification } from 'antd';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import notificationSound from './notificationSound/notificationSound.mp3';
import { userInformations } from '../../contex';
import { Howl } from 'howler';
import { useTypedSelector } from '../../store/hooks/useTypedSelector';
import { delay } from 'lodash';

const NotificationWebSocket = (props) => {
  const WS_URL = `${werSocketHost}/pipe/?token=Token ${localStorage.getItem(
    'token',
  )}`;
  const { userInfo } = useContext(userInformations);
  const activeChatId = useTypedSelector((state) => state.activeChatId.chatId);
  const [copyActiveChatIdState, setCopyActiveChatIdState] =
    useState(activeChatId);
  const navigate = useNavigate();
  const copyToggleSound = useRef(props.useSound);
  const copyUseChatNotifications = useRef(props.useChatNotifications);

  useEffect(() => {
    window.addEventListener('unload', sendWebSocketDisconnectMessage);
    return () => {
      window.removeEventListener('unload', sendWebSocketDisconnectMessage);
    };
  }, []);

  useEffect(() => {
    copyToggleSound.current = props.useSound;
  }, [props.useSound]);

  useEffect(() => {
    setCopyActiveChatIdState(activeChatId);
  }, [activeChatId]);

  useEffect(() => {
    copyUseChatNotifications.current = props.useChatNotifications;
  }, [props.useChatNotifications]);

  const { sendJsonMessage, lastMessage, lastJsonMessage, readyState } =
    useWebSocket(WS_URL, {
      share: true,
      shouldReconnect: () => true,
    });

  const openNotification = (notificationData, notificationType) => {
    let Sound;

    if (copyToggleSound.current) {
      if (Sound === undefined) {
        Sound = new Howl({
          src: [notificationSound],
        });
      }
      Sound.play();
    }

    notification.info({
      message: `${notificationData?.text}`,
      placement: 'bottomRight',
      duration: 20,
      key: notificationData?.id,
      style: { cursor: 'pointer' },
      closeIcon: (
        <CloseOutlined
        /*onClick={async () => {
                                if (notificationData?.id) {
                                    await viewNotification(userInfo, {notification: notificationData?.id})
                                }
                            }}*/
        />
      ),
      onClose: () => {
        console.log('onClose');
        //props.handleGetNotificationList(null,notificationType)
      },
      onClick: () => {
        if (notificationData?.hyperlink?.calendarevent) {
          notificationData?.id && notification.close(notificationData?.id);
          redirectToCalendar(notificationData.hyperlink.calendarevent);
          props.handleGetNotificationList(null, notificationType);
        } else if (notificationData?.event) {
          notificationData?.id && notification.close(notificationData?.id);
          redirectToCalendar(notificationData.event);
          props.handleGetNotificationList(null, notificationType);
        }
      },
    });
    delay(() => {
      props.handleGetNotificationList(notificationData, notificationType);
    }, 1000);
  };

  useEffect(() => {
    console.log(lastJsonMessage);
    if (
      lastJsonMessage?.type === 'notifications.new' ||
      lastJsonMessage?.type === 'chat_message.new'
    ) {
      preparationNotificationBeforeOpen(lastJsonMessage);
    }
    if (lastJsonMessage?.type === 'websocket.disconnect') {
      console.log(lastJsonMessage, 'websocket.disconnect');
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    console.log(lastMessage, 'lastMessage');
  }, [lastMessage]);

  useEffect(() => {
    console.log(readyState, 'readyState');
  }, [readyState]);

  const preparationNotificationBeforeOpen = useCallback(
    (notificationData) => {
      if (!notificationData) return;

      const copyNotificationData = { ...notificationData };

      if (
        copyUseChatNotifications.current &&
        copyNotificationData?.type === 'chat_message.new' &&
        copyNotificationData?.chat !== copyActiveChatIdState &&
        copyNotificationData?.data?.creator?.staff_id !== userInfo.current?.id
      ) {
        copyNotificationData.data[
          'originalText'
        ] = `${copyNotificationData.data?.text}`;

        copyNotificationData.data['text'] = copyNotificationData?.data?.creator
          ?.full_name
          ? `Новое сообщение от ${copyNotificationData?.data?.creator?.full_name}:
                                                    ${copyNotificationData.data?.text}`
          : `Новое сообщение: ${copyNotificationData.data?.text}`;

        copyNotificationData.data['chat'] = copyNotificationData?.chat;

        copyNotificationData.data['event'] = copyNotificationData?.event;

        openNotification(copyNotificationData?.data, 'chat_message.new');
      } else if (copyNotificationData?.type === 'notifications.new') {
        openNotification(copyNotificationData?.data, 'notifications.new');
      }
    },
    [copyActiveChatIdState],
  );

  const sendWebSocketDisconnectMessage = () => {
    console.log('call websocket.disconnect');
    sendJsonMessage({
      type: 'websocket.disconnect',
    });
  };

  const redirectToCalendar = (eventId) => {
    if (!eventId) return;
    const state = {
      eventId: eventId,
    };
    navigate('/calendar', { state });
  };

  return null;
};

NotificationWebSocket.propsTypes = {
  useSound: PropTypes.bool,
  useChatNotifications: PropTypes.bool,
  visibleDrawerChatNotifications: PropTypes.bool,
  handleGetNotificationList: PropTypes.func.isRequired,
};

export default NotificationWebSocket;
