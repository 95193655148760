import React, { useEffect, useState } from 'react';
import EmailCodeRequest from './EmailCodeRequest';
import SendCodeAndPassword from './SendCodeAndPassword';
import { restApiHost } from '../../utils/appVariables';
import { ErrorBoundary } from '../ErrorBoundary';
import { Box, Container } from '@material-ui/core';

const ResetPassword = ({ backToLoginForm }) => {
  const [step, setStep] = useState(1);
  const [seconds, setSeconds] = useState(60);
  const [timerActive, setTimerActive] = useState(false);
  const [loadingSendCode, setLoadingSendCode] = useState(false);
  const [loadingRestorePassword, setLoadingRestorePassword] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [errorSendEmail, setErrorSendEmail] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [errorRestorePassword, setErrorRestorePassword] = useState(false);
  const [errorTextRestorePassword, setErrorTextRestorePassword] = useState('');
  const [successSendCode, setSuccessSendCode] = useState(false);
  const [successTextSendCode, setSuccessTextSendCode] = useState(false);
  const [codeSent, setCodeSent] = useState(false);

  const sendCode = async (values) => {
    setLoadingSendCode(true);
    try {
      setUserEmail(values.email);
      if (codeSent) {
        values['resend'] = true;
      }
      const url = `${restApiHost}/accounts/request_restore_password/`;
      const res = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });
      const json = await res.json();

      if (res.status !== 200 && res.status !== 201) {
        if (res.status === 500) {
          setErrorText(`Внутреняя ошибка сервера`);
          return;
        }

        setErrorText(json?.data || json?.data?.data);
        setErrorSendEmail(true);
      } else {
        setSuccessSendCode(true);
        setSuccessTextSendCode(json?.data);
        setCodeSent(true);
        setTimerActive(true);
        setSeconds(60);
        setStep(2);
      }
    } catch (e) {
      setErrorText('Ошибка запроса к серверу');
      setErrorSendEmail(true);
      console.log(e);
    } finally {
      setLoadingSendCode(false);
    }
  };

  const restorePassword = async (values) => {
    setLoadingRestorePassword(true);
    try {
      const url = `${restApiHost}/accounts/restore_password/`;
      const res = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });
      const json = await res.json();

      if (res.status !== 201 && res.status !== 200) {
        setErrorRestorePassword(true);
        if (res.status === 500) {
          setErrorTextRestorePassword(`Внутреняя ошибка сервера`);
          return;
        }
        if (typeof json?.data === 'object') {
          let message = '';
          Object.entries(json?.data).map((err) => {
            if (err[0] && err[1]) {
              message += `${err[0]} - ${err[1]}`;
            }
          });
          setErrorTextRestorePassword(`${message}`);
          return;
        }

        if (json?.data) {
          setErrorTextRestorePassword(`${json.data || json?.data?.data}`);
        } else {
          setErrorText('Неизвестный ответ от сервера');
          setErrorTextRestorePassword('Неизвестный ответ от сервера');
        }

        return;
      }

      setCodeSent(false);
      backToLoginForm(true, json?.data);
    } catch (e) {
      console.log(e);
      setErrorText('Ошибка запроса к серверу');
      setErrorSendEmail(true);
    } finally {
      setLoadingRestorePassword(false);
    }
  };
  const backToStepOne = () => {
    setStep(1);
  };
  const backToStepTwo = () => {
    setStep(2);
  };
  const handleCloseSnack = () => {
    setErrorSendEmail(false);
    setErrorRestorePassword(false);
    setSuccessSendCode(false);
  };
  useEffect(() => {
    if (seconds > 0 && timerActive) {
      setTimeout(setSeconds, 1000, seconds - 1);
    } else {
      setTimerActive(false);
    }
  }, [seconds, timerActive]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <Container maxWidth="sm">
          {step === 1 ? (
            <ErrorBoundary>
              <EmailCodeRequest
                sendCode={sendCode}
                handleCloseSnack={handleCloseSnack}
                backToStepTwo={backToStepTwo}
                loadingSendCode={loadingSendCode}
                seconds={seconds}
                timerActive={timerActive}
                userEmail={userEmail}
                errorSendEmail={errorSendEmail}
                errorText={errorText}
              />{' '}
            </ErrorBoundary>
          ) : (
            <ErrorBoundary>
              <SendCodeAndPassword
                backToStepOne={backToStepOne}
                handleCloseSnack={handleCloseSnack}
                loadingRestorePassword={loadingRestorePassword}
                restorePassword={restorePassword}
                errorRestorePassword={errorRestorePassword}
                errorTextRestorePassword={errorTextRestorePassword}
                successSendCode={successSendCode}
                successTextSendCode={successTextSendCode}
              />
            </ErrorBoundary>
          )}
        </Container>
      </Box>
    </>
  );
};

export default ResetPassword;
