import { List, Pagination, Button, Drawer } from 'antd';
import React, { useState, useContext, useEffect } from 'react';
import { restApiHost } from '../../../utils/appVariables';
import { userInformations } from '../../../contex';
import { RotateCw } from 'react-feather';
import { call } from '../../../apiUtils/call';
import { ScheduleItem } from './ScheduleItem';

export default function Schedules({ open, schedules, setSchedule, onClose }) {
  const [loadingSchedule, setLoadingSchedule] = useState(false);
  const { userInfo } = useContext(userInformations);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    if (!schedules?.length) updateSchedule();
  }, [currentPage]);

  async function updateSchedule() {
    setLoadingSchedule(true);
    const params = new URLSearchParams();
    params.append('page', currentPage.toString());
    params.append('size', pageSize.toString());
    const json = await call(
      `${restApiHost}/administration/periodic/`,
      {
        method: 'GET',
        params,
        headers: { Authorization: `Token ${localStorage.getItem('token')}` },
      },
      userInfo.current.haveViewBranch,
      null,
    );
    if (!json) return;
    setSchedule(json?.data ? json.data : []);
    setTotalPages(json.count);
    setCurrentPage(1);
    setLoadingSchedule(false);
  }

  const renderSchedule = (schedule) => (
    <ScheduleItem schedule={schedule} updateSchedules={updateSchedule} />
  );

  return (
    <Drawer visible={open} onClose={onClose} title="Расписание" width={'100%'}>
      <div style={{ display: 'flex' }}>
        <Button icon={<RotateCw size={12} />} onClick={updateSchedule}></Button>
      </div>
      <List
        loading={loadingSchedule}
        dataSource={schedules}
        renderItem={renderSchedule}
      />
      <Pagination
        style={{ marginTop: 8 }}
        current={currentPage}
        onChange={(page) => setCurrentPage(page)}
        onShowSizeChange={(current, size) => setPageSize(size)}
        total={totalPages}
        pageSize={pageSize}
      />
    </Drawer>
  );
}
