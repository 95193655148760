import React, { useEffect, useState } from 'react';
import { Button, Space, Table, message, Checkbox } from 'antd';
import { PhoneOutgoing, PhoneIncoming } from 'react-feather';
import { restApiHost } from '../../utils/appVariables';
import AddLeadModal from './AddLeadModal';
import { useNavigate } from 'react-router-dom';

const HistoryCallTable = ({
  historyCalls,
  loading,
  setPage,
  setFilters,
  filterCalls,
  filters,
  //currUser,
  totalCalls,
  page,
}) => {
  const navigate = useNavigate();
  const [choices, setChoices] = useState(null);
  const [currentCall, setCurrentCall] = useState(null);

  const [openAddLeadModal, setOpenAddLeadModal] = useState(false);
  /*const [openLeadModal, setOpenLeadModal] = useState(false);*/

  //add lead modal
  const [cities, setCities] = useState([]);

  useEffect(() => {
    getChoices();
    searchCities();
  }, []);

  const searchCities = async (keyword) => {
    try {
      const url =
        keyword?.length > 0
          ? `${restApiHost}/refbook/cities/?search=${keyword}&active=true`
          : `${restApiHost}/refbook/cities/?active=true`;
      const res = await fetch(url, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });

      if (res.status == 403) {
        message.warning(
          'У вас недостаточно прав для получения информации по лидам',
        );
        return;
      }

      const json = await res.json();
      setCities(json?.data ? json.data : []);
    } catch (e) {
      console.log(e);
    }
  };

  const getColumnFilter = () => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
          >
            <Checkbox
              style={{ margin: '0 0 8px 0' }}
              value={'terminator'}
              checked={selectedKeys.includes('terminator')}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedKeys([...selectedKeys, e.target.value]);
                } else {
                  let newArr = selectedKeys.filter(
                    (key) => key != 'Terminator',
                  );
                  setSelectedKeys(newArr);
                }
              }}
            >
              Входящий
            </Checkbox>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
          >
            <Checkbox
              style={{ margin: '0 0 8px 0' }}
              value={'originator'}
              checked={selectedKeys.includes('originator')}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedKeys([...selectedKeys, e.target.value]);
                } else {
                  let newArr = selectedKeys.filter(
                    (key) => key != 'Originator',
                  );
                  setSelectedKeys(newArr);
                }
              }}
            >
              Исходящий
            </Checkbox>
          </div>
          <Space>
            <Button
              onClick={() => {
                clearFilters();
                setSelectedKeys([]);
                // handleUpdate(1);
              }}
              size="small"
              style={{ width: 90 }}
            >
              Сбросить
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
              }}
            >
              Ок
            </Button>
          </Space>
        </>
      </div>
    ),
  });

  const columns = [
    {
      title: 'Направление вызова',
      key: 'direction',
      dataIndex: 'call_direction',
      width: 200,
      ...getColumnFilter('направление вызова'),
      render: (direction) =>
        direction == 'Originator' ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <PhoneOutgoing
              style={{ color: '#1890ff', width: '20px', marginRight: '10px' }}
            />
            Исходящий
          </div>
        ) : direction == 'Terminator' ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <PhoneIncoming
              style={{ color: '#37bd00', width: '20px', marginRight: '10px' }}
            />
            Входящий
          </div>
        ) : (
          'Не известно'
        ),
    },
    {
      title: 'Номер телефона',
      key: 'phone_number',
      dataIndex: 'phone_number',
      width: 150,
    },

    {
      title: 'Дата звонка',
      key: 'start_time',
      dataIndex: 'start_time',
      width: 150,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Время ответа на звонок',
      key: 'answer_time',
      dataIndex: 'answer_time',
      width: 200,
    },
    {
      title: 'Окончание звонка',
      key: 'end_time',
      dataIndex: 'end_time',
      width: 200,
    },
    {
      title: 'Время ответа на вызов(сек.)',
      key: 'answer_duration',
      dataIndex: 'answer_duration',
      width: 200,
    },
    {
      title: 'Время вызова(сек.)',
      key: 'call_duration',
      dataIndex: 'call_duration',
      width: 200,
    },
    {
      title: 'Абонент',
      key: 'abonent',
      children: [
        {
          title: 'ФИО',
          key: 'fio',
          dataIndex: 'abonent',
          width: 150,
          ellipsis: {
            showTitle: false,
          },
          render: (abonent) => abonent?.fio || '-',
        },
        {
          title: 'Email',
          key: 'email',
          dataIndex: 'abonent',
          width: 150,
          ellipsis: {
            showTitle: false,
          },
          render: (abonent) => abonent?.email || '-',
        },
      ],
    },
    {
      title: 'Лид',
      key: 'lead',
      children: [
        {
          title: 'Компания',
          key: 'company',
          dataIndex: 'lead',
          width: 150,
          ellipsis: {
            showTitle: false,
          },
          render: (lead) => lead?.company_name || '-',
        },
        {
          title: 'Контактное лицо',
          key: 'company',
          dataIndex: 'lead',
          width: 200,
          ellipsis: {
            showTitle: false,
          },
          render: (lead) => lead?.contact_person || '-',
        },
        {
          title: 'Email',
          key: 'email',
          dataIndex: 'lead',
          width: 150,
          ellipsis: {
            showTitle: false,
          },
          render: (lead) => lead?.email || '-',
        },
      ],
    },
    {
      title: 'Контрагент',
      key: 'customer',
      children: [
        {
          title: 'Наименование',
          key: 'short_name',
          dataIndex: 'customer',
          width: 150,
          ellipsis: {
            showTitle: false,
          },
          render: (customer) => customer?.short_name || '-',
        },
        {
          title: 'ИНН',
          key: 'inn',
          dataIndex: 'customer',
          width: 150,
          ellipsis: {
            showTitle: false,
          },
          render: (customer) => customer?.inn || '-',
        },
        {
          title: 'Email',
          key: 'email',
          dataIndex: 'customer',
          width: 150,
          ellipsis: {
            showTitle: false,
          },
          render: (customer) => customer?.email || '-',
        },
      ],
    },
  ];

  const getChoices = async () => {
    try {
      const url = `${restApiHost}/leads/type_choices`;
      const json = await fetch(url, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });

      if (json?.data) {
        setChoices(json.data);
      }
    } catch (e) {
      console.log('get choices err', e);
    }
  };

  const getFiltersTable = (pagination, filtersTable, sorter) => {
    let newFilters = {
      filter: {},
      order: {},
    };

    if (Object.entries(filtersTable).length > 0) {
      Object.entries(filtersTable)
        .filter((item) => item[1])
        .map((item) => {
          newFilters = {
            ...newFilters,
            filter: {
              ...newFilters.filter,
              [item[0]]: item[1].join(','),
            },
          };
        });
    }

    if (sorter.order) {
      newFilters = {
        ...newFilters,
        order: {
          ...newFilters.order,
          [sorter.field]:
            sorter.order == 'ascend' ? `${sorter.field}` : `-${sorter.field}`,
        },
      };
    } else {
      newFilters = {
        ...newFilters,
        order: {},
      };
    }

    return {
      page: pagination.current,
      filters: newFilters,
    };
  };

  const handleTableChange = (pagination, filtersTable, sorter) => {
    const { page, filters } = getFiltersTable(pagination, filtersTable, sorter);
    setPage(page);
    setFilters(filters);
    filterCalls(page, filters);
  };

  return (
    <>
      <Table
        locale={{
          triggerDesc: 'По убыванию',
          triggerAsc: 'По возрастанию',
          cancelSort: 'Сбросить сортировку',
        }}
        onRow={(record) => {
          return {
            onDoubleClick: () => {
              if (!record?.customer && record?.lead) {
                navigate('/leeds', { state: { id: record?.lead?.id } });
              } else if (record?.customer && record?.lead) {
                navigate('/all_customers', {
                  state: { id: record?.customer?.id },
                });
              } else {
                setCurrentCall(record);
                setOpenAddLeadModal(true);
              }
            },
          };
        }}
        showHeader
        scroll={{ x: 2750, y: 700 }}
        onChange={handleTableChange}
        loading={loading}
        dataSource={historyCalls}
        columns={columns}
        size="small"
        bordered={true}
        rowClassName={(record, index) =>
          index % 2 === 0
            ? 'black-text table-row-color'
            : 'black-text table-row-white'
        }
        pagination={{
          totalCalls,
          position: totalCalls ? ['bottomRight'] : ['none'],
          size: 'middle',
          pageSize: 15,
          showSizeChanger: false,
        }}
      />

      <AddLeadModal
        redirect={(state) => navigate('/leeds', { state })}
        callProps={currentCall}
        handleUpdate={() => filterCalls(page, filters)}
        openModal={openAddLeadModal}
        choices={choices}
        cities={cities}
        searchCities={searchCities}
        setOpenModal={(val) => setOpenAddLeadModal(val)}
      />
    </>
  );
};

export default HistoryCallTable;
