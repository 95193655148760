import { TodoActionDemoAccess } from '../todo/demoAccess/demoAccessTodo';

const initialState = {
  demoAccessIsUsed: false,
};

export const demoAccessReducer = (
  state = initialState,
  action: TodoActionDemoAccess,
) => {
  switch (action.type) {
    case 'SET_DEMO_ACCESS_IS_USED':
      return { demoAccessIsUsed: action.payload };
    default:
      return state;
  }
};
