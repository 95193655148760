import PropTypes from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { userInformations } from '../../contex';
import {
  ConfigProvider,
  Dropdown,
  Menu,
  message,
  Popconfirm,
  Space,
  Table,
  Tooltip,
} from 'antd';
import locale from 'antd/lib/locale/ru_RU';
import { setPaginationSettings } from '../../localStorageUtils/paginationSettings';
import { getFiltersTable } from '../../utils/getFilters';
import EllipsisOutlined from '@ant-design/icons/lib/icons/EllipsisOutlined';
import SearchDataFilter from '../leeds/filters/SearchData';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import BranchesMainModal from './BranchesMainModal/BranchesMainModal';
import { restApiHost } from '../../utils/appVariables';
import { call } from '../../apiUtils/call';
import { ErrorBoundary } from '../ErrorBoundary';
import SettingTableColumnsDrawer from '../settingTable/SettingTableColumnsDrawer';
import { callGetProfileInfo } from '../account/callGetProfileInfo';
import {
  prepareColumnsForResizing,
  sortingColumnsTable,
} from '../settingTable/sortingColumnsTable';
import ResizableTableColumnHeader from '../table/ResizableTableColumnHeader';
import filterActiveColumns from '../table/utils';
import changeWidthStyleMainTable from '../table/changeWidthStyleMainTable';
import { editTableColumn } from '../../logic/profileInfo/mainTable/MainTableApiFunctions';
import useStateRef from 'react-usestateref';
import { getProfileInfo } from '../../logic/account/AccountsApiFunctions';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import ScrollingLoadedList from '../mobile/scrollingLoadedList/ScrollingLoadedList';
import BranchListItem from '../mobile/branches/list/BranchListItem';
import { userInfoHaveViewAllBranch } from '../../logic/account/utils';
import UniversalFiltersModal from '../mobile/filtersModal/UniversalFiltersModal';

const BranchesTable = (props) => {
  const tableName = 'branches';
  const { userInfo } = useContext(userInformations);
  const [params, setParams] = useState([]);
  const [page, setPage] = useState(1);
  const [columnFiltersFromTableComponent, setColumnFiltersFromTableComponent] =
    useState([]);
  const [visibleEditBranchModal, setVisibleEditBranchModal] = useState(false);
  const [editingBranchItem, setEditingBranchItem] = useState(null);
  const [baseColumns, setBaseColumns, baseColumnsRef] = useStateRef([]);
  const mainTableRef = useRef(null);
  const isMobile = useCheckMobileScreen();

  useEffect(() => {
    const columns = sortingColumnsTable(
      userInfo,
      tableName,
      columnsFromTable,
      props.visibleFilters,
    );
    const preparedColumnsForResizing = prepareColumnsForResizing(
      columns,
      handleResize,
    );
    setBaseColumns(preparedColumnsForResizing);
  }, [props.visibleFilters]);

  useEffect(() => {
    if (props.tableFilters) {
      const newFilters = {
        ...props.tableFilters?.filter,
        filter: {
          ...props.tableFilters?.filter,
          search: props.tableFilters?.filter?.search,
        },
      };
      setParams(newFilters);
    } else {
      setParams(null);
      setColumnFiltersFromTableComponent([]);
    }
  }, [props.tableFilters]);

  useEffect(() => {
    if (props.page) {
      setPage(props.page);
    }
  }, [props.page]);

  const menu = (branch) => {
    return (
      <Menu style={{ width: 210 }}>
        <Menu.Item
          key={'edit'}
          icon={<EditOutlined />}
          onClick={async () => {
            openModalEditBranch(branch);
          }}
        >
          Редактирование филиала
        </Menu.Item>
        <Popconfirm
          title="Вы действительно хотите удалить запись?"
          onConfirm={async () => await deleteBranch(branch?.id)}
          okText="Да"
          cancelText="Нет"
          placement="rightBottom"
        >
          <Menu.Item key={'delete'} icon={<DeleteOutlined />}>
            Удалить
          </Menu.Item>
        </Popconfirm>
      </Menu>
    );
  };

  let columnsFromTable = [
    {
      title: '',
      name: 'Действия',
      key: 'action',
      width: 50,
      align: 'center',
      uniqueIndex: 'action',
      index: 1,
      ellipsis: {
        showTitle: false,
      },
      render: (lead) => (
        <Space size="middle">
          <Dropdown overlay={menu(lead)} trigger={['hover']}>
            <EllipsisOutlined style={{ cursor: 'pointer', fontSize: '24px' }} />
          </Dropdown>
        </Space>
      ),
    },
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      width: 80,
      uniqueIndex: 'id',
      index: 2,
      ellipsis: {
        showTitle: false,
      },
      render: (id) =>
        id ? (
          <Tooltip title={id} placement={'topLeft'}>
            {id}
          </Tooltip>
        ) : (
          'Не указано'
        ),
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'ID'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={false}
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={columnFiltersFromTableComponent?.id}
              columnKey={'id'}
            />
          ),
          visible: props.visibleFilters,
          key: 'id',
          dataIndex: 'id',
          width: 80,
          ellipsis: {
            showTitle: false,
          },
        },
      ],
    },
    {
      title: 'Название',
      key: 'name__search',
      dataIndex: 'name',
      width: 419,
      uniqueIndex: 'name',
      index: 3,
      ellipsis: {
        showTitle: false,
      },
      render: (name) =>
        name ? (
          <Tooltip title={name} placement={'topLeft'}>
            {name}
          </Tooltip>
        ) : (
          'Не указано'
        ),
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Название'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={columnFiltersFromTableComponent?.name__search}
              columnKey={'name__search'}
            />
          ),
          visible: props.visibleFilters,
          key: 'name__search',
          dataIndex: 'name',
          width: 419,
          ellipsis: {
            showTitle: false,
          },
        },
      ],
    },
    {
      title: 'Город',
      key: 'city__search',
      dataIndex: 'city',
      width: 350,
      uniqueIndex: 'city',
      index: 4,
      ellipsis: {
        showTitle: false,
      },
      render: (city) =>
        city ? (
          <Tooltip title={city?.name || 'Не указано'} placement={'topLeft'}>
            {city?.name || 'Не указано'}
          </Tooltip>
        ) : (
          'Не указано'
        ),
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Город'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={columnFiltersFromTableComponent?.city__search}
              columnKey={'city__search'}
            />
          ),
          visible: props.visibleFilters,
          key: 'city__search',
          dataIndex: 'city',
          width: 350,
          ellipsis: {
            showTitle: false,
          },
        },
      ],
    },
    {
      title: 'Email',
      key: 'email__search',
      dataIndex: 'email',
      uniqueIndex: 'email',
      width: 380,
      index: 5,
      ellipsis: {
        showTitle: false,
      },
      render: (email) =>
        email ? (
          <Tooltip title={email} placement={'topLeft'}>
            {email}
          </Tooltip>
        ) : (
          'Не указано'
        ),
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Email'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={columnFiltersFromTableComponent?.email__search}
              columnKey={'email__search'}
            />
          ),
          visible: props.visibleFilters,
          key: 'email__search',
          dataIndex: 'email',
          width: 380,
          ellipsis: {
            showTitle: false,
          },
        },
      ],
    },
    {
      title: 'Адрес',
      key: 'address__search',
      dataIndex: 'address',
      width: 450,
      index: 6,
      uniqueIndex: 'address',
      ellipsis: {
        showTitle: false,
      },
      render: (address) =>
        address ? (
          <Tooltip title={address} placement={'topLeft'}>
            {address}
          </Tooltip>
        ) : (
          'Не указано'
        ),
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Адрес'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={columnFiltersFromTableComponent?.address__search}
              columnKey={'address__search'}
            />
          ),
          visible: props.visibleFilters,
          key: 'address__search',
          dataIndex: 'address',
          width: 450,
          ellipsis: {
            showTitle: false,
          },
        },
      ],
    },
  ];

  const filteringColumnsChildrenPillarByVisible = useMemo(() => {
    const copyColumns = [...baseColumns];
    return filterActiveColumns(copyColumns);
  }, [baseColumns]);

  const mainTableWidth = useMemo(() => {
    const copyColumns = [...baseColumns];
    let filteredColumns = filterActiveColumns(copyColumns);
    return filteredColumns.reduce((n, { width }) => n + width, 0);
  }, [baseColumns]);

  const getMainTableWidth = useCallback(() => {
    return mainTableWidth;
  }, [mainTableWidth]);

  useEffect(() => {
    if (mainTableRef.current.children[0]) {
      changeWidthStyleMainTable(mainTableRef, mainTableWidth);
    }
  }, [mainTableWidth]);

  const handleResize = useCallback(
    (index) =>
      async (e, { size }) => {
        const copyColumns = [...baseColumnsRef.current];

        setBaseColumns((columns) => {
          const nextColumns = [...columns];
          const children = nextColumns[index]?.children
            ? // eslint-disable-next-line no-unsafe-optional-chaining
              [...nextColumns[index]?.children]
            : null;
          if (children && children[0]?.width) {
            children[0].width = size;
          }
          nextColumns[index] = {
            ...nextColumns[index],
            width: size,
            children: children ? [...children] : [],
          };
          return nextColumns;
        });

        const databaseColumnsId = copyColumns[index]?.idInDatabase;
        if (!databaseColumnsId) return;

        await editTableColumn(databaseColumnsId, { width: size }, userInfo);
        const res = await getProfileInfo(userInfo);
        if (res) {
          userInfo.current = res;
          const haveViewBranch = userInfoHaveViewAllBranch(res);
          if (haveViewBranch) {
            userInfo.current['haveViewBranch'] = true;
          }
        }
      },
    [baseColumnsRef.current],
  );

  const handleTableChange = (pagination, filtersTable, sorter) => {
    const { page, filters } = getFiltersTable(pagination, filtersTable, sorter);
    setPage(page);
    setColumnFiltersFromTableComponent(filtersTable);
    let newFilters = { ...filters };
    if (props.tableFilters?.filter?.search) {
      newFilters = {
        ...filters,
        filter: { ...filters.filter, search: props.tableFilters.filter.search },
      };
    }
    setParams(newFilters);
    props.handleUpdate(page, newFilters);
  };

  const openModalEditBranch = (branch) => {
    if (branch) {
      setEditingBranchItem(branch);
      setVisibleEditBranchModal(true);
    }
  };

  const deleteBranch = async (branchId) => {
    try {
      const url = `${restApiHost}/accounts/branches/${branchId}/`;
      const response = await call(
        url,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!response) return;

      message.success('Филиал успешно удален!');
      isMobile && props.handleScrollToTopMobileList();
      props.handleUpdate(isMobile ? 1 : page, params);
    } catch (e) {
      console.log('create branch err', e);
      message.error('Ошибка запроса на удаление филиала');
    }
  };

  const updateUserInfo = async (userInfoForCallApi) => {
    props.setLoading(true);
    const profileInfo = await callGetProfileInfo(userInfoForCallApi);
    const haveViewBranch =
      profileInfo?.data?.permissions.some(
        (o) => o.codename === 'switch_branch',
      ) &&
      profileInfo?.data?.permissions.some((o) => o.codename === 'view_branch');
    if (haveViewBranch) {
      profileInfo['haveViewBranch'] = true;
    }
    userInfo.current = profileInfo;
    const columns = sortingColumnsTable(
      userInfo,
      tableName,
      columnsFromTable,
      props.visibleFilters,
    );
    const preparedColumnsForResizing = prepareColumnsForResizing(
      columns,
      handleResize,
    );
    setBaseColumns(preparedColumnsForResizing);
    props.setLoading(false);
  };

  return (
    <>
      <ConfigProvider locale={locale}>
        <div ref={mainTableRef}>
          {!isMobile ? (
            <Table
              rowClassName={(record, index) =>
                index % 2 === 0
                  ? 'black-text table-row-color'
                  : 'black-text table-row-white'
              }
              onRow={(record) => {
                return {
                  onDoubleClick: async () => {
                    openModalEditBranch(record);
                  },
                };
              }}
              components={{
                header: {
                  cell: ResizableTableColumnHeader,
                },
              }}
              scroll={{ x: getMainTableWidth() }}
              sticky={true}
              bordered={true}
              onChange={(val) => {
                handleTableChange(
                  { current: val?.current },
                  columnFiltersFromTableComponent,
                );
              }}
              dataSource={props.branchesList}
              columns={filteringColumnsChildrenPillarByVisible}
              loading={props.loading}
              size="small"
              pagination={setPaginationSettings(props.total, page)}
            />
          ) : (
            <>
              <ErrorBoundary>
                <ScrollingLoadedList
                  dataList={props.branchesList}
                  totalData={props.total}
                  loadMoreItems={async () => {
                    props.handleUpdate(page + 1, params, false, true);
                  }}
                  scrollingLoadedListRef={props.scrollingLoadedListRef}
                  renderListItem={(item) => (
                    <BranchListItem
                      branch={item}
                      menuActions={(branch) => menu(branch)}
                    />
                  )}
                />
              </ErrorBoundary>
            </>
          )}
        </div>
      </ConfigProvider>

      <ErrorBoundary>
        <UniversalFiltersModal
          visibleModal={props.visibleFilters && isMobile}
          handleClose={() => {
            props.setVisibleFilters(false);
          }}
          resetFilters={() => {
            props.setVisibleFilters(false);
            props.handleUpdate(1, null, false, false);
          }}
          columnsList={columnsFromTable}
        />
      </ErrorBoundary>

      <ErrorBoundary>
        <BranchesMainModal
          visibleModal={visibleEditBranchModal}
          closeModal={(visible) => {
            setEditingBranchItem(null);
            setVisibleEditBranchModal(visible);
            isMobile && props.handleScrollToTopMobileList();
            props.handleUpdate(isMobile ? 1 : page, params);
          }}
          editingBranchItem={editingBranchItem}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <SettingTableColumnsDrawer
          visible={props.visibleTableSetting}
          loading={false}
          columns={baseColumns}
          onCloseDrawer={async () => {
            props.onCloseDrawerTableSetting();
            await updateUserInfo(userInfo);
          }}
        />
      </ErrorBoundary>
    </>
  );
};

BranchesTable.propTypes = {
  branchesList: PropTypes.any,
  handleUpdate: PropTypes.func,
  loading: PropTypes.bool,
  total: PropTypes.number,
  tableFilters: PropTypes.any,
  page: PropTypes.number,
  visibleFilters: PropTypes.bool,
  setVisibleFilters: PropTypes.func,
  locationUrl: PropTypes.any,
  scrollingLoadedListRef: PropTypes.any,
  handleScrollToTopMobileList: PropTypes.func,
  visibleTableSetting: PropTypes.bool,
  onCloseDrawerTableSetting: PropTypes.func,
  setLoading: PropTypes.func,
};

export default BranchesTable;
