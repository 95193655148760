import { restApiHost } from '../../../utils/appVariables';
import { call } from '../../../apiUtils/call';

export default async function getTypesPack(userInfo, keyword) {
  const params = new URLSearchParams();
  params.set('active', 'true');
  if (keyword?.length > 0) {
    params.set('search', keyword);
  }

  try {
    const url = `${restApiHost}/refbook/type_cargo_packing/?${params}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (json?.data) {
      return json.data;
    }
  } catch (e) {
    console.log('get type packaging cargo err', e);
  }
}
