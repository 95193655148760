import { generateImageFormBlob } from './generateImageFormBlob';

export async function getFile(url) {
  try {
    if (!url) return;
    const decodeUrl = decodeURI(url);
    const responseStampImage = await fetch(decodeUrl);
    const filename = url.split(/[\\/]/g).pop();
    const file = generateImageFormBlob(
      await responseStampImage.blob(),
      filename,
    );
    if (!file) return;
    return file;
  } catch (e) {
    throw new Error(e);
  }
}
