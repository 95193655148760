import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Dropdown,
  Menu,
  message,
  Popconfirm,
  Space,
  Table,
  Tag,
  Tooltip,
  ConfigProvider,
} from 'antd';
import locale from 'antd/lib/locale/ru_RU';
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  FilePdfOutlined,
} from '@ant-design/icons';
import PreApplicationModal from '../pre-applications/PreApplicationModal';
import EllipsisOutlined from '@ant-design/icons/lib/icons/EllipsisOutlined';
import { restApiHost } from '../../utils/appVariables';
import { getFiltersTable } from '../../utils/getFilters';
import { dateStrRevers } from '../../utils/dateStrRevers';
import { userInformations } from '../../contex';
import { call } from '../../apiUtils/call';
import SendKpOrPresentationModal from '../sendKpOrPresentation/SendKpOrPresentationModal';
import RangeNumberFilters from '../leeds/filters/RangeNumberFilters';
import FilterDatePicker from '../leeds/filters/FilterDatePicker';
import SearchDataFilter from '../leeds/filters/SearchData';
import { setPaginationSettings } from '../../localStorageUtils/paginationSettings';
import convertToSelectOptions from '../leeds/filters/filtersUtils/convertToSelectOptions';
import FilterSelect from '../leeds/filters/FilterSelect';
import { ErrorBoundary } from '../ErrorBoundary';
import {
  prepareColumnsForResizing,
  sortingColumnsTable,
} from '../settingTable/sortingColumnsTable';
import SettingTableColumnsDrawer from '../settingTable/SettingTableColumnsDrawer';
import { callGetProfileInfo } from '../account/callGetProfileInfo';
import { useTypedSelector } from '../../store/hooks/useTypedSelector';
import useAsyncEffect from 'use-async-effect';
import useStateRef from 'react-usestateref';
import ResizableTableColumnHeader from '../table/ResizableTableColumnHeader';
import filterActiveColumns from '../table/utils';
import changeWidthStyleMainTable from '../table/changeWidthStyleMainTable';
import { editTableColumn } from '../../logic/profileInfo/mainTable/MainTableApiFunctions';
import { getProfileInfo } from '../../logic/account/AccountsApiFunctions';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import ScrollingLoadedList from '../mobile/scrollingLoadedList/ScrollingLoadedList';
import PreTaskListItem from '../mobile/preTasks/list/PreTaskListItem';
import { userInfoHaveViewAllBranch } from '../../logic/account/utils';
import UniversalFiltersModal from '../mobile/filtersModal/UniversalFiltersModal';

const PreAppicationsTable = ({
  loading,
  modalPreApp,
  rowSel,
  applications,
  updateTable,
  updtFromLead,
  total,
  setLoading,
  fromPreApplicationPage,
  setLoadingSpin,
  notDisplayActionPreTask,
  renderInOutPagePreTask,
  preTaskForKP,
  tableFilters,
  visibleFilters,
  visibleTableSetting,
  onCloseDrawerTableSetting,
  pageProps,
  setVisibleFilters,
  scrollingLoadedListRef,
  handleScrollToTopMobileList,
}) => {
  const tableName = 'preTasks';
  const [currEditApplication, setCurrEditApplication] = useState(null);
  const [visibleEditApplicationModal, setVisibleEditApplicationModal] =
    useState(false);
  const [page, setPage, pageRef] = useStateRef(1);
  const [params, setParams, paramsRef] = useStateRef(null);
  const [isCopy, setIsCopy] = useState(false);
  const [expanded] = useState(false);
  const { userInfo } = useContext(userInformations);
  const [visitSumsClient, setVisitSumsClient] = useState(false);
  const savingSum = useRef(false);
  const savingSumWereEditing = useRef(false);
  const [disableBtnAtSaveSums, setDisableBtnAtSaveSums] = useState(false);
  const [visibleSendKpModal, setVisibleSendKpModal] = useState(false);
  const [sendKpForLead, setSendKpForLead] = useState(false);
  const [currLead, setCurrLead] = useState(null);
  const [currCustomer, setCurrCustomer] = useState(null);
  const [loadingLead, setLoadingLead] = useState(false);
  const [loadingCustomers, setLoadingCustomers] = useState(false);
  const [staffList, setStaffList] = useState([]);
  const [sumsWereEdited, setSumsWereEdited] = useState(false);
  const [loadingStaffList, setLoadingStaffList] = useState(false);
  const [transportList, setTransportList] = useState([]);
  const [columnFiltersFromTableComponent, setColumnFiltersFromTableComponent] =
    useState([]);
  const mainTableRef = useRef(null);
  const isMobile = useCheckMobileScreen();

  const [templateTaskOptionsForFilters] = useState([
    {
      title: 'Авто',
      id: 1,
    },
    {
      title: 'Импорт',
      id: 2,
    },
    {
      title: 'Отправление',
      id: 3,
    },
    {
      title: 'Отпр.-Порожний',
      id: 4,
    },
    {
      title: 'Предоставление вагона',
      id: 5,
    },
    {
      title: 'Предоставление платформы',
      id: 6,
    },
    {
      title: 'Прибытие',
      id: 7,
    },
    {
      title: 'Продажа контейнера',
      id: 8,
    },
    {
      title: 'Ремонт',
      id: 9,
    },
    {
      title: 'Склад - Склад',
      id: 10,
    },
    {
      title: 'Франшиза',
      id: 11,
    },
  ]);
  const [statusTaskOptionsForFilters] = useState([
    {
      title: 'Новая',
      id: false,
    },
    {
      title: 'Отправлено КП',
      id: true,
    },
  ]);
  const [baseColumns, setBaseColumns, baseColumnsRef] = useStateRef([]);
  const demoAccessIsUsed = useTypedSelector(
    (state) => state.demoAccess.demoAccessIsUsed,
  );

  useEffect(() => {
    const columns = sortingColumnsTable(
      userInfo,
      tableName,
      columnsTable,
      visibleFilters,
    );
    const preparedColumnsForResizing = prepareColumnsForResizing(
      columns,
      handleResize,
    );
    setBaseColumns(preparedColumnsForResizing);
  }, [visibleFilters, staffList]);

  const columnsTable = [
    {
      title: '',
      name: 'Дейсвия',
      key: 'action',
      width: 50,
      align: 'center',
      uniqueIndex: 'action',
      index: 1,
      ellipsis: {
        showTitle: false,
      },
      render: (application) =>
        !notDisplayActionPreTask ? (
          <Space size="middle">
            <Dropdown overlay={menu(application)} trigger={['hover']}>
              <EllipsisOutlined
                style={{ cursor: 'pointer', fontSize: '24px' }}
              />
            </Dropdown>
          </Space>
        ) : (
          <></>
        ),
    },
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      width: 80,
      uniqueIndex: 'id',
      index: 2,
      ellipsis: {
        showTitle: false,
      },
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'ID'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={columnFiltersFromTableComponent?.id}
              columnKey={'id'}
            />
          ),
          visible: visibleFilters,
          key: 'id',
          dataIndex: 'id',
          width: 150,
          ellipsis: {
            showTitle: false,
          },
        },
      ],
    },
    {
      title: 'Номер',
      key: 'pre_task_number__search',
      dataIndex: 'pre_task_number',
      width: 150,
      uniqueIndex: 'pre_task_number',
      index: 3,
      ellipsis: {
        showTitle: false,
      },
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Номер'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={
                columnFiltersFromTableComponent?.pre_task_number__search
              }
              columnKey={'pre_task_number__search'}
            />
          ),
          visible: visibleFilters,
          key: 'pre_task_number__search',
          dataIndex: 'pre_task_number',
          width: 150,
          ellipsis: {
            showTitle: false,
          },
        },
      ],
    },
    {
      title: 'Клиент',
      key: 'lead__company_name__icontains',
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      uniqueIndex: 'lead_company_name',
      index: 4,
      render: (lead) => {
        if (lead?.lead?.company_name) {
          return (
            <Tooltip placement={'topLeft'} title={lead.lead.company_name}>
              {lead.lead.company_name}
            </Tooltip>
          );
        } else if (
          lead?.customer?.short_name ||
          lead?.customer?.legal_name ||
          lead?.customer?.email
        ) {
          return (
            <Tooltip
              placement={'topLeft'}
              title={
                lead?.customer?.short_name ||
                lead?.customer?.legal_name ||
                lead?.customer?.email
              }
            >
              {lead?.customer?.short_name ||
                lead?.customer?.legal_name ||
                lead?.customer?.email}
            </Tooltip>
          );
        } else {
          return 'Не указан';
        }
      },
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Клиент'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={
                columnFiltersFromTableComponent?.lead__company_name__icontains
              }
              columnKey={'lead__company_name__icontains'}
            />
          ),
          key: 'lead__company_name__icontains',
          width: 150,
          visible: visibleFilters,
          ellipsis: {
            showTitle: false,
          },
          render: (lead) => {
            if (lead?.lead?.company_name) {
              return (
                <Tooltip placement={'topLeft'} title={lead.lead.company_name}>
                  {lead.lead.company_name}
                </Tooltip>
              );
            } else if (
              lead?.customer?.short_name ||
              lead?.customer?.legal_name ||
              lead?.customer?.email
            ) {
              return (
                <Tooltip
                  placement={'topLeft'}
                  title={
                    lead?.customer?.short_name ||
                    lead?.customer?.legal_name ||
                    lead?.customer?.email
                  }
                >
                  {lead?.customer?.short_name ||
                    lead?.customer?.legal_name ||
                    lead?.customer?.email}
                </Tooltip>
              );
            } else {
              return 'Не указан';
            }
          },
        },
      ],
    },
    {
      title: 'Ведет',
      key: 'vdt',
      width: 250,
      ellipsis: {
        showTitle: false,
      },
      dataIndex: 'vdt_manager',
      uniqueIndex: 'vdt',
      index: 5,
      render: (vdt_manager) => {
        return (
          <Tooltip placement={'topLeft'} title={vdt_manager}>
            {vdt_manager}
          </Tooltip>
        );
      },
      children: [
        {
          title: (
            <FilterSelect
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              tableFilters={columnFiltersFromTableComponent}
              useAdditionalFilterCheckboxOnNull={false}
              columnKey={'vdt'}
              selectedKeys={columnFiltersFromTableComponent?.vdt}
              dataList={convertToSelectOptions(
                staffList,
                'item?.full_name || item?.email',
              )}
              useApiSearch={true}
              onSearch={async (e) => {
                if (e) {
                  await searchStaff(e);
                } else {
                  await getStaffList();
                }
              }}
            />
          ),
          visible: visibleFilters,
          key: 'vdt',
          width: 250,
          ellipsis: {
            showTitle: false,
          },
          dataIndex: 'vdt_manager',
          render: (vdt_manager) => {
            return (
              <Tooltip placement={'topLeft'} title={vdt_manager}>
                {vdt_manager}
              </Tooltip>
            );
          },
        },
      ],
    },
    {
      title: 'Ответственный',
      key: 'otv',
      width: 250,
      ellipsis: {
        showTitle: false,
      },
      dataIndex: 'otv_manager',
      uniqueIndex: 'otv',
      index: 6,
      render: (otv_manager) => {
        return (
          <Tooltip placement={'topLeft'} title={otv_manager}>
            {otv_manager}
          </Tooltip>
        );
      },
      children: [
        {
          title: (
            <FilterSelect
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              tableFilters={columnFiltersFromTableComponent}
              useAdditionalFilterCheckboxOnNull={false}
              columnKey={'otv'}
              selectedKeys={columnFiltersFromTableComponent?.otv}
              dataList={convertToSelectOptions(
                staffList,
                'item?.full_name || item?.email',
              )}
              useApiSearch={true}
              onSearch={async (e) => {
                if (e) {
                  await searchStaff(e);
                } else {
                  await getStaffList();
                }
              }}
            />
          ),
          visible: visibleFilters,
          key: 'otv',
          width: 250,
          ellipsis: {
            showTitle: false,
          },
          dataIndex: 'otv_manager',
          render: (otv_manager) => {
            return (
              <Tooltip placement={'topLeft'} title={otv_manager}>
                {otv_manager}
              </Tooltip>
            );
          },
        },
      ],
    },
    /*{
            title: 'Ответственный',
            dataIndex: 'manager',
            width: 270,
            ellipsis: {
                showTitle: false
            },
            render: (manager) => manager?.full_name ?
                <Tooltip title={manager.full_name}
                         placement={'topLeft'}>
                    {manager.full_name}
                </Tooltip> : 'ФИО не указано',
            children: [
                {
                    title:  <FilterByStaff
                        confirm={(filtersTable) => handleTableChange({current: 1}, filtersTable)}
                        loading={loadingStaffList}
                        viewSearch={true}
                        visibleSelect={false}
                        tableFilters={columnFiltersFromTableComponent}
                        selectedKeys={columnFiltersFromTableComponent?.manager}
                        columnKey={'manager'}
                        dataList={convertToSelectOptions(staffList, "item?.full_name || item?.email")}
                        onSearch={(e) => {
                            if (e) {
                                searchStaff(e);
                            } else {
                                getStaffList();
                            }
                        }}
                    />,
                    visible: visibleFilters,
                    width: 270,
                    key: 'manager',
                    dataIndex: 'manager',
                    render: (manager) => manager?.full_name ?
                        <Tooltip title={manager.full_name}
                                 placement={'topLeft'}>
                            {manager.full_name}
                        </Tooltip> : 'ФИО не указано',
                    }
                ]
        },*/
    {
      title: 'Статус',
      key: 'send_comm_prop',
      dataIndex: 'send_comm_prop',
      width: 200,
      uniqueIndex: 'send_comm_prop',
      index: 7,
      ellipsis: {
        showTitle: false,
      },
      render: (status) =>
        status ? (
          <Tag color={'green'}>Отправлено КП</Tag>
        ) : (
          <Tag color={'blue'}>Новая</Tag>
        ),
      children: [
        {
          title: (
            <FilterSelect
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={columnFiltersFromTableComponent?.send_comm_prop}
              columnKey={'send_comm_prop'}
              useSingleSelect={true}
              dataList={convertToSelectOptions(
                statusTaskOptionsForFilters,
                'item?.title',
              )}
            />
          ),
          visible: visibleFilters,
          key: 'send_comm_prop',
          dataIndex: 'send_comm_prop',
          width: 200,
          ellipsis: {
            showTitle: false,
          },
          render: (status) =>
            status ? (
              <Tag color={'green'}>Отправлено КП</Tag>
            ) : (
              <Tag color={'blue'}>Новая</Tag>
            ),
        },
      ],
    },
    {
      title: 'Тип заявки',
      key: 'template',
      dataIndex: 'template',
      width: 180,
      ellipsis: {
        showTitle: false,
      },
      uniqueIndex: 'template',
      index: 8,
      render: (template) =>
        template?.short ? (
          <Tooltip title={template.short} placement={'topLeft'}>
            {template.short}
          </Tooltip>
        ) : (
          'Без шаблона'
        ),
      children: [
        {
          title: (
            <FilterSelect
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={columnFiltersFromTableComponent?.template}
              columnKey={'template'}
              dataList={convertToSelectOptions(
                templateTaskOptionsForFilters,
                'item?.title',
              )}
            />
          ),
          key: 'template',
          dataIndex: 'template',
          visible: visibleFilters,
          width: 180,
          ellipsis: {
            showTitle: false,
          },
          render: (template) =>
            template?.short ? (
              <Tooltip title={template?.short} placement={'topLeft'}>
                {template?.short}
              </Tooltip>
            ) : (
              'Без шаблона'
            ),
        },
      ],
    },
    {
      title: 'Дата создания',
      key: 'date_create__periodDate',
      dataIndex: 'date_create',
      width: 150,
      uniqueIndex: 'date_create',
      index: 9,
      ellipsis: {
        showTitle: false,
      },
      render: (date_create) =>
        date_create ? dateStrRevers(date_create.split('T')[0]) : null,
      children: [
        {
          title: (
            <FilterDatePicker
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={
                columnFiltersFromTableComponent?.date_create__periodDate
              }
              columnKey={'date_create__periodDate'}
            />
          ),
          key: 'date_create__periodDate',
          dataIndex: 'date_create',
          visible: visibleFilters,
          width: 150,
          ellipsis: {
            showTitle: false,
          },
          render: (date_create) =>
            date_create ? dateStrRevers(date_create.split('T')[0]) : null,
        },
      ],
    },
    {
      title: 'Кол-во тр. ед.',
      key: 'transport_count__range',
      dataIndex: 'transport_count',
      width: 150,
      uniqueIndex: 'transport_count',
      index: 10,
      ellipsis: {
        showTitle: false,
      },
      children: [
        {
          title: (
            <RangeNumberFilters
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={
                columnFiltersFromTableComponent?.transport_count__range
              }
              columnKey={'transport_count__range'}
            />
          ),
          visible: visibleFilters,
          key: 'transport_count__range',
          dataIndex: 'transport_count',
          width: 150,
          ellipsis: {
            showTitle: false,
          },
        },
      ],
    },
    {
      title: 'Наименования тр. ед.',
      key: 'transport_cargo_task__transport__transport_unit__id',
      dataIndex: 'transport_unit_types',
      ellipsis: {
        showTitle: false,
      },
      width: 200,
      uniqueIndex: 'transport_cargo_task',
      index: 11,
      render: (transport_unit_types) => {
        if (transport_unit_types?.length > 0) {
          return (
            <Tooltip
              placement={'topLeft'}
              title={transport_unit_types.join(', ')}
            >
              {transport_unit_types.map(
                (item, index) => item && <Tag key={index}>{item}</Tag>,
              )}
            </Tooltip>
          );
        }
      },
      children: [
        {
          title: (
            <FilterSelect
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={
                columnFiltersFromTableComponent?.transport_cargo_task__transport__transport_unit__id
              }
              columnKey={'transport_cargo_task__transport__transport_unit__id'}
              dataList={convertToSelectOptions(
                transportList,
                "item?.type_full || item?.type_short || 'Без названия'",
              )}
            />
          ),
          visible: visibleFilters,
          key: 'transport_cargo_task__transport__transport_unit__id',
          dataIndex: 'transport_cargo_task',
          ellipsis: {
            showTitle: false,
          },
          width: 200,
          render: (transport_cargo_task) => {
            let transport = [];

            if (transport_cargo_task?.length > 0) {
              transport = transport_cargo_task.map((item) => {
                if (item.transport?.transport_unit) {
                  return (
                    item.transport.transport_unit.type_short ||
                    item.transport.transport_unit.type_full
                  );
                } else {
                  return '-';
                }
              });
            }

            if (transport.length > 0) {
              return (
                <Tooltip placement={'topLeft'} title={transport.join(', ')}>
                  {transport.map((item, index) => (
                    <Tag key={index}>{item}</Tag>
                  ))}
                </Tooltip>
              );
            } else {
              return null;
            }
          },
        },
      ],
    },
    {
      title: 'Маршрут',
      key: 'route__search',
      dataIndex: 'route',
      width: 300,
      ellipsis: {
        showTitle: false,
      },
      uniqueIndex: 'route',
      index: 12,
      render: (route) => {
        return (
          <>
            {route ? (
              <Tooltip title={route} placement={'topLeft'}>
                {route}
              </Tooltip>
            ) : (
              'Не указано'
            )}
          </>
        );
      },
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Маршрут'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={columnFiltersFromTableComponent?.route__search}
              useAdditionalFilterCheckbox={true}
              useAdditionalFilter={true}
              nameAdditionalFilter={'route'}
              columnKey={'route__search'}
            />
          ),
          visible: visibleFilters,
          key: 'route__search',
          dataIndex: 'route',
          width: 300,
          ellipsis: {
            showTitle: false,
          },
          render: (route) => {
            return (
              <>
                {route ? (
                  <Tooltip title={route} placement={'topLeft'}>
                    {route}
                  </Tooltip>
                ) : (
                  'Не указано'
                )}
              </>
            );
          },
        },
      ],
    },
    {
      title: 'Сумма клиенту',
      key: 'sum_str__icontains',
      width: 180,
      ellipsis: {
        showTitle: false,
      },
      uniqueIndex: 'client_sum',
      index: 13,
      render: (application) => {
        return (
          <>
            {application?.sum_str ? (
              <Tooltip title={application?.sum_str} placement={'topLeft'}>
                {application?.sum_str}
              </Tooltip>
            ) : (
              <Tooltip title={'0'} placement={'topLeft'}>
                {'0'}
              </Tooltip>
            )}
          </>
        );
      },
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Сумма клиенту'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={columnFiltersFromTableComponent?.sum_str__icontains}
              columnKey={'sum_str__icontains'}
            />
          ),
          visible: visibleFilters,
          key: 'sum_str__icontains',
          width: 180,
          ellipsis: {
            showTitle: false,
          },
          render: (application) => {
            return (
              <>
                {application?.sum_str ? (
                  <Tooltip title={application?.sum_str} placement={'topLeft'}>
                    {application?.sum_str}
                  </Tooltip>
                ) : (
                  <Tooltip title={'0'} placement={'topLeft'}>
                    {'0'}
                  </Tooltip>
                )}
              </>
            );
          },
        },
      ],
    },
    {
      title: 'Предв. расходы',
      key: 'upfronts_sum_str__icontains',
      width: 180,
      ellipsis: {
        showTitle: false,
      },
      uniqueIndex: 'upfronts_sum',
      index: 14,
      render: (application) => {
        return (
          <>
            {application?.upfronts_sum_str ? (
              <Tooltip
                title={application?.upfronts_sum_str}
                placement={'topLeft'}
              >
                {application?.upfronts_sum_str}
              </Tooltip>
            ) : (
              <Tooltip title={'0'} placement={'topLeft'}>
                {'0'}
              </Tooltip>
            )}
          </>
        );
      },
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Предв. расходы'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={
                columnFiltersFromTableComponent?.upfronts_sum_str__icontains
              }
              columnKey={'upfronts_sum_str__icontains'}
            />
          ),
          visible: visibleFilters,
          key: 'upfronts_sum_str__icontains',
          width: 180,
          ellipsis: {
            showTitle: false,
          },
          render: (application) => {
            return (
              <>
                {application?.upfronts_sum_str ? (
                  <Tooltip
                    title={application?.upfronts_sum_str}
                    placement={'topLeft'}
                  >
                    {application?.upfronts_sum_str}
                  </Tooltip>
                ) : (
                  <Tooltip title={'0'} placement={'topLeft'}>
                    {'0'}
                  </Tooltip>
                )}
              </>
            );
          },
        },
      ],
    },
    {
      title: 'Дельта',
      key: 'delta_str__icontains',
      width: 180,
      uniqueIndex: 'delta_sum',
      ellipsis: {
        showTitle: false,
      },
      index: 15,
      render: (application) => {
        return (
          <>
            {application?.delta_str ? (
              <Tooltip title={application?.delta_str} placement={'topLeft'}>
                {application?.delta_str}
              </Tooltip>
            ) : (
              <Tooltip title={'0'} placement={'topLeft'}>
                {'0'}
              </Tooltip>
            )}
          </>
        );
      },
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Дельта'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponent}
              selectedKeys={
                columnFiltersFromTableComponent?.delta_str__icontains
              }
              columnKey={'delta_str__icontains'}
            />
          ),
          visible: visibleFilters,
          key: 'delta_str__icontains',
          width: 180,
          ellipsis: {
            showTitle: false,
          },
          render: (application) => {
            return (
              <>
                {application?.delta_str ? (
                  <Tooltip title={application?.delta_str} placement={'topLeft'}>
                    {application?.delta_str}
                  </Tooltip>
                ) : (
                  <Tooltip title={'0'} placement={'topLeft'}>
                    {'0'}
                  </Tooltip>
                )}
              </>
            );
          },
        },
      ],
    },
  ];

  useAsyncEffect(async () => {
    if (visibleFilters) {
      await getStaffList();
      await getTransportList();
    }
  }, [visibleFilters]);

  useEffect(() => {
    if (tableFilters) {
      const newFilters = {
        ...tableFilters?.filter,
        filter: {
          ...tableFilters?.filter,
          search: tableFilters?.filter?.search,
        },
      };
      setParams(newFilters);
    } else {
      setParams(null);
      setColumnFiltersFromTableComponent([]);
    }
  }, [tableFilters]);

  useEffect(() => {
    if (modalPreApp) {
      setVisibleEditApplicationModal(true);
      setCurrEditApplication(modalPreApp);
    }
  }, [modalPreApp]);

  useEffect(() => {
    if (pageProps) {
      setPage(pageProps);
    }
  }, [pageProps]);

  const deleteApplication = async (pre_task) => {
    try {
      const url = `${restApiHost}/leads/pre_tasks/${pre_task}/`;
      const response = await call(
        url,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ disactive: true }),
        },
        userInfo.current.haveViewBranch,
      );

      if (!response) return;

      message.success('Запись успешно удалена!');
      isMobile && handleScrollToTopMobileList();
      updateTable &&
        updateTable(
          isMobile ? 1 : pageRef.current,
          paramsRef.current,
          false,
          false,
        );
    } catch (e) {
      console.log('error delete application', e);
      message.error('Ошибка запроса на удаление!');
    }
  };

  const handleUpdate = (page, params, me, concatData) => {
    updateTable(page, params, me, concatData);
    //setParams(params)
  };

  useEffect(async () => {
    if (preTaskForKP) {
      await handleSendKpApplication(preTaskForKP);
    }
  }, [preTaskForKP]);

  const handleSendKpApplication = async (application) => {
    setLoadingSpin(true);
    await getStaffList();
    const hide = message.loading('Поиск лида/клиента', 0);
    if (application?.lead?.id) {
      await getLead(application.lead.id);
      setSendKpForLead(true);
    } else if (application?.customer?.id) {
      await getCustomer(application.customer.id);
      setSendKpForLead(false);
    }
    setVisibleSendKpModal(true);
    hide();
    setLoadingSpin(false);
  };
  const closeVisibleModalKp = () => {
    setVisibleSendKpModal(false);
    setCurrCustomer(null);
    setCurrLead(null);
  };

  const getLead = async (id) => {
    setLoadingLead(true);
    try {
      const url = `${restApiHost}/leads/list/${id}/`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );
      if (json?.data) {
        setCurrLead(json.data);
        return json.data;
      }
    } catch (e) {
      console.log('get lead err', e);
    } finally {
      setLoadingLead(false);
    }
  };
  const getCustomer = async (id) => {
    setLoadingCustomers(true);
    try {
      const url = `${restApiHost}/customers/list/${id}/`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (json?.data) {
        setCurrCustomer(json?.data);
        return json;
      }
    } catch (e) {
      console.log('delete customer err', e);
      message.error('Ошибка запроса на удаление клиента');
    } finally {
      setLoadingCustomers(false);
    }
  };
  const handleCopyApplication = async (application) => {
    if (!application?.id) return;

    const hide = message.loading('Копирование предварительной заявки', 0);
    try {
      const body = {
        task: application.id,
        copy: true,
      };
      const json = await call(
        `${restApiHost}/leads/copy_task/`,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json?.data) return;

      setCurrEditApplication(json?.data);
      setVisibleEditApplicationModal(true);
      setIsCopy(true);
    } catch (e) {
      console.log('copy pre task err', e);
      message.error('Ошибка копирования предварительной заявки');
    } finally {
      hide();
    }
  };

  const getStaffList = async () => {
    try {
      const url = `${restApiHost}/accounts/staff_users/`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (json?.data) {
        setStaffList(json?.data);
      }
    } catch (e) {
      console.log('fetch profile info error', e);
    }
  };

  const searchStaff = async (keyword) => {
    try {
      setLoadingStaffList(true);
      const url = `${restApiHost}/accounts/staff_users/?search=${keyword}`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );
      if (json?.data) {
        setStaffList(json?.data);
      }
    } catch (e) {
      console.log(e);
      message.error('Ошибка запроса! Не удалось найти лида');
    } finally {
      setLoadingStaffList(false);
    }
  };

  const getTransportList = async () => {
    try {
      const url = `${restApiHost}/refbook/transport_units/?active=true`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );
      if (json?.data) {
        setTransportList(json.data);
      }
    } catch (e) {
      console.log(e);
      message.error('Ошибка получения списка вида тр. ед.');
    }
  };

  const menu = (application) => {
    return (
      <Menu style={{ width: 220 }}>
        <Menu.Item
          icon={<EditOutlined />}
          onClick={() => {
            setCurrEditApplication(application);
            setVisibleEditApplicationModal(true);
          }}
        >
          Редактировать
        </Menu.Item>
        <Menu.Item
          icon={<CopyOutlined />}
          onClick={async () => await handleCopyApplication(application)}
        >
          Копировать
        </Menu.Item>
        {!renderInOutPagePreTask && !application?.send_comm_prop ? (
          <Menu.Item
            disabled={demoAccessIsUsed}
            icon={<FilePdfOutlined />}
            onClick={() => handleSendKpApplication(application)}
          >
            Отправить КП/Презентацию
          </Menu.Item>
        ) : (
          <></>
        )}
        <Popconfirm
          title="Вы действительно хотите удалить запись?"
          onConfirm={() => deleteApplication(application.id)}
          okText="Да"
          cancelText="Нет"
          placement="rightBottom"
        >
          <Menu.Item icon={<DeleteOutlined />}>Удалить</Menu.Item>
        </Popconfirm>
      </Menu>
    );
  };

  const updateUserInfo = async (userInfoForCallApi) => {
    setLoading(true);
    const profileInfo = await callGetProfileInfo(userInfoForCallApi);
    const haveViewBranch =
      profileInfo?.data?.permissions.some(
        (o) => o.codename === 'switch_branch',
      ) &&
      profileInfo?.data?.permissions.some((o) => o.codename === 'view_branch');
    if (haveViewBranch) {
      profileInfo['haveViewBranch'] = true;
    }
    userInfo.current = profileInfo;
    const columns = sortingColumnsTable(
      userInfo,
      tableName,
      columnsTable,
      visibleFilters,
    );
    const preparedColumnsForResizing = prepareColumnsForResizing(
      columns,
      handleResize,
    );
    setBaseColumns(preparedColumnsForResizing);
    setLoading(false);
  };

  const filteringColumnsChildrenPillarByVisible = useMemo(() => {
    const copyColumns = [...baseColumns];
    return filterActiveColumns(copyColumns, expanded);
  }, [baseColumns, expanded]);

  const mainTableWidth = useMemo(() => {
    const copyColumns = [...baseColumns];
    let filteredColumns = filterActiveColumns(copyColumns, expanded);
    return filteredColumns.reduce((n, { width }) => n + width, 0);
  }, [baseColumns, expanded]);

  const getMainTableWidth = useCallback(() => {
    return mainTableWidth;
  }, [mainTableWidth]);

  useEffect(() => {
    if (mainTableRef.current.children[0]) {
      changeWidthStyleMainTable(mainTableRef, mainTableWidth);
    }
  }, [mainTableWidth]);

  const handleResize = useCallback(
    (index) =>
      async (e, { size }) => {
        const copyColumns = [...baseColumnsRef.current];

        setBaseColumns((columns) => {
          const nextColumns = [...columns];
          const children = nextColumns[index]?.children
            ? // eslint-disable-next-line no-unsafe-optional-chaining
              [...nextColumns[index]?.children]
            : null;
          if (children && children[0]?.width) {
            children[0].width = size;
          }
          nextColumns[index] = {
            ...nextColumns[index],
            width: size,
            children: children ? [...children] : [],
          };
          return nextColumns;
        });

        const databaseColumnsId = copyColumns[index]?.idInDatabase;
        if (!databaseColumnsId) return;

        await editTableColumn(databaseColumnsId, { width: size }, userInfo);
        const res = await getProfileInfo(userInfo);
        if (res) {
          userInfo.current = res;
          const haveViewBranch = userInfoHaveViewAllBranch(res);
          if (haveViewBranch) {
            userInfo.current['haveViewBranch'] = true;
          }
        }
      },
    [baseColumnsRef.current],
  );

  const handleTableChange = (pagination, filtersTable, sorter) => {
    setColumnFiltersFromTableComponent(filtersTable);
    const { page, filters } = getFiltersTable(pagination, filtersTable, sorter);
    setPage(page);
    let newFilters = { ...filters };
    if (tableFilters?.filter?.search) {
      newFilters = {
        ...filters,
        filter: { ...filters.filter, search: tableFilters.filter.search },
      };
    }
    setParams(newFilters);
    handleUpdate(page, newFilters);
  };

  const setSavingSum = () => {
    if (visitSumsClient) {
      if (!updtFromLead) {
        isMobile && handleScrollToTopMobileList();
        handleUpdate(isMobile ? 1 : pageRef.current, paramsRef.current, false);
      }
      setSumsWereEdited(false);
      updtFromLead && updtFromLead();
      setVisibleEditApplicationModal(false);
      setDisableBtnAtSaveSums(false);
      setIsCopy(false);
      setVisitSumsClient(false);
      savingSum.current = false;
    }
  };
  const setVisitSums = () => {
    setVisitSumsClient(true);
  };
  const saveSumsWhenSwitching = () => {
    savingSumWereEditing.current = true;
    setDisableBtnAtSaveSums(true);
  };
  const endSavingSumsWhenSwitching = () => {
    setSumsWereEdited(false);
    setDisableBtnAtSaveSums(false);
    setVisitSumsClient(false);
    savingSumWereEditing.current = false;
  };
  const rowSelection = rowSel ? { rowSelection: rowSel } : {};

  return (
    <>
      {/*{fromPreApplicationPage ?*/}
      {/*    <Checkbox checked={filterMe} onChange={(e) => setFilterMe(e.target.checked)}><span className={'filter-me-label'}>Только свои</span></Checkbox>*/}
      {/*    : <></>}*/}

      {/*pre-applications table*/}
      <ConfigProvider locale={locale}>
        <div ref={mainTableRef}>
          {!isMobile ? (
            <Table
              rowClassName={(record, index) =>
                index % 2 === 0
                  ? 'black-text table-row-color'
                  : 'black-text table-row-white'
              }
              onRow={(record) => {
                return {
                  onDoubleClick: () => {
                    setCurrEditApplication(record);
                    setVisibleEditApplicationModal(true);
                  },
                };
              }}
              scroll={{ x: getMainTableWidth() }}
              sticky={fromPreApplicationPage}
              onChange={(val) => {
                handleTableChange(
                  { current: val?.current },
                  columnFiltersFromTableComponent,
                );
              }}
              components={{
                header: {
                  cell: ResizableTableColumnHeader,
                },
              }}
              dataSource={applications}
              columns={filteringColumnsChildrenPillarByVisible}
              loading={loading}
              size="small"
              bordered={true}
              pagination={setPaginationSettings(
                total,
                pageRef.current,
                'left',
                fromPreApplicationPage,
                1,
                'middle',
                fromPreApplicationPage ? null : 15,
                [10, 15, 20, 50, 100],
                fromPreApplicationPage,
              )}
              rowKey={(record) => record.id}
              {...rowSelection}
            />
          ) : (
            <ErrorBoundary>
              <ScrollingLoadedList
                dataList={applications}
                totalData={total}
                loadMoreItems={async () => {
                  await handleUpdate(page + 1, params, false, true);
                }}
                scrollingLoadedListRef={scrollingLoadedListRef}
                renderListItem={(item) => (
                  <PreTaskListItem
                    preTask={item}
                    menuActions={(preTask) => menu(preTask)}
                  />
                )}
              />
            </ErrorBoundary>
          )}
        </div>
      </ConfigProvider>

      <ErrorBoundary>
        <UniversalFiltersModal
          visibleModal={visibleFilters && isMobile}
          handleClose={() => {
            setVisibleFilters(false);
          }}
          resetFilters={async () => {
            setVisibleFilters(false);
            isMobile && handleScrollToTopMobileList();
            await handleUpdate(1, null, false, false);
          }}
          columnsList={columnsTable}
        />
      </ErrorBoundary>

      {/*edit pre-application modal*/}
      <ErrorBoundary>
        <PreApplicationModal
          visible={visibleEditApplicationModal}
          handleUpdate={() => {
            isMobile && handleScrollToTopMobileList();
            handleUpdate(
              isMobile ? 1 : pageRef.current,
              paramsRef.current,
              false,
              false,
            );
          }}
          handleOk={() => {
            setVisibleEditApplicationModal(false);
            setIsCopy(false);
          }}
          handleCancel={() => {
            if (visitSumsClient && sumsWereEdited) {
              savingSum.current = true;
              setDisableBtnAtSaveSums(true);
            } else {
              updtFromLead && updtFromLead();
              if (!updtFromLead) {
                isMobile && handleScrollToTopMobileList();
                handleUpdate(
                  isMobile ? 1 : pageRef.current,
                  paramsRef.current,
                  false,
                  false,
                );
              }
              setVisibleEditApplicationModal(false);
              setIsCopy(false);
            }
          }}
          handleOpenSendKpModal={async (application) => {
            await handleSendKpApplication(application);
          }}
          fromPreApplicationPage={
            fromPreApplicationPage !== undefined ? fromPreApplicationPage : true
          }
          loading={false}
          updateData={(data) => setCurrEditApplication(data)}
          data={currEditApplication}
          customer={modalPreApp?.customer || currEditApplication?.customer}
          isCopy={isCopy}
          savingSum={savingSum.current}
          savingSumWereEditing={savingSumWereEditing.current}
          setSavingSum={setSavingSum}
          setVisitSums={setVisitSums}
          saveSumsWhenSwitching={() => saveSumsWhenSwitching()}
          changeSumsWereEdited={(sumEditing) => setSumsWereEdited(sumEditing)}
          sumsWereEdited={sumsWereEdited}
          endSavingSumsWhenSwitching={() => endSavingSumsWhenSwitching()}
          disableBtnAtSaveSums={disableBtnAtSaveSums}
          renderInOutPagePreTask={renderInOutPagePreTask}
        />
      </ErrorBoundary>

      {sendKpForLead ? (
        <ErrorBoundary>
          <SendKpOrPresentationModal
            visibleModal={visibleSendKpModal}
            preTask={currLead?.pre_tasks}
            closeVisibleModal={closeVisibleModalKp}
            currentLead={currLead}
            customer={currCustomer}
            staffList={staffList}
            sendFromLeadPage={true}
            page={page}
            updateModelData={getLead}
            handleUpdate={() => {
              isMobile && handleScrollToTopMobileList();
              handleUpdate(isMobile ? 1 : pageRef.current, paramsRef.current);
            }}
            loading={loadingLead}
          />
        </ErrorBoundary>
      ) : (
        <ErrorBoundary>
          <SendKpOrPresentationModal
            visibleModal={visibleSendKpModal}
            preTask={currCustomer?.pre_tasks}
            currentLead={currLead}
            customer={currCustomer}
            staffList={staffList}
            closeVisibleModal={closeVisibleModalKp}
            handleUpdate={() => {
              isMobile && handleScrollToTopMobileList();
              handleUpdate(isMobile ? 1 : pageRef.current, paramsRef.current);
            }}
            updateModelData={getCustomer}
            page={page}
            sendFromLeadPage={false}
            loading={loadingCustomers}
          />
        </ErrorBoundary>
      )}

      <ErrorBoundary>
        <SettingTableColumnsDrawer
          visible={visibleTableSetting}
          loading={false}
          columns={baseColumns}
          onCloseDrawer={async () => {
            onCloseDrawerTableSetting();
            await updateUserInfo(userInfo);
          }}
        />
      </ErrorBoundary>
    </>
  );
};

export default PreAppicationsTable;
