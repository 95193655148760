import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { restApiHost } from '../../../../utils/appVariables';
import { call } from '../../../../apiUtils/call';
import { message } from 'antd';
import BranchesSettingSectionsTable from './SettingDisplaySectionsBranchStepComponents/BranchesSettingSectionsTable';

const SettingDisplaySectionsBranchStep = (props) => {
  const [branchGroup, setBranchGroup] = useState([]);
  const [totalBranchGroup, setTotalBranchGroup] = useState(0);
  const [page] = useState(1);

  useEffect(async () => {
    if (props.branchId) {
      await getGroups(page, props.branchId);
    }
  }, [props.branchId]);

  const getGroups = async (page, branchId) => {
    const groupBranchJson = await getBranchGroups(branchId, page);
    if (groupBranchJson?.data) {
      setBranchGroup(groupBranchJson?.data);
    }
    if (groupBranchJson?.count) {
      setTotalBranchGroup(groupBranchJson?.count);
    }
  };

  const getBranchGroups = async (branchId, page) => {
    props.setLoading(true);
    try {
      const url = `${restApiHost}/accounts/groups/?branch=${branchId}&page=${page}`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        false,
      );

      if (json) {
        return json;
      }
    } catch (e) {
      console.log('get group branch err', e);
      message.error('Ошибка запроса на получение групп филиала');
    } finally {
      props.setLoading(false);
    }
  };

  return (
    <>
      <BranchesSettingSectionsTable
        branchId={props.branchId}
        groups={branchGroup}
        groupsTotal={totalBranchGroup ? totalBranchGroup : branchGroup?.length}
        page={page}
        setLoading={(val) => props.setLoading(val)}
        handleUpdateGroup={async (page, branchId) =>
          await getGroups(page, branchId)
        }
      />
    </>
  );
};

SettingDisplaySectionsBranchStep.propTypes = {
  branchId: PropTypes.number,
  setLoading: PropTypes.func,
};

export default SettingDisplaySectionsBranchStep;
