import { Helmet } from 'react-helmet';
import { Box } from '@material-ui/core';
import AccountProfile from '../components/account/profile/AccountProfile';
import { useContext, useEffect, useState } from 'react';
import { restApiHost } from '../utils/appVariables';
import { userInformations } from '../contex';
import { call } from '../apiUtils/call';
import { useTypedSelector } from '../store/hooks/useTypedSelector';
import formationDocumentTitle from '../utils/formationDocumentTitle';
import { Menu, Skeleton, Space } from 'antd';
import AccountSecurity from '../components/account/security/AccountSecurity';

const Account = () => {
  const [profileInfo, setProfileInfo] = useState(null);
  const [loadingProfileInfo, setLoadingProfileInfo] = useState(true);
  const { userInfo } = useContext(userInformations);
  const notificationCount = useTypedSelector(
    (state) => state.notificationCount.count,
  );
  const [menuState, setMenuState] = useState('main');

  useEffect(() => {
    const getProfileInfo = async () => {
      setLoadingProfileInfo(true);
      try {
        const url = `${restApiHost}/accounts/profile/`;
        const json = await call(
          url,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${localStorage.getItem('token')}`,
            },
          },
          userInfo.current.haveViewBranch,
        );
        if (json?.data) {
          setProfileInfo(json.data);
        }
      } catch (e) {
        console.log('fetch profile info error', e);
        setLoadingProfileInfo(false);
      } finally {
        setLoadingProfileInfo(false);
      }
    };

    localStorage.getItem('token') && getProfileInfo();
  }, []);

  const onMenuClick = (e) => {
    setMenuState(e.key);
  };

  return (
    <>
      <Helmet>
        <title>
          {formationDocumentTitle('Аккаунт | Квик Транс', notificationCount)}
        </title>
      </Helmet>

      <Space align="start" direction="horizontal">
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
          }}
        >
          <Menu
            onClick={onMenuClick}
            mode="horizontal"
            style={{ backgroundColor: 'transparent', marginBottom: 16 }}
          >
            <Menu.Item key="main">Основная информация</Menu.Item>
            <Menu.Item key="security">Безопасность</Menu.Item>
          </Menu>

          {Object.is(menuState, 'main') ? (
            <AccountProfile
              profileInfo={profileInfo}
              loading={loadingProfileInfo}
              update={setProfileInfo}
            />
          ) : Object.is(menuState, 'security') ? (
            <AccountSecurity loading={loadingProfileInfo} />
          ) : (
            <Skeleton />
          )}
        </Box>
      </Space>
    </>
  );
};

export default Account;
