import { Checkbox, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';

const SearchDataFilter = ({
  selectedKeys,
  tableFilters,
  columnKey,
  confirm,
  inputPlaceholder,
  disable,
  useAdditionalFilter,
  nameAdditionalFilter,
  useAdditionalFilterCheckbox,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [valueAdditionalFilterCheckbox, setAdditionalFilterCheckbox] =
    useState(false);
  const isMobile = useCheckMobileScreen();

  useEffect(() => {
    return () => {
      setInputValue('');
    };
  }, []);

  useEffect(() => {
    const value =
      selectedKeys?.length > 0 && selectedKeys[0] ? selectedKeys[0] : '';
    setInputValue(value);
  }, [selectedKeys]);

  const search = (event) => {
    if (event?.key === 'Enter' || (isMobile && event.type === 'blur')) {
      let filters = tableFilters;
      filters[columnKey] = [inputValue];
      if (useAdditionalFilter && !useAdditionalFilterCheckbox) {
        filters[nameAdditionalFilter] = [inputValue];
      }
      confirm(filters);
    }
  };

  const clearInput = () => {
    let filters = tableFilters;
    filters[columnKey] = null;
    if (useAdditionalFilter) {
      filters[nameAdditionalFilter] = null;
    }
    confirm(filters);
  };

  const onChangeCheckbox = (val) => {
    setAdditionalFilterCheckbox(val);
    setInputValue('');
    if (val && useAdditionalFilter && nameAdditionalFilter) {
      let filters = tableFilters;
      filters[nameAdditionalFilter] = ['no'];
      filters[columnKey] = null;
      confirm(filters);
    } else {
      clearInput();
    }
  };

  return (
    <div style={{ padding: 8, display: 'flex', alignItems: 'center' }}>
      <Input
        style={{ width: '100%' }}
        value={inputValue}
        onChange={(e) => {
          if (e.target.value === '') {
            clearInput();
          }
          setInputValue(e.target.value);
        }}
        onBlur={(event) => {
          search(event);
        }}
        disabled={disable || valueAdditionalFilterCheckbox}
        onKeyUp={(event) => {
          search(event);
        }}
        autoFocus={false}
        allowClear={true}
        placeholder={inputPlaceholder ? inputPlaceholder : ''}
      />
      {useAdditionalFilterCheckbox ? (
        <Checkbox
          style={{ marginLeft: '5px' }}
          value={valueAdditionalFilterCheckbox}
          autoFocus={false}
          onChange={(e) => onChangeCheckbox(e.target.checked)}
        >
          <span>Не указано</span>
        </Checkbox>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SearchDataFilter;
