import { Card } from 'antd';
import DayOfMonthHeader from '../DayOfMonthHeader';
import TaskItemDayOfMonth from '../../tasks/TaskItemDayOfMonth';
import React from 'react';
import PropTypes from 'prop-types';
import detectArray from '../../../../../utils/detectArray';
import DropdownViewAllTasks from './DropdownViewAllTasks';

const DropdownOverlayRender = (props) => {
  return (
    <>
      <Card
        onDoubleClick={() => {
          if (props.isWeekDay) {
            props.setVisibleDropdown(false);
          }
        }}
        className={'task-item-day-month-dropdown-overlay-container'}
        bodyStyle={{ padding: '3px 10px 15px 10px' }}
      >
        <div>
          <DayOfMonthHeader
            day={props.day}
            rowIndex={0}
            goToCompetitionDate={(day) => {
              props.goToCompetitionDate(day);
            }}
            useForDayViewCalendar={props.useForDayViewCalendar}
            timePeriodTitle={
              props.timePeriodTitle ? props.timePeriodTitle : null
            }
          />
        </div>
        <div style={{ paddingTop: '5px' }}>
          {props.tasks?.length > 0 &&
            props.tasks.map((task, index) => {
              if (!detectArray(task)) {
                return (
                  <TaskItemDayOfMonth
                    key={index}
                    task={task}
                    useForDayViewCalendar={false}
                    handleEditTask={(task) => props.handleEditTask(task)}
                  />
                );
              } else if (detectArray(task)) {
                return (
                  <DropdownViewAllTasks
                    key={index}
                    day={props.day}
                    tasks={task[1] ? task[1] : []}
                    isWeekDay={true}
                    groupTitle={'ПЕРСОНАЛЬНЫЕ ЗАДАЧИ'}
                    useForDayViewCalendar={props.useForDayViewCalendar}
                    useForCallGroupLead={true}
                    timePeriodTitle={props.timePeriodTitle}
                    goToCompetitionDate={(day) => {
                      props.goToCompetitionDate(day);
                    }}
                    handleEditTask={(task) => props.handleEditTask(task)}
                  />
                );
              }
            })}
        </div>
      </Card>
    </>
  );
};

DropdownOverlayRender.PropTypes = {
  day: PropTypes.any,
  tasks: PropTypes.any,
  timePeriodTitle: PropTypes.string,
  useForDayViewCalendar: PropTypes.bool,
  handleEditTask: PropTypes.func.isRequired,
  setVisibleDropdown: PropTypes.func,
  goToCompetitionDate: PropTypes.func,
  isWeekDay: PropTypes.bool,
};

export default DropdownOverlayRender;
