import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Form,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Tooltip,
} from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import getLocations from '../../../utils/getLocations';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { userInformations } from '../../../contex';
import {
  getContactsCustomer,
  getCustomerCustomerWaypoints,
} from '../../../logic/customer/CustomerApiFunctions';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';

const { Option } = Select;

const AddTransportationModal = (props) => {
  const [form] = Form.useForm();
  const [disableSaveBtn, setDisableSaveBtn] = useState(true);
  const [waypoints, setWaypoints] = useState([]);
  const [addressValue, setAddressValue] = useState(null);
  const [contactPersonSelectedCustomer, setContactPersonSelectedCustomer] =
    useState([]);
  const [storageCustomerSelected, setStorageCustomerSelected] = useState(false);
  const { userInfo } = useContext(userInformations);
  const isMobile = useCheckMobileScreen();

  useEffect(async () => {
    await searchLocation();
  }, []);

  useEffect(async () => {
    if (!props.visible) {
      await handleClearVariables();
    }
  }, [props.visible]);

  useEffect(async () => {
    if (!props.currentStockCustomer && props.visible && props.customerId) {
      const contactCustomer = await handleGetCustomerContactPersons(
        props.customerId,
      );
      if (contactCustomer) {
        setContactPersonSelectedCustomer(
          contactCustomer ? contactCustomer : [],
        );
      }
    }
  }, [props.customerId, props.visible]);

  useEffect(async () => {
    if (props.currentStockCustomer) {
      const contactCustomer = await handleGetCustomerContactPersons(
        props?.currentStockCustomer?.id,
      );
      if (contactCustomer) {
        setContactPersonSelectedCustomer(
          contactCustomer ? contactCustomer : [],
        );
      }
      const waypointsCustomer = await handleGetCustomerWaypoints(
        props?.currentStockCustomer?.id,
      );
      setWaypoints(waypointsCustomer ? waypointsCustomer : []);
    }
  }, [props.currentStockCustomer]);

  useEffect(async () => {
    if (props.editingItem) {
      form.setFieldsValue({
        ...props.editingItem,
        address: props.editingItem?.address ? props.editingItem.address : null,
        storage_customer: props.editingItem?.storage_customer?.id
          ? props.editingItem?.storage_customer?.id
          : null,
        contact_person: props.editingItem?.contact_person?.id
          ? props.editingItem?.contact_person?.id
          : null,
      });
      props.editingItem?.storage_customer?.id &&
        props.addCustomerToListOptionsSelect(
          props.editingItem?.storage_customer?.id,
        );
      props.editingItem?.storage_customer?.id &&
        (await onChangeSelectedStockCustomer(
          props.editingItem?.storage_customer?.id,
        ));
    }
  }, [props.editingItem]);

  const handleGetCustomerWaypoints = useCallback(
    async (customerId) => {
      const res = await getCustomerCustomerWaypoints(customerId, userInfo);
      if (res?.data && typeof res?.data !== 'string') {
        return res.data;
      }
    },
    [getCustomerCustomerWaypoints],
  );

  const handleGetCustomerContactPersons = useCallback(
    async (customerId) => {
      const res = await getContactsCustomer(customerId, userInfo);
      if (res?.data) {
        return res.data;
      }
    },
    [getContactsCustomer],
  );

  const searchLocation = async (keyword) => {
    try {
      const location = await getLocations(keyword ? keyword : null);
      if (location) {
        setWaypoints(location);
      }
    } catch (e) {
      console.log('search location err', e);
      message.error('Ошибка поиска адреса');
      setWaypoints([]);
    }
  };

  const handleCloseModal = () => {
    props.onCloseModal();
  };

  const handleClearVariables = async () => {
    setDisableSaveBtn(true);
    if (props.customerId) {
      const contactCustomer = await handleGetCustomerContactPersons(
        props.customerId,
      );
      if (contactCustomer) {
        setContactPersonSelectedCustomer(
          contactCustomer ? contactCustomer : [],
        );
      }
    }
    setWaypoints([]);
    setStorageCustomerSelected(false);
    props.clearCurrentStockCustomer();
    form.resetFields();
  };

  const onChangeSelectedStockCustomer = async (val, clearForm) => {
    if (!val) return;
    if (clearForm) {
      form.setFieldsValue({
        contact_person: null,
        address: '',
      });
      setAddressValue(null);
    }
    setStorageCustomerSelected(true);
    await props.getCustomerStockDataById(val, userInfo);
    props.getCustomerInStockList();
  };

  return (
    <>
      <Modal
        visible={props.visible}
        zIndex={props.zIndexModal ? props.zIndexModal : 199}
        title={props.editingItem ? 'Редактировать пункт' : 'Добавить пункт'}
        onCancel={() => {
          handleCloseModal();
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              handleCloseModal();
            }}
          >
            Закрыть
          </Button>,
          <Button
            key="submit"
            type="primary"
            disabled={disableSaveBtn}
            onClick={() => {
              form.validateFields().then(async (values) => {
                let body = {
                  ...values,
                  customer: props.customerId,
                };
                props.editingItem
                  ? props.editTransportation(props.editingItem?.id, body)
                  : props.addTransportation(body);
              });
            }}
          >
            {props.editingItem ? 'Редактировать' : 'Добавить'}
          </Button>,
        ]}
      >
        <Spin spinning={props.loading}>
          <Form
            onValuesChange={() => {
              setTimeout(() => {
                form
                  .validateFields()
                  .then(() => setDisableSaveBtn(false))
                  .catch(() => setDisableSaveBtn(true));
              }, 0);
            }}
            form={form}
            layout={'vertical'}
          >
            <Row gutter={16}>
              {!props.clientHasCategorySkl ? (
                <Col lg={24} xs={24}>
                  <Form.Item label={'Контрагент'} name={'storage_customer'}>
                    <div style={{ display: 'flex', gap: '0px' }}>
                      <Form.Item
                        style={{ width: '100%' }}
                        name={'storage_customer'}
                      >
                        <Select
                          getPopupContainer={(trigger) =>
                            isMobile ? trigger.parentNode : document.body
                          }
                          className={'storage-customer-select'}
                          width={'100%'}
                          filterOption={false}
                          showSearch
                          allowClear={true}
                          onClear={async () => {
                            await handleClearVariables();
                          }}
                          placeholder={'Контрагент'}
                          onSearch={async (keyword) => {
                            if (keyword?.length > 0) {
                              props.callSearchCustomerInStockList(keyword);
                            } else {
                              props.getCustomerInStockList();
                            }
                          }}
                          onChange={async (val) => {
                            await onChangeSelectedStockCustomer(val, true);
                          }}
                        >
                          {props.customerInStock?.length > 0 ? (
                            <>
                              {props.customerInStock.map((customer, index) => (
                                <Option key={index} value={customer?.id}>
                                  {customer?.short_name ||
                                    customer?.legal_name ||
                                    'Юр.имя не задано'}
                                </Option>
                              ))}
                            </>
                          ) : (
                            <Option disabled={true} value={null}>
                              Нет доступных вариантов
                            </Option>
                          )}
                        </Select>
                      </Form.Item>
                      <Tooltip title={'Добавить контрагента'}>
                        <Button
                          onClick={() => props.openModalCreateStockCustomer()}
                          className={'additional-btn-select'}
                          autoFocus={false}
                          type="primary"
                          icon={<PlusOutlined />}
                        />
                      </Tooltip>
                    </div>
                  </Form.Item>
                </Col>
              ) : (
                <></>
              )}
              <Col lg={24} xs={24}>
                <Form.Item
                  rules={[{ required: true, message: 'Обязательное поле!' }]}
                  label={'Транспортный пункт'}
                  name={'address'}
                >
                  <Select
                    getPopupContainer={(trigger) =>
                      isMobile ? trigger.parentNode : document.body
                    }
                    filterOption={false}
                    showSearch
                    placeholder={'Транспортный пункт'}
                    //disabled={!props.currentStockCustomer}
                    onSearch={async (keyword) => {
                      setAddressValue(keyword);
                      if (
                        !storageCustomerSelected ||
                        props.clientHasCategorySkl
                      ) {
                        await searchLocation(keyword);
                      }
                    }}
                    onChange={async () => {
                      if (
                        !storageCustomerSelected ||
                        props.clientHasCategorySkl
                      ) {
                        await searchLocation();
                      }
                    }}
                  >
                    {waypoints.length > 0 ? (
                      <>
                        {waypoints.map((waypoint, index) => (
                          <Option
                            key={index}
                            value={waypoint?.value || waypoint?.address}
                          >
                            {waypoint?.value || waypoint?.address}
                          </Option>
                        ))}
                      </>
                    ) : (
                      <>
                        {addressValue ? (
                          <Option value={addressValue}>{addressValue}</Option>
                        ) : (
                          <Option disabled value={null}>
                            Нет доступных вариантов
                          </Option>
                        )}
                      </>
                    )}
                  </Select>
                </Form.Item>
              </Col>
              {!props.clientHasCategorySkl ? (
                <Col lg={24} xs={24}>
                  <Form.Item label={'Контактное лицо'} name={'contact_person'}>
                    <div style={{ display: 'flex', gap: '0px' }}>
                      <Form.Item
                        style={{ width: '100%' }}
                        name={'contact_person'}
                        rules={[
                          {
                            required: storageCustomerSelected,
                            message: 'Обязательное поле!',
                          },
                        ]}
                      >
                        <Select
                          getPopupContainer={(trigger) =>
                            isMobile ? trigger.parentNode : document.body
                          }
                          className={'storage-customer-select'}
                          width={'100%'}
                          filterOption={true}
                          showSearch
                          //disabled={contactPersonSelectedCustomer?.length <= 0}
                          placeholder={'Контактное лицо'}
                        >
                          {contactPersonSelectedCustomer?.length > 0 ? (
                            <>
                              {contactPersonSelectedCustomer.map(
                                (contactPerson, index) => (
                                  <Option key={index} value={contactPerson?.id}>
                                    {`${contactPerson?.full_name} | ${
                                      contactPerson?.phone_number ||
                                      contactPerson?.email ||
                                      'телефон или почта не указаны'
                                    }`}
                                  </Option>
                                ),
                              )}
                            </>
                          ) : (
                            <Option disabled={true} value={null}>
                              Нет доступных вариантов
                            </Option>
                          )}
                        </Select>
                      </Form.Item>
                      <Tooltip title={'Перейти к контактам контрагента'}>
                        <Button
                          onClick={() =>
                            props.openModalEditContactPersonCustomer(
                              storageCustomerSelected
                                ? props.currentStockCustomer?.id
                                : props.customerId,
                            )
                          }
                          className={'additional-btn-select'}
                          autoFocus={false}
                          type="primary"
                          disabled={
                            storageCustomerSelected
                              ? !props.currentStockCustomer?.id
                              : !props.customerId
                          }
                          icon={<EditOutlined />}
                        />
                      </Tooltip>
                    </div>
                  </Form.Item>
                </Col>
              ) : (
                <></>
              )}
            </Row>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};
AddTransportationModal.propTypes = {
  visible: PropTypes.bool,
  editingItem: PropTypes.any,
  customerId: PropTypes.number,
  clientHasCategorySkl: PropTypes.bool,
  loading: PropTypes.bool,
  customerInStock: PropTypes.any,
  currentStockCustomer: PropTypes.any,
  onCloseModal: PropTypes.func,
  zIndexModal: PropTypes.number,
  customerContactPerson: PropTypes.any,
  callSearchCustomerInStockList: PropTypes.func,
  getCustomerInStockList: PropTypes.func,
  addTransportation: PropTypes.func,
  editTransportation: PropTypes.func,
  addCustomerToListOptionsSelect: PropTypes.func,
  openModalCreateStockCustomer: PropTypes.func,
  openModalEditContactPersonCustomer: PropTypes.func,
  getCustomerStockDataById: PropTypes.func,
  clearCurrentStockCustomer: PropTypes.func,
};

export default AddTransportationModal;
