import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import ContractStepTable from './ContractStepTable';
import { Box } from '@material-ui/core';
import { convertObjFilterToUrl } from '../../../../utils/getFilters';
import { restApiHost } from '../../../../utils/appVariables';
import { call } from '../../../../apiUtils/call';
import { Button as ButtonAntd, Button, Col, message, Row, Tooltip } from 'antd';
import { userInformations } from '../../../../contex';
import * as _ from 'lodash';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import { FilterOutlined, ReloadOutlined } from '@ant-design/icons';
import { ErrorBoundary } from '../../../ErrorBoundary';
import useCheckMobileScreen from '../../../../hooks/useCheckMobileScreen';

const ContractStep = ({ customerId, renderAfterCreationContract }) => {
  const { userInfo } = useContext(userInformations);
  const [contractList, setContractList] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [totalContracts, setTotalContracts] = useState(false);
  const [visibleFilters, setVisibleFilters] = useState(false);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState([]);
  const [typeChoices, setTypeChoices] = useState([]);
  const [signerCustomers, setSignerCustomers] = useState([]);
  const [signerManagers, setSignerManagers] = useState([]);
  const [allClients, setAllClients] = useState([]);
  const [visibleCreateContractModal, setVisibleCreateContractModal] =
    useState(false);
  const [thisCreationContract, setThisCreationContract] = useState(false);
  const scrollingLoadedListRef = useRef(null);
  const isMobile = useCheckMobileScreen();

  useEffect(async () => {
    await getDataContractUseDefauldFilters(customerId);
    await getChoices();
    await getSignerCustomers(customerId);
  }, []);

  useEffect(async () => {
    if (renderAfterCreationContract) {
      await getDataContractUseDefauldFilters(customerId);
      await getChoices();
      await getSignerCustomers(customerId);
    }
  }, [renderAfterCreationContract]);

  const getDataContractUseDefauldFilters = async (customerId) => {
    const filters = generateDefaultFilters(customerId);
    await handleUpdate(1, filters);
  };

  const generateDefaultFilters = (customerId) => {
    return {
      filter: {
        customer: customerId ? customerId.toString() : null,
      },
      order: {},
    };
  };

  const getCustomerContracts = async (
    page = 1,
    params,
    me,
    customerId,
    concatData,
  ) => {
    setLoadingData(true);

    let urlParams = ``;

    if (params) {
      urlParams = convertObjFilterToUrl(params, null);
    }
    try {
      const url = urlParams
        ? `${restApiHost}/customers/contracts?page=${page}${urlParams}`
        : `${restApiHost}/customers/contracts?page=${page}`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
        true,
      );
      if (json) {
        concatData
          ? setContractList((prevState) => prevState.concat(json?.data))
          : setContractList(json?.data ? json.data : []);
        setTotalContracts(json?.count);
      }
    } catch (e) {
      console.log(e);
      message.error('Ошибка получения договоров');
    } finally {
      setLoadingData(false);
    }
  };

  const handleUpdate = useCallback(
    async (page = 1, params, me, concatData = false) => {
      setPage(page);
      setFilters(params);
      await getCustomerContracts(page, params, me, customerId, concatData);
    },
    [customerId, setPage, setFilters],
  );

  const getChoices = async () => {
    try {
      const url = `${restApiHost}/customers/type_choices/`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );
      if (json?.data) {
        setTypeChoices(json.data);
      }
    } catch (e) {
      console.log('get choices err', e);
    }
  };
  const getSignerCustomers = async (id) => {
    try {
      const url = `${restApiHost}/customers/filter_our_customer/?customer=${id}`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!json?.data) return;

      const clients = _.groupBy(
        json?.data?.clients,
        (contact) => contact.customer_legal_name,
      );
      const our = _.groupBy(
        json.data?.our,
        (contact) => contact.customer_legal_name,
      );
      const all = _.groupBy(
        json.data?.all_clients,
        (contact) => contact.customer_legal_name,
      );

      setSignerCustomers(clients);
      setSignerManagers(our);
      setAllClients(all);
    } catch (e) {
      console.log('get staff err', e);
    }
  };

  const openCreateContractModal = (visible, isCreation) => {
    if (isCreation) {
      setThisCreationContract(isCreation);
    } else {
      setThisCreationContract(false);
    }
    setVisibleCreateContractModal(visible);
  };

  const handleScrollToTopMobileList = useCallback(() => {
    if (scrollingLoadedListRef.current?.childNodes[0]?.childNodes[0]) {
      scrollingLoadedListRef.current.childNodes[0].childNodes[0].scrollTop = 0;
    }
  }, [scrollingLoadedListRef]);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
      }}
    >
      <div className={'top-table-actions'}>
        <Row justify={'space-between'} style={{ width: '100%' }}>
          <Col>
            <Tooltip placement={'topLeft'} title={'Создать договор'}>
              <Button
                size={'small'}
                icon={<PlusOutlined />}
                type={'primary'}
                onClick={() => openCreateContractModal(true, true)}
              />
            </Tooltip>
          </Col>
          <Col>
            <Tooltip title={'Обновить'}>
              <ButtonAntd
                onClick={async () => {
                  isMobile && handleScrollToTopMobileList();
                  await handleUpdate(isMobile ? 1 : page, filters);
                }}
                style={{ marginLeft: '10px' }}
                type="text"
                icon={<ReloadOutlined />}
              />
            </Tooltip>
            <Tooltip title={'Отобразить фильтры'}>
              <ButtonAntd
                style={{ margin: '0 10px' }}
                disabled={loadingData}
                onClick={() => {
                  isMobile && handleScrollToTopMobileList();
                  setVisibleFilters(!visibleFilters);
                }}
                type="text"
                icon={<FilterOutlined />}
              />
            </Tooltip>
          </Col>
        </Row>
      </div>
      <ErrorBoundary>
        <ContractStepTable
          page={page}
          handleUpdate={handleUpdate}
          customerId={customerId}
          contractsList={contractList}
          loading={loadingData}
          visibleFilters={visibleFilters}
          tableFilters={filters}
          total={totalContracts}
          setVisibleFilters={(visible) => {
            setVisibleFilters(visible);
          }}
          updatePage={(page) => setPage(page)}
          typeChoices={typeChoices}
          scrollingLoadedListRef={scrollingLoadedListRef}
          handleScrollToTopMobileList={handleScrollToTopMobileList}
          signerManagers={signerManagers}
          signerCustomers={signerCustomers}
          allClients={allClients}
          getSignerCustomers={async (customerId) =>
            await getSignerCustomers(customerId)
          }
          visibleCreateDocModal={visibleCreateContractModal}
          changeVisibleCreateContractModal={(visible, isCreation) =>
            openCreateContractModal(visible, isCreation)
          }
          thisCreationContract={thisCreationContract}
          setThisCreationContract={(val) => setThisCreationContract(val)}
          defaultFilter={[{ name: 'customer', value: customerId }]}
        />
      </ErrorBoundary>
    </Box>
  );
};

export default ContractStep;
