import { Avatar, Col, Form, List, message, Row, Select } from 'antd';
import React, { useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { UserOutlined } from '@ant-design/icons';
import useAsyncEffect from 'use-async-effect';
import { getCustomerGroupCompanies } from '../../../../logic/customer/CustomerApiFunctions';
import { userInformations } from '../../../../contex';
import { restApiHost } from '../../../../utils/appVariables';
import { call } from '../../../../apiUtils/call';
import useCheckMobileScreen from '../../../../hooks/useCheckMobileScreen';

const { Option } = Select;

const CustomerGroupCompany = (props) => {
  const { userInfo } = useContext(userInformations);
  const [selectedValue, setSelectedValue] = useState(null);
  const [currSubsidiaryList, setCurrSubsidiaryList] = useState([]);
  const [joinGroupOptions, setJoinGroupOptions] = useState([]);
  const isMobile = useCheckMobileScreen();

  useAsyncEffect(async () => {
    props.customerId && (await handleGetGroupCompanies(props.customerId));
  }, [props.customerId]);

  const handleGetGroupCompanies = useCallback(
    async (customerId) => {
      const res = await getCustomerGroupCompanies(customerId, userInfo);
      if (res?.data && typeof res?.data !== 'string') {
        setCurrSubsidiaryList(res.data);
      }
    },
    [setCurrSubsidiaryList, getCustomerGroupCompanies],
  );

  const searchCustomers = useCallback(
    async (keyword) => {
      try {
        const url = `${restApiHost}/customers/list/?search=${keyword}`;
        const json = await call(
          url,
          {
            method: 'GET',
            headers: {
              Authorization: `Token ${localStorage.getItem('token')}`,
            },
          },
          userInfo.current.haveViewBranch,
        );

        if (json) {
          let filteredOptions = json?.data.filter(
            (item) => item.id !== props.customerId,
          );

          const selectedSubsidiary =
            currSubsidiaryList.length > 0 &&
            currSubsidiaryList.map((item) => item.subsidiary.id);

          selectedSubsidiary.length > 0 &&
            selectedSubsidiary.map((value) => {
              filteredOptions = filteredOptions.filter(
                (item) => item.id !== value,
              );
            });

          setJoinGroupOptions(filteredOptions);
        }
      } catch (e) {
        console.log('search clients err', e);
        message.error('Ошибка запроса для поиска клиента');
      }
    },
    [props.customerId, currSubsidiaryList, setJoinGroupOptions],
  );

  const onSearchCompany = useCallback(
    async (company) => {
      if (company.length > 0) {
        await searchCustomers(company);
      } else {
        let filteredOptions = joinGroupOptions.filter(
          (item) => item.id !== props.customerId,
        );

        const selectedSubsidiary =
          props.currSubsidiaryList.length > 0 &&
          props.currSubsidiaryList.map((item) => item.subsidiary.id);

        selectedSubsidiary.length > 0 &&
          selectedSubsidiary.map((value) => {
            filteredOptions = filteredOptions.filter(
              (item) => item.id !== value,
            );
          });
        setJoinGroupOptions(filteredOptions);
      }
    },
    [
      searchCustomers,
      joinGroupOptions,
      props.currSubsidiaryList,
      setJoinGroupOptions,
    ],
  );

  const addSubsidiary = useCallback(
    async (body) => {
      try {
        const url = `${restApiHost}/customers/group_customer/`;
        const json = await call(
          url,
          {
            method: 'POST',
            headers: {
              Authorization: `Token ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
          },
          userInfo.current.haveViewBranch,
        );
        if (!json) return;

        message.success('Успешно добавлено');
        props.customerId && (await handleGetGroupCompanies(props.customerId));
      } catch (e) {
        console.log('add subsidiart err', e);
      }
    },
    [props.customerId, handleGetGroupCompanies],
  );

  const onChangeCompany = useCallback(
    async (value) => {
      await addSubsidiary({
        parent: props.customerId,
        subsidiary: value,
      });
      setSelectedValue(null);
    },
    [addSubsidiary, setSelectedValue],
  );

  const deleteSubsidiary = useCallback(
    async (id) => {
      try {
        const url = `${restApiHost}/customers/group_customer/${id}/`;
        const response = await call(
          url,
          {
            method: 'DELETE',
            headers: {
              Authorization: `Token ${localStorage.getItem('token')}`,
            },
          },
          userInfo.current.haveViewBranch,
        );

        if (!response) return;

        props.customerId && (await handleGetGroupCompanies(props.customerId));

        message.success('Успешно удалено');
      } catch (e) {
        console.log('delete subsidiary err', e);
        message.error('Ошибка запроса на удаление дочерней компании');
      }
    },
    [props.customerId, handleGetGroupCompanies],
  );

  const renderItemCompanyGroupList = (item) => {
    return (
      <List.Item
        actions={[
          <a
            key="list-delete"
            onClick={async (e) => {
              e.preventDefault();
              await deleteSubsidiary(item.id);
            }}
          >
            Удалить
          </a>,
        ]}
      >
        <List.Item.Meta
          avatar={<Avatar icon={<UserOutlined />} />}
          title={
            item?.subsidiary && item.subsidiary.legal_name
              ? item.subsidiary.legal_name
              : 'Не указано'
          }
          description={
            <div>
              <div>
                <b>Полное наименование: </b>{' '}
                {item?.subsidiary && item.subsidiary.legal_name
                  ? item.subsidiary.legal_name
                  : 'Не указано'}
              </div>
              <div>
                <b>Сокращенное наименование: </b>{' '}
                {item?.subsidiary && item.subsidiary.short_name
                  ? item.subsidiary.short_name
                  : 'Не указано'}
              </div>
              <div>
                <b>Email: </b>
                {item?.subsidiary && item.subsidiary.email
                  ? item.subsidiary.email
                  : 'Не указано'}
              </div>
              <div>
                <b>Телефон: </b>{' '}
                {item?.subsidiary &&
                item?.subsidiary.phone_numbers &&
                item?.subsidiary.phone_numbers.length > 0
                  ? item.subsidiary.phone_numbers[0].phone_number
                  : 'Не указано'}
              </div>
            </div>
          }
        />
      </List.Item>
    );
  };

  return (
    <>
      <Form form={props.form} layout="vertical">
        <Row gutter={16}>
          <Col xs={24} lg={24}>
            <Form.Item label={'Компания'}>
              <Select
                getPopupContainer={(trigger) =>
                  isMobile ? trigger.parentNode : document.body
                }
                value={selectedValue}
                placeholder="Начните поиск или выберите из списка"
                showSearch
                filterOption={false}
                onSearch={async (e) => {
                  await onSearchCompany(e);
                }}
                onChange={async (value) => await onChangeCompany(value)}
              >
                {joinGroupOptions.length > 0 ? (
                  <>
                    {joinGroupOptions.map((company) => {
                      return (
                        <Option
                          key={company.id}
                          data-value={JSON.stringify(company)}
                          value={company.id}
                        >
                          {company.short_name
                            ? company.short_name
                            : company.legal_name
                            ? company.legal_name
                            : company.email}
                        </Option>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <h4 style={{ marignTop: '20px' }}>Список групп компании</h4>
      <List
        className="demo-loadmore-list"
        itemLayout="horizontal"
        dataSource={currSubsidiaryList}
        renderItem={(item) => renderItemCompanyGroupList(item)}
      />
    </>
  );
};

CustomerGroupCompany.propTypes = {
  form: PropTypes.any,
  customerId: PropTypes.number,
  joinGroupOptions: PropTypes.any,
  currSubsidiaryList: PropTypes.any,
};

export default CustomerGroupCompany;
