import moment from 'moment/moment';
import { isCreatorTask } from './filtersTasksByTimePeriod';
import dayjs from 'dayjs';
dayjs.locale('ru');
moment.locale('ru');

export default function filtersTasksByDay(day, tasks, useGroupCallLead) {
  const dayDate = day.format('YYYY-MM-DD');
  const tasksOnThisDay = useGroupCallLead
    ? tasks.filter(
        (item) =>
          moment(item.end).format('YYYY-MM-DD') === dayDate &&
          !item?.lead &&
          !item?.customer &&
          !item?.group,
      )
    : tasks.filter(
        (item) =>
          moment(item.end).format('YYYY-MM-DD') === dayDate && !item?.group,
      );
  return tasksOnThisDay.sort(
    (a, b) => b.priority - a.priority || new Date(a.end) - new Date(b.end),
  );
}

export function filtersTasksByDayIncludeLeadId(day, tasks) {
  const dayDate = day.format('YYYY-MM-DD');
  const tasksOnThisDay = tasks.filter(
    (item) =>
      moment(item.end).format('YYYY-MM-DD') === dayDate &&
      (item?.lead || item?.customer),
  );
  return tasksOnThisDay.sort(
    (a, b) => b.priority - a.priority || new Date(a.end) - new Date(b.end),
  );
}

export function filtersTasksByTimePeriodAndGroupToPersonallyCommon(
  day,
  tasks,
  useGroupCallLead,
  currentUserId,
) {
  if (!tasks) return [];
  const dayDate = day.format('YYYY-MM-DD');
  const tasksOnThisDay = useGroupCallLead
    ? tasks.filter(
        (item) =>
          moment(item.end).format('YYYY-MM-DD') === dayDate &&
          !item?.lead &&
          !item?.customer &&
          item?.group !== null,
      )
    : tasks
        .filter(
          (item) =>
            moment(item.end).format('YYYY-MM-DD') === dayDate &&
            item?.group !== null,
        )
        .sort(
          (a, b) =>
            b.priority - a.priority || new Date(a.end) - new Date(b.end),
        );

  const tasksToGroup = [];
  const classicTasks = [];
  for (const task of tasksOnThisDay) {
    if (task?.members?.length > 0) {
      if (isCreatorTask(task, currentUserId)) {
        tasksToGroup.push(task);
      } else {
        classicTasks.push(task);
      }
    }
  }
  let outputGroupTask = [];

  if (tasksToGroup?.length > 0) {
    outputGroupTask = _.mapValues(_.groupBy(tasksToGroup, (i) => i?.group));
  }

  return classicTasks.concat(Object.entries(outputGroupTask));
}

export function mergedClassicAndGroupPersonallyCommonTask(
  tasks,
  timeStart,
  timeEnd,
  selectedStaffId,
  userInfoId,
) {
  if (!tasks) return [];
  const currentUserId = selectedStaffId ? selectedStaffId : userInfoId;
  const classicTasks = filtersTasksByDay(tasks, timeStart, timeEnd);
  const groupTaskToPersonallyCommon =
    filtersTasksByTimePeriodAndGroupToPersonallyCommon(
      tasks,
      timeStart,
      false,
      currentUserId,
    );
  return classicTasks.concat(groupTaskToPersonallyCommon);
}
