import { restApiHost } from '../../../utils/appVariables';
import { call } from '../../../apiUtils/call';
import { message } from 'antd';

export async function getCustomerById(customerId, userInfo) {
  try {
    const url = `${restApiHost}/customers/list/${customerId}/`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (json?.data) {
      return json.data;
    }
  } catch (e) {
    console.log('get customer err', e);
    message.error('Ошибка запроса на получение клиента');
  }
}
