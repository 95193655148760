import PropTypes from 'prop-types';
import { Card, Col, Divider, Dropdown, Row, Space } from 'antd';
import React, { useCallback } from 'react';
import './leadListItem.css';
import dayjs from 'dayjs';
import EllipsisOutlined from '@ant-design/icons/lib/icons/EllipsisOutlined';

const LeadListItem = (props) => {
  const cardTitle = useCallback(() => {
    return (
      <>
        <Row justify={'space-between'} wrap={false}>
          <Col>
            <Col className={'mainText'}>{props.lead?.company_name || '-'}</Col>
            <Col>{`+${props.lead?.phone_number}` || '-'}</Col>
          </Col>
          <Row justify={'end'}>
            <Space size="middle" align={'start'}>
              <Dropdown
                overlay={props.menuActions(props.lead)}
                trigger={['click']}
              >
                <EllipsisOutlined
                  style={{ cursor: 'pointer', fontSize: '24px' }}
                />
              </Dropdown>
            </Space>
          </Row>
        </Row>
      </>
    );
  }, [props.lead]);

  return (
    <>
      <Card className={'mainCard'} bodyStyle={{ padding: '12px' }}>
        {cardTitle()}
        <Divider style={{ margin: '10px 0' }} />

        <Row gutter={25}>
          <Col>
            <div className={'secondText'}>{'Цвет'}</div>
            <div
              className={'mainText colorBlock'}
              style={{
                background: props.lead?.color_str
                  ? `rgb(${props.lead?.color_str})`
                  : '#e5e5e5',
              }}
            />
          </Col>

          <Col>
            <div className={'secondText'}>{'ABC'}</div>
            <div className={'valueText'}>{props.lead?.abc_str || '-'}</div>
          </Col>

          <Col>
            <div className={'secondText'}>{'XYZ'}</div>
            <div className={'valueText'}>{props.lead?.xyz_str || '-'}</div>
          </Col>

          <Col>
            <div className={'secondText'}>{'Мессенджер'}</div>
            <div className={'valueText'}>
              {props.lead?.messenger_str || '-'}
            </div>
          </Col>

          <Col>
            <div className={'secondText'}>{'Итог звонка'}</div>
            <div className={'valueText'}>
              {props.lead?.call_result?.result || '-'}
            </div>
          </Col>
        </Row>

        <Row gutter={25}>
          <Col>
            <div className={'secondText'}>{'Посл.обращение'}</div>
            <div className={'valueText'}>
              {props.lead?.date_recent_request !== null
                ? dayjs(props.lead?.date_recent_request).format(
                    'DD.MM.YYYY HH:mm',
                  )
                : '-'}
            </div>
          </Col>

          <Col>
            <div className={'secondText'}>{'След.обращение'}</div>
            <div className={'valueText'}>
              {(props.lead?.date_next_request !== null &&
                dayjs(props.lead?.date_next_request).format(
                  'DD.MM.YYYY HH:mm',
                )) ||
                '-'}
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};

LeadListItem.propTypes = {
  lead: PropTypes.object,
  menuActions: PropTypes.func,
};

export default LeadListItem;
