import { Col, Row } from 'antd';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import TotalSumsGroupInput from './totalSumsField/TotalSumsGroupInput';

const PreApplicationModalFormFieldsSums = (props) => {
  const notZeroValueField = useCallback((objSum) => {
    if (!objSum) return false;
    let notZero = false;
    for (const [, value] of Object.entries(objSum)) {
      if (value !== 0) {
        notZero = true;
      }
    }
    return notZero;
  }, []);

  return (
    <>
      <Col lg={8} xs={24}>
        <Row gutter={5} className={'reset-margin-form'}>
          <TotalSumsGroupInput
            totalSumsObj={props.totalSumsClient}
            groupTitle={'Клиенту'}
            notZeroValueField={(sums) => notZeroValueField(sums)}
          />

          <TotalSumsGroupInput
            totalSumsObj={props.totalSumsUpfront}
            groupTitle={'Расходы'}
            notZeroValueField={(sums) => notZeroValueField(sums)}
          />

          <TotalSumsGroupInput
            totalSumsObj={props.totalDelta}
            groupTitle={'Дельта'}
            notZeroValueField={(sums) => notZeroValueField(sums)}
          />
        </Row>
      </Col>
    </>
  );
};

PreApplicationModalFormFieldsSums.propTypes = {
  totalSumsClient: PropTypes.object,
  totalSumsUpfront: PropTypes.object,
  totalDelta: PropTypes.object,
};

export default React.memo(PreApplicationModalFormFieldsSums);
