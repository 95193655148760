export function formationOfShoulderFromPoints(preTasks) {
  const preTaskForTableSum = [];
  console.log(preTasks, 'formationOfShoulderFromPoints preTasks');
  if (preTasks !== undefined) {
    preTasks.forEach((preTask) => {
      const shoulderPars = [];
      const sortWaypointsPreTask = preTask?.waypoint.sort((a, b) =>
        a['number'] > b['number'] ? 1 : -1,
      );
      for (let i = 0; i <= sortWaypointsPreTask.length; i++) {
        if (sortWaypointsPreTask[i]?.waypoint?.operation?.type === 'other') {
          continue;
        }
        const waypoint = sortWaypointsPreTask[i];
        if (preTask?.template?.short === 'ПР') {
          if (waypoint?.operation?.type !== 'in') {
            continue;
          }
        } else {
          if (waypoint?.operation?.type !== 'out') {
            continue;
          }
        }
        if (sortWaypointsPreTask[i + 1] !== undefined) {
          const nextWaypoint = sortWaypointsPreTask[i + 1];
          if (preTask?.template?.short === 'ПР') {
            if (
              nextWaypoint.operation.type !== 'out' ||
              nextWaypoint?.operation?.type_transport !== 'Авто'
            ) {
              continue;
            }
          } else {
            if (nextWaypoint.operation.type !== 'in') {
              continue;
            }
          }
          const transportTask = [];
          // eslint-disable-next-line no-unsafe-optional-chaining
          for (const transportCargo of preTask?.transport_cargo_task) {
            transportTask.push({
              typeShort:
                transportCargo?.transport?.transport_unit?.type_transport,
            });
          }
          if (
            preTask?.template?.short === 'ИП' ||
            preTask?.template?.short === 'ОП-Э'
          ) {
            if (preTask?.sums) {
              for (const sum of preTask.sums) {
                if (!sum?.custom) {
                  if (
                    waypoint.id === sum?.operation_out?.id &&
                    nextWaypoint?.id === sum.operation_in?.id
                  ) {
                    const operationParInSum = {
                      operationOut: sum.operation_out.id,
                      operationIn: sum.operation_in.id,
                      type_transport:
                        sum.operation_out.operation.type_transport,
                      shoulderName: formationShoulderNameForSums(
                        sum?.operation_out,
                        sum?.operation_in,
                      ),
                      out: sum.operation_out,
                      in: sum.operation_in,
                      currency: sum.currency,
                      vat_rate: sum.vat_rate,
                      price: sum.price,
                      inSum: true,
                      sumsId: sum?.id,
                      transportTask: transportTask,
                    };

                    shoulderPars.push(operationParInSum);
                  }
                }
              }
            }
          } else {
            const operationPar = {
              operationOut: waypoint.id,
              operationIn: nextWaypoint.id,
              type_transport: waypoint?.operation?.type_transport,
              shoulderName: formationShoulderNameForWaypoints(
                preTask?.template?.short,
                waypoint,
                nextWaypoint,
              ),
              out: waypoint?.operation,
              in: nextWaypoint?.operation,
              inSum: false,
              price: preTask?.price,
              vat_rate: preTask?.vat_rate,
              transportTask: transportTask,
            };
            shoulderPars.push(operationPar);
          }
        }
      }
      if (
        preTask?.template?.short === 'ИП' ||
        preTask?.template?.short === 'ОП-Э'
      ) {
        const updateWithCustomSumsShoulderPars = addShoulderFromCustomSum(
          shoulderPars,
          preTask?.sums,
        );
        preTaskForTableSum.push({
          id: preTask.id,
          shoulderPars: updateWithCustomSumsShoulderPars,
        });
      } else {
        preTaskForTableSum.push({
          id: preTask.id,
          shoulderPars,
        });
      }
    });
    console.log(preTaskForTableSum);
    return preTaskForTableSum.filter((item) => item.shoulderPars?.length > 0);
  }
}

const addShoulderFromCustomSum = (shoulderPars, dataSums) => {
  if (dataSums?.length > 0) {
    for (const sum of dataSums) {
      if (sum?.custom) {
        const operationParInSum = {
          shoulderName: sum?.name ? sum.name : 'Без названия',
          currency: sum.currency,
          vat_rate: sum.vat_rate,
          price: sum?.price ? sum?.price : 0,
          inSum: true,
          isCustom: true,
          sumsId: sum?.id,
        };
        shoulderPars.push(operationParInSum);
      }
    }
  }
  return shoulderPars;
};

const formationShoulderNameForWaypoints = (
  preTaskTemplateShortName,
  waypoint,
  nextWaypoint,
) => {
  if (preTaskTemplateShortName === 'ПР') {
    return (
      (waypoint?.waypoint?.name ? waypoint.waypoint.name : 'Без названия') +
      '-' +
      (nextWaypoint?.address ? nextWaypoint.address : 'Без названия')
    );
  }

  return (
    (waypoint?.waypoint?.name || waypoint?.address
      ? waypoint?.waypoint?.name || waypoint?.address
      : 'Без названия') +
    '-' +
    (nextWaypoint?.waypoint?.name || nextWaypoint?.address
      ? nextWaypoint?.waypoint?.name || nextWaypoint?.address
      : 'Без названия')
  );
};

const formationShoulderNameForSums = (operationOut, operationIn) => {
  return (
    (operationOut?.waypoint?.name
      ? operationOut?.waypoint?.name
      : 'Без названия') +
    '-' +
    (operationIn?.waypoint?.name ? operationIn?.waypoint?.name : 'Без названия')
  );
};
