import PropTypes from 'prop-types';
import React, { useCallback, useContext, useState } from 'react';
import { userInformations } from '../../../../../../../contex';
import { Button, Space, Tooltip } from 'antd';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import { ErrorBoundary } from '../../../../../../ErrorBoundary';
import TransportationTable from './TransportationTable';
import { getShippingTaskTransportationList } from '../../../../../../../logic/task/TaskApiFunctions';
import useAsyncEffect from 'use-async-effect';
import TransportationModal from './modal/TransportationModal';
import useStateRef from 'react-usestateref';
import { FilterOutlined } from '@ant-design/icons';
import useCheckMobileScreen from '../../../../../../../hooks/useCheckMobileScreen';

const TransportationTab = (props) => {
  const { userInfo } = useContext(userInformations);
  const [transportationDataList, setTransportationDataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [transportFormValue, setTransportFormValue] = useState(null);
  const [, setVisibleFilters, visibleFiltersRef] = useStateRef(false);
  const [, setTableFilters, tableFiltersRef] = useStateRef(null);
  const isMobile = useCheckMobileScreen();

  useAsyncEffect(async () => {
    await handleGetTransportationList();
  }, []);

  useAsyncEffect(async () => {
    if (!visibleFiltersRef.current && !isMobile) {
      await handleGetTransportationList(null);
    }
  }, [visibleFiltersRef.current, isMobile]);

  const handleAddTransportation = useCallback(() => {
    setVisibleModal(true);
  }, [setVisibleModal]);

  const handleGetTransportationList = useCallback(
    async (params = null) => {
      try {
        setLoading(true);
        setTableFilters(params);
        const res = await getShippingTaskTransportationList(
          props.taskData?.id,
          params,
          userInfo,
        );
        setTransportationDataList(res ?? []);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    [setTransportationDataList, setLoading],
  );

  const handleCloseModal = useCallback(async () => {
    setVisibleModal(false);
    setTransportFormValue(null);
    await handleGetTransportationList(tableFiltersRef.current);
  }, [setVisibleModal, tableFiltersRef.current]);

  return (
    <>
      <div className={'top-table-actions'}>
        <Space>
          <Tooltip placement={'topLeft'} title={'Добавить'}>
            <Button
              size={'small'}
              icon={<PlusOutlined />}
              onClick={() => handleAddTransportation()}
              type="primary"
            />
          </Tooltip>
          <Tooltip placement={'topLeft'} title={'Отобразить фильтры'}>
            <Button
              size={'small'}
              type="primary"
              icon={<FilterOutlined />}
              onClick={() => {
                setVisibleFilters(!visibleFiltersRef.current);
              }}
            />
          </Tooltip>
        </Space>
      </div>
      <ErrorBoundary>
        <TransportationTable
          taskData={props.taskData}
          transportationDataList={transportationDataList}
          loading={loading}
          visibleFilters={visibleFiltersRef.current}
          setVisibleFilters={(val) => {
            setVisibleFilters(val);
          }}
          tableFilters={tableFiltersRef.current}
          taskEdit={props.taskEdit}
          handleUpdateList={async (tableFilters) => {
            await handleGetTransportationList(tableFilters);
          }}
        />
      </ErrorBoundary>

      <ErrorBoundary>
        <TransportationModal
          visible={visibleModal}
          taskData={props.taskData}
          //В данном случаи props editingTransport нужен для сохранение данных о Транспорте при переключении шагов
          editingTransport={transportFormValue}
          updateValueEditingTransport={(value) => setTransportFormValue(value)}
          forCreate={true}
          closeModal={async () => {
            await handleCloseModal();
          }}
          taskEdit={props.taskEdit}
        />
      </ErrorBoundary>
    </>
  );
};

export default TransportationTab;

TransportationTab.propTypes = {
  taskData: PropTypes.object.isRequired,
  taskEdit: PropTypes.func.isRequired,
};
