import React, { useState, useContext, useCallback } from 'react';
import {
  Table,
  Popconfirm,
  Menu,
  Space,
  Dropdown,
  Form,
  message,
  Button,
  Tooltip,
} from 'antd';

import 'moment/locale/ru';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import EllipsisOutlined from '@ant-design/icons/lib/icons/EllipsisOutlined';
import { restApiHost } from '../../utils/appVariables';
import { dateStrRevers } from '../../utils/dateStrRevers';
import { userInformations } from '../../contex';
import { call } from '../../apiUtils/call';
import { getPreTaskWaypoints } from '../../logic/preTasks/PreTaskApiFunctions';
import useAsyncEffect from 'use-async-effect';
import { ErrorBoundary } from '../ErrorBoundary';
import WayPointModal from '../pre-applications/preApplicationModal/wayPoints/WayPointModal';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import ScrollingLoadedList from '../mobile/scrollingLoadedList/ScrollingLoadedList';
import WayPointsListItem from '../mobile/preTasks/modal/WayPointsListItem';

const WayPointsTableUpdated = ({
  taskId,
  //data,
  //setData,
  //searchWaypoints,
  taskTemplete,
}) => {
  const [form] = Form.useForm();
  const [currWaypoint, setCurrWaypoint] = useState(null);
  const [, setDateWayPoint] = useState(null);
  const [, setSelectedAddress] = useState(null);
  const [isOperationAuto, setIsOperationAuto] = useState(false);
  const [waypointsList, setWaypointsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const { userInfo } = useContext(userInformations);
  const isMobile = useCheckMobileScreen();

  useAsyncEffect(async () => {
    if (taskId) {
      await callGetWaypoints(taskId);
    }
  }, [taskId, taskTemplete]);

  const callGetWaypoints = useCallback(
    async (taskId) => {
      setLoading(true);
      const res = await getPreTaskWaypoints(taskId, userInfo);
      if (res?.data) {
        setWaypointsList(res.data);
      }
      setLoading(false);
    },
    [getPreTaskWaypoints, setWaypointsList, setLoading],
  );

  function menu(waypoint) {
    return (
      <Menu style={{ width: 200 }}>
        <Menu.Item
          icon={<EditOutlined />}
          onClick={() => {
            handleEditWayPoint(waypoint);
          }}
        >
          Редактировать
        </Menu.Item>
        <Popconfirm
          title="Вы действительно хотите удалить запись?"
          onConfirm={() => deleteWayPoint(waypoint.id)}
          onCancel={() => console.log('Delete item')}
          okText="Да"
          cancelText="Нет"
          placement="rightBottom"
        >
          <Menu.Item icon={<DeleteOutlined />}>Удалить</Menu.Item>
        </Popconfirm>
      </Menu>
    );
  }

  const columns = [
    {
      title: '',
      key: 'action',
      width: 10,
      align: 'center',
      render: (waypoint) => (
        <Space size="middle">
          <Dropdown overlay={menu(waypoint)} trigger={['hover']}>
            <EllipsisOutlined style={{ cursor: 'pointer', fontSize: '24px' }} />
          </Dropdown>
        </Space>
      ),
    },
    {
      title: '№',
      key: 'number',
      width: 10,
      dataIndex: 'number',
      render: (number) => <Tooltip title={number}>{number}</Tooltip>,
    },
    {
      title: 'Операция',
      dataIndex: 'operation',
      key: 'operation',
      width: 60,
      ellipsis: {
        showTitle: false,
      },
      render: (operation) =>
        operation?.name ? (
          <Tooltip title={operation?.name} placement={'topLeft'}>
            {operation?.name}
          </Tooltip>
        ) : (
          ''
        ),
    },
    {
      title: 'Пункт',
      width: 60,
      dataIndex: 'waypoint',
      key: 'waypoint',
      render: (waypoint) =>
        waypoint ? `${waypoint.name} ${waypoint.code}` : '',
    },
    {
      title: 'Адрес',
      width: 100,
      dataIndex: 'address',
      key: 'address',
      render: (address) => (address ? `${address}` : ''),
    },
  ];

  const deleteWayPoint = useCallback(
    async (id) => {
      if (!id) return;
      try {
        const url = `${restApiHost}/leads/pre_waypoints/${id}/`;
        const response = await call(
          url,
          {
            method: 'DELETE',
            headers: {
              Authorization: `Token ${localStorage.getItem('token')}`,
            },
          },
          userInfo.current.haveViewBranch,
        );

        if (!response) return;
        message.success('Операция прошла успешно!');
        await callGetWaypoints(taskId);
      } catch (e) {
        console.log('delete waypoint err', e);
        message.error('Ошибка запроса на удаление пункта');
      }
    },
    [taskId, callGetWaypoints],
  );

  const handleOpenModal = useCallback(() => {
    setVisibleModal(true);
  }, [setVisibleModal]);

  const handleEditWayPoint = useCallback((waypoint) => {
    if (!waypoint) return;
    setCurrWaypoint(waypoint);

    form.setFieldsValue({
      ...waypoint,
      date: waypoint.date ? dateStrRevers(waypoint.date) : null,
      operation: waypoint.operation ? waypoint.operation.id : null,
      address: waypoint?.address ? waypoint?.address : '',
      waypoint: waypoint.waypoint ? waypoint.waypoint.name : null,
    });

    if (waypoint?.operation?.type_transport === 'Авто') {
      setIsOperationAuto(true);
    } else {
      setIsOperationAuto(false);
    }
    //waypoint.waypoint && searchWaypoints(waypoint.waypoint.name);
    setSelectedAddress(waypoint.waypoint);
    setDateWayPoint(waypoint.date);
    setVisibleModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setCurrWaypoint(null);
    setVisibleModal(false);
    setIsOperationAuto(false);
    form.resetFields();
  }, [setVisibleModal, setCurrWaypoint, setIsOperationAuto, form]);

  return (
    <>
      <div className={'top-table-actions'}>
        <Tooltip placement={'topLeft'} title={'Добавить пункт'}>
          <Button
            size={'small'}
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              handleOpenModal();
            }}
          />
        </Tooltip>
      </div>
      {!isMobile ? (
        <Table
          onRow={(waypoint) => {
            return {
              onDoubleClick: () => {
                handleEditWayPoint(waypoint);
              },
            };
          }}
          rowClassName={(record, index) =>
            index % 2 === 0
              ? 'black-text table-row-color'
              : 'black-text table-row-white'
          }
          size="small"
          bordered={true}
          loading={loading}
          columns={columns}
          dataSource={waypointsList}
          pagination={{ position: ['none'] }}
          scroll={{ x: 1350 }}
        />
      ) : (
        <>
          <div style={{ paddingTop: '5px' }}>
            <ErrorBoundary>
              <ScrollingLoadedList
                dataList={waypointsList}
                totalData={waypointsList?.length}
                loadMoreItems={async () => {
                  //handleUpdate(page + 1, filters, false,true)
                }}
                renderListItem={(item) => (
                  <WayPointsListItem
                    wayPoint={item}
                    menuActions={(wayPoint) => menu(wayPoint)}
                  />
                )}
              />
            </ErrorBoundary>
          </div>
        </>
      )}
      <ErrorBoundary>
        <WayPointModal
          visible={visibleModal}
          taskId={taskId}
          form={form}
          editingWayPoint={currWaypoint}
          isOperationAuto={isOperationAuto}
          waypointsList={waypointsList}
          setIsOperationAuto={(value) => {
            setIsOperationAuto(value);
          }}
          handleUpdateList={async () => {
            taskId && (await callGetWaypoints(taskId));
          }}
          handleCloseModal={() => {
            handleCloseModal();
          }}
        />
      </ErrorBoundary>
    </>
  );
};

export default WayPointsTableUpdated;
