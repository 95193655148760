export function generateImageFormBlob(blobFile, filename) {
  if (blobFile) {
    const file = new File([blobFile], filename ? filename : 'img', {
      type: blobFile?.type,
    });
    return {
      originFileObj: file,
    };
  }
}
