import { createMuiTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';

const theme = createMuiTheme({
  palette: {
    background: {
      default:
        'backgroundImage: "url(public/static/images/mainBackground.jpg)"',
      paper: colors.common.white,
    },
    primary: {
      contrastText: '#fff',
      main: '#0b7b7f',
    },
    text: {
      primary: '#172b4d',
      secondary: '#6b778c',
      white: colors.common.white,
    },
    secondary: {
      contrastText: colors.common.white,
      main: colors.common.white,
    },
  },
  shadows,
  typography,
});

export default theme;
