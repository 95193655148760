import { Card, CardContent } from '@material-ui/core';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const Sales = (props) => {
  //const theme = useTheme();

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Статистика успешных заявок',
      },
    },
  };

  const labels = props?.stat?.tasks?.dates
    ? props?.stat?.tasks?.dates.map((item) =>
        new Intl.DateTimeFormat('ru-RU', {
          day: 'numeric',
          month: 'long',
        }).format(new Date(item)),
      )
    : [];

  const dataSet = {
    labels,
    datasets: [
      {
        label: 'Заявки',
        data: props?.stat?.tasks?.success ? props.stat.tasks.success : [],
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Пред.заявки',
        data: props?.stat?.pre_tasks?.success
          ? props.stat.pre_tasks.success
          : [],
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };
  return (
    <Card {...props}>
      <CardContent
        sx={{
          position: 'relative',
          height: 500,
          maxHeight: 500,
        }}
      >
        <Bar
          data={dataSet}
          options={options}
          className={'Dashboard-stats-item-sales-chart'}
        />
      </CardContent>
    </Card>
  );
};

export default Sales;
