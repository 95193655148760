import PropTypes from 'prop-types';
import { Button, Form, message, Modal } from 'antd';
import { ErrorBoundary } from '../../../../../../../ErrorBoundary';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import CostModalForm from './CostModalForm';
import {
  addShippingTaskCost,
  editShippingTaskCost,
} from '../../../../../../../../logic/task/TaskApiFunctions';
import { userInformations } from '../../../../../../../../contex';

const CostModal = (props) => {
  const { userInfo } = useContext(userInformations);
  const [form] = Form.useForm();
  const [inCurrency, setInCurrency] = useState(false);

  useEffect(() => {
    if (props.editingCost) {
      handleSetFormFieldsValue(props.editingCost);
    }
  }, [props.editingCost]);

  const handleSetFormFieldsValue = (editingCost) => {
    if (!editingCost) return;
    form.setFieldsValue({
      ...editingCost,
      transport: editingCost?.transport,
      in_currency: editingCost?.in_currency,
      customer: editingCost?.customer?.id || null,
      operation: editingCost?.operation?.id || null,
      service: editingCost?.service?.id ?? null,
    });
  };

  const handleCloseModal = useCallback(() => {
    form.resetFields();
    props.closeModal && props.closeModal();
  }, [props.closeModal]);

  const handleCreateOrEditCost = useCallback(() => {
    const endpoint = props.apiEndpoint ?? null;
    form
      .validateFields()
      .then(async (values) => {
        let body = {
          ...values,
          in_currency: inCurrency,
          task: props.taskData?.id,
        };
        const res = props.editingCost?.id
          ? await editShippingTaskCost(
              props.editingCost?.id,
              body,
              endpoint,
              userInfo,
            )
          : await addShippingTaskCost(body, endpoint, userInfo);

        if (res) {
          message.success(
            props.editingWaypoint?.id
              ? 'Расход успешно отредактирован'
              : 'Расход успешно добавлен',
          );
          handleCloseModal();
          props.refreshTaskInfo();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [form, props.apiEndpoint, inCurrency, props.editingCost]);

  return (
    <>
      <Modal
        visible={props.visible}
        destroyOnClose={true}
        zIndex={199}
        title={
          props.editingCost?.id ? 'Редактировать расход' : 'Добавить расход'
        }
        onOk={() => {
          handleCloseModal();
        }}
        onCancel={() => {
          handleCloseModal();
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              handleCloseModal();
            }}
          >
            Закрыть
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={async () => {
              await handleCreateOrEditCost();
            }}
          >
            Сохранить
          </Button>,
        ]}
      >
        <ErrorBoundary>
          <CostModalForm
            form={form}
            editingCost={props.editingCost}
            visibleModal={props.visible}
            taskData={props.taskData}
            inCurrency={inCurrency}
            setInCurrency={(value) => {
              setInCurrency(value);
            }}
          />
        </ErrorBoundary>
      </Modal>
    </>
  );
};

export default CostModal;

CostModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  taskData: PropTypes.object.isRequired,
  editingCost: PropTypes.object,
  apiEndpoint: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  refreshTaskInfo: PropTypes.func.isRequired,
};
