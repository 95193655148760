import { Button, Col, Form, message, Modal, Select } from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  createTaskWaypoint,
  editTaskWaypoint,
} from '../../../../logic/preTasks/PreTaskApiFunctions';
import { userInformations } from '../../../../contex';
import getLocations from '../../../../utils/getLocations';
import useAsyncEffect from 'use-async-effect';
import { getListOperationWaypoint } from '../../../../logic/refbook/operationWaypoint/OperationWaypointApiFunctions';
import { getListRouteWaypoint } from '../../../../logic/refbook/operationWaypoint/RouteWaypointApiFunctions';
import useCheckMobileScreen from '../../../../hooks/useCheckMobileScreen';

const { Option } = Select;

const WayPointModal = (props) => {
  const [disableWayPointBtn, setDisableWayPointBtn] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [locations, setLocations] = useState([]);
  const [operations, setOperations] = useState([]);
  const [waypointsOptions, setWaypointsOptions] = useState([]);
  const { userInfo } = useContext(userInformations);
  const isMobile = useCheckMobileScreen();

  useAsyncEffect(async () => {
    if (props.visible) {
      await getOperationOptions();
      await getRouteWaypointsOptions();
      await getLocationsOptions();
    }
  }, [props.visible]);

  useEffect(() => {
    if (props.editingWayPoint) {
      setDisableWayPointBtn(false);
    }
  }, [props.editingWayPoint]);

  const handleCreateOrEditWayPoint = useCallback(async () => {
    props.form.validateFields().then(async (values) => {
      let body = {
        ...values,
        task: props.taskId,
      };
      if (selectedAddress?.id) {
        body['waypoint'] = selectedAddress.id;
      }

      if (props.waypointsList?.length > 0 && !props.editingWayPoint?.id) {
        const indexNumber = Math.max(
          ...props.waypointsList.map((item) => item?.number),
          0,
        );
        body['number'] = indexNumber + 1;
      }
      const res = props.editingWayPoint?.id
        ? await editTaskWaypoint(props.editingWayPoint.id, body, userInfo)
        : await createTaskWaypoint(body, userInfo);
      if (res) {
        message.success(
          props.editingWayPoint?.id
            ? 'Пункт успешно отредактирован'
            : 'Пункт успешно добавлен',
        );
        props.handleCloseModal();
        props.handleUpdateList();
      }
    });
  }, [
    props.form,
    props.taskId,
    selectedAddress,
    props.editingWayPoint,
    props.waypointsList,
  ]);

  const handleAddressChange = useCallback(
    async (value) => {
      if (value) {
        const locations = await getLocations(value);
        setLocations(locations);
      } else {
        setLocations([]);
      }
    },
    [setLocations],
  );

  const getLocationsOptions = useCallback(async () => {
    const res = await getLocations();
    if (res?.data) {
      setLocations(res.data);
    }
  }, [setLocations]);

  const getOperationOptions = useCallback(async () => {
    const res = await getListOperationWaypoint(userInfo);
    if (res.data) {
      setOperations(res?.data);
    }
  }, [setOperations]);

  const getRouteWaypointsOptions = useCallback(
    async (keyword) => {
      const res = await getListRouteWaypoint(userInfo, keyword);
      if (res?.data) {
        setWaypointsOptions(res.data);
      }
    },
    [setWaypointsOptions],
  );

  const debouncedSearch = _.debounce(handleAddressChange, 500);

  const handleChangeOperation = useCallback(
    (data) => {
      if (JSON.parse(data['data-value'])?.name) {
        props.form.setFieldsValue({
          name: JSON.parse(data['data-value']).name,
        });
        const operation = JSON.parse(data['data-value']);
        props.setIsOperationAuto(operation?.type_transport === 'Авто');
      }
    },
    [props.form],
  );

  const handleCloseModal = useCallback(() => {
    setDisableWayPointBtn(true);
    props.handleCloseModal();
  }, []);

  return (
    <>
      <Modal
        visible={props.visible}
        title={
          props.editingWayPoint?.id ? 'Редактировать пункт' : 'Добавить пункт'
        }
        width={!isMobile ? 300 : ''}
        onOk={() => {
          handleCloseModal();
        }}
        onCancel={() => {
          handleCloseModal();
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              handleCloseModal();
            }}
          >
            Закрыть
          </Button>,
          <Button
            key="submit"
            type="primary"
            disabled={disableWayPointBtn}
            onClick={async () => {
              await handleCreateOrEditWayPoint();
            }}
          >
            Сохранить
          </Button>,
        ]}
      >
        <Form
          onValuesChange={() => {
            setTimeout(() => {
              props.form
                .validateFields()
                .then(() => setDisableWayPointBtn(false))
                .catch(() => setDisableWayPointBtn(true));
            }, 0);
          }}
          form={props.form}
          layout={'vertical'}
        >
          <Col gutter={5}>
            <Col lg={24} md={24} xs={24}>
              <Form.Item name={'operation'} label={'Операция'}>
                <Select
                  getPopupContainer={(trigger) =>
                    isMobile ? trigger.parentNode : document.body
                  }
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(value, data) => {
                    handleChangeOperation(data);
                  }}
                  placeholder="Поиск операции"
                >
                  {operations && operations.length > 0 ? (
                    <>
                      {operations.map((item) => {
                        return (
                          <Option
                            key={item.id}
                            value={item.id}
                            data-value={JSON.stringify(item)}
                          >
                            {item.name}
                          </Option>
                        );
                      })}
                    </>
                  ) : (
                    <Option disabled value="No data">
                      Нет данных
                    </Option>
                  )}
                </Select>
              </Form.Item>
            </Col>
            {props.isOperationAuto ? (
              <Col lg={24} md={24} xs={24}>
                <Form.Item name={'address'} label={'Адрес'}>
                  <Select
                    getPopupContainer={(trigger) =>
                      isMobile ? trigger.parentNode : document.body
                    }
                    filterOption={false}
                    placheolder={'Введите адрес'}
                    disabled={disableWayPointBtn}
                    onSearch={(value) =>
                      value.length > 0
                        ? debouncedSearch(value)
                        : getLocationsOptions()
                    }
                    showSearch
                  >
                    {locations?.length > 0 ? (
                      <>
                        {locations.map((item) => {
                          return (
                            <Option
                              key={item.unrestricted_value}
                              value={item.unrestricted_value}
                            >
                              {item.unrestricted_value}
                            </Option>
                          );
                        })}
                      </>
                    ) : (
                      <Option value={'none'} disabled>
                        Начните поиск
                      </Option>
                    )}
                  </Select>
                </Form.Item>
              </Col>
            ) : (
              <Col lg={24} md={24} xs={24}>
                <Form.Item
                  // rules={[{required: true, message: 'Обязательное поле'}]}
                  name={'waypoint'}
                  label={'Пункт'}
                >
                  <Select
                    getPopupContainer={(trigger) =>
                      isMobile ? trigger.parentNode : document.body
                    }
                    disabled={disableWayPointBtn}
                    showSearch
                    filterOption={null}
                    onChange={(value, data) => {
                      setSelectedAddress(JSON.parse(data['data-value']));
                    }}
                    onSearch={(value) =>
                      value.length > 0
                        ? getRouteWaypointsOptions(value)
                        : getRouteWaypointsOptions()
                    }
                    placeholder="Найти пункт"
                  >
                    {waypointsOptions && waypointsOptions.length > 0 ? (
                      <>
                        {waypointsOptions.map((item) => {
                          return (
                            <Option
                              key={item.id}
                              value={item.name}
                              data-value={JSON.stringify(item)}
                            >
                              {item.name}
                            </Option>
                          );
                        })}
                      </>
                    ) : (
                      <Option disabled value="noData">
                        Нет данных
                      </Option>
                    )}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Col>
        </Form>
      </Modal>
    </>
  );
};

WayPointModal.propTypes = {
  visible: PropTypes.bool,
  form: PropTypes.any,
  taskId: PropTypes.number,
  editingWayPoint: PropTypes.object,
  waypointsList: PropTypes.arrayOf(PropTypes.object),
  isOperationAuto: PropTypes.bool,
  handleUpdateList: PropTypes.func,
  setIsOperationAuto: PropTypes.func,
  handleCloseModal: PropTypes.func,
};
export default WayPointModal;
