import React, { useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Col, Form, Input, Row, Select } from 'antd';
import PhoneInput from 'react-phone-input-2';
import moment from 'moment/moment';
import ru from 'react-phone-input-2/lang/ru.json';
import searchCities from '../../../../../logic/refbook/cities/CitiesApiFunction';
import { userInformations } from '../../../../../contex';
import { getChoicesLead } from '../../../../../logic/lead/LeadApiFunctions';
import useAsyncEffect from 'use-async-effect';
import getCallResult from '../../../../../logic/refbook/callResult/CallResultApiFunction';
import { getListStrategicArchReason } from '../../../../../logic/refbook/strategicArchReason/StrategicArchReasonApiFunctions';
import useCheckMobileScreen from '../../../../../hooks/useCheckMobileScreen';

const { Option } = Select;

const BasicInformationLeadForm = (props) => {
  const { userInfo } = useContext(userInformations);
  const [countryCode, setCountryCode] = useState('ru');
  const [cities, setCities] = useState([]);
  const [choices, setChoices] = useState([]);
  const [callResultOptions, setCallResultOptions] = useState([]);
  const [stratArchReasonList, setStratArchReasonList] = useState([]);
  const isMobile = useCheckMobileScreen();

  useAsyncEffect(async () => {
    await searchRefbookCities();
    await callGetChoices();
    await callGetCallResult();
    await callGetStratArchReason();
  }, []);

  useAsyncEffect(async () => {
    if (props.editingLead?.city?.name) {
      await searchRefbookCities(props.editingLead?.city?.name);
    }
  }, [props.editingLead]);

  const ourBranch = useMemo(() => {
    const branchId = userInfo.current?.haveViewBranch
      ? parseInt(localStorage.getItem('branchId'))
      : userInfo.current?.branch?.id;
    return branchId === 1;
  }, [userInfo]);

  //phone input
  const handleChangePhone = useCallback(
    (value, data, event) => {
      if (event.type === 'click') {
        setCountryCode(data.countryCode);
        setCountryCode('ru');
      }
    },
    [setCountryCode, setCountryCode],
  );

  const searchRefbookCities = useCallback(
    async (keyword = null) => {
      const res = await searchCities(keyword, userInfo);
      if (res) {
        setCities(res);
      }
    },
    [setCities, useCallback],
  );

  const callGetChoices = useCallback(async () => {
    const res = await getChoicesLead(userInfo);
    if (res) {
      setChoices(res);
    }
  }, [setChoices, getChoicesLead]);

  const callGetCallResult = useCallback(async () => {
    const res = await getCallResult('lead', userInfo);
    if (res) {
      setCallResultOptions(res);
    }
  }, [setCallResultOptions, getCallResult]);

  const callGetStratArchReason = useCallback(async () => {
    const res = await getListStrategicArchReason(userInfo);

    if (res?.data) {
      setStratArchReasonList(res.data);
    }
  }, [setCallResultOptions, userInfo, getListStrategicArchReason]);

  return (
    <>
      <Form form={props.form} layout="vertical">
        <Row gutter={16}>
          <Col xs={24} lg={4}>
            <Form.Item label={'Цвет'} name={'color'}>
              <Select
                placeholder={'Выберите цвет'}
                getPopupContainer={(trigger) =>
                  isMobile ? trigger.parentNode : document.body
                }
              >
                {choices && choices?.colors ? (
                  <>
                    {Object.entries(choices.colors).map((item) => {
                      return (
                        <Option
                          key={item[0]}
                          style={{ background: `rgb(${item[1]})` }}
                          value={item[0]}
                        >
                          <div
                            style={{
                              color: '#fff',
                              background: `rgb(${item[1]})`,
                            }}
                          >
                            {item[0]}
                          </div>
                        </Option>
                      );
                    })}
                    <Option value={null}>
                      <div>Без цвета</div>
                    </Option>
                  </>
                ) : (
                  <Option disabled value={null}>
                    Нет доступных вариантов
                  </Option>
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={4}>
            <Form.Item label={'ABC'} name={'abc'}>
              <Select
                placeholder={'Выберите классификацию ABC'}
                getPopupContainer={(trigger) =>
                  isMobile ? trigger.parentNode : document.body
                }
              >
                {choices && choices?.abc ? (
                  <>
                    {Object.entries(choices.abc).map((item) => {
                      return (
                        <Option key={item[0]} value={item[0]}>
                          {item[1]}
                        </Option>
                      );
                    })}
                  </>
                ) : (
                  <Option disabled value={null}>
                    Нет доступных вариантов
                  </Option>
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={4}>
            <Form.Item label={'XYZ'} name={'xyz'}>
              <Select
                placeholder={'Выберите классификацию XYZ'}
                getPopupContainer={(trigger) =>
                  isMobile ? trigger.parentNode : document.body
                }
              >
                {choices && choices?.xyz ? (
                  <>
                    {Object.entries(choices.xyz).map((item) => {
                      return (
                        <Option key={item[0]} value={item[0]}>
                          {item[1]}
                        </Option>
                      );
                    })}
                  </>
                ) : (
                  <Option disabled value={null}>
                    Нет доступных вариантов
                  </Option>
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={6}>
            <Form.Item
              label="Компания"
              name={'company_name'}
              rules={[{ required: true, message: 'Обязательное поле!' }]}
            >
              <Input placeholder="Введите наименование компании" />
            </Form.Item>
          </Col>
          <Col xs={24} lg={6} md={6}>
            <Form.Item label="ИНН" name={'okpo'}>
              <Input placeholder="Введите ИНН" />
            </Form.Item>
          </Col>
          <Col xs={24} lg={6} md={6}>
            <Form.Item
              label="ФИО (контактное лицо)"
              name={'contact_person'}
              rules={[{ required: true, message: 'Обязательное поле!' }]}
            >
              <Input placeholder="Введите ФИО" />
            </Form.Item>
          </Col>
          <Col xs={24} lg={6} md={6}>
            <Form.Item label="Email" name={'email'}>
              <Input placeholder="Введите email" />
            </Form.Item>
          </Col>
          <Col lg={4} md={4} xs={24}>
            <Form.Item label={'Доб. номер'} name={'add_tel_number'}>
              <Input placeholder={'Введите добавочный номер'} />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            lg={8}
            md={8}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Form.Item
              label="Телефон (контактное лицо)"
              name={'phone_number'}
              rules={[{ required: true, message: 'Обязательное поле!' }]}
            >
              <PhoneInput
                localization={ru}
                value={countryCode}
                country={'ru'}
                onlyCountries={[
                  'ru',
                  'us',
                  'fr',
                  'by',
                  'az',
                  'ge',
                  'ua',
                  'kz',
                  'am',
                  'kg',
                  'md',
                  'tr',
                  'uz',
                  'tj',
                ]}
                onChange={handleChangePhone}
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            lg={
              props.messengerOtherSelected && props.sourceOtherSelected ? 4 : 6
            }
          >
            <Form.Item label={'Город'} name={'city'}>
              <Select
                getPopupContainer={(trigger) =>
                  isMobile ? trigger.parentNode : document.body
                }
                optionFilterProp="children"
                placeholder={'Выберите город'}
                showSearch
                onSearch={(val) => searchRefbookCities(val)}
              >
                {cities?.length > 0 ? (
                  <>
                    {cities.map((item) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </>
                ) : (
                  <Option disabled value={null}>
                    Нет доступных вариантов
                  </Option>
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col
            xs={24}
            lg={
              props.messengerOtherSelected && props.sourceOtherSelected
                ? 5
                : props.messengerOtherSelected || props.sourceOtherSelected
                ? 6
                : 9
            }
          >
            <Form.Item label={'Мессенджер'} name={'messenger'}>
              <Select
                getPopupContainer={(trigger) =>
                  isMobile ? trigger.parentNode : document.body
                }
                onChange={(value) => {
                  props.setMessengerOtherSelected(value === 'oth');
                }}
                placeholder={'Выберите мессенджер'}
              >
                {choices && choices?.messenger ? (
                  <>
                    {Object.entries(choices?.messenger).map((item) => {
                      return (
                        <Option key={item[0]} value={item[0]}>
                          {item[1]}
                        </Option>
                      );
                    })}
                  </>
                ) : (
                  <Option disabled value={null}>
                    Нет доступных вариантов
                  </Option>
                )}
              </Select>
            </Form.Item>
          </Col>
          {props.messengerOtherSelected && (
            <Col
              xs={24}
              lg={
                props.messengerOtherSelected && props.sourceOtherSelected
                  ? 5
                  : props.messengerOtherSelected || props.sourceOtherSelected
                  ? 6
                  : 9
              }
            >
              <Form.Item name={'messenger_other'} label={'Др. мессенджер'}>
                <Input placeholder={'Введите свой мессенджер'} />
              </Form.Item>
            </Col>
          )}
          <Col
            xs={24}
            lg={
              props.messengerOtherSelected && props.sourceOtherSelected
                ? 5
                : props.messengerOtherSelected || props.sourceOtherSelected
                ? 6
                : 9
            }
          >
            <Form.Item label="Источник" name={'source_select'}>
              <Select
                getPopupContainer={(trigger) =>
                  isMobile ? trigger.parentNode : document.body
                }
                placeholder={'Выберите источник'}
              >
                {choices && choices?.source ? (
                  <>
                    {Object.entries(choices?.source).map((item) => {
                      return (
                        <Option key={item[0]} value={item[0]}>
                          {item[1]}
                        </Option>
                      );
                    })}
                  </>
                ) : (
                  <Option disabled value={null}>
                    Нет доступных вариантов
                  </Option>
                )}
              </Select>
            </Form.Item>
          </Col>
          {props.sourceOtherSelected && (
            <Col
              xs={24}
              lg={
                props.messengerOtherSelected && props.sourceOtherSelected
                  ? 5
                  : props.messengerOtherSelected || props.sourceOtherSelected
                  ? 6
                  : 9
              }
            >
              <Form.Item name={'source_other'} label={'Др. источник'}>
                <Input placeholder={'Введите свой источник'} />
              </Form.Item>
            </Col>
          )}
          <Col lg={12} xs={24}>
            <Row gutter={16}>
              <Col xs={24} lg={12}>
                <Form.Item label="Посл. общение" name={'date_recent_request'}>
                  <input
                    className={'ant-input'}
                    max="9999-12-31"
                    type={'date'}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  label="Дата след. общение"
                  name={'date_next_request'}
                  rules={[{ required: true, message: 'Обязательное поле!' }]}
                >
                  <input
                    className={'ant-input'}
                    type="datetime-local"
                    max="9999-12-31"
                    min={moment().format('YYYY-MM-DD HH:mm')}
                    pattern="[0-2][0-9]:[0-5][0-9]"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col lg={12} md={12} xs={24}>
                <Form.Item label={'Комментарий'} name={'comment'}>
                  <Input.TextArea placeholder="Введите комментарий по лиду" />
                </Form.Item>
              </Col>
              <Col lg={12} md={12} xs={24}>
                <Form.Item
                  label={'Сайт'}
                  name={'website'}
                  rules={[
                    {
                      required: false,
                    },
                    {
                      pattern:
                        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g,
                      message: 'Введите правильный URL',
                    },
                  ]}
                >
                  <Input placeholder="Введите адрес вебсайта" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col lg={12} xs={24}>
            <Row gutter={16}>
              <Col xs={24} md={12} lg={12}>
                <Form.Item
                  label="Итог звонка"
                  name={'call_result'}
                  rules={[{ required: true, message: 'Обязательное поле!' }]}
                >
                  <Select
                    getPopupContainer={(trigger) =>
                      isMobile ? trigger.parentNode : document.body
                    }
                    placeholder={'Выберите итог звонка'}
                    onChange={(value) => {
                      props.setIsArchive(value === 1 || value === 2);
                    }}
                  >
                    {callResultOptions && callResultOptions?.length > 0 ? (
                      <>
                        {callResultOptions.map((item) => {
                          return (
                            <Option key={item?.id} value={item?.id}>
                              {item?.result}
                            </Option>
                          );
                        })}
                      </>
                    ) : (
                      <Option disabled value={null}>
                        Нет доступных вариантов
                      </Option>
                    )}
                  </Select>
                </Form.Item>
              </Col>
              {ourBranch ? (
                <Col xs={24} md={12} ld={12}>
                  <Form.Item
                    label="Франчайзи"
                    name={'franchise'}
                    valuePropName={'checked'}
                  >
                    <Checkbox />
                  </Form.Item>
                </Col>
              ) : (
                <></>
              )}
            </Row>
            {props.isArchive && (
              <Row gutter={16}>
                <Col xs={24} md={12} lg={12}>
                  <Form.Item
                    label="Причина страт. архива"
                    name={'strat_arch_reason'}
                    rules={[{ required: true, message: 'Обязательное поле!' }]}
                  >
                    <Select
                      getPopupContainer={(trigger) =>
                        isMobile ? trigger.parentNode : document.body
                      }
                      placeholder={'Выберите причину'}
                    >
                      {stratArchReasonList &&
                      stratArchReasonList?.length > 0 ? (
                        <>
                          {stratArchReasonList.map((item) => {
                            return (
                              <Option key={item?.id} value={item?.id}>
                                {item?.reason || 'Не заданно'}
                              </Option>
                            );
                          })}
                        </>
                      ) : (
                        <Option disabled value={null}>
                          Нет доступных вариантов
                        </Option>
                      )}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} ld={12}>
                  <Form.Item
                    label={'Комментарий (страт. архив)'}
                    name={'strat_arch_reason_comment'}
                  >
                    <Input.TextArea placeholder="Введите комментарий" />
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
};

BasicInformationLeadForm.propTypes = {
  form: PropTypes.any,
  messengerOtherSelected: PropTypes.bool,
  setMessengerOtherSelected: PropTypes.func,
  sourceOtherSelected: PropTypes.bool,
  editingLead: PropTypes.object,
  isArchive: PropTypes.bool,
  setIsArchive: PropTypes.func,
};

export default React.memo(BasicInformationLeadForm);
